import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './Styles.css';
import { multiStepContext } from '../../../contexts/CurriculumContext';
import { Step1 } from './steps/curriculum-registration/Step1';
import { Step2 } from './steps/curriculum-registration/Step2';
import { Step3 } from './steps/curriculum-registration/Step3';
import { Step4 } from './steps/curriculum-registration/Step4';
import { Step5 } from './steps/curriculum-registration/Step5';
import { Step6 } from './steps/curriculum-registration/Step6';
import { Step7 } from './steps/curriculum-registration/Step7';
import { Step8 } from './steps/curriculum-registration/Step8';
import { Typography, Alert, Snackbar, AlertTitle } from '@mui/material';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	ExperienciaLaboralModel,
	HerramientasModel,
	IdiomaModel,
	NivelEducativoModel,
	PuestosModel,
	ResumeModel,
	UserComplementoModel,
} from '../../../models/ResumeModel';
import ColorlibStepIcon from './steps/Index';
import { LinearLoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import parameterSingleton from '../../../services/Parameters.service';
import parameters from '../../../util/parameters';

const steps = ['', '', '', '', '', '', '', ''];

export const CurriculumRegistration = () => {
	const { currentStep, userData, currentProgress, loading } = useContext(multiStepContext);
	const [listTipoOtrosConocimientos, setListTipoOtrosConocimientos] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const navigate = useNavigate();

	const showTitle = (step: number) => {
		switch (step) {
			case 1:
				return 'Información básica';
			case 2:
				return 'Información básica';
			case 3:
				return 'Información básica';
			case 4:
				return 'Información básica';
			case 5:
				return 'Nivel educativo';
			case 6:
				return 'Experiencia laboral';
			case 7:
				return 'Educación informal';
			case 8:
				return 'Idiomas y habilidades';
		}
	};

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			case 6:
				return <Step6 />;
			case 7:
				return <Step7 />;
			case 8:
				return <Step8 />;
		}
	};

	const setValueOrDefault = (value: any, typeClassMember: string) => {
		switch (typeClassMember) {
			case 'string':
				return value === '' || value === undefined || value === null ? '' : value;
			case 'boolean':
				return value === '' || value === undefined || value === null ? false : value;
			case 'number':
				return value === '' || value === undefined || value === null ? 0 : value;
			default:
				return '';
		}
	};

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const fetchOtrosConocimientosParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setListTipoOtrosConocimientos(res);
	}

	const createObjectHerramienta = (): HerramientasModel[] => {
		fetchOtrosConocimientosParams();
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						listTipoOtrosConocimientos.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexo'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'],'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
            grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
			tiene_nivel_educativo: userData['tiene_nivel_educativo'],
			tiene_experiencia_laboral: userData['tiene_experiencia_laboral'],
			tiene_capacitaciones: userData['tiene_capacitaciones'],
			porcentaje_diligenciamiento: userData['porcentaje_diligenciamiento']
		};
		console.log('resume', resume);
		return resume;
	};

	const handleClose = () => {
		setOpen(false);
	};

	function LinearProgressWithLabelThick(props: LinearProgressProps & { value: any }) {
		const value = props.value ? Number(props.value) : 0;
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1 }}>
					<BorderLinearProgress variant="determinate" value={value} />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant="h5" color="#2266cc">
						{`${value}%`}
					</Typography>
				</Box>
			</Box>
		);
	}

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
		},
	}));

	return (
		<>
			<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '50px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
					<Box>
						<div className='row'>
							<div className='col-sm-3'>
								<button
									onClick={() => { navigate(-1); }}
									className='btn btn-primary'
									style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
								>
									<i
										className='bi bi-arrow-left'
										style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
									></i>
								</button>
							</div>
							<div className='col-sm-9'>
								<Typography
									style={{ marginTop: '10px', gap: '10px', lineHeight: '0.9em' }}
									variant='h5'
									sx={{ fontWeight: 'bold' }}
								>
									{showTitle(currentStep)}
								</Typography>
							</div>
						</div>
					</Box>
				</Box>

				<Box style={{ width: '100%' }}>
					{/* <LinearProgressWithLabelThin value={currentProgress} /> */}
					<LinearProgressWithLabelThick value={currentProgress} />
					<br />
				</Box>

				<Box style={{ width: '100%' }}>
					<Stepper style={{ width: '20% !important' }} activeStep={currentStep - 1} orientation='horizontal'>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
						<Step className='Step'>
							<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
						</Step>
					</Stepper>
				</Box>
				<Box mt={5}>
					<LinearLoadingSpinner isLoading={loading} />
					{showStep(currentStep)}
				</Box>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
