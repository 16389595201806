import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../models/parameter.model';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';

const useLaboralExperienceParams = () => {
	const [listPais, setPaisList] = useState<ParameterConfigMmodel[]>([]);
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);
	const [otrosConocimientosList, setOtrosConocimientosList] = useState<ParameterConfigMmodel[]>([]);
	const [listSectorIndustrial, setListSectorIndustrial] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoExperienciaLaboral, setListTipoExperienciaLaboral] = useState<
		ParameterConfigMmodel[]
	>([]);

	useEffect(() => {
		getAllParameters();
		fetchListSiNo();
		fetchOtrosConocimientos();
		fetchListSectorIndustrial();
		fetchListTipoExperienciaLaboral();
	}, []);

	const getAllParameters = () => {
		fetchPaisParams();
	};

	const fetchPaisParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PAIS);
		setPaisList(res);
	};

	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};

	const fetchOtrosConocimientos = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setOtrosConocimientosList(res);
	};

	const fetchListSectorIndustrial = async () => {
		const res = await parameterSingleton.fetchData(parameters.SECTORINDUSTRIAL);
		setListSectorIndustrial(res);
	};

	const fetchListTipoExperienciaLaboral = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOEXPERIENCIALABORAL);
		setListTipoExperienciaLaboral(res);
	};

	return {
		listPais,
		listSiNo,
		otrosConocimientosList,
		listSectorIndustrial,
		listTipoExperienciaLaboral,
	};
};

export default useLaboralExperienceParams;
