import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import parameterSingleton from '../../../services/Parameters.service';
import parameters from '../../../util/parameters';

const useStep1Parameters = (setLoading?: any) => {
	const [sexoList, setSexoList] = useState<ParameterConfigMmodel[]>([]);
	const [generoList, setGeneroList] = useState<ParameterConfigMmodel[]>([]);
	const [otrosConocimientosList, setOtrosConocimientosList] = useState<ParameterConfigMmodel[]>([]);
	const [siNoList, setSiNoList] = useState<ParameterConfigMmodel[]>([]);
	const [orientacionSexualList, setOrientacionSexualList] = useState<ParameterConfigMmodel[]>([]);
	const [paisList, setPaisList] = useState<ParameterConfigMmodel[]>([]);
	const [departamentoList, setDepartamentoList] = useState<ParameterConfigMmodel[]>([]);
	const [estadoCivilList, setEstadoCivilList] = useState<ParameterConfigMmodel[]>([]);
	const [tipoLibretaMilList, setTipoLibretaMilList] = useState<ParameterConfigMmodel[]>([]);
	const [nacionalidadList, setNacionalidadList] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoDocumentoCandidato, setlistTipoDocumentoCandidato] = useState<ParameterConfigMmodel[]>([]);
	const [listGrupoEtnico, setListGrupoEtnico] = useState<ParameterConfigMmodel[]>([]);
	const [loadingParams, setLoadingParams] = useState(true);
	const [tipoSisbenList,setTipoSisbenList] = useState<ParameterConfigMmodel[]>([]);
	const [tipoCuidadorList,setTipoCuidadorList] = useState<ParameterConfigMmodel[]>([]);
	const [listGrupoIndigena,setListGrupoIndigena] = useState<ParameterConfigMmodel[]>([]);
	const [listDiscapacidad,setListDiscapacidad] = useState<ParameterConfigMmodel[]>([]);
	const [listPoblaciones, setListPoblaciones] = useState<ParameterConfigMmodel[]>([]);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		// setLoadingParams(true);
		await fetchNacionalidadList();
		await fetchGenero();
		await fetchOtrosConocimientosList();
		await fetchListGrupoEtnico();
		await fetchSiNoList();
		await fetchOrientacionSexualParams();
		await fetchPaisParams();
		await fetchDepartamentoParams();
		await fetchEstadoCivilParams();
		await fetchTipoLibretaMilList();
		await fetchTipoCuidador();
		await fetchTipoSisbenList();
		await fetchListTipoDocumentoCandidato();
		await fetchListGrupoIndigena();
		await fetchListDiscapacidad();
		await fetchListPoblaciones();
		setLoadingParams(false);
	};

	const fetchListDiscapacidad = async () => {
		const res = await parameterSingleton.fetchData(parameters.DISCAPACIDADES);
		setListDiscapacidad(res);
	}

	const fetchListTipoDocumentoCandidato = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPODOCUMENTO);
		setlistTipoDocumentoCandidato(res);
	};

	const fetchListGrupoIndigena = async () => {
		const res = await parameterSingleton.fetchData(parameters.GRUPOINDIGENA);
		setListGrupoIndigena(res);
	};
	const fetchListGrupoEtnico = async () => {
		const res = await parameterSingleton.fetchData(parameters.GRUPOETNICO);
		setListGrupoEtnico(res);
	};

	const fetchGenero = async () => {
		const sexResponse = await parameterSingleton.fetchData(parameters.SEXO);
		setSexoList(sexResponse);

		const res = await parameterSingleton.fetchData(parameters.GENERO);
		setGeneroList(res);
	};

	const fetchOtrosConocimientosList = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setOtrosConocimientosList(res);
	};

	const fetchSiNoList = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setSiNoList(res);
	};

	const fetchTipoSisbenList = async () => {
		const res = await parameterSingleton.fetchData(parameters.SISBEN);
		setTipoSisbenList(res);
	};

	const fetchTipoCuidador = async () => {
		const res = await parameterSingleton.fetchData(parameters.PERSONACUIDADA);
		setTipoCuidadorList(res);
	};

	const fetchOrientacionSexualParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.ORIENTACIONSEXUAL);
		setOrientacionSexualList(res);
	};

	const fetchPaisParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PAIS);
		setPaisList(res);
	};

	const fetchDepartamentoParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.DEPARTAMENTO);
		setDepartamentoList(res);
	};

	const fetchEstadoCivilParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.ESTADOCIVIL);
		setEstadoCivilList(res);
	};

	const fetchTipoLibretaMilList = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOLIBRETA);
		setTipoLibretaMilList(res);
	};

	const fetchNacionalidadList = async () => {
		const res = await parameterSingleton.fetchData(parameters.NACIONALIDAD);
		setNacionalidadList(res);
	};

	const fetchListPoblaciones = async () => {
		const res = await parameterSingleton.fetchData(parameters.POBLACIONES);
		const resCandidato = res.filter(i => {
			return i.code != '15' //Persona cuidadora
				&& i.code != '16' //Grupo Étnico (Afrocolombiano, Comunidad Negra, Raizal, Palenquero, Indígena, Rrom/Gitano)
				&& i.code != '17' //Discapacidad (discapacidad física, discapacidad auditiva, discapacidad visual, sordoceguera, discapacidad intelectual, discapacidad psicosocial - mental, discapacidad múltiple)
				&& i.code != '18'//Persona que pertenece a Sisbén (A, B, C)
				&& i.code != '19'//Persona joven (18 a 28 años)
				&& i.code != '20'//Persona mayor de 50 años
				&& i.code != '21'//Mujer
				&& i.code != '22'//Persona migrante
		});
		setListPoblaciones(resCandidato);
	}

	return {
		sexoList,
		generoList,
		otrosConocimientosList,
		siNoList,
		orientacionSexualList,
		paisList,
		departamentoList,
		estadoCivilList,
		tipoLibretaMilList,
		tipoSisbenList,
		tipoCuidadorList,
		nacionalidadList,
		loadingParams,
		listGrupoEtnico,
		listTipoDocumentoCandidato,
		listGrupoIndigena,
		listDiscapacidad,
		listPoblaciones
	};
};

export default useStep1Parameters;
