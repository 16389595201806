import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ID, Response } from '../../../../../../_metronic/helpers';
import { User, UsersQueryResponse } from './_models';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_URL_OF = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;
const GET_USERS_URL = `${API_URL}/users/query`;
const GET_USERS = `${API_URL_OF}/users/`;
const GET_USERS_COUNSELOR_EMPRESA = `${API_URL_OF}/counselors_enterprise/`;
const GET_USERS_COUNSELOR_CADIDATE = `${API_URL_OF}/counselors_candidate/`;
const PUT_ACTIVE_USER = `${API_URL_OF}/activateUser/`;
const PUT_DESACTIVE_USER = `${API_URL_OF}/deactivateUser/`;
const GET_USERCOMPLEMETO = `${API_URL_OF}/userCompelmento`;
const GET_COMPANIES = `${API_URL_OF}/empresas/`;
const GET_VACANTES_POSTULADAS = `${API_URL_OF}/vacantes-postulados-count?estado=PRESENTADA`;
const GET_LISTA_VACANTES_POSTULADAS = `${API_URL_OF}/vacantes-postulados?estado=PRESENTADA`;
const GET_ACCOMPANIMENT = `${API_URL_OF}/accompaniment-empresarial`;
const GET_ACCOMPANIMENTBYID = `${API_URL_OF}/accompaniment-empresarial`;
const POST_ACCOMPANIMENT = `${API_URL_OF}/accompaniment-empresarial`;
const UPDATE_ACCOMPANIMENT = `${API_URL_OF}/accompaniment-empresarial/`;
const PUT_ACOMPANIMENT_STATUS = `${API_URL_OF}/accompaniment-empresarial/`;
const GET_ALL_COMPANIES = `${API_URL_OF}/listEmpresas`;
const GET_COMPANIES_COUNSELOR = `${API_URL_OF}/listEmpresasConselheiro`;
const GET_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const PUT_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const DELETE_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const GET_COMPANY = `${API_URL_OF}/empresasByid/`;
const POST_COMPANY = `${API_URL_OF}/empresa/`;
const POST_UPDATECOMPANY = `${API_URL_OF}/empresa_update/`;
const GET_USER_BY_ID = `${API_URL_OF}/userId`;
const GET_VACANCY = `${API_URL_OF}/empresa_vaga/`;
const POST_VACANCY = `${API_URL_OF}/empresa_vaga`;
const POST_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const GET_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const PUT_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const DELETE_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const DELETE_VACANCY = `${API_URL_OF}/vacanteId`;
const PUT_COMPANY_ID = `${API_URL_OF}/empresaId`;
const DELETE_ACOMPANIMENT_ID = `${API_URL_OF}/accompaniment-empresarial/`;
const POST_FEEDBACK_CANDIDATE = `${API_URL_OF}/feedbackCandidate/`;
const POST_EMPRESA_CANDIDATE = `${API_URL_OF}/empresa_candidate/`;
const PUT_STATUS_EMPRESA = `${API_URL_OF}/updateStatusEmpresa/`;
const PUT_ACTIVE_EMPRESA = `${API_URL_OF}/activateEmpresa/`;
const PUT_DESACTIVE_EMPRESA = `${API_URL_OF}/deactivateEmpresa/`;
const GET_VAGACOUNTCURRICULOALL = `${API_URL_OF}/vagaCountCurriculoAll`;
const GET_VACANTES = `${API_URL_OF}/vagaCountCurriculoAll`;
const GET_LIST_VACANCY = `${API_URL_OF}/vagaCountAll`;
const GET_VAGAEMPRESACURRICULOALL = `${API_URL_OF}/vagaEmpresaCurriculoAll`;
const GET_AGENDAVAGACURRICULOALL = `${API_URL_OF}/agendaVagaCurriculoAll`;
const GET_AGENDACURRICULOBYID = `${API_URL_OF}/agendaCurriculoById`;
const GET_VAGAPOSTULACURRICULOALL = `${API_URL_OF}/vagaPostulaCurriculoAll`;
const POST_VACANCY_REGISTER = `${API_URL_OF}/curriculoVaga`;
const GET_CURRICULOBYID = `${API_URL_OF}/curriculoById`;
const GET_CURRICULOVAGABYID = `${API_URL_OF}/curriculoVagaByid`;
const GET_VAGASPOSTULADAS = `${API_URL_OF}/vagasPostulatas`;
const GET_SEARCH_VACANTES = `${API_URL_OF}/search_vacantes`;
const GET_VAGACURRICULOBYID = `${API_URL_OF}/wordpress/searchVacantes`;
const GET_VANCANTEWORDPRESS = `${API_URL_OF}/wordpress/searchVacanteId`;
const GET_USERCURRICULOALL = `${API_URL_OF}/allUserCurriculo/`;
const DELETE_ACOMPANAMIENTO = `${API_URL_OF}/accompaniment-candidato/`;
const UPDATE_ACCOMPANIMENT_CANDIDATO = `${API_URL_OF}/accompaniment-candidato/`;
// const GET_LISTA_DE_PARAMETROS = `${API_URL_OF}/parameters/LISTA_VALORES_FORMULARIO`;
const GET_LISTA_DE_PARAMETROS = `${API_URL_OF}/lista-valores/formularios/`;
const GET_LISTA_DE_VALORES = `${API_URL_OF}/parameters/LISTA_VALORES`;
const GET_LISTA = `${API_URL_OF}/lista-valores`;
const CREATE_VALOR = `${API_URL_OF}/lista-valores`;
const PUT_VALOR = `${API_URL_OF}/lista-valores/`;
const GET_DIRECCIONAMIENTOS = `${API_URL_OF}/direccionamiento`;
const POST_CREAR_DIRECCIONAMIENTO = `${API_URL_OF}/direccionamiento`;
const GET_DIRECCIONAMIENTO_BY_ID = `${API_URL_OF}/direccionamiento/`;
const PATCH_DIRECCIONAMIENTO = `${API_URL_OF}/direccionamiento/`;
const DELTE_DIRECCIONAMIENTO = `${API_URL_OF}/direccionamiento/`;
const GET_LISTA_DE_ORIENTADOS_BY_ID = `${API_URL_OF}/participante-direccionamiento`;
const GET_EXCEL_DIRECCIONAMIENTO = `${API_URL_OF}/participante-direccionamiento/template`;
const POST_EXCEL_DIRECCIONAMIENTO = `${API_URL_OF}/participante-direccionamiento`;
const DELETE_PARTICIPANTES_ORIENTADOS = `${API_URL_OF}/direccionamiento/:direccionamientoId/participante-direccionamiento/eliminar`;
const PUT_FINALIZAR_CARGUE_DIRECCIONAMIENTO = `${API_URL_OF}/direccionamiento/:direccionamientoId/participante-direccionamiento/finalizar-cargue`;
const GET_CANDIDATO_ORIENTADO = `${API_URL_OF}/user/candidate`;
const POST_PARTICAPANTE_INDIVIDUAL = `${API_URL_OF}/participante-direccionamiento/individual`;
const GET_VRD = `${API_URL_OF}/participante-direccionamiento/:id/evaluacion`;
const POST_VRD_RESPUESTAS = `${API_URL_OF}/evaluacion/respuesta/`;
const POST_VRD_PONDERAR = `${API_URL_OF}/evaluacion/ponderar/`;
const GET_USUARIO_TRAZABILIDAD = `${API_URL_OF}/atenciones/aspirante/`;
const GET_ARCHIVO_TRAZABILIDAD = `${API_URL_OF}/atenciones/aspirante/descargar-document/`;
const POST_REPORTE_CANDIDATO = `${API_URL_OF}/reporte/candidatos`;
const POST_REPORTE = `${API_URL_OF}/reporte/`;
const GET_LIST_OF_TRAINING_PROGRAMS = `${API_URL_OF}/programas-formacion`;
const GET_LISTA_DE_TIPOS_DE_CONVOCATORIAS = `${API_URL_OF}/tipo-convocatoria`;
const GET_CONVOCATORIA_BY_ID = `${API_URL_OF}/convocatoria`;
const POST_CREATE_CONVOCATORIA = `${API_URL_OF}/convocatoria`;
const GET_LISTS_OF_CALLS = `${API_URL_OF}/convocatorias`;
const DELETE_CONVOCATORIA = `${API_URL_OF}/convocatoria`;
const GET_LISTA_PARTICIPANTES_CONVOCATORIA = `${API_URL_OF}/participante-convocatoria`;
const POST_PROCESO_DE_CARGUE = `${API_URL_OF}/participante-convocatoria`;
const GET_TEMPLATE_EXCEL = `${API_URL_OF}/participante-convocatoria/template`;
const GET_CERTIFICADOS_DE_UN_USUARIO = `${API_URL_OF}/participante-convocatoria/:id/descargar-certificado`;
const GET_CERTIFICADOS_DE_UNA_CONVOCATORIA = `${API_URL_OF}/convocatoria/:convocatoriaId/participante-convocatoria/descargar-certificado-zip `;
const DELETE_PARTICIPANTES = `${API_URL_OF}/convocatoria/:convocatoriaId/participante-convocatoria/eliminar`;
const PUT_FINALIZAR_CARGUE = `${API_URL_OF}/convocatoria/:convocatoriaId/participante-convocatoria/finalizar-cargue`;
const GET_LISTA_DE_ACOMPANAMIENTOS = `${API_URL_OF}/accompaniment-candidato`;
const POST_CREATE_ACOMPANAMIENTO_CANDIDATO = `${API_URL_OF}/accompaniment-candidato`;
const POST_CERRAR_ACOMPANAMIENTO = `${API_URL_OF}/accompaniment-candidato/:id/status`;
const PUT_APROBAR_PARTICIPANTES = `${API_URL_OF}/convocatoria/:convocatoriaId/participante-convocatoria/aprobar`;
const GET_ACOMPANIMENT_BY_ID = `${API_URL_OF}/accompaniment-candidato/`;
const UPDATE_CONVOCATORIA = `${API_URL_OF}/convocatoria/`;
const POST_CURRICULO = `${API_URL_OF}/curriculo/`;
const PUT_VACANCY = `${API_URL_OF}/empresa_vaga`;
const CREATE_USER = `${API_URL_OF}/addUser`;
const UPDATE_USER = `${API_URL_OF}/user`;
const GET_ALL_MEET_CONSEJERO = `${API_URL_OF}/agenda_couselor`;
const GET_ALL_MEET_CANDIDATE = `${API_URL_OF}/agenda_candidate`;
const AGENDA_LIST_COUSELOR_MEETS = `${API_URL_OF}/agenda_couselor_busy_dates`;
const AGENDA_LIST_CANDIDATES_MEETS = `${API_URL_OF}/agenda_candidates_availables`;
const AGENDA_LIST_USERS_AVAILABLES = `${API_URL_OF}/agenda_users_availables`;
const AGENDA_CREATE = `${API_URL_OF}/agenda_create`;
const AGENDA_DELETE = `${API_URL_OF}/agenda_delete`;
const AGENDA_DONE = `${API_URL_OF}/agenda_done`;
const UPDATE_VACANCY_STATUS = `${API_URL_OF}/vacanteStatus`;
const GET_LOCALIDADES = `${API_URL_OF}/localidades`;
const GET_BARRIOS = `${API_URL_OF}/barrios`;
const GET_CARGOS = `${API_URL_OF}/cargos`;
const GET_NIVELES_EDUCATIVOS = `${API_URL_OF}/nivelesEducativos`;
const GET_NUCLEOS_CONOCIMIENTO = `${API_URL_OF}/nucleosConocimiento`;
const GET_NIVELES_NUCLEOS = `${API_URL_OF}/nivelesNucleos`;
const GET_TITLOS_HOMOLOGADOS = `${API_URL_OF}/titulosHomologados`;
const GET_AREAS_DESEMPENIO = `${API_URL_OF}/areasDesempenio`;
const GET_PARAMETROS = `${API_URL_OF}/parameters`;
const PUT_CURRICULOVAGA_STATUS = `${API_URL_OF}/curriculoVagaStatus`;
const POST_REMITIDO = `${API_URL_OF}/validarCurriculoVaga`;
const POST_PDF_MASIVO = `${API_URL_OF}/cv_pdf/empresaVaga`;
// const POST_PDF_MASIVO = `${API_URL_OF}`;
const PUT_VACANCYSTATE_APPROVAL = `${API_URL_OF}/vacanteStatusApproval`;
const GET_CODIGOS_CIUU = `${API_URL_OF}/codigos_ciuu`;
const GET_SECTOR_BY_CODIGO_CIUU = `${API_URL_OF}/sectorBycodigo_ciuu`;
const EMPRESA_INFORMACION_ADICIONAL = `${API_URL_OF}/empresa/extra-info/`;
const VAGA_INFORMACION_ADICIONAL = `${API_URL_OF}/vaga/extra-info/`;

const DOWNLOAD_ZIP_ALL_FILES_COMPANY = `${API_URL_OF}/empresa/archivos_download_zip`;
const DOWNLOAD_ZIP_FILE_COMPANY = `${API_URL_OF}/empresa/archivo_download`;
const GET_FILES_BY_COMPANY = `${API_URL_OF}/empresa/archivos`;
const DELETE_FILE_COMPANY = `${API_URL_OF}/empresa/archivo`;
const GET_BITACORASALL = `${API_URL_OF}/postulacion/bitacoras`;
const PUT_BITACORASTATUS = `${API_URL_OF}/postulacion/bitacora/status`;
const POST_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const GET_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const PUT_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const DELETE_BITACORA = `${API_URL_OF}/postulacion/bitacora`;

//const AGENDA_VERIFY_DATE = `${API_URL_OF}/verify_date`;
//const AGENDA_RANDOM_DATE = `${API_URL_OF}/random_date`;
//const AGENDA_LIST_COUSELOR = `${API_URL_OF}/list_couselor`;

const getUsers = async (query: string): Promise<any> => {
	return axios.get(`${GET_USERS}?${query}`).then((response: any) => response.data);
};

const getUsersConsejeroEmpresarial = async (): Promise<any> => {
	return axios.get(`${GET_USERS_COUNSELOR_EMPRESA}`).then((response: any) => response.data);
};
const getUsersConsejeroCandidato = async (): Promise<any> => {
	return axios.get(`${GET_USERS_COUNSELOR_CADIDATE}`).then((response: any) => response.data);
};

const getCompanies = async (query: string): Promise<any> => {
	return axios.get(`${GET_COMPANIES}?${query}`).then((response: any) => response.data);
};

const getAccompaniment = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_ACCOMPANIMENT}`, {
			params: query,
		})
		.then((response: any) => response.data);
};
const getVacantesPostuladas = async (): Promise<any> => {
	return axios.get(`${GET_VACANTES_POSTULADAS}`).then((response: any) => response.data);
};
const getListaVacantesPostuladas = async (): Promise<any> => {
	return axios.get(`${GET_LISTA_VACANTES_POSTULADAS}`).then((response: any) => response.data);
};
// buscar
const getAccompanimentById = async (id: any): Promise<any> => {
	return axios.get(`${GET_ACCOMPANIMENTBYID}/${id}`).then((response: any) => response.data);
};

const putStatusAcompaniment = (id: any, statusData: any): Promise<any> => {
	return axios.put(`${PUT_ACOMPANIMENT_STATUS}${id}/status`, statusData).then((d: any) => d.data);
};

const postCreateAccompaniment = (empresa: string): Promise<any> => {
	return axios.post(`${POST_ACCOMPANIMENT}`, empresa).then((d: any) => d.data);
};

const updateAccompaniment = (empresa: any, id: any): Promise<any> => {
	return axios.put(`${UPDATE_ACCOMPANIMENT}${id}`, empresa).then((d: any) => d.data);
};

const getAllCompanies = async (): Promise<any> => {
	return axios.get(`${GET_ALL_COMPANIES}`).then((response: any) => response.data);
};

const getCompaniesCounselor = async (): Promise<any> => {
	return axios.get(`${GET_COMPANIES_COUNSELOR}`).then((response: any) => response.data);
};

const getPreforma = async (id: any): Promise<any> => {
	return axios
		.get(`${GET_PREFORMA}`, {
			params: {
				id_empresa: id,
			},
		})
		.then((response: any) => response.data);
};

const putPreforma = (vacancy: any): Promise<any> => {
	return axios.put(`${PUT_PREFORMA}`, vacancy).then((d: any) => d.data);
};

const deletePreforma = (vacancy: any): Promise<any> => {
	return axios
		.delete(`${DELETE_PREFORMA}`, {
			data: {
				id: vacancy,
			},
		})
		.then((d: any) => d.data);
};

const getVagaCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGACURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getVacanteWordpress = (query: string): Promise<any> => {
	return axios.get(`${GET_VANCANTEWORDPRESS}?${query}`).then((d: any) => d.data);
};

const getVagaCountCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGACOUNTCURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getVacantes = (id_empresa: any): Promise<any> => {
	return axios
		.get(`${GET_LIST_VACANCY}`, { params: { id_empresa } })
		.then((response: AxiosResponse<ParameterConfigMmodel[]>) => response.data);
};
const getListVacantes = (): Promise<any> => {
	return axios
		.get(`${GET_LIST_VACANCY}`)
		.then((response: AxiosResponse<ParameterConfigMmodel[]>) => response.data);
};

const getVagaEmpresaCurriculoAll = async (query: string): Promise<any> => {
	return axios.get(`${GET_VAGAEMPRESACURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getAgendaVagaCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_AGENDAVAGACURRICULOALL}?${query}`).then((d: any) => d.data);
};
const getAgendaCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_AGENDACURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getVagaPostulaCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGAPOSTULACURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getUsersCurriculo = (query: string): Promise<UsersQueryResponse> => {
	return axios
		.get(`${GET_USERS_URL}?${query}`)
		.then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const getUserById = (id: ID): Promise<User | undefined> => {
	return axios
		.get(`${USER_URL}/${id}`)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const getUserDataById = (query: string): Promise<any> => {
	return axios.get(`${GET_USER_BY_ID}?${query}`).then((d: any) => d.data);
};

const deleteUserById = (query: string): Promise<any> => {
	return axios.delete(`${GET_USER_BY_ID}?${query}`).then((d: any) => d.data);
};

const deleteVacancyById = (query: string): Promise<any> => {
	console.log(`${DELETE_VACANCY}?${query}`);
	return axios.delete(`${DELETE_VACANCY}?${query}`).then((d: any) => d.data);
};

const updateStatusUserToActive = (query: string): Promise<any> => {
	return axios.put(`${PUT_ACTIVE_USER}?${query}`).then((d: any) => d.data);
};

const createInformacionAdicionalEmpresa = (empresa: string): Promise<any> => {
	return axios.post(`${EMPRESA_INFORMACION_ADICIONAL}`, empresa).then((d: any) => d.data);
};

const updateInformacionAdicionalEmpresa = (empresa: string): Promise<any> => {
	return axios.put(`${EMPRESA_INFORMACION_ADICIONAL}`, empresa).then((d: any) => d.data);
};

const getInformacionAdicionalEmpresa = (id_empresa: number): Promise<any> => {
	return axios
		.get(`${EMPRESA_INFORMACION_ADICIONAL}?id_empresa=${id_empresa}`)
		.then((d: any) => d.data);
};

const createInformacionAdicionalVacante = (vacante: string): Promise<any> => {
	return axios.post(`${VAGA_INFORMACION_ADICIONAL}`, vacante).then((d: any) => d.data);
};

const updateInformacionAdicionalVacante = (vacante: string): Promise<any> => {
	return axios.put(`${VAGA_INFORMACION_ADICIONAL}`, vacante).then((d: any) => d.data);
};

const getInformacionAdicionalVacante = (id_vaga: number): Promise<any> => {
	return axios.get(`${VAGA_INFORMACION_ADICIONAL}?id_vaga=${id_vaga}`).then((d: any) => d.data);
};

const updateStatusUserToDesactive = (query: string): Promise<any> => {
	return axios.put(`${PUT_DESACTIVE_USER}?${query}`).then((d: any) => d.data);
};

const deleteEmpresaById = (query: string): Promise<any> => {
	return axios.put(`${PUT_COMPANY_ID}?${query}`).then((d: any) => d.data);
};

const deleteAcompaniment = (id: any): Promise<any> => {
	const endpoint = `${DELETE_ACOMPANIMENT_ID}${id}`;
	return axios
		.delete(endpoint)
		.then((response) => response.data)
		.catch((error) => {
			console.error('Error al eliminar el acompañamiento:', error);
			throw error;
		});
};

const updateStatusEmpresa = (data: any): Promise<any> => {
	return axios.put(PUT_STATUS_EMPRESA, data).then((d: any) => d.data);
};

const emailCandidateEmpresa = (data: any): Promise<any> => {
	return axios.post(POST_EMPRESA_CANDIDATE, data).then((d: any) => d.data);
};

const feedbackCandidate = (data: any): Promise<any> => {
	return axios.post(POST_FEEDBACK_CANDIDATE, data).then((d: any) => d.data);
};

const updateEmpresaToActive = (query: string): Promise<any> => {
	return axios.put(`${PUT_ACTIVE_EMPRESA}?${query}`).then((d: any) => d.data);
};

const updateEmpresaToDesactive = (query: string): Promise<any> => {
	return axios.put(`${PUT_DESACTIVE_EMPRESA}?${query}`).then((d: any) => d.data);
};

const createUser = (user: User): Promise<User | undefined> => {
	return axios
		.put(USER_URL, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const createUserPost = (user: any): Promise<any> => {
	return axios.post(CREATE_USER, user).then((d: any) => d.data);
};

const updateUserPut = (user: any): Promise<any> => {
	return axios.put(UPDATE_USER, user).then((d: any) => d.data);
};
const updateUserComplementoPut = async (user: any): Promise<any> => {
	return axios.put(GET_USERCOMPLEMETO, user).then((d: any) => d.data);
};

const updateUser = (user: User): Promise<User | undefined> => {
	return axios
		.post(`${USER_URL}/${user.id}`, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const updateVacancy = (data: any): Promise<any> => {
	return axios.put(`${PUT_VACANCY}`, data).then((d: any) => d.data);
};

const deleteUser = (userId: ID): Promise<void> => {
	return axios.delete(`${USER_URL}/${userId}`).then(() => {});
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
	const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
	return axios.all(requests).then(() => {});
};

const getUserCompelmentoId = (id: ID): Promise<any> => {
	return axios.get(`${GET_USERCOMPLEMETO}?id=${id}`).then((d: any) => d.data);
};

const updateCompany = async (company: any): Promise<any> => {
	return axios.post(`${POST_UPDATECOMPANY}`, company).then((d: any) => d.data);
};

const createCompany = async (company: any): Promise<any> => {
	return axios.post(`${POST_COMPANY}`, company).then((d: any) => d.data);
};

const createCurriculo = (curriculo: any): Promise<void> => {
	return axios.post(`${POST_CURRICULO}`, curriculo).then((d: any) => d.data);
};

const getUserCurriculoAll = async (query: string): Promise<any> => {
	return axios.get(`${GET_USERCURRICULOALL}?${query}`).then((d: any) => d.data);
};

// Inicio de endpoints para las convocatorias
const getListOfTrainingPrograms = (): Promise<any> => {
	return axios.get(`${GET_LIST_OF_TRAINING_PROGRAMS}`).then((d: any) => d.data);
};
const getListarTiposDeConvocatorias = (): Promise<any> => {
	return axios.get(`${GET_LISTA_DE_TIPOS_DE_CONVOCATORIAS}`).then((d: any) => d.data);
};

const getConvocatoriaById = async (id: any): Promise<any> => {
	return axios.get(`${GET_CONVOCATORIA_BY_ID}/${id}`).then((response: any) => response.data);
};

const postCreateConvicatoria = (data: string): Promise<any> => {
	return axios.post(`${POST_CREATE_CONVOCATORIA}`, data).then((d: any) => d.data);
};

const getListOfCalls = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_LISTS_OF_CALLS}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const deleteConvocatoria = (id: any): Promise<any> => {
	const endpoint = `${DELETE_CONVOCATORIA}/${id}`;
	return axios
		.delete(endpoint)
		.then((response) => response.data)
		.catch((error) => {
			console.error('Error al eliminar la convocatoria:', error);
			throw error;
		});
};

const updateConvocatoria = (empresa: any, id: any): Promise<any> => {
	return axios.put(`${UPDATE_CONVOCATORIA}${id}`, empresa).then((d: any) => d.data);
};

const getParticipantesDeLaConvocatoria = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_LISTA_PARTICIPANTES_CONVOCATORIA}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const PostProcesoDeCargue = (convocatoria_id: any, file: any): Promise<any> => {
	const formData = new FormData();
	formData.append('convocatoria_id', convocatoria_id);
	formData.append('file', file);
	return axios
		.post(`${POST_PROCESO_DE_CARGUE}`, formData, {
			responseType: 'blob',
		})
		.then((response: any) => response.data);
};

// const getUnSoloCertificadoDeUnaConvocatoria = async (idUsuario: any, convocatoria_id:any): Promise<any> => {
// 	return axios
// 		.post(`${GET_CERTIFICADOS_DE_UN_USUARIO}`.replace(':id', idUsuario),
// 		{convocatoria_id: convocatoria_id},
// 		{ responseType: 'blob' }
// 	)
// 		.then((response: any) => response.data);
// };

const getUnSoloCertificadoDeUnaConvocatoria = async (
	idUsuario: any,
	convocatoria_id: any
): Promise<Blob> => {
	return axios
		.post(
			`${GET_CERTIFICADOS_DE_UN_USUARIO}`.replace(':id', idUsuario),
			{ convocatoria_id },
			{ responseType: 'blob' } // Asegurando que se maneje como archivo binario
		)
		.then((response) => response.data);
};

const getCetificadosDeUnaConvocatoria = async (convocatoriaId: any): Promise<Blob> => {
	return axios
		.post(
			`${GET_CERTIFICADOS_DE_UNA_CONVOCATORIA}`.replace(':convocatoriaId', convocatoriaId),
			{},
			{ responseType: 'blob' }
		)
		.then((response) => response.data);
};

const getTemplateExcel = async (): Promise<any> => {
	return axios
		.get(`${GET_TEMPLATE_EXCEL}`, {
			responseType: 'blob',
		})
		.then((response: any) => response.data);
};

const deleteParticipantes = (convocatoriaId: any, ids: any[]): Promise<any> => {
	return axios
		.delete(`${DELETE_PARTICIPANTES}`.replace(':convocatoriaId', convocatoriaId), { data: { ids } })
		.then((d: any) => d.data);
};

const putAprobarParticipantes = (convocatoriaId: any, { aspirantes }: any): Promise<any> => {
	return axios
		.put(`${PUT_APROBAR_PARTICIPANTES}`.replace(':convocatoriaId', convocatoriaId), { aspirantes })
		.then((d: any) => d.data);
};

const getAcompanimentById = async (id: any): Promise<any> => {
	return axios.get(`${GET_ACOMPANIMENT_BY_ID}${id}`).then((response: any) => response.data);
};

const finalizarConvocatoria = (convocatoriaId: any): Promise<any> => {
	return axios
		.put(`${PUT_FINALIZAR_CARGUE}`.replace(':convocatoriaId', convocatoriaId))
		.then((d: any) => d.data);
};

// Fin de endpoints para las convocatorias

// Inicio de acompañamientos candidato

// const getListarAcompanamientos = (): Promise<any> => {
// 	return axios.get(`${GET_LISTA_DE_ACOMPANAMIENTOS}`).then((d: any) => d.data);
// }

const getListarAcompanamientos = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_LISTA_DE_ACOMPANAMIENTOS}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const postCreateAcompanamento = (data: string): Promise<any> => {
	return axios.post(`${POST_CREATE_ACOMPANAMIENTO_CANDIDATO}`, data).then((d: any) => d.data);
};

const getCerrarAcompanamiento = (id: any, statusData: any): Promise<any> => {
	return axios
		.put(`${POST_CERRAR_ACOMPANAMIENTO}`.replace(':id', id), statusData)
		.then((d: any) => d.data);
};

const getlistadoDeCandidatos = async (query: string): Promise<any> => {
	return axios.get(`${GET_USERCURRICULOALL}?${query}`).then((d: any) => d.data);
};

const deleteAcompanamiento = (id: any): Promise<any> => {
	return axios.delete(`${DELETE_ACOMPANAMIENTO}${id}`).then((d: any) => d.data);
};

const updateAcompanmientoCandidato = (empresa: any, id: any): Promise<any> => {
	return axios.put(`${UPDATE_ACCOMPANIMENT_CANDIDATO}${id}`, empresa).then((d: any) => d.data);
};

// Fin de acompañamientos candidato

// Inicio Lista de valores

// const getListaDeParametros = (): Promise<ParameterConfigMmodel[]> => {
// 	return axios.get(`${GET_LISTA_DE_PARAMETROS}`).then((d: any) => d.data);
// };

const getListaDeParametros = (
	paramtro: any,
	id_param_parent?: any
): Promise<ParameterConfigMmodel[]> => {
	return axios
		.get(`${GET_LISTA_DE_PARAMETROS}${paramtro}`, {
			// params : { id_formulario },
			params : { id_param_parent },
		})
		.then((d: any) => d.data);
};

const getListaDeValores = (id: any): Promise<ParameterConfigMmodel[]> => {
	return axios
		.get(`${GET_LISTA_DE_VALORES}`, {
			data: { id_param_parent: id },
		})
		.then((response) => response.data);
};

const getValores = ({
	selectedFormulario,
	selectedListaDeValor,
}: any): Promise<ParameterConfigMmodel[]> => {
	return axios.get(
		`${GET_LISTA}?id_formulario=${selectedFormulario}&id_lista_valor=${selectedListaDeValor}`
	);
};

const postCrearValor = ({ selectedFormulario, selectedListaDeValor, valor }: any): Promise<any> => {
	return axios
		.post(`${CREATE_VALOR}`, {
			id_formulario: selectedFormulario,
			id_lista_valor: selectedListaDeValor,
			valor,
			estado: 'activo',
		})
		.then((d: any) => d.data);
};

const putValor = (id: any, { estado, valor }: any): Promise<any> => {
	return axios
		.patch(`${PUT_VALOR}${id}`, {
			valor,
			estado,
		})
		.then((d: any) => d.data);
};

// FinLista de valores

// Inicio Direccionamiento / Orientacion

const getListaDireccionamientos = (params: any): Promise<ParameterConfigMmodel[]> => {
	return axios.get(`${GET_DIRECCIONAMIENTOS}`, { params }).then((d: any) => d.data);
};

const getTipos = (params: {
	TIPO_DIRECCIONAMIENTO: any;
	SUBTIPO_DIRECCIONAMIENTO?: any;
}): Promise<ParameterConfigMmodel[]> => {
	return axios.get(`${GET_DIRECCIONAMIENTOS}`, { params }).then((d: any) => d.data);
};

const postCrearDireccionamiento = ({
	id_consejero_candidato,
	tipo_direccionamiento,
	subtipo_direccionamiento,
	fecha_registro,
}: any): Promise<any> => {
	return axios
		.post(`${POST_CREAR_DIRECCIONAMIENTO}`, {
			id_consejero_candidato,
			tipo_direccionamiento,
			subtipo_direccionamiento,
			fecha_registro,
		})
		.then((d: any) => d.data);
};

const getDireccionamientoById = async (id: any): Promise<any> => {
	return axios.get(`${GET_DIRECCIONAMIENTO_BY_ID}${id}`).then((response: any) => response.data);
};

const updateDireccionamiento = (data: any, id: any): Promise<any> => {
	return axios.patch(`${PATCH_DIRECCIONAMIENTO}${id}`, data).then((d: any) => d.data);
};

const deleteDireccionamiento = (id: any): Promise<any> => {
	return axios.delete(`${DELTE_DIRECCIONAMIENTO}${id}`).then((d: any) => d.data);
};

const getListadoDeOrientadosById = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_LISTA_DE_ORIENTADOS_BY_ID}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const getExcelDireccionamiento = async (): Promise<any> => {
	return axios
		.get(`${GET_EXCEL_DIRECCIONAMIENTO}`, {
			responseType: 'blob',
		})
		.then((response: any) => response.data);
};

const PostProcesoDeCargueOrientados = (direccionamiento_id: any, file: any): Promise<any> => {
	const formData = new FormData();
	formData.append('direccionamiento_id', direccionamiento_id);
	formData.append('file', file);
	return axios
		.post(`${POST_EXCEL_DIRECCIONAMIENTO}`, formData, {
			responseType: 'blob',
		})
		.then((response: any) => response.data);
};

const deleteParticipantesOrientados = (direccionamientoId: any, ids: any[]): Promise<any> => {
	return axios
		.delete(
			`${DELETE_PARTICIPANTES_ORIENTADOS}`.replace(':direccionamientoId', direccionamientoId),
			{ data: { ids } }
		)
		.then((d: any) => d.data);
};

const finalizarDireccionamiento = (direccionamientoId: any): Promise<any> => {
	return axios
		.put(
			`${PUT_FINALIZAR_CARGUE_DIRECCIONAMIENTO}`.replace(':direccionamientoId', direccionamientoId)
		)
		.then((d: any) => d.data);
};

const getCandidato = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_CANDIDATO_ORIENTADO}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const postParticipanteIndividual = ({
	tipo_doc,
	numero_doc,
	direccionamiento_id,
}: any): Promise<any> => {
	return axios
		.post(`${POST_PARTICAPANTE_INDIVIDUAL}`, {
			tipo_doc,
			numero_doc,
			direccionamiento_id,
		})
		.then((d: any) => d.data);
};

const getVRD = async (id: any): Promise<any> => {
	return axios.get(`${GET_VRD}`.replace(':id', id)).then((response: any) => response.data);
};

const postRespuestas = (evaluacionData: any, id_evaluacion: any, id_user: any): Promise<any> => {
	return axios
		.post(`${POST_VRD_RESPUESTAS}`, {
			id_evaluacion,
			id_user,
			respuesta: evaluacionData.respuesta,
		})
		.then((d: any) => d.data);
};

const getRespuestas = (id_evaluacion: any, id_user: any): Promise<any> => {
	return axios
		.get(`${POST_VRD_RESPUESTAS}`, {
			params: { id_evaluacion, id_user },
		})
		.then((d: any) => d.data);
};

const postPonderar = (id_evaluacion: any, id_user: any): Promise<any> => {
	return axios
		.post(`${POST_VRD_PONDERAR}`, {
			id_evaluacion,
			id_user,
		})
		.then((d: any) => d.data);
};

// por el body

// Fin Direccionamiento / Orientacion

//Inicio Trazabilidad de Atenciones

const getCandidatTrazabilidad = async (query: any): Promise<any> => {
	return axios
		.get(`${GET_USUARIO_TRAZABILIDAD}`, {
			params: query,
		})
		.then((response: any) => response.data);
};

const getArchivoTrazabilidad = async ({
	numero_doc,
	tipo_doc,
	tipo_documento,
}: any): Promise<Blob> => {
	return axios
		.post(
			`${GET_ARCHIVO_TRAZABILIDAD}`,
			{
				numero_doc,
				tipo_doc,
				tipo_documento,
			},
			{ responseType: 'blob' } // Asegurando que se maneje como archivo binario
		)
		.then((response) => response.data);
};

// Fin Trazabilidad de Atenciones
const reportesDetallados = (tipoDoc: any, filtros: any, endpoint: any): Promise<any> => {
	return axios
		.post(
			`${POST_REPORTE}${endpoint}`,
			{ formato_exportar: tipoDoc }, // Se envía en el body
			{
				params: filtros,
				responseType: 'blob',
			} // Se envían en la URL como query params
		)
		.then((d: any) => d.data);
};
// Fin Reportes Detallados

const getCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_CURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getLocalidades = (): Promise<any> => {
	return axios.get(`${GET_LOCALIDADES}`).then((d: any) => d.data);
};

//Obtiene la informacion de los barrios segun la localidad
const getBarriosByLocalidad = (id_localidad: number): Promise<any> => {
	return axios.get(`${GET_BARRIOS}?id_localidad=${id_localidad}`).then((d: any) => d.data);
};

const getVacancyId = (id: ID, queryParam: any = undefined): Promise<any> => {
	const query = queryParam ? `id=${id}&${queryParam}` : `id=${id}`;
	return axios.get(`${GET_VACANCY}?${query}`).then((d: any) => d.data);
};
const getCompanyId = (id: ID): Promise<any> => {
	return axios.get(`${GET_COMPANY}?id=${id}`).then((d: any) => d.data);
};
const getCurriculoVagaById = (query: string): Promise<any> => {
	return axios.get(`${GET_CURRICULOVAGABYID}?${query}`).then((d: any) => d.data);
};

const getVagasPostuladas = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGASPOSTULADAS}?${query}`).then((d: any) => d.data);
};

const createVacancy = (vacancy: any): Promise<any> => {
	return axios.post(`${POST_VACANCY}`, vacancy).then((d: any) => d.data);
};

const createVacancyTempral = (vacancy: any): Promise<any> => {
	return axios.post(`${POST_VACANCY_TEMPORAL}`, vacancy).then((d: any) => d.data);
};

const vacancyTempral = (): Promise<any> => {
	return axios.get(`${GET_VACANCY_TEMPORAL}`).then((d: any) => d.data);
};

const putVacancyTempral = (vacancy: any): Promise<any> => {
	return axios.put(`${PUT_VACANCY_TEMPORAL}`, vacancy).then((d: any) => d.data);
};

const deleteVacancyTempral = (): Promise<any> => {
	return axios.delete(`${DELETE_VACANCY_TEMPORAL}`).then((d: any) => d.data);
};

const registerForVacancy = (register: { id_vagas: ID }): Promise<any> => {
	return axios.post(`${POST_VACANCY_REGISTER}`, register).then((d: any) => d.data);
};

const getMeetsByCouselor = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CONSEJERO}?${query}`).then((d: any) => d.data);
};

const getMeetsByCandidate = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CANDIDATE}?${query}`).then((d: any) => d.data);
};

/*const getVerifyFreeDate = (query: string): Promise<any> => {
  return axios.get(`${AGENDA_VERIFY_DATE}?${query}`).then((d: any) => d.data);
};*/

/*const getRandomDate = (): Promise<any> => {
  return axios.get(`${AGENDA_RANDOM_DATE}`).then((d: any) => d.data);
};*/

const meetListUsersAvailables = (filterBy: string, limit: any = null): Promise<any> => {
	return axios
		.get(`${AGENDA_LIST_USERS_AVAILABLES}?filterBy=${filterBy}&limit=${limit}`)
		.then((d: any) => d.data);
};

const listConsuelorMeets = (month: number, year: number): Promise<any> => {
	return axios
		.get(`${AGENDA_LIST_COUSELOR_MEETS}?month=${month}&year=${year}`)
		.then((d: any) => d.data);
};

const listCandidateAvailableMeets = (
	filterBy: string,
	month: number,
	year: number,
	limit: any = null
): Promise<any> => {
	return axios
		.get(
			`${AGENDA_LIST_CANDIDATES_MEETS}?filterBy=${filterBy}&limit=${limit}&month=${month}&year=${year}`
		)
		.then((d: any) => d.data);
};

const updateVacancyStatus = (vacancySingle: { id: ID; status: boolean }): Promise<any> => {
	return axios.put(UPDATE_VACANCY_STATUS, vacancySingle).then((d: any) => d.data);
};

const storeMeetApi = (agenda: any): Promise<any> => {
	return axios.post(`${AGENDA_CREATE}`, agenda).then((response: any) => response.data);
};

const deleteMeetApi = (data: any): Promise<any> => {
	return axios.put(AGENDA_DELETE, data).then((response: any) => response.data);
};

const doneMeetApi = (agenda: any): Promise<any> => {
	return axios.put(`${AGENDA_DONE}`, agenda).then((response: any) => response.data);
};

const getSearchVacantes = (query: string): Promise<any> => {
	return axios.get(`${GET_SEARCH_VACANTES}?${query}`).then((d: any) => d.data);
};

const downloadZipAllFilesByCompany = (query: string): Promise<any> => {
	return axios
		.get(`${DOWNLOAD_ZIP_ALL_FILES_COMPANY}?${query}`, { responseType: 'blob' })
		.then((d: any) => d);
};

const downloadZipFileByCompany = (query: string): Promise<any> => {
	return axios
		.get(`${DOWNLOAD_ZIP_FILE_COMPANY}?${query}`, { responseType: 'blob' })
		.then((d: any) => d);
};

const getFilesCompany = (query: string): Promise<any> => {
	return axios.get(`${GET_FILES_BY_COMPANY}?${query}`).then((d: any) => d.data);
};

const deleteFileCompany = (query: string): Promise<any> => {
	return axios.delete(`${DELETE_FILE_COMPANY}?${query}`).then((d: any) => d.data);
};

const getCargos = (): Promise<any> => {
	return axios.get(`${GET_CARGOS}`).then((d: any) => d.data);
};

// listar parametros
const getParametos = (
	subtype: String,
	params?: AxiosRequestConfig<any>['params']
): Promise<ParameterConfigMmodel[]> => {
	return axios
		.get(`${GET_PARAMETROS}/${subtype}`, {
			params: params,
		})
		.then((d: any) => d.data);
};

const getParametros = (
	subtype: string,
	params?: AxiosRequestConfig<any>['params']
): Promise<any> => {
	// Devuelve `any` para evitar problemas de tipos
	return axios
		.get(`${GET_PARAMETROS}/${subtype}`, {
			params: params,
		})
		.then((response: any) => response.data); // Devuelve `response.data` directamente
};

const getNivelesEducativos = (): Promise<any> => {
	return axios.get(`${GET_NIVELES_EDUCATIVOS}`).then((d: any) => d.data);
};

const getNucleosConocimiento = (): Promise<any> => {
	return axios.get(`${GET_NUCLEOS_CONOCIMIENTO}`).then((d: any) => d.data);
};

const getNivelesNucleos = (): Promise<any> => {
	return axios.get(`${GET_NIVELES_NUCLEOS}`).then((d: any) => d.data);
};

const getTitulosHomologados = (): Promise<any> => {
	return axios.get(`${GET_TITLOS_HOMOLOGADOS}`).then((d: any) => d.data);
};

const getAreasDesempenio = (): Promise<any> => {
	return axios.get(`${GET_AREAS_DESEMPENIO}`).then((d: any) => d.data);
};

const updatePostulationStatus = (postulationSingle: { id: ID; status: string }): Promise<any> => {
	return axios.put(PUT_CURRICULOVAGA_STATUS, postulationSingle).then((d: any) => d.data);
};

const submitCandidate = (id: ID, idCandidato: ID): Promise<any> => {
	return axios.post(`${POST_REMITIDO}/${id}/${idCandidato}`).then((d: any) => d.data);
};

const downloadPdfMasivo = (vaga_id: any, candidato_id: any): Promise<any> => {
	return axios
		.post(
			`${POST_PDF_MASIVO}/${vaga_id}/usersCandidato`,
			{ candidato_id },
			{
				responseType: 'blob',
			}
		)
		.then((d: any) => d.data);
};

const updateVacancyStatusApproval = (postulationSingle: {
	id: ID;
	status_approval: string;
	description: string;
}): Promise<any> => {
	return axios.put(PUT_VACANCYSTATE_APPROVAL, postulationSingle).then((d: any) => d.data);
};

const getAllCIUUCodes = (): Promise<any> => {
	return axios.get(`${GET_CODIGOS_CIUU}`).then((d: any) => d.data);
};

const getSectorByCIUU = (ciuu: string): Promise<any> => {
	return axios.get(`${GET_SECTOR_BY_CODIGO_CIUU}?ciuu=${ciuu}`).then((d: any) => d.data);
};

const getBitacorasAll = (query: string): Promise<any> => {
	return axios.get(`${GET_BITACORASALL}?${query}`).then((d: any) => d.data);
};

const putBitacorasStatus = (bitacoraStatus: {
	id: string;
	status: string;
	detalle: string;
}): Promise<any> => {
	return axios.put(PUT_BITACORASTATUS, bitacoraStatus).then((d: any) => d.data);
};

const createBitacora = async (data: any): Promise<any> => {
	return axios.post(`${POST_BITACORA}`, data).then((d: any) => d.data);
};

const getBitacora = (query: string): Promise<any> => {
	return axios.get(`${GET_BITACORA}?${query}`).then((d: any) => d.data);
};

const putBitacora = (data: any): Promise<any> => {
	return axios.put(`${PUT_BITACORA}`, data).then((d: any) => d.data);
};

const deleteBitacora = (bitacoraToDelete: { id: string }): Promise<any> => {
	return axios
		.delete(DELETE_BITACORA, { data: bitacoraToDelete })
		.then((response: any) => response.data);
};
export {
	getUsers,
	getUsersCurriculo,
	deleteUser,
	deleteSelectedUsers,
	getUserById,
	createUser,
	updateUser,
	getCompanies,
	getAccompaniment,
	getAccompanimentById,
	getVacantesPostuladas,
	getListaVacantesPostuladas,
	putStatusAcompaniment,
	postCreateAccompaniment,
	updateAccompaniment,
	getAllCompanies,
	getCompaniesCounselor,
	getPreforma,
	putPreforma,
	deletePreforma,
	createCompany,
	createCurriculo,
	createVacancy,
	createVacancyTempral,
	vacancyTempral,
	putVacancyTempral,
	deleteVacancyTempral,
	getCurriculoById,
	getCandidatTrazabilidad,
	// getExcelTrazabilidad,
	// getPdfTrazabilidad,
	getArchivoTrazabilidad,
	reportesDetallados,
	getVagaCurriculoById,
	getVacanteWordpress,
	getCurriculoVagaById,
	getVacancyId,
	getUserCompelmentoId,
	registerForVacancy,
	getUserDataById,
	updateStatusUserToActive,
	updateStatusUserToDesactive,
	deleteUserById,
	deleteVacancyById,
	updateVacancy,
	updateUserPut,
	createUserPost,
	getCompanyId,
	updateCompany,
	updateUserComplementoPut,
	deleteEmpresaById,
	deleteAcompaniment,
	getMeetsByCouselor,
	getMeetsByCandidate,
	updateStatusEmpresa,
	emailCandidateEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
	updateVacancyStatus,
	getSearchVacantes,
	getVagaEmpresaCurriculoAll,
	getVagaPostulaCurriculoAll,
	getVagaCountCurriculoAll,
	getVacantes,
	getListVacantes,
	getUserCurriculoAll,
	getListOfTrainingPrograms,
	getListarTiposDeConvocatorias,
	getConvocatoriaById,
	postCreateConvicatoria,
	getListOfCalls,
	deleteConvocatoria,
	updateConvocatoria,
	getParticipantesDeLaConvocatoria,
	PostProcesoDeCargue,
	getUnSoloCertificadoDeUnaConvocatoria,
	getCetificadosDeUnaConvocatoria,
	getTemplateExcel,
	deleteParticipantes,
	putAprobarParticipantes,
	getAcompanimentById,
	finalizarConvocatoria,
	getListarAcompanamientos,
	postCreateAcompanamento,
	getCerrarAcompanamiento,
	getlistadoDeCandidatos,
	deleteAcompanamiento,
	updateAcompanmientoCandidato,
	getListaDeParametros,
	getListaDeValores,
	getValores,
	postCrearValor,
	putValor,
	getListaDireccionamientos,
	getTipos,
	postCrearDireccionamiento,
	getDireccionamientoById,
	updateDireccionamiento,
	deleteDireccionamiento,
	getListadoDeOrientadosById,
	getExcelDireccionamiento,
	PostProcesoDeCargueOrientados,
	deleteParticipantesOrientados,
	finalizarDireccionamiento,
	getCandidato,
	postParticipanteIndividual,
	getVRD,
	postRespuestas,
	getRespuestas,
	postPonderar,
	getVagasPostuladas,
	storeMeetApi,
	deleteMeetApi,
	doneMeetApi,
	meetListUsersAvailables,
	listConsuelorMeets,
	downloadZipAllFilesByCompany,
	getLocalidades,
	getAgendaVagaCurriculoAll,
	getAgendaCurriculoById,
	feedbackCandidate,
	getBarriosByLocalidad,
	getCargos,
	getNivelesEducativos,
	getNucleosConocimiento,
	getNivelesNucleos,
	getTitulosHomologados,
	getAreasDesempenio,
	getParametos,
	getParametros,
	updatePostulationStatus,
	submitCandidate,
	downloadPdfMasivo,
	listCandidateAvailableMeets,
	updateVacancyStatusApproval,
	getAllCIUUCodes,
	getSectorByCIUU,
	getUsersConsejeroEmpresarial,
	getUsersConsejeroCandidato,
	createInformacionAdicionalEmpresa,
	updateInformacionAdicionalEmpresa,
	getInformacionAdicionalEmpresa,
	createInformacionAdicionalVacante,
	getInformacionAdicionalVacante,
	updateInformacionAdicionalVacante,
	downloadZipFileByCompany,
	getFilesCompany,
	deleteFileCompany,
	getBitacorasAll,
	putBitacorasStatus,
	createBitacora,
	getBitacora,
	putBitacora,
	deleteBitacora,
};
