import { useEffect, useState } from "react"
import { ParameterConfigMmodel } from "../../../models/parameter.model"
import parameters from "../../../util/parameters";
import parameterSingleton from "../../../services/Parameters.service";


const useEmployerInfoParams = () => {

    const [listTipoDocumentoEmpresa, setlistTipoDocumentoEmpresa] = useState<ParameterConfigMmodel[]>([]);
    const [listNaturalezaEmpresa, setlistNaturalezaEmpresa] = useState<ParameterConfigMmodel[]>([]);
    const [listTipoEmpresa, setlistTipoEmpresa] = useState<ParameterConfigMmodel[]>([]);
    const [listTamanoEmpresa, setlistTamanoEmpresa] = useState<ParameterConfigMmodel[]>([]);
	const [listProgramasCorporativos, setListProgramasCorporativos] = useState<ParameterConfigMmodel[]>([]);
	const [listInteresesEmpresa, setListInteresesEmpresa] = useState<ParameterConfigMmodel[]>([]);

    const [loadingParams, setloadingParams] = useState(true);

    useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchListTipoDocumentoEmpresa();
		await fetchListNaturalezaEmpresa();
		await fetchListTipoEmpresa();
		await fetchlistTamanoEmpresa();
		await fetchListProgramasCorporativos();
		await fetchlistInteresesEmpresa();
		setloadingParams(false);
	};

	const fetchListProgramasCorporativos = async () => {
		const res = await parameterSingleton.fetchData(parameters.PROGRAMASCORPORATIVOS);
		setListProgramasCorporativos(res);
	}

	const fetchListTipoDocumentoEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPODOCUMENTOEMPRESA);
		setlistTipoDocumentoEmpresa(res);
	};

	const fetchListNaturalezaEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.NATURALEZAEMPRESA);
		setlistNaturalezaEmpresa(res);
	};

    const fetchListTipoEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOEMPRESA);
		setlistTipoEmpresa(res);
	};

    const fetchlistTamanoEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.TAMANOEMPRESA);
		setlistTamanoEmpresa(res);
	};

	const fetchlistInteresesEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.INTERESESEMPRESA);
		setListInteresesEmpresa(res);
	};

    return {
        listTipoDocumentoEmpresa, 
        listNaturalezaEmpresa, 
        listTipoEmpresa, 
        listTamanoEmpresa,
		loadingParams, 
		listProgramasCorporativos,
		listInteresesEmpresa
    }

}

export default useEmployerInfoParams