import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	TextField,
	FormLabel,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Alert,
	Snackbar,
	AlertTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { PageTitle } from '../../../_metronic/layout/core';
import {
	getAgendaCurriculoById,
	feedbackCandidate,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { initValue } from '../../util/Index';
import { ResumeCandidatoSkeleton } from './ResumenCandidatoSkeleton';
import { LoadingBackdrop } from '../LoadingSpinner/LoadingSpinner';

const ResumeCandidatoPage = () => {
	const parms = useParams();
	const { currentUser } = useAuth();
	const navigate = useNavigate();
	const [checkList, setCheckList] = useState('');
	const [isSelected, setIsSelected] = useState(false);
	const [resume, setResume] = useState<any>({});
	interface StatusCompanyProps {
		id: number;
		id_Empresa?: number;
		id_Vacancy?: number;
		observaciones?: string;
		id_aprobado?: boolean;
		id_desaprobado?: boolean;
		id_abandonar?: boolean;
	}
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [companyData, setCompanyData] = useState<StatusCompanyProps>({
		id: 0,
		id_aprobado: false,
		id_desaprobado: false,
		id_abandonar: false,
	});
	const [openExperience, setOpenExperience] = useState(true);
	const [openEducation, setOpenEducation] = useState(true);
	const [openOccupationalInterest, setOpenOccupationalInterest] = useState(true);
	const [openKnowledge, setOpenKnowledge] = useState(true);
	const [openLanguages, setOpenLanguages] = useState(true);

	//MODAL
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => setOpenModal(false);
	const [messageModal, setMessageModal] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingCV, setLoadingCV] = useState(false);

	useEffect(() => {
		fetchData().catch(console.error);
	}, []);

	const fetchData = async () => {
		//console.log(parms);
		if (parms.id !== undefined && parms.id_empresa !== undefined && parms.id_vacancy) {
			setLoadingCV(true);
			const response = await getAgendaCurriculoById(`id_curriculo=${parms.id}&id_empresa=${parms.id_empresa}&id_vacancy=${parms.id_vacancy}`);
			try {
				if (response && response?.data) {
					setResume(response?.data[0]);
				}
				if (parms.id_empresa !== undefined) {
					setCompanyData({
						...companyData,
						id: Number(parms.id),
						id_Empresa: Number(parms.id_empresa),
						id_Vacancy: Number(parms.id_vacancy),

					});
				}
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error)
					setAlert(false);
					setAlertMsg(error.response.data.error);
					setOpen(true)
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoadingCV(false);
			}
		}
	};
	const handleOpenModal = () => {
		setOpenModal(true);
		setCompanyData({
			...companyData,
			id_abandonar: resume.id_abandonar,
			id_desaprobado: resume.id_desaprobado,
			id_aprobado: resume.id_aprobado,
			observaciones: resume.observaciones,

		});
		if (resume.id_abandonar) {
			setIsSelected(true);
			setCheckList('id_abandonar');
		}
		if (resume.id_desaprobado) {
			setIsSelected(true);
			setCheckList('id_desaprobado');
		}
		if (resume.id_aprobado) {
			setIsSelected(true);
			setCheckList('id_aprobado');
		}
	}
	const handleClose = () => {
		setOpen(false);
	};
	const changeCheckEhtnic = (e: any) => {
		if (e.target.checked) {
			!isSelected && setIsSelected(e.target.checked);
			setCheckList(e.target.name);
		} else {
			setIsSelected(false);
		}
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		if (!companyData['id_aprobado'] && !companyData['id_desaprobado'] && !companyData['id_abandonar'])
			errors.push({ field: 'seleccionar un estado', error: 'Campo requerido' });
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	}

	const changeEnviaEmail = async () => {
		const retorno = handleValidOnSubmit();
		if (retorno) {
			try {
				setLoading(true);
				setCompanyData({
					...companyData,
					id: Number(parms.id),
					id_Empresa: Number(parms.id_empresa),
					id_Vacancy: Number(parms.id_vacancy),
				});

				const response = await feedbackCandidate(companyData);
				setAlertMsg(response.data.message);
				setResume({
					...resume,
					id_abandonar: companyData.id_abandonar,
					id_aprobado: companyData.id_aprobado,
					id_desaprobado: companyData.id_desaprobado,
					observaciones: companyData.observaciones,
				})
				setOpen(true);
				setAlert(true);
				setMessageModal('');
				handleCloseModal();
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
					setTimeout(() => {
						setOpen(false);
					}, 2000);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
				handleClose();
			} finally {
				setLoading(false);
			}
		}
	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				{loadingCV ? (<ResumeCandidatoSkeleton />) : (
					<>
						<div className='row'>
							<div className='col-sm-1'>
								<button
									onClick={() => navigate(-1)}
									className='btn btn-primary'
									style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
								>
									<i
										className='bi bi-arrow-left'
										style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
									></i>
								</button>
							</div>
							<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
								<h2 style={{ display: 'flex', gap: '10px' }}>Hoja de vida</h2>
								{/* <span style={{}}>Todos os dados laborais de lo candidato.</span> */}
							</div>
							{/* <div className='col d-flex justify-content-end'>
						<Link to='/empleador/area' className='btn btn-primary' style={{ padding: '8px 20px' }}>
							Visión general
						</Link>
					</div> */}
						</div>
						<div className='row mt-16'>
							<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
								<h3 style={{ display: 'flex', gap: '10px' }}>
									{initValue(resume.primer_nome) +
										' ' +
										initValue(resume.segundo_nome)}
								</h3>
								<span style={{}}>
									{initValue(resume.email)} {'    '}{' '}
									{initValue(resume.celular)}
								</span>
							</div>
							<div className='col d-flex justify-content-end'>
								{currentUser?.user?.role !== 3 && (
									<button
										onClick={handleOpenModal}
										className='btn btn-primary'
										style={{ padding: '8px 20px', background: '#153E7B' }}
									>
										Feedback
									</button>
								)}
							</div>
						</div>
						{/* Experiencia */}
						<div className='row mt-10'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Datos de la Entrevista'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenExperience(!openExperience)}
											aria-label='expand'
											size='small'
										>
											{openExperience ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openExperience} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='mt-10' >
												<h6> </h6>
												<ul className='mt-2'>
													<div className='row'>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																<b>Nombre de la vacancy:</b> {initValue(resume.nombre_vacante)}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>observaciones:</b> {initValue(resume.observaciones)}
															</li>
														</div>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																<b>fecha de contacto: </b>{initValue(resume.createdAt)}
															</li>

														</div>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																{resume.id_aprobado !== null && resume.id_aprobado !== 0 && resume.id_aprobado &&
																	<b>Estado:   Contratado</b>
																}
																{resume.id_desaprobado !== null && resume.id_desaprobado !== 0 && resume.id_desaprobado &&
																	<b>Estado:   Descartado </b>

																}
																{resume.id_abandonar !== null && resume.id_abandonar !== 0 && resume.id_abandonar &&
																	<b>Estado:   No compareció </b>
																}
																{!resume.id_aprobado && !resume.id_desaprobado && !resume.id_abandonar &&
																	<b>Estado: </b>
																}

															</li>
														</div>
													</div>
												</ul>
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
					</>)}
			</div>

			{/* MODAL */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{ border: 'none' }}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<LoadingBackdrop loading={loading} />
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						Comentarios sobre el candidato
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						Comentarios sobre la entrevista
					</Typography>
					<FormLabel
						sx={{ fontSize: 14, fontWeight: 'bold', color: '#2B2B2B', marginTop: 2 }}
					>
						Estado
					</FormLabel>
					<FormGroup aria-label='position' row>
						<FormControlLabel
							value='id_aprobado'
							control={
								<Checkbox
									name='id_aprobado'
									color='default'
									checked={initValue(companyData['id_aprobado'])}
									value={initValue(companyData['id_aprobado'])}
									disabled={isSelected ? checkList !== 'id_aprobado' : false}
									onChange={(e) => {
										setCompanyData({
											...companyData,
											id_aprobado: e.target.checked,
										});
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='Contratado'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='id_desaprobado'
							control={
								<Checkbox
									name='id_desaprobado'
									color='default'
									checked={companyData['id_desaprobado']}
									value={companyData['id_desaprobado']}
									disabled={isSelected ? checkList !== 'id_desaprobado' : false}
									onChange={(e) => {
										setCompanyData({ ...companyData, id_desaprobado: e.target.checked });
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='Descartado'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='id_abandonar'
							control={
								<Checkbox
									name='id_abandonar'
									disabled={isSelected ? checkList !== 'id_abandonar' : false}
									color='default'
									checked={companyData['id_abandonar']}
									value={companyData['id_abandonar']}
									onChange={(e) => {
										setCompanyData({ ...companyData, id_abandonar: e.target.checked });
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='No compareció'
							labelPlacement='end'
						/>
					</FormGroup>
					<TextField
						className='mt-5 mb-5'
						fullWidth
						rows={10}
						id='observaciones'
						name='observaciones'
						label='Observaciones'
						value={initValue(companyData['observaciones'])}
						onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
						size='medium'
						multiline
						inputProps={{ MaxLength: 500 }}
						disabled={loading}
						helperText={`${companyData['observaciones'] === undefined
							? '0'
							: companyData['observaciones']?.length
							}/${500}`}
					// helperText={`${funcionesLogros === undefined ? '0' : funcionesLogros?.length}/${2000}`}
					/>

					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-primary'
							onClick={() => changeEnviaEmail()}
							disabled={
								companyData.observaciones && companyData.observaciones.length > 0 && !loading
									? false
									: true
							}
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							Salvar
						</button>
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							Cancelar
						</button>
					</div>
				</Box>
			</Modal>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const ResumeCandidatoComponent = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Hoja de vida</PageTitle>
			<ResumeCandidatoPage />
		</>
	);
};

export default ResumeCandidatoComponent;
