import { AxiosRequestConfig } from 'axios';
import { ParameterConfigMmodel } from '../models/parameter.model';
import {
	getLocalidades,
	getParametos,
} from '../modules/apps/user-management/users-list/core/_requests';
import { isValidFieldRequired } from '../util/Index';
import parameters from '../util/parameters';
const LOCALIDADES: string = 'LOCALIDADES';

class ParametersService {
	private static instance: ParametersService | undefined = undefined;
	solvedRequest: Map<string, any>;

	private constructor() {
		this.solvedRequest = new Map();
	}

	public static getInstance(): ParametersService {
		if (ParametersService.instance === undefined) {
			ParametersService.instance = new ParametersService();
		}
		return ParametersService.instance;
	}

	public fetchLocalidades = async () => {
		if (this.__isSolvedRequest(LOCALIDADES)) {
			return this.solvedRequest.get(LOCALIDADES);
		}

		try {
			const response: any = await getLocalidades();
			this.solvedRequest.set(LOCALIDADES, response.data);
			return response.data;
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud de Localidades.', error);
			}
		}
	};

	public fetchData = async (parameterType: string, reload: boolean=false, params?: AxiosRequestConfig<any>['params']): Promise<ParameterConfigMmodel[]> => {
		if (this.__isSolvedRequest(parameterType) && reload == false) {
			return this.solvedRequest.get(parameterType);
		}

		try {
			const response: any = await getParametos(parameterType, params);
			this.solvedRequest.set(parameterType, response.data);

			return response.data;
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
			}
		}
		return [];
	};


	public fetchDataWithParams = async (
		parameterType: string,
		parentType?: string,
		parentId?: string
	): Promise<ParameterConfigMmodel[]> => {
		if (isValidFieldRequired(parentType) && isValidFieldRequired(parentId)) {
			const parameters: string = `${parameterType}?typeParent=${parentType}&codeParent=${parentId}`;
			return this.fetchData(parameters);
		} else {
			return this.fetchData(parameterType);
		}
	};

	public fetchMunicipioById = async (id: string) => {
		return this.fetchDataWithParams(parameters.MUNICIPIO, parameters.DEPARTAMENTO, id);
	};

	public fetchCUODSubgrupoById = async (id: string) => {
		return this.fetchDataWithParams(parameters.CUOCSUBGRUPO, parameters.CUOCGRUPOOCUPACION, id);
	};

	private __isSolvedRequest(parameterType: string): boolean {
		return this.solvedRequest.has(parameterType);
	}
}

const parameterSingleton = ParametersService.getInstance();

export default parameterSingleton;
