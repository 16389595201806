import { useEffect, useState } from 'react';
import styleScss from './styles.module.scss';
import { CardModel } from '../../models/CardModel';
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { getCandidatoMatching } from '../../modules/auth/core/_requests';
import { getVagaCountCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { Alert, Box, CircularProgress, LinearProgress, Snackbar, Typography } from '@mui/material';
import Chatbot from '../../components/Chatbot';
import { truncateWords } from '../../util/Index';
import { useAuth } from '../../modules/auth/core/Auth';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

const EmployerAreaPage = () => {
	const { auth } = useAuth();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [listBestCandidates2, setListBestCandidates2]: any[] = useState([]);
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
	});
	const disableMatching = (/true/).test(process.env.REACT_APP_DISABLE_MATCHING_COMPONENTS || '');

	/**
	 * Llamado al servicio de match de candidatos
	 */
	async function getMatchCandidatos() {
		if (!disableMatching) {
			setLoading(true);
			try {
				const response = await getCandidatoMatching();
				if (response && response?.data) {
					setListBestCandidates2(response.data.results ? response.data.results : []);
				} else {
					setListBestCandidates2([]);
				}
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setIsError(true);
					setMsgAlert(error.response.data.error);
					setTimeout(() => {
						setOpen(false);
					}, 7000);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const getVacancyAPI = async () => {
		try {
			setLoading(true);
			const response = await getVagaCountCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}${filter.name !== '' ? '&vacante=' + filter.name : ''
				}`
			);
			setTotal(response.data.total);
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		getMatchCandidatos();
		getVacancyAPI();
	}, []);

	useEffect(() => {
		//console.log(listBestCandidates2);
	}, [listBestCandidates2]);

	const listManage: CardModel[] = [
		{
			title: 'Gestionar vacantes',
			content: 'Actualiza la información de tus vacantes para recibir los mejores candidatos.',
			action: '/empleador/gestion-vacantes/',
			actionName: 'Ver más',
			icon: 'bi-layout-text-sidebar'
		},
		{
			title: 'Gestionar candidatos',
			content: 'Conoce qué candidatos se han postulado a los trabajos que ofreces.',
			action: '/empleador/gestion-candidatos/0',
			actionName: 'Ver más',
			icon: 'bi-people'
		},
		/*{
			title: 'Gestionar candidatos seleccionados',
			content: 'Actualiza el proceso de selección y el estado de los candidatos entrevistados.',
			action: '/empleador/feedback-candidatos/0',
			actionName: 'Ver más',
			icon: 'bi-people-fill'
		},*/
	];

	const cardCountVacancy: number = total;

	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard de la Empresa',
			content: 'Métricas e indicadores de las vacantes y candidatos.',
			action: '/empleador/dashboard/empresa',
			icon: 'bi-rocket-takeoff-fill'
		},
		{
			title: `${cardCountVacancy} es la cantidad de vacantes`,
			content: 'que tu empresa registró en la plataforma.',
			icon: 'bi-grid-fill'
		}
	];

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	return (
		<>
			{/* <h2>Área del empleador</h2> */}
			<div className='row mt-15'>
				{/* Cards */}
				<div className='col-sm-4 mt-5'>
					{listManage.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px' }}
							key={x.action}
							id={i.toString()}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-3'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>

				{/* Match Mejores candidatos */}
				{!disableMatching && (
					<div className='col-sm-5 mt-5'>
						<div className={`card mb-10 ${styleScss['card-match']}`} style={{ borderRadius: '10px', width: '100%' }}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi bi-person-fill-up ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-5'>
								<h2 className='card-title'>Mejores candidatos</h2>
								<p className='card-text mb-5'>
									Consulta los candidatos con mejores aptitudes para las vacantes que ofreces
								</p>
								{loading ? (
									<LinearLoadingSpinner isLoading={loading} />
								) : listBestCandidates2.length === 0 ? (
									<Typography variant='h5'>{msgAlert}</Typography>
								) : (
									<>
										<table
											className='table table-hover table-responsive'
											style={{ fontSize: '12px' }}
										>
											<thead>
												<tr>
													<th scope='col'>
														<h4>Candidato</h4>
													</th>
													<th scope='col'>
														<h4>Vacante</h4>
													</th>
													<th scope='col'>
														<h4>Match</h4>
													</th>
												</tr>
											</thead>
											<tbody>
												{listBestCandidates2.slice(0, 10).map((x: any, i: number) => (
													<tr key={i} id={i.toString()}>
														<td className={`${styleScss['td-break']}`}>{truncateWords(x.candidato, 25)}</td>
														<td className={`${styleScss['td-break']}`}>{x.codigo_vacante}</td>
														<td>
															<label>{x.matching}%</label>
															<LinearProgress
																variant='determinate'
																color='primary'
																value={normalise(x.matching)}
															/>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<Link
											to='/empleador/matching-candidatos'
											className='btn btn-primary mt-4'
											style={{ padding: '8px 40px' }}
										>
											Ver más
										</Link>
									</>
								)}
							</div>
						</div>
					</div>
				)}
				{/* Dashboards */}
				<div className={`col-sm-${!disableMatching ? '3' : '4'} mt-5`}>
					{listDashboard.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['blue-card']}`} key={i} id={i.toString()}>
							{x.action ? (
								<Link to={x.action!}>
									<div className='card-body mt-3'>
										<i className={`bi ${x.icon} ${styleScss['icon-custom-white']}`}></i>
										<h3 className='card-title text-white mt-2'>{x.title}</h3>
										<p className='card-text text-white'>{x.content}</p>
									</div>
								</Link>
							) : (
								<div className='card-body mt-3'>
									<i className={`bi  ${x.icon} ${styleScss['icon-custom-white']}`}></i>
									<h3 className='card-title text-white mt-2'>{x.title}</h3>
									<p className='card-text text-white'>{x.content}</p>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</>
	);
};

const EmployerAreaWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Área Empleador</PageTitle>
			<EmployerAreaPage />
			<Chatbot />
		</>
	);
};

export { EmployerAreaWrapper };
