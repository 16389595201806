import { useEffect, useState } from 'react';
import parameters from '../../../util/parameters';
import parameterSingleton from '../../../services/Parameters.service';
import { ParameterConfigMmodel } from '../../../models/parameter.model';

const usePublicVacancyParams = () => {
	const [listNivelEducativo, setlistNivelEducativo] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoContrato, setlistTipoContrato] = useState<ParameterConfigMmodel[]>([]);
	const [listSalarioMensual, setListSalarioMensual] = useState<ParameterConfigMmodel[]>([]);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = () => {
		fetchListNivelEducativo();
		fetchListTipoContrato();
        fetchListSalarioMensual();
	};

    const fetchListNivelEducativo = async () => {
		const res = await parameterSingleton.fetchData(parameters.NIVELEDUCATIVO);
		setlistNivelEducativo(res);
	};

	const fetchListTipoContrato = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONTRATO);
		setlistTipoContrato(res);
	};
	const fetchListSalarioMensual = async () => {
		const res = await parameterSingleton.fetchData(parameters.SALARIOMENSUAL);
		setListSalarioMensual(res);
	};

	return {
		listNivelEducativo,
		listTipoContrato,
        listSalarioMensual
	};
};

export default usePublicVacancyParams;
