import { useContext, useEffect, useState } from 'react';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import useAboutVacancyParams from '../../../../../hooks/parameters/vacancy-management/useAboutVacancyParams';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import {
	Grid,
	Box,
	Button,
	Divider,
	Typography,
	Slider
} from '@mui/material';
import { createVacancyTempral, putVacancyTempral } from '../../../../apps/user-management/users-list/core/_requests';
import { concatArrayToStringJoinBy } from '../../../../../util/Index';

export const Step6 = () => {

	const { setStep, userData, setUserData, shouldDisable } = useContext(multiStepContext);
	const { listPesosVacantes, loadingParams } = useAboutVacancyParams();
	const [valorSliders, setValorSliders] = useState<{ [key: number]: number }>({});
	const [VagaPesos] = useState(userData['VagaPesos']);

	const [sumaPesos, setSumaPesos] = useState(0);
	const [pesosValidos, setPesosValidos] = useState(true);
	interface VacantesPesosParametrosConfig {
		id: number;
		type: string;
		code: string;
		value: number;
		label?: string;
	}
	const [nuevaListPesosVacantes, setNuevaListPesosVacantes] = useState<VacantesPesosParametrosConfig[]>([]);

	useEffect(() => {
		if (listPesosVacantes) {
			if (VagaPesos) {
				setNuevaListPesosVacantes(
					listPesosVacantes.map(item => {
						// Buscar en VagaPesos el objeto con el mismo id_parameter
						const matchingItem = VagaPesos.find((vaga: { id_parameter: number; value: number; }) => vaga.id_parameter === item.id);
						// Si se encuentra un objeto coincidente, usar su valor. Si no, usar el valor de item.
						const value = matchingItem ? matchingItem.value : Number(item.value);
						// Devolver un nuevo objeto con el valor actualizado
						return { ...item, value };
					})
				);
			} else {
				let listPesosVacantesNumeros = listPesosVacantes.map(obj => {
					return { ...obj, value: Number(obj.value) };
				});
				setNuevaListPesosVacantes(listPesosVacantesNumeros);
			}
		}
	}, [listPesosVacantes, VagaPesos]);

	useEffect(() => {
		if (nuevaListPesosVacantes.length > 0) {
			let listNuevaListaPesosVacantes = nuevaListPesosVacantes.reduce((acc, item) => ({ ...acc, [item.id]: item.value }), {});
			setValorSliders(listNuevaListaPesosVacantes);
		}
	}, [nuevaListPesosVacantes]);

	useEffect(() => {
		if (Object.keys(valorSliders).length > 0) {
			const suma = Object.values(valorSliders).reduce((a, b) => a + b, 0)
			setSumaPesos(suma);
		}
	}, [valorSliders]);

	useEffect(() => {
		if (sumaPesos === 100) {
			let listNuevosVagaPesos: { id?: number; id_vaga?: number, id_parameter: number; value: number }[] = [];
			Object.entries(valorSliders).forEach(([parametro_id, valor_slider]) => {
				// Buscar en VagaPesos el objeto con el mismo id_parameter
				const vagaPesosCoincidente = VagaPesos?.find((vaga: { id?: number; id_vaga?: number, id_parameter: number; value: number }) => vaga.id_parameter === parseInt(parametro_id));
				// Si se encuentra la vacante se arma la estructura de update si no la de insert.
				if (vagaPesosCoincidente) {
					listNuevosVagaPesos.push({ id: vagaPesosCoincidente.id, id_vaga: vagaPesosCoincidente.id_vaga, id_parameter: parseInt(parametro_id), value: valor_slider });
				} else {
					listNuevosVagaPesos.push({ id_parameter: parseInt(parametro_id), value: valor_slider });
				}
			});
			setUserData((prevUserData: { [x: string]: any; }) => ({
				...prevUserData,
				VagaPesos: listNuevosVagaPesos
			}));
			setPesosValidos(true);
		} else {
			setPesosValidos(false);
		}
	}, [sumaPesos, VagaPesos, valorSliders, setUserData]);

	const handleSliderChange = (id: number) => (event: any, newValue: any) => {
		setValorSliders(prevValorSliders => {
			const newValorSliders = { ...prevValorSliders, [id]: newValue };
			return newValorSliders;
		});
	};

	const showErros = () => {
		let condicion = false;
		if (Object.keys(valorSliders).length > 0) {
			const suma = Object.values(valorSliders).reduce((a, b) => a + b, 0);
			condicion = suma !== 100;
		}
		return condicion;
	}


	const enviar = () => {
		try {
			const data = { ...userData, poblaciones_aceptadas: concatArrayToStringJoinBy(userData.poblacionesAceptadas, ',', null) }
			const nextStep = () => {
				setStep(7)
			}
			if (data.status_approval === 'TEMPORAL') return putVacancyTempral(data).then((res) => {
				nextStep()
			}).catch((error) => {
				console.log(error);
			});
			else nextStep()

		} catch (error) {
			console.error(error);
		}
	}

	return (
		<>
			{loadingParams
				? <LinearLoadingSpinner isLoading={true} />
				:
				<>
					<Box sx={{ mb: 4 }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }} mb={2}>
							Administración de pesos
						</Typography>
						<Typography variant='inherit'>
							Por favor, asigna pesos a los siguientes parámetros de la vacante hasta sumar 100. Un peso mayor indica mayor importancia del parámetro.
						</Typography>
					</Box>
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
						<Grid container spacing={2}>
							{nuevaListPesosVacantes.map((item, index) => (
								<Grid item xs={12} md={6} key={item.id} mb={2}>
									<Grid container spacing={2}>
										<Grid item xs={3}>
											<Typography variant='inherit'>{item.label}</Typography>
										</Grid>
										<Grid item xs={9}>
											<Slider
												aria-label={item.code}
												defaultValue={Number(item.value)}
												onChange={handleSliderChange(item.id)}
												valueLabelDisplay="on"
												min={0}
												max={100}
												step={1}
												disabled={shouldDisable}
											/>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
						<Typography variant='inherit' style={{ color: showErros() ? 'red' : 'blue' }}>
							Total pesos: {sumaPesos}. {showErros() ? 'Recuerde que la suma de los pesos debe ser 100' : ''}
						</Typography>
					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90 }}
							onClick={() => setStep(5)}
						>
							Atrás
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={() => {
								enviar()
							}}
							disabled={!pesosValidos}
						>
							Siguiente
						</Button>
					</Box>
				</>
			}
		</>
	);
};
