import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	TablePagination,
	IconButton,
	Paper,
	FormControl,
	InputLabel,
	Select,
	Snackbar,
	Alert,
	AlertTitle,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { deleteBitacora, getBitacorasAll, putBitacorasStatus } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { initValue } from '../../util/Index'
import { listPostulacionModel } from '../../models/ListPostulacionModel'
import { updatePostulationStatus } from '../../modules/apps/user-management/users-list/core/_requests'
import VerifiedIcon from '@mui/icons-material/Verified';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react';
import { ParameterConfigMmodel } from '../../models/parameter.model';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';

const EmployerManagementBitacoraWrapperPage = () => {
	const [listTipoBitacora, setListTipoBitacora] = useState<ParameterConfigMmodel[]>([]);
	const { currentUser, auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const { id_postulacion } = useParams();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: ''
	});
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const formatDate = (dateString: any) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
	};
	const formatTime = (dateString: any) => {
		const date = new Date(dateString);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
	};

	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { ...filter, page: newPageNumber };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { ...filter, page: 1, limit: parseInt(event.target.value, 10) }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};
	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
	}, []);
	useEffect(() => {
		fetchListaTipoBitacora();
	}, []);

	useEffect(() => {
		handleSubmit();
	}, [filter]);

	const handleClose = () => {
		setOpen(false);
	};
	const fetchListaTipoBitacora = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOBITACORA);
		setListTipoBitacora(res);
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await getBitacorasAll(
				`page=${filter.page}&limit=${filter.limit}&idPostulacion=${id_postulacion}&estado=${filter.estado}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	/**
	* Retorna el path del usuario acorde al rol para ver resumen de la hoja de vida del candidato postulado
	* @returns 
	*/
	const getPathVerBitacora = (id: any) => {
		if (isAdminRole()) {
			return `/admin/bitacora/${id_postulacion}/${(id !== '') ? id + '/' : ''}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/bitacora/${id_postulacion}/${(id !== '') ? id + '/' : ''}`;
		} else if (isEmployerRole()) {
			return `/empleador/bitacora/${id_postulacion}/${(id !== '') ? id + '/' : ''}`;
		} else {
			return 'auth/*';
		}
	}
	const MenuAction = (props: any) => {

		const { data } = props;
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
		const open = Boolean(anchorEl);
		const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(e.currentTarget)
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const [openModalGestionBitacora, setOpenModalGestionBitacora] = useState(false);
		const [openModalEliminarBitacora, setOpenModalEliminarBitacora] = useState(false);
		const [statusGestionBitacora, setStatusGestionBitacora] = useState('PENDIENTE');
		const [DetalleGestionBitacora, setDetalleGestionBitacora] = useState("");
		const [touchedDescription, setTouchedDescription] = useState(false)
		const [errorDescription, setErrorDescription] = useState(true)

		/**
		 * Llamado servicio API para actualizar la Bitacora
		 * @param statusBitacora
		 */
		const updateBitacora = async (statusBitacora: any) => {
			try {
				setRequestInProgress(true);
				await putBitacorasStatus({ id: data.x.id, status: statusBitacora, detalle: DetalleGestionBitacora });
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				setAlertMsg(`Bitácora actualizada con éxito`)
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 10000);
				setRequestInProgress(false);
			}
		}

		const eliminarBitacora = async () => {
			try {
				setRequestInProgress(true);
				await deleteBitacora({ id: data.x.id });
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				setAlertMsg(`Bitácora eliminada con éxito`)
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 10000);
				setRequestInProgress(false);
			}
		}
		/**
		 * Open modal confirmación gestion bitacora
		 * @param statusApproval 
		 */
		const openConfirmModalGestionBitacora = (statusApproval: any) => {
			setStatusGestionBitacora(statusApproval)
			setOpenModalGestionBitacora(true);
		};

		/**
		 * Close modal confirmación gestion bitacora
		 */
		const handleCloseModalGestionBitacora = () => {
			setStatusGestionBitacora('PENDIENTE')
			setOpenModalGestionBitacora(false);
		};

		/**
		 * Open modal confirmación eliminacion de la bitacora
		 * @param statusApproval 
		 */
		const openConfirmModalEliminarBitacora = () => {
			setOpenModalEliminarBitacora(true);
		};

		/**
		 * Close modal confirmación eliminacion de la bitacora
		 */
		const handleCloseModalEliminarBitacora = () => {
			setOpenModalEliminarBitacora(false);
		};

		return (
			<>
				<Button aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem onClick={handleClose}>
						<Link to={getPathVerBitacora(data.x.id)}>
							{(data.x.status === 'PENDIENTE') ? (
								<>
									<BorderColorIcon />
									&nbsp; Editar bitácora
								</>
							) : (
								<>
									<RemoveRedEyeIcon />
									&nbsp; Ver bitácora
								</>
							)}
						</Link>
					</MenuItem>
					{(data.x.status === 'PENDIENTE') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => openConfirmModalGestionBitacora('REALIZADA')}>
								<VerifiedIcon />
								&nbsp; Realizada
							</a>
						</MenuItem>
					)}
					{(data.x.status === 'PENDIENTE') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => openConfirmModalEliminarBitacora()}>
								<DeleteIcon />
								&nbsp; Eliminar bitácora
							</a>
						</MenuItem>
					)}
				</Menu>
				{/* Dialog confirmar gestion bitácora */}
				<Dialog
					open={openModalGestionBitacora}
					keepMounted
					onClose={handleCloseModalGestionBitacora}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Gestiónar la bitácora'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro que desea marcar como realizada esta bitácora?
						</DialogContentText>
						<DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
							Para marcar como realizada esta bitácora, es necesario suministrar un detalle de la misma
						</DialogContentText>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								sx={{ width: 500, height: 250 }}
								rows={10}
								id='detalle'
								name='detalle'
								label='Detalle'
								required
								value={initValue(DetalleGestionBitacora)}
								onChange={(e) => {
									setDetalleGestionBitacora(e.target.value);
									setTouchedDescription(true);
									(e.target.value?.length >= 100) ? setErrorDescription(false) : setErrorDescription(true);
								}}
								onTouchStart={() => setTouchedDescription(true)}
								size='medium'
								multiline
								inputProps={{ maxLength: 1200 }}
								disabled={loading}
								helperText={(errorDescription && touchedDescription) ? `${DetalleGestionBitacora === undefined ? '0' : DetalleGestionBitacora?.length}/${1200} El detalle de bitácora debe ser mayor a 100 carácteres*` : `${DetalleGestionBitacora === undefined ? '0' : DetalleGestionBitacora?.length}/${1200}`}
								error={errorDescription && touchedDescription}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalGestionBitacora}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => updateBitacora(statusGestionBitacora)}
							autoFocus
							disabled={(loading || errorDescription || DetalleGestionBitacora === undefined || DetalleGestionBitacora.trim()==='') ? true : false}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={openModalEliminarBitacora}
					keepMounted
					onClose={handleCloseModalEliminarBitacora}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Confirmación eliminar bitácora'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro que desea eliminar esta bitácora?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalEliminarBitacora}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => eliminarBitacora()}
							autoFocus
							disabled={(loading) ? true : false}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	// const [textoBuscar, setTextoBuscar] = useState('');

	// const filtraTextoBuscar = () => {
	// 	setPage(0);
	// 	setFilter({ ...filter, page: 1 });
	// }

	// const handleChageTextoBuscar = (event: any) => {
	// 	setTextoBuscar(event.target.value);
	// 	setUserData({ ...userData, nombre_candidato: event.target.value});
	// }

	// const handleKeyDownEnterTextoBuscar = (event: any) => {
	// 	if (event.key === 'Enter') {
	// 		filtraTextoBuscar();
	// 	}
	// }

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4'>
					<div className='col-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175 }} >
									<InputLabel id='estado'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Estado</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='estado'
										id='estado'
										label='Estado'
										value={initValue(filter.estado)}
										onChange={(e) => {
											setPage(0);
											setFilter({ ...filter, page: 1, estado: e.target.value });
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='PENDIENTE'>Pendiente</MenuItem>
										<MenuItem value='REALIZADA'>Realizada</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
								<Link to={getPathVerBitacora(0)}
									className='btn btn-primary btn-lg'
									role="button">Nueva bitácora</Link>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.lenght !== 0 && (
					<TableContainer component={Paper}>
						<Table className={`table table-responsive table-striped`}>

							<TableHead className={`${styleScss['th-custom']}`}>
								<TableRow>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Fecha</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Hora</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Responsable</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Tipo</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Estado</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Acciones</h4>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{!loading && data.map((x: any, i: any) => (
									<TableRow key={i} id={i.toString()}>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
											{formatDate(x.fecha)}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
											{formatTime(x.fecha)}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
											{x.entrevistador}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
											{listTipoBitacora.find(item => item.code === x.tipo)?.label}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
											{x.status.charAt(0).toUpperCase() + x.status.slice(1).toLowerCase()}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
											<MenuAction
												data={{ x }} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>

							{data.length === 0 && (
								<tfoot>
									<TableRow>
										<TableCell colSpan={5}>
											<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
												No se encontraron registros
											</p>
										</TableCell>
									</TableRow>
								</tfoot>
							)}
						</Table>
						<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50, 100]}
								component="div"
								style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
								count={total}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={(event, newPage) => handleChangePage(newPage)}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage="Filas por página:"
								labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
								ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
									<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
										<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
											<FirstPageIcon />
										</IconButton>
										<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
											<ChevronLeftIcon />
										</IconButton>
										<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
											<ChevronRightIcon />
										</IconButton>
										<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
											<LastPageIcon />
										</IconButton>
									</div>
								)}
							/>
						</div>
					</TableContainer>
				)}
				{/* <PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/> */}
			</div >
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>

		</>
	);
};

const EmployerManagementBitacoraWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión de bitácoras</PageTitle>
			<EmployerManagementBitacoraWrapperPage />
		</>
	);
};

export { EmployerManagementBitacoraWrapper };
