/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import SVG from 'react-inlinesvg';
import './AuthLayout.css';

const AuthLayout = () => {
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	return (
		<>
			{/* begin::Header */}
			<div className='ContainerAuth'>
				{/* begin::Header */}
				<div className='headergov d-flex flex-column pt-1 h-40px w-100' style={{ background: '#3366C9' }}>
					{/* begin::Logo */}
					<Link to='/' className='mb-1'>
						<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} />
					</Link>
					{/* end::Logo */}
				</div>
				{/* end::Header */}

				<div className='d-flex flex-column flex-lg-row h-100 flex-column-fluid bg-white'>
					{/* begin::Body */}
					<div className='d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 pb-10 order-2 order-lg-2'>
						{/* begin::Form */}
						<div className='d-flex flex-center flex-lg-row-fluid'>
							{/* begin::Wrapper */}
							<div className='w-100 mt-10 mb-10'>
								<Outlet />
							</div>
							{/* end::Wrapper */}
						</div>
						{/* end::Form */}

						{/* begin::Footer */}
						{/*<div className='d-flex flex-center flex-wrap px-5'>
          </div>*/}
						{/* end::Footer */}
					</div>
					{/* end::Body */}

					{/* begin::Aside */}
					<div
						className='w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2 d-flex flex-column flex-lg-row-fluid'
						style={{ backgroundColor: '#02266C' }}
					>
						<div className='d-flex flex-column flex-lg-row-fluid'>
							
							<div className='d-flex flex-column flex-lg-row-fluid p-5 col-12'>
								<div className='row '>
								<img
									style={{
										height: 'auto',
										width: '350px',
										display: 'block',
										marginLeft: 'auto',
										marginRight: 'auto',
										marginTop: '90px',
										marginBottom: '100px'
									}}
									src={toAbsoluteUrl('/media/svg/logo/logo_bogota_trabaja.svg')}
								/>
							</div>
								<div className='row mb-5'>
									<h2 style={{ textAlign: 'center' }} className='text-white'>
										Regístrese
									</h2>
									<span className='text-white' style={{ textAlign: 'center' }}>
										Seleccione el perfil que mejor se adapte a usted como persona<br></br>
										buscando empleo o empresa buscando contratar
									</span>
								</div>
								<div className='row mt-4'>
									<div className='col-sm-8 col-md-6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
										<div
											className={`card mb-10`}
											style={{
												borderRadius: '10px',
												background: 'rgba(217, 217, 217, 0.15)',
												borderColor: '#FFFF',
											}}
										>
											<Link to={'/registration'} className='card-text text-white'>
												<div className='card-body'>
													<h2 className='card-title text-white mt-2'>Soy un candidato</h2>
													Haz clic para registrarte
													<br />
												</div>
											</Link>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-sm-8 col-md-6 justify-content-center' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
										<div
											className={`card mb-10`}
											style={{
												borderRadius: '10px',
												background: 'rgba(217, 217, 217, 0.15)',
												borderColor: '#FFFF',
											}}
										>
											<Link to={'/registro-de-empresa'} className='card-text text-white'>
												<div className='card-body'>
													<h2 className='card-title text-white mt-2'>Soy una empresa</h2>
													Haz clic para registrarte
													<br />
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>

						</div>
						{/* begin::Content */}
						{/*<div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          </div>*/}
						{/* end::Content */}
					</div>
					{/* end::Aside */}
				</div>
			</div>
		</>
	);
};

export { AuthLayout };
