import { Skeleton } from "@mui/material"

export const ResumeSkeleton = () => {
    return (
        <>
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <Skeleton className="mt-6" variant="text" sx={{ fontSize: '0.8rem' }} width={300} />
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <Skeleton variant="rectangular" width={'100%'} height={117} />

            {/* Experiencia laboral */}
            <Skeleton className="mt-5" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <div className='row mt-3'>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
            </div>

            {/* Educación */}
            <Skeleton className="mt-5" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <div className='row mt-3'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                </div>
                <div className='col-md-6 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                </div>
            </div>

            {/* Capacitaciones y Certificaciones */}
            <Skeleton className="mt-5" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <div className='row mt-3'>
                <div className='col-md-6 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                </div>
            </div>

            {/* Interés ocupacional */}
            <Skeleton className="mt-5" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <Skeleton variant="rectangular" width={'100%'} height={117} />

            {/* Conocimientos */}
            <Skeleton className="mt-5" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />
            <Skeleton variant="rectangular" width={'100%'} height={117} />
        </>
    )
}
