import { FormEvent, useContext, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Snackbar } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { register } from '../../../core/_requests';

export function Step5() {
	const { setStep, firstUser, setRequestInProgress } = useContext(multiStepContext);
	const navigate = useNavigate();

	const [verified, setVerified] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS = process.env.REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS || '';
	const REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES = process.env.REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES || '';
	const REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA = process.env.REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA || '';

	const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
		setLoading(true);
		try {
			setRequestInProgress(true);
			const response = await register(firstUser);
			setIsError(false);
			setMsgAlert(response.data.message);
			setOpen(true);
			setTimeout(() => {
				setLoading(false);
				setRequestInProgress(false);
				navigate('/auth');
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				setIsError(true);
				setMsgAlert(`${error.response.data.error}`);
				setOpen(true);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			setLoading(false);
			setRequestInProgress(false);
		}
	}

	function onChange(value: any) {
		setVerified(true);
	}

	return (
		<Box>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
				<ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} hl='es' onChange={onChange} />
			</div>
			{' '}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: 60,
					marginBottom: 30,
					width: '100%',
					fontSize: 14,
					textAlign: 'justify'
				}}
			>
				<div style={{ textAlign: 'justify' }}>
					<div>
						Al enviar el formulario de registro estás aceptando la{' '}
						<a href={`${REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS}`}
							target='_blank' rel='noopener noreferrer'>
							Política de Privacidad y Tratamiento de Datos
						</a>{' '}
					</div>
					<div>
						Personales, el{' '}
						<a href={`${REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES}`}
							target='_blank' rel='noopener noreferrer'>
							"Manual de Políticas y Procedimientos para el tratamiento de Datos Personales"
						</a>{' '}
						de la
					</div>
					<div>
						Secretaria General de la Alcaldia Mayor de Bogotá, D.C. y la{' '}
						<a href={`${REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA}`}
							target='_blank' rel='noopener noreferrer'>
							Política de Privacidad de GOBIERNO ABIERTO BOGOTÁ
						</a>{' '}
					</div>
				</div>
			</div>
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					disabled={loading}
					variant='contained'
					sx={{ mr: 1, width: 90 }}
					onClick={() => setStep(4)}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Link to='/auth'>
					<Button
						disabled={!verified || loading}
						variant='contained'
						sx={{ mr: 1, width: 90 }}
						onClick={handleSubmit}
					>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							{loading && <CircularProgress size={20} sx={{ marginRight: 2, color: 'white' }} />}
							{'Enviar'}
						</span>
					</Button>
				</Link>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</Box>
	);
}
