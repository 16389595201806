import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ID, Response } from '../../../../../../_metronic/helpers';
import { User, UsersQueryResponse } from './_models';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_URL_OF = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;
const GET_USERS_URL = `${API_URL}/users/query`;
const GET_USERS = `${API_URL_OF}/users/`;
const GET_USERS_COUNSELOR_EMPRESA = `${API_URL_OF}/counselors_enterprise/`;
const PUT_ACTIVE_USER = `${API_URL_OF}/activateUser/`;
const PUT_DESACTIVE_USER = `${API_URL_OF}/deactivateUser/`;
const GET_USERCOMPLEMETO = `${API_URL_OF}/userCompelmento`;
const GET_COMPANIES = `${API_URL_OF}/empresas/`;
const GET_ALL_COMPANIES = `${API_URL_OF}/listEmpresas`;
const GET_COMPANIES_COUNSELOR = `${API_URL_OF}/listEmpresasConselheiro`;
const GET_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const PUT_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const DELETE_PREFORMA = `${API_URL_OF}/empresa_vaga/preforma`;
const GET_COMPANY = `${API_URL_OF}/empresasByid/`;
const POST_COMPANY = `${API_URL_OF}/empresa/`;
const POST_UPDATECOMPANY = `${API_URL_OF}/empresa_update/`;
const GET_USER_BY_ID = `${API_URL_OF}/userId`;
const GET_VACANCY = `${API_URL_OF}/empresa_vaga/`;
const POST_VACANCY = `${API_URL_OF}/empresa_vaga`;
const POST_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const GET_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const PUT_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const DELETE_VACANCY_TEMPORAL = `${API_URL_OF}/empresa_vaga/temporal`;
const DELETE_VACANCY = `${API_URL_OF}/vacanteId`;
const PUT_COMPANY_ID = `${API_URL_OF}/empresaId`;
const POST_FEEDBACK_CANDIDATE = `${API_URL_OF}/feedbackCandidate/`;
const POST_EMPRESA_CANDIDATE = `${API_URL_OF}/empresa_candidate/`;
const PUT_STATUS_EMPRESA = `${API_URL_OF}/updateStatusEmpresa/`;
const PUT_ACTIVE_EMPRESA = `${API_URL_OF}/activateEmpresa/`;
const PUT_DESACTIVE_EMPRESA = `${API_URL_OF}/deactivateEmpresa/`;
const GET_VAGACOUNTCURRICULOALL = `${API_URL_OF}/vagaCountCurriculoAll`;
const GET_VAGAEMPRESACURRICULOALL = `${API_URL_OF}/vagaEmpresaCurriculoAll`;
const GET_AGENDAVAGACURRICULOALL = `${API_URL_OF}/agendaVagaCurriculoAll`;
const GET_AGENDACURRICULOBYID = `${API_URL_OF}/agendaCurriculoById`;
const GET_VAGAPOSTULACURRICULOALL = `${API_URL_OF}/vagaPostulaCurriculoAll`;
const POST_VACANCY_REGISTER = `${API_URL_OF}/curriculoVaga`;
const GET_CURRICULOBYID = `${API_URL_OF}/curriculoById`;
const GET_CURRICULOVAGABYID = `${API_URL_OF}/curriculoVagaByid`;
const GET_VAGASPOSTULADAS = `${API_URL_OF}/vagasPostulatas`;
const GET_SEARCH_VACANTES = `${API_URL_OF}/search_vacantes`;
const GET_VAGACURRICULOBYID = `${API_URL_OF}/wordpress/searchVacantes`;
const GET_VANCANTEWORDPRESS = `${API_URL_OF}/wordpress/searchVacanteId`;
const GET_USERCURRICULOALL = `${API_URL_OF}/allUserCurriculo/`;
const POST_CURRICULO = `${API_URL_OF}/curriculo/`;
const PUT_VACANCY = `${API_URL_OF}/empresa_vaga`;
const CREATE_USER = `${API_URL_OF}/addUser`;
const UPDATE_USER = `${API_URL_OF}/user`;
const GET_ALL_MEET_CONSEJERO = `${API_URL_OF}/agenda_couselor`;
const GET_ALL_MEET_CANDIDATE = `${API_URL_OF}/agenda_candidate`;
const AGENDA_LIST_COUSELOR_MEETS = `${API_URL_OF}/agenda_couselor_busy_dates`;
const AGENDA_LIST_CANDIDATES_MEETS = `${API_URL_OF}/agenda_candidates_availables`;
const AGENDA_LIST_USERS_AVAILABLES = `${API_URL_OF}/agenda_users_availables`;
const AGENDA_CREATE = `${API_URL_OF}/agenda_create`;
const AGENDA_DELETE = `${API_URL_OF}/agenda_delete`;
const AGENDA_DONE = `${API_URL_OF}/agenda_done`;
const UPDATE_VACANCY_STATUS = `${API_URL_OF}/vacanteStatus`;
const GET_LOCALIDADES = `${API_URL_OF}/localidades`;
const GET_BARRIOS = `${API_URL_OF}/barrios`;
const GET_CARGOS = `${API_URL_OF}/cargos`;
const GET_NIVELES_EDUCATIVOS = `${API_URL_OF}/nivelesEducativos`;
const GET_NUCLEOS_CONOCIMIENTO = `${API_URL_OF}/nucleosConocimiento`;
const GET_NIVELES_NUCLEOS = `${API_URL_OF}/nivelesNucleos`;
const GET_TITLOS_HOMOLOGADOS = `${API_URL_OF}/titulosHomologados`;
const GET_AREAS_DESEMPENIO = `${API_URL_OF}/areasDesempenio`;
const GET_PARAMETROS = `${API_URL_OF}/parameters`;
const PUT_CURRICULOVAGA_STATUS = `${API_URL_OF}/curriculoVagaStatus`;
const POST_REMITIDO = `${API_URL_OF}/validarCurriculoVaga`;
const POST_PDF_MASIVO = `${API_URL_OF}/cv_pdf/empresaVaga`;
// const POST_PDF_MASIVO = `${API_URL_OF}`;
const PUT_VACANCYSTATE_APPROVAL = `${API_URL_OF}/vacanteStatusApproval`;
const GET_CODIGOS_CIUU = `${API_URL_OF}/codigos_ciuu`;
const GET_SECTOR_BY_CODIGO_CIUU = `${API_URL_OF}/sectorBycodigo_ciuu`;
const EMPRESA_INFORMACION_ADICIONAL = `${API_URL_OF}/empresa/extra-info/`;
const VAGA_INFORMACION_ADICIONAL = `${API_URL_OF}/vaga/extra-info/`;

const DOWNLOAD_ZIP_ALL_FILES_COMPANY = `${API_URL_OF}/empresa/archivos_download_zip`;
const DOWNLOAD_ZIP_FILE_COMPANY = `${API_URL_OF}/empresa/archivo_download`;
const GET_FILES_BY_COMPANY = `${API_URL_OF}/empresa/archivos`;
const DELETE_FILE_COMPANY = `${API_URL_OF}/empresa/archivo`;
const GET_BITACORASALL = `${API_URL_OF}/postulacion/bitacoras`;
const PUT_BITACORASTATUS = `${API_URL_OF}/postulacion/bitacora/status`;
const POST_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const GET_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const PUT_BITACORA = `${API_URL_OF}/postulacion/bitacora`;
const DELETE_BITACORA = `${API_URL_OF}/postulacion/bitacora`;

//const AGENDA_VERIFY_DATE = `${API_URL_OF}/verify_date`;
//const AGENDA_RANDOM_DATE = `${API_URL_OF}/random_date`;
//const AGENDA_LIST_COUSELOR = `${API_URL_OF}/list_couselor`;

const getUsers = async (query: string): Promise<any> => {
	return axios.get(`${GET_USERS}?${query}`).then((response: any) => response.data);
};

const getUsersConsejeroEmpresarial = async (): Promise<any> => {
	return axios.get(`${GET_USERS_COUNSELOR_EMPRESA}`).then((response: any) => response.data);
};

const getCompanies = async (query: string): Promise<any> => {
	return axios.get(`${GET_COMPANIES}?${query}`).then((response: any) => response.data);
};

const getAllCompanies = async (): Promise<any> => {
	return axios.get(`${GET_ALL_COMPANIES}`).then((response: any) => response.data);
};

const getCompaniesCounselor = async (): Promise<any> => {
	return axios.get(`${GET_COMPANIES_COUNSELOR}`).then((response: any) => response.data);
};
const getPreforma = async (id: any): Promise<any> => {
	return axios
		.get(`${GET_PREFORMA}`, {
			params: {
				id_empresa: id,
			},
		})
		.then((response: any) => response.data);
};
const putPreforma = (vacancy: any): Promise<any> => {
	return axios.put(`${PUT_PREFORMA}`, vacancy).then((d: any) => d.data);
};
const deletePreforma = (vacancy: any): Promise<any> => {
	return axios
		.delete(`${DELETE_PREFORMA}`, {
			data: {
				id: vacancy,
			},
		})
		.then((d: any) => d.data);
};
const getVagaCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGACURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getVacanteWordpress = (query: string): Promise<any> => {
	return axios.get(`${GET_VANCANTEWORDPRESS}?${query}`).then((d: any) => d.data);
};

const getVagaCountCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGACOUNTCURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getVagaEmpresaCurriculoAll = async (query: string): Promise<any> => {
	return axios.get(`${GET_VAGAEMPRESACURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getAgendaVagaCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_AGENDAVAGACURRICULOALL}?${query}`).then((d: any) => d.data);
};
const getAgendaCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_AGENDACURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getVagaPostulaCurriculoAll = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGAPOSTULACURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getUsersCurriculo = (query: string): Promise<UsersQueryResponse> => {
	return axios
		.get(`${GET_USERS_URL}?${query}`)
		.then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const getUserById = (id: ID): Promise<User | undefined> => {
	return axios
		.get(`${USER_URL}/${id}`)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const getUserDataById = (query: string): Promise<any> => {
	return axios.get(`${GET_USER_BY_ID}?${query}`).then((d: any) => d.data);
};

const deleteUserById = (query: string): Promise<any> => {
	return axios.delete(`${GET_USER_BY_ID}?${query}`).then((d: any) => d.data);
};

const deleteVacancyById = (query: string): Promise<any> => {
	console.log(`${DELETE_VACANCY}?${query}`);
	return axios.delete(`${DELETE_VACANCY}?${query}`).then((d: any) => d.data);
};

const updateStatusUserToActive = (query: string): Promise<any> => {
	return axios.put(`${PUT_ACTIVE_USER}?${query}`).then((d: any) => d.data);
};

const createInformacionAdicionalEmpresa = (empresa: string): Promise<any> => {
	return axios.post(`${EMPRESA_INFORMACION_ADICIONAL}`, empresa).then((d: any) => d.data);
};

const updateInformacionAdicionalEmpresa = (empresa: string): Promise<any> => {
	return axios.put(`${EMPRESA_INFORMACION_ADICIONAL}`, empresa).then((d: any) => d.data);
};

const getInformacionAdicionalEmpresa = (id_empresa: number): Promise<any> => {
	return axios
		.get(`${EMPRESA_INFORMACION_ADICIONAL}?id_empresa=${id_empresa}`)
		.then((d: any) => d.data);
};

const createInformacionAdicionalVacante = (vacante: string): Promise<any> => {
	return axios.post(`${VAGA_INFORMACION_ADICIONAL}`, vacante).then((d: any) => d.data);
};

const updateInformacionAdicionalVacante = (vacante: string): Promise<any> => {
	return axios.put(`${VAGA_INFORMACION_ADICIONAL}`, vacante).then((d: any) => d.data);
};

const getInformacionAdicionalVacante = (id_vaga: number): Promise<any> => {
	return axios.get(`${VAGA_INFORMACION_ADICIONAL}?id_vaga=${id_vaga}`).then((d: any) => d.data);
};

const updateStatusUserToDesactive = (query: string): Promise<any> => {
	return axios.put(`${PUT_DESACTIVE_USER}?${query}`).then((d: any) => d.data);
};

const deleteEmpresaById = (query: string): Promise<any> => {
	return axios.put(`${PUT_COMPANY_ID}?${query}`).then((d: any) => d.data);
};

const updateStatusEmpresa = (data: any): Promise<any> => {
	return axios.put(PUT_STATUS_EMPRESA, data).then((d: any) => d.data);
};

const emailCandidateEmpresa = (data: any): Promise<any> => {
	return axios.post(POST_EMPRESA_CANDIDATE, data).then((d: any) => d.data);
};

const feedbackCandidate = (data: any): Promise<any> => {
	return axios.post(POST_FEEDBACK_CANDIDATE, data).then((d: any) => d.data);
};

const updateEmpresaToActive = (query: string): Promise<any> => {
	return axios.put(`${PUT_ACTIVE_EMPRESA}?${query}`).then((d: any) => d.data);
};

const updateEmpresaToDesactive = (query: string): Promise<any> => {
	return axios.put(`${PUT_DESACTIVE_EMPRESA}?${query}`).then((d: any) => d.data);
};

const createUser = (user: User): Promise<User | undefined> => {
	return axios
		.put(USER_URL, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const createUserPost = (user: any): Promise<any> => {
	return axios.post(CREATE_USER, user).then((d: any) => d.data);
};

const updateUserPut = (user: any): Promise<any> => {
	return axios.put(UPDATE_USER, user).then((d: any) => d.data);
};
const updateUserComplementoPut = async (user: any): Promise<any> => {
	return axios.put(GET_USERCOMPLEMETO, user).then((d: any) => d.data);
};

const updateUser = (user: User): Promise<User | undefined> => {
	return axios
		.post(`${USER_URL}/${user.id}`, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const updateVacancy = (data: any): Promise<any> => {
	return axios.put(`${PUT_VACANCY}`, data).then((d: any) => d.data);
};

const deleteUser = (userId: ID): Promise<void> => {
	return axios.delete(`${USER_URL}/${userId}`).then(() => {});
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
	const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
	return axios.all(requests).then(() => {});
};

const getUserCompelmentoId = (id: ID): Promise<any> => {
	return axios.get(`${GET_USERCOMPLEMETO}?id=${id}`).then((d: any) => d.data);
};

const updateCompany = async (company: any): Promise<any> => {
	return axios.post(`${POST_UPDATECOMPANY}`, company).then((d: any) => d.data);
};

const createCompany = async (company: any): Promise<any> => {
	return axios.post(`${POST_COMPANY}`, company).then((d: any) => d.data);
};

const createCurriculo = (curriculo: any): Promise<void> => {
	return axios.post(`${POST_CURRICULO}`, curriculo).then((d: any) => d.data);
};

const getUserCurriculoAll = async (query: string): Promise<any> => {
	return axios.get(`${GET_USERCURRICULOALL}?${query}`).then((d: any) => d.data);
};

const getCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_CURRICULOBYID}?${query}`).then((d: any) => d.data);
};

const getLocalidades = (): Promise<any> => {
	return axios.get(`${GET_LOCALIDADES}`).then((d: any) => d.data);
};

//Obtiene la informacion de los barrios segun la localidad
const getBarriosByLocalidad = (id_localidad: number): Promise<any> => {
	return axios.get(`${GET_BARRIOS}?id_localidad=${id_localidad}`).then((d: any) => d.data);
};

const getVacancyId = (id: ID, queryParam: any = undefined): Promise<any> => {
	const query = queryParam ? `id=${id}&${queryParam}` : `id=${id}`;
	return axios.get(`${GET_VACANCY}?${query}`).then((d: any) => d.data);
};
const getCompanyId = (id: ID): Promise<any> => {
	return axios.get(`${GET_COMPANY}?id=${id}`).then((d: any) => d.data);
};
const getCurriculoVagaById = (query: string): Promise<any> => {
	return axios.get(`${GET_CURRICULOVAGABYID}?${query}`).then((d: any) => d.data);
};

const getVagasPostuladas = (query: string): Promise<any> => {
	return axios.get(`${GET_VAGASPOSTULADAS}?${query}`).then((d: any) => d.data);
};

const createVacancy = (vacancy: any): Promise<any> => {
	return axios.post(`${POST_VACANCY}`, vacancy).then((d: any) => d.data);
};

const createVacancyTempral = (vacancy: any): Promise<any> => {
	return axios.post(`${POST_VACANCY_TEMPORAL}`, vacancy).then((d: any) => d.data);
};

const vacancyTempral = (): Promise<any> => {
	return axios.get(`${GET_VACANCY_TEMPORAL}`).then((d: any) => d.data);
};

const putVacancyTempral = (vacancy: any): Promise<any> => {
	return axios.put(`${PUT_VACANCY_TEMPORAL}`, vacancy).then((d: any) => d.data);
};

const deleteVacancyTempral = (): Promise<any> => {
	return axios.delete(`${DELETE_VACANCY_TEMPORAL}`).then((d: any) => d.data);
};

const registerForVacancy = (register: { id_vagas: ID }): Promise<any> => {
	return axios.post(`${POST_VACANCY_REGISTER}`, register).then((d: any) => d.data);
};

const getMeetsByCouselor = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CONSEJERO}?${query}`).then((d: any) => d.data);
};

const getMeetsByCandidate = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CANDIDATE}?${query}`).then((d: any) => d.data);
};

/*const getVerifyFreeDate = (query: string): Promise<any> => {
  return axios.get(`${AGENDA_VERIFY_DATE}?${query}`).then((d: any) => d.data);
};*/

/*const getRandomDate = (): Promise<any> => {
  return axios.get(`${AGENDA_RANDOM_DATE}`).then((d: any) => d.data);
};*/

const meetListUsersAvailables = (filterBy: string, limit: any = null): Promise<any> => {
	return axios
		.get(`${AGENDA_LIST_USERS_AVAILABLES}?filterBy=${filterBy}&limit=${limit}`)
		.then((d: any) => d.data);
};

const listConsuelorMeets = (month: number, year: number): Promise<any> => {
	return axios
		.get(`${AGENDA_LIST_COUSELOR_MEETS}?month=${month}&year=${year}`)
		.then((d: any) => d.data);
};

const listCandidateAvailableMeets = (
	filterBy: string,
	month: number,
	year: number,
	limit: any = null
): Promise<any> => {
	return axios
		.get(
			`${AGENDA_LIST_CANDIDATES_MEETS}?filterBy=${filterBy}&limit=${limit}&month=${month}&year=${year}`
		)
		.then((d: any) => d.data);
};

const updateVacancyStatus = (vacancySingle: { id: ID; status: boolean }): Promise<any> => {
	return axios.put(UPDATE_VACANCY_STATUS, vacancySingle).then((d: any) => d.data);
};

const storeMeetApi = (agenda: any): Promise<any> => {
	return axios.post(`${AGENDA_CREATE}`, agenda).then((response: any) => response.data);
};

const deleteMeetApi = (data: any): Promise<any> => {
	return axios.put(AGENDA_DELETE, data).then((response: any) => response.data);
};

const doneMeetApi = (agenda: any): Promise<any> => {
	return axios.put(`${AGENDA_DONE}`, agenda).then((response: any) => response.data);
};

const getSearchVacantes = (query: string): Promise<any> => {
	return axios.get(`${GET_SEARCH_VACANTES}?${query}`).then((d: any) => d.data);
};

const downloadZipAllFilesByCompany = (query: string): Promise<any> => {
	return axios
		.get(`${DOWNLOAD_ZIP_ALL_FILES_COMPANY}?${query}`, { responseType: 'blob' })
		.then((d: any) => d);
};

const downloadZipFileByCompany = (query: string): Promise<any> => {
	return axios
		.get(`${DOWNLOAD_ZIP_FILE_COMPANY}?${query}`, { responseType: 'blob' })
		.then((d: any) => d);
};

const getFilesCompany = (query: string): Promise<any> => {
	return axios.get(`${GET_FILES_BY_COMPANY}?${query}`).then((d: any) => d.data);
};

const deleteFileCompany = (query: string): Promise<any> => {
	return axios.delete(`${DELETE_FILE_COMPANY}?${query}`).then((d: any) => d.data);
};

const getCargos = (): Promise<any> => {
	return axios.get(`${GET_CARGOS}`).then((d: any) => d.data);
};

const getParametos = (
	subtype: String,
	params?: AxiosRequestConfig<any>['params']
): Promise<ParameterConfigMmodel[]> => {
	return axios
		.get(`${GET_PARAMETROS}/${subtype}`, {
			params: params,
		})
		.then((d: any) => d.data);
};

const getNivelesEducativos = (): Promise<any> => {
	return axios.get(`${GET_NIVELES_EDUCATIVOS}`).then((d: any) => d.data);
};

const getNucleosConocimiento = (): Promise<any> => {
	return axios.get(`${GET_NUCLEOS_CONOCIMIENTO}`).then((d: any) => d.data);
};

const getNivelesNucleos = (): Promise<any> => {
	return axios.get(`${GET_NIVELES_NUCLEOS}`).then((d: any) => d.data);
};

const getTitulosHomologados = (): Promise<any> => {
	return axios.get(`${GET_TITLOS_HOMOLOGADOS}`).then((d: any) => d.data);
};

const getAreasDesempenio = (): Promise<any> => {
	return axios.get(`${GET_AREAS_DESEMPENIO}`).then((d: any) => d.data);
};

const updatePostulationStatus = (postulationSingle: { id: ID; status: string }): Promise<any> => {
	return axios.put(PUT_CURRICULOVAGA_STATUS, postulationSingle).then((d: any) => d.data);
};

const submitCandidate = (id: ID, idCandidato: ID): Promise<any> => {
	return axios.post(`${POST_REMITIDO}/${id}/${idCandidato}`).then((d: any) => d.data);
};


const downloadPdfMasivo = (vaga_id: any, candidato_id: any): Promise<any> => {
	return axios
		.post(`${POST_PDF_MASIVO}/${vaga_id}/usersCandidato`, {candidato_id}, {
			responseType: 'blob',
		})
		.then((d: any) => d.data);
};

const updateVacancyStatusApproval = (postulationSingle: {
	id: ID;
	status_approval: string;
	description: string;
}): Promise<any> => {
	return axios.put(PUT_VACANCYSTATE_APPROVAL, postulationSingle).then((d: any) => d.data);
};

const getAllCIUUCodes = (): Promise<any> => {
	return axios.get(`${GET_CODIGOS_CIUU}`).then((d: any) => d.data);
};

const getSectorByCIUU = (ciuu: string): Promise<any> => {
	return axios.get(`${GET_SECTOR_BY_CODIGO_CIUU}?ciuu=${ciuu}`).then((d: any) => d.data);
};

const getBitacorasAll = (query: string): Promise<any> => {
	return axios.get(`${GET_BITACORASALL}?${query}`).then((d: any) => d.data);
};

const putBitacorasStatus = (bitacoraStatus: {
	id: string;
	status: string;
	detalle: string;
}): Promise<any> => {
	return axios.put(PUT_BITACORASTATUS, bitacoraStatus).then((d: any) => d.data);
};

const createBitacora = async (data: any): Promise<any> => {
	return axios.post(`${POST_BITACORA}`, data).then((d: any) => d.data);
};

const getBitacora = (query: string): Promise<any> => {
	return axios.get(`${GET_BITACORA}?${query}`).then((d: any) => d.data);
};

const putBitacora = (data: any): Promise<any> => {
	return axios.put(`${PUT_BITACORA}`, data).then((d: any) => d.data);
};

const deleteBitacora = (bitacoraToDelete: { id: string }): Promise<any> => {
	return axios
		.delete(DELETE_BITACORA, { data: bitacoraToDelete })
		.then((response: any) => response.data);
};
export {
	getUsers,
	getUsersCurriculo,
	deleteUser,
	deleteSelectedUsers,
	getUserById,
	createUser,
	updateUser,
	getCompanies,
	getAllCompanies,
	getCompaniesCounselor,
	getPreforma,
	putPreforma,
	deletePreforma,
	createCompany,
	createCurriculo,
	createVacancy,
	createVacancyTempral,
	vacancyTempral,
	putVacancyTempral,
	deleteVacancyTempral,
	getCurriculoById,
	getVagaCurriculoById,
	getVacanteWordpress,
	getCurriculoVagaById,
	getVacancyId,
	getUserCompelmentoId,
	registerForVacancy,
	getUserDataById,
	updateStatusUserToActive,
	updateStatusUserToDesactive,
	deleteUserById,
	deleteVacancyById,
	updateVacancy,
	updateUserPut,
	createUserPost,
	getCompanyId,
	updateCompany,
	updateUserComplementoPut,
	deleteEmpresaById,
	getMeetsByCouselor,
	getMeetsByCandidate,
	updateStatusEmpresa,
	emailCandidateEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
	updateVacancyStatus,
	getSearchVacantes,
	getVagaEmpresaCurriculoAll,
	getVagaPostulaCurriculoAll,
	getVagaCountCurriculoAll,
	getUserCurriculoAll,
	getVagasPostuladas,
	storeMeetApi,
	deleteMeetApi,
	doneMeetApi,
	meetListUsersAvailables,
	listConsuelorMeets,
	downloadZipAllFilesByCompany,
	getLocalidades,
	getAgendaVagaCurriculoAll,
	getAgendaCurriculoById,
	feedbackCandidate,
	getBarriosByLocalidad,
	getCargos,
	getNivelesEducativos,
	getNucleosConocimiento,
	getNivelesNucleos,
	getTitulosHomologados,
	getAreasDesempenio,
	getParametos,
	updatePostulationStatus,
	submitCandidate,
	downloadPdfMasivo,
	listCandidateAvailableMeets,
	updateVacancyStatusApproval,
	getAllCIUUCodes,
	getSectorByCIUU,
	getUsersConsejeroEmpresarial,
	createInformacionAdicionalEmpresa,
	updateInformacionAdicionalEmpresa,
	getInformacionAdicionalEmpresa,
	createInformacionAdicionalVacante,
	getInformacionAdicionalVacante,
	updateInformacionAdicionalVacante,
	downloadZipFileByCompany,
	getFilesCompany,
	deleteFileCompany,
	getBitacorasAll,
	putBitacorasStatus,
	createBitacora,
	getBitacora,
	putBitacora,
	deleteBitacora,
};
