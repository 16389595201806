import { StepIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColorlibStepIconRoot = styled('div')<{ownerState: { completed?: boolean; active?: boolean };}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient( #2266cc, #2266cc)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient( #2266cc, #2266cc)',
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const icons: {[index: string]: string}  = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10'
      };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
             {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

export default ColorlibStepIcon