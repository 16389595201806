import { useNavigate } from 'react-router-dom';

const BackButton: React.FC<{ to?: any }> = ({ to }) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => { navigate(to || -1); }}
            className='btn btn-primary'
            style={{ background: '#C4D2E8', borderRadius: '4px', padding: '10.5px 15px' }}
        >
            <i
                className='bi bi-arrow-left'
                style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
            ></i>
        </button>
    )
}

export default BackButton;
