import { useContext, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useAuth } from '../core/Auth'

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out (Default 30 minutes (1800 seconds))
 */
const useIdleTimeout = ({ idleTime =  1800}) => {
    
    idleTime = process.env.REACT_APP_MAX_TIME_INACTIVE_USER ? Number(process.env.REACT_APP_MAX_TIME_INACTIVE_USER) : idleTime;
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)
    const { logout } = useAuth()

    const handleIdle = () => {
        setIdle(true)
        logout()
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: idleTimeout / 2,
        //onPrompt: onIdle, function to notify user when idle timeout is close
        onIdle: handleIdle,
        debounce: 500
    })

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

export default useIdleTimeout;