import { PageTitle } from '../../../_metronic/layout/core';
import BackButton from '../../components/BackButton/BackButton';
import { useEffect } from 'react';
import { useAuth } from '../../../app/modules/auth';
import { Padding } from '@mui/icons-material';

const CandidatosDashboardPage = () => {

	const { isAdminRole, isCandidateRole, auth } = useAuth();
	const dashCandidato = process.env.REACT_APP_DASH_CANDIDATO || '';
	const dashCandidatoFilterFieldId = process.env.REACT_APP_DASH_CANDIDATO_FILTER_FIELD_ID || '';

	/**
	 * Retorna url para llamado a dashboard en looker studio	
	 */
	const url = () => {
		if (isAdminRole()) {
			return dashCandidato;
		} else if (isCandidateRole()) {
			/**
			 *  renatosilvacavalcanti
			 *	July 7, 2023 at 3:54 PM
			 *	ajsute dash
			 *	url: 'https://lookerstudio.google.com/u/0/reporting/8f7dc586-9e97-4d12-afe3-4753790971b9/page/p_4ymnosdz6c?params=%7B%22df208%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580'+idHash+'%22%7D'
			 */
			/**
			 *  Generacion de un marcador personalizado
			 *  Documentación: https://support.google.com/looker-studio/answer/7664330?hl=en#zippy=%2Cin-this-article
			 * 	Ejemplo: La siguiente linea hace referencia a la aplicacion de un filtro por el atributo idhashcandidato
			 *	`%7B%22df213%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${idHash}%22%7D` 
			 */
			return `${dashCandidato}?params=%7B%22${dashCandidatoFilterFieldId}%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.user?.idHash}%22%7D`
		} else {
			return ``
		}
	}

	useEffect(() => {

	}, []);

	/**
	 * Valida si es administrador o empresa
	 * @returns 
	 */
	const isAdminOrCandidate = () => {
		return isAdminRole() || isCandidateRole();
	}

	return (
		<>
			<div className='p-10 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div
								className='col-sm-1'
								style={{
									display: 'contents',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<BackButton to='/' />
							</div>
							<div className='col-sm-10' style={{ padding: '0px 0px 0px 10px' }}>
								<p>Métricas e indicadores de las vacantes</p>
							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '100%' }}>
					{isAdminOrCandidate() && (
						<iframe
							width="100%"
							height='850px'
							frameBorder="0"
							style={{ border: '0' }}
							allowFullScreen
							src={url()}
						>
						</iframe>
					)}
				</div>
			</div>
		</>
	);
};

const CandidatosDashboardWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Candidatos</PageTitle>
			<CandidatosDashboardPage />
		</>
	);
};

export { CandidatosDashboardWrapper };
