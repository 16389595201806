import { useState, useEffect, useContext } from 'react';
import { Alert, Snackbar, AlertTitle, Select, MenuItem, Button, Grid, OutlinedInput, Box, Chip, Autocomplete, TextField } from '@mui/material';
import { useAuth } from '../../modules/auth';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getAllCompanies, getCargos, getCompaniesCounselor, getListarTiposDeConvocatorias, getListOfTrainingPrograms, getListVacantes, getParametros, getUsers, getUsersConsejeroCandidato, getUsersConsejeroEmpresarial, getVacantes, reportesDetallados } from '../../modules/apps/user-management/users-list/core/_requests';
import DatePicker from 'react-multi-date-picker';
import useStep1Parameters from '../../hooks/parameters/curriculum/useStep1Parameters';
import { multiStepContext } from '../../hooks/StepContext';







const DetailedReportingPanel = () => {

	const {
		listGrupoEtnico,
		listDiscapacidad,
		listPoblaciones
	} = useStep1Parameters();





	// estados Formacion
	const EstadoConvocatoria = {
		CREADA: 'Creada',
		Finalizada: 'Finalizada',
		Registrados: 'Registrados',
		AsistentesCargados: "Asistentes cargados"
	}

	// reportes
	const [selectedKey, setSelectedKey] = useState<string | null>(null);
	// reportes

	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [requestInProgress, setRequestInProgress] = useState(false);

	const [open, setOpen] = useState(false);

	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const colombia_es_lowercase: any = {
		name: "gregorian_es_lowercase",
		months: [
			["enero", "ene"],
			["febrero", "feb"],
			["marzo", "mar"],
			["abril", "abr"],
			["mayo", "may"],
			["junio", "jun"],
			["julio", "jul"],
			["agosto", "ago"],
			["septiembre", "sep"],
			["octubre", "oct"],
			["noviembre", "nov"],
			["diciembre", "dic"],
		],
		weekDays: [
			["sábado", "sáb"],
			["domingo", "dom"],
			["lunes", "lun"],
			["martes", "mar"],
			["miércoles", "mié"],
			["jueves", "jue"],
			["viernes", "vie"],
		],
		digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		meridiems: [
			["AM", "am"],
			["PM", "pm"],
		],
	};


	const [nivelesEducativos, setNivelesEducativos] = useState([]);


	const [tiposConvocatoria, setTiposConvocatoria] = useState([]);
	const [programaDeFormacion, setProgramaDeFormacion] = useState([]);


	const [tipoDireccionamiento, setTipoDireccionamiento] = useState([]);
	const [subtipoDireccionamiento, setSubipoDireccionamiento] = useState([]);
	const [companies, setCompanies] = useState([])
	const [companiesDoc, setCompaniesDoc] = useState([])
	const [cargos, setCargos] = useState([])
	const [vacantes, setVacantes] = useState([])


	const handleGetParametros = async () => {
		setLoading(true);
		try {

			const nivelEducativo: any = await getParametros("NIVELEDUCATIVO");
			setNivelesEducativos(nivelEducativo.data)

			const tipoDireccionamiento: any = await getParametros("TIPO_DIRECCIONAMIENTO");
			setTipoDireccionamiento(tipoDireccionamiento.data)

			const subtipoDireccionamiento: any = await getParametros("SUBTIPO_DIRECCIONAMIENTO");
			setSubipoDireccionamiento(subtipoDireccionamiento.data)

			const programaDeFormacion: any = await getListOfTrainingPrograms();
			setProgramaDeFormacion(programaDeFormacion.data)

			const tipoConvocatoria: any = await getListarTiposDeConvocatorias();
			setTiposConvocatoria(tipoConvocatoria.data)

			const allCompanies: any = await getAllCompanies();
			setCompanies(allCompanies.data)

			const allCompaniesDoc: any = await getCompaniesCounselor();
			
			console.log("🔥 ~ handleGetParametros ~ allCompaniesDoc:", allCompaniesDoc)
			setCompaniesDoc(allCompaniesDoc.data)

			const cargos: any = await getCargos();
			setCargos(cargos.data)

			// const vacantes: any = await getVacantes();
			const vacantes: any = await getListVacantes();
			setVacantes(vacantes.data)

		} catch (error: any) {
			console.error("Error obteniendo parámetros:", error.response?.data?.error || error.message);
		} finally {
			setLoading(false);
		}
	};


	const [consejeroEmpleador, setConsejeroEmpleador] = useState([]);
	const [consejeroCandidato, setConsejeroCandidato] = useState([]);
	// traer lista de consejeros
	const fetchListsCounselorEmpresa = async () => {
		const res = await getUsersConsejeroEmpresarial();
		await setConsejeroEmpleador(res.data.data);

		const consejeroCandidato = await getUsersConsejeroCandidato();
		setConsejeroCandidato(consejeroCandidato.data.data)
	};


	useEffect(() => {
		handleGetParametros()
		fetchListsCounselorEmpresa()
	}, [])


	const SeccionDescarga = ({ endpoint, data }: { endpoint: string; data: any }) => (
		<div style={{ width: '100%' }}>
			<div className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
				<h4>¿En cuál formato deseas el documento?</h4>
			</div>

			<div className="d-flex justify-content-around">
				<Button
					variant="contained"
					size="large"
					startIcon={<CloudDownloadIcon />}
					onClick={() => handleDescargarReporteCSV("csv", data, endpoint)}
				>
					Formato .CSV
				</Button>
				<Button
					variant="contained"
					size="large"
					startIcon={<CloudDownloadIcon />}
					onClick={() => handleGetDescargarExcel("xlsx", data, endpoint)}
				>
					Formato .XLSX
				</Button>
			</div>
		</div>
	);

	const handleGetDescargarExcel = async (tipoDoc: any, data: any, endpoint: any) => {
		try {
			const blob = await reportesDetallados(tipoDoc, data, endpoint);
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = `reporte_${endpoint}.xlsx`;
			link.click();
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Iniciando descarga del .XLSX!`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);

		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const handleDescargarReporteCSV = async (tipoDoc: any, data: any, endpoint: any) => {
		try {
			const response = await reportesDetallados(tipoDoc, data, endpoint);
			const blob = new Blob([response], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `reporte_${endpoint}.${tipoDoc}`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Iniciando descarga del .CSV!`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			console.error('Error capturado:', error);
			if (error.response) {
				console.error('Response completa:', error.response);
				const errorMessage = error.response.data?.error || 'Ocurrió un error inesperado';
				setOpen(true);
				setAlert(false);
				setAlertMsg(errorMessage);
				setTimeout(() => {
					setOpen(false);
				}, 2500);
			} else {
				console.error('Ocurrió un error procesando la solicitud.');
			}
		}
	};




	// Inicio Filtro Candidato
	const [registroFechas, setRegistroFechas] = useState<any>([]);
	const [actualizacionFechas, setActualizacionFechas] = useState<any>([]);
	const [tipoDePoblación, setTipoDePoblación] = useState<string[]>([]);
	const [grupoEtnico, setGrupoEtnico] = useState("");
	const [tipoDeDiscapacidad, setTipoDeDiscapacidad] = useState("");
	const [educacionMaxima, setEducacionMaxima] = useState("")
	const [userReporteCandidatos, setUserReporteCandidatos] = useState<any>({});

	// Fecha de registro hoja de vida 
	const handleRegistroFechasChange = (dates: any) => {
		setRegistroFechas(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserReporteCandidatos({
				...userReporteCandidatos,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});

		}
	};
	// Fecha de última actualización hoja de vida
	const handleActualizacionFechasChange = (dates: any) => {
		setActualizacionFechas(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserReporteCandidatos({
				...userReporteCandidatos,
				fecha_cv_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cv_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Tipo de población
	const handleChangeTipoDePoblacion = (event: any) => {
		setUserReporteCandidatos({
			...userReporteCandidatos,
			tipo_poblacion: event.target.value
		})
		setTipoDePoblación(event.target.value);
	};
	// Grupo Étnico
	const handleChangeGurpoEtnico = (event: any) => {
		setUserReporteCandidatos({
			...userReporteCandidatos,
			grupo_etnico: event.target.value
		})
		setGrupoEtnico(event.target.value);
	};
	// Tipo de discapacidad
	const handleChangeTipoDeDiscapacidad = (event: any) => {
		setUserReporteCandidatos({
			...userReporteCandidatos,
			condicion_discapacidad: event.target.value
		})
		setTipoDeDiscapacidad(event.target.value);
	};
	// Nivel educacion
	const handleChangeNivelEducacion = (event: any) => {
		setUserReporteCandidatos({
			...userReporteCandidatos,
			nivel_educativo_max: event.target.value
		})
		setEducacionMaxima(event.target.value);
	};
	// Limpiar campos
	const handleClearFiltersCandidatos = () => {
		setRegistroFechas([]);
		setActualizacionFechas([]);
		setTipoDePoblación([]);
		setGrupoEtnico('');
		setTipoDeDiscapacidad('');
		setEducacionMaxima('');
		setUserReporteCandidatos({});
	};

	const Candidatos = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Candidatos</h4>

			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de registro hoja de vida  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro hoja de vida
						</label>
						<DatePicker
							id="registro-date-picker"
							value={registroFechas}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='MM/DD/YY'
							onChange={handleRegistroFechasChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Última fecha de actualización hoja de vida */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Última fecha de actualización hoja de vida
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={actualizacionFechas}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='MM/DD/YY'
							onChange={handleActualizacionFechasChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Tipo de población */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de población
					</label>
					<Select
						fullWidth
						labelId="demo-multiple-chip-label"
						id="demo-multiple-chip"
						multiple
						value={tipoDePoblación}
						onChange={handleChangeTipoDePoblacion}
						input={<OutlinedInput id="select-multiple-chip" />}
						renderValue={(selected) => (
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
								{selected.map((code) => {
									const selectedItem = listPoblaciones.find((item) => item.code === code);
									return <Chip key={code} label={selectedItem ? selectedItem.label : code} />;
								})}

							</Box>
						)}
					>
						{listPoblaciones.map((item) => (
							<MenuItem key={item.id} value={item.code} >
								{item.label}
							</MenuItem>
						))}
					</Select>


				</Grid>
				{/* Grupo étnico */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Grupo Étnico
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={grupoEtnico}
						onChange={handleChangeGurpoEtnico}
					>
						{listGrupoEtnico.map((item) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Tipo de discapacidad */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de discapacidad
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={tipoDeDiscapacidad}
						onChange={handleChangeTipoDeDiscapacidad}
					>
						{listDiscapacidad.map((item) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
					{/* <Select
					fullWidth
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={tipoDeDiscapacidad}
						onChange={handleChangeTipoDeDiscapacidad}
					>
						<MenuItem value={0}>No</MenuItem>
						<MenuItem value={1}>Si</MenuItem>
						
					</Select> */}
				</Grid>
				{/* nivel educativo */}
				<Grid lg={6} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						¿Cual es el nivel maximo de educación culimnado con éxito?
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={educacionMaxima}
						onChange={handleChangeNivelEducacion}
					>
						{nivelesEducativos.map((item: any) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
					{/* <Select
					fullWidth
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={tipoDeDiscapacidad}
						onChange={handleChangeTipoDeDiscapacidad}
					>
						<MenuItem value={0}>No</MenuItem>
						<MenuItem value={1}>Si</MenuItem>
						
					</Select> */}
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersCandidatos} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userReporteCandidatos} endpoint="detallado/candidatos" />

		</div>
	);
	// Fin Filtro Candidato



	// Inicio Filtro NivelEducativo
	const [registroCV_NivelEducativo, setRegistroCV_NivelEducativo] = useState<any>([]);
	const [actualizacionCV_NivelEducativo, setActualizacionCV_NivelEducativo] = useState<any>([]);
	const [educacionMaxima_NivelEducativo, setEducacionMaxima_NivelEducativo] = useState("")
	const [filtrosDeNivelEducativo, setFiltrosDeNivelEducativo] = useState<any>({});
	// Fecha de registro hoja de vida
	const handleChangeReporteRegistroCV = (dates: any) => {
		setRegistroCV_NivelEducativo(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDeNivelEducativo({
				...filtrosDeNivelEducativo,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Fecha de última actualización hoja de vida 
	const handleChangeReporteActualizacionCV = (dates: any) => {
		setActualizacionCV_NivelEducativo(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDeNivelEducativo({
				...filtrosDeNivelEducativo,
				fecha_cv_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cv_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Nivel educacion
	const handleChangeReporteNivelEducativo = (event: any) => {
		setEducacionMaxima_NivelEducativo(event.target.value);
		setFiltrosDeNivelEducativo({
			...filtrosDeNivelEducativo,
			nivel_educativo_max: event.target.value
		})
	};
	// Limpiar campos
	const handleClearFiltersNivelEducativo = () => {
		setRegistroCV_NivelEducativo([]);
		setActualizacionCV_NivelEducativo([]);
		setEducacionMaxima_NivelEducativo('');
		setFiltrosDeNivelEducativo({});
	};

	const NivelEducativo = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Nivel Educativo</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de registro hoja de vida */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro hoja de vida
						</label>
						<DatePicker
							id="registro-date-picker"
							value={registroCV_NivelEducativo}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeReporteRegistroCV}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de última actualización hoja de vida    */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de última actualización hoja de vida
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={actualizacionCV_NivelEducativo}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeReporteActualizacionCV}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* nivel educativo */}
				<Grid lg={6} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Nivel educativo
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={educacionMaxima_NivelEducativo}
						onChange={handleChangeReporteNivelEducativo}
					>
						{nivelesEducativos.map((item: any) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersNivelEducativo} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDeNivelEducativo} endpoint='detallado/nivel-educativo' />
		</div>
	);
	// fin Filtro NivelEducativo

	// Inicio experiencia laboral
	const [registroCV_ExperienciaLaborar, setRegistroCV_ExperienciaLaborar] = useState<any>([]);
	const [actualizacionCV_ExperienciaLaborar, setActualizacionCV_ExperienciaLaborar] = useState<any>([]);
	const [cargoEquivalente_ExperienciaLaborar, setCargoEquivalente_ExperienciaLaborar] = useState("")
	const [filtrosDeExperienciaLaborar, setFiltrosDeExperienciaLaborar] = useState<any>({});
	// Fecha de registro hoja de vida
	const handleChangeReporteRegistroCVExperienciaLaboral = (dates: any) => {
		setRegistroCV_ExperienciaLaborar(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDeExperienciaLaborar({
				...filtrosDeExperienciaLaborar,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Fecha de última actualización hoja de vida 
	const handleChangeReporteActualizacionCVExperienciaLaboral = (dates: any) => {
		setActualizacionCV_ExperienciaLaborar(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDeExperienciaLaborar({
				...filtrosDeExperienciaLaborar,
				fecha_cv_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cv_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Cargo equivalente  
	const handleChangeReporteNivelEducativoExperienciaLaboral = (event: any) => {
		setCargoEquivalente_ExperienciaLaborar(event.target.value);
		setFiltrosDeExperienciaLaborar({
			...filtrosDeExperienciaLaborar,
			cargo_equivalente: event.target.value
		})
	};
	// Limpiar campos
	const handleClearFiltersExperienciaLaboral = () => {
		setRegistroCV_ExperienciaLaborar([]);
		setActualizacionCV_ExperienciaLaborar([]);
		setCargoEquivalente_ExperienciaLaborar('');
		setFiltrosDeExperienciaLaborar({});
	};
	const ExperienciaLaboral = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Experiencia Laboral</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de registro hoja de vida  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro hoja de vida
						</label>
						<DatePicker
							id="registro-date-picker"
							value={registroCV_ExperienciaLaborar}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeReporteRegistroCVExperienciaLaboral}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de última actualización hoja de vida    */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de última actualización hoja de vida
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={actualizacionCV_ExperienciaLaborar}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeReporteActualizacionCVExperienciaLaboral}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/*  Cargo equivalente  */}
				<Grid lg={6} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Cargo equivalente
					</label>
					{/* <Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={cargoEquivalente_ExperienciaLaborar}
						onChange={handleChangeReporteNivelEducativoExperienciaLaboral}
					>
						{nivelesEducativos.map((item: any) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select> */}
					{/* <Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={cargoEquivalente_ExperienciaLaborar}
						onChange={handleChangeReporteNivelEducativoExperienciaLaboral}
					>
						{cargos.map((cargo: any) => (
							<MenuItem key={cargo.id} value={cargo.desc_cargo}>
								{cargo.desc_cargo}
							</MenuItem>
						))}
					</Select> */}



					<Autocomplete
						options={cargos}
						getOptionLabel={(option: any) => option.desc_cargo || ''}
						value={cargos.find((cargo: any) => cargo.desc_cargo === cargoEquivalente_ExperienciaLaborar) || null}
						onChange={(_, newValue) => handleChangeReporteNivelEducativoExperienciaLaboral({ target: { value: newValue?.desc_cargo || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
					/>



				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersExperienciaLaboral} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDeExperienciaLaborar} endpoint='detallado/experiencia-laboral' />
		</div>
	);
	// Fin experiencia laboral

	// Inicio CitasVirtuales
	const [consejeroCandidato_CitasVirtuales, setConsejeroCandidato_CitasVirtuales] = useState("")
	const [fechaDeAgendamiento_CitasVirtuales, setFechaDeAgendamiento_CitasVirtuales] = useState<any>([]);
	const [filtrosDe_CitasVirtuales, setFiltrosDe_CitasVirtuales] = useState<any>({});
	// Consejero candidato 
	const handleChangeReporteConsejeroCandidatoCitasVirtuales = (event: any) => {
		setConsejeroCandidato_CitasVirtuales(event.target.value);
		setFiltrosDe_CitasVirtuales({
			...filtrosDe_CitasVirtuales,
			id_concejero_candidato: event.target.value
		})
	};
	// Consejero candidato 
	const handleChangeReporteFechaDeAgendamientoCitasVirtuales = (dates: any) => {
		setFechaDeAgendamiento_CitasVirtuales(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_CitasVirtuales({
				...filtrosDe_CitasVirtuales,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Limpiar campos
	const handleClearFiltersCitasVirtuales = () => {
		setConsejeroCandidato_CitasVirtuales('');
		setFechaDeAgendamiento_CitasVirtuales([]);
		setFiltrosDe_CitasVirtuales({});
	};
	const CitasVirtuales = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Citas Virtuales</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Consejero candidato */}
				<Grid lg={6} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero candidato
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroCandidato_CitasVirtuales}
						onChange={handleChangeReporteConsejeroCandidatoCitasVirtuales}
					>
						{consejeroCandidato.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fechas de agendamiento  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={fechaDeAgendamiento_CitasVirtuales}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeReporteFechaDeAgendamientoCitasVirtuales}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersCitasVirtuales} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_CitasVirtuales} endpoint='detallado/citas-virtuales' />
		</div>
	);
	// Fin CitasVirtuales

	// Inicio Formacion
	const [consejeroCandidato_Formacion, setConsejeroCandidato_Formacion] = useState("")
	const [fechaDeregistro_Formacion, setFechaDeRegistro_Formacion] = useState<any>([]);
	const [fechaDeCierre_Formacion, setFechaDeCierre_Formacion] = useState<any>([]);
	const [tipoDeConvocatoria_Formacion, setTipoDeConvocatoria_Formacion] = useState("")
	const [programaDeFormacion_Formacion, setProgramaDeFormacion_Formacion] = useState("")
	const [estado_Formacion, setEstado_Formacion] = useState("")
	const [filtrosDe_Formacion, setFiltrosDe_Formacion] = useState<any>({});
	// Consejero candidato 
	const handleChangeConsejeroCandidato_Formacion = (event: any) => {
		setConsejeroCandidato_Formacion(event.target.value);
		setFiltrosDe_Formacion({
			...filtrosDe_Formacion,
			id_consejero_candidato: event.target.value
		})
	};
	// Fecha De Registro
	const handleChangeFechaDeRegistro_Formacion = (dates: any) => {
		setFechaDeRegistro_Formacion(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Formacion({
				...filtrosDe_Formacion,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Fecha de cierre
	const handleChangeFechaDeCierre_Formacion = (dates: any) => {
		setFechaDeCierre_Formacion(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Formacion({
				...filtrosDe_Formacion,
				fecha_cierre_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cierre_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Tipo de convocatoria 
	const handleChangeTipoDeConvocatoria_Formacion = (event: any) => {
		setTipoDeConvocatoria_Formacion(event.target.value);
		setFiltrosDe_Formacion({
			...filtrosDe_Formacion,
			id_tipo_convocatoria: event.target.value
		})
	};
	// Programa de formación
	const handleChangeProgramaDeFormacion_Formacion = (event: any) => {
		setProgramaDeFormacion_Formacion(event.target.value);
		setFiltrosDe_Formacion({
			...filtrosDe_Formacion,
			id_programa_formacion: event.target.value
		})
	};
	// Estado
	const handleChangeEstado_Formacion = (event: any) => {
		setEstado_Formacion(event.target.value);
		setFiltrosDe_Formacion({
			...filtrosDe_Formacion,
			estado_convocatoria: event.target.value
		})
	};
	// Limpiar campos
	const handleClearFiltersDeFormacion = () => {
		setConsejeroCandidato_Formacion('');
		setFechaDeRegistro_Formacion([]);
		setFechaDeCierre_Formacion([]);
		setTipoDeConvocatoria_Formacion('');
		setProgramaDeFormacion_Formacion('');
		setEstado_Formacion('');
		setFiltrosDe_Formacion({});
	};
	const Formacion = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Formacion</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Consejero candidato  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero candidato
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroCandidato_Formacion}
						onChange={handleChangeConsejeroCandidato_Formacion}
					>
						{consejeroCandidato.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fecha de registro   */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaDeregistro_Formacion}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeRegistro_Formacion}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de cierre   */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de cierre
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={fechaDeCierre_Formacion}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeCierre_Formacion}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Tipo de convocatoria */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de convocatoria
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={tipoDeConvocatoria_Formacion}
						onChange={handleChangeTipoDeConvocatoria_Formacion}
					>
						{tiposConvocatoria.map((item: any) => (
							<MenuItem key={item.code} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>


				</Grid>
				{/* Programa de formación */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Programa de formación
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={programaDeFormacion_Formacion}
						onChange={handleChangeProgramaDeFormacion_Formacion}
					>
						{programaDeFormacion.map((item: any) => (
							<MenuItem key={item.code} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Estado */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Estado
					</label>
					<Select
						fullWidth
						labelId="estado-convocatoria-label"
						id="estado-convocatoria-select"
						value={estado_Formacion}
						onChange={handleChangeEstado_Formacion}
					>
						{Object.entries(EstadoConvocatoria).map(([key, value]) => (
							<MenuItem key={key} value={key}>
								{value}
							</MenuItem>
						))}
					</Select>

				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersDeFormacion} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_Formacion} endpoint='detallado/formacion' />
		</div>
	);
	// Fin Formacion

	// Inicio Orientacion
	const [consejeroCandidato_Orientacion, setConsejeroCandidato_Orientacion] = useState("")
	const [fechaDeregistro_Orientacion, setFechaDeRegistro_Orientacion] = useState<any>([]);
	const [fechaDeCierre_Orientacion, setFechaDeCierre_Orientacion] = useState<any>([]);
	const [tipoDeDireccionamiento_Orientacion, setTipoDeDireccionamiento_Orientacion] = useState("")
	const [subtipoDeDireccionamiento_Orientacion, setSubtipoDeDireccionamiento_Orientacion] = useState("")
	const [estado_Orientacion, setEstado_Orientacion] = useState("")
	const [filtrosDe_Orientacion, setFiltrosDe_Orientacion] = useState<any>({});
	// Consejero candidato 
	const handleChangeConsejeroCandidato_Orientacion = (event: any) => {
		setConsejeroCandidato_Orientacion(event.target.value);
		setFiltrosDe_Orientacion({
			...filtrosDe_Orientacion,
			id_consejero_candidato: event.target.value
		})
	};
	// Fecha De Registro
	const handleChangeFechaDeRegistro_Orientacion = (dates: any) => {
		setFechaDeRegistro_Orientacion(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Orientacion({
				...filtrosDe_Orientacion,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Fecha de cierre
	const handleChangeFechaDeCierre_Orientacion = (dates: any) => {
		setFechaDeCierre_Orientacion(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Orientacion({
				...filtrosDe_Orientacion,
				fecha_cierre_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cierre_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Tipo de diccionamiento 
	const handleChangeTipoDeDireccionamiento_Orientacion = (event: any) => {
		setTipoDeDireccionamiento_Orientacion(event.target.value);
		setFiltrosDe_Orientacion({
			...filtrosDe_Orientacion,
			id_tipo_direccionamiento: event.target.value
		})
	};
	// Subtipo de diccionamiento 
	const handleChangeSubtipoDeDireccionamiento_Orientacion = (event: any) => {
		setSubtipoDeDireccionamiento_Orientacion(event.target.value);
		setFiltrosDe_Orientacion({
			...filtrosDe_Orientacion,
			id_subtipo_direccionamiento: event.target.value
		})
	};
	// Estado
	const handleChangeEstado_Orientacion = (event: any) => {
		setEstado_Orientacion(event.target.value);
		setFiltrosDe_Orientacion({
			...filtrosDe_Orientacion,
			estado_direccionamiento: event.target.value
		})
	};
	// Limpiar campos
	const handleClearFilters_Orientacion = () => {
		setConsejeroCandidato_Orientacion('');
		setFechaDeRegistro_Orientacion([]);
		setFechaDeCierre_Orientacion([]);
		setTipoDeDireccionamiento_Orientacion('');
		setSubtipoDeDireccionamiento_Orientacion('');
		setEstado_Orientacion('');
		setFiltrosDe_Orientacion({});
	};

	const Orientacion = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Orientacion</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Consejero candidato  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero candidato
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroCandidato_Orientacion}
						onChange={handleChangeConsejeroCandidato_Orientacion}
					>
						{consejeroCandidato.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fecha de creación Ocupaciones  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaDeregistro_Orientacion}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeRegistro_Orientacion}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de aprobación Ocupaciones   */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de cierre
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={fechaDeCierre_Orientacion}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeCierre_Orientacion}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Tipo de direccionamiento  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de direccionamiento
					</label>
					<Select
						fullWidth
						labelId="tipo-direccionamiento-label"
						id="tipo-direccionamiento-select"
						value={tipoDeDireccionamiento_Orientacion}
						onChange={handleChangeTipoDeDireccionamiento_Orientacion}
					>
						{tipoDireccionamiento.map((item: any) => (
							<MenuItem key={item.id} value={item.code}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Subtipo de direccionamiento  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Subtipo de direccionamiento
					</label>
					<Select
						fullWidth
						labelId="tipo-direccionamiento-label"
						id="tipo-direccionamiento-select"
						value={subtipoDeDireccionamiento_Orientacion}
						onChange={handleChangeSubtipoDeDireccionamiento_Orientacion}
					>
						{subtipoDireccionamiento.map((item: any) => (
							<MenuItem key={item.id} value={item.code}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Estado */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Estado
					</label>
					<Select
						fullWidth
						labelId="estado-convocatoria-label"
						id="estado-convocatoria-select"
						value={estado_Orientacion}
						onChange={handleChangeEstado_Orientacion}
					>
						{Object.entries(EstadoConvocatoria).map(([key, value]) => (
							<MenuItem key={key} value={key}>
								{value}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFilters_Orientacion} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_Orientacion} endpoint='detallado/orientacion' />
		</div>
	);
	// Fin Orientacion

	// Inicio AcompanamientoACandidatos
	const [consejeroCandidato_AcompanamientoCandidadito, setConsejeroCandidato_AcompanamientoCandidadito] = useState("")
	const [fechaDeCreacion_AcompanamientoCandidadito, setFechaDeCreacion_AcompanamientoCandidadito] = useState<any>([]);
	const [filtrosDe_AcompanamientoCandidadito, setFiltrosDe_AcompanamientoCandidadito] = useState<any>({});
	// Consejero candidato
	const handleChangeConsejeroCandidato_AcompanamientoCandidadito = (event: any) => {
		setConsejeroCandidato_AcompanamientoCandidadito(event.target.value);
		setFiltrosDe_AcompanamientoCandidadito({
			...filtrosDe_AcompanamientoCandidadito,
			id_consejero_candidato: event.target.value
		})
	};
	// Fecha de creación
	const handleChangeFechaDeCreacion_AcompanamientoCandidadito = (dates: any) => {
		setFechaDeCreacion_AcompanamientoCandidadito(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_AcompanamientoCandidadito({
				...filtrosDe_AcompanamientoCandidadito,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Limpiar campos
	const handleClearFilters_AcompanamientoCandidadito = () => {
		setConsejeroCandidato_AcompanamientoCandidadito('');
		setFechaDeCreacion_AcompanamientoCandidadito([]);
		setFiltrosDe_AcompanamientoCandidadito({});
	};
	const AcompanamientoACandidatos = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Acompanamiento a Candidatos</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Consejero candidato  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero candidato
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroCandidato_AcompanamientoCandidadito}
						onChange={handleChangeConsejeroCandidato_AcompanamientoCandidadito}
					>
						{consejeroCandidato.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fecha de creación Ocupaciones  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de creación
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaDeCreacion_AcompanamientoCandidadito}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeCreacion_AcompanamientoCandidadito}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFilters_AcompanamientoCandidadito} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_AcompanamientoCandidadito} endpoint='detallado/acompanamiento-candidato' />
		</div>
	);
	// Fin AcompanamientoACandidatos

	// Inicio AcompanamientoEmpresarial
	const [numeroDocEmpresa_AcompanamientoEmpresarial, setNumeroDocEmpresa_AcompanamientoEmpresarial] = useState("")
	const [nombreEmpresa_AcompanamientoEmpresarial, setNombreEmpresa_AcompanamientoEmpresarial] = useState("")
	const [codigoVacante_AcompanamientoEmpresarial, setCodigoVacante_AcompanamientoEmpresarial] = useState("")
	const [consejeroEmpleador_AcompanamientoEmpresarial, setConsejeroEmpleador_AcompanamientoEmpresarial] = useState("")
	const [fechaDeCreacion_AcompanamientoEmpresarial, setFechaDeCreacion_AcompanamientoEmpresarial] = useState<any>([]);
	const [filtrosDe_AcompanamientoEmpresarial, setFiltrosDe_AcompanamientoEmpresarial] = useState<any>({});

	//  Numero de documento de la empresa 
	const handleChangeNumeroDocEmpresa_AcompanamientoEmpresarial = (event: any) => {
		setNumeroDocEmpresa_AcompanamientoEmpresarial(event.target.value);
		setFiltrosDe_AcompanamientoEmpresarial({
			...filtrosDe_AcompanamientoEmpresarial,
			numero_documento: event.target.value
		})
	};
	//  Nombre empresa 
	const handleChangeNombreEmpresa_AcompanamientoEmpresarial = (event: any) => {
		setNombreEmpresa_AcompanamientoEmpresarial(event.target.value);
		setFiltrosDe_AcompanamientoEmpresarial({
			...filtrosDe_AcompanamientoEmpresarial,
			razon_social: event.target.value
		})
	};
	//  Código vacante 
	// const handleChangeCodigoVacante_AcompanamientoEmpresarial = (event: any) => {
	// 	setCodigoVacante_AcompanamientoEmpresarial(event.target.value);
	// 	setFiltrosDe_AcompanamientoEmpresarial({
	// 		...filtrosDe_AcompanamientoEmpresarial,
	// 		codigo_vacante: event.target.value
	// 	})
	// };
	const handleChangeCodigoVacante_AcompanamientoEmpresarial = (_: any, newValue: any) => {
		setCodigoVacante_AcompanamientoEmpresarial(newValue ? newValue.codigo_vacante : null);
		setFiltrosDe_AcompanamientoEmpresarial((prev: any) => ({
			...prev,
			codigo_vacante: newValue ? newValue.codigo_vacante : null
		}));
	};

	// Consejero empleador
	const handleChangeConsejeroEmpleador_AcompanamientoEmpresarial = (event: any) => {
		setConsejeroEmpleador_AcompanamientoEmpresarial(event.target.value);
		setFiltrosDe_AcompanamientoEmpresarial({
			...filtrosDe_AcompanamientoEmpresarial,
			id_consejero_empleador: event.target.value
		})
	};
	// Fecha de creación
	const handleChangeFechaDeCreacion_AcompanamientoEmpresarial = (dates: any) => {
		setFechaDeCreacion_AcompanamientoEmpresarial(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_AcompanamientoEmpresarial({
				...filtrosDe_AcompanamientoEmpresarial,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};
	// Limpiar campos
	const handleClearFilters_AcompanamientoEmpresarial = () => {
		setNumeroDocEmpresa_AcompanamientoEmpresarial('');
		setNombreEmpresa_AcompanamientoEmpresarial('');
		setCodigoVacante_AcompanamientoEmpresarial('');
		setConsejeroEmpleador_AcompanamientoEmpresarial('');
		setFechaDeCreacion_AcompanamientoEmpresarial([]);
		setFiltrosDe_AcompanamientoEmpresarial({});
	};
	const AcompanamientoEmpresarial = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Acompanamiento Empresarial</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/*Numero de documento de la empresa  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Numero de documento de la empresa
					</label>
					<Autocomplete
						options={companiesDoc}
						getOptionLabel={(option: any) => `${option.razon_social} - ${option.numero_documento}`}
						value={companiesDoc.find((company: any) => company.numero_documento === numeroDocEmpresa_AcompanamientoEmpresarial) || null}
						onChange={(_, newValue) => handleChangeNumeroDocEmpresa_AcompanamientoEmpresarial({ target: { value: newValue?.numero_documento || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
					/>


				</Grid>
				{/* Nombre empresa */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Nombre empresa
					</label>
					<Autocomplete
						options={companies}
						getOptionLabel={(option: any) => option.razon_social}
						value={companies.find((company: any) => company.razon_social === nombreEmpresa_AcompanamientoEmpresarial) || null}
						onChange={(_, newValue) => handleChangeNombreEmpresa_AcompanamientoEmpresarial({ target: { value: newValue?.razon_social || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
						renderOption={(props, option: any) => (
							<li {...props} key={option.id || option.razon_social}>
								{option.razon_social}
							</li>
						)}
					/>

				</Grid>
				{/* Código vacante */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Código vacante
					</label>
					<Autocomplete
						options={vacantes as any[]}
						getOptionLabel={(option: any) => `${option.codigo_vacante} - ${option.Empresa?.razon_social || ''}`}
						value={vacantes.find((v: any) => v.codigo_vacante === codigoVacante_AcompanamientoEmpresarial) || null}
						onChange={handleChangeCodigoVacante_AcompanamientoEmpresarial}
						renderInput={(params: any) => <TextField {...params} variant="outlined" />}
					/>
				</Grid>
				{/* Consejero empleador */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero empleador
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroEmpleador_AcompanamientoEmpresarial}
						onChange={handleChangeConsejeroEmpleador_AcompanamientoEmpresarial}
					>
						{consejeroEmpleador.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fecha de creación Ocupaciones  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaDeCreacion_AcompanamientoEmpresarial}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaDeCreacion_AcompanamientoEmpresarial}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFilters_AcompanamientoEmpresarial} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_AcompanamientoEmpresarial} endpoint='detallado/acompanamiento-empresarial' />
		</div>
	);
	// Fin AcompanamientoEmpresarial


	// Inicio Filtro Empresa
	const [consejeroEmpleadorAprobado, setConsejeroEmpleadorAprobado] = useState<any>([]);
	const [ultimoconsejeroEmpleador, setUltimoConsejeroEmpleador] = useState<any>([]);
	const [registroEmpresa, setRegistroEmpresa] = useState<any>([]);
	const [aprobacionEmpresa, setAprobacionEmpresa] = useState<any>([]);
	const [ultimaFechaDeActualizacion, setUltimaFechaDeActualizacion] = useState<any>([]);
	const [userEmpresa, setUserEmpresa] = useState<any>({});
	// Consejero empleador que aprobo la empresa
	const handleChangeConsejeroEmpleador = (event: any) => {
		setUserEmpresa({
			...userEmpresa,
			id_concejero_aprobo: event.target.value
		})
		setConsejeroEmpleadorAprobado(event.target.value);
	};
	// Último consejero asignado 
	const handleChangeUltimoConsejeroAsignado = (event: any) => {
		setUserEmpresa({
			...userEmpresa,
			id_concejero_asignado: event.target.value
		})
		setUltimoConsejeroEmpleador(event.target.value);
	};
	// Fecha de registro
	const handleRegistroEmpresaChange = (dates: any) => {
		setRegistroEmpresa(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY"),
			});

		}
	};
	// Fecha de aprobación
	const handleAprobacionEmpresaChange = (dates: any) => {
		setAprobacionEmpresa(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_de_aprobación_de_empresa_inicio: startDate.format("DD-MM-YYYY"),
				fecha_de_aprobación_de_empresa_fin: endDate.format("DD-MM-YYYY")
			});
		}
	};
	// Última fecha de actualización información
	const handleChangeActualizacionEmpresa = (dates: any) => {
		setUltimaFechaDeActualizacion(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_actualizacion_inicio: startDate.format("DD-MM-YYYY"),
				fecha_actualizacion_fin: endDate.format("DD-MM-YYYY")
			});
		}
	};
	// Limpiar campos
	const handleClearFiltersEmpresas = () => {
		setRegistroEmpresa([]);
		setAprobacionEmpresa([]);
		setConsejeroEmpleadorAprobado([]);
		setUltimoConsejeroEmpleador([]);
		setUltimaFechaDeActualizacion([]);
		setUserEmpresa({});
	};

	const Empresas = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Empresas</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Consejero empleador que aprobo la empresa */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero empleador que aprobo la empresa
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroEmpleadorAprobado}
						onChange={handleChangeConsejeroEmpleador}
					>
						{consejeroEmpleador.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Último consejero asignado  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Último consejero asignado
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={ultimoconsejeroEmpleador}
						onChange={handleChangeUltimoConsejeroAsignado}
					>
						{consejeroEmpleador.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Fecha de registro empresa */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro
						</label>
						<DatePicker
							id="registro-date-picker"
							value={registroEmpresa}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleRegistroEmpresaChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de aprobación de empresa  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de aprobación
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={aprobacionEmpresa}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleAprobacionEmpresaChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de aprobación vacantes  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Última fecha de actualización información
					</label>
					<DatePicker
						id="actualizacion-date-picker"
						value={ultimaFechaDeActualizacion}
						numberOfMonths={2}
						range={true}
						locale={colombia_es_lowercase}
						title='Rango de fechas'
						name='actualizacionFechas'
						placeholder='Rango de fechas'
						dateSeparator=' - '
						format='DD/MM/YY'
						onChange={handleChangeActualizacionEmpresa}
						style={{ padding: 20, fontSize: 14, height: 50 }}
					/>
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersEmpresas} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userEmpresa} endpoint='detallado/empresas' />
		</div>
	);
	// Fin Filtro Empresa


	// Inicio Filtro Vacantes
	const [razonSocial_vacantes, setRazonSocial_vacantes] = useState("")
	const [nombreVacante_vacantes, setNombreVacante_vacantes] = useState("")
	const [fechaCreacion_Vacantes, setFechaCreacion_Vacantes] = useState<any>([]);
	const [fechaLimite_Vacantes, setFechaLimite_Vacantes] = useState<any>([]);
	const [consejeroAprobo_vacantes, setConsejeroAprobo_vacantes] = useState("")
	const [ultimoConsejero_vacantes, setUltimoConsejero_vacantes] = useState("")
	const [filtrosDe_Vacantes, setFiltrosDe_Vacantes] = useState<any>({});

	//  Nombre empresa 
	const handleChangeNombreEmpresa_Vacantes = (event: any) => {
		setRazonSocial_vacantes(event.target.value);
		setFiltrosDe_Vacantes({
			...filtrosDe_Vacantes,
			razon_social: event.target.value
		})

	};
	//  Nombre vacante 
	// const handleChangeNombreVacante_Vacantes = (event: any) => {
	// 	setNombreVacante_vacantes(event.target.value);
	// 	setFiltrosDe_Vacantes({
	// 		...filtrosDe_Vacantes,
	// 		nombre_vacante: event.target.value
	// 	})
	// };
	const handleChangeNombreVacante_Vacantes = (_: any, newValue: any) => {
		setNombreVacante_vacantes(newValue ? newValue.nombre_vacante : null);
		setFiltrosDe_Vacantes((prev: any) => ({
			...prev,
			nombre_vacante: newValue ? newValue.nombre_vacante : null
		}));
	};
	// Fecha de creación 
	const handleChangefechaCreacion = (dates: any) => {
		setFechaCreacion_Vacantes(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Vacantes({
				...filtrosDe_Vacantes,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY"),
			});

		}
	};
	// Fecha límite de aplicación 
	const handleChangeFechaLimite = (dates: any) => {
		setFechaLimite_Vacantes(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltrosDe_Vacantes({
				...filtrosDe_Vacantes,
				fecha_limite_inicio: startDate.format("DD-MM-YYYY"),
				fecha_limite_fin: endDate.format("DD-MM-YYYY")
			});
		}
	};
	// Consejero empleador que aprobo la vacante 
	const handleChangeConsejeroEmpleador_Vacantes = (event: any) => {
		setConsejeroAprobo_vacantes(event.target.value);
		setFiltrosDe_Vacantes({
			...filtrosDe_Vacantes,
			id_concejero_aprobo: event.target.value
		})
	};
	// Último consejero asignado 
	const handleChangeUltimoConsejero_Vacantes = (event: any) => {
		setUltimoConsejero_vacantes(event.target.value);
		setFiltrosDe_Vacantes({
			...filtrosDe_Vacantes,
			id_concejero_asiganad: event.target.value
		})
	};
	// Limpiar campos
	const handleClearFiltersVacantes = () => {
		setRazonSocial_vacantes('');
		setNombreVacante_vacantes('');
		setFechaCreacion_Vacantes([]);
		setFechaLimite_Vacantes([]);
		setConsejeroAprobo_vacantes('');
		setUltimoConsejero_vacantes('');
		setFiltrosDe_Vacantes({});
	};

	const Vacantes = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Vacantes</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Razón social */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Razón social
					</label>
					{/* <Autocomplete
						options={companies}
						getOptionLabel={(option: any) => option.razon_social}
						value={companies.find((company: any) => company.razon_social === razonSocial_vacantes) || null}
						onChange={(_, newValue) => handleChangeNombreEmpresa_Vacantes({ target: { value: newValue?.razon_social || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
					/> */}
					<Autocomplete
						options={companies}
						getOptionLabel={(option: any) => option.razon_social}
						value={companies.find((company: any) => company.razon_social === razonSocial_vacantes) || null}
						onChange={(_, newValue) => handleChangeNombreEmpresa_Vacantes({ target: { value: newValue?.razon_social || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
						renderOption={(props, option: any) => (
							<li {...props} key={option.id || option.razon_social}>
								{option.razon_social}
							</li>
						)}
					/>

				</Grid>
				{/* Nombre de la vacante */}
				<Grid lg={7} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Nombre de la vacante
					</label>

					<Autocomplete
						options={vacantes as any[]}
						getOptionLabel={(option: any) => `${option.nombre_vacante} - ${option.Empresa?.razon_social || ''}`}
						value={vacantes.find((v: any) => v.nombre_vacante === nombreVacante_vacantes) || null}
						onChange={handleChangeNombreVacante_Vacantes}
						renderInput={(params: any) => <TextField {...params} variant="outlined" />}
						renderOption={(props, option: any) => (
							<li {...props} key={option.id || `${option.nombre_vacante}-${option.Empresa?.razon_social}`}>
								{`${option.nombre_vacante} - ${option.Empresa?.razon_social || ''}`}
							</li>
						)}
					/>


				</Grid>
				{/* Fecha de creación  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de creación
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaCreacion_Vacantes}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangefechaCreacion}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha límite de aplicación  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha límite de aplicación
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={fechaLimite_Vacantes}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaLimite}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Consejero empleador que aprobo la vacante */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Consejero empleador que aprobo la vacante
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={consejeroAprobo_vacantes}
						onChange={handleChangeConsejeroEmpleador_Vacantes}
					>
						{consejeroEmpleador.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Último consejero asignado */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Último consejero asignado
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={ultimoConsejero_vacantes}
						onChange={handleChangeUltimoConsejero_Vacantes}
					>
						{consejeroEmpleador.map((item: any) => (
							<MenuItem key={item.id} value={item.id}>
								{`${item.UserComplemento.primer_nome} ${item.UserComplemento.primer_apelido}`}
							</MenuItem>
						))}
					</Select>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersVacantes} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtrosDe_Vacantes} endpoint='detallado/vacantes' />
		</div>
	);
	// Fin Filtro Vacantes

	// Inicio Filtro Remitidos
	const [numeroDoc_Remitidos, setNumeroDoc_Remitidos] = useState("")
	const [condigoVacantes_Remitidos, setCondigoVacantes_Remitidos] = useState("")
	const [fechaRemision_Remitidos, setFechaRemision_Remitidos] = useState<any>([]);
	const [filtros_Remitidos, setFiltros_Remitidos] = useState<any>({});
	//  Numero documento empresa 
	const handleChangeNumeroDoc_Remitidos = (event: any) => {
		setNumeroDoc_Remitidos(event.target.value);
		setFiltros_Remitidos({
			...filtros_Remitidos,
			numero_documento: event.target.value
		})

	};
	//  Código de la vacante 
	const handleChangeCodigoVacante_Remitidos = (_: any, newValue: any) => {
		setCondigoVacantes_Remitidos(newValue ? newValue.codigo_vacante : null);
		setFiltros_Remitidos((prev: any) => ({
			...prev,
			codigo_vacante: newValue ? newValue.codigo_vacante : null
		}));
	};
	// Fecha de remisón
	const handleChangeFechaRemision_Remitidos = (dates: any) => {
		setFechaRemision_Remitidos(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltros_Remitidos({
				...filtros_Remitidos,
				fecha_inicio: startDate.format("DD-MM-YYYY"),
				fecha_fin: endDate.format("DD-MM-YYYY")
			});

		}
	}
	// Limpiar campos
	const handleClearFilters_Remitidos = () => {
		setNumeroDoc_Remitidos('');
		setCondigoVacantes_Remitidos('');
		setFechaRemision_Remitidos([]);
		setFiltros_Remitidos({});
	};

	const Remitidos = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Remitidos</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Numero documento empresa  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Numero documento empresa
					</label>
					<Autocomplete
						options={companiesDoc}
						getOptionLabel={(option: any) => `${option.razon_social} - ${option.numero_documento}`}
						value={companiesDoc.find((company: any) => company.numero_documento === numeroDoc_Remitidos) || null}
						onChange={(_, newValue) => handleChangeNumeroDoc_Remitidos({ target: { value: newValue?.numero_documento || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
					/>
				</Grid>
				{/* Código de la vacante  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Código de la vacante
					</label>
					<Autocomplete
						options={vacantes as any[]}
						getOptionLabel={(option: any) => `${option.codigo_vacante} - ${option.Empresa?.razon_social || ''}`}
						value={vacantes.find((v: any) => v.codigo_vacante === condigoVacantes_Remitidos) || null}
						onChange={handleChangeCodigoVacante_Remitidos}
						renderInput={(params: any) => <TextField {...params} variant="outlined" />}
					/>
				</Grid>
				{/* Fecha de remisón  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de remisón
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaRemision_Remitidos}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaRemision_Remitidos}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFilters_Remitidos} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtros_Remitidos} endpoint='detallado/remitidos' />
		</div>
	);
	// Fin Filtro Remitidos

	// Inicio Colocados
	const [numeroDoc_Colocados, setNumeroDoc_Colocados] = useState("")
	const [condigoVacantes_Colocados, setCondigoVacantes_Colocados] = useState("")
	const [fechaRemision_Colocados, setFechaRemision_Colocados] = useState<any>([]);
	const [filtros_Colocados, setFiltros_Colocados] = useState<any>({});
	//  Numero documento empresa 
	const handleChangeNumeroDoc_Colocados = (event: any) => {
		setNumeroDoc_Colocados(event.target.value);
		setFiltros_Colocados({
			...filtros_Colocados,
			numero_documento: event.target.value
		})

	};
	//  Código de la vacante 
	const handleChangeCodigoVacante_Colocados = (_: any, newValue: any) => {
		setCondigoVacantes_Colocados(newValue ? newValue.codigo_vacante : null);
		setFiltros_Colocados((prev: any) => ({
			...prev,
			codigo_vacante: newValue ? newValue.codigo_vacante : null
		}));
	};
	// Fecha de colocación 
	const handleChangeFechaRemision_Colocados = (dates: any) => {
		setFechaRemision_Colocados(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setFiltros_Colocados({
				...filtros_Colocados,
				fecha_inicio: startDate.format("DD-MM-YYYY"),
				fecha_fin: endDate.format("DD-MM-YYYY")
			});

		}
	}
	// Limpiar campos
	const handleClearFilters_Colocados = () => {
		setNumeroDoc_Colocados('');
		setCondigoVacantes_Colocados('');
		setFechaRemision_Colocados([]);
		setFiltros_Colocados({});
	};
	const Colocados = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Colocados</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Numero documento empresa  */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Numero documento empresa
					</label>
					
					<Autocomplete
						options={companiesDoc}
						getOptionLabel={(option: any) => `${option.razon_social} - ${option.numero_documento}`}
						value={companiesDoc.find((company: any) => company.numero_documento === numeroDoc_Colocados) || null}
						onChange={(_, newValue) => handleChangeNumeroDoc_Colocados({ target: { value: newValue?.numero_documento || '' } })}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" fullWidth />
						)}
					/>
				</Grid>
				{/* Código de la vacante */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Código de la vacante
					</label>
					<Autocomplete
						options={vacantes as any[]}
						getOptionLabel={(option: any) => `${option.codigo_vacante} - ${option.Empresa?.razon_social || ''}`}
						value={vacantes.find((v: any) => v.codigo_vacante === condigoVacantes_Colocados) || null}
						onChange={handleChangeCodigoVacante_Colocados}
						renderInput={(params: any) => <TextField {...params} variant="outlined" />}
					/>
				</Grid>
				{/* Fecha de colocación  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de colocación
						</label>
						<DatePicker
							id="registro-date-picker"
							value={fechaRemision_Colocados}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleChangeFechaRemision_Colocados}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFilters_Colocados} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={filtros_Colocados} endpoint='detallado/colocados' />
		</div>
	);
	// Fin Colocados

	// Mapeo de los botones a sus respectivos componentes
	const components = {
		Candidatos: <Candidatos />,
		NivelEducativo: <NivelEducativo />,
		ExperienciaLaboral: <ExperienciaLaboral />,
		CitasVirtuales: <CitasVirtuales />,
		Formacion: <Formacion />,
		Orientacion: <Orientacion />,
		AcompanamientoACandidatos: <AcompanamientoACandidatos />,
		AcompanamientoEmpresarial: <AcompanamientoEmpresarial />,
		Empresas: <Empresas />,
		Vacantes: <Vacantes />,
		Remitidos: <Remitidos />,
		Colocados: <Colocados />,
	};

	// Definir nombres más amigables
	const labels: any = {
		Candidatos: "Candidatos",
		NivelEducativo: "Nivel Educativo",
		ExperienciaLaboral: "Experiencia Laboral",
		CitasVirtuales: "Citas Virtuales",
		Formacion: "Formación",
		Orientacion: "Orientación",
		AcompanamientoACandidatos: "Acompañamiento a Candidatos",
		AcompanamientoEmpresarial: "Acompañamiento Empresarial",
		Empresas: "Empresas",
		Vacantes: "Vacantes",
		Remitidos: "Remitidos",
		Colocados: "Colocados",
	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-xs-12 col-sm-10 col-md-10'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>

						</div>
					</div>

				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className='d-flex ' style={{}}>
					<div className='col-lg-3 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px', background: '#B0CFF7' }}>
						<div style={{ background: '#4683DE', padding: 15, marginBottom: 20 }}>
							<h5 style={{ color: '#fff', textAlign: 'center' }}>Tipo de Reporte</h5>
						</div>
						<div className="d-flex flex-column">
							{Object.keys(components).map((key) => (
								<button
									key={key}
									className="btn my-1"
									style={{
										backgroundColor: selectedKey === key ? "#4683DE" : "white",
										color: selectedKey === key ? "white" : "black",
									}}
									onClick={() => setSelectedKey(key)}
								>
									{labels[key]}
								</button>
							))}
						</div>
					</div>
					<div className='col-lg-9 col-xs-12 col-sm-6 col-md-4 d-flex justify-content-center align-items-center' style={{ padding: '10px' }}>

						{selectedKey ? components[selectedKey as keyof typeof components] : (
							<h4>SECCIÓN DE CONFIGURACIÓN DE REPORTE Y DESCARGA</h4>
						)}

					</div>
				</div>



			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

export { DetailedReportingPanel };
