import { useEffect, useState } from 'react';
import styleCss from './styles.module.scss';
import PaginationComponent from '../../components/Pagination/Index';
import { Link } from 'react-router-dom';
import { getVagaCurriculoById } from '../../modules/apps/user-management/users-list/core/_requests';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import usePublicVacancyParams from '../../hooks/parameters/public-vacancy/usePublicVacancyParams';
const PublicVacanciesPage = () => {
	const [data, setData]: any[] = useState([]);
	const {
		listNivelEducativo,
		listTipoContrato
	} = usePublicVacancyParams();
	const [userData, setUserData]: any[] = useState([]);

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});
	const [loading, setLoading] = useState(false);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};
	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);
	const handleInputChange = (e: any) => {
		if (e.key !== 'Enter') setUserData({ ...userData, nombre_vacante: e.target.value });

	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter') handleSubmit();
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			var nivelestudio = userData.nivel_estudio;
			var tipocontrato = userData.tipo_contrato;
			if (userData.nivel_estudio === 'Escolaridad') {
				nivelestudio = '';
			}
			if (userData.tipo_contrato === 'Tipo de contrato') {
				tipocontrato = '';
			}
			const response = await getVagaCurriculoById(
				`page=${filter.page}&limit=${filter.limit}&nombre_vacante=${userData.nombre_vacante}&nivel_estudio=${nivelestudio}&tipo_contrato=${tipocontrato}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				alert(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={`${styleCss['custom-body']}`}>
				<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#D0DDF0' }}>
					<div className='row align-items-center'>
						<div className='col-md-2'>
							<input
								value={userData['nombre_vacante']}
								onChange={(e) => handleInputChange(e)}
								onKeyDown={(e) => handleKeyDown(e)}
								className='form-control'
								type='text'
								placeholder='Busca la vacante'
							/>
						</div>
						<div className='col-md-2'>
							<select
								className='form-control form-select'
								style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
								aria-label='schooling'
								value={userData['nivel_estudio']}
								onChange={(e) => setUserData({ ...userData, nivel_estudio: e.target.value })}
							>
								<option defaultValue={-1}>Escolaridad</option>
								{listNivelEducativo.map((x, i) => (
									<option key={i} value={x.code}>
										{x.value}
									</option>
								))}
							</select>
						</div>
						<div className='col-md-2'>
							<select
								className='form-control form-select'
								style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
								aria-label='typeContract'
								value={userData['tipo_contrato']}
								onChange={(e) => setUserData({ ...userData, tipo_contrato: e.target.value })}
							>
								<option defaultValue={-1}>Tipo de contrato</option>
								{listTipoContrato.map((item, i) => (
									<option key={i} value={item.code}>
										{item.value}
									</option>
								))}
							</select>
						</div>
						<div className='col-md-2'>
							<button
								className={`form-control btn ${styleCss['btn-red']}`}
								onClick={handleSubmit}
							>
								<i className='bi bi-search'></i>Buscar
							</button>
						</div>
					</div>
				</div>
				{data.length === 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{data.length} Vacantes de empleo encontradas
							</p>
						</div>
						<hr />
					</div>
				)}
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.length !== 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{total} Vacantes de empleo encontradas
							</p>
						</div>
						<hr />

						<div className='row'>
							{!loading && data.map((x: any, i: number) => (
								<div className='col-sm-4' key={i}>
									<div className='card shadow p-3 mb-5 bg-white rounded' style={{ height: '95%' }}>
										<div className='card-body'>
											<h5 className='card-title text-primary'>{x.nombre_vacante}</h5>
											<div>
												<h6
													style={{ display: 'inline' }}
													className='card-subtitle mb-2 mt-4 text-muted'
												>
													<b>{x.Empresa?.razon_social}</b>
												</h6>
												<span style={{ display: 'inline', marginLeft: '40px' }}>
													<b>Fecha de anuncio:</b> {x.createdAt.toLocaleString().substr(0, 10)}
												</span>
											</div>

											<div className='card-text mt-4'>
												<span className='mt-4 aa'>
													<p>{x.Describa_vacante}</p>
												</span>
											</div>
											<div className='card-text mt-4'>
												<span className='mt-4'>
													<i className='bi bi-book' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>Nivel de estudios: </b>
													{listNivelEducativo.find((y) => y.code === x.nivel_estudio)?.value}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-stopwatch' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>Experiencia: </b>
													{x.rango_experiencia} meses
												</span>
												<br />
												<span className='mt-4'>
													<i className={`bi ${x.vacante_susceptible_teletrabajo == '1' ? 'bi-house-door' : 'bi-building'}`} style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{x.vacante_susceptible_teletrabajo == '1' ? 'Remoto' : 'Presencial'}</b>
												</span>
												<br />
											</div>
											<Link
												to={`/vacante/${x.id}`}
												className={`btn btn-primary btn-lg btn-block`}
												style={{
													padding: '8px 20px',
													float: 'right',
												}}
											>
												Ver vacante
											</Link>
										</div>
									</div>
								</div>
							))}
						</div>
						<PaginationComponent
							totalPage={totalPage}
							total={total}
							customClass={''}
							paginate={changePaginate}
						/>
					</div>
				)}
			</div>
		</>
	);
};

const PublicVacanciesWrapper = () => {
	return (
		<>
			<PublicVacanciesPage />
		</>
	);
};

export { PublicVacanciesWrapper };
