import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, AlertTitle, FormControl, InputLabel, Select, MenuItem, TextField, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useAuth } from '../../modules/auth';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import useDocumentTypeParams from '../../hooks/parameters/useDocumentTypeParams';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getArchivoTrazabilidad, getCandidato, getCandidatTrazabilidad, postParticipanteIndividual } from '../../modules/apps/user-management/users-list/core/_requests';


const TraceabilityManagementOfCare = () => {

	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [requestInProgress, setRequestInProgress] = useState(false);

	const [open, setOpen] = useState(false);

	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const navigate = useNavigate();


	const handleClose = () => {
		setOpen(false);
	};




	const [tipoDoc, setTipoDoc] = useState<any>('')
	const [numeroDoc, setNumeroDoc] = useState('');
	const [showTable, setShowTable] = useState(false)
	const [dataUserIndividual, setDataUserIndividual] = useState<any>([])
	const { listTipoDocumentoCandidato } = useDocumentTypeParams();


	const handleSelectDoc = (event: any) => {
		setTipoDoc(event.target.value);
	};
	const handleNumeroDocChange = (event: any) => {
		setNumeroDoc(event.target.value.trim());
	};

	const handleGetCanditado = async () => {
		setLoading(true);
		const params = {
			numero_doc: numeroDoc,
			tipo_doc: tipoDoc
		}

		try {
			const response = await getCandidatTrazabilidad(params);
			setDataUserIndividual(response.data)
			setShowTable(true)
			setAlertMsg('Participante encontrado');
			setOpen(true);
			setAlert(true);
			setTimeout(() => {
				setOpen(false);
			}, 1000);
		} catch (error: any) {
			setAlertMsg(error.response.data.error);
			setOpen(true);
			setAlert(false);
			setTimeout(() => {
				setOpen(false);
			}, 2500);
		} finally {
			setLoading(false);
		}
	};

	// Descargar formato de excel
	const handleGetDescargarExcel = async () => {
		setLoading(true);
		const numero_doc = dataUserIndividual.userCv.UserComplemento.numero_doc
		const tipo_doc = dataUserIndividual.userCv.UserComplemento.tipo_doc
		const tipo_documento = "xlsx"
		try {
			// {numero_doc, tipo_doc, tipo_documento }
			// {, dataUserIndividual.userCv.UserComplemento.numero_doc,}
			const blob = await getArchivoTrazabilidad({ numero_doc, tipo_doc, tipo_documento });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'trazabilidad_de_atencion.xlsx';
			link.click();
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Iniciando descarga del excel!`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);

		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	// Descargar PDF
	const handleDescargarCertificado = async () => {
		const numero_doc = dataUserIndividual.userCv.UserComplemento.numero_doc
		const tipo_doc = dataUserIndividual.userCv.UserComplemento.tipo_doc
		const tipo_documento = "pdf"
		try {
			const res = await getArchivoTrazabilidad({ numero_doc, tipo_doc, tipo_documento });
			// Si el archivo es PDF
			if (res.type === 'application/pdf') {
				const url = window.URL.createObjectURL(res);
				const link = document.createElement('a');
				link.href = url;
				link.download = 'pdf_de_trazabilidad_de_atencion.pdf';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}

			else {
				console.error('El archivo no es un ZIP');
			}

		} catch (error: any) {
			console.error('Error al descargar:', error);
		}
	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-xs-12 col-sm-10 col-md-10'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>

						</div>
					</div>

				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className='d-flex ' style={{ width: '60%', height: '100px' }}>
					<div className='col-lg-5 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">TIPO DE DOCUMENTO</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={tipoDoc}
								label="TIPO DE DOCUMENTO"
								onChange={handleSelectDoc}
							>
								{listTipoDocumentoCandidato.map((doc) => (
									<MenuItem key={doc.id} value={doc.code}>
										{doc.value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>

					<div className='col-lg-5 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
						<TextField fullWidth id="outlined-basic" label="NÚMERO DE DOCUMENTO" variant="outlined" value={numeroDoc}
							onChange={handleNumeroDocChange}
						/>
					</div>

					<div className='col-lg-2 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
						<Button variant="contained" disabled={tipoDoc === '' || numeroDoc === ''} size="large" onClick={handleGetCanditado}>
							Buscar
						</Button>
					</div>
				</div>

				{

					showTable &&
					<>
						{/* INFORMACION CANDIDATO */}
						<div className='container' style={{ marginBottom: 20 }}>
							<div style={{ background: '#A9D18E', padding: 10, font: 'bold' }}>
								INFORMACION CANDIDATO
							</div>
							<TableContainer component={Paper} >
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow sx={{ backgroundColor: "#4683DE" }}>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												PRIMER NOMBRE
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												SEGUNDO NOMBRE
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												PRIMER APELLIDO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												SEGUNDO APELLIDO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA DE REGISTRO HV
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												HOJA DE VIDA COMPLETA
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA DE ÚLTIMA ACTULIZACION HV
											</TableCell>


										</TableRow>
									</TableHead>
									<TableBody>


										<TableRow
											key={dataUserIndividual?.userCv?.id}
										// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>

											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{dataUserIndividual?.userCv?.UserComplemento?.primer_nome}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{dataUserIndividual?.userCv?.UserComplemento?.segundo_nome}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{dataUserIndividual?.userCv?.UserComplemento?.primer_apelido}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{dataUserIndividual?.userCv?.UserComplemento?.segundo_apelido}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{`${new Date(dataUserIndividual?.userCv?.UserCurriculo?.createdAt).toLocaleDateString('es-ES')}`}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{dataUserIndividual?.userCv?.UserCurriculo?.porcentaje_diligenciamiento === 100 && 'SI'}
											</TableCell>
											<TableCell
												style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}
											>
												{`${new Date(dataUserIndividual?.userCv?.UserCurriculo?.updatedAt).toLocaleDateString('es-ES')}`}
											</TableCell>
										</TableRow>

									</TableBody>
								</Table>
							</TableContainer>
						</div>

						{/* ORIENTACIÓN */}
						<div className='container' style={{ marginBottom: 20 }}>
							<div style={{ background: '#FFC100', padding: 10, font: 'bold' }}>
								ORIENTACIÓN
							</div>
							<TableContainer component={Paper} >
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow sx={{ backgroundColor: "#4683DE" }}>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												CÓDIGO DIRECCIONAMIENTO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												TIPO DIRECCIONAMIENTO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												SUBTIPO DIRECCIONAMIENTO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA REGISTRO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA CIERRE
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataUserIndividual?.userDireccionamiento.map((row: any) => (
											<TableRow
												key={row?.id}
											>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', textTransform:'uppercase'
													 }
													}>
													{row?.Direccionamiento?.codigo_direccionamiento}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.Direccionamiento?.tipoDireccionamiento?.label}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.Direccionamiento?.subtipoDireccionamiento?.label}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.Direccionamiento?.fecha_registro
														? new Date(row?.Direccionamiento?.fecha_registro).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
													{/* {`${new Date(row?.Direccionamiento?.fecha_registro).toLocaleDateString('es-ES')}`} */}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.Direccionamiento?.fecha_cierre
														? new Date(row?.Direccionamiento?.fecha_cierre).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
													{/* {`${new Date(row?.Direccionamiento?.fecha_cierre).toLocaleDateString('es-ES')}`} */}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>

						{/* FORMACIÓN */}
						<div className='container' style={{ marginBottom: 20 }}>
							<div style={{ background: '#F8CBAD', padding: 10, font: 'bold' }}>
								FORMACIÓN
							</div>
							<TableContainer component={Paper} >
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow sx={{ backgroundColor: "#4683DE" }}>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												CÓDIGO CONVOCATORIA
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												TIPO CONVOCATORIA
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												PROGRAMA FORMACIÓN
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FEHCA DE REGISTRO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												APROBADO
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA CIERRE
											</TableCell>


										</TableRow>
									</TableHead>
									<TableBody>
										{dataUserIndividual?.userConvocatoria.map((row: any) => (
											<TableRow
												key={row?.id}
											>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', textTransform:'uppercase' }
													}>
													{row?.Convocatorium?.codigo_convocatoria}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.Convocatorium?.TipoConvocatorium?.name}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.Convocatorium?.ProgramaFormacion?.name}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.Convocatorium?.fecha_registro
														? new Date(row.Convocatorium.fecha_registro).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.aprobado}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.Convocatorium?.fecha_cierre
														? new Date(row.Convocatorium.fecha_cierre).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>

						{/* REMISIÓN-COLOCACIÓN */}
						<div className='container' style={{ marginBottom: 20 }}>
							<div style={{ background: '#D9D9D9', padding: 10, font: 'bold' }}>
								REMISIÓN-COLOCACIÓN
							</div>
							<TableContainer component={Paper} >
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow sx={{ backgroundColor: "#4683DE" }}>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												CÓDIGO VACANTE
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												NOMBRE VACANTE
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												NOMBRE EMPRESA
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA DE REMISIÓN
											</TableCell>
											<TableCell
												sx={{ color: "white", fontWeight: "bold" }} align="center"
											>
												FECHA DE COLOCACION
											</TableCell>

										</TableRow>
									</TableHead>

									<TableBody>
										{dataUserIndividual?.userVacante.map((row: any) => (
											<TableRow
												key={row?.id}
											>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}>
													{row?.EmpresaVaga?.codigo_vacante}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.EmpresaVaga?.nombre_vacante}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{row?.EmpresaVaga?.Empresa?.razon_social}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.fecha_remitida
														? new Date(row?.fecha_remitida).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
												</TableCell>
												<TableCell
													style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }
													}
												>
													{`${row?.fecha_contratada
														? new Date(row?.fecha_contratada).toLocaleDateString('es-ES')
														: 'Fecha no disponible'}`
													}
												</TableCell>
											</TableRow>
										))}
									</TableBody>

								</Table>
							</TableContainer>
						</div>

						<div className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
							<h4>¿En cuál formato deseas el documento?</h4>
						</div>

						<div className="d-flex justify-content-around">
							<Button variant="contained" size="large" startIcon={<CloudDownloadIcon />} onClick={handleDescargarCertificado}>
								Formato .PDF
							</Button>
							<Button variant="contained" size="large" startIcon={<CloudDownloadIcon />} onClick={handleGetDescargarExcel}>
								Formato .XLSX
							</Button>
						</div>
					</>
				}


			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

export { TraceabilityManagementOfCare };
