import { Route, Routes, Navigate, Outlet, Link } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import CurriculumContext from '../contexts/CurriculumContext';
import UserRegistryContext from '../contexts/UserRegistryContext';
import VacancyContext from '../contexts/VacancyContext';
import { AdminAreaWrapper } from '../pages/admin/AdminAreaWrapper';
import { EmployerAreaWrapper } from '../pages/employer/EmployerAreaWrapper';
import { ProvisionalAreaWrapper } from '../pages/employer/ProvisionalAreaWrapper';
import { CounselorAreaWrapper } from '../pages/counselor/CounselorAreaWrapper';
import { EmployerMatchingCandidateWrapper } from '../pages/employer/EmployerMatchingCandidateWrapper';
import { CandidateAreaWrapper } from '../pages/candidate/CandidateAreaWrapper';
import { AdminCompanyManagementWrapper } from '../pages/admin/AdminCompanyManagementWrapper';
import { AdminRegistroManagementWrapper } from '../pages/admin/AdminRegistroManagementWrapper';
import { CandidateMatchingVacancyWrapper } from '../pages/candidate/CandidateMatchingVacancyWrapper';
import { CandidateRequestedVacancyWrapper } from '../pages/candidate/CandidateRequestedVacancyWrapper';
import { CandidateSearchVacantes } from '../pages/candidate/CandidateSearchVacantes';
import CustomCalendarComponent from '../components/CustomCalendar/Index';
import * as authHelper from '../modules/auth/core/AuthHelpers';
import { RoleEnum } from '../models/enums/RoleEnum';
import { AuthPage } from '../modules/auth';
import ResumeComponent from '../components/Resume/Index';
import ResumeCandidatoComponent from '../components/ResumeCandidato/Index';
import VacancyComponent from '../components/Vacancy/Index';
import { EmployerManagementVacancyWrapper } from '../pages/employer/EmployerManagementVacancyWrapper';
import { EmployerRequestedFeedbackCandidatoWrapper } from '../pages/employer/EmployerRequestedFeedbackCandidatoWrapper';
import { EmployerRequestedCandidatoVacancyWrapper } from '../pages/employer/EmployerRequestedCandidatoVacancyWrapper';
import { EmployerRequestedCandidatoPostularWrapper } from '../pages/employer/EmployerRequestedCandidatoPostularWrapper';
import { CandidatosManagementWrapper } from '../pages/counselor/CandidatosManagementWrapper';
import Profile from '../pages/profile/Profile';
import CompanyEditContext from '../contexts/CompanyEditContext';
import CompanyContext from '../contexts/CompanyContext';
import InformacionAdicionalVacancyContext from '../contexts/InformacionAdicionalVacancyContext';
import InformacionAdicionalEmpresaContext from '../contexts/InformacionAdicionalEmpresaContext';

import { UserJwtModel } from '../models/UserJwtModel';
import { RequireAuth } from '../modules/auth/RequireAuth';
import { PoliticasDashboardWrapper } from '../pages/dashboard/PoliticasDashboardWrapper';
import { EmpresasDashboardWrapper } from '../pages/dashboard/EmpresasDashboardWrapper';
import { CandidatosDashboardWrapper } from '../pages/dashboard/CandidatosDashboardWrapper';
import { EmployerFilesWrapper } from '../pages/employer/EmployerFilesManagementWrapper';
import BitacoraContext from '../contexts/BitacoraContext'
import { EmployerManagementBitacoraWrapper } from '../pages/employer/EmployerManagementBitacoraWrapper';


type RequireAuthProps = {
	userRole: number,
	allowedRol: number
};

const PrivateRoutes = () => {

	const { user } = authHelper.getAuth() || {};
	const userJwt: UserJwtModel | undefined = authHelper.getAuthFromTokenJwt();
	const role = userJwt?.role;
	const hasCompany = user?.empresa_id ? true : false;

	const RoutesAdmin = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						{/* Solucion temporal al problema de clic en logos y redireccion a admin area cuando es se creo una empresa con rol administrador */}
						<Route path='auth/*' element={<Navigate to={hasCompany ? '/empleador/area' : '/admin/area'} />} />
						<Route path='admin/area' element={<AdminAreaWrapper />} />
						<Route path='admin/gestion-candidatos' element={<CandidatosManagementWrapper />} />
						<Route path='admin/gestion-empresas' element={<AdminCompanyManagementWrapper />} />
						<Route path='admin/gestion-registros' element={<AdminRegistroManagementWrapper />} />
						<Route path='admin/nuevo-usuario' element={<UserRegistryContext />} />
						<Route path='admin/editar-usuario/:id' element={<UserRegistryContext />} />
						<Route path='admin/registro-de-empresa' element={<CompanyContext />} />
						<Route path='admin/registro-de-empresa/:id' element={<CompanyEditContext />} />
						<Route path='admin/gestion-vacantes' element={<EmployerManagementVacancyWrapper />} />
						<Route path='admin/gestion-de-vacante' element={<VacancyContext />} />
						<Route path='admin/gestion-de-vacante/:id' element={<VacancyContext />} />
						<Route path='admin/candidatos-postula/:id_vacante' element={<EmployerRequestedCandidatoPostularWrapper />} />
						<Route path='admin/hoja-vida/:id/:id_empresa/:id_vacancy' element={<ResumeComponent />} />
						<Route path='admin/matching-candidatos/:id' element={<EmployerMatchingCandidateWrapper />} />
						<Route path='admin/hoja-vida/:id' element={<ResumeComponent />} />
						<Route path='admin/crear-hoja-vida/:id' element={<CurriculumContext />} />
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path='admin/dashboard/politicas' element={<PoliticasDashboardWrapper />} />
						<Route path='admin/dashboard/empresas' element={<EmpresasDashboardWrapper />} />
						<Route path='admin/dashboard/candidatos' element={<CandidatosDashboardWrapper />} />
						<Route path='admin/adicional-vacante/:id' element={<InformacionAdicionalVacancyContext />} />					
						<Route path='admin/adicional-empresa/:id' element={<InformacionAdicionalEmpresaContext />} />	
						<Route path='admin/empresa/:id_empresa/archivos' element={<EmployerFilesWrapper />} />
						<Route path='admin/gestion-bitacoras/:id_postulacion' element={<EmployerManagementBitacoraWrapper />}/>
						<Route path='admin/bitacora/:id_postulacion/:id' element={<BitacoraContext />} />
					</Route>
				</Route>
			</Routes>
		);
	};


	const RoutesCandidate = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						<Route path='auth/*' element={<Navigate to='/candidato/area' />} />
						<Route path='candidato/area' element={<CandidateAreaWrapper />} />
						<Route path='candidato/matching-vacantes' element={<CandidateMatchingVacancyWrapper />} />
						<Route path='candidato/search-vacantes' element={<CandidateSearchVacantes />} />
						<Route path='candidato/search-vacantes/:vacante' element={<CandidateSearchVacantes />} />
						<Route path='candidato/postuladas-vacantes' element={<CandidateRequestedVacancyWrapper />} />
						<Route path='candidato/crear-hoja-vida/:id' element={<CurriculumContext />} />
						<Route path='candidato/citas-programadas' element={<CustomCalendarComponent />} />
						<Route path='candidato/vacante/:id' element={<VacancyComponent />} />
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path='candidato/dashboard/candidato' element={<CandidatosDashboardWrapper />} />
					</Route>
				</Route>
			</Routes>
		);
	};

	const RoutesCounselorCandidate = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						<Route path='auth/*' element={<Navigate to='/consejero/area' />} />
						<Route path='consejero/area' element={<CounselorAreaWrapper />} />
						<Route path='consejero/nuevo-usuario' element={<UserRegistryContext />} />
						<Route path='consejero/gestion-candidatos' element={<CandidatosManagementWrapper />} />
						<Route path='consejero/search-vacantes/:id_candidato' element={<CandidateSearchVacantes/>}/>
						<Route path='consejero/vacante/:id/:id_candidato' element={<VacancyComponent />} />
						<Route path='consejero/candidatos-postula/:id_vacante' element={<EmployerRequestedCandidatoPostularWrapper />} />
						<Route path='consejero/hoja-vida/:id' element={<ResumeComponent />} />
						<Route path='consejero/crear-hoja-vida/:id' element={<CurriculumContext />} />
						<Route path='consejero/postuladas-vacantes/:id_candidato' element={<CandidateRequestedVacancyWrapper />} />
						<Route path='consejero/vacante/:id/:id_candidato' element={<VacancyComponent />} />						
						<Route path='consejero/citas-programadas' element={<CustomCalendarComponent />} />
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Route>
				</Route>
			</Routes>
		);
	};
	const RoutesCounselorEmployer = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						<Route path='auth/*' element={<Navigate to='/consejero/area' />} />
						<Route path='consejero/area' element={<CounselorAreaWrapper />} />
						<Route path='consejero/adicional-vacante/:id' element={<InformacionAdicionalVacancyContext />} />						
						<Route path='consejero/adicional-empresa/:id' element={<InformacionAdicionalEmpresaContext />} />						
						<Route path='consejero/gestion-empresas' element={<AdminCompanyManagementWrapper />} />
						<Route path='consejero/registro-de-empresa' element={<CompanyContext />} />
						<Route path='consejero/registro-de-empresa/:id' element={<CompanyEditContext />} />
						<Route path='consejero/gestion-de-vacante' element={<VacancyContext />} />
						<Route path='consejero/gestion-vacantes' element={<EmployerManagementVacancyWrapper />} />
						<Route path='consejero/gestion-de-vacante/:id' element={<VacancyContext />} />
						<Route path='consejero/candidatos-postula/:id_vacante' element={<EmployerRequestedCandidatoPostularWrapper />} />
						<Route path='consejero/hoja-vida/:id/:id_empresa/:id_vacancy' element={<ResumeComponent />} />
						<Route path='consejero/matching-candidatos/:id' element={<EmployerMatchingCandidateWrapper />} />
						<Route path='consejero/hoja-vida/:id' element={<ResumeComponent />} />	
						<Route path='consejero/empresa/:id_empresa/archivos' element={<EmployerFilesWrapper />} />
						<Route path='consejero/gestion-bitacoras/:id_postulacion' element={<EmployerManagementBitacoraWrapper />}/>		
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path='consejero/bitacora/:id_postulacion/:id' element={<BitacoraContext />} />
					</Route>
				</Route>
			</Routes>
		);
	};

	const RoutesEmployer = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						<Route path='auth/*' element={<Navigate to='/empleador/area' />} />
						<Route path='empleador/area' element={<EmployerAreaWrapper />} />
						<Route path='empleador/matching-candidatos' element={<EmployerMatchingCandidateWrapper />} />
						<Route path='empleador/matching-candidatos/:id' element={<EmployerMatchingCandidateWrapper />} />
						<Route path='empleador/gestion-candidatos/:id_empresa' element={<EmployerRequestedCandidatoVacancyWrapper />} />
						<Route path='empleador/feedback-candidatos/:id_empresa' element={<EmployerRequestedFeedbackCandidatoWrapper />} />
						<Route path='empleador/candidatos-postula/:id_vacante' element={<EmployerRequestedCandidatoPostularWrapper />} />
						<Route path='empleador/gestion-vacantes' element={<EmployerManagementVacancyWrapper />} />
						<Route path='empleador/hoja-vida/:id/:id_empresa/:id_vacancy' element={<ResumeComponent />} />
						<Route path='empleador/entrevista/:id/:id_empresa/:id_vacancy' element={<ResumeCandidatoComponent />} />
						<Route path='empleador/gestion-de-vacante' element={<VacancyContext />} />
						<Route path='empleador/gestion-de-vacante/:id' element={<VacancyContext />} />
						<Route path='empleador/gestion-bitacoras/:id_postulacion' element={<EmployerManagementBitacoraWrapper />}/>	
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path='empleador/dashboard/empresa' element={<EmpresasDashboardWrapper />} />
						<Route path='empleador/bitacora/:id_postulacion/:id' element={<BitacoraContext />} />
					</Route>
				</Route>
			</Routes>
		);
	};


	const RoutesProvisional = ({ userRole, allowedRol }: RequireAuthProps) => {
		return (
			<Routes>
				<Route element={<RequireAuth userRole={userRole} allowedRol={allowedRol} />}>
					<Route element={<MasterLayout />}>
						<Route path='auth/*' element={<Navigate to='/empleador/area' />} />
						<Route path='empleador/area' element={<ProvisionalAreaWrapper />} />
						<Route path='empleador/registro-de-empresa/:id' element={<CompanyEditContext />} />
						<Route path='profile' element={<Profile />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Route>
				</Route>
			</Routes>
		);
	};

	const RoutesWithoutRole = () => {
		return (
			<Routes>
				<Route path='auth/*' element={<AuthPage />} />
			</Routes>
		);
	};

	const roleUser: number = role ?? -1;
	switch (roleUser) {
		case RoleEnum.ADMIN:
			return hasCompany ?
				<RoutesEmployer userRole={roleUser} allowedRol={RoleEnum.EMPLOYER} />
				: <RoutesAdmin userRole={roleUser} allowedRol={RoleEnum.ADMIN} />;
		case RoleEnum.CANDIDATE:
			return <RoutesCandidate userRole={roleUser} allowedRol={RoleEnum.CANDIDATE} />;
		case RoleEnum.COUNSELOR_CADIDATE:
			return <RoutesCounselorCandidate userRole={roleUser} allowedRol={RoleEnum.COUNSELOR_CADIDATE} />;
		case RoleEnum.COUNSELOR_EMPLOYER:
			return <RoutesCounselorEmployer userRole={roleUser} allowedRol={RoleEnum.COUNSELOR_EMPLOYER} />;
		case RoleEnum.EMPLOYER:
			return <RoutesEmployer userRole={roleUser} allowedRol={RoleEnum.EMPLOYER} />;
		case RoleEnum.PROVISIONAL:
			return <RoutesProvisional userRole={roleUser} allowedRol={RoleEnum.PROVISIONAL} />;
		default:
			return <RoutesWithoutRole />;
	}

};

export { PrivateRoutes };
