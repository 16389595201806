import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import parameterSingleton from '../../../services/Parameters.service';
import parameters from '../../../util/parameters';

const useBasicInfoVacancyPrarams = () => {
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);
	const [listPresencialidad,setListPresencialidad] =useState<ParameterConfigMmodel[]>([]);
	const [listDepartamento, setListDepartamento] = useState<ParameterConfigMmodel[]>([]);
	const [listNivelEducativo, setListNivelEducativo] = useState<ParameterConfigMmodel[]>([]);
	const [listMunicipio, setListMunicipio] = useState<ParameterConfigMmodel[]>([]);
	const [listLocalidades, setListLocalidades] = useState<any[]>([]);
	const [loadingParams, setloadingParams] = useState(true);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchListSiNo();
		await fetchListPresencialidad();
		await fetchDepartamentos();
		await fetchOtrosConocimientosList();
		await fetchListMunicipio();
		await fetchListLocalidades();
		setloadingParams(false);
	};

	const fetchListLocalidades = async () => {
		const res = await parameterSingleton.fetchLocalidades();
		setListLocalidades(res);
	};

	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};

	const fetchListPresencialidad = async () => {
		const res = await parameterSingleton.fetchData(parameters.PRESENCIALIDAD);
		setListPresencialidad(res);
	};

	const fetchDepartamentos = async () => {
		const res = await parameterSingleton.fetchData(parameters.DEPARTAMENTO);
		setListDepartamento(res);
	};

	const fetchOtrosConocimientosList = async () => {
		const res = await parameterSingleton.fetchData(parameters.NIVELEDUCATIVO);
		setListNivelEducativo(res);
	};

	const fetchListMunicipio = async () => {
		const res = await parameterSingleton.fetchData(parameters.MUNICIPIO);
		setListMunicipio(res);
	};

	return {
		listSiNo,
		listPresencialidad,
		listDepartamento,
		listNivelEducativo,
		listMunicipio,
		listLocalidades,
		loadingParams,
	};
};

export default useBasicInfoVacancyPrarams;
