import { ChangeEvent, Key, useContext, useEffect, useState } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,
	InputAdornment,
	Chip,
	Checkbox
} from '@mui/material';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import { helperIsValidDocumentNumber, isValidDocumentNumber, isValidFieldRequired, initValue, convertBytesToMB, concatArrayToStringJoinBy } from '../../../../../util/Index';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { getVerifyCompanyDocumentExists } from '../../../core/_requests';
import { isValidMinMaxText, helperValidMinMaxText, eventOnKeyDownInputNumber } from '../../../../../util/Index';
import useEmployerInfoParams from '../../../../../hooks/parameters/company-edition/useEmployerInfoParams';
import { useAuth } from '../../../core/Auth';
import { getSectorByCIUU, getAllCIUUCodes } from '../../../../../modules/apps/user-management/users-list/core/_requests';


export const Step1 = () => {
	const { listTipoDocumentoEmpresa, listNaturalezaEmpresa, listTipoEmpresa, listTamanoEmpresa, listProgramasCorporativos, listInteresesEmpresa, loadingParams } = useEmployerInfoParams();
	const {
		setStep,
		userData,
		setUserData,
		firstUser,
		setFirstUser,
		firstCompany,
		setFirstCompany,
		attachments,
		setAttachments,
	} = useContext(multiStepContext);
	const [documentType, setDocumentType] = useState('');
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [files, setFiles] = useState<any[]>([]);
	const MAX_FILES_UPLOADED_BY_COMPANY = Number(process.env.MAX_FILES_UPLOADED_BY_COMPANY) || 2;
	const MAX_SIZE_FILE_UPLOADED_BY_COMPANY = Number(process.env.MAX_SIZE_FILE_UPLOADED_BY_COMPANY) || 2097152;//2MB

	const [companyExists, setCompanyExists] = useState(false);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');
	const [interesesEmpresa, setInteresesEmpresa] = useState<string[]>([]);
	const [programasCorporativos, setProgramasCorporativos] = useState<string[]>([]);

	const [fileErrors, setFileErrors] = useState('');
	const { isAdminRole, isCounselorEmployerRole } = useAuth();

	//Lista de referencia de los cargos desde la BD
	const [listCIIU, setListCIIU] = useState<any[]>([]);
	const [selectedCIIU, setSelectedCIIU] = useState<any>('');

	const CODE_PROGRAMASCORPORATIVOS_NINGUNA = '7';//Ninguna
	const CODE_INTERESESEMPRESA_NINGUNA = '6';//Ninguna

	const handleChange = (event: SelectChangeEvent) => {
		setDocumentType(event.target.value);
	};

	/**
	 * Realiza llamado a endpoint de ciuu
	*/
	const fetchDataCiiu = async () => {
		try {

			const response = await getAllCIUUCodes();
			if (response && response.data) {
				setListCIIU(response.data);
				if (initValue(firstCompany) && initValue(firstCompany.company['codigo_ciiu'])) {
					const CIIU = response.data.filter((row: { limite_inferior: number; limite_superior: number; }) => row.limite_inferior <= firstCompany.company['codigo_ciiu'] && row.limite_superior >= firstCompany.company['codigo_ciiu'])[0];
					setSelectedCIIU(CIIU);
				}
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {

		if (e.target.files) {

			const chosenFiles = Array.prototype.slice.call(e.target.files);
			const uploaded = [...files];

			//Se aplican reglas de control para el tamaño del archivo
			for (let index = 0; index < e.target.files.length; index++) {
				const file = e.target.files[index];
				if (file.size > MAX_SIZE_FILE_UPLOADED_BY_COMPANY) {//2MB
					setFileErrors(`El tamaño del archivo ${file.name} supera el máximo permitido de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}`);
					setAlert(false);
					setAlertMsg(`El tamaño del archivo ${file.name} supera el máximo permitido de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}`);
					setOpen(true);
					setTimeout(() => {
						setOpen(false);
						setFileErrors('');
					}, 5000);
					return; // do not process the file if it exceeds the size limit
				}
			}
			//Se aplican reglas de control para cantidad de archivos
			if ((Array.prototype.slice.call(attachments).length + e.target.files.length) > MAX_FILES_UPLOADED_BY_COMPANY) {
				setAlert(false);
				setFileErrors(`El número de archivos seleccionados e insertados supera los ${MAX_FILES_UPLOADED_BY_COMPANY} archivos permitidos.`);
				setAlertMsg(`El número de archivos seleccionados e insertados supera los ${MAX_FILES_UPLOADED_BY_COMPANY} archivos permitidos.`);
				setOpen(true);
				setTimeout(() => {
					setOpen(false);
					setFileErrors('');
				}, 5000);
				return;
			} else {
				for (let index = 0; index < e.target.files.length; index++) {
					const element = e.target.files[index];
					if (attachments.find((x: any) => x.name === element.name)) continue;
					setAttachments((old: any) => [...old, element]);
				}
			}

			chosenFiles.some((x) => {
				if (uploaded.findIndex((f) => f.name === x.name) === -1) {
					uploaded.push(x);
				}
			});

			setFiles(uploaded);
			setUserData({ ...userData, file: uploaded });

		}
	};

	const handleDeleteFile = (fileName: string) => {
		const idRemove = Array.prototype.slice.call(attachments).findIndex((x) => x.name === fileName);
		const itemsToSave = Array.prototype.slice.call(attachments).filter((_, i) => i !== idRemove);
		setAttachments(itemsToSave);
		setUserData({ ...userData, file: itemsToSave });
	};

	useEffect(() => {
		fetchDataCiiu();
		validationSchemaByTpDoc(firstCompany.company.tipo_documento);
		setProgramasCorporativos(typeof firstCompany.company.programas_corporativos === 'string' ? firstCompany.company.programas_corporativos.split(',') : []);
		setInteresesEmpresa(typeof firstCompany.company.intereses_empresa === 'string' ? firstCompany.company.intereses_empresa.split(',') : []);
	}, []);

	const getIconFile = (fileName: string) => {
		switch (fileName?.toLowerCase()) {
			case 'img':
			case 'png':
			case 'jpg':
				return <ImageIcon />;
			case 'pdf':
				return <PictureAsPdfIcon />;
			case 'txt':
				return <TextSnippetIcon />;
			case 'xls':
			case 'xlsx':
			case 'csv':
				return <BorderAllIcon />;
			default:
				return <ArticleIcon />;
		}
	};

	function goBack() {
		window.history.back();
	}
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const handleNext = async () => {
		let errors: { field: string; error: string }[] = [];

		let camposError: string[] = [];

		if (!isValidFieldRequired(firstCompany.company['tipo_documento'])) {
			camposError.push('tipo_documento');
			errors.push({ field: 'Tipo de documento', error: 'Campo requerido' });
		}

		if (!isValidDocumentNumber(firstCompany.company['numero_documento'], documentTypeField, firstCompany.company['tipo_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)) {
			camposError.push('numero_documento');
			errors.push({ field: 'Número de documento', error: helperIsValidDocumentNumber(firstCompany.company['numero_documento'], documentTypeField, firstCompany.company['tipo_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true) });
		}

		if (!isValidFieldRequired(firstCompany.company['razon_social'])) {
			camposError.push('razon_social');
			errors.push({ field: 'Razón social', error: 'Campo requerido' });
		}

		if (!isValidFieldRequired(firstCompany.company['naturaleza'])) {
			camposError.push('naturaleza');
			errors.push({ field: 'Naturaleza', error: 'Campo requerido' });
		}

		if (!isValidFieldRequired(firstCompany.company['tipo'])) {
			camposError.push('tipo');
			errors.push({ field: 'Tipo', error: 'Campo requerido' });
		}

		if (!isValidFieldRequired(firstCompany.company['Tamano_empleados'])) {
			camposError.push('Tamano_empleados');
			errors.push({ field: 'Tamaño por número de empleados', error: 'Campo requerido' });
		}

		if (!isValidMinMaxText(firstCompany.company['descricion_employer'], 100, 1200, true)) {
			camposError.push('descricion_employer');
			errors.push({ field: 'Descripción de la compañia', error: helperValidMinMaxText(firstCompany.company['descricion_employer'], 100, 1200, true).split('\n')[0] });
		}

		//Controla que almenos se adjunte un archivo, maximo 3
		if (!(isAdminRole() || isCounselorEmployerRole())) {
			if (!attachments || (attachments.length < 1 || attachments.length > 3))
				errors.push({ field: 'Archivos', error: 'Debe adjuntar almenos 1 archivo, máximo 3 archivos' });
		}

		if (!isValidFieldRequired(firstCompany.company['codigo_ciiu'])) {
			camposError.push('codigo_ciiu');
			errors.push({ field: 'Código CIIU', error: 'Campo requerido' });
		}

		if (!selectedCIIU || !selectedCIIU.sector_economico) {
			camposError.push('codigo_ciiu');
			errors.push({ field: 'Sector Económico', error: 'El código CIIU no corresponde a un sector económico' });
		}

		if (interesesEmpresa.length <= 0) {
			camposError.push('intereses_empresa');
			errors.push({ field: 'Intereses de la Empresa', error: 'Los intereses no pueden estar vacios' });
		}

		if (programasCorporativos.length <= 0) {
			camposError.push('programas_corporativos');
			errors.push({ field: 'Programas Corporativos', error: 'Los programas Corporativos no pueden estar vacios' });
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		}

		setFirstCompany({
			...firstCompany,
			company: {
				...firstCompany.company,
				intereses_empresa: concatArrayToStringJoinBy(interesesEmpresa, ',', null),
				programas_corporativos: concatArrayToStringJoinBy(programasCorporativos, ',', null)
			},
		});
		if (isValidFieldRequired(firstCompany.company['numero_documento'])) {
			const companyNit = firstCompany.company['numero_documento'];
			const existCompany: boolean = await verifyCompanyDocumentExists(companyNit);
			if (existCompany) {
				setCompanyExists(true);
				setAlert(false);
				setAlertMsg(`Ya existe una empresa registrada con el Número de documento: ${companyNit}`);
				setOpen(true);
			} else {
				setStep(2);
				setCompanyExists(false);
				return;
			}
		}
	};

	const handleClose = () => {
		setOpen(false);
	};


	const verifyCompanyDocumentExists = async (documentNumber: string) => {
		try {
			const response = await getVerifyCompanyDocumentExists(`document=${documentNumber}`);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setOpen(true);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			return true;
		}
	};

	//validation for company document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			/*case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				break;
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(7);
				break;*/
			case 'NIT':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(9);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};

	/*
	* Buscar sector economico que corresponde al ciuu.
	*/
	const handleChangeCIIU = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFirstCompany({
			...firstCompany,
			company: { ...firstCompany.company, codigo_ciiu: e.target.value },
		})
		const select = listCIIU.filter(row => row.limite_inferior <= e.target.value && row.limite_superior >= e.target.value)[0];
		setSelectedCIIU(select);
	};

	/**
		 * event handle select input: programas corporativos
		 * @param event 
		 */
	const handleChangeProgramas = (event: SelectChangeEvent<string[]>) => {
		const { target: { value } } = event;
		var itemsSelected = typeof value === 'string' ? value.split(',') : value;
		if (itemsSelected?.indexOf(CODE_PROGRAMASCORPORATIVOS_NINGUNA) > -1) {
			itemsSelected = [CODE_PROGRAMASCORPORATIVOS_NINGUNA];
		} else {
			const index = itemsSelected?.indexOf(CODE_PROGRAMASCORPORATIVOS_NINGUNA);
			delete itemsSelected[index];
		}
		setProgramasCorporativos(itemsSelected);
	};

	/**
	 * event handle select input: intereses de la empresa
	 * @param event 
	 */
	const handleChangeIntereses = (event: SelectChangeEvent<string[]>) => {
		const { target: { value } } = event;
		var itemsSelected = typeof value === 'string' ? value.split(',') : value;
		if (itemsSelected?.indexOf(CODE_INTERESESEMPRESA_NINGUNA) > -1) {
			itemsSelected = [CODE_INTERESESEMPRESA_NINGUNA];
		} else {
			const index = itemsSelected?.indexOf(CODE_INTERESESEMPRESA_NINGUNA);
			delete itemsSelected[index];
		}
		setInteresesEmpresa(itemsSelected);
	};

	return (
		<>
			<Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
				Información del empleador
			</Typography>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} md={6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstCompany.company['tipo_documento'])}
					>
						<InputLabel id='tipo_documento' required>Tipo de documento</InputLabel>
						<Select
							required
							labelId='tipo_documento'
							id='tipo_documento'
							label='Tipo de documento'
							value={firstCompany.company['tipo_documento']}
							onChange={(e) => {
								firstCompany.company['numero_documento'] = '';
								validationSchemaByTpDoc(e.target.value);
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, tipo_documento: e.target.value },
								});
							}}
							onBlur={() => handleBlur('tipo_documento')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listTipoDocumentoEmpresa.map(({ code, value }, i) => (
								<MenuItem key={i} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstCompany.company['tipo_documento']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['tipo_documento'])
									? 'Campo requerido'
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('numero_documento') && !isValidDocumentNumber(firstCompany.company['numero_documento'], documentTypeField, firstCompany.company['tipo_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true) || companyExists}>
						<TextField
							fullWidth
							required
							id='numero_documento'
							name='numero_documento'
							label='Número de documento'
							placeholder='Ingrese un número'
							value={firstCompany.company['numero_documento']}
							onKeyUp={() => setCompanyExists(false)}
							type={documentTypeField}
							size='medium'
							inputProps={{
								maxLength: maxDocumentFieldLenght,
								minLength: minDocumentFieldLenght,
								readOnly: !isValidFieldRequired(firstCompany.company['tipo_documento'])
							}}
							onChange={(e) => {
								if (e.target.value.length <= maxDocumentFieldLenght) {
									setFirstCompany({ ...firstCompany, company: { ...firstCompany.company, numero_documento: e.target.value } });
								}
							}}
							onBlur={() => handleBlur('numero_documento')}
							onKeyDown={documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen 
							error={touchedFields.includes('numero_documento') && !isValidDocumentNumber(firstCompany.company['numero_documento'], documentTypeField, firstCompany.company['tipo_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true) || companyExists}
							helperText={
								touchedFields.includes('numero_documento') && !isValidFieldRequired(firstCompany.company['tipo_documento']) ? 'Primero seleccione el tipo de documento'
									:
									(
										companyExists ? '¡Empresa ya registrada!' : helperIsValidDocumentNumber(firstCompany.company['numero_documento'], documentTypeField, firstCompany.company['tipo_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)

									)
							}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('naturaleza') && !isValidFieldRequired(firstCompany.company['naturaleza'])}
					>
						<InputLabel id='naturaleza' required>Naturaleza</InputLabel>
						<Select
							required
							labelId='naturaleza'
							id='naturaleza'
							label='Naturaleza'
							value={firstCompany.company['naturaleza']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, naturaleza: e.target.value },
								})
							}
							onBlur={() => handleBlur('naturaleza')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listNaturalezaEmpresa.map(({ code, value }, i) => (
								<MenuItem key={i} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('naturaleza') && !isValidFieldRequired(firstCompany.company['naturaleza']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['naturaleza']) ? 'Campo requerido' : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				{/* </Grid> */}
				<Grid xs={6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('razon_social') && !isValidFieldRequired(firstCompany.company['razon_social'])}>
						<TextField
							fullWidth
							required
							id='razon_social'
							name='razon_social'
							label='Razón social'
							placeholder='Ingrese la razón social'
							value={firstCompany.company['razon_social']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, razon_social: e.target.value },
								})
							}
							onBlur={() => handleBlur('razon_social')}
							size='medium'
							error={touchedFields.includes('razon_social') && !isValidFieldRequired(firstCompany.company['razon_social'])}
							helperText={touchedFields.includes('razon_social') && !isValidFieldRequired(firstCompany.company['razon_social']) ? 'Campo requerido' : ''}
							inputProps={{ maxLength: 300 }}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('Tamano_empleados') && !isValidFieldRequired(firstCompany.company['Tamano_empleados'])}
					>
						<InputLabel id='Tamano_empleados' required>Tamaño por número de empleados</InputLabel>
						<Select
							required
							labelId='Tamano_empleados'
							id='Tamano_empleados'
							label='Tamaño por número de empleados'
							value={firstCompany.company['Tamano_empleados']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, Tamano_empleados: e.target.value },
								})
							}
							onBlur={() => handleBlur('Tamano_empleados')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listTamanoEmpresa.map(({ code, value }, i) => (
								<MenuItem key={i} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('Tamano_empleados') && !isValidFieldRequired(firstCompany.company['Tamano_empleados']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['Tamano_empleados'])
									? 'Campo requerido'
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={4} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('codigo_ciiu') && !isValidFieldRequired(firstCompany.company['codigo_ciiu'])}>
						<TextField
							required
							fullWidth
							id='codigo_ciiu'
							name='codigo_ciiu'
							label='Código CIIU'
							placeholder='Ingrese el código CIIU'
							value={initValue(firstCompany.company['codigo_ciiu'])}
							onChange={(e) => {
								handleChangeCIIU(e);
							}}
							onBlur={() => handleBlur('codigo_ciiu')}
							size='medium'
							inputProps={{
								maxLength: 4,
								inputMode: 'numeric',
								pattern: '/^-?\d+(?:\.\d+)?$/g'
							}}
							error={touchedFields.includes('codigo_ciiu') && !isValidFieldRequired(firstCompany.company['codigo_ciiu'])}
							helperText={touchedFields.includes('codigo_ciiu') && !isValidFieldRequired(firstCompany.company['codigo_ciiu']) ? 'Campo requerido' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={9} mb={6} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('codigo_ciiu') && (!selectedCIIU || !selectedCIIU.sector_economico)}>
						<TextField
							required
							fullWidth
							id='sector_economico'
							name='sector_economico'
							label='Sector Económico'
							placeholder='Sector Económico'
							value={selectedCIIU ? initValue(selectedCIIU.sector_economico) : ''}
							size='medium'
							inputProps={{ maxLength: 300, readOnly: true }}
							error={touchedFields.includes('codigo_ciiu') && (!selectedCIIU || !selectedCIIU.sector_economico)}
							helperText={touchedFields.includes('codigo_ciiu') && (!selectedCIIU || !selectedCIIU.sector_economico) ? 'El código CIIU no corresponde a un sector económico' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('tipo') && !isValidFieldRequired(firstCompany.company['tipo'])}
					>
						<InputLabel id='tipo' required>Tipo</InputLabel>
						<Select
							labelId='tipo'
							id='tipo'
							label='Tipo'
							required
							value={firstCompany.company['tipo']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, tipo: e.target.value },
								})
							}
							onBlur={() => handleBlur('tipo')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listTipoEmpresa.map(({ code, value }, i) => (
								<MenuItem key={i} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('tipo') && !isValidFieldRequired(firstCompany.company['tipo']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['tipo']) ? 'Campo requerido' : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={6} mb={5} pr={2} item={true} mt={1}>
					<Button variant='contained' component='label' startIcon={<AttachFileIcon />}>
						Subir archivo
						<input hidden multiple type='file' accept="application/pdf" onChange={handleFileChange} />
					</Button>
					<FormHelperText style={{ color: isValidFieldRequired(fileErrors) ? '#d32f2f' : '' }}>{isValidFieldRequired(fileErrors) ? fileErrors : `Archivos con extensión PDF y tamaño máximo permitido es de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}, subir Certificado Cámara de Comercio y el NIT actualizado.`}</FormHelperText>
				</Grid>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%', maxWidth: '100%', marginLeft: '0', marginTop: '0' }} size='medium' error={touchedFields.includes('programas_corporativos') && programasCorporativos.length <= 0}>
						<InputLabel required>Implementa acciones y/o hace parte de programas corporativos</InputLabel>
						<Select
							labelId="programas_corporativos"
							id="programas_corporativos"
							multiple
							value={programasCorporativos}
							label='Implementa acciones y/o hace parte de programas corporativos'
							onChange={handleChangeProgramas}
							onBlur={() => handleBlur('programas_corporativos')}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((selected_value: Key | null | undefined) => (
										<Chip key={selected_value} label={listProgramasCorporativos.find(({ code }) => code == selected_value)?.label} />
									))

									}
								</Box>
							)}
						>
							{listProgramasCorporativos.map(({ id, code, label }) => (
								<MenuItem key={id} value={code}
								>
									<Checkbox checked={programasCorporativos.indexOf(code) > -1} />
									{label}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{touchedFields.includes('programas_corporativos') && programasCorporativos.length <= 0 ? 'Campo requerido' : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%', maxWidth: '100%', marginLeft: '0', marginTop: '0' }} size='medium' error={touchedFields.includes('intereses_empresa') && interesesEmpresa.length <= 0}>
						<InputLabel required>Interés de la Empresa</InputLabel>
						<Select
							labelId="intereses_empresa"
							id="intereses_empresa"
							required
							multiple
							value={interesesEmpresa}
							label='Intereses de la Empresa.'
							onChange={handleChangeIntereses}
							onBlur={() => handleBlur('intereses_empresa')}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((selected_value: Key | null | undefined) => (
										<Chip key={selected_value} label={listInteresesEmpresa.find(({ code }) => code == selected_value)?.label} />
									))}
								</Box>
							)}
						>
							{listInteresesEmpresa.map(({ id, code, label }) => (
								<MenuItem
									key={id}
									value={code}
								>
									<Checkbox checked={interesesEmpresa.indexOf(code) > -1} />
									{label}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{touchedFields.includes('intereses_empresa') && interesesEmpresa.length <= 0 ? 'Campo requerido' : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} mb={5} pr={2} item>
					<OverlayTrigger
						placement='top'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								Ejemplo: Somos una Empresa SAS con 10 años de creación,
								dedicada a confeccionar ropa de un negocio familiar de cerca de 60 empleados,
								que tiene fábricas en Suba y Usme. Nos caracterizamos por trabajar con satélites
								y nos esforzamos por tener un modelo de trabajo colaborativo.
							</Tooltip>
						)}
					>
						<FormControl sx={{ minWidth: '100%' }} error={touchedFields.includes('descricion_employer') && !isValidMinMaxText(firstCompany.company['descricion_employer'], 100, 1200, true)}>
							<TextField
								fullWidth
								required
								id='descricion_employer'
								name='descricion_employer'
								label='Descripción de la compañía'
								value={firstCompany.company['descricion_employer']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, descricion_employer: e.target.value },
									})
								}
								onBlur={() => handleBlur('descricion_employer')}

								multiline
								rows={4}
								placeholder='descripción de la compañía'
								inputProps={{ maxLength: 1200, minLength: 100 }}
								error={touchedFields.includes('descricion_employer') && !isValidMinMaxText(firstCompany.company['descricion_employer'], 100, 1200, true)}
								helperText={touchedFields.includes('descricion_employer') ? helperValidMinMaxText(firstCompany.company['descricion_employer'], 100, 1200, true) : `${firstCompany.company['descricion_employer'].length}/100`}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
										</InputAdornment>
									),
								}}
								FormHelperTextProps={{ style: { wordWrap: 'break-word' } }}
							/>
						</FormControl>
					</OverlayTrigger>
				</Grid>
				{Array.prototype.slice.call(attachments).length > 0 && (
					<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} mb={2}>
						{Array.prototype.slice.call(attachments).map((x: any, i: number) => (
							<Grid item xs={'auto'} key={i}>
								<Chip
									key={i}
									icon={getIconFile(x.name?.split('.').pop())}
									label={x.name}
									// onClick={() => {
									// 	console.log(`clicou`);
									// 	downloadFile(x.name);
									// }}
									onDelete={() => {
										//console.log(`deleteou`);
										handleDeleteFile(x.name);
									}}
									color='primary'
									variant='outlined'
								/>
							</Grid>
						))}
					</Grid>
				)}
			</Grid >
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => goBack()}
					disabled
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={handleNext}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
