import { FormEvent, useContext, useState, useEffect, SyntheticEvent } from 'react';
import {
	TextField,
	Grid,
	Typography,
	Button,
	Box,
	Divider,
	Chip,
	Autocomplete,
	Stack,
	Alert,
	Snackbar,
	AlertTitle
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import _ from 'lodash';
import { createCurriculo, getCargos } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import { initValueString, setValueOrDefault } from '../../../../../util/Index';
import parameters from '../../../../../util/parameters';
import parameterSingleton from '../../../../../services/Parameters.service';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const primeira = [{ valid: false }];
export const Step4 = () => {
	const { setStep, userData, setUserData, setCurrentProgress, setLoading } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [step, setStepUser] = useState<string>('step4');
	const [rowsPuestos, setRowsPuestos] = useState<any[]>([]);

	//Lista de referencia de los cargos desde la BD
	const [listCargos, setListCargos] = useState<any[]>([]);
	const [loadingCargos, setLoadingCargos] = useState(false);
	const [listTipoOtrosConocimientos, setListTipoOtrosConocimientos] = useState<ParameterConfigMmodel[]>([]);

	useEffect(() => {
		fetchDataCargos();
		fetchOtrosConocimientos();
	}, []);

	/**
	 * Se genera un listener sobre la variable "listCargos", cuando se detecte un cambio se precarga 
	 * la informacion de cargos seleccionados.
	 */
	useEffect(() => {
		if (listCargos && listCargos.length > 0) {
			const listRowsPuestos = rowsPuestos.find((obj) => {
				return obj.id === 0;
			});
			if (listRowsPuestos) {
				const list = [...rowsPuestos];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsPuestos(list);
			}
			if (userData['listPuestos'] !== undefined) {
				let arr = [{ id: 0, puestos: '' }];
				(userData['listPuestos'] as Array<any>).forEach((x: any) => {
					let desc: any = listCargos.find((y) => y.id === x.id)?.desc_cargo;
					arr.push({ id: x.id, puestos: desc });
				});
				arr.shift();
				setRowsPuestos(arr);
			}
		}
	}, [listCargos]);

	/**
	 * Realiza llamado a consulta de cargos
	 */
	const fetchDataCargos = async () => {
		setLoadingCargos(true)
		try {
			setLoading(true);
			const response = await getCargos();
			if (response && response.data) {
				setListCargos(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoadingCargos(false)
			setLoading(false);
		}
	}

	const fetchOtrosConocimientos = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setListTipoOtrosConocimientos(res);
	}

	const handleDelete = (id: number) => {
		const list = [...rowsPuestos];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsPuestos(list);
		//setUseStateDataPuestos();
	};


	const handleChange = (eventElement: SyntheticEvent, newValue: any) => {
		if (!newValue) {
			eventElement.preventDefault();
			return;
		}
		const e = newValue.desc_cargo as string;
		const cargoSelected = listCargos.find((cargo) => cargo.desc_cargo === e);
		const cargoExist = rowsPuestos.find((puesto) => puesto.puestos === e);

		if (cargoSelected) {
			if (!cargoExist) {
				if (rowsPuestos.length < 5) {
					setRowsPuestos([
						...rowsPuestos,
						{
							id: cargoSelected.id,
							puestos: cargoSelected.desc_cargo,
						},
					]);
					//setUseStateDataPuestos();
				} else {
					setAlert(false);
					setAlertMsg('Están permitidos seleccionar máximo 5 cargos');
					setOpen(true);
				}
			} else {
				setAlert(false);
				setAlertMsg('El trabajo seleccionar "' + cargoExist.puestos + '" ya existe');
				setOpen(true);
			}

		}

	};

	// const setUseStateDataPuestos = () => {
	// 	setUserData({ ...userData, listPuestos: rowsPuestos });
	// };

	const handleNext = () => {
		setUserData({ ...userData, listPuestos: rowsPuestos });
		setStep(5);
	};
	const handleRetorno = () => {
		setUserData({ ...userData, listPuestos: rowsPuestos });
		setStep(3);
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];

		//Se controlan minimos y maximos de cargos
		if (!(rowsPuestos.length > 0))
			errors.push({ field: 'cargos', error: 'Seleccionar mínimo 1 cargo' });
		else if (rowsPuestos.length > 5)
			errors.push({ field: 'cargos', error: 'Seleccionar máximo 5 cargos' });

		console.log(errors);
		setUserData({ ...userData, listPuestos: rowsPuestos });
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};
	async function handleSubmit(event: FormEvent) {
		const retorno = handleValidOnSubmit();
		if (retorno) {
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(5);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];
		if (
			rowsPuestos !== null &&
			rowsPuestos !== undefined &&
			(rowsPuestos as Array<any>).length > 0
		) {
			(rowsPuestos as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						listTipoOtrosConocimientos.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
            tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
            tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'],'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
		};

		return resume;
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h5' sx={{ color: '#2A2A2A', fontWeight: 'bold' }}>
					¿En qué le gustaría trabajar?
				</Typography>
				<Typography sx={{ color: '#888888' }}>Seleccione máximo 5 cargos</Typography>
			</Box>
			<Grid container direction='column' width={'100%'} item>
				<Grid xs={4} mb={5} item width={'100%'}>
					<Autocomplete
						disabled={loadingCargos}
						id='Buscar cargos'
						freeSolo
						clearText=''
						onChange={(e, newValue) => handleChange(e, newValue)}
						getOptionLabel={(opt: any) => initValueString(opt.desc_cargo)}
						options={listCargos}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.desc_cargo}
								</li>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								required
								label='Buscar cargos'
								error={!loadingCargos && !(rowsPuestos.length > 0)}
								helperText={!loadingCargos && !(rowsPuestos.length > 0) ? 'Seleccionar al menos un cargo' : ''}
							/>
						)}
					/>
				</Grid>
				<Grid xs={12} mb={5} item>
					{rowsPuestos.map((row, key) => (
						<Grid xs={12} md={6} mb={1} key={key} item>
							<Stack direction='column' spacing={1}>
								<Chip
									label={key + 1 + ' - ' + row.puestos}
									sx={{ borderRadius: 1, fontSize: 14, justifyContent: 'space-between' }}
									style={{ fontSize: '16px' }}
									onDelete={() => handleDelete(row.id)}
								/>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
