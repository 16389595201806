import moment from "moment";
import { doc } from "prettier";
import { AnyObject } from "yup/lib/types";

/**
 * convertDateToYYYYMMDD
 * @param date Date object
 * @returns date with format: YYYY-MM-DD nad take away n year(s)
 */
const dateMinusNYears = (date: Date, numberYears: number): string => {
	const today = new Date();
	today.setFullYear(today.getFullYear() - numberYears);
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

/**
 * convertDateToYYYYMMDD
 * @param date Date object
 * @returns date with format: YYYY-MM-DD
 */
const convertDateToYYYYMMDD = (date: Date): string => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

/**
 * ConvertDateToISOString
 * @param date date with format: dd/MM/yyyy
 * @returns date with format: yyyy-MM-dd
 */
const convertDateToISOString = (date: any) => {
	if (!isValidFieldRequired(date)) return '0001-01-01';
	if (typeof date !== 'string') return '0001-01-01';
	if (!date.includes('/')) return '0001-01-01';

	return (
		date.split('/')[2] +
		'-' +
		('0' + date.split('/')[1]).slice(-2) +
		'-' +
		('0' + date.split('/')[0]).slice(-2)
	);
};

/**
 * ConvertDateToISOString
 * @param date date with format: dd/MM/yyyy
 * @returns date with format: yyyy-MM-dd or Null
 */
const convertDateToISOStringOrNull = (date: any) => {
	if (!isValidFieldRequired(date)) return '';
	if (typeof date !== 'string') return '';
	if (!date.includes('/')) return '';

	return (
		date.split('/')[2] +
		'-' +
		('0' + date.split('/')[1]).slice(-2) +
		'-' +
		('0' + date.split('/')[0]).slice(-2)
	);
};

/**
 * ConvertDateISOToDateString
 * @param date date with format: 2018-07-19T22:07:00.000-03:00
 * @returns date with format: dd/MM/yyyy
 */
const convertDateISOToDateString = (date: string): string => {
	if (!isValidFieldRequired(date)) return '01/01/0001';
	if (typeof date !== 'string') return '01/01/0001';
	if (!date.includes('-')) return '01/01/0001';
	const d = new Date(date);
	return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1)
		.toString()
		.padStart(2, '0')}/${d.getFullYear()}`;
};

const initValue = (field: any) => {
	return field === '' || field === undefined || field === null ? '' : field;
};

const initValueString = (field: any): string => {
	return field === '' || field === undefined || field === null ? '' : field;
};

const isValidFieldRequired = (field: any) =>
	field === '' || field === undefined || field === null ? false : true;

const capitalizeFirstLetter = (str: any) => str.replace(/^./, str[0].toUpperCase());

const setValueOrDefault = (value: any, typeClassMember: string) => {
	switch (typeClassMember) {
		case 'string':
			return value === '' || value === undefined || value === null ? '' : value;
		case 'boolean':
			return value === '' || value === undefined || value === null ? false : value;
		case 'number':
			return value === '' || value === undefined || value === null ? 0 : value;
		default:
			return '';
	}
};

/**
 * Simple validation for address where init by any String and contains any number
 * Ej. CRA 20, AV Toronto 10, CL 5004.
 * @param value address value
 * @returns TRUE for valid Adrress FALSE otherwise.
 */
const isValidAddress = (value: string): boolean => {
	if (value?.match(matchAddress())) return true;

	return false;
};

/**
 * 
 * @returns 
 */
const matchAddress = () => /^([A-Z]|[a-z])+.+[0-9]+(.)*/;

/**
 * regExpPassword
 * @returns match to use in forming passwords with at least one uppercase letter, at least one lowercase letter, at least one special character and at least one.
 */
const regExpPassword = () =>
	/^(?=(.*[A-Z]){1})(?=(.*[a-z]){1})(?=(.*[0-9]){1})(?=(.*[@#$%^!&+=.\-_*]){1})([a-zA-Z0-9@#$%^!&+=*.\-_]){8,}$/;

/**
 * regExpPhoneNumber10Digits
 * @returns match to use in the formation for validation of Colombian telephone (10 digits).
 */
const regExpPhoneNumber10Digits = () => /[0-9]{10}/gm;

/**
 * regExpPhoneNumber12Digits
 * @returns match to use in the formation for validation of Colombian telephone (12 digits (DDI - XX) (DDD - XXX) XXX-XXXX).
 */
// const regExpPhoneNumber12Digits = () => /[0-9]{12}/gm;
const regExpPhoneNumber12Digits = () => /[0-9]/gm;

/**
 * matchPhoneNumber12Digits
 * @returns match to use in the formation for validation of Colombian telephone mask (DDI - XX) (DDD - XXX) XXXXXXX).
 */
const matchPhoneNumber12Digits = () => /(\d{2})(\d{3})(\d{7})/;

/**
 * matchEmail
 * @returns match to use in the formation for validation of email.
 */
const matchEmail = () =>
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

/**
 * formatMaskPhoneNumber12Digits
 * @returns Mask +XX-XXX-XXXXXXX.
 */
const formatMaskPhoneNumber12Digits = (value: string) => {
	// value = value.replace(/\D/g, '');
	// let x = value.replace(/\D/g, '').match(matchPhoneNumber12Digits());
	// if (x !== null) value = '+' + x[1] + '-' + x[2] + '-' + x[3];

	// return value;
	return value.replace(/\D/g, '');
};

/**
 * isValidPhoneNumber
 * @returns true/false.
 */
const isValidPhoneNumber = (value: string): boolean => {
	if (
		value
			?.replace(/[^\d]/g, '')
			?.replaceAll('-', '')
			?.replaceAll('+', '')
			?.match(regExpPhoneNumber12Digits())
	)
		return true;

	return false;
};
/**
 * isValidEmail
 * @returns true/false.
 */
const isValidEmail = (value: string): boolean => {
	if (value?.match(matchEmail())) return true;

	return false;
};

//Expresion regular para detectar mas de 2 caracteres iguales consecutivos
const regExpConsecutiveCharacteres = /^([A-Za-záéíóúÁÉÍÓÚñÑ])\1+$/gm;
//Regex validAlphabetValueText
const regexValidAlphabetValueText = /^[A-Za-záéíóúÁÉÍÓÚñÑ ]+$/

/**
 * getDateNowToString
 * @returns date now in yyyy-MM-dd format string
 */
const getDateNowToString = () => {
	var d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

//Funcion que permite validar si el campo de texto cumple con las reglas de validacion de obligatoriedad, minimos y maximos
const isValidMinMaxText = (value: any, min: number, max: number, required: boolean = false): boolean => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if (required === false && !isValidFieldRequired(value)) return true;
	if (value?.length < min || value?.length > max) return false;
	return true;
};

//Funcion que muestra helper del campo de texto de acuerdo con las reglas de validacion de obligatoriedad, minimos y maximos
const helperValidMinMaxText = (value: string, min: number, max: number, required: boolean = false): string => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if (required === false && !isValidFieldRequired(value)) return ``;
	if (!isValidMinMaxText(value, min, max, required))
		return `¡El campo debe tener al menos ${min} caracteres!\n\n${value.length}/${max}`;
	return `${value.length}/${max}`;
};

//Funcion que valida si el valor solo contiene caracteres alfabeticos
const isValidAlphabetValueText = (value: string, required: boolean = false): boolean => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if (required === false && !isValidFieldRequired(value)) return true;
	if (value.match(regexValidAlphabetValueText) === null || value.match(regexValidAlphabetValueText) === undefined) return false;
	return true;
};

//Funcion que muestra helper del campo de texto con las reglas de validación de solo caracteres alfabeticos
const helperValidAlphabetValueText = (value: string, required: boolean = false): string => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if (required === false && !isValidFieldRequired(value)) return ``;
	return !isValidAlphabetValueText(value) ? `Solo caracteres alfabéticos` : ``;
};

//Funcion que valida una Regex en criterios minimos de aceptacion para considerar un nombres o apellidos validos
const isValidFirstSecondNameFirstSecondLastName = (value: string, required: boolean = false) => {
	if (value?.length === 2 && value?.toLowerCase()?.match(regExpConsecutiveCharacteres)) {
		return false
	} else if (required && value?.length === 1) {
		return false;
	}
	return isValidAlphabetValueText(value, required);
}

//Funcion que muestra helper del campo de texto con las reglas de validación de nombres y apellidos
const helperValidFirstSecondNameFirstSecondLastName = (value: string, required: boolean = false) => {
	if (value?.length === 2 && value?.toLowerCase()?.match(regExpConsecutiveCharacteres)) {
		return 'No debe contener 2 caracteres iguales consecutivos';
	} else if (required && value?.length === 1) {
		return 'Debe tener al menos 2 caracteres';
	}
	return helperValidAlphabetValueText(value, required);
}

//Funcion que permite validar si el campo de texto cumple con las reglas de validacion de obligatoriedad y maximos
const isValidMaxText = (value: string, max: number, required: boolean = false): boolean => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if (required === false && !isValidFieldRequired(value)) return true;
	if (value.length > max) return false;
	return true;
};

//Funcion que muestra helper del campo de texto de acuerdo con las reglas de validacion de obligatoriedad, minimos y maximos
const helperValidMaxText = (value: string, max: number, required: boolean = false): string => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if (required === false && !isValidFieldRequired(value)) return ``;
	if (!isValidMaxText(value, max, required))
		return `${max - value.length} caracteres restantes`;
	return `${value.length}/${max}`;
};

//Adiciona numero de dias a la fecha
const addDaysToDate = (date: Date, days: number) => {
	const newDate = new Date(date.getTime());
	newDate.setDate(date.getDate() + days);
	return newDate;
}

//Obtiene la edad de la persona, calculandola entre la fecha de nacimiento y la fecha del sistema
const getAge = (v: any) => {
	var birthDate = new Date(v);
	var today = new Date();
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) return age--;
	return age;
}

//Aplica reglas de control para tipos de documento segun fecha de nacimiento
const checkRulesByBirthDateAndDocType = (date: any, type: any) => {
	let errors: { field: string; error: string }[] = [];
	if (!isValidFieldRequired(date)) return errors;
	const age = getAge(date);
	switch (type) {
		case 'CC':
			if (age < 18) {
				errors.push({ field: 'Tipo de documento', error: 'No puede seleccionar Cédula de Ciudadanía si es menor de 18 años de edad' });
			}
			break;
		case 'TI':
			if (age < 7 || age > 18) {
				errors.push({ field: 'Tipo de documento', error: 'No puede seleccionar Tarjeta de Identidad si es menor de 7 o mayor de 17 años de edad' });
			}
			break;
		default:
			errors = [];
	}
	return errors;
}

//Aplica reglas de control para tipos de documento segun fecha de nacimiento
const checkRulesByCountryAndDocumentType = (country: any, type: any) => {
	let errors: { field: string; error: string }[] = [];
	switch (type) {
		case 'PEP':
			if (country === 'CO') {
				errors.push({ field: 'Tipo de documento', error: 'No puede seleccionar Permiso Especial de Permanencia si es Colombiano(a)' });
			}
			break;
		case 'PT':
			if (country === 'CO') {
				errors.push({ field: 'Tipo de documento', error: 'No puede seleccionar Permiso por Protección Temporal si es Colombiano(a)' });
			}
			break;
		default:
			errors = [];
	}
	return errors;
}

/**
 * Esta funcion toma un string con el formato YYYY-MM-DD y lo ajusta al formato DD/MM/YYYY
 * convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY
 * @param date date string with format: YYYY-MM-DD
 * @returns date string with format DD/MM/YYYYY
 */
const convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY = (date: string) => {
	if (!isValidFieldRequired(date)) return date;
	if (typeof date !== 'string') return date;
	if (!date.includes('-')) return date;
	return date.split('-').reverse().join('/');;
};

/**
 * Esta funcion toma un string con el formato DD/MM/YYYY y lo ajusta al formato YYYY-MM-DD
 * convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD
 * @param date date string with format: DD/MM/YYYYY
 * @returns date string with format YYYY-MM-DD
 */
const convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD = (date: string) => {
	if (!isValidFieldRequired(date)) return date;
	if (typeof date !== 'string') return date;
	if (!date.includes('/')) return date;
	return date.split('/').reverse().join('-');;
};

//Funcion que valida en criterios minimos de aceptacion para numeros telefonicos
const isValidColombianPhoneNumber = (value: any, required: boolean = false) => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if (required === false && !isValidFieldRequired(value)) return true;
	return (value as string).length === 7 || (value as string).length === 10 ? true : false;
}

//Funcion que muestra helper del campo de texto de acuerdo con las reglas de validacion de obligatoriedad, minimos y maximos
const helperIsValidColombianPhoneNumber = (value: any, required: boolean = false): string => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if (required === false && !isValidFieldRequired(value)) return ``;
	return !isValidColombianPhoneNumber(value, required) ? `Número telefónico no válido` : ``;
};

//Funcion que controla el evento keyDown contenga valores del input Numero de documento
const eventOnKeyDownInputNumber = (event: any) => {
	let key = Number(event.key);
	let valid = !isNaN(key) || (event.key === 'ArrowUp' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Home' || event.key === 'End');
	if (!valid) event.preventDefault()
	return valid;
	//numbers on top of keyboard ( 0 - 9 ) : 48 - 57
	//numbers on numeric keypad ( 0 - 9 ) : 96 - 105
	/*if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
		checkIfNum = true;
	} else*/
	/*let checkIfNum;
	if (event.key !== undefined) {
		// Check if it's a "e", ".", "+" , "-", ","
		checkIfNum = event.key === "e" || event.key === "." || event.key === "+" || event.key === "-" || event.key === ",";
	} else if (event.keyCode !== undefined) {
		// Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
		checkIfNum = event.keyCode === 69 || event.keyCode === 190 || event.keyCode === 187 || event.keyCode === 188;
	}
	return checkIfNum && event.preventDefault();*/
}
// Función que controla el evento keyDown para que contenga valores de input alfanumericos y espacios
const eventOnKeyDownInputAlphaNumeric = (event: any) => {
	let key = event.key;
	let isValidAlphaNumeric = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9 ]$/.test(key) || key === 'Backspace' || key === 'Delete' || key === 'Home' || key === 'End' || key === 'ArrowUp' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowDown';
	if (!isValidAlphaNumeric) event.preventDefault();
	return isValidAlphaNumeric
}

//Funcion que valida si el valor es un numero valido
const isValidNumber = (value: string, min: number = -999, max: number = -999, required: boolean = false) => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if ((required === false && value?.length || 0 > 0) || required) {
		if (!value?.match(/^[0-9]+$/)) return false;
		if ((min > 0 && max > 0 && min === max) && (value.length < max)) return false;
		if ((min > 0 && max > 0) && (value.length < min || value.length > max)) return false;
		if ((min > 0) && (value.length < min)) return false;
		if ((max > 0) && (value.length > max)) return false;
	}
	return true;
}

//Funcion que muestra helper del campo numerico
const helperIsValidNumber = (value: string, min: number, max: number, required: boolean = false) => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if ((required === false && value?.length || 0 > 0) || required) {
		if (!value.match(/^[0-9]+$/)) return `Solo valores numéricos`;
		if ((min > 0 && max > 0 && min === max) && (value.length < min)) return `Longitud mínima ${max} dígitos`;
		if ((min > 0 && max > 0) && (value.length < min || value.length > max)) return `Longitud mínima ${min} y máxima ${max} dígitos`;
		if ((min > 0) && (value.length < min)) return `Longitud mínima ${min}`;
		if ((max > 0) && (value.length > max)) return `Longitud máxima ${max}`;
	}
	return !isValidNumber(value) ? `Solo valores numéricos` : ``;
}

//Funcion que valida que la fechaA no sea menor que la fechaB
const isValidDateAndEqualOrAfterThan = (dateA: any, valueChanged: any, required: boolean = false, dateB = new Date()) => {
	if (!valueChanged || valueChanged === false) return true;
	if (required && !isValidFieldRequired(dateA)) return false;
	dateA = dateA instanceof Date ? dateA : castStringToDateWithMoment(dateA, 'YYYY-MM-DD');
	dateB = dateB instanceof Date ? dateB : castStringToDateWithMoment(dateB, 'YYYY-MM-DD');
	dateA.setHours(0, 0, 0, 0);
	dateB.setHours(0, 0, 0, 0);
	if (dateA.getTime() < dateB.getTime()) return false;
	return true;
}

//Funcion que muestra helper para validacion isValidAndEqualOrAfterThan
const helperIsValidDateAndEqualOrAfterThan = (dateA: any, valueChanged: any, required: boolean = false, dateB = new Date()) => {
	if (!valueChanged) return '';
	if (required && !isValidFieldRequired(dateA)) return 'Campo requerido';
	return !isValidDateAndEqualOrAfterThan(dateA, valueChanged, required, dateB) ? 'Fecha no valida' : '';
}

//Funcion que valida que la fechaA no sea menor o igual que la fechaB
const isValidDateAndAfterThan = (dateA: Date, valueChanged: any, required: boolean = false, dateB: Date = new Date()) => {
	if (!valueChanged || valueChanged === false) return true;
	if (required && !isValidFieldRequired(dateA)) return false;
	dateA = dateA instanceof Date ? dateA : castStringToDateWithMoment(dateA, 'YYYY-MM-DD');
	dateB = dateB instanceof Date ? dateB : castStringToDateWithMoment(dateB, 'YYYY-MM-DD');
	dateA.setHours(0, 0, 0, 0);
	dateB.setHours(0, 0, 0, 0);
	if (dateA.getTime() <= dateB.getTime()) return false;
	return true;
}

//Funcion que muestra helper para validacion isValidAndAfterThan
const helperIsValidDateAndAfterThan = (dateA: any, valueChanged: any, required: boolean = false, dateB = new Date()) => {
	if (!valueChanged) return '';
	if (required && !isValidFieldRequired(dateA)) return 'Campo requerido';
	return !isValidDateAndAfterThan(dateA, valueChanged, required, dateB) ? 'Fecha no valida' : '';
}

//Funcion que valida si el valor es un numero de documento valido
const isValidDocumentNumber = (value: string, type: string, documentType: string, min: number = -999, max: number = -999, required: boolean = false) => {
	if (required === true && !isValidFieldRequired(value)) return false;
	if (type === 'number') {
		if (typeof value === 'string' && !value.match(/^[0-9]+$/)) return false;
		if (documentType === 'CC' && value.toString().length === 9) return false;
	} else if (type === 'text' && documentType === 'PA') {
		if (!value.match(/^[A-Za-z0-9]+$/)) return false;
	} else {
		if (!value.match(/^[0-9]*$/)) return false;
		if (documentType === 'CC' && value.toString().length === 9) return false;
	}
	const length = type === 'number' ? value.toString().length : value.length;
	if ((min > 0 && max > 0 && min === max) && (length < min)) return false;
	if ((min > 0 && max > 0) && (length < min || length > max)) return false;
	if ((min > 0) && (length < min)) return false;
	if ((max > 0) && (length > max)) return false;
	return true;
}

//Funcion que muestra helper del numero de documento
const helperIsValidDocumentNumber = (value: string, type: string, documentType: string, min: number, max: number, required: boolean = false) => {
	if (required === true && !isValidFieldRequired(value)) return `Campo requerido`;
	if (type === 'number') {
		if (typeof value === 'string' && !value.match(/^[0-9]+$/)) return `Solo valores numéricos`;
		if (documentType === 'CC' && value.toString().length === 9) return `No debe tener longitud de 9 dígitos`;
	} else if (type === 'text' && documentType === 'PA') {//Pasaporte
		if (typeof value === 'string' && !value.match(/^[A-Za-z0-9]+$/)) return 'Solo números y letras';
	} else {
		if (!value.match(/^[0-9]*$/)) return 'Solo valores numéricos';
		if (documentType === 'CC' && value.toString().length === 9) return `No debe tener longitud de 9 dígitos`;
	}
	const length = type === 'number' ? value.toString().length : value.length;
	const isAlphaNumeric = documentType === 'PA' ? true : false
	if ((min > 0 && max > 0 && min === max) && (length < min)) return `Longitud mínima ${min} ${isAlphaNumeric ? 'caracteres' : 'dígitos'}`;
	if ((min > 0 && max > 0) && (length < min || length > max)) return `Longitud mínima ${min} y máxima ${max} ${isAlphaNumeric ? 'caracteres' : 'dígitos'}`;
	if ((min > 0) && (length < min)) return `Longitud mínima ${min} ${isAlphaNumeric ? 'caracteres' : 'dígitos'}`;
	if ((max > 0) && (length > max)) return `Longitud máxima ${max} ${isAlphaNumeric ? 'caracteres' : 'dígitos'}`;
	return !isValidDocumentNumber(value, type, documentType, min, max, required) ? `Número de documento no válido` : ``;
}

/**
 * Realiza un split para unir un array, por el sepearador indicado
 * @param {*} array 
 * @param {*} joinBy 
 * @returns 
 */
const concatArrayToStringJoinBy = (array: any[] = [], joinBy = ' ', value: any = '') => {
	return array ? array.join(joinBy) : value;
}

/**
	 * Realiza un cortamiento del texto, acorde al valor maximo permitido
	 * @param text 
	 * @param maxlength 
	 * @returns 
	 */
const truncateWords = (text: string, maxlength: number) => {
	if (text) {
		return text.length <= maxlength ? text : `${text.slice(0, maxlength - 3)}...`
	}
	return '';
}

/**
 * Convierte el string, que contiene el formato especifico usando la libreria moment
 * @param date 
 * @param format 
 * @returns 
 */
const castStringToDateWithMoment = (date: string, format: string = 'YYYY-MM-DD') => {
	try {
		return new Date(moment(date, format).toDate())
	} catch (error) {
		return new Date();
	}
}

/**
 * Convierte hora militar a formato 24 horas
 * @param time 
 * @returns 
 */
const convertMilitaryTimeTo24HourFormat = (time: string): number => {
	const [hours, minutes] = time.split(':').map(Number);
	return hours * 100 + minutes;
}

/**
 * Convierte fecha UTC a local date
 * @param date 
 * @returns 
 */
const convertUTCDateToLocalDate = (date: any) => {
	var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
	var offset = date.getTimezoneOffset() / 60;
	var hours = date.getHours();
	newDate.setHours(hours - offset);
	return newDate;
}

/**
 * Adiciona minutos a la hora
 * @param hora 
 * @param minutos 
 * @returns 
 */
const addMinutes = (hora: string, minutos: number) => {
	let [horas, mins] = hora.split(":").map(Number);
	let totalMinutos = horas * 60 + mins + minutos;
	horas = Math.floor(totalMinutos / 60) % 24;
	mins = totalMinutos % 60;
	return `${horas.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
}

/**
 * Convierte hora militar a formato 12 horas
 * @param militaryTime 
 * @returns 
 */
const convertMilitaryTimeTo12HourFormat = (militaryTime: string): string => {
	if (militaryTime) {
		let hours = parseInt(militaryTime.split(':')[0]);
		let minutes = militaryTime.split(':')[1];
		let meridian = hours >= 12 ? 'p.m.' : 'a.m.';
		hours = hours % 12;
		hours = hours ? hours : 12;
		return `${hours}:${minutes} ${meridian}`;
	}
	return '00';
}

/**
 * Permite validar la existencia de un valor en una lista de referencia
 * @param value 
 * @param list 
 * @param findByAttr 
 */
const isValidValueOnList = (value: any, list: any[], findByAttr: string) => {
	if (!isValidFieldRequired(value)) { return false; }
	else if (!list || list?.length <= 0) { return false; }
	return list.map(i => { return i[findByAttr] }).find(v => v == value);
}

/**
 * Convierte hora, minuto y segundos en millisegundos
 * @param hour 
 * @param minute 
 * @param seconds 
 * @returns 
 */
const convertTimeToMs = (hour: any, minute: any, seconds: any = 0) => {
	return ((hour * 60 * 60 + minute * 60 + seconds) * 1000);
}

/**
	 * Convierte milisegundos a hora, minutos y segundos
	 * @param milliseconds 
	 * @returns 
	 */
const convertMsToTime = (milliseconds: any) => {
	let seconds = Math.floor(milliseconds / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	seconds = seconds % 60;
	minutes = minutes % 60;
	hours = hours % 24;
	return { hours, minutes, seconds };
}

const formatNumber = (value: any) => {
	try {
		return Number(value).toLocaleString('en-ES', {
			style: 'currency',
			currency: 'COP'
		})
	} catch (error) {
		return value;
	}
}

/**
	 * Valida que salario minimo no sea mayor que salario maximo
	 * @param salarioMin 
	 * @param salarioMAx 
	 * @returns 
	 */
const salarioMinIsGreaterThanSalarioMax = (salarioMin: any, salarioMAx: any) => {
	try {
		if (Number(salarioMin) > Number(salarioMAx)) {
			return false;
		}
		return true;
	} catch (error) {
		console.log(error)
		return false;
	}
}


/**
 * Funcion que muestra helper para validacion salarioMinIsGreaterThanSalarioMax
 * @param salarioMin 
 * @param salarioMAx 
 * @returns 
 */
const helperSalarioMinIsGreaterThanSalarioMax = (salarioMin: any, salarioMAx: any, message: string = 'Salarios no válidos') => {
	return !salarioMinIsGreaterThanSalarioMax(salarioMin, salarioMAx) ? message : '';
}

/**
	 * Convierte el tamaño de archivo de bytes a MB
	 * @param size 
	 * @returns 
	 */
const convertBytesToMB = (size: any) => {
	try {
		return `${size ? (Number(size) / (1024 * 1024)).toFixed(2) : 0} MB`
	} catch (error) {
		return `${size} Bytes`
	}
}

/**
 * Convierte un string en un array
 * @param array 
 * @param splitBy 
 * @param value 
 * @returns 
 */
const stringToArrayBySeparator = (value: any, splitBy = ',', defaultValue = []) => {
	try {
		if (typeof value === 'string') {
			const arr = value ? value?.split(splitBy) : [];
			return arr?.filter((i: any) => isValidFieldRequired(i));
		} else if (Array.isArray(value)) {
			return value;
		} else {
			return defaultValue;
		}
	} catch (error) {
		return defaultValue;
	}
}

export {
	dateMinusNYears,
	convertDateToYYYYMMDD,
	convertDateToISOString,
	convertDateISOToDateString,
	convertDateToISOStringOrNull,
	initValue,
	initValueString,
	capitalizeFirstLetter,
	isValidFieldRequired,
	isValidAddress,
	setValueOrDefault,
	regExpPassword,
	regExpPhoneNumber10Digits,
	regExpPhoneNumber12Digits,
	matchPhoneNumber12Digits,
	formatMaskPhoneNumber12Digits,
	matchEmail,
	isValidPhoneNumber,
	isValidEmail,
	getDateNowToString,
	isValidMinMaxText,
	helperValidMinMaxText,
	isValidAlphabetValueText,
	helperValidAlphabetValueText,
	isValidMaxText,
	helperValidMaxText,
	addDaysToDate,
	getAge,
	checkRulesByBirthDateAndDocType,
	checkRulesByCountryAndDocumentType,
	convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY,
	isValidFirstSecondNameFirstSecondLastName,
	helperValidFirstSecondNameFirstSecondLastName,
	regExpConsecutiveCharacteres,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	eventOnKeyDownInputNumber,
	eventOnKeyDownInputAlphaNumeric,
	isValidNumber,
	helperIsValidNumber,
	isValidDateAndEqualOrAfterThan,
	helperIsValidDateAndEqualOrAfterThan,
	isValidDateAndAfterThan,
	helperIsValidDateAndAfterThan,
	isValidDocumentNumber,
	helperIsValidDocumentNumber,
	convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD,
	concatArrayToStringJoinBy,
	truncateWords,
	castStringToDateWithMoment,
	convertMilitaryTimeTo24HourFormat,
	convertMilitaryTimeTo12HourFormat,
	convertUTCDateToLocalDate,
	addMinutes,
	isValidValueOnList,
	regexValidAlphabetValueText,
	convertTimeToMs,
	convertMsToTime,
	formatNumber,
	salarioMinIsGreaterThanSalarioMax,
	helperSalarioMinIsGreaterThanSalarioMax,
	convertBytesToMB,
	stringToArrayBySeparator
};
