import { PageTitle } from '../../../_metronic/layout/core';
import { useEffect, useState } from 'react';
import { LoadingBackdrop, LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import { Grid, Chip, Button, Menu, MenuItem, FormHelperText, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, TablePagination, FormControl, InputLabel, Select, Snackbar, Alert, AlertTitle, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Typography } from '@mui/material';
import { Table } from 'react-bootstrap';
import styleScss from './styles.module.scss';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getFilesCompany, downloadZipFileByCompany, deleteFileCompany } from '../../modules/apps/user-management/users-list/core/_requests';
import { uploadFileCompany } from '../../modules/auth/core/_requests';
import { convertDateISOToDateString, isValidFieldRequired, convertBytesToMB } from '../../util/Index';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAuth } from '../../modules/auth';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ChangeEvent } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const EmployerCompanyFilesPage = () => {

    const { isAdminRole, isCounselorEmployerRole } = useAuth();

    //query param
    const { id_empresa } = useParams();
    const [empresa, setEmpresa] = useState<any>(undefined);


    //loading / progress
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    //Data
    const [data, setData]: any[] = useState([]);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [total, setTotal] = useState<number>(0);
    const [filter, setFilter] = useState<any>({ page: 1, limit: 100 });

    //Notification
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');

    //File
    const [files, setFiles] = useState<any[]>([]);
    const [fileErrors, setFileErrors] = useState('');
    const [attachments, setAttachments] = useState<any[]>([]);
    const [openModalChooseFile, setOpenModalChooseFile] = useState(false);
    const MAX_FILES_UPLOADED_BY_COMPANY = Number(process.env.MAX_FILES_UPLOADED_BY_COMPANY) || 2;
    const MAX_SIZE_FILE_UPLOADED_BY_COMPANY = Number(process.env.MAX_SIZE_FILE_UPLOADED_BY_COMPANY) || 2097152;//2MB

    //Close notification
    const handleClose = () => { setOpen(false); };

    /**
     * Evento cambio de pagina
     * @param newPage 
     */
    const handleChangePage = (newPage: any) => {
        const newPageNumber = parseInt(newPage) + 1;
        const f = { ...filter, page: newPageNumber };
        setPage(newPage);
        setFilter(f);
    };

    /**
     * Evento cambio registros por pagina
     * @param event 
     */
    const handleChangeRowsPerPage = (event: any) => {
        const f = { ...filter, page: 1, limit: parseInt(event.target.value, 10) };
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFilter(f);
    };

    /**
     * Cambio a primera pagina
     */
    const handleFirstPageButtonClick = () => {
        handleChangePage(0);
    };

    /**
     * Cambio ultima pagina
     */
    const handleLastPageButtonClick = () => {
        handleChangePage(Math.ceil(total / rowsPerPage) - 1);
    };

    /**
     * Llamado al API para obtener los archivos vinculados
     */
    const getFilesByCompany = async () => {
        try {
            setLoading(true);
            const response = await getFilesCompany(`page=${filter.page}&limit=${filter.limit}&id_empresa=${id_empresa}`);
            if (response && response.data) {
                setEmpresa(response.data.data?.[0]?.Empresa);
                setTotal(response.data.total);
                setData(response.data.data);
            }
        } catch (error: any) {
            if (error.response) {
                console.error('error', error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
            setEmpresa(undefined);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getFilesByCompany();
    }, []);

    /**
         * Valida si el rol del usuario en sesion es Administrador o Consejero Empresarial
         * @returns 
         */
    const isAdminOrCounselor = () => {
        return isAdminRole() || isCounselorEmployerRole();
    }

    /**
     * Determina si el boton de "Subir archivos" se debe mostrar o no
     * @returns 
     */
    const enableUploadFile = () => {
        return isAdminOrCounselor() && total < MAX_FILES_UPLOADED_BY_COMPANY && !loading;
    }

    /**
 * Renderiza un botón con tres puntos que abre un menú con diferentes opciones basadas en 
 * los datos pasados en la variable props.
 *
 * @param {object} props - Contiene los datos que se utilizarán para renderizar las 
 * opciones del menu según la información pasada.
 * @returns {JSX.Element} Componente MenuAction.
 */
    const MenuAction = (props: any) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const { data } = props;
        const [fileToDelete, setFileToDelete] = useState(null);
        const [companyToDeleteFile, setCompanyToDeleteFile] = useState(null);
        const [openModalDeleteFile, setOpenModalDeleteFile] = useState(false);

        const handleClick = (e: any) => {
            setAnchorEl(e.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        /**
         * Llamado al API para descargar archivo
         * @param id 
         * @param id_empresa 
         */
        const downloadFileZip = async (id: number, id_empresa: number) => {
            try {
                setRequestInProgress(true);
                const response = await downloadZipFileByCompany(`id_empresa=${id_empresa}&id=${id}`);
                const blob = response.data;
                const url = URL.createObjectURL(blob);
                window.location.href = url;
            } catch (error: any) {
                if (error.response) {
                    console.error(error.response.data.error);
                    setOpen(true);
                    setAlert(false);
                    setAlertMsg(error.response.data.error);
                    setTimeout(() => {
                        setOpen(false);
                    }, 4000);
                } else {
                    console.error('Ocurrio un error procesando la solicitud.');
                }
            } finally {
                setRequestInProgress(false);
            }
        };

        /**
         * Llamado al API para eliminar el archivo
         * @param id 
         */
        const deleteFile = async (id: any) => {
            try {
                setRequestInProgress(true);
                await deleteFileCompany(`id_empresa=${companyToDeleteFile}&id=${fileToDelete}`);
                setOpen(true);
                setAlert(true);
                setAlertMsg(`Archivo eliminado con éxito!`);
                getFilesByCompany();
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } catch (error: any) {
                if (error.response) {
                    console.error(error.response.data.error);
                    setOpen(true);
                    setAlert(false);
                    setAlertMsg(error.response.data.error);
                    setTimeout(() => {
                        setOpen(false);
                    }, 4000);
                } else {
                    console.error('Ocurrio un error procesando la solicitud.');
                }
            } finally {
                setRequestInProgress(false);
            }
        }

        /**
         * Evento open modal delete file
         * @param id 
         * @param id_empresa 
         */
        const handleOpenModalDeleteFile = (id: any, id_empresa: any) => {
            setFileToDelete(id);
            setCompanyToDeleteFile(id_empresa)
            setOpenModalDeleteFile(true);
        };

        /**
         * Evento close modal delete file
         */
        const handleCloseModalDeleteFile = () => {
            setFileToDelete(null);
            setOpenModalDeleteFile(false);
        };

        return (
            <>
                <Button aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
                </Button>
                <Menu
                    id='card-actions-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <button className={`${styleScss['button-link']} pointer`} onClick={() => downloadFileZip(data?.id, data?.empresa?.id)}>
                            <FileDownloadIcon />
                            &nbsp; Descargar
                        </button>
                    </MenuItem>
                    {(isAdminOrCounselor() && data?.empresa?.status == 'En análisis' && data?.empresa?.active == true) && (
                        <MenuItem onClick={handleClose}>
                            <button className={`${styleScss['button-link']} pointer`} onClick={() => handleOpenModalDeleteFile(data.id, data?.empresa?.id)}>
                                <DeleteIcon />
                                &nbsp; Eliminar
                            </button>
                        </MenuItem>
                    )}
                </Menu>

                <Dialog
                    open={openModalDeleteFile}
                    keepMounted
                    onClose={handleCloseModalDeleteFile}
                    aria-describedby='alert-dialog-description'>
                    <DialogTitle id='alert-dialog-title'>{'Eliminar archivo'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Esta acción eliminar el archivo asociado a la empresa.
                        </DialogContentText>
                        <br />
                        <DialogContentText id='alert-dialog-description'>
                            ¿Está seguro de que desea eliminarlo?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90 }}
                            onClick={handleCloseModalDeleteFile}
                            autoFocus
                            disabled={!requestInProgress ? false : true}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', width: 90 }}
                            onClick={() => deleteFile(fileToDelete)}
                            autoFocus
                            disabled={!requestInProgress ? false : true}
                        >
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }

    /**
     * Evento change de archivo
     * @param e 
     * @returns 
     */
    const handleFileChangeSelected = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files?.length == 1) {

            const chosenFiles = Array.prototype.slice.call(e.target.files);
            const uploaded = [...files];

            //Se aplican reglas de control para el tamaño del archivo

            for (let index = 0; index < e.target.files.length; index++) {
                const file = e.target.files[index];
                if (file.size > MAX_SIZE_FILE_UPLOADED_BY_COMPANY) {//2MB
                    setFileErrors(`El tamaño del archivo ${file.name} supera el máximo permitido de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}`);
                    setAlert(false);
                    setAlertMsg(`El tamaño del archivo ${file.name} supera el máximo permitido de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}`);
                    setOpen(true);
                    setTimeout(() => {
                        setOpen(false);
                        setFileErrors('');
                    }, 5000);
                    return; // do not process the file if it exceeds the size limit
                }
            }
            //Se aplican reglas de control para cantidad de archivos
            if ((Array.prototype.slice.call(attachments).length + e.target.files.length + total) > MAX_FILES_UPLOADED_BY_COMPANY) {
                setAlert(false);
                setFileErrors(`El número de archivos seleccionados e insertados supera los ${MAX_FILES_UPLOADED_BY_COMPANY} archivos permitidos.`);
                setAlertMsg(`El número de archivos seleccionados e insertados supera los ${MAX_FILES_UPLOADED_BY_COMPANY} archivos permitidos.`);
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                    setFileErrors('');
                }, 5000);
                return;
            } else {
                for (let index = 0; index < e.target.files.length; index++) {
                    const element = e.target.files[index];
                    if (attachments.find((x: any) => x.name === element.name)) continue;
                    setAttachments((old: any) => [...old, element]);
                }
            }
            chosenFiles.some((x) => {
                if (uploaded.findIndex((f) => f.name === x.name) === -1) {
                    uploaded.push(x);
                }
            });
            setFiles(uploaded);
        }
    };


    /**
        * Evento open modal choose file
        * @param id 
        * @param id_empresa 
        */
    const handleOpenModalChooseFile = () => {
        setOpenModalChooseFile(true);
    };

    /**
     * Evento close modal choose file
     */
    const handleCloseModalChooseFile = () => {
        setOpenModalChooseFile(false);
        setAttachments([]);
    };

    /**
         * Llamado al API para cargar el archivo
         * @param id 
         */
    const uploadFile = async () => {
        try {
            setRequestInProgress(true);
            //attachments.forEach(async (element: any) => { });
            const attachment = attachments?.[0];
            let dataForm = new FormData();
            dataForm.append(`id_empresa`, String(id_empresa));
            dataForm.append(`file`, attachment, attachment.name);
            await uploadFileCompany(dataForm);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Archivo guardado con éxito!`);
            setOpenModalChooseFile(false);
            setAttachments([]);
            getFilesByCompany();
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        } catch (error: any) {
            if (error.response) {
                console.error(error.response)
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 4000);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        } finally {
            setRequestInProgress(false);
        }
    }

    /**
     * Evento para eliminar archivo previamente seleccionado para guardar
     * @param fileName 
     */
    const handleDeleteFileChooseSelected = (fileName: string) => {
        const idRemove = Array.prototype.slice.call(attachments).findIndex((x) => x.name === fileName);
        const itemsToSave = Array.prototype.slice.call(attachments).filter((_, i) => i !== idRemove);
        setAttachments(itemsToSave);
    };

    /**
     * Obtiene ilustracion visual del tipo de archivo seleccionado
     * @param fileName 
     * @returns 
     */
    const getIconFileChooseSelected = (fileName: string) => {
        switch (fileName?.toLowerCase()) {
            case 'img':
            case 'png':
            case 'jpg':
                return <ImageIcon />;
            case 'pdf':
                return <PictureAsPdfIcon />;
            case 'txt':
                return <TextSnippetIcon />;
            case 'xls':
            case 'xlsx':
            case 'csv':
                return <BorderAllIcon />;
            default:
                return <ArticleIcon />;
        }
    };

    return (
        <>
            <div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
                <LoadingBackdrop loading={requestInProgress} />
                <div className='row mb-4' style={{ alignItems: 'center' }}>
                    <div className='col-sm-12'>
                        <div className='input-group d-flex' style={{ width: '100%' }}>
                            <div style={{ padding: '10px 0px 0px 0px' }}>
                                <div className='col-xs-2 col-sm-1 col-md-1'
                                    style={{
                                        display: 'contents',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} >
                                    <BackButton />
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-4 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
                                {enableUploadFile() && (
                                    <>
                                        <Button className='btn btn-primary btn-lg' startIcon={<AttachFileIcon />} onClick={() => handleOpenModalChooseFile()}>
                                            Subir archivo
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <LinearLoadingSpinner isLoading={loading} />}
                {data.lenght !== 0 && (
                    <TableContainer component={Paper}>
                        <Table className={`table table-responsive table-striped`} style={{ textAlign: 'center' }}>
                            <TableHead className={`${styleScss['th-custom']}`}>
                                <TableRow>
                                    <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', width: '15%' }}>
                                        <h4>Fecha creación</h4>
                                    </TableCell>
                                    <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', width: '50%' }} >
                                        <h4>Nombre</h4>
                                    </TableCell>
                                    <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                        <h4>Tamaño</h4>
                                    </TableCell>
                                    <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                        <h4>Acciones</h4>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && data.map((x: any, i: any) => (
                                    <TableRow key={i} id={i.toString()}>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                            {convertDateISOToDateString(x['createdAt'])}
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                            {decodeURIComponent(escape(x['original_name']))}
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                            {convertBytesToMB(x['size'])}
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <MenuAction data={{ id: x.id, empresa: x.Empresa }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {data.length === 0 && (
                                <tfoot>
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
                                                No se encontraron registros
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </tfoot>
                            )}
                        </Table>
                        {/*  <div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Filas por página:"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
                                    <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
                                        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                                            <FirstPageIcon />
                                        </IconButton>
                                        <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                                            <ChevronLeftIcon />
                                        </IconButton>
                                        <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                                            <ChevronRightIcon />
                                        </IconButton>
                                        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                                            <LastPageIcon />
                                        </IconButton>
                                    </div>
                                )}
                            />
                        </div> */}
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={open}
                            onClose={handleClose}
                        >
                            <Alert
                                onClose={handleClose}
                                variant={'filled'}
                                severity={alert ? 'success' : 'error'}
                                sx={{ whiteSpace: 'pre-line' }}
                            >
                                {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                                {alertMsg}
                            </Alert>
                        </Snackbar>
                    </TableContainer>
                )}
                <Dialog
                    open={openModalChooseFile}
                    keepMounted
                    onClose={handleCloseModalChooseFile}
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title' variant='h4' component='h2'>
                        <b>Subir Archivo</b>
                    </DialogTitle>
                    <DialogContent dividers style={{ overflow: "auto" }}>
                        <DialogContentText id='alert-dialog-description'>
                            <Typography variant='inherit' sx={{ fontSize: '10pt' }} mb={1}>
                                Vincule de a un archivo al proceso de registro de la empresa:
                            </Typography>
                        </DialogContentText>                                              
                        <Button variant='contained' component='label' startIcon={<AttachFileIcon />} disabled={total >= MAX_FILES_UPLOADED_BY_COMPANY || attachments?.length == 1}>
                            Adjuntar
                            <input hidden type='file' accept="application/pdf" onChange={handleFileChangeSelected} />
                        </Button>
                        <br/><br/>
                        <FormHelperText 
                            style={{ 
                                font: 'caption', 
                                fontSize: '8pt',
                                color: isValidFieldRequired(fileErrors) ? '#d32f2f' : 'gray' 
                            }}
                        >
                            {isValidFieldRequired(fileErrors) ? fileErrors :                             
                                `Archivos con extensión PDF y tamaño máximo permitido es de ${convertBytesToMB(MAX_SIZE_FILE_UPLOADED_BY_COMPANY)}, subir Certificado Cámara de Comercio ó el NIT actualizado por separado.`}                            
                        </FormHelperText>
                        <br/>                        
                        {Array.prototype.slice.call(attachments)?.length > 0 && (
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} mb={2}>
                                {Array.prototype.slice.call(attachments)?.map((x: any, i: number) => (
                                    <Grid item xs={'auto'} key={i}>
                                        <Chip
                                            key={i}
                                            icon={getIconFileChooseSelected(x.name?.split('.').pop())}
                                            label={x.name}
                                            onDelete={() => {
                                                handleDeleteFileChooseSelected(x.name);
                                            }}
                                            color='primary'
                                            variant='outlined'
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90 }}
                            onClick={handleCloseModalChooseFile}
                            autoFocus
                            disabled={!requestInProgress || !loading ? false : true}
                        >
                            Cancelar
                        </Button>
                        {Array.prototype.slice.call(attachments).length > 0 && (
                            <Button
                                variant='contained'
                                sx={{ background: '#0A4396', width: 90 }}
                                onClick={() => uploadFile()}
                                autoFocus
                                disabled={!requestInProgress || !loading ? false : true}
                            >
                                Guardar
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

const EmployerFilesWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Gestión de archivos</PageTitle>
            <EmployerCompanyFilesPage />
        </>
    );
};

export { EmployerFilesWrapper };