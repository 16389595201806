import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { Rating } from '@mui/material';
import { PageTitle } from '../../../_metronic/layout/core';
import {
	getCurriculoById,
	emailCandidateEmpresa,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { initValue, setValueOrDefault, convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY, concatArrayToStringJoinBy } from '../../util/Index';
import AlertMessage from '../AlertMessage/AlertMessage';
import { ResumeSkeleton } from './ResumeSkeleton';
import { LoadingBackdrop } from '../LoadingSpinner/LoadingSpinner';
import BackButton from '../BackButton/BackButton';
import useEducationParams from '../../hooks/parameters/useEducationParams';
import useLanguageAndSkillsParams from '../../hooks/parameters/curriculum/useLanguageAndSkillsParams';
import { getNivelesEducativos } from '../../modules/apps/user-management/users-list/core/_requests';
import useLaboralExperienceParams from '../../hooks/parameters/useLaboralExperienceParams';

const ResumePage = () => {
	const parms = useParams();
	const { currentUser, isEmployerRole, isAdminRole, isCounselorCandidateRole, isCounselorEmployerRole } = useAuth();
	const [resume, setResume] = useState<any>({});
	interface StatusCompanyProps {
		id: number;
		id_Empresa?: number;
		id_Vacancy?: number;
		observaciones?: string;
	}
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [companyData, setCompanyData] = useState<StatusCompanyProps>({
		id: 0,
	});
	const [openExperience, setOpenExperience] = useState(true);
	const [openEducation, setOpenEducation] = useState(true);
	const [openCapacitationCeritification, setOpenCapacitationCeritification] = useState(true);
	const [openOccupationalInterest, setOpenOccupationalInterest] = useState(true);
	const [openKnowledge, setOpenKnowledge] = useState(true);
	const [openLanguages, setOpenLanguages] = useState(true);
	const [openDescription, setOpenDescription] = useState(true);

	//MODAL
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const [messageModal, setMessageModal] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingCV, setloadingCV] = useState(false);

	//Parameters
	const { listEstadoCurso, listEstadoCertificado, listTipoCapacitacion } = useEducationParams();
	const { listIdioma, listNivelIdioma } = useLanguageAndSkillsParams();
	const { listTipoExperienciaLaboral, listSectorIndustrial } = useLaboralExperienceParams();

	const [ddlNivelEducativo, setddlNivelEducativo] = useState<any[]>([]);


	useEffect(() => {
		fetchDataNivelesEducativos();
		fetchData().catch(console.error);
	}, []);

	/**
	 * Realiza llamado a consulta de niveles educativos
	 */
	const fetchDataNivelesEducativos = async () => {
		try {
			const response = await getNivelesEducativos();
			if (response && response.data) {
				setddlNivelEducativo(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	const fetchData = async () => {
		setloadingCV(true);
		if (parms.id !== undefined) {
			try {
				const response = await getCurriculoById(`id_users=${currentUser?.user?.id}&id=${parms.id}`);
				if (response && response.data) {
					setResume(response?.data[0]);
				}
				if (parms.id_empresa !== undefined) {
					setCompanyData({
						...companyData,
						id: Number(parms.id),
						id_Empresa: Number(parms.id_empresa),
						id_Vacancy: Number(parms.id_vacancy),
					});
				}
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setloadingCV(false);
			}
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendEmailToCandidate = () => {
		companyData.observaciones = messageModal;
		setCompanyData({
			...companyData,
			id: Number(parms.id),
			id_Empresa: Number(parms.id_empresa),
			id_Vacancy: Number(parms.id_vacancy),
		});
	};

	useEffect(() => {
		//console.log(companyData)
		if ((companyData.id !== undefined || companyData.id_Empresa !== undefined || companyData.id_Vacancy !== undefined) && openModal === true) {
			setLoading(true);
			emailCandidateEmpresa(companyData)
				.then((response) => {
					showAlertMessage(response.data.message, true, true, 4000);
					setMessageModal('');
					handleCloseModal();
				})
				.catch(error => {
					if (error.response) {
						console.error(error.response.data.error);
						showAlertMessage(error.response.data.error, true, false, 2000);
					} else {
						console.error('Ocurrio un error procesando la solicitud.');
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [companyData]);

	const showAlertMessage = (message: string, isOpen: boolean = true, isAlert: boolean, timeToClose?: number) => {
		setAlertMsg(message);
		setOpen(isOpen);
		setAlert(isAlert);
		if (timeToClose) {
			setTimeout(() => {
				setOpen(false);
			}, timeToClose);
		}
	}

	/**
	 * Regla para habilita la funcionalidad de contactar
	 * @returns 
	 */
	const canbeContactCandidate = () => {
		return isEmployerRole() || isAdminRole() || isCounselorCandidateRole() || isCounselorEmployerRole();
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				{loadingCV ? <ResumeSkeleton /> : (
					<>
						<div className='row mb-4'>
							<div className='col-sm-12'>
								<div className='input-group'>
									<div
										className='col-sm-1'
										style={{
											display: 'contents',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<BackButton />
									</div>
									<div className='col-sm-10' style={{ padding: '5px 0px 0px 20px' }}>
										<h2 style={{ display: 'flex', gap: '10px' }}>Hoja de vida</h2>
									</div>
								</div>
							</div>
						</div>
						<div className='row mt-16'>
							<div className='col-sm-4' style={{ lineHeight: '1.5em' }}>
								<h3 style={{ display: 'flex', gap: '10px' }}>
									{
										concatArrayToStringJoinBy([initValue(resume['usercomplemento']?.['primer_nome']), initValue(resume['usercomplemento']?.['segundo_nome'])])
									}
								</h3>
								<span>
									{
										concatArrayToStringJoinBy([initValue(resume['usercomplemento']?.['User']?.['email']), initValue(resume['usercomplemento']?.['celular'])], ' - ')
									}
								</span>
							</div>
							<div className='col d-flex justify-content-end'>
								{canbeContactCandidate() && (
									<button
										onClick={handleOpenModal}
										className='btn btn-primary'
										style={{ padding: '8px 20px', background: '#153E7B' }}>
										Contactar
									</button>
								)}
							</div>
						</div>
						{/* Descrição */}
						<div className='row'>
							<Card
								sx={{
									border: 'none',
									boxShadow: 'none',
									width: '100%',
								}}
							>
								<CardHeader
									title='Perfil Laboral'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenDescription(!openDescription)}
											aria-label='expand'
											size='small'
										>
											{openDescription ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openDescription} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row mt-10'>
												<span style={{ textAlign: 'justify' }}>{initValue(resume['curriculoListId']?.['perfil_laboral'])}</span>
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Experiencia */}
						<div className='row mt-10'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Experiencia laboral'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenExperience(!openExperience)}
											aria-label='expand'
											size='small'
										>
											{openExperience ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openExperience} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											{resume['curriculoListId']?.['CurriculoExperienciaLaborals'] && (
												resume['curriculoListId']?.['CurriculoExperienciaLaborals'].map(
													(x: any, i: number) => (
														<div className='mt-10' key={i}>
															<h6 style={{ textTransform: 'capitalize' }}>{`Empresa: ${initValue(x['nombreEmpresa'])}`}</h6>
															<ul className='mt-2'>
																<div className='row'>
																	<div className='col-sm-4'>
																		<li style={{ margin: '10px 0' }}>
																			<b>Tipo de experiencia: </b>
																			{(listTipoExperienciaLaboral.find((obj) => obj.code === initValue(x['tpExperienciaLaboral']) || obj.value === initValue(x['tpExperienciaLaboral']))?.value || '')}
																		</li>
																		<li style={{ margin: '10px 0' }}>
																			<b>Sector: </b>
																			{(listSectorIndustrial.find((obj) => obj.code === initValue(x['sector']) || obj.value === initValue(x['sector']))?.value || '')}
																		</li>
																	</div>
																	<div className='col-sm-4'>
																		<li style={{ margin: '10px 0' }}>
																			<b>Cargo: </b>
																			{initValue(x['cargo'])}
																		</li>
																		<li style={{ margin: '10px 0' }}>
																			<b>Cargo Equivalente: </b>
																			{initValue(x['cargoEquivalente'])}
																		</li>
																		{x['telefonoEmpresa'] && (
																			<>
																				<li style={{ margin: '10px 0' }}>
																					<b>Teléfono de la empresa: </b>
																					{initValue(x['telefonoEmpresa'])}
																				</li>
																			</>
																		)}
																	</div>
																	<div className='col-sm-4'>
																		<li style={{ margin: '10px 0' }}>
																			<b>Fecha de ingreso:</b> {convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(initValue(x['fechaIngresso']))}
																		</li>
																		{x['fechaRetiro'] && (
																			<>
																				<li style={{ margin: '10px 0' }}>
																					<b>Fecha de retiro: </b>
																					{convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(initValue(x['fechaRetiro']))}
																				</li>
																			</>
																		)}
																	</div>
																</div>
															</ul>
															<ul>
																<li>
																	<b>Funciones y logros: </b>
																	<div className='row mt-2'>
																		<span style={{ textAlign: 'justify', overflowWrap: 'break-word', wordBreak: 'break-all' }}>{initValue(x['funcionesLogros'])}</span>
																	</div>
																</li>
															</ul>
														</div>
													)
												)
											)}
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Educación */}
						<div className='row'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Educación'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenEducation(!openEducation)}
											aria-label='expand'
											size='small'
										>
											{openEducation ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openEducation} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row mt-10'>
												{resume['curriculoListId']?.['CurriculoNivelEducativos'] && (
													resume['curriculoListId']?.['CurriculoNivelEducativos'].map(
														(x: any, i: number) => (
															<div className='col-sm-6' key={i}>
																<div className='col-sm-7'>
																	<h6>{ddlNivelEducativo.find(i => i.sigla_nivel_educativo === x['nivelEducativo'])?.desc_nivel_educativo}</h6>
																	<ul className='mt-2'>
																		<li style={{ margin: '10px 0' }}>
																			<b>Institución: </b>
																			{initValue(x['Institucion'])}
																		</li>
																		{x['fechafinalizacion'] && (
																			<>
																				<li style={{ margin: '10px 0' }}>
																					<b>Fecha de Finalización: </b>
																					{convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(initValue(x['fechafinalizacion']))}
																				</li>
																			</>
																		)}
																		<li style={{ margin: '10px 0' }}>
																			<b>Estado: </b>
																			{listEstadoCurso.find(i => i.code === initValue(x['estadoDelCurso']) || i.value === initValue(x['estadoDelCurso']))?.value}
																		</li>
																	</ul>
																</div>
															</div>
														)
													)
												)}
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Capacitaciones y Certificaciones */}
						<div className='row'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Capacitaciones y Certificaciones'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenCapacitationCeritification(!openCapacitationCeritification)}
											aria-label='expand'
											size='small'
										>
											{openCapacitationCeritification ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openCapacitationCeritification} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row mt-10'>
												{resume['curriculoListId']?.['CurriculoCapacitaciones'] && (
													resume['curriculoListId']?.['CurriculoCapacitaciones'].map(
														(x: any, i: number) => (
															<div className='col-sm-6' key={i}>
																<div className='col-sm-7'>
																	<h6>{listTipoCapacitacion.find(i => i.code === initValue(x['tpCapacitacionCertificacion']) || i.value === initValue(x['tpCapacitacionCertificacion']))?.value}</h6>
																	<ul className='mt-2'>
																		<li style={{ margin: '10px 0' }}>
																			<b>Institución: </b>
																			{initValue(x['instituicion'])}
																		</li>
																		{x['fechaCertificacion'] && (
																			<li style={{ margin: '10px 0' }}>
																				<b>Fecha de Certificación: </b>
																				{convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(initValue(x['fechaCertificacion']))}
																			</li>
																		)}
																		<li style={{ margin: '10px 0' }}>
																			<b>Estado: </b>
																			{listEstadoCertificado.find(i => i.code === initValue(x['estadoCapacitacione']) || i.value === initValue(x['estadoCapacitacione']))?.value}
																		</li>
																	</ul>
																</div>
															</div>
														)
													)
												)}
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Interés ocupacional */}
						<div className='row'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Interés ocupacional'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenOccupationalInterest(!openOccupationalInterest)}
											aria-label='expand'
											size='small'
										>
											{openOccupationalInterest ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openOccupationalInterest} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row'>
												{resume['curriculoListId']?.['Cargos'] && (
													resume['curriculoListId']?.['Cargos'].map((x: any, i: number) => (
														<span
															key={i}
															className='col-sm-2 badge rounded-pill mt-10'
															style={{
																boxSizing: 'border-box',
																color: '#153E7B',
																border: '1px solid #153E7B',
																backgroundColor: 'transparent',
																marginRight: '15px',
																marginBottom: '10px',
																padding: '15px 30px 15px 30px',
																textAlign: 'center',
																height: '46px',
																alignContent: 'center',
																fontSize: '10pt',
																width: 'auto',
															}}
														>
															{x['desc_cargo']}
														</span>
													))
												)}
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Conocimientos */}
						<div className='row'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Conocimientos'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenKnowledge(!openKnowledge)}
											aria-label='expand'
											size='small'
										>
											{openKnowledge ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openKnowledge} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row'>
												{resume['curriculoListId']?.['CurriculoConocimientos'] && (
													resume['curriculoListId']?.['CurriculoConocimientos'].map(
														(x: any, i: number) => (
															<span
																className='col-sm-2 badge rounded-pill mt-10'
																style={{
																	boxSizing: 'border-box',
																	color: '#153E7B',
																	border: '1px solid #153E7B',
																	backgroundColor: 'transparent',
																	marginRight: '15px',
																	marginBottom: '10px',
																	padding: '15px 30px 15px 30px',
																	textAlign: 'center',
																	height: '46px',
																	alignContent: 'center',
																	fontSize: '11pt',
																	width: 'auto'
																}}
																key={i}
															>
																{initValue(x['herramientas'])}&nbsp;&nbsp;
																<Rating
																	size='medium'
																	name='read-only'
																	value={setValueOrDefault(x['nivel'], 'number')}
																	readOnly
																/>
															</span>
														)
													)
												)}
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
						{/* Idiomas */}
						<div className='row'>
							<Card
								sx={{
									minWidth: 300,
									// border: '1px solid rgba(211,211,211,0.6)',
									border: 'none',
									boxShadow: 'none',
								}}
							>
								<CardHeader
									title='Idiomas'
									titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
									action={
										<IconButton
											onClick={() => setOpenLanguages(!openLanguages)}
											aria-label='expand'
											size='small'
										>
											{openLanguages ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
									}
								/>
								<div>
									<Collapse in={openLanguages} timeout='auto' unmountOnExit>
										<CardContent>
											<hr style={{ marginTop: '-10px' }} />
											<div className='row'>
												{resume['curriculoListId']?.['CurriculoIdiomas'] && (
													resume['curriculoListId']?.['CurriculoIdiomas'].map((x: any, i: number) => (
														<span
															key={i}
															className='col-sm-2 badge rounded-pill mt-10'
															style={{
																boxSizing: 'border-box',
																color: '#153E7B',
																border: '1px solid #153E7B',
																backgroundColor: 'transparent',
																marginRight: '15px',
																marginBottom: '10px',
																padding: '15px 20px 15px 20px',
																textAlign: 'center',
																alignContent: 'center',
																fontSize: '11pt',
																width: 'auto',
															}}
														>
															{listIdioma.find((y, k) => y.code === x['id_idioma']?.toString())?.value}&nbsp;&nbsp;
															{`(${listNivelIdioma.find(i => i.code === x['nivel']?.toString())?.value})`}&nbsp;&nbsp;
														</span>
													))
												)}
											</div>
										</CardContent>
									</Collapse>
								</div>
							</Card>
						</div>
					</>
				)}
			</div>

			{/* MODAL */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{ border: 'none' }}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					{/* <Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={loading}
					>
						<CircularProgress color="primary" />
					</Backdrop> */}
					<LoadingBackdrop loading={loading} />
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						Contactar a{' '}
						{initValue(resume['usercomplemento']?.['primer_nome']) +
							' ' +
							initValue(resume['usercomplemento']?.['segundo_nome'])}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						Escriba un mensaje al candidato {initValue(resume['usercomplemento']?.['primer_nome'])}
					</Typography>
					<TextField
						className='mt-5 mb-5'
						fullWidth
						rows={10}
						id='observaciones'
						name='observaciones'
						label='Mensaje'
						value={initValue(messageModal)}
						onChange={(e) => setMessageModal(e.target.value)}
						size='medium'
						multiline
						inputProps={{ maxLength: 2000 }}
						disabled={loading}
						helperText={`${messageModal === undefined ? '0' : messageModal?.length}/${2000}`}
					/>

					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-primary'
							onClick={() => sendEmailToCandidate()}
							disabled={
								messageModal && messageModal.length > 0 && !loading
									? false
									: true
							}
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							{loading ? 'Enviando...' : 'Enviar email'}
						</button>
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							Cancelar
						</button>
					</div>
				</Box>
			</Modal>
			<AlertMessage isOpen={open} isAlert={alert} message={alertMsg} />
		</>
	);
};

const ResumeComponent = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Hoja de vida</PageTitle>
			<ResumePage />
		</>
	);
};

export default ResumeComponent;
