import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link } from 'react-router-dom';
import { getAgendaVagaCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

/**
 * @deprecated
 * @returns 
 */
const EmployerRequestedFeedbackCandidatoPage = () => {
	const { currentUser } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});
	const [loading, setLoading] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [id, setId] = useState<string>('');
	const [idEmpresa, setIdEmpresa] = useState<string>('');
	const [idVacancy, setIdVacancy] = useState<string>('');
	const id_users = currentUser?.user?.id;
	const id_empresa = currentUser?.user?.empresa_id;
	const handleClickMenu = (
		event: React.MouseEvent<HTMLButtonElement>,
		id: any,
		idEmpresa: any,
		idVacancy: any
	) => {
		setAnchorEl(event.currentTarget);
		setId(id);
		setIdEmpresa(idEmpresa);
		setIdVacancy(idVacancy);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		handleSubmit();
	}, []);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await getAgendaVagaCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}&id_empresa=${id_empresa}&nome=${userData.nombre_candidato}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className='form-control'
								value={userData['nombre_candidato']}
								onChange={(e) => setUserData({ ...userData, nombre_candidato: e.target.value })}
								placeholder='Escriba el nombre del candidato'
								aria-label='Escriba el nombre del candidato'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className='input-group-text'
									style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
									onClick={handleSubmit}
									id='searchCandidato'
								>
									<i className='bi bi-search' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.lenght !== 0 && (
					<table className={`table table-responsive table-striped`}>
						<thead className={`${styleScss['th-custom']}`}>
							<tr>
								<th scope='col'>
									<h4>Candidato</h4>
								</th>

								<th scope='col'>
									<h4>Email</h4>
								</th>
								<th scope='col'>
									<h4>Vacante</h4>
								</th>
								<th scope='col'>
									<h4>Estado</h4>
								</th>
								<th scope='col'>
									<h4>Acciones</h4>
								</th>
							</tr>
						</thead>
						<tbody className={`${styleScss['td-custom']}`}>
							{!loading && data.map((x: any, i: number) => (
								<tr key={i} id={i.toString()}>
									<th className='pt-3 pb-3' scope='row'>
										{x.primer_nome}
									</th>
									<td className={`${styleScss['td-break']}`}>{x.email}</td>
									<td className={`${styleScss['td-break']}`}>{x.nombre_vacante}</td>
									{x.id_aprobado !== null && x.id_aprobado !== 0 && <td>Contratado</td>}
									{x.id_desaprobado !== null && x.id_desaprobado !== 0 && <td>Descartado</td>}
									{x.id_abandonar !== null && x.id_abandonar !== 0 && <td>No compareció</td>}
									{!x.id_aprobado && !x.id_desaprobado && !x.id_abandonar && <td> - </td>}
									<td>
										<Button
											id='basic-button'
											aria-controls={openMenu ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={openMenu ? 'true' : undefined}
											onClick={(e) =>
												handleClickMenu(
													e,
													x.id_entrevista_curriculo,
													x.id_empresa,
													x.id_entrevista_vaga
												)
											}
										>
											<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
										</Button>

										<Menu
											id='basic-menu'
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleCloseMenu}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem onClick={handleCloseMenu}>
												<Link to={`/empleador/entrevista/${id}/${idEmpresa}/${idVacancy}`}>
													Feedback candidato
												</Link>
											</MenuItem>
										</Menu>
									</td>
								</tr>
							))}
						</tbody>
						{data.length === 0 && (
							<tfoot>
								<tr>
									<td colSpan={5}>
										<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
											{/* <br/> */}
											No se encontraron registros
										</p>
									</td>
								</tr>
							</tfoot>
						)}
					</table>
				)}
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
		</>
	);
};

const EmployerRequestedFeedbackCandidatoWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Candidatos seleccionados</PageTitle>
			<EmployerRequestedFeedbackCandidatoPage />
		</>
	);
};

export { EmployerRequestedFeedbackCandidatoWrapper };
