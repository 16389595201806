import React, { useState } from 'react';
import { Registration } from '../modules/auth/components/Registration';
import { FirstUserProps } from '../models/FirstUserModel';

export const multiStepContext = React.createContext<any>(null);

const StepContext = () => {
	const [currentStep, setStep] = useState<number>(1);
	const [userData, setUserData] = useState<any[]>([]);
	const [firstUser, setFirstUser] = useState<FirstUserProps>({
		user: {
			email: '',
			confirma_email: '',
			password: '',
			confirma_password: '',
			role: 2,
			active: true,
			acepto_termos: false,
			dados_personales: false,
		},
		complemento: {
			tipo_doc: '',
			numero_doc: '',
			primer_nome: '',
			segundo_nome: '',
			primer_apelido: '',
			segundo_apelido: '',
			fecha_nascimiento: '',
			ciudad: '',
			estrato: '',
			localidad: '',
			upz: '',
			bairro: '',
			celular: '',
			correo: '',
			cargo: '',
			pregunta_seguridad: '',
			respuesta: '',
		},
	});
	const [finalData, setFinalData] = useState<any[]>([]);

	const submitData = () => {
		setFinalData((finalData) => [...finalData, userData]);
		setUserData([]);
	};
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<div>
			<multiStepContext.Provider
				value={{
					currentStep,
					setStep,
					userData,
					firstUser,
					setFirstUser,
					setUserData,
					finalData,
					setFinalData,
					submitData,
					requestInProgress, 
					setRequestInProgress,
					loading, 
					setLoading
				}}
			>
				<Registration />
			</multiStepContext.Provider>
		</div>
	);
};

export default StepContext;
