import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { Link } from 'react-router-dom';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { concatArrayToStringJoinBy, initValue, truncateWords } from '../../util/Index';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import { StatusAccompanimentModel } from '../../models/StatusAccompanimentModel'
import {
	getCompanies,
	downloadZipAllFilesByCompany,
	deleteEmpresaById,
	updateStatusEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
	getAccompaniment,
	deleteAcompaniment,
	putStatusAcompaniment,

	getListOfCalls,
	deleteConvocatoria,
	getListarAcompanamientos,
	getCerrarAcompanamiento,
	deleteAcompanamiento
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	TablePagination,
	Stack,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	CircularProgress,
	Select,
	InputLabel
} from '@mui/material';

import DatePicker from "react-multi-date-picker";
import dayjs from 'dayjs';
import { Close, Edit } from '@mui/icons-material';
import { da } from 'date-fns/locale';


interface StatusCompanyProps {
	id: number;
	status?: string;
	observaciones?: string;
}

const ListAccompanimentToCandidates = () => {
	const { isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [selectedDates, setSelectedDates] = useState<any>([]);
	const colombia_es_lowercase: any = {
		name: "gregorian_es_lowercase",
		months: [
			["enero", "ene"],
			["febrero", "feb"],
			["marzo", "mar"],
			["abril", "abr"],
			["mayo", "may"],
			["junio", "jun"],
			["julio", "jul"],
			["agosto", "ago"],
			["septiembre", "sep"],
			["octubre", "oct"],
			["noviembre", "nov"],
			["diciembre", "dic"],
		],
		weekDays: [
			["sábado", "sáb"],
			["domingo", "dom"],
			["lunes", "lun"],
			["martes", "mar"],
			["miércoles", "mié"],
			["jueves", "jue"],
			["viernes", "vie"],
		],
		digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		meridiems: [
			["AM", "am"],
			["PM", "pm"],
		],
	};
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [idConvocatoria, setIdConvocatoria] = useState();
	const [idAcompanimento, setIdAcompanamiento] = useState();
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [openModal, setOpenModal] = useState(false);
	const [openModalCloseAcompaniment, setOpenModalCloseAcompaniment] = useState(false);
	const [openModalDeleteAcompaniment, setOpenModalDeleteAcompaniment] = useState(false);
	const [textoBuscar, setTextoBuscar] = useState('');

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: ''
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleGetListaDeAcompanamientos();
	}, [filter]);



	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, estado: filter.estado };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Get de los acompañamientos empresariales
	const handleGetListaDeAcompanamientos = async () => {
		setLoading(true);
		const params = {
			pages: filter?.page,
			limit: filter?.limit,
			estado: filter?.estado,
			search: userData?.search,
			fecha_inicio: userData?.fecha_inicio,
			fecha_fin: userData?.fecha_fin,
		}
		try {
			const response = await getListarAcompanamientos(params);
			if (response && response.data) {
				setTotal(response.data.total);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};



	// Eliminar un acompañamiento 
	const handleDeleteAcompanamiento = async (id: any) => {
		try {
			await deleteAcompanamiento(id);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Acompañamiento eliminado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 1500);
			setOpenModalDeleteAcompaniment(false)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}

	}

	// Cerrar un acompanamiento
	const handleCerrarUnAcompanamiento = async (id: any) => {
		const close = { estado: "Cerrado", }
		try {
			await getCerrarAcompanamiento(id, close);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Acompañamiento cerrado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
			setOpenModalCloseAcompaniment(false)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};


	// const MenuAction = (props: any) => {
	// 	const [anchorEl, setAnchorEl] = useState(null);

	// 	const { data } = props;


	// 	const handleClick = (e: any) => {
	// 		setAnchorEl(e.currentTarget);
	// 	};

	// 	const handleClose = () => {
	// 		setAnchorEl(null);
	// 	};



	// 	const handleEditarConvocatoria = (id: any, visible: any) => {
	// 		return `/consejero/gestionar-acompanamiento/${id}`;
	// 	};




	// 	return (
	// 		<>
	// 			<Button onClick={handleClick}>
	// 				<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
	// 			</Button>

	// 			<Menu
	// 				id="card-actions-menu"
	// 				anchorEl={anchorEl}
	// 				keepMounted
	// 				open={Boolean(anchorEl)}
	// 				onClose={handleClose}
	// 			>
	// 				{data.status === "Cerrado" ? (
	// 					<MenuItem onClick={handleClose} key={`delete${data.id}`}>
	// 						<span onClick={() => handleOpenModalAcompanimentDelete(data.id)}>
	// 							<DeleteIcon />
	// 							&nbsp; Eliminar
	// 						</span>
	// 					</MenuItem>
	// 				) : (
	// 					<>
	// 						<MenuItem onClick={handleClose} key={`show${data.id}`}>
	// 							<Link to={handleEditarConvocatoria(data.id, true)}>
	// 								<Edit />
	// 								&nbsp; Editar
	// 							</Link>
	// 						</MenuItem>
	// 						<MenuItem onClick={handleClose} key={`close${data.id}`}>
	// 							<span onClick={() => handleOpenModalAcompaniment(data.id)}>
	// 								<Close />
	// 								&nbsp; Cerrar
	// 							</span>
	// 						</MenuItem>
	// 						<MenuItem onClick={handleClose} key={`delete${data.id}`}>
	// 							<span onClick={() => handleOpenModalAcompanimentDelete(data.id)}>
	// 								<DeleteIcon />
	// 								&nbsp; Eliminar
	// 							</span>
	// 						</MenuItem>
	// 					</>
	// 				)}
	// 			</Menu>

	// 		</>
	// 	)
	// }




	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
	  
		const { data } = props;
	  
		const handleClick = (e: any) => {
		  setAnchorEl(e.currentTarget);
		};
	  
		const handleClose = () => {
		  setAnchorEl(null);
		};
	  
		const handleEditarConvocatoria = (id: any, visible: any) => {
		  return `/consejero/gestionar-acompanamiento/${id}`;
		};
	  
		const handleOpenModalAcompaniment = (id: any) => {
		  // Aquí debes implementar la lógica para abrir el modal
		  console.log("Abrir modal para el acompañamiento con ID:", id);
		};
	  
		const handleOpenModalAcompanimentDelete = (id: any) => {
		  // Aquí debes implementar la lógica para abrir el modal de eliminación
		  console.log("Abrir modal de eliminación para el acompañamiento con ID:", id);
		};
	  
		return (
		  <div>
			<Button onClick={handleClick}>
			  <i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
			</Button>
	  
			<Menu
			  id="card-actions-menu"
			  anchorEl={anchorEl}
			  keepMounted
			  open={Boolean(anchorEl)}
			  onClose={handleClose}
			>
			  {data.status === "Cerrado" ? (
				<MenuItem onClick={handleClose} key={`delete${data.id}`}>
				  <span onClick={() => handleOpenModalAcompanimentDelete(data.id)}>
					<DeleteIcon />
					&nbsp; Eliminar
				  </span>
				</MenuItem>
			  ) : (
				[
				  <MenuItem onClick={handleClose} key={`show${data.id}`}>
					<Link to={handleEditarConvocatoria(data.id, true)}>
					  <Edit />
					  &nbsp; Editar
					</Link>
				  </MenuItem>,
				  <MenuItem onClick={handleClose} key={`close${data.id}`}>
					<span onClick={() => handleCerrarUnAcompanamiento(data.id)}>
					  <Close />
					  &nbsp; Cerrar
					</span>
				  </MenuItem>,
				  <MenuItem onClick={handleClose} key={`delete${data.id}`}>
					<span onClick={() => handleDeleteAcompanamiento(data.id)}>
					  <DeleteIcon />
					  &nbsp; Eliminar
					</span>
				  </MenuItem>,
				]
			  )}
			</Menu>
		  </div>
		);
	  };




	const handleOpenModalAcompaniment = (id: any) => {
		setOpenModalCloseAcompaniment(true);
		setIdAcompanamiento(id)
	};
	const handleCloseModalAcompaniment = () => {
		setOpenModalCloseAcompaniment(false);
	};


	const handleOpenModalAcompanimentDelete = (id: any) => {
		setOpenModalDeleteAcompaniment(true);
		setIdAcompanamiento(id)
	};
	const handleCloseModalAcompanimentDelete = () => {
		setOpenModalDeleteAcompaniment(false);
	};



	const handleCloseModal = () => {
		setOpenModal(false);
	};

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */


	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */


	const handleCrearAcompañamiento = () => {
		return `/consejero/gestionar-acompanamiento`;
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */


	const filtraTextoBuscar = () => {
		handleChangePage(0);
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, search: event.target.value });
	}


	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	const handleDateChange = (dates: any) => {
		setSelectedDates(dates);
		const searchDate = (startDate?: string, endDate?: string) => {
			setUserData({ ...userData, fecha_inicio: startDate, fecha_fin: endDate });
			setTimeout(() => {
				filtraTextoBuscar()
			}, 150);
		}
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			searchDate(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
		} else if (dates === null) {
			searchDate(undefined, undefined)
		}

	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15'
				style={{ backgroundColor: '#FFFF' }}
			>
				<LoadingBackdrop loading={isSubmitting} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%', alignItems: 'baseline' }}>
							{/* BackButton */}
							<div style={{ padding: '10px 0px 0px 15px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>

							{/* Filtro de busqueda*/}
							<div className='col-lg-5 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={'input-group mb-3'}>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Filtra por Consejero candidato y Candidato'
										aria-label='Filtra por Consejero candidato y Candidato'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchEmpresa'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							{/* fitro de fechas */}
							<div className='col-lg-3 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<DatePicker
									value={selectedDates}
									range={true} locale={colombia_es_lowercase}
									title='rango de fechas' name='rangeDate'
									placeholder='Filtra por rango de fechas'
									dateSeparator=' - ' format='DD/MM/YY'
									onChange={handleDateChange}
									style={{ padding: 20, fontSize: 14, width: 225 }}
								/>
							</div>
							{/* Boton  para registrar un acompañamiento */}
							<div className='col-lg-3 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>

								<Link to={handleCrearAcompañamiento()}
									className='btn btn-primary btn-lg'
									role="button">Registrar acompañamiento</Link>

							</div>

						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
					<Stack padding={2}>

						{/* <TableContainer component={Paper} className={`${styleScss['table-limit']}`} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}> */}

						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>

							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Consejero candidato</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Fecha de creacion</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Candidato</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Observación</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Estado</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Acciones</h4>
										</TableCell>
									</TableRow>
								</TableHead>

								{!loading && <TableBody>
									{data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.consejeroCandidato?.UserComplemento?.primer_nome} {x?.consejeroCandidato?.UserComplemento?.primer_apelido}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{dayjs(x?.fecha).format("DD/MM/YYYY")}

											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.createdByUser?.UserComplemento?.primer_nome} {x?.createdByUser?.UserComplemento?.primer_apelido}

												{x?.User?.UserComplemento?.primer_nome} {x?.User?.UserComplemento?.primer_apelido}
											</TableCell>
											<TableCell style={{
												fontFamily: 'Poppins',
												fontSize: '14.5px',
												maxWidth: 200,
											}}>
												{x.observaciones}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
												{x.estado}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												<MenuAction key={x.id} data={{ id: x.id, active: x.active, status: x.estado }} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>}

								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={8}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}

							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>
					</Stack>
				</div>
			</div>

			{/* modal de eliminar */}
			<div>
				<Dialog
					open={openModalDeleteAcompaniment}
					keepMounted
					onClose={handleCloseModalAcompanimentDelete}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Esta seguro que desea eliminar esta convocatoria'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							una vez realizado no es posible deshacer la acción.
						</DialogContentText>
						<br />
						<DialogContentText id='alert-dialog-description'>
							¿ Desea continuar con la eliminación del acompañamiento ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90, }}
							onClick={handleCloseModalAcompanimentDelete}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handleDeleteAcompanamiento(idAcompanimento)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			{/* modal Cerrar */}
			<div>
				<Dialog
					open={openModalCloseAcompaniment}
					keepMounted
					onClose={handleCloseModalAcompaniment}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Confirme que desea cerrar este acompañamiento.'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea cerrar este acompañamiento?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalAcompaniment}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handleCerrarUnAcompanamiento(idAcompanimento)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cerrar
						</Button>
					</DialogActions>
				</Dialog>
			</div>




			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const AccompanimentToCandidates = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Panel de acompañamiento consejero candidato</PageTitle>
			<ListAccompanimentToCandidates />
		</>
	);
};

export { AccompanimentToCandidates };
