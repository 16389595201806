import { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../modules/auth/core/Auth';
import styleCss from './styles.module.scss';

const Chatbot = () => {
	const { auth } = useAuth();
	const [reloadKey, setReloadKey] = useState(0);
	const [chatEnv, setChatEnv] = useState('');
	const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
	const [chatUrl, setChatUrl] = useState('https://www.gstatic.com/dialogflow-console/fast/messenger-cx/bootstrap.js?v=1');

	const inpRef = useRef();


	useEffect(() => {
		setChatEnv(process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : '');

		const chaticoScript = document.getElementById('chatico_vcc');
		if (chaticoScript) {
			const dfMessenger: any = document.querySelector('df-messenger');
			buildChaticoWelcomeMessage(dfMessenger);
		} else {
			const script = document.createElement('script');
			script.src = chatUrl;
			script.id = 'chatico_vcc';
			script.async = true;
			document.body.appendChild(script);
			/*const dfMessenger = document.querySelector('df-messenger');
			dfMessenger?.addEventListener('df-messenger-loaded', (evt)=>{
				buildChaticoWelcomeMessage(dfMessenger);
			});*/
		}

		/*return () => {
			document.body.removeChild(script);
			window.location.reload();
		};*/

	}, []);

	const buildChaticoWelcomeMessage = (dfMessenger: any) => {
		const payload = [
			{
				"type": "info",
				"title": `¡Hola, ${auth?.user?.nick}! Soy Chatico, tu asistente virtual de empleo! 😀`,
				"subtitle": `Escribe "Inicio" para volver al punto inicial de nuestra conversación.`,
			}
		];
		dfMessenger.renderCustomCard(payload);
	}

	return (
		<div key={reloadKey} className={`${styleCss['dft-messenger']}`}>
			<df-messenger
				ref={inpRef}
				className={`${styleCss['dft-messenger']}`}
				df-cx='true'
				location='global'
				chat-title='Chatico'
				agent-id={`${process.env.REACT_APP_CHATICO_AGENT_ID}`}
				chat-icon='https://storage.googleapis.com/docs-chatico/ChaticoBogota.png'
				language-code='es'
				intent='WELCOME'
				wait-open='true'
				session-id={auth ? `${auth.user?.id} | ${chatEnv} | ${auth?.api_token}` : ''}
			/>
		</div>
	);
};

export default Chatbot;
