import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getSearchVacantes } from '../../modules/apps/user-management/users-list/core/_requests';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { Alert, Snackbar } from '@mui/material';
import { convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY } from '../../util/Index';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { useAuth } from '../../modules/auth';


const CandidateSearchVacancyPage = () => {
	const { isCandidateRole, isCounselorCandidateRole, isCounselorEmployerRole } = useAuth();
	const { vacante, id_candidato } = useParams();
	const [data, setData]: any[] = useState([]);
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const handleClose = () => {
		setOpen(false);
	};

	const changeSearch = (data: any) => {
		//console.log(data);
		setSearch(data);
	};

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	useEffect(() => {
		vacante ? setSearch(vacante) : setSearch('');
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await getSearchVacantes(isCandidateRole() ? `page=${filter.page}&limit=${filter.limit}&vacante=${search}` : `page=${filter.page}&limit=${filter.limit}&vacante=${search}&id_candidato=${id_candidato}`);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setIsError(true);
				setMsgAlert(`${error.response.data.error}`);
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};
	const getPathVerVacante = (idVacante: number, id_candidato: any) => {
		if (isCandidateRole() || isCounselorEmployerRole()) {
			return `/candidato/vacante/${idVacante}?id_candidato=${id_candidato}`;
		} else if (isCounselorCandidateRole()) {
			return `/consejero/vacante/${idVacante}/${id_candidato}`;
		} else {
			return 'auth/*';
		}
	}

	const handleKeyDownEnterTextoBuscar = (event:any) => {
		if(event.key === 'Enter'){
			handleSubmit();
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15 ' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div>
								<div className='col-xs-12 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<button onClick={() => navigate(-1)} 
											className='btn btn-primary' 
											style={{ background: '#C4D2E8', borderRadius: '4px', padding: '10.5px 15px', marginTop: '4px'}}>
										<i className='bi bi-arrow-left' 
										style={{ color: 'black', fontSize: '20px'}}></i>
									</button>
								</div>
							</div>
							<div className='col-sm-3 my-1' style={{ padding: '0px 0px 0px 15px' }} >
								<input
									value={search}
									onChange={(e) => {
										changeSearch(e.target.value);
									}}
									className='form-control'
									type='text'
									placeholder='Busca la vacante'
									onKeyDown={handleKeyDownEnterTextoBuscar}
								/>
							</div>
							<div className='col-sm-2 my-1' style={{ padding: '0px 0px 0px 15px' }}>
								<button className={`btn btn-primary`}
									onClick={(e) => {
										setFilter({ ...filter, page: 1 });
									}}>
									<i className='bi bi-search'></i>Buscar
								</button>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.length !== 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{total} Vacantes de empleo encontradas
							</p>
						</div>
						<hr />
						<div className='row'>
							{!loading && data.map((x: any, i: number) => (
								<div className='col-sm-4' key={i}>
									<div className='card shadow p-3 mb-5 bg-white rounded' style={{ height: '95%' }}>
										<div className='card-body'>
											<h5 className='card-title text-primary'>{x.nombre_vacante}</h5>
											{x.codigo_vacante && (
												<>
													<b>Código:</b> {x.codigo_vacante}
												</>
											)}
											<div>
												{x.vacante_confidencial !== '0' ?  null : <h6 style={{ display: 'inline' }} className='card-subtitle mb-2 mt-4 text-muted'>
													<b>{x.Empresa.razon_social}</b>
												</h6> }
												
												<span style={{ display: 'inline', marginLeft: '20px' }}>
													<b>Fecha de anuncio:</b> {convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(x.createdAt.toLocaleString().substr(0, 10))}
												</span>
											</div>

											<div
												className='card-text mt-4'
												style={{
													height: '10rem',
													overflow: 'hidden',
													whiteSpace: 'normal',
													scrollbarWidth: 'revert',
													textAlign: 'justify'
												}}
											>
												<span className='mt-4'>{x.Describa_vacante}</span>
											</div>
											<div className='card-text mt-4'>
												<span className='mt-4'>
													<i className='bi bi-book' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>Nivel de estudios: </b>
													{x.NivelEducativo.desc_nivel_educativo}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-stopwatch' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>Experiencia: </b>
													{x.rango_experiencia} {x.rango_experiencia <= 1 ? 'mes' : 'meses'}
												</span>
												<br />
												<span className='mt-4'>
													<i className={`bi ${x.vacante_susceptible_teletrabajo == '1' ? 'bi-house-door' : 'bi-building'}`} style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{x.vacante_susceptible_teletrabajo == '1' ? 'Remoto' : 'Presencial'}</b>
												</span>
												<br />
											</div>
											<Link
												to={getPathVerVacante(x.id, id_candidato)}
												className={`btn btn-primary btn-lg btn-block`}
												style={{
													padding: '8px 20px',
													float: 'right',
												}}
											>
												Ver vacante
											</Link>
										</div>
									</div>
								</div>
							))}
						</div>
						<PaginationComponent
							totalPage={totalPage}
							total={total}
							customClass={''}
							paginate={changePaginate}
						/>
					</div>
				)}
				{data.length === 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								0 Vacantes de empleo encontradas
							</p>
						</div>
					</div>
				)}
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidateSearchVacantes = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Lista de vacantes</PageTitle>
			<CandidateSearchVacancyPage />
		</>
	);
};

export { CandidateSearchVacantes };
