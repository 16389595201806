import { FormEvent, useContext, useState, useEffect } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Box,
	Typography,
	Divider,
	FormControlLabel,
	Checkbox,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
	SelectChangeEvent,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import _ from 'lodash';
import { createCurriculo, getAreasDesempenio, getNivelesEducativos, getNivelesNucleos, getNucleosConocimiento, getTitulosHomologados } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import { initValue, isValidFieldRequired, getDateNowToString, setValueOrDefault, isValidMinMaxText, helperValidMinMaxText, convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY, convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD } from '../../../../../util/Index';
import useEducationParams from '../../../../../hooks/parameters/useEducationParams';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs, { Dayjs } from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

export const Step5 = () => {
	const { setStep, userData, setUserData, setCurrentProgress } = useContext(multiStepContext);
	const { listPais,
		otrosConocimientosList,
		listEstadoCurso,
		listSiNo } = useEducationParams();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [tieneEducacionFormal, setTieneEducacionFormal] = useState<string>('');
	const [nivelEducativo, setNivelEducativo] = useState<string>('');
	const [tituloObtenido, setTituloObtenido] = useState<string>('');
	const [institucion, setInstitucion] = useState<string>('');
	const [fechafinalizacion, setFechafinalizacion] = useState<string>('');
	const [tituloHomologado, setTituloHomologado] = useState<string>('');
	const [areaDesempeno, setAreaDesempeno] = useState<string>('');
	const [nucleoConocimiento, setNucleoConocimiento] = useState<string>('');
	const [paisDelCurso, setPaisDelCurso] = useState<string>('');
	const [estadoDelCurso, setEstadoDelCurso] = useState<string>('');
	const [idObservaciones, setIdObservaciones] = useState<string>('');
	const [txtNumeroTarjProf, setTxtNumeroTarjProf] = useState<string>('');
	const [fechaTarjProf, setFechaTarjProf] = useState<string>('');
	const [tarjetaProfesional, setTarjetaProfesional] = useState<string>('');

	const [interessadoPractica, setInteressadoPractica] = useState<boolean>(false);
	const [display, setDisplay] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [step, setStepUser] = useState<string>('step5');

	const [editMode, setEditMode] = useState(false);
	const [openRowEdit, setOpenRowEdit] = useState<number>(0);
	const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');

	const [ddlNivel, setDdlNivel] = useState([{ id: '', name: '' }]);
	const [ddlNucleo, setDdlNucleo] = useState([{ id: '', name: '' }]);
	const [ddlTitEquivalente, setDDLTitEquivalente] = useState([{ id: '', name: '' }]);
	const [ddlArea, setDdlArea] = useState([{ id: '', name: '' }]);




	const [rowsNivelEducativo, setRowsNivelEducativo] = useState([
		{
			id: 0,
			nivelEducativo: '',
			tituloObtenido: '',
			tituloHomologado: '',
			areaDesempeno: '',
			nucleoConocimiento: '',
			paisDelCurso: '',
			estadoDelCurso: '',
			idObservaciones: '',
			txtNumeroTarjProf: '',
			fechaTarjProf: '',
			interessadoPractica: false,
			Institucion: '',
			fechafinalizacion: '',
		},
	]);

	const [ddlNivelEducativo, setddlNivelEducativo] = useState<any[]>([]);
	const [ddlNucleoConocimiento, setddlNucleoConocimiento] = useState<any[]>([]);
	const [ddlNivelNucleo, setddlNivelNucleo] = useState<any[]>([]);
	const [ddlTituloHomologado, setddlTituloHomologado] = useState<any[]>([]);
	const [ddlAreasDesempenio, setddlAreasDesempenio] = useState<any[]>([]);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [editOrCreateItemInProgress, setEditOrCreateItemInProgress] = useState(false);

	const CODIGO_SI = '1';

	useEffect(() => {
		let tiene_nivel_educativo = userData['tiene_nivel_educativo'] === true ? CODIGO_SI : (userData['tiene_nivel_educativo'] === false ? '0' : '');
		setTieneEducacionFormal(tiene_nivel_educativo);
		if (
			userData['CurriculoNivelEducativos'] !== null &&
			userData['CurriculoNivelEducativos'] !== undefined &&
			(userData['CurriculoNivelEducativos'] as Array<any>).length > 0
		) {
			const listRowsNivelEducativo = rowsNivelEducativo.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsNivelEducativo) {
				const list = [...rowsNivelEducativo];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsNivelEducativo(list);
			}
			setRowsNivelEducativo(userData['CurriculoNivelEducativos']);
			setDisplay('none');
			setTieneEducacionFormal(CODIGO_SI);
			setUserData({ ...userData, tiene_nivel_educativo: true });
		} else {
			const listRowsNivelEducativo = rowsNivelEducativo.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsNivelEducativo) {
				const list = [...rowsNivelEducativo];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsNivelEducativo(list);
			}
		}

		//Carga la informacion de niveles educativos
		if (ddlNivelEducativo?.length <= 0) {
			fetchDataNivelesEducativos();
		}

		//Carga la informacion de nucleos de conocimiento
		if (ddlNucleoConocimiento?.length <= 0) {
			fetchDataNucleosConocimiento();
		}

		//Carga la informacion de relacion entre nivel educativo y nucleos de conocimiento
		if (ddlNivelNucleo?.length <= 0) {
			fetchDataNivelesNucleos();
		}

		//Carga la informacion de titulos homologados
		if (ddlTituloHomologado?.length <= 0) {
			fetchDataTitulosHomologados();
		}

		//Carga la informacion de areas de desempeño
		if (ddlAreasDesempenio?.length <= 0) {
			fetchDataAreasDesempenio();
		}

	}, []);



	/**
	 * Realiza llamado a consulta de niveles educativos
	 */
	const fetchDataNivelesEducativos = async () => {
		try {
			const response = await getNivelesEducativos();
			if (response && response.data) {
				setddlNivelEducativo(response.data);
				const list = [...ddlNivel];
				(list as Array<any>).forEach((d) => {
					ddlNivel.splice(_.findIndex(ddlNivel, { id: d['id'] }), 1);
				});
				(response.data as Array<any>).forEach((e) => {
					let x = {
						id: setValueOrDefault(e['sigla_nivel_educativo'], 'string'),
						name: setValueOrDefault(e['desc_nivel_educativo'], 'string'),
					};
					ddlNivel.push(x);
				});
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	/**
	 * Realiza llamado a consulta de nucleos de conocimiento
	 */
	const fetchDataNucleosConocimiento = async () => {
		try {
			const response = await getNucleosConocimiento();
			if (response && response.data) {
				setddlNucleoConocimiento(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	/**
	 * Realiza llamado a consulta de nucleos de conocimiento
	 */
	const fetchDataNivelesNucleos = async () => {
		try {
			const response = await getNivelesNucleos();
			if (response && response.data) {
				setddlNivelNucleo(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	/**
	 * Realiza llamado a consulta de nucleos de conocimiento
	 */
	const fetchDataTitulosHomologados = async () => {
		try {
			const response = await getTitulosHomologados();
			if (response && response.data) {
				setddlTituloHomologado(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	/**
	 * Realiza llamado a consulta de areas de desempeño
	 */
	const fetchDataAreasDesempenio = async () => {
		try {
			const response = await getAreasDesempenio();
			if (response && response.data) {
				setddlAreasDesempenio(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	const handleclear = () => {
		setChangedNivelEducativo(false);
		setNivelEducativo('');
		setTituloObtenido('');
		setTituloHomologado('');
		setAreaDesempeno('');
		setNucleoConocimiento('');
		setPaisDelCurso('');
		setEstadoDelCurso('');
		setIdObservaciones('');
		setTxtNumeroTarjProf('');
		setFechaTarjProf('');
		setInstitucion('');
		setFechafinalizacion('');
		setInteressadoPractica(false);
		setOpenRowEdit(0);
		setEditMode(false);
		setEditOrCreateItemInProgress(false);
	};

	const handleValidOnSave = () => {

		let errors: { field: string; error: string }[] = [];

		let camposError: string[] = [];

		//Se ajusta la regla, cuando el usuario ha seleccionado ¿Tiene educación formal? : SI, entonces debe diligenciar informacion de almenos un Nivel 
		if (!isValidFieldRequired(tieneEducacionFormal)) {
			errors.push({ field: '¿Tiene educación formal?', error: 'Debe indicar si tiene o no educación formal' });
			camposError.push('tieneEducacionFormal');
		}
		else if (tieneEducacionFormal == CODIGO_SI && rowsNivelEducativo.length === 0)
			errors.push({ field: 'Agregar nivel educativo', error: 'Debe diligenciar información del nivel educativo' });

		if (changedNivelEducativo && !isValidFieldRequired(nivelEducativo))
			errors.push({ field: 'Nivel Educativo', error: 'Campo requerido' });

		//Nivel educativo maximo
		if (!isValidFieldRequired(userData['nivel_educativo_max'])) {
			errors.push({ field: 'Nivel educativo máximo', error: 'Campo requerido' });
			camposError.push('nivel_educativo_max');
		}

		//Nivel educativo: 	Preescolar, Basica Primaria, Basica secundaria, Media
		if (isValidFieldRequired(nivelEducativo) && (nivelEducativo === 'PR' || nivelEducativo === 'P' || nivelEducativo === 'BS' || nivelEducativo === 'MD')) {
			if (!isValidFieldRequired(tituloHomologado)) {
				errors.push({ field: 'Titulo Homologado', error: 'Campo requerido' });
				camposError.push('titulo_homologado');
			}
			if (!isValidFieldRequired(institucion)) {
				errors.push({ field: 'Institución', error: 'Campo requerido' });
				camposError.push('institucion');
			}
			if (!isValidFieldRequired(estadoDelCurso)) {
				errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
				camposError.push('estado_curso');
			}
		}

		//Nivel educativo: Tecnica laboral
		if (isValidFieldRequired(nivelEducativo) && nivelEducativo === 'TL') {
			if (!isValidFieldRequired(areaDesempeno)) {
				errors.push({ field: 'Área de desempeño', error: 'Campo requerido' });
				camposError.push('areadesempeno');
			}
			if (!isValidFieldRequired(paisDelCurso)) {
				errors.push({ field: 'País del curso', error: 'Campo requerido' });
				camposError.push('paisdelcurso');
			}
			if (!isValidFieldRequired(estadoDelCurso)) {
				errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
				camposError.push('estadodelcurso');
			}
			if (!isValidFieldRequired(institucion)) {
				errors.push({ field: 'Institución', error: 'Campo requerido' });
				camposError.push('institucion');
			}
		}

		//Nivel educativo: Tecnica Profesional, Tecnologia, Universitaria, Especializacion, Maestris
		if (isValidFieldRequired(nivelEducativo) && (nivelEducativo === 'TP' || nivelEducativo === 'T' || nivelEducativo === 'PG' || nivelEducativo === 'E' || nivelEducativo === 'M' || nivelEducativo === 'D')) {
			if (!isValidFieldRequired(nucleoConocimiento)) {
				errors.push({ field: 'Nucleo Conocimiento', error: 'Campo requerido' });
				camposError.push('nucleoconocimiento');
			}
			if (!isValidFieldRequired(tituloHomologado)) {
				errors.push({ field: 'Titulo Homologado', error: 'Campo requerido' });
				camposError.push('titulohomologado');
			}
			if (!isValidFieldRequired(paisDelCurso)) {
				errors.push({ field: 'País titulo', error: 'Campo requerido' });
				camposError.push('paisdelcurso');
			}
			if (!isValidFieldRequired(estadoDelCurso)) {
				errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
				camposError.push('estadodelcurso');
			}
			if (!isValidFieldRequired(institucion)) {
				errors.push({ field: 'Institución', error: 'Campo requerido' });
				camposError.push('institucion');
			}
		}

		//Estado del curso: Graduado
		if (isValidFieldRequired(estadoDelCurso) && estadoDelCurso === '3') {
			if (!isValidFieldRequired(fechafinalizacion)) {
				errors.push({ field: 'Fecha Finalización', error: 'Campo requerido' });
				camposError.push('fechafinalizacion');
			}
			//Nivel educativo: Tecnica laboral, Tecnica Profesional, Tecnologia, Universitaria, Especializacion, Maestris
			if (nivelEducativo === 'TL' || nivelEducativo === 'TP' || nivelEducativo === 'T' || nivelEducativo === 'PG' || nivelEducativo === 'E' || nivelEducativo === 'M' || nivelEducativo === 'D') {
				if (!isValidFieldRequired(tarjetaProfesional)) {
					errors.push({ field: '¿Tiene tarjeta profesiona?', error: 'Campo requerido' });
					camposError.push('tarjetaProfesional');
				}
				//Si tiene tarjeta profesional
				if (isValidFieldRequired(tarjetaProfesional) && tarjetaProfesional == CODIGO_SI) {
					if (!isValidFieldRequired(txtNumeroTarjProf)) {
						errors.push({ field: 'Número tarjeta profesiona', error: 'Campo requerido' });
						camposError.push('txtNumeroTarjProf');
					}
					if (!isValidFieldRequired(fechaTarjProf)) {
						errors.push({ field: 'Fecha de expedición de tarjeta profesional', error: 'Campo requerido' });
						camposError.push('fechaTarjProf');
					}
				}
			}
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos completado\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;

	};




	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const handleAddNivelEducattivo = () => {
		if (handleValidOnSubmit()) {
			const listNivel = ddlNivel.find((obj) => {
				return obj.id === nivelEducativo || obj.name === nivelEducativo;
			});
			const listTitEquivalente = ddlTitEquivalente.find((obj) => {
				return obj.id === tituloHomologado || obj.name === tituloHomologado;
			});
			const listArea = ddlArea.find((obj) => {
				return obj.id === areaDesempeno || obj.name === areaDesempeno;
			});
			const listNucleo = ddlNucleo.find((obj) => {
				return obj.id === nucleoConocimiento || obj.name === nucleoConocimiento;
			});
			const listPaisTitulo = listPais.find((obj) => {
				return obj.code === paisDelCurso || obj.value === paisDelCurso;
			});
			const listEstado = listEstadoCurso.find((obj) => {
				return obj.code === estadoDelCurso || obj.value === estadoDelCurso;
			});

			let idNivel = listNivel?.id as string;
			let idTitEquivalente = listTitEquivalente?.id as string;
			let idArea = listArea?.id as string;
			let idNucleo = listNucleo?.id as string;
			let idPaisTitulo = (listPaisTitulo?.code as string) === undefined ? '' : (listPaisTitulo?.code as string);
			let idEstado = listEstado?.code as string;
			setRowsNivelEducativo([
				...rowsNivelEducativo,
				{
					id: new Date().getTime(),
					nivelEducativo: idNivel,
					tituloObtenido: tituloObtenido,
					tituloHomologado: idTitEquivalente,
					areaDesempeno: idArea,
					nucleoConocimiento: idNucleo,
					paisDelCurso: idPaisTitulo,
					estadoDelCurso: idEstado,
					idObservaciones: idObservaciones,
					txtNumeroTarjProf: tarjetaProfesional == CODIGO_SI ? txtNumeroTarjProf : '',
					fechaTarjProf: tarjetaProfesional == CODIGO_SI ? fechaTarjProf : '',
					interessadoPractica: interessadoPractica,
					Institucion: institucion,
					fechafinalizacion: fechafinalizacion,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Nivel educativo agregado con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			handleclear();
			setDisplay('none');
			setEditOrCreateItemInProgress(false);
		}
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveNivelEducativo = (id: number) => {
		const list = [...rowsNivelEducativo];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsNivelEducativo(list);
		if (rowsNivelEducativo.length === 0) {
			setDisplay('');
			setTieneEducacionFormal('');
		}

		if (list.length === 0) {
			setDisplay('');
			setTieneEducacionFormal('');
		}
	};

	const handleNext = () => {
		setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
		setStep(6);
	};
	const handleRetorno = () => {
		setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
		setStep(4);
	};

	const buscaDDLTitEquivalente = (nivel: any) => {
		if (nivel !== '') {
			if (nucleoConocimiento) {
				setNucleoConocimiento('');
			}
			const list = [...ddlTitEquivalente];
			(list as Array<any>).forEach((d) => {
				ddlTitEquivalente.splice(_.findIndex(ddlTitEquivalente, { id: d['id'] }), 1);
			});
			let id = ddlNivelEducativo.find(
				(y: any) => y.sigla_nivel_educativo === nivel
			)?.id;
			(ddlNivelNucleo as Array<any>).forEach((n) => {
				if (n['id_nivel_educativo'] === id) {
					(ddlTituloHomologado as Array<any>).forEach((t) => {
						if (n['id'] === t['id_nivel_nucleo']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number').toString(),
								name: setValueOrDefault(t['desc_titulo_homologado'], 'string'),
							};
							ddlTitEquivalente.push(x);
						}
					});
				}
			});
		}
	};

	const showDatePickeRetiro = () => {
		// @ts-ignore
		document.querySelector('#fechafinalizacion').showPicker();
	};

	const showDatePickeFechaTarjProf = () => {
		// @ts-ignore
		document.querySelector('#fechaTarjProf').showPicker();
	};


	const buscaDDLNucleoEquivalente = (nivel: any) => {
		if (!editMode) setNucleoConocimiento(nivel);
		if (nivel !== '') {
			const list3 = [...ddlTitEquivalente];
			(list3 as Array<any>).forEach((d) => {
				ddlTitEquivalente.splice(_.findIndex(ddlTitEquivalente, { id: d['id'] }), 1);
			});
			let id = ddlNivelEducativo.find(
				(y: any) => y.sigla_nivel_educativo === nivelEducativo
			)?.id;
			(ddlNivelNucleo as Array<any>).forEach((n) => {
				if (n['id_nucleo_conocimento'] === nivel && n['id_nivel_educativo'] === id) {
					(ddlTituloHomologado as Array<any>).forEach((t) => {
						if (n['id'] === t['id_nivel_nucleo']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number'),
								name: setValueOrDefault(t['desc_titulo_homologado'], 'string'),
							};
							ddlTitEquivalente.push(x);
						}
					});
				}
			});
		}
	};

	const buscaDDLArea = (nivel: any) => {
		if (nivel !== '') {
			const list = [...ddlArea];
			(list as Array<any>).forEach((d) => {
				ddlArea.splice(_.findIndex(ddlArea, { id: d['id'] }), 1);
				///setDDLTitEquivalente(list);
			});

			(ddlAreasDesempenio as Array<any>).forEach((n) => {
				let x = {
					id: setValueOrDefault(n['id'], 'number'),
					name: setValueOrDefault(n['desc_area_desempeno'], 'string'),
				};
				ddlArea.push(x);
			});
		}
	};

	const buscaDDLNucleo = (nivel: any) => {
		if (nivel !== '') {
			const list2 = [...ddlNucleo];
			(list2 as Array<any>).forEach((d) => {
				ddlNucleo.splice(_.findIndex(ddlNucleo, { id: d['id'] }), 1);
			});
			let id = ddlNivelEducativo.find(
				(y: any) => y.sigla_nivel_educativo === nivel
			)?.id;
			(ddlNivelNucleo as Array<any>).forEach((n) => {
				if (n['id_nivel_educativo'] === id) {
					(ddlNucleoConocimiento as Array<any>).forEach((t) => {
						if (n['id_nucleo_conocimento'] === t['id']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number'),
								name: setValueOrDefault(t['desc_nucleo_conocimiento'], 'string'),
							};
							ddlNucleo.push(x);
						}
					});
				}
			});
		}
	};

	const FieldsNivelEducativo = (nivel: any) => {
		switch (nivel) {
			case 'PR':
			case 'P':
			case 'BS':
			case 'MD':
				buscaDDLTitEquivalente(nivel);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label='Título obtenido'
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder='Escriba el título'
								size='medium'
								inputProps={{ maxLength: 500 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('titulo_homologado') && !isValidFieldRequired(tituloHomologado)}
							>
								<InputLabel required id={'tituloHomologado'}>
									Título homologado
								</InputLabel>
								<Select
									required
									labelId={'tituloHomologado'}
									id={'tituloHomologado'}
									label='Título homologado'
									value={initValue(tituloHomologado)}
									onChange={(e) => setTituloHomologado(e.target.value)}
									onBlur={() => handleBlur('titulo_homologado')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlTitEquivalente.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('titulo_homologado') && !isValidFieldRequired(tituloHomologado) && (
									<FormHelperText>
										{!isValidFieldRequired(tituloHomologado) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={4} pr={2} item>
							<TextField
								sx={{ minWidth: '100%' }}
								required
								fullWidth
								id={'institucion'}
								name={'institucion'}
								label='Institución'
								value={institucion}
								onChange={(e) => setInstitucion(e.target.value)}
								onBlur={() => handleBlur('institucion')}
								placeholder='Escreba el institución'
								size='medium'
								error={touchedFields.includes('institucion') && !isValidFieldRequired(institucion)}
								helperText={touchedFields.includes('institucion') && !isValidFieldRequired(institucion) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 500 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('estado_curso') && !isValidFieldRequired(estadoDelCurso)}
							>
								<InputLabel required id={'estadoDelCurso'}>
									Estado del curso
								</InputLabel>
								<Select
									required
									labelId={'estadoDelCurso'}
									id={'estadoDelCurso'}
									label='Estado del curso'
									value={estadoDelCurso}
									onChange={(e) => setEstadoDelCurso(e.target.value)}
									onBlur={() => handleBlur('estado_curso')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listEstadoCurso.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('estado_curso') && !isValidFieldRequired(estadoDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(estadoDelCurso) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={4} pr={2} item>
									<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
										<DemoItem>
											<DatePicker
												disableFuture
												value={dayjs(fechafinalizacion, 'YYYY-MM-DD')}
												format='DD/MM/YYYY'
												onOpen={() => handleBlur('fechafinalizacion')}
												onChange={(newValue) => {
													setFechafinalizacion(newValue ? newValue.format('YYYY-MM-DD') : '')
												}}
												slotProps={{
													textField: {
														id: 'fechafinalizacion',
														name: fechafinalizacion,
														label: 'Fecha de Finalización',
														required: true,
														variant: 'outlined',
														error: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion),
														helperText: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion) ? 'Campo requerido' : ''
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
											/>
										</DemoItem>
									</LocalizationProvider>
								</Grid>
							</>
						)}
						<Grid xs={4} mb={5} pr={2} item></Grid>

						<Grid xs={12} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'idObservaciones'}
								name={'idObservaciones'}
								label='Observaciones'
								placeholder='Escriba observaciones'
								value={idObservaciones}
								inputProps={{ type: 'text', maxLength: 1200, minLength: 300 }}
								onChange={(e) => setIdObservaciones(e.target.value)}
								onBlur={() => handleBlur('observaciones')}
								size='medium'
								multiline
								rows={4}
								error={touchedFields.includes('observaciones') && !isValidMinMaxText(idObservaciones, 300, 1200)}
								helperText={touchedFields.includes('observaciones') && helperValidMinMaxText(idObservaciones, 300, 1200)}
							/>
						</Grid>
						<Grid xs={12} mb={5} item>
							<FormControlLabel
								value={interessadoPractica}
								control={
									<Checkbox
										color='default'
										checked={interessadoPractica}
										value={interessadoPractica}
										onChange={(e) => setInteressadoPractica(e.target.checked)}
									/>
								}
								label='Estoy interesado(a) en realizar una práctica empresarial'
								labelPlacement='end'
							/>
						</Grid>
					</>
				);
			case 'TL':
				buscaDDLArea(nivel);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label='Título obtenido'
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder='Escriba el título'
								size='medium'
								inputProps={{ maxLength: 500 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('areadesempeno') && !isValidFieldRequired(areaDesempeno)}
							>
								<InputLabel required id={'areaDesempeno'}>
									Área de desempeño
								</InputLabel>
								<Select
									required
									labelId={'areaDesempeno'}
									id={'areaDesempeno'}
									label='Área de desempeño'
									value={areaDesempeno}
									onChange={(e) => setAreaDesempeno(e.target.value)}
									onBlur={() => handleBlur('areadesempeno')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlArea.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('areadesempeno') && !isValidFieldRequired(areaDesempeno) && (
									<FormHelperText>
										{!isValidFieldRequired(areaDesempeno) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('paisdelcurso') && !isValidFieldRequired(paisDelCurso)}
							>
								<InputLabel required id={'paisDelCurso'}>
									País del título
								</InputLabel>
								<Select
									required
									labelId={'paisDelCurso'}
									id={'paisDelCurso'}
									label='País titulo'
									value={paisDelCurso}
									onChange={(e) => setPaisDelCurso(e.target.value)}
									onBlur={() => handleBlur('paisdelcurso')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listPais.map(({ code, value }, i) => (
										<MenuItem key={i} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('paisdelcurso') && !isValidFieldRequired(paisDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(paisDelCurso) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('estadodelcurso') && !isValidFieldRequired(estadoDelCurso)}
							>
								<InputLabel required id={'estadoDelCurso'}>
									Estado del curso
								</InputLabel>
								<Select
									required
									labelId={'estadoDelCurso'}
									id={'estadoDelCurso'}
									label='Estado del curso'
									value={estadoDelCurso}
									onChange={(e) => setEstadoDelCurso(e.target.value)}
									onBlur={() => handleBlur('estadodelcurso')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listEstadoCurso.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('estadodelcurso') && !isValidFieldRequired(estadoDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(estadoDelCurso) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>

						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								required
								fullWidth
								id={'institucion'}
								name={'institucion'}
								label='Institución'
								value={institucion}
								onChange={(e) => setInstitucion(e.target.value)}
								onBlur={() => handleBlur('institucion')}
								placeholder='Escriba la institución'
								size='medium'
								error={touchedFields.includes('institucion') && !isValidFieldRequired(institucion)}
								helperText={touchedFields.includes('institucion') && !isValidFieldRequired(institucion) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 500 }}
							/>
						</Grid>
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={5} pr={2} item>
									<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
										<DemoItem>
											<DatePicker
												disableFuture
												value={dayjs(fechafinalizacion, 'YYYY-MM-DD')}
												format='DD/MM/YYYY'
												onOpen={() => handleBlur('fechafinalizacion')}
												onChange={(newValue) => {
													setFechafinalizacion(newValue ? newValue.format('YYYY-MM-DD') : '')
												}}
												slotProps={{
													textField: {
														id: 'fechafinalizacion',
														name: fechafinalizacion,
														label: 'Fecha de Finalización',
														required: true,
														variant: 'outlined',
														error: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion),
														helperText: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion) ? 'Campo requerido' : ''
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
											/>
										</DemoItem>
									</LocalizationProvider>
								</Grid>
							</>
						)}
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={5} pr={2} item>
									<FormControl
										sx={{ minWidth: '100%' }}
										size='medium'
										error={touchedFields.includes('tarjetaprofesional') && !isValidFieldRequired(tarjetaProfesional)}
									>
										<InputLabel required id={'tarjetaProfesional'}>
											¿Tiene tarjeta profesional?
										</InputLabel>
										<Select
											required
											labelId={'tarjetaProfesional'}
											id={'tarjetaProfesional'}
											label='¿Tiene tarjeta profesional?'
											value={tarjetaProfesional}
											onChange={(e) => onChangeTieneTarjetaProfesinal(e)}
											onBlur={() => handleBlur('tarjetaprofesional')}
										>
											<MenuItem value={''}>Seleccione...</MenuItem>
											<MenuItem value={'0'}>NO</MenuItem>
											<MenuItem value={'1'}>SI</MenuItem>
										</Select>
										{touchedFields.includes('tarjetaprofesional') && !isValidFieldRequired(tarjetaProfesional) && (
											<FormHelperText>
												{!isValidFieldRequired(tarjetaProfesional) ? 'Campo requerido' : ''}
											</FormHelperText>
										)}
									</FormControl>

								</Grid>
							</>
						)}

						{tarjetaProfesional == CODIGO_SI && (
							<>
								<Grid xs={12} md={4} mb={5} pr={2} item>
									<TextField
										required
										fullWidth
										id={'txtNumeroTarjProf'}
										name={'txtNumeroTarjProf'}
										label='Número tarjeta profesional'
										value={txtNumeroTarjProf}
										onChange={(e) => setTxtNumeroTarjProf(e.target.value)}
										onBlur={() => handleBlur('numerotarjprof')}
										placeholder='Número tarjeta profesional'
										size='medium'
										inputProps={{ type: 'text', maxLength: 30 }}
										error={touchedFields.includes('numerotarjprof') && !isValidFieldRequired(txtNumeroTarjProf)}
										helperText={touchedFields.includes('numerotarjprof') && !isValidFieldRequired(txtNumeroTarjProf) ? 'Campo requerido' : ''}
									/>
								</Grid>
								<Grid xs={4} mb={5} pr={2} item>
									<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
										<DemoItem>
											<DatePicker
												disableFuture
												value={dayjs(fechaTarjProf, 'YYYY-MM-DD')}
												format='DD/MM/YYYY'
												onOpen={() => handleBlur('fechatarjprof')}
												onChange={(newValue) => {
													setFechaTarjProf(newValue ? newValue.format('YYYY-MM-DD') : '')
												}}
												slotProps={{
													textField: {
														id: 'fechaTarjProf',
														name: fechaTarjProf,
														label: 'Fecha expedición de tarjeta profesional',
														required: true,
														variant: 'outlined',
														error: touchedFields.includes('fechatarjprof') && !isValidFieldRequired(fechaTarjProf),
														helperText: touchedFields.includes('fechatarjprof') && !isValidFieldRequired(fechaTarjProf) ? 'Campo requerido' : ''
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
											/>
										</DemoItem>
									</LocalizationProvider>
								</Grid>
							</>
						)}
						<Grid xs={4} mb={5} pr={2} item></Grid>
						<Grid xs={12} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'idObservaciones'}
								name={'idObservaciones'}
								label='Observaciones'
								placeholder='Escriba observaciones'
								value={idObservaciones}
								inputProps={{ type: 'text', maxLength: 1200, minLength: 300 }}
								onChange={(e) => setIdObservaciones(e.target.value)}
								onBlur={() => handleBlur('observaciones')}
								size='medium'
								multiline
								rows={4}
								error={touchedFields.includes('observaciones') && !isValidMinMaxText(idObservaciones, 300, 1200)}
								helperText={touchedFields.includes('observaciones') && helperValidMinMaxText(idObservaciones, 300, 1200)}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} item>
							<FormControlLabel
								value={interessadoPractica}
								control={
									<Checkbox
										color='default'
										checked={interessadoPractica}
										value={interessadoPractica}
										onChange={(e) => setInteressadoPractica(e.target.checked)}
									/>
								}
								label='Estoy interesado(a) en realizar una práctica empresarial'
								labelPlacement='end'
							/>
						</Grid>
					</>
				);
			case 'TP':
			case 'T':
			case 'PG':
			case 'E':
			case 'M':
			case 'D':
				buscaDDLNucleo(nivel);
				if (editMode) buscaDDLNucleoEquivalente(nucleoConocimiento);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label='Título obtenido'
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder='Escriba el título'
								size='medium'
								inputProps={{ maxLength: 500 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								className='col-12'
								error={touchedFields.includes('nucleoconocimiento') && !isValidFieldRequired(nucleoConocimiento)}
							>
								<InputLabel required id={'nucleoConocimiento'}>
									Núcleo de conocimiento
								</InputLabel>
								<Select
									required
									labelId={'nucleoConocimiento'}
									id={'nucleoConocimiento'}
									label='Núcleo de conocimiento'
									value={nucleoConocimiento}
									onBlur={() => handleBlur('nucleoconocimiento')}
									onChange={(e) => {
										buscaDDLNucleoEquivalente(e.target.value);
										setNucleoConocimiento(e.target.value);
									}}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlNucleo.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('nucleoconocimiento') && !isValidFieldRequired(nucleoConocimiento) && (
									<FormHelperText>
										{!isValidFieldRequired(nucleoConocimiento) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{FieldsNucleo(nucleoConocimiento)}
					</>
				);
			default:
				return <></>;
		}
	};

	const FieldsNucleo = (nivel: any) => {
		if (nivel) {
			return (
				<>
					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							className='col-12'
							error={touchedFields.includes('titulohomologado') && !isValidFieldRequired(tituloHomologado)}
						>
							<InputLabel required id={'tituloHomologado'}>
								Título homologado
							</InputLabel>
							<Select
								required
								labelId={'tituloHomologado'}
								id={'tituloHomologado'}
								label='Título homologado'
								value={tituloHomologado}
								onChange={(e) => setTituloHomologado(e.target.value)}
								onBlur={() => handleBlur('titulohomologado')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{ddlTitEquivalente.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('titulohomologado') && !isValidFieldRequired(tituloHomologado) && (
								<FormHelperText>
									{!isValidFieldRequired(tituloHomologado) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('paisdelcurso') && !isValidFieldRequired(paisDelCurso)}
						>
							<InputLabel required id={'paisDelCurso'}>
								País titulo
							</InputLabel>
							<Select
								required
								labelId={'paisDelCurso'}
								id={'paisDelCurso'}
								label='País titulo'
								value={paisDelCurso}
								onChange={(e) => setPaisDelCurso(e.target.value)}
								onBlur={() => handleBlur('paisdelcurso')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{listPais.map(({ code, value }, i) => (
									<MenuItem key={i} value={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('paisdelcurso') && !isValidFieldRequired(paisDelCurso) && (
								<FormHelperText>
									{!isValidFieldRequired(paisDelCurso) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('estadodelcurso') && !isValidFieldRequired(estadoDelCurso)}
						>
							<InputLabel required id={'estadoDelCurso'}>
								Estado del curso
							</InputLabel>
							<Select
								required
								labelId={'estadoDelCurso'}
								id={'estadoDelCurso'}
								label='Estado del curso'
								value={estadoDelCurso}
								onChange={(e) => setEstadoDelCurso(e.target.value)}
								onBlur={() => handleBlur('estadodelcurso')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{listEstadoCurso.map(({ code, value }) => (
									<MenuItem key={code} value={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('estadodelcurso') && !isValidFieldRequired(estadoDelCurso) && (
								<FormHelperText>
									{!isValidFieldRequired(estadoDelCurso) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid xs={12} md={4} mb={5} pr={2} item>
						<TextField
							required
							fullWidth
							id={'institucion'}
							name={'institucion'}
							label='Institución'
							value={institucion}
							onChange={(e) => setInstitucion(e.target.value)}
							placeholder='Escriba la institución'
							size='medium'
							error={touchedFields.includes('institucion') && !isValidFieldRequired(institucion)}
							helperText={touchedFields.includes('institucion') && !isValidFieldRequired(institucion) ? 'Campo requerido' : ''}
							inputProps={{ maxLength: 500 }}
						/>
					</Grid>
					{estadoDelCurso === '3' && (
						<>
							<Grid xs={12} md={4} mb={5} pr={2} item>
								<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
									<DemoItem>
										<DatePicker
											disableFuture
											value={dayjs(fechafinalizacion, 'YYYY-MM-DD')}
											format='DD/MM/YYYY'
											onOpen={() => handleBlur('fechafinalizacion')}
											onChange={(newValue) => {
												setFechafinalizacion(newValue ? newValue.format('YYYY-MM-DD') : '')
											}}
											slotProps={{
												textField: {
													id: 'fechafinalizacion',
													name: fechafinalizacion,
													label: 'Fecha de Finalización',
													required: true,
													variant: 'outlined',
													error: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion),
													helperText: touchedFields.includes('fechafinalizacion') && !isValidFieldRequired(fechafinalizacion) ? 'Campo requerido' : ''
												},
												actionBar: { actions: ["clear", "cancel", "today"] }
											}}
										/>
									</DemoItem>
								</LocalizationProvider>
							</Grid>
						</>
					)}
					{estadoDelCurso === '3' && (
						<>
							<Grid xs={4} mb={5} pr={2} item>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={!isValidFieldRequired(tarjetaProfesional) && touchedFields.includes('tarjetaProfesional')}
								>
									<InputLabel required id={'tarjetaProfesional'}>
										¿Tiene tarjeta profesional?
									</InputLabel>
									<Select
										required
										labelId={'tarjetaProfesional'}
										id={'tarjetaProfesional'}
										label='¿Tiene tarjeta profesional?'
										value={tarjetaProfesional}
										onChange={(e) => onChangeTieneTarjetaProfesinal(e)}
									>
										<MenuItem value={''}>Seleccione...</MenuItem>
										<MenuItem value={'0'}>NO</MenuItem>
										<MenuItem value={'1'}>SI</MenuItem>
									</Select>
									{!isValidFieldRequired(tarjetaProfesional) && touchedFields.includes('tarjetaProfesional') && (
										<FormHelperText>
											{!isValidFieldRequired(tarjetaProfesional) ? 'Campo requerido' : ''}
										</FormHelperText>
									)}
								</FormControl>

							</Grid>
						</>
					)}

					{tarjetaProfesional == CODIGO_SI && (
						<>
							<Grid xs={12} md={4} mb={5} pr={2} item>
								<TextField
									required
									fullWidth
									id={'txtNumeroTarjProf'}
									name={'txtNumeroTarjProf'}
									label='Número tarjeta profesional'
									value={txtNumeroTarjProf}
									onChange={(e) => setTxtNumeroTarjProf(e.target.value)}
									placeholder='Número tarjeta profesional'
									size='medium'
									inputProps={{ type: 'text', maxLength: 30 }}
									error={!isValidFieldRequired(txtNumeroTarjProf) && touchedFields.includes('txtNumeroTarjProf')}
									helperText={(!isValidFieldRequired(txtNumeroTarjProf) && touchedFields.includes('txtNumeroTarjProf')) ? 'Campo requerido' : ''}
								/>
							</Grid>
							<Grid xs={4} mb={5} pr={2} item>
								<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
									<DemoItem>
										<DatePicker
											disableFuture
											value={dayjs(fechaTarjProf, 'YYYY-MM-DD')}
											format='DD/MM/YYYY'
											onOpen={() => handleBlur('fechatarjprof')}
											onChange={(newValue) => {
												setFechaTarjProf(newValue ? newValue.format('YYYY-MM-DD') : '')
											}}
											slotProps={{
												textField: {
													id: 'fechaTarjProf',
													name: fechaTarjProf,
													label: 'Fecha expedición de tarjeta profesional',
													required: true,
													variant: 'outlined',
													error: touchedFields.includes('fechatarjprof') && !isValidFieldRequired(fechaTarjProf),
													helperText: touchedFields.includes('fechatarjprof') && !isValidFieldRequired(fechaTarjProf) ? 'Campo requerido' : ''
												},
												actionBar: { actions: ["clear", "cancel", "today"] }
											}}
										/>
									</DemoItem>
								</LocalizationProvider>
							</Grid>
						</>
					)}
					<Grid xs={12} md={4} mb={5} pr={2} item></Grid>
					<Grid xs={12} mb={5} item>
						<TextField
							fullWidth
							id={'idObservaciones'}
							name={'idObservaciones'}
							label='Observaciones'
							placeholder='Escriba observaciones'
							value={idObservaciones}
							inputProps={{ type: 'text', maxLength: 1200, minLength: 300 }}
							onChange={(e) => setIdObservaciones(e.target.value)}
							size='medium'
							multiline
							rows={4}
							error={!isValidMinMaxText(idObservaciones, 300, 1200)}
							helperText={helperValidMinMaxText(idObservaciones, 300, 1200)}
						/>
					</Grid>
					<Grid xs={12} md={4} mb={5} item>
						<FormControlLabel
							value={interessadoPractica}
							control={
								<Checkbox
									color='default'
									checked={interessadoPractica}
									value={interessadoPractica}
									onChange={(e) => setInteressadoPractica(e.target.checked)}
								/>
							}
							label='Estoy interesado(a) en realizar una práctica empresarial'
							labelPlacement='end'
						/>
					</Grid>
				</>
			);
		} else {
			return <></>;
		}
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];
		if (tieneEducacionFormal == CODIGO_SI)
			if (!isValidFieldRequired(nivelEducativo))
				errors.push({ field: 'Nivel educativo', error: 'Campo requerido' });
			else {
				switch (nivelEducativo) {
					case 'PR':
					case 'P':
					case 'BS':
					case 'MD':
						if (!isValidFieldRequired(tituloHomologado))
							errors.push({ field: 'Título homologado', error: 'Campo requerido' });
						camposError.push('titulo_homologado');
						if (!isValidFieldRequired(estadoDelCurso))
							errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
						camposError.push('estado_curso');
						if (!isValidFieldRequired(institucion))
							errors.push({ field: 'Institución', error: 'Campo requerido' });
						camposError.push('institucion');
						break;
					case 'TL':
						if (!isValidFieldRequired(areaDesempeno))
							errors.push({ field: 'Área de desempeño', error: 'Campo requerido' });
						camposError.push('areadesempeno');
						if (!isValidFieldRequired(paisDelCurso))
							errors.push({ field: 'País del curso', error: 'Campo requerido' });
						camposError.push('paisdelcurso');
						if (!isValidFieldRequired(estadoDelCurso))
							errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
						camposError.push('estadodelcurso');
						if (!isValidFieldRequired(institucion))
							errors.push({ field: 'Institución', error: 'Campo requerido' });
						camposError.push('institucion');
						if (isValidFieldRequired(estadoDelCurso) && estadoDelCurso === '3') {
							if (!isValidFieldRequired(tarjetaProfesional)) {
								errors.push({ field: '¿Tiene tarjeta profesiona?', error: 'Campo requerido' });
								camposError.push('tarjetaProfesional');
							}
							if (tarjetaProfesional == CODIGO_SI) {
								if (!isValidFieldRequired(txtNumeroTarjProf))
									errors.push({ field: 'Número tarjeta profesional', error: 'Campo requerido' });
								camposError.push('txtNumeroTarjProf');
								if (!isValidFieldRequired(fechaTarjProf))
									errors.push({ field: 'Fecha expedición de tarjeta profesional', error: 'Campo requerido' });
								camposError.push('fechaTarjProf');
							}
						}
						break;
					case 'TP':
					case 'T':
					case 'PG':
					case 'E':
					case 'M':
					case 'D':
						if (!isValidFieldRequired(nucleoConocimiento)) {
							errors.push({ field: 'nucleo_Conocimiento', error: 'Campo requerido' });
							camposError.push('nucleoconocimiento');
						} else {
							if (!isValidFieldRequired(tituloHomologado))
								errors.push({ field: 'titulo_homologado', error: 'Campo requerido' });
							camposError.push('titulohomologado');
							if (!isValidFieldRequired(paisDelCurso))
								errors.push({ field: 'pais_Del_Curso', error: 'Campo requerido' });
							camposError.push('paisdelcurso');
							if (!isValidFieldRequired(estadoDelCurso))
								errors.push({ field: 'estado_Del_Curso', error: 'Campo requerido' });
							camposError.push('estadodelcurso');
							if (!isValidFieldRequired(institucion))
								errors.push({ field: 'institucion', error: 'Campo requerido' });
							camposError.push('institucion');
						}
						if (isValidFieldRequired(estadoDelCurso) && estadoDelCurso === '3') {
							if (!isValidFieldRequired(tarjetaProfesional)) {
								errors.push({ field: '¿Tiene tarjeta profesiona?', error: 'Campo requerido' });
								camposError.push('tarjetaProfesional');
							}
							if (tarjetaProfesional == CODIGO_SI) {
								if (!isValidFieldRequired(txtNumeroTarjProf))
									errors.push({ field: 'Número tarjeta profesional', error: 'Campo requerido' });
								camposError.push('txtNumeroTarjProf');
								if (!isValidFieldRequired(fechaTarjProf))
									errors.push({ field: 'Fecha expedición de tarjeta profesional', error: 'Campo requerido' });
								camposError.push('fechaTarjProf');
							}
						}
						break;
					default:
						break;
				}

				//Se adiciona control de longitud minima y maxima para el atributo de observaciones
				if (idObservaciones !== undefined || idObservaciones !== '') {
					if (!isValidMinMaxText(idObservaciones, 300, 1200))
						errors.push({
							field: 'Observaciones',
							error: helperValidMinMaxText(idObservaciones, 300, 1200).split('\n')[0],
						});
					camposError.push('observaciones');
				}

				//Valida fecha de finalización cuando el estado del curso es graduado
				if (isValidFieldRequired(estadoDelCurso) && estadoDelCurso === '3') {
					if (!isValidFieldRequired(fechafinalizacion)) {
						errors.push({ field: 'Fecha Finalización', error: 'Campo requerido' });
						camposError.push('fechafinalizacion');
					}
				}
			}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};

	const handleClose = () => {
		setOpen(false);
	};

	async function handleSubmit(event: FormEvent) {

		if (handleValidOnSave()) {
			setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
			setLoading(true);
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(6);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];
		if (
			rowsNivelEducativo !== null &&
			rowsNivelEducativo !== undefined &&
			(rowsNivelEducativo as Array<any>).length > 0
		) {
			(rowsNivelEducativo as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
					Institucion: setValueOrDefault(e['Institucion'], 'string'),
					fechafinalizacion: setValueOrDefault(e['fechafinalizacion'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						otrosConocimientosList.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {

		let resume: ResumeModel = {
			// campo nivel max para envio 
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			// antiguo
			// nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
			tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
			tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'], 'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
			tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
			tiene_nivel_educativo: createObjectNivelEducativo()?.length > 0 ? true : (tieneEducacionFormal === CODIGO_SI ? true : (tieneEducacionFormal === '0' ? false : userData['tiene_nivel_educativo'])),
			tiene_experiencia_laboral: userData['tiene_experiencia_laboral'],
			tiene_capacitaciones: userData['tiene_capacitaciones'],

		};

		return resume;
	};

	/**
	 * Metodo de carga de inforación para la edicion
	 * @param item 
	 */
	const handleEdit = (item: any) => {
		setEditOrCreateItemInProgress(true);
		setEditMode(true);
		setOpenRowEdit(item.id);
		setBorderColorEdit('2px solid blue');
		setTimeout(() => {
			setBorderColorEdit('1px solid');
		}, 4000);
		window.scrollTo(0, 170);
		setTieneEducacionFormal(CODIGO_SI);
		setNivelEducativo(ddlNivel.find((x: any) => x.id == item.nivelEducativo || x.name == item.nivelEducativo)?.id || '');
		setInstitucion(item.Institucion || '');
		setFechafinalizacion(convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD(item.fechafinalizacion) || '');
		if (isValidFieldRequired(item.fechaTarjProf) || isValidFieldRequired(item.txtNumeroTarjProf)) { setTarjetaProfesional(CODIGO_SI); } else { setTarjetaProfesional('0'); }
		setFechaTarjProf(item.fechaTarjProf || '');
		setTxtNumeroTarjProf(item.txtNumeroTarjProf || '');
		setAreaDesempeno(ddlAreasDesempenio.find((x: any) => x.desc_area_desempeno == item.areaDesempeno || x.id == item.areaDesempeno)?.id || '');
		setEstadoDelCurso(listEstadoCurso.find((x: any) => x.value == item.estadoDelCurso || x.code == item.estadoDelCurso)?.code || '');
		setIdObservaciones(item.idObservaciones || '');
		setInteressadoPractica(item.interessadoPractica || '');
		setNucleoConocimiento(ddlNucleoConocimiento.find((x: any) => x.desc_nucleo_conocimiento == item.nucleoConocimiento || x.id == item.nucleoConocimiento)?.id || '');
		setPaisDelCurso(listPais.find((x: any) => x.value == item.paisDelCurso || x.code == item.paisDelCurso)?.code || '');

		const nivel = ddlNivel.find((x: any) => x.id == item.nivelEducativo || x.name == item.nivelEducativo);
		//Nivel educativo: 	Preescolar, Basica Primaria, Basica secundaria, Media		
		if (nivel?.id === 'PR' || nivel?.id === 'P' || nivel?.id === 'BS' || nivel?.id === 'MD') {
			//Obtiene el id del nivel educativo del item
			const nivelEducativo = ddlNivelEducativo.find((x: any) => x.sigla_nivel_educativo == item.nivelEducativo || x.desc_nivel_educativo == item.nivelEducativo);
			//Pre carga la lista de opciones disponibles
			buscaDDLTitEquivalente(nivelEducativo?.sigla_nivel_educativo)
			//Obtiene el titulo homologado			
			setTituloHomologado(ddlTitEquivalente.find((x: any) => x.name == item.tituloHomologado || x.id == item.tituloHomologado)?.id || '');
		} else {
			//Pre procesamiento para encontrar la relacion de Nivel y Nucleo de Conocimiento, vinculados al titulo homologado
			//Obtiene el id del nivel educativo del item
			const idNivelEducativo = ddlNivelEducativo.find((x: any) => x.sigla_nivel_educativo == item.nivelEducativo || x.desc_nivel_educativo == item.nivelEducativo)?.id || '';
			//Obtiene el id del nucleo de conocimiento del item
			const idNucleoConocimiento = ddlNucleoConocimiento.find((x: any) => x.desc_nucleo_conocimiento == item.nucleoConocimiento || x.id == item.nucleoConocimiento)?.id || '';
			//Obtiene el id de la relacion entre nivel y nucleo
			const idNivelNucleo = ddlNivelNucleo.find((x: any) => x.id_nivel_educativo === idNivelEducativo && x.id_nucleo_conocimento === idNucleoConocimiento)?.id || '';
			//Obtiene el titulo homologado
			setTituloHomologado(ddlTituloHomologado.find((x: any) => (x.desc_titulo_homologado == item.tituloHomologado || x.id == item.tituloHomologado) && x.id_nivel_nucleo === idNivelNucleo)?.id || '');
		}
		setTituloObtenido(item.tituloObtenido || '');
	};

	const handleSaveEdit = () => {
		if (editMode) {
			let iRemove = rowsNivelEducativo.findIndex((x) => x.id === openRowEdit);
			if (handleValidOnSubmit()) {
				const listNivel = ddlNivel.find((obj) => {
					return obj.id === nivelEducativo || obj.name === nivelEducativo;
				});
				const listTitEquivalente = ddlTitEquivalente.find((obj) => {
					return obj.id === tituloHomologado || obj.name === tituloHomologado;
				});
				const listArea = ddlArea.find((obj) => {
					return obj.id === areaDesempeno || obj.name === areaDesempeno;
				});
				const listNucleo = ddlNucleo.find((obj) => {
					return obj.id === nucleoConocimiento || obj.name === nucleoConocimiento;
				});
				const listPaisTitulo = listPais.find((obj) => {
					return obj.code === paisDelCurso || obj.value === paisDelCurso;
				});
				const listEstado = listEstadoCurso.find((obj) => {
					return obj.code === estadoDelCurso || obj.value === estadoDelCurso;
				});

				let idNivel = listNivel?.id as string;
				let idTitEquivalente = listTitEquivalente?.id as string;
				let idArea = listArea?.id as string;
				let idNucleo = listNucleo?.id as string;
				let idPaisTitulo = (listPaisTitulo?.code as string) === undefined ? '' : (listPaisTitulo?.code as string);
				let idEstado = listEstado?.code as string;

				let itemsToSave = rowsNivelEducativo.filter((_, i) => i !== iRemove);

				itemsToSave.push({
					id: openRowEdit || new Date().getTime(),
					nivelEducativo: idNivel,
					tituloObtenido: tituloObtenido,
					tituloHomologado: idTitEquivalente,
					areaDesempeno: idArea,
					nucleoConocimiento: idNucleo,
					paisDelCurso: idPaisTitulo,
					estadoDelCurso: idEstado,
					idObservaciones: idObservaciones,
					txtNumeroTarjProf: tarjetaProfesional === CODIGO_SI ? txtNumeroTarjProf : '',
					fechaTarjProf: tarjetaProfesional === CODIGO_SI ? fechaTarjProf : '',
					interessadoPractica: interessadoPractica,
					Institucion: institucion,
					fechafinalizacion: fechafinalizacion,
				});
				setRowsNivelEducativo(itemsToSave);
				handleclear();
				setEditOrCreateItemInProgress(false);
			}
		}
	};

	const onChangeTieneEducacionFormal = (e: SelectChangeEvent) => {
		let tieneNivelEducativo = null;
		if (e.target.value == CODIGO_SI) {
			setTieneEducacionFormal(e.target.value)
			if (rowsNivelEducativo && rowsNivelEducativo.length === 0) {
				setChangedNivelEducativo(true);
			}
			tieneNivelEducativo = true;
		} else {
			setTieneEducacionFormal('0')
			handleclear();
			tieneNivelEducativo = false;
		}
		userData['tiene_nivel_educativo'] = tieneNivelEducativo;
	}

	const clearDependentFields = () => {
		setTituloHomologado('');
		setNucleoConocimiento('');
		setEstadoDelCurso('');
		setTarjetaProfesional('');
		// setPaisDelCurso('');
		// setInstitucion('');
		setAreaDesempeno('');
	};

	//Flag para saber si ha cambiado el input de nivel educativo
	const [changedNivelEducativo, setChangedNivelEducativo] = useState<boolean>(false);

	//Metodo de control para evento de cambio del input: 'Nivel educativo'
	const onChangeNivelEducativo = (e: SelectChangeEvent) => {
		setNivelEducativo(e.target.value);
		clearDependentFields();
		setChangedNivelEducativo(isValidFieldRequired(e.target.value) ? true : false);
		setEditOrCreateItemInProgress(true);
	}
	//Metodo de control para evento de cambio del input: 'Tiene tarjeta profesional'
	const onChangeTieneTarjetaProfesinal = (e: SelectChangeEvent) => {
		setTarjetaProfesional(e.target.value)
		setFechaTarjProf('');
		setTxtNumeroTarjProf('');
	}

	return (
		<>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Agregar nivel educativo{editMode ? ' - modo de edición' : ''}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					// border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
					border: `${borderColorEdit}`,
				}}
				item
			>
				<Grid xs={4} mb={5} pr={2} display={display} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={
							touchedFields.includes('tieneEducacionFormal')
							&& !isValidFieldRequired(tieneEducacionFormal)
						}
					>
						<InputLabel required id={'tieneEducacionFormal'}>¿Tiene educación formal?</InputLabel>
						<Select
							required
							labelId={'tieneEducacionFormal'}
							id={'tieneEducacionFormal'}
							label='¿Tiene educación formal?'
							value={tieneEducacionFormal}
							onChange={(e) => onChangeTieneEducacionFormal(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listSiNo.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('tieneEducacionFormal')
							&& !isValidFieldRequired(tieneEducacionFormal)
							&& (
								<FormHelperText>
									Debe indicar si tiene o no educación formal
								</FormHelperText>
							)
						}
					</FormControl>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('nivel_educativo_max') && !isValidFieldRequired(userData['nivel_educativo_max'])}
					>
						<InputLabel required id='nivel_educativo_max'>
							<span>
								{' '}
								¿Cuál es el nivel máximo de educación culminado con éxito?
							</span>
						</InputLabel>
						<Select
							required
							labelId='nivel_educativo_max'
							id='nivel_educativo_max'
							label='¿Cuál es el nivel máximo de educación culminado con éxito?'
							value={userData['nivel_educativo_max']}
							onBlur={() => handleBlur('nivel_educativo_max')}
							onChange={(e) => setUserData({ ...userData, nivel_educativo_max: e.target.value })}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{ddlNivelEducativo.map(({ id, sigla_nivel_educativo, desc_nivel_educativo }) => (
								<MenuItem key={id} value={sigla_nivel_educativo}>
									{desc_nivel_educativo}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('nivel_educativo_max') && !isValidFieldRequired(userData['nivel_educativo_max']) && (
							<FormHelperText>
								{!isValidFieldRequired(userData['nivel_educativo_max']) ? 'Campo requerido' : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				{tieneEducacionFormal == CODIGO_SI && (
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={changedNivelEducativo && !isValidFieldRequired(nivelEducativo)}
							>
								<InputLabel required id={nivelEducativo}>
									Nivel educativo
								</InputLabel>
								<Select
									required
									labelId={nivelEducativo}
									id={nivelEducativo}
									label='Nivel educativo'
									value={nivelEducativo}
									onChange={(e) => { onChangeNivelEducativo(e) }}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlNivel.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
								{changedNivelEducativo && !isValidFieldRequired(nivelEducativo) && (
									<FormHelperText>
										{changedNivelEducativo && !isValidFieldRequired(nivelEducativo) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{FieldsNivelEducativo(nivelEducativo)}
					</Grid>
				)}
				<Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>

					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						{editMode ? (
							<>
								<Button
									variant='contained'
									onClick={handleSaveEdit}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Guardar
								</Button>
							</>
						) : (
							<>
								<Button
									disabled={tieneEducacionFormal === '0' || tieneEducacionFormal === ''}
									variant='contained'
									onClick={handleAddNivelEducattivo}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Agregar
								</Button>
							</>
						)
						}
						{editOrCreateItemInProgress && (
							<Button
								variant='contained'
								sx={{ width: 90, background: '#6c757d', mr: 1 }}
								onClick={handleclear}
							>
								Cancelar
							</Button>
						)}

					</Box>
				</Grid>
			</Grid >
			{
				rowsNivelEducativo.length > 0 && (
					<Grid xs={12} item>
						<Typography color='#2B2B2B' variant='h6'>
							Agregados
						</Typography>
						<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
					</Grid>
				)
			}
			< Grid xs={12} item >
				{
					rowsNivelEducativo.map((row, i) => (
						<Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								width={'100%'}
								sx={{
									backgroundColor: '#1C447F',
									pt: 2,
									pl: 2,
									pb: 2,
									pr: 2,
									borderTopLeftRadius: 6,
									borderTopRightRadius: 6,
								}}
								item
							>
								<Typography color='white' variant='h6'>
									{'Nivel educativo: ' + ddlNivel.find((x: any) => x.id === row.nivelEducativo || x.name === row.nivelEducativo)?.name || ''}
								</Typography>
								<Box display='flex' justifyContent='end' alignItems='center'>
									<div>
										<div className='d-flex justify-content-end flex-shrink-0'>
											<button
												disabled={editOrCreateItemInProgress}
												className='btn btn-icon btn-active-color-secondary btn-sm me-3'
												style={{ background: '#C4D2E8' }}
												onClick={() => {
													handleEdit(row);
													// setOpenRowEdit(row.id === openRowEdit as number ? null : row.id);
												}}
											>
												<EditIcon />
											</button>
											<button
												disabled={editOrCreateItemInProgress}
												className='btn btn-icon btn-active-color-secondary btn-sm'
												onClick={() => handleRemoveNivelEducativo(row.id)}
												style={{ background: '#DD1342' }}
											>
												<DeleteOutlinedIcon style={{ color: 'white' }} />
											</button>
										</div>
									</div>
								</Box>
							</Grid>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								width={'100%'}
								mb={5}
								sx={{
									border: 1,
									borderBottom: 3,
									borderColor: '#AFADAD',
									borderBottomLeftRadius: 6,
									borderBottomRightRadius: 6,
									pt: 5,
									pl: 3,
									pr: 3,
								}}
								item
							>
								<Grid xs={12} md={3} mb={5} item>
									<Typography>{'Nivel educativo: ' + (ddlNivel.find((x: any) => x.id == row.nivelEducativo || x.name == row.nivelEducativo)?.name || '')}</Typography>
									<Typography>{'Estado del curso: ' + (listEstadoCurso.find((x: any) => x.value == row.estadoDelCurso || x.code == row.estadoDelCurso)?.value || '')}</Typography>
									{row.paisDelCurso && (<Typography>{'Pais del curso: ' + (listPais.find((x: any) => x.value == row.paisDelCurso || x.code == row.paisDelCurso)?.value || '')}</Typography>)}
									<Typography>{'Institución: ' + (row.Institucion || '')}</Typography>
									{row.fechafinalizacion && (<Typography>{'Fecha de finalización: ' + (convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(row.fechafinalizacion) || '')}</Typography>)}
									{row.fechaTarjProf && (<Typography>{row.fechaTarjProf ? 'Fecha expedición de tarjeta profesional: ' + (convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(row.fechaTarjProf) || '') : ''}</Typography>)}
								</Grid>
								<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
								<Grid xs={12} md={3} mb={5} item>
									{row.tituloObtenido && (<Typography>{'Título obtenido: ' + (row.tituloObtenido || '')}</Typography>)}
									{row.tituloHomologado && (<Typography>{'Título homologado: ' + (ddlTituloHomologado.find((x: any) => x.id == row.tituloHomologado || x.desc_titulo_homologado == row.tituloHomologado)?.desc_titulo_homologado || '')}</Typography>)}
									{row.nucleoConocimiento && (<Typography>{'Núcleo de conocimiento: ' + (ddlNucleoConocimiento.find((x: any) => x.id == row.nucleoConocimiento || x.desc_nucleo_conocimiento == row.nucleoConocimiento)?.desc_nucleo_conocimiento || '')}</Typography>)}
									{row.txtNumeroTarjProf && (<Typography>{row.txtNumeroTarjProf ? 'Número tarjeta profesional: ' + (row.txtNumeroTarjProf || '') : ''}</Typography>)}
								</Grid>
								<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
								<Grid xs={12} md={3} mb={5} item>
									{row.areaDesempeno && (<Typography>{'Área de desempeño: ' + (ddlAreasDesempenio.find((x: any) => x.desc_area_desempeno == row.areaDesempeno || x.id == row.areaDesempeno)?.desc_area_desempeno || '' || '')}</Typography>)}
									{row.idObservaciones && (<Typography>{'Observaciones: ' + (row.idObservaciones || '')}</Typography>)}
									<Typography>{'Está interesado(a) en realizar práctica empresarial: ' + (row.interessadoPractica ? 'Sí' : 'No')}</Typography>
								</Grid>
							</Grid>
						</Grid>
					))
				}
			</Grid >
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					Atrás
				</Button>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
					disabled={editOrCreateItemInProgress}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
