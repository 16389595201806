/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import './style.css';

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, pageTitle } = config

  return (
    <div
      id='kt_header'
      className={clsx('header  flex-column h-100px', classes.header.join(' '), ' align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='Govcontainer'>
        {/* begin::Container */}
        <div className=' d-flex flex-column pt-1 h-35px mb-2' style={{ background: '#3366C9', }}>
          {/* begin::Logo */}
           
            <a href='https://www.gov.co' target="_blank" className='mb-1 '>
            <img alt='Logo' className='h-35px ' src={toAbsoluteUrl('/media/logos/logo.png')} />
            </a>
          {/* end::Logo */}
        </div>


        <div>

          <div
            className={clsx(
              classes.headerContainer.join(' '),
              'd-flex align-items-stretch justify-content-between'
            )}
          >
            {/* begin::Aside mobile toggle */}

            <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
              <div className='btn btn-icon btn-active-color-primary w-40px h-40px' id='kt_aside_toggle'>
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
              </div>
            </div>
            {/* end::Aside mobile toggle */}

            {/* begin::Mobile logo */}
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/dashboard' className='d-lg-none'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logo-bogota.png')}
                  className='h-30px'
                />
              </Link>
            </div>
            {/* end::Mobile logo */}
            <Link to='/'>
            <div className='logobogota d-flex justify-content-center align-items-center' style={ {paddingRight:'15px'}}>
              <img
                    alt='Logo-bogota'
                    src={toAbsoluteUrl('/media/logos/logo-bogota.png')}
                    className='h-40px'
                  />
            </div>
            </Link>
            {pageTitle?.display && (
              <div className='d-flex align-items-center' id='kt_header_wrapper'>
                <DefaultTitle />
              </div>
            )}

            {/* begin::Wrapper */}
            <div
              className={clsx(
                'd-flex align-items-stretch',
                `justify-content-${header.menu ? 'between' : 'end'}`,
                'flex-lg-grow-1'
              )}
            >
              {header.menu && (
                <div className='d-flex align-items-stretch' id='kt_header_nav'>
                  <Header />
                </div>
              )}

              <Topbar />
            </div>
            {/* end::Wrapper */}
          </div>
        </div>

        {/* end::Container */}
      </div>
    </div>
  )
}
