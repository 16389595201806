import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import parameterSingleton from '../../../services/Parameters.service';
import parameters from '../../../util/parameters';

const useLanguageAndSkillsParams = () => {
	const [listIdioma, setListIdioma] = useState<ParameterConfigMmodel[]>([]);
	const [listNivelIdioma, setListNivelIdioma] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoOtrosConocimientos, setListTipoOtrosConocimientos] = useState<ParameterConfigMmodel[]>([]);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = () => {
		 fetchIdioma();
		 fetchNivelIdioma();
		 fetchListTipoOtrosConocimientos();
	};

	const fetchIdioma = async () => {
		const res = await parameterSingleton.fetchData(parameters.IDIOMA);
		setListIdioma(res);
	};
	const fetchNivelIdioma = async () => {
		const res = await parameterSingleton.fetchData(parameters.NIVELIDIOMA);
		setListNivelIdioma(res);
	};
	const fetchListTipoOtrosConocimientos = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setListTipoOtrosConocimientos(res);
	}

	return {
		listIdioma,
		listNivelIdioma,
		listTipoOtrosConocimientos
	};
};

export default useLanguageAndSkillsParams;
