import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import parameterSingleton from '../../../services/Parameters.service';
import parameters from '../../../util/parameters';
import { AxiosRequestConfig } from 'axios';

type ConfigParams = {
	reload?: boolean;
	params?: AxiosRequestConfig<any>['params'];
}

type UseAboutVacancyParams = {
	CUOCSubGrupo?: ConfigParams
}

function useAboutVacancyParams(props?: UseAboutVacancyParams) {
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);
	const [listDiasLaborales, setListDiasLaborales] = useState<ParameterConfigMmodel[]>([]);
	const [listHorarioTrabajo, setListHorarioTrabajo] = useState<ParameterConfigMmodel[]>([]);
	const [listSalario, setListSalario] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoVacante, setListTipoVacante] = useState<ParameterConfigMmodel[]>([]);
	const [listJornadaLaboral, setListJornadaLaboral] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoContrato, setListTipoContrato] = useState<ParameterConfigMmodel[]>([]);
	const [listSalarioMensual, setListSalarioMensual] = useState<ParameterConfigMmodel[]>([]);
	const [listPesosVacantes, setListPesosVacanteso] = useState<ParameterConfigMmodel[]>([]);
	const [listSwitchesVacantes, setListSwitchesVacantes] = useState<ParameterConfigMmodel[]>([]);
	const [listCUOCOcupacion, setListCUOCOcupacion] = useState<ParameterConfigMmodel[]>([]);
	const [listCUOCSubGrupo, setCUOCSubGrupo] = useState<ParameterConfigMmodel[]>([]);
	const [listPoblaciones, setListPoblaciones] = useState<ParameterConfigMmodel[]>([]);
	const [listDiscapacidades, setListDiscapacidades] = useState<ParameterConfigMmodel[]>([]);
	const [listGrupoEtnico, setListGrupoEtnico] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoCargoContacto, setListTipoCargoContacto] = useState<ParameterConfigMmodel[]>([]);

	const [loadingParams, setloadingParams] = useState(true);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchListSiNo();
		await fetchDiasLaborales();
		await fetchHorarioTrabajo();
		await fetchSalario();
		await fetchTipoVacante();
		await fetchJornadaLaboral();
		await fetchTipoContrato();
		await fetchSalarioMensual();
		await fetchPesosVacantes();
		await fetchSwitchesVacantes();
		await fetchCUOC();
		await fetchCUOCSubGrupo(props?.CUOCSubGrupo?.reload || false, props?.CUOCSubGrupo?.params);
		await fetchListPoblaciones();
		await fetchListDiscapacidades();
		await fetchListGrupoEtnico();
		await fetchListTipoCargoContacto();
		setloadingParams(false);
	};

	const fetchListTipoCargoContacto = async () => {
		const res = await parameterSingleton.fetchData(parameters.CARGOCONTACTO);
		setListTipoCargoContacto(res);
	}


	const fetchListPoblaciones = async () => {
		const res = await parameterSingleton.fetchData(parameters.POBLACIONES);
		setListPoblaciones(res);
	}

	const fetchListDiscapacidades = async () => {
		const res = await parameterSingleton.fetchData(parameters.DISCAPACIDADES);
		setListDiscapacidades(res);
	}
	const fetchCUOC = async () => {
		const res = await parameterSingleton.fetchData(parameters.CUOCGRUPOOCUPACION);
		setListCUOCOcupacion(res);
	};

	const fetchCUOCSubGrupo = async (reload = false, params?: AxiosRequestConfig<any>['params']) => {
		const res = await parameterSingleton.fetchData(parameters.CUOCSUBGRUPO, reload, params);
		setCUOCSubGrupo(res);
	};

	const fetchListGrupoEtnico = async () => {
		const res = await parameterSingleton.fetchData(parameters.GRUPOETNICO);
		const resVacante = res.filter(i => {
			return i.code != '7' //No se autorreconoce en ninguno de los anteriores				
		});
		setListGrupoEtnico(resVacante);
	}

	const fetchCUOCSubGrupoById = async (id: string | undefined) => {
		const res = await parameterSingleton.fetchDataWithParams(parameters.CUOCSUBGRUPO, parameters.CUOCGRUPOOCUPACION, id)
		setCUOCSubGrupo(res);
	};

	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};

	const fetchDiasLaborales = async () => {
		const res = await parameterSingleton.fetchData(parameters.DIASLABORALES);
		setListDiasLaborales(res);
	};

	const fetchHorarioTrabajo = async () => {
		const res = await parameterSingleton.fetchData(parameters.HORARIOTRABAJO);
		setListHorarioTrabajo(res);
	};

	const fetchSalario = async () => {
		const res = await parameterSingleton.fetchData(parameters.SALARIO);
		setListSalario(res);
	};

	const fetchSalarioMensual = async () => {
		const res = await parameterSingleton.fetchData(parameters.SALARIOMENSUAL);
		const resOrderByCode = res.sort((a, b) => (Number(a.code) > Number(b.code)) ? 1 : -1);
		setListSalarioMensual(resOrderByCode);
	}

	const fetchTipoVacante = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOVACANTE);
		setListTipoVacante(res);
	};

	const fetchJornadaLaboral = async () => {
		const res = await parameterSingleton.fetchData(parameters.JORNADALABORAL);
		setListJornadaLaboral(res);
	};

	const fetchTipoContrato = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONTRATO);
		setListTipoContrato(res);
	};

	const fetchPesosVacantes = async () => {
		const res = await parameterSingleton.fetchData(parameters.SISTEMAPESOSVACANTES, true);
		setListPesosVacanteso(res);
	};

	const fetchSwitchesVacantes = async () => {
		const res = await parameterSingleton.fetchData(parameters.SISTEMASWITCHESVACANTES, true);
		setListSwitchesVacantes(res);
	}

	return {
		listSiNo,
		listDiasLaborales,
		listHorarioTrabajo,
		// listSalario,
		listTipoVacante,
		listJornadaLaboral,
		listTipoContrato,
		listSalarioMensual,
		listPesosVacantes,
		loadingParams,
		listSwitchesVacantes,
		listCUOCOcupacion,
		listCUOCSubGrupo,
		listDiscapacidades,
		listGrupoEtnico,
		listPoblaciones,
		listTipoCargoContacto
	};
}

export default useAboutVacancyParams;
