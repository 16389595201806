import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { AuthLayout } from './AuthLayout';
import StepContext from '../../hooks/StepContext';

const AuthPage = () => (
	<Routes>
		<Route element={<AuthLayout />}>
			<Route path='login' element={<Login />} />
			<Route path='forgot-password' element={<ForgotPassword />} />
			<Route index element={<Login />} />
		</Route>
	</Routes>
);

export { AuthPage };
