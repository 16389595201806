import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Grid,
	Box,
	Button,
	Divider,
	Autocomplete,
	Select,
	MenuItem,
	InputLabel,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText,
	FormControl,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/UserRegistryContext';
import { useAuth } from '../../../../../../app/modules/auth';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	formatMaskPhoneNumber12Digits,
	initValue,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	isValidFirstSecondNameFirstSecondLastName,
	helperValidFirstSecondNameFirstSecondLastName,
	isValidEmail,
	isValidDocumentNumber,
	helperIsValidDocumentNumber,
	concatArrayToStringJoinBy
} from '../../../../../util/Index';
import {
	getCompaniesCounselor,
	getUserDataById,
	createUserPost,
	updateUserPut,
	getAllCompanies,
} from '../../../../apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useDocumentTypeParams from '../../../../../hooks/parameters/useDocumentTypeParams';
import { RoleEnum } from '../../../../../models/enums/RoleEnum';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';
import parameterSingleton from '../../../../../services/Parameters.service';
import parameters from '../../../../../util/parameters';
import { AnyObject } from 'chart.js/types/basic';
import { createFilterOptions } from '@mui/material/Autocomplete';

export interface CompanyModel {
	id: number;
	razon_social: string;
	numero_documento: string;
	status: string;
}

export const Step1 = () => {
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [open, setOpen] = useState(false);
	const [listCompanies, setListCompanies] = useState([{ id: 0, razon_social: '', status: false }]);
	const [sexoList, setSexoList] = useState<ParameterConfigMmodel[]>([]);
	const navigate = useNavigate();
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const { listTipoDocumentoCandidato } = useDocumentTypeParams();
	const { currentUser, auth, isAdminRole, isCounselorEmployerRole, isCounselorCandidateRole } = useAuth();
	const { setStep, userData, setUserData, loadedData, setLoadedData, loading, setLoading } =
		useContext(multiStepContext);
	const { id } = useParams(); /*if it has ID: Edit Mode; else: Create Mode*/
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState<CompanyModel | null>(null);
	const location = useLocation();
	const [documentTypeField, setDocumentTypeField] = useState('text');
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);

	const ALLOWED_DOMAINS_USERS_COUNSELOR = process.env.REACT_APP_ALLOWED_DOMAINS_USERS_COUNSELOR || '';

	const setDocumentParams = (type: string) => {
		switch (type) {
			case 'CC':
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				setDocumentTypeField('number');
				break;
			case 'TI':
				setMaxDocumentFieldLenght(11);
				setMinDocumentFieldLenght(10);
				setDocumentTypeField('number');
				break;
			case 'CE':
				setMaxDocumentFieldLenght(7);
				setMinDocumentFieldLenght(1);
				setDocumentTypeField('number');
				break;
			case 'PA':
				setMaxDocumentFieldLenght(17);
				setMinDocumentFieldLenght(1);
				setDocumentTypeField('text');
				break;
			case 'PEP':
				setMaxDocumentFieldLenght(20);
				setMinDocumentFieldLenght(15);
				setDocumentTypeField('number');
				break;
			case 'PT':
				setMaxDocumentFieldLenght(7);
				setMinDocumentFieldLenght(4);
				setDocumentTypeField('number');
				break;
			default:
				setMaxDocumentFieldLenght(3);
				setMinDocumentFieldLenght(11);
				setDocumentTypeField('number');
				break;
		}

	};
	const fetchGender = async () => {
		const res = await parameterSingleton.fetchData(parameters.SEXO);
		setSexoList(res);
	}
	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};
	useEffect(() => {
		fetchGender();
		fetchListSiNo();
		if (isAdminOrCounselor()) {
			getListCompanies();
		}
		if (id !== undefined && parseInt(id) > 0 && (!loadedData as boolean)) {
			getUser();
		}
		if (currentUser?.user?.empresa_id) {
			setUserData({ ...userData, empresa_id: currentUser?.user?.empresa_id });
		}
	}, []);

	useEffect(() => {
		findAndSetSelectedCompany();
	}, [userData?.empresa_id, companies?.length > 0]);

	const getAllCompaniesAPI = async () => {
		try {
			const response = await getAllCompanies();
			setListCompanies(response.data);
			return response.data;
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	};
	const findAndSetSelectedCompany = () => {
		if (userData && userData?.empresa_id && null != userData.empresa_id) {
			const addedCompany: CompanyModel | undefined = companies.find(c => (c['id'] as number) == userData.empresa_id);
			if (addedCompany) {
				setSelectedCompany(addedCompany);
				formik.initialValues.empresa_id = initValue(addedCompany['id']);
			}
		}
	}
	const handleClose = () => {
		setOpen(false);
	};
	const getUser = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			try {
				setLoading(true);
				const response = await getUserDataById(`id=${id}`);
				setUserData(response.data);
				setDocumentParams(response?.data?.UserComplemento?.tipo_doc)
				setLoadedData(true);
			} catch (error: any) {
				if (error.response) {
					console.error('error', error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	};

	/**
	 * Obtiene lista de compañias para asociar al flujo de registro de la vacante
	 */
	const getListCompanies = async () => {
		try {
			const response = await getCompaniesCounselor();
			setCompanies(response.data);
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	};

	class validationForm {
		documentType: string = initValue(userData?.UserComplemento?.tipo_doc);
		documentNumber: string = initValue(userData?.UserComplemento?.numero_doc);
		roleType: string = initValue(userData?.role);
		active: string = initValue(userData?.active);
		empresa_id?: string;
		firstName: string = initValue(userData?.UserComplemento?.primer_nome);
		secondName: string = initValue(userData?.UserComplemento?.segundo_nome);
		firstNickName: string = initValue(userData?.UserComplemento?.primer_apelido);
		secondNickName: string = initValue(userData?.UserComplemento?.segundo_apelido);
		gender: string = initValue(userData?.UserComplemento?.genero);
		telefono_contacto: string = formatMaskPhoneNumber12Digits(initValue(userData?.UserComplemento?.celular));
		Correo_eletrenico_contacto: string = initValue(userData?.email);
		habilitarAgenda?: string;
		constructor() {
			//Roles: Administrador y Consejero
			if (userData.role === 4) {
				this.empresa_id = initValue(userData?.empresa_id);
			}
			if (userData.role === RoleEnum.COUNSELOR_CADIDATE) {
				this.habilitarAgenda = initValue(userData?.habilitarAgenda);
			}
		}
	}

	const formSchema = useMemo(() => {
		const documentValidation = (schema: Yup.StringSchema<string | undefined, AnyObject, string | undefined>, type: string) => {
			switch (type) {
				case 'CC':
					return Yup.string()
						.required('Campo requerido')
						.test('cedula', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'number', documentType, 3, 11, true);
							else
								return false;
						})
				case 'TI':
					return Yup.string()
						.required('Campo requerido')
						.test('tarjeta', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'number', documentType, 10, 11, true);
							else
								return false;
						})
				case 'CE':
					return Yup.string()
						.required('Campo requerido')
						.test('cedulaE', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'number', documentType, 1, 7, true);
							else
								return false;
						})
				case 'PA':
					return Yup.string()
						.required('Campo requerido')
						.test('pa', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'text', documentType, 1, 17, true);
							else
								return false;
						})
				case 'PEP':
					return Yup.string()
						.required('Campo requerido')
						.test('PEP', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'number', documentType, 15, 20, true);
							else
								return false;
						})
				case 'PT':
					return Yup.string()
						.required('Campo requerido')
						.test('PT', 'Número de documento no valido', function (value: any | undefined, documentType: any | undefined) {
							if (value && documentType)
								return isValidDocumentNumber(value, 'number', documentType, 4, 7, true);
							else
								return false;
						})
				default:
					return Yup.string().required('Campo requerido');
			}
		};

		const documentNumber = Yup.string()
			.required('Campo requerido')
			.test('validDocument', 'El número de documento no es válido', function (value: any | undefined, documentType: any | undefined) {
				if (value && documentType)
					return isValidDocumentNumber(value, "text", documentType);
				else
					return false;
			})

		return Yup.object().shape({
			empresa_id: Yup.string().when('roleType', {
				is: (roleType: any) => Number(roleType) === RoleEnum.EMPLOYER,
				then: Yup.string().required("Campo requerido")
			}),
			habilitarAgenda: Yup.string().when('roleType', {
				is: (roleType: any) => Number(roleType) === RoleEnum.COUNSELOR_CADIDATE,
				then: Yup.string().required("Campo requerido")
			}),
			documentType: Yup.string().required('Campo requerido'),
			// documentNumber : documentNumber,
			documentNumber: Yup.string().when('documentType', {
				is: (type: string | undefined) => !!type,
				then: (schema: Yup.StringSchema<string | undefined, AnyObject, string | undefined>) => documentValidation(schema, userData?.UserComplemento?.tipo_doc),
			}),
			//   .required('Campo requerido')
			//   .matches(/^[0-9]+$/, 'Deben ser solo números'),
			firstName: Yup.string()
				.test('rule1', 'Campo no válido', function (value) {
					return isValidFirstSecondNameFirstSecondLastName(value || '', true)
				})
				.required('Campo requerido'),
			secondName: Yup.string()
				.test('rule1', 'Campo no válido', function (value) {
					return isValidFirstSecondNameFirstSecondLastName(value || '', false)
				}),
			firstNickName: Yup.string()
				.test('rule1', 'Campo no válido', function (value) {
					return isValidFirstSecondNameFirstSecondLastName(value || '', true)
				})
				.required('Campo requerido'),
			secondNickName: Yup.string()
				.test('rule1', 'Campo no válido', function (value) {
					return isValidFirstSecondNameFirstSecondLastName(value || '', false)
				}),
			telefono_contacto: Yup.string()
				.test('isValidColombianPhoneNumber', (val) => helperIsValidColombianPhoneNumber(val, true), (val) => { return isValidColombianPhoneNumber(val, true) })
				.required('Campo requerido'),
			Correo_eletrenico_contacto: Yup.string()
				.required('Campo requerido')
				.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
					return isValidEmail(value || '')
				})
				.test(
					'domain',
					`El correo electrónico debe pertenecer al/los dominio(s): ${concatArrayToStringJoinBy(ALLOWED_DOMAINS_USERS_COUNSELOR.split(','), ' ó ')}`,
					(value) => {
						if (value && (userData?.role === RoleEnum.COUNSELOR_CADIDATE || userData?.role === RoleEnum.COUNSELOR_EMPLOYER)) {
							const allowedDomains = ALLOWED_DOMAINS_USERS_COUNSELOR.split(',');
							return allowedDomains.some((domain) => value?.trim().endsWith(domain?.trim()));
						}
						return true;
					}
				)
				.max(80, 'Máximo 80 caracteres'),
			roleType: Yup.string().required('Campo requerido'),
			gender: Yup.string().required('Campo requerido'),
			active: Yup.string().required('Campo requerido')
		});
	}, [userData]);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true);
			} catch (error) {
				setSubmitting(false);
				setStatus('Información incorrecta');
			}
		},
	});

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */
	const isAdminOrCounselor = () => {
		return isAdminRole() || isCounselorEmployerRole();
	}

	const ddlTpUserRole = (): any[] => {
		const prev = location?.state as string || '';
		if (isCounselorCandidateRole() || prev.includes('gestion-candidatos')) {
			return [{ id: RoleEnum.CANDIDATE, name: 'Candidato' }]
		} else if (isAdminRole()) {
			return [
				{ id: RoleEnum.ADMIN, name: 'Administrador' },
				{ id: RoleEnum.CANDIDATE, name: 'Candidato' },
				{ id: RoleEnum.COUNSELOR_CADIDATE, name: 'Consejero Candidato' },
				{ id: RoleEnum.COUNSELOR_EMPLOYER, name: 'Consejero Empleador' },
				{ id: RoleEnum.EMPLOYER, name: 'Empleador' },
			]
		} else {
			return []
		}
	}
	async function handleSubmit(event: FormEvent) {
		setLoading(true);
		let objFinal: {
			id: string | undefined;
			correo: any;
			tipo_doc: any;
			numero_doc: any;
			role: any;
			primer_nome: any;
			segundo_nome: any;
			primer_apelido: any;
			segundo_apelido: any;
			celular: any;
			genero: any;
			email: any;
			active: any;
			empresa_id?: any;
			habilitarAgenda?: any;
		};

		if (typeof userData.empresa_id === 'number' && !isNaN(userData.empresa_id)) {
			objFinal = {
				id: id,
				correo: userData.email,
				tipo_doc: userData.UserComplemento.tipo_doc,
				numero_doc: userData?.UserComplemento?.numero_doc,
				role: userData.role,
				primer_nome: userData.UserComplemento?.primer_nome,
				segundo_nome: userData.UserComplemento?.segundo_nome,
				primer_apelido: userData.UserComplemento?.primer_apelido,
				segundo_apelido: userData.UserComplemento?.segundo_apelido,
				celular: userData.UserComplemento?.celular?.replaceAll('-', '').replaceAll('+', '').trim(),
				genero: userData.UserComplemento?.genero,
				email: userData.email,
				active: userData.active,
				empresa_id: userData.empresa_id,
			};
		} else {
			objFinal = {
				id: id,
				correo: userData.email,
				tipo_doc: userData.UserComplemento.tipo_doc,
				numero_doc: userData?.UserComplemento?.numero_doc,
				role: userData.role,
				primer_nome: userData.UserComplemento?.primer_nome,
				segundo_nome: userData.UserComplemento?.segundo_nome,
				primer_apelido: userData.UserComplemento?.primer_apelido,
				segundo_apelido: userData.UserComplemento?.segundo_apelido,
				celular: userData.UserComplemento?.celular?.replaceAll('-', '').replaceAll('+', '').trim(),
				genero: userData.UserComplemento?.genero,
				email: userData.email,
				active: userData.active,
			};
		}

		if (userData.role === RoleEnum.COUNSELOR_CADIDATE) {
			objFinal['habilitarAgenda'] = userData.habilitarAgenda;
		}

		try {
			event.preventDefault();
			setRequestInProgress(true);

			if (id !== undefined && parseInt(id) > 0) {
				const response = await updateUserPut(objFinal);
				setLoading(false);
				setOpen(true);
				setAlert(true);
				setAlertMsg('¡Registro actualizado con éxito!');
				setTimeout(() => {
					setOpen(false);
					setRequestInProgress(false);
					navigate(-1);
				}, 2000);
			} else {
				const response = await createUserPost(objFinal);
				setOpen(true);
				setAlert(true);
				setAlertMsg('¡Registro completado con éxito!');
				setTimeout(() => {
					setOpen(false);
					setRequestInProgress(false);
					navigate(-1);
				}, 2000);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrió un error procesando la solicitud.');
			}
			setRequestInProgress(false);
		} finally {
			setLoading(false);
		}
	}

	/**
	 * Opcion de filtrado para campo de empresa
	 */
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		stringify: (option: CompanyModel) => option.numero_documento + option.razon_social,
	});

	return (
		<>
			{loading ? (
				null
			) : (loading ? <LinearLoadingSpinner isLoading={loading} /> :
				<>
					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={formik.touched.documentType && Boolean(formik.errors.documentType)}
							>
								<InputLabel required id='documentType'>
									Tipo de documento
								</InputLabel>
								<Select
									fullWidth
									{...formik.getFieldProps('documentType')}
									error={formik.touched.documentType && Boolean(formik.errors.documentType)}
									required
									id='documentType'
									name='documentType'
									label='Tipo de documento'
									value={initValue(userData?.UserComplemento?.tipo_doc)}
									onChange={(e) => {
										setDocumentParams(e.target.value);
										setUserData({
											...userData,
											UserComplemento: {
												...userData?.UserComplemento,
												tipo_doc: e.target.value,
											},
										});
										formik.handleChange(e);
									}}
									size='medium'
								>
									<MenuItem value='' key={''} disabled>
										Seleccione...
									</MenuItem>
									{listTipoDocumentoCandidato.map(({ code, value }) => (
										<MenuItem value={code} key={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error>
									{formik.touched.documentType ? formik.errors.documentType : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('documentNumber')}
								error={(formik.touched.documentNumber || formik.touched.documentType) && !isValidDocumentNumber(userData?.UserComplemento?.numero_doc, documentTypeField, userData?.UserComplemento?.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true)}
								helperText={(formik.touched.documentNumber || formik.touched.documentType) && !isValidDocumentNumber(userData?.UserComplemento?.numero_doc, documentTypeField, userData?.UserComplemento?.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true) ? helperIsValidDocumentNumber(userData?.UserComplemento?.numero_doc, documentTypeField, userData?.UserComplemento?.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true) : ''}
								label='Numero de Documento'
								placeholder='Ingrese el numero de documento'
								type='text'
								value={initValue(userData?.UserComplemento?.numero_doc)}
								onChange={(e) => {
									setUserData({
										...userData,
										UserComplemento: {
											...userData?.UserComplemento,
											numero_doc: e.target.value,
										},
									});
									formik.handleChange(e);
								}}
								inputProps={{
									maxLength: maxDocumentFieldLenght,
									minLength: minDocumentFieldLenght,
								}}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={formik.touched.roleType && Boolean(formik.errors.roleType)}
							>
								<InputLabel required id='roleType'>
									Tipo de usuario
								</InputLabel>
								<Select
									fullWidth
									{...formik.getFieldProps('roleType')}
									error={formik.touched.roleType && Boolean(formik.errors.roleType)}
									required
									id='roleType'
									name='roleType'
									label='Tipo de usuario(Rol)'
									value={initValue(userData?.role)}
									onChange={(e) => {
										setUserData({ ...userData, role: e.target.value });
										formik.handleChange(e);
									}}
									size='medium'
								>
									<MenuItem value='' key={''} disabled>
										Seleccione...
									</MenuItem>
									{ddlTpUserRole().map(({ id, name }, i) => (
										<MenuItem value={id} key={i}>
											{name}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error>
									{formik.touched.roleType ? formik.errors.roleType : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={formik.touched.active && Boolean(formik.errors.active)}
							>
								<InputLabel required id='active'>
									Estado
								</InputLabel>
								<Select
									fullWidth
									{...formik.getFieldProps('active')}
									error={formik.touched.active && Boolean(formik.errors.active)}
									required
									id='active'
									name='active'
									label='Estado'
									value={initValue(userData?.active)}
									// defaulValue='false'
									onChange={(e) => {
										setUserData({ ...userData, active: e.target.value });
										formik.handleChange(e);
									}}
									size='medium'
								>
									<MenuItem value='' key={''} disabled>
										Seleccione...
									</MenuItem>
									<MenuItem value='true'>
										Activo
									</MenuItem>
									<MenuItem value='false'>
										Inactivo
									</MenuItem>
								</Select>
								<FormHelperText error>
									{formik.touched.active ? formik.errors.active : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						{userData?.role === 4 && (
							<Grid xs={12} md={6} mb={5} pr={2} item={true}>
								<Autocomplete
									disabled={loading}
									sx={{ minWidth: '100%' }}
									size='medium'
									id="empresa_id"
									getOptionLabel={(opt: CompanyModel) => initValue(opt.razon_social)}
									isOptionEqualToValue={(opt, value) => (opt && value) && opt.id === value.id}
									options={companies}
									value={selectedCompany}
									filterOptions={filterOptions}
									onChange={(e: any, newValue: CompanyModel | null) => {
										setSelectedCompany(newValue);
										setUserData({ ...userData, empresa_id: newValue?.id });
										formik.setFieldValue('empresa_id', newValue?.id);
										formik.handleChange(e);
									}}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.razon_social}
											</li>
										);
									}}
									renderInput={(params) => (

										<TextField {...params}
											label="Empresa"
											id="empresa_id"
											{...formik.getFieldProps('empresa_id')}
											error={formik.touched.empresa_id && formik.touched.empresa_id && Boolean(formik.errors.empresa_id)}
											// error={(!selectedCompany) && (!userData.empresa_id)}
											helperText={formik.touched.empresa_id ? formik.errors.empresa_id : ''}
											required
										/>

									)}
								/>
							</Grid>
						)}
					</Grid>
					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('firstName')}
								error={formik.touched.firstName && !!helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.primer_nome, true)}
								helperText={formik.touched.firstName ? helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.primer_nome, true) : ''}
								label='Primer Nombre'
								placeholder='Ingresa el primer nombre'
								type='text'
								value={initValue(userData?.UserComplemento?.primer_nome)}
								onChange={(e) => {
									setUserData({
										...userData,
										UserComplemento: {
											...userData?.UserComplemento,
											primer_nome: e.target.value,
										},
									});
									formik.handleChange(e);
								}}
								inputProps={{ maxLength: 40 }}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('secondName')}
								error={formik.touched.secondName && !!helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.segundo_nome, false)}
								helperText={formik.touched.secondName ? helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.segundo_nome, false) : ''}
								label='Segundo Nombre'
								placeholder='Ingresa el segundo nombre'
								type='text'
								value={initValue(userData?.UserComplemento?.segundo_nome)}
								onChange={(e) => {
									setUserData({
										...userData,
										UserComplemento: {
											...userData?.UserComplemento,
											segundo_nome: e.target.value,
										},
									});
									formik.handleChange(e);
								}}
								inputProps={{ maxLength: 40 }}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('firstNickName')}
								error={formik.touched.firstNickName && !!helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.primer_apelido, true)}
								helperText={formik.touched.firstNickName ? helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.primer_apelido, true) : ''}
								label='Primer Apellido'
								placeholder='Ingresa el primer apellido'
								type='text'
								value={initValue(userData?.UserComplemento?.primer_apelido)}
								onChange={(e) => {
									setUserData({
										...userData,
										UserComplemento: {
											...userData?.UserComplemento,
											primer_apelido: e.target.value,
										},
									});
									formik.handleChange(e);
								}}
								inputProps={{ maxLength: 40 }}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('secondNickName')}
								error={formik.touched.secondNickName && !!helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.segundo_apelido, false)}
								helperText={formik.touched.secondNickName ? helperValidFirstSecondNameFirstSecondLastName(userData?.UserComplemento?.segundo_apelido, false) : ''}
								label='Segundo Apellido'
								placeholder='Ingresa el segundo apellido'
								type='text'
								value={initValue(userData?.UserComplemento?.segundo_apelido)}
								onChange={(e) => {
									setUserData({
										...userData,
										UserComplemento: {
											...userData?.UserComplemento,
											segundo_apelido: e.target.value,
										},
									});
									formik.handleChange(e);
								}}
								inputProps={{ maxLength: 40 }}
								size='medium'
							/>
						</Grid>
					</Grid>
					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								inputProps={{ autoComplete: 'new-password', maxLength: 10 }}
								{...formik.getFieldProps('telefono_contacto')}
								error={formik.touched.telefono_contacto && !isValidColombianPhoneNumber(formik.getFieldProps('telefono_contacto').value, true)}
								helperText={formik.touched.telefono_contacto ? helperIsValidColombianPhoneNumber(formik.getFieldProps('telefono_contacto').value, true) : ''}
								label='Teléfono de contacto'
								placeholder='Ingresa un teléfono'
								value={formatMaskPhoneNumber12Digits(initValue(userData?.UserComplemento?.celular))}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
										?.replaceAll('-', '')
										?.replaceAll('+', '');
									let reg = /[0-9]|\./;
									if (reg.test(val) || val === '') {
										setUserData({
											...userData,
											UserComplemento: {
												...userData?.UserComplemento,
												celular: formatMaskPhoneNumber12Digits(val),
											},
										});
										formik.handleChange(e);
									}
								}}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={formik.touched.gender && Boolean(formik.errors.gender)}
							>
								<InputLabel required id='gender'>
									Sexo asignado al nacer
								</InputLabel>
								<Select
									fullWidth
									{...formik.getFieldProps('gender')}
									error={formik.touched.gender && Boolean(formik.errors.gender)}
									required
									id='gender'
									name='gender'
									label='Sexo asignado al nacer'
									value={initValue(userData?.UserComplemento?.genero)}
									defaultValue=''
									onChange={(e) => {
										setUserData({
											...userData,
											UserComplemento: {
												...userData?.UserComplemento,
												genero: e.target.value,
											},
										});
										formik.handleChange(e);
									}}
									size='medium'
								>
									<MenuItem value='' key={''} disabled>
										Seleccione...
									</MenuItem>
									{sexoList.map(({ code, value }) => (
										<MenuItem value={code} key={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error>
									{formik.touched.gender ? formik.errors.gender : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 6 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('Correo_eletrenico_contacto')}
								error={
									formik.touched.Correo_eletrenico_contacto &&
									Boolean(formik.errors.Correo_eletrenico_contacto)
								}
								helperText={
									formik.touched.Correo_eletrenico_contacto
										? formik.errors.Correo_eletrenico_contacto
										: ''
								}
								label='Correo electrónico de contacto'
								placeholder='Ingresa un correo electrónico'
								value={initValue(userData?.email)}
								onChange={(e) => {
									setUserData({ ...userData, email: e.target.value });
									formik.handleChange(e);
								}}
								inputProps={{ type: 'email', maxLength: 80 }}
								size='medium'
							/>
						</Grid>
					</Grid>
					{userData?.role === RoleEnum.COUNSELOR_CADIDATE && (
						<Grid container direction='row' justifyContent='space-between' width={'100%'}>
							<Grid xs={12} md={isAdminOrCounselor() ? 3 : 6} mb={5} pr={2} item={true}>
								<FormControl sx={{ minWidth: '100%' }}
									size='medium'
									error={formik.touched.habilitarAgenda && Boolean(formik.errors.habilitarAgenda)}>
									<InputLabel required id='habilitarAgenda'>Habilitar agenda</InputLabel>
									<Select
										{...formik.getFieldProps('habilitarAgenda')}
										error={formik.touched.habilitarAgenda && Boolean(formik.errors.habilitarAgenda)}
										required
										labelId='habilitarAgenda'
										id='habilitarAgenda'
										label='Habilitar agenda'
										value={initValue(userData?.habilitarAgenda)}
										onChange={(e) => {
											setUserData({ ...userData, habilitarAgenda: e.target.value });
											formik.handleChange(e);
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listSiNo.map(({ code, value }) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									<FormHelperText error>
										{formik.touched.habilitarAgenda ? formik.errors.habilitarAgenda : ''}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					)}
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Link to='/auth'>
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								className='btn btn-secondary'>
								Cancelar
							</Button>
						</Link>
						{/*<Box sx={{ flex: '1 1 auto' }} />*/}
						{formik.isValid ? (
							<Link to='/auth'>
								<Button
									variant='contained'
									sx={{ mr: 1, width: 90, height: 40 }}
									onClick={(e) => {
										e.preventDefault();
										if (formik.isValid) {
											handleSubmit(e);
										}
									}}
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{'Finalizar'}
								</Button>
							</Link>
						) : (
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={(e) => {
									e.preventDefault();
									if (formik.isValid) {
										handleSubmit(e);
									}
								}}
								disabled={formik.isSubmitting || !formik.isValid}
							>
								{'Finalizar'}
							</Button>
						)}
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
							{alertMsg}
						</Alert>
					</Snackbar>
				</>
			)}
		</>
	);
};


