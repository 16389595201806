import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { Link } from 'react-router-dom';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Alert, Snackbar, AlertTitle, Autocomplete, TextField, InputLabel } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import {
	getListarAcompanamientos,
	getCerrarAcompanamiento,
	deleteAcompanamiento,
	getListaDireccionamientos,
	deleteDireccionamiento,
	getParametos,
	finalizarDireccionamiento
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper, TablePagination,
	Stack,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import DatePicker from "react-multi-date-picker";
import dayjs from 'dayjs';



interface StatusCompanyProps {
	id: number;
	status?: string;
	observaciones?: string;
}

const ListAddressOrientation = () => {
	const { isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [selectedDates, setSelectedDates] = useState<any>([]);
	const colombia_es_lowercase: any = {
		name: "gregorian_es_lowercase",
		months: [
			["enero", "ene"],
			["febrero", "feb"],
			["marzo", "mar"],
			["abril", "abr"],
			["mayo", "may"],
			["junio", "jun"],
			["julio", "jul"],
			["agosto", "ago"],
			["septiembre", "sep"],
			["octubre", "oct"],
			["noviembre", "nov"],
			["diciembre", "dic"],
		],
		weekDays: [
			["sábado", "sáb"],
			["domingo", "dom"],
			["lunes", "lun"],
			["martes", "mar"],
			["miércoles", "mié"],
			["jueves", "jue"],
			["viernes", "vie"],
		],
		digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		meridiems: [
			["AM", "am"],
			["PM", "pm"],
		],
	};
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [idConvocatoria, setIdConvocatoria] = useState();
	const [idAcompanimento, setIdAcompanamiento] = useState();
	const [idDireccionamiento, setIdDireccionamiento] = useState();
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [openModal, setOpenModal] = useState(false);
	const [openModalCloseAcompaniment, setOpenModalCloseAcompaniment] = useState(false);
	const [openModalDeleteAcompaniment, setOpenModalDeleteAcompaniment] = useState(false);



	const [tipoDeDireccionamiento, setTipoDeDireccionamiento] = useState<any[]>([]);
	const [subTipoDeDireccionamiento, setSubTipoDeDireccionamiento] = useState<any[]>([]);
	const [estado, setEstado] = useState<any>('');



	// Get de los parametros de  Direccionamientos
	const handleGetParametros = async () => {
		setLoading(true);

		try {
			// Primer llamado con "TIPO_DIRECCIONAMIENTO"
			const resTipo: any = await getParametos("TIPO_DIRECCIONAMIENTO"); // Usa `any`
			setTipoDeDireccionamiento(resTipo.data)

			// Segundo llamado con "SUBTIPO_DIRECCIONAMIENTO"
			const resSubtipo: any = await getParametos("SUBTIPO_DIRECCIONAMIENTO"); // Usa `any`
			setSubTipoDeDireccionamiento(resSubtipo.data); // Guarda solo el array de `data`



		} catch (error: any) {
			console.error("Error obteniendo parámetros:", error.response?.data?.error || error.message);
		} finally {
			setLoading(false);
		}
	};



	useEffect(() => {
		handleGetParametros()
	}, [])


	const [textoBuscar, setTextoBuscar] = useState('');

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: '',

		search: userData?.search,
		fecha_inicio: userData?.fecha_inicio,
		fecha_fin: userData?.fecha_fin,
		tipo_direccionamiento: userData?.tipo_direccionamiento,
		subtipo_direccionamiento: userData?.subtipo_direccionamiento,
		id_consejero_candidato: '',

	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleGetListaDireccionamientos(userData);

	}, [filter]);



	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, estado: userData?.estado, tipo_direccionamiento: userData.tipo_direccionamiento, };
		setPage(newPage);

		setFilter(f);

	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Get de los Direccionamientos
	const handleGetListaDireccionamientos = async (data: any) => {


		setLoading(true);
		const params = {
			pages: filter?.page,
			limit: filter?.limit,
			estado: userData?.estado,
			search: userData?.search,
			tipo_direccionamiento: userData?.tipo_direccionamiento,
			subtipo_direccionamiento: userData?.subtipo_direccionamiento,
			// id_consejero_candidato
			fecha_inicio: userData?.fecha_inicio,
			fecha_fin: userData?.fecha_fin,
		}
		try {
			const response: any = await getListaDireccionamientos(params);
			if (response && response.data) {
				setTotal(response.data.total);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};



	// Eliminar un direccionamiento
	const handleDeleteDireccionamiento = async (id: any) => {
		try {
			await deleteDireccionamiento(id);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Direccionamiento eliminado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 1500);
			setOpenModalDeleteAcompaniment(false)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}

	}

	// Cerrar un acompanamiento
	const handleCerrarUnAcompanamiento = async (id: any) => {
		const close = { estado: "Cerrado", }
		try {
			await getCerrarAcompanamiento(id, close);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Acompañamiento cerrado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
			setOpenModalCloseAcompaniment(false)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};

	const handlFinalizarDireccionamiento = async (direccionamientoId: any) => {
        try {
            await finalizarDireccionamiento(direccionamientoId);
            setOpen(true);
            setAlert(true);
            handleCloseModal()
            setAlertMsg(`Finalizacido con éxito!`);
			setFilter({ ...filter });
            setTimeout(() => {
                setOpen(false);
            }, 1500);


        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        }

    }

	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const { data } = props;


		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};



		const handleEditarDireccionamiento = (id: any, visible: any) => {
			return `/consejero/direccionamiento-orientacion-gestion/${id}`;
		};

		const handleVerDireccionamiento = (id: any, visible: any) => {
			return `/consejero/direccionamiento-orientacion-gestion/${id}/${visible}`;
		};

		const handleVerOrientados = (id: any, subtipo: any, ver: any) => {
			return `/consejero/gestionar-orientados/${id}/${subtipo}/${ver}`;
		};

		const handleRegistrarOrientados = (id: any, subtipo: any) => {
			return `/consejero/gestionar-orientados/${id}/${subtipo}`;
		};
		const handleValoresDeRiesgosDeDesempleo = (id: any) => {
			return `/consejero/valores-de-riesgo-de-desempleo/${id}`;
		};

		// const handleRegistroYPonderacionDeVariables = (id: any) => {
		// 	return `/consejero/registro-y-ponderacion-de-variables/${id}`;
		// };




		return (
			<>
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>

				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{
						[
							// individual

							<div key={`${data.status}${data.subtipo} Creado`}>
								{
									data.status === 'Creado' && data.subtipo === 'Individual' ?
										<div>
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento`}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											<MenuItem onClick={handleClose} key={`edit${data.id} Editar Direccionamiento`}>
												<Link
													to={handleEditarDireccionamiento(data.id, true)}
												>

													<EditIcon />
													&nbsp; Editar Direccionamiento
												</Link>
											</MenuItem>

											<MenuItem onClick={handleClose} key={`delete${data.id} Eliminar Direccionamiento`}>
												<span
													onClick={() => { handleOpenModalAcompanimentDelete(data.id) }}
												>
													<DeleteIcon />
													&nbsp; Eliminar Direccionamiento
												</span>
											</MenuItem>

											<MenuItem onClick={handleClose} key={`create${data.id} Registrar Orientados`}>
												<Link

													to={handleRegistrarOrientados(data.id, data.subtipo)}
												>
													<AppRegistrationIcon />

													&nbsp; Registrar Orientados
												</Link>
											</MenuItem>

										</div>
										:
										<div>

										</div>
								}
							</div>,

							<div key={`${data.status}${data.subtipo} Registrados`}>
								{
									data.status === 'Registrados' && data.subtipo === 'Individual' ?
										<div>
											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento`}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											{/* Ver Orientados */}
											<MenuItem onClick={handleClose} key={`showDir${data.id} Ver Orientados`}>
												<Link
												to={handleVerOrientados(data.id, data.subtipo,true)}
												>
													<VisibilityIcon />
													&nbsp; Ver Orientados
												</Link>
											</MenuItem>

											{/* Registrar Orientados */}
											<MenuItem onClick={handleClose} key={`create${data.id} Registrar Orientados`}>
												<Link

													to={handleRegistrarOrientados(data.id, data.subtipo)}
												>
													<AppRegistrationIcon />

													&nbsp; Registrar Orientados
												</Link>
											</MenuItem>

											{/* Valoración de riesgo de desempleo (VRD) */}
											<MenuItem onClick={handleClose} key={`scores${data.id} Valoración de riesgo de desempleo (VRD)`}>
												<Link
													to={handleValoresDeRiesgosDeDesempleo(data.id)}
												>
													<AlignVerticalBottomIcon />

													&nbsp; Valoración de riesgo de desempleo (VRD)
												</Link>
											</MenuItem>

											{/* Finalizar Direccionamiento */}
											<MenuItem onClick={handleClose} key={`finish${data.id} Finalizar Direccionamiento`}>
												<span
												onClick={() => {handlFinalizarDireccionamiento(data.id)}}
													// to={'#'}
												// to={handleEditarConvocatoria(data.id, true)}
												>
													<CheckCircleIcon />

													&nbsp; Finalizar Direccionamiento
												</span>
											</MenuItem>
										</div>
										:
										<div>

										</div>
								}
							</div>,

							<div key={`${data.status}${data.subtipo} Finalizado`}>
								{
									data.status === 'Finalizado' && data.subtipo === 'Individual' ?
										<>
											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento`}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											{/* Ver Orientados */}
											<MenuItem onClick={handleClose} key={`showDir${data.id}  Ver Orientados`}>
												<Link
												to={handleVerOrientados(data.id, data.subtipo, true)}
												>
													<VisibilityIcon />
													&nbsp; Ver Orientados
												</Link>
											</MenuItem>


										</>
										:
										<>

										</>
								}
							</div>,

							// Grupal

							<div key={`${data.status}${data.subtipo} Creado`}>
								{
									data.status === 'Creado' && data.subtipo === 'Grupal' ?
										<div>
											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento`}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											{/* Editar Direccionamiento */}
											<MenuItem onClick={handleClose} key={`edit${data.id} Editar Direccionamiento`}>
												<Link
													to={handleEditarDireccionamiento(data.id, true)}
												>

													<EditIcon />
													&nbsp; Editar Direccionamiento
												</Link>
											</MenuItem>

											{/* Eliminar Direccionamiento */}
											<MenuItem onClick={handleClose} key={`delete${data.id} Eliminar Direccionamiento`}>
												<span
													onClick={() => { handleOpenModalAcompanimentDelete(data.id) }}
												>
													<DeleteIcon />
													&nbsp; Eliminar Direccionamiento
												</span>
											</MenuItem>

											{/* Registrar Orientados */}
											<MenuItem onClick={handleClose} key={`create${data.id} Registrar Orientados`}>
												<Link

													to={handleRegistrarOrientados(data.id, data.subtipo)}
												>
													<AppRegistrationIcon />

													&nbsp; Registrar Orientados
												</Link>
											</MenuItem>

										</div>
										:
										<div>

										</div>
								}
							</div>,

							<div key={`${data.status}${data.subtipo} Registrados`}>
								{
									data.status === 'Registrados' && data.subtipo === 'Grupal' ?
										<div>
											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento `}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											{/* Ver Orientados */}
											<MenuItem onClick={handleClose} key={`showDir${data.id} Ver Orientados`}>
												<Link
													
												to={handleVerOrientados(data.id, data.subtipo, true)}
												>
													<VisibilityIcon />
													&nbsp; Ver Orientados
												</Link>
											</MenuItem>

											{/* Registrar Orientados */}
											<MenuItem onClick={handleClose} key={`create${data.id} Registrar Orientados`}>
												<Link

													to={handleRegistrarOrientados(data.id, data.subtipo)}
												>
													<AppRegistrationIcon />

													&nbsp; Registrar Orientados
												</Link>
											</MenuItem>


										</div>
										:
										<div>

										</div>
								}
							</div>,

							<div key={`${data.status}${data.subtipo} Finalizado`}>
								{
									data.status === 'Finalizado' && data.subtipo === 'Grupal' ?
										<div>
											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`show${data.id} Ver Direccionamiento`}>
												<Link
													to={handleVerDireccionamiento(data.id, true)}
												>
													<VisibilityIcon />

													&nbsp; Ver Direccionamiento
												</Link>
											</MenuItem>

											{/* Ver Direccionamiento */}
											<MenuItem onClick={handleClose} key={`showDir${data.id} Ver Direccionamiento`}>
												<Link
												to={handleVerOrientados(data.id, data.subtipo, true)}
												>
													<VisibilityIcon />
													&nbsp; Ver Orientados
												</Link>
											</MenuItem>


										</div>
										:
										<div>

										</div>
								}
							</div>,
						]
					}

				</Menu >
			</>
		)
	}



	const handleCloseModalAcompaniment = () => {
		setOpenModalCloseAcompaniment(false);
	};


	const handleOpenModalAcompanimentDelete = (id: any) => {
		setOpenModalDeleteAcompaniment(true);
		setIdDireccionamiento(id)
	};
	const handleCloseModalAcompanimentDelete = () => {
		setOpenModalDeleteAcompaniment(false);
	};



	const handleCloseModal = () => {
		setOpenModal(false);
	};

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */


	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */


	const handleCrearAcompañamiento = () => {
		return `/consejero/direccionamiento-orientacion-gestion`;
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */


	const filtraTextoBuscar = () => {
		handleChangePage(0);
	}



	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, search: event.target.value });
	}

	const handleChageTipo = (id: any) => {
		setUserData({ ...userData, tipo_direccionamiento: id });
		setTimeout(() => {
			filtraTextoBuscar()
		}, 150);

	}
	const handleChageSubtipo = (id: any) => {
		setUserData({ ...userData, subtipo_direccionamiento: id });
		setTimeout(() => {
			filtraTextoBuscar()
		}, 150);

	}

	const handleEstado = (event: any) => {
		setEstado(event.target.value);
		setUserData({ ...userData, estado: event.target.value });
		setTimeout(() => {
			filtraTextoBuscar()
		}, 150);

	};


	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	const handleDateChange = (dates: any) => {
		setSelectedDates(dates);
		const searchDate = (startDate?: string, endDate?: string) => {
			setUserData({ ...userData, fecha_inicio: startDate, fecha_fin: endDate });
			setTimeout(() => {
				filtraTextoBuscar()
			}, 150);
		}
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			searchDate(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
		} else if (dates === null) {
			searchDate(undefined, undefined)
		}

	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15'
				style={{ backgroundColor: '#FFFF' }}
			>
				<LoadingBackdrop loading={isSubmitting} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-sm-12'>
						<div className='input-group d-flex ' style={{ width: '100%', }}>
							{/* BackButton */}
							<div style={{ padding: '10px 0px 0px 15px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>

							{/* Filtro de TIPO DIRECCIONAMIENTO */}
							<div className='col-lg-2 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px', }}>
								<Autocomplete
									options={tipoDeDireccionamiento}
									getOptionLabel={(option: any) => option.label || ""}
									isOptionEqualToValue={(option, value) => option?.id === value?.id}
									renderOption={(props, option) => (
										<li {...props} key={option.id} >
											{option.label}
										</li>
									)}
									onChange={(event, value) => {

										handleChageTipo(value?.id)

									}}
									loading={loading}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label="Tipo"

										/>
									)}
								/>
							</div>
							{/* Filtro de SUBTIPO DIRECCIONAMIENTO */}
							<div className='col-lg-2 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px', }}>
								<Autocomplete

									options={subTipoDeDireccionamiento}
									getOptionLabel={(option: any) => option.label || ""}
									isOptionEqualToValue={(option, value) => option?.id === value?.id}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)}
									onChange={(event, value) => {
										handleChageSubtipo(value?.id)
									}}
									loading={loading}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label="Subtipo"

										/>
									)}
								/>
							</div>

							<div className='col-lg-2 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px', }}>
								<FormControl fullWidth >
									<InputLabel id="demo-simple-select-label">Estado</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={estado}
										label="Estado"
										onChange={handleEstado}
									>
										<MenuItem ></MenuItem>
										<MenuItem value={'Creado'}>Creado</MenuItem>
										<MenuItem value={'Registrados'}>Registrados</MenuItem>
										<MenuItem value={'Finalizado'}>Finalizado</MenuItem>
									</Select>
								</FormControl>
							</div>
							{/* Filtro de busqueda*/}
							<div className='col-lg-4 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={'input-group mb-3'}>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Filtra por Código, Consejero candidato.'

										aria-label='Filtra por Código, Consejero candidato.'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchEmpresa'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							{/* fitro de fechas */}
							<div className='col-lg-3 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<DatePicker
									value={selectedDates}
									range={true} locale={colombia_es_lowercase}
									title='rango de fechas' name='rangeDate'
									placeholder='Filtra por rango de fechas'
									dateSeparator=' - ' format='DD/MM/YY'
									onChange={handleDateChange}
									style={{ padding: 20, fontSize: 14, width: 225 }}
								/>
							</div>
							{/* Boton  para registrar un acompañamiento */}
							<div className='col-lg-2 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>

								<Link to={handleCrearAcompañamiento()}
									className='btn btn-primary btn-lg'
									role="button">Registrar direccionamiento</Link>

							</div>


						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
					<Stack padding={2}>

						{/* <TableContainer component={Paper} className={`${styleScss['table-limit']}`} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}> */}

						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>

							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>CÓDIGO DIRECCIONAMIENTO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>TIPO DIRECCIONAMIENTO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>SUBTIPO DIRECCIONAMIENTO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>FECHA DE REGISTRO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>CONSEJERO CANDIDATO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>ESTADO</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>ACCIONES</h4>
										</TableCell>
									</TableRow>
								</TableHead>

								{!loading && <TableBody>
									{data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.codigo_direccionamiento?.toUpperCase()}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.tipoDireccionamiento.label}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.subtipoDireccionamiento.label}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{dayjs(x?.fecha_registro).format("DD/MM/YYYY")}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.consejeroCandidato?.UserComplemento.primer_nome} {x?.consejeroCandidato?.UserComplemento.primer_apelido}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
												{x?.estado}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												<MenuAction data={{ id: x.id, active: x.active, status: x.estado, subtipo: x?.subtipoDireccionamiento.label }} />
											</TableCell>
										</TableRow>
									))}
									
								</TableBody>}

								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={8}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}

							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>
					</Stack>
				</div>
			</div>

			{/* modal de eliminar */}
			<div>
				<Dialog
					open={openModalDeleteAcompaniment}
					keepMounted
					onClose={handleCloseModalAcompanimentDelete}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Esta seguro que desea eliminar este direccionamiento una vez realizado no es posible deshacer la acción'}</DialogTitle>
					<DialogContent>
						
						<br />
						<DialogContentText id='alert-dialog-description'>
							¿ Desea continuar con la eliminación del Direccionamiento ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90, }}
							onClick={handleCloseModalAcompanimentDelete}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handleDeleteDireccionamiento(idDireccionamiento)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			{/* modal Cerrar */}
			<div>
				<Dialog
					open={openModalCloseAcompaniment}
					keepMounted
					onClose={handleCloseModalAcompaniment}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Confirme que desea cerrar este acompañamiento.'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea cerrar este acompañamiento?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalAcompaniment}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handleCerrarUnAcompanamiento(idAcompanimento)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cerrar
						</Button>
					</DialogActions>
				</Dialog>
			</div>




			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const AddressOrientation = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Panel de Direccionamiento / Orientación</PageTitle>
			<ListAddressOrientation />
		</>
	);
};

export { AddressOrientation };
