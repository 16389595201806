import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../models/parameter.model';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';

const useAddressParams = () => {
	const [paisList, setPaisList] = useState<ParameterConfigMmodel[]>([]);
	const [departamentoList, setDepartamentoList] = useState<ParameterConfigMmodel[]>([]);
	const [otrosConocimientosList, setOtrosConocimientosList] = useState<ParameterConfigMmodel[]>([]);
	const [zonaList, setZonaList] = useState<ParameterConfigMmodel[]>([]);
	const [viaPrincipalBisList, setViaPrincipalBisList] = useState<ParameterConfigMmodel[]>([]);
	const [listViaPrincipalCuadrante, setListViaPrincipalCuadrante] = useState<
		ParameterConfigMmodel[]
	>([]);
	const [listViaPrincipalPrimeraLetra, setListViaPrincipalPrimeraLetra] = useState<
		ParameterConfigMmodel[]
	>([]);
	const [listViaPrincipal, setListViaPrincipal] = useState<ParameterConfigMmodel[]>([]);
	const [listViaPrincipalComplementoDireccion, setListViaPrincipalComplementoDireccion] = useState<
		ParameterConfigMmodel[]
	>([]);
	const [listPrestadorPreferencia, setListPrestadorPreferencia] = useState<ParameterConfigMmodel[]>(
		[]
	);
	const [listPuntoAtencion, setListPuntoAtencion] = useState<ParameterConfigMmodel[]>([]);
	const [listViaPrincipalEmpresa, setListViaPrincipalEmpresa] = useState<ParameterConfigMmodel[]>([]);
	const [listLocalidades, setListLocalidades] = useState<any[]>([]);
	const [listLocalidadesCompletas, setListLocalidadesCompletas] = useState<any[]>([]);
	const [loadingParams, setloadingParams] = useState(true);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchPaisParams();
		await fetchDepartamentoParams();
		await fetchOtrosConocimientosList();
		await fetchZonaList();
		await fetchViaPrincipalBisParams();
		await fetchListViaPrincipalCuadranteParams();
		await fetchlistViaPrincipalPrimeraLetraParams();
		await fetchSetListViaPrincipalParams();
		await fetchListViaPrincipalComplementoDireccionParams();
		await fetchListPrestadorPreferenciaParams();
		await fetchListPuntoAtencionModelParams();
		await fetchListViaPrincipalEmpresa();
		await fetchListLocalidadesCompletas();
		setloadingParams(false);
	};

	const fetchListLocalidadesCompletas = async ()=>{
		const res = await parameterSingleton.fetchLocalidades();
		setListLocalidadesCompletas(res);
	}

	useEffect(() => {
		const localidadesReales = listLocalidadesCompletas.filter((localidad) => localidad.id_localidad !== 99);
		setListLocalidades(localidadesReales);
	 }, [listLocalidadesCompletas]);
	 

	const fetchPaisParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PAIS);
		setPaisList(res);
	};

	const fetchDepartamentoParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.DEPARTAMENTO);
		setDepartamentoList(res);
	};

	const fetchOtrosConocimientosList = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setOtrosConocimientosList(res);
	};

	const fetchZonaList = async () => {
		const res = await parameterSingleton.fetchData(parameters.ZONA);
		setZonaList(res);
	};

	const fetchViaPrincipalBisParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPALBIS);
		setViaPrincipalBisList(res);
	};

	const fetchListViaPrincipalCuadranteParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPALCUADRANTE);
		setListViaPrincipalCuadrante(res);
	};

	const fetchlistViaPrincipalPrimeraLetraParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPALPRIMERALETRA);
		setListViaPrincipalPrimeraLetra(res);
	};

	const fetchSetListViaPrincipalParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPAL);
		setListViaPrincipal(res);
	};

	const fetchListViaPrincipalComplementoDireccionParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPALDIRECCION);
		setListViaPrincipalComplementoDireccion(res);
	};

	const fetchListPrestadorPreferenciaParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PRESTADORPREFERENCIA);
		setListPrestadorPreferencia(res);
	};

	const fetchListPuntoAtencionModelParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PUNTOATENCION);
		setListPuntoAtencion(res);
	};

	const fetchListViaPrincipalEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.VIAPRINCIPALEMPRESA);
		setListViaPrincipalEmpresa(res);
	};

	return {
		paisList,
		departamentoList,
		otrosConocimientosList,
		zonaList,
		viaPrincipalBisList,
		listViaPrincipalCuadrante,
		listViaPrincipalPrimeraLetra,
		listViaPrincipal,
		listViaPrincipalComplementoDireccion,
		listPrestadorPreferencia,
		listPuntoAtencion,
		listViaPrincipalEmpresa,
		listLocalidades,
		loadingParams,
		setloadingParams
	};
};

export default useAddressParams;
