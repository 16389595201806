import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Snackbar, AlertTitle, Select, FormControl, FormControlLabel, Checkbox, InputLabel, SelectChangeEvent, OutlinedInput, ListItemText, FormHelperText } from '@mui/material';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import {
    getRespuestas,
    getVRD,
    postPonderar,
    postRespuestas
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, Stack
} from '@mui/material';

import { useParams } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const validarPrecondicon = (pregunta: any, respuestas: any, calback: () => void) => {
    if (!pregunta.estructura.precondicion) {
        return false
    }
    const respuestasMap = new Map();
    respuestas.forEach((r: any) => {
        respuestasMap.set(`${r.id_seccion}-${r.codigo_pregunta}`, r);
    });
    const { activar } = pregunta.estructura.precondicion;
    const precondicionesNoCumplidas = activar.some((condicion: any) => {
        const keyCondicion = `${condicion.seccion}-${condicion.codigo_pregunta}`;
        const respuestaCondicion = respuestasMap.get(keyCondicion);

        // Validar si condicion.codigo_respuesta es un string o un array
        if (Array.isArray(condicion.codigo_respuesta)) {
            // Si es un array, verificar si incluye el código de respuesta
            return (
                !respuestaCondicion ||
                !condicion.codigo_respuesta.includes(respuestaCondicion.codigo_respuesta)
            );
        } else {
            // Si es un string, realizar la comparación directa
            return (
                !respuestaCondicion ||
                respuestaCondicion.codigo_respuesta !== condicion.codigo_respuesta
            );
        }
    });
    if (precondicionesNoCumplidas) {
        // console.log('borrado2')
        calback()
        return true
    }
    return false
}


const borrarPrecondicon = ({ idSesion, idPregunta, evaluacionData, setEvaluacionData }: any) => {
    // console.log(idSesion, subPregunta)
    const respuesta = evaluacionData.respuesta.find((item: any) => item.id_pregunta === idPregunta && item.id_seccion === idSesion)
    if (respuesta) {
        setEvaluacionData({
            ...evaluacionData, 
            respuesta: evaluacionData.respuesta.filter((item: any) => !(item.id_pregunta === idPregunta && item.id_seccion === idSesion))
        })
    }
}



const validarErrors = (pregunta: any, errores: any[]) => {

    // return 'aqui va el mensja de error'

    // Busca si hay un error relacionado con la pregunta actual
    const errorPregunta = errores?.find((error: any) => error.id_pregunta === pregunta.id);


    // Si existe un error, devuelve el mensaje de error
    if (errorPregunta) {
        return errorPregunta.error; // Devuelve el mensaje de error específico

        //  return errorPregunta.error; // Devuelve el mensaje de error específico
    }

    // Si no hay error, devuelve una cadena vacía
    return '';
}


const ListRegisterCounselors = () => {
    const { id } = useParams();
    const [data, setData] = useState<any>({});
    const [errores, setErrores] = useState<any>([])
    const [preguntas, setPreguntas] = useState<any>([]);
    const [evaluacionData, setEvaluacionData] = useState<any>({
        respuesta: []
    });
    const [loading, setLoading] = useState(true);
    const [showPonderar, setShowPonderar] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleGetVRD = async () => {
        setLoading(true);
        try {
            const response = await getVRD(id);
            let listado: any[] = []
            for (let i = 0; i < response.data.evaluacion.Secciones.length; i++) {
                const element = response.data.evaluacion.Secciones[i];
                for (let e = 0; e < element.Preguntas.length; e++) {
                    const preguntas = element.Preguntas[e];
                    listado.push(...preguntas.Preguntas)
                }
                if (i + 1 === response.data.evaluacion.Secciones.length) {
                    setPreguntas(listado)
                    setData(response.data)
                    setEvaluacionData((prev: any) => ({
                        ...prev,
                        id_user: response.data.aspirante,
                        id_evaluacion: response.data.evaluacion_id
                    }))
                    await handleGetRespuestasVRD(response.data.aspirante, response.data.evaluacion_id)
                }
            }
        } catch (error: any) {
            setAlertMsg(error.error);
            setOpen(false);
            setAlert(true);
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        } finally {
            setLoading(false);
        }
    };

    const respuestasVRD = async (id_evaluacion: any, id_user: any) => {
        const response = await getRespuestas(id_evaluacion, id_user);
        setEvaluacionData({
            ...evaluacionData,
            respuesta: response.data.respuesta,
            id_evaluacion: response.data.id_evaluacion,
            id_user: response.data.id_user,
            resultado: response.data.resultado
        })
    }
    const handleGetRespuestasVRD = async (id_user: any,
        id_evaluacion: any) => {
        try {
            await respuestasVRD(id_evaluacion, id_user)
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        }
    };

    const handleChange = (idPregunta: number, idSeccion: number, value: any, codigoPregunta: string, tipo: string) => {
        setEvaluacionData((prev: any) => {
            // console.log("🔥 ", prev)
            const respuestaExistente = prev.respuesta.find((r: any) => r.id_pregunta === idPregunta);

            if (tipo === "select") {
                if (respuestaExistente) {
                    return {
                        ...prev,
                        respuesta: prev.respuesta.map((r: any) =>
                            r.id_pregunta === idPregunta ? { ...r, codigo_respuesta: value } : r
                        )
                    };
                } else {
                    return {
                        ...prev,
                        respuesta: [
                            ...prev.respuesta,
                            {
                                id_pregunta: idPregunta,
                                id_seccion: idSeccion,
                                codigo_pregunta: codigoPregunta,
                                codigo_respuesta: value
                            }
                        ]
                    };
                }
            } else { // checkbox (multiple select)
                if (respuestaExistente) {
                    return {
                        ...prev,
                        respuesta: prev.respuesta.map((r: any) =>
                            r.id_pregunta === idPregunta ? { ...r, codigo_respuesta: value } : r
                        )
                    };
                } else {
                    return {
                        ...prev,
                        respuesta: [
                            ...prev.respuesta,
                            {
                                id_pregunta: idPregunta,
                                id_seccion: idSeccion,
                                codigo_pregunta: codigoPregunta,
                                codigo_respuesta: value
                            }
                        ]
                    };
                }
            }
        });
    };

    const getRenderValue = (idPregunta: number, options: any[]) => {
        const respuesta = evaluacionData.respuesta.find((r: any) => r.id_pregunta === idPregunta);
        if (!respuesta) return '';

        const selectedCodes = Array.isArray(respuesta.codigo_respuesta) ? respuesta.codigo_respuesta : [];
        return options
            .filter(op => selectedCodes.includes(op.codigo))
            .map(op => op.value)
            .join(', ');
    };

    const enviarRespuestas = async () => {
        try {
            setErrores([])
            await postRespuestas(evaluacionData, evaluacionData.id_evaluacion, evaluacionData.id_user);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Respuestas Enviadas con éxito!`);
            setTimeout(() => {
                setOpen(false);
            }, 1500);
        } catch (error: any) {
            if (error.response) {
                // console.log(error.response.data.error)
                setErrores(error.response.data.error)
                setOpen(true);
                setAlert(false);
                setAlertMsg('Valide las preguntas');
                setTimeout(() => {
                    setOpen(false);
                }, 1500);
            }
            else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        }
    };


    useEffect(() => {
        handleGetVRD();
    }, []);

    const colores: any = {
        rojo: { background: '#F7DDDE', color: '#A3696F' },
        naranja: { background: '#FFE5C4', color: '#A68550' },
        verde: { background: '#D4EDDA', color: '#155724' }
    };



    const handlePonderar = async () => {
        setShowPonderar(true)
        try {
            await postPonderar(evaluacionData.id_evaluacion, evaluacionData.id_user)
            await respuestasVRD(evaluacionData.id_evaluacion, evaluacionData.id_user)
        } catch (error: any) {
            console.error('Error capturado:', error);
            if (error.response) {
                console.error('Response completa:', error.response);
                const errorMessage = error.response.data?.error || "Ocurrió un error inesperado";
                setOpen(true);
                setAlert(false);
                setAlertMsg(errorMessage);
                setTimeout(() => {
                    setOpen(false);
                }, 2500);
            } else {
                console.error('Ocurrió un error procesando la solicitud.');
            }
        }
    }

    return (
        <>
            <div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
                <LoadingBackdrop loading={isSubmitting} />
                <div className='row mb-4' style={{ alignItems: 'center' }}>
                    <div className='col-sm-12'>
                        <div className='input-group d-flex' style={{ width: '100%', }}>
                            <div style={{ padding: '10px 0px 0px 15px' }}>
                                <div className='d-flex justify-content-start'
                                    style={{
                                        display: 'contents',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <BackButton />
                                    <p style={{ marginLeft: 20 }}>Para el diligenciamiento de la siguiente lista de chequeo, el/la profesional de orientación debe seleccionar la opción que mejor describa a los buscadores de empleo:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? <LinearLoadingSpinner isLoading={loading} /> :
                    <div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
                        <Stack padding={2}>
                            <>
                                {data.evaluacion.Secciones.map((seccion: any) => (
                                    <div key={seccion.id}>
                                        <h2 style={{ padding: "16px" }}>{seccion.titulo}</h2>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ marginBottom: 5 }}
                                            style={{ overflowY: 'visible', overflowWrap: 'break-word' }}
                                        >
                                            <Table style={{ overflowY: 'visible' }}>
                                                <TableHead className={`${styleScss['th-custom']}`}>
                                                    <TableRow>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}>
                                                            <h4>Preguntas</h4>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}>
                                                            <h4>Respuestas</h4>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {seccion.Preguntas.map((pregunta: any) =>
                                                        pregunta.Preguntas.map((subPregunta: any) => {
                                                            const error = validarErrors(subPregunta, errores)
                                                            return (
                                                                <TableRow key={subPregunta.id}>
                                                                    <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
                                                                        {`${subPregunta.codigo} ${subPregunta.titulo}`} <br /> <br />
                                                                        {subPregunta?.detalle && subPregunta?.detalle}
                                                                        {/* {`${subPregunta?.detalle}`} */}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {subPregunta.estructura.tipo === "select" ? (
                                                                            <FormControl
                                                                                error={!!error}
                                                                                fullWidth
                                                                            >
                                                                                <Select
                                                                                    disabled={validarPrecondicon(subPregunta, evaluacionData.respuesta, () => {
                                                                                        borrarPrecondicon({ idSesion: seccion.id, idPregunta: subPregunta.id, evaluacionData, setEvaluacionData })
                                                                                        // console.log(seccion.id, subPregunta.id, )
                                                                                    })}
                                                                                    fullWidth
                                                                                    value={
                                                                                        evaluacionData.respuesta.find((r: any) => r.id_pregunta === subPregunta.id)?.codigo_respuesta || ""
                                                                                    }
                                                                                    onChange={(e) => handleChange(subPregunta.id, seccion.id, e.target.value, subPregunta.codigo, "select")}
                                                                                >
                                                                                    {subPregunta.estructura?.option?.map((op: any) => (
                                                                                        <MenuItem key={`${op.codigo}-${op.value}`} value={op.codigo}>
                                                                                            {op.value}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                                {error && <FormHelperText>{error}</FormHelperText>}

                                                                            </FormControl>
                                                                        ) : (
                                                                            <FormControl
                                                                                error={!!error}
                                                                                fullWidth
                                                                                style={{ maxWidth: 350, textAlign: 'end' }}
                                                                            >
                                                                                <Select
                                                                                    fullWidth
                                                                                    multiple
                                                                                    disabled={validarPrecondicon(subPregunta, evaluacionData.respuesta, () => {
                                                                                        borrarPrecondicon({ idSesion: seccion.id, idPregunta: subPregunta.id, evaluacionData, setEvaluacionData })
                                                                                        // console.log(seccion.id, subPregunta.id, )
                                                                                    })}
                                                                                    value={evaluacionData.respuesta.find((r: any) => r.id_pregunta === subPregunta.id)?.codigo_respuesta || []}
                                                                                    onChange={(e) => handleChange(
                                                                                        subPregunta.id,
                                                                                        seccion.id,
                                                                                        e.target.value,
                                                                                        subPregunta.codigo,
                                                                                        "checkbox"
                                                                                    )}
                                                                                    renderValue={() => getRenderValue(subPregunta.id, subPregunta.estructura.option)}
                                                                                    MenuProps={MenuProps}
                                                                                >
                                                                                    {subPregunta.estructura?.option?.map((op: any) => (
                                                                                        <MenuItem key={`${op.codigo}-${op.value}`} value={op.codigo}>
                                                                                            <Checkbox
                                                                                                checked={evaluacionData.respuesta
                                                                                                    .find((r: any) => r.id_pregunta === subPregunta.id)
                                                                                                    ?.codigo_respuesta?.includes(op.codigo) || false}
                                                                                            />
                                                                                            <ListItemText primary={op.value} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                                {error && <FormHelperText>{error}</FormHelperText>}
                                                                            </FormControl>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ))}

                                {/* Botones de Enviar y ponderar */}
                                <div className="d-flex justify-content-end" style={{ marginBottom: 50 }}>
                                    <Button onClick={enviarRespuestas} variant="contained" size="large" style={{ marginRight: 10 }}>
                                        GUARDAR RESPUESTAS
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={!evaluacionData?.respuesta}
                                        size="large"
                                        onClick={handlePonderar}
                                    >
                                        PONDERAR
                                    </Button>
                                </div>


                                {/* tabla de ponderar */}
                                {evaluacionData?.resultado &&
                                    <div>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ marginBottom: 5 }}
                                            style={{ overflowY: 'visible', overflowWrap: 'break-word' }}
                                        >
                                            <Table style={{ overflowY: 'visible' }}>
                                                <TableHead className={`${styleScss['th-custom']}`}>
                                                    <TableRow>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}>
                                                            <h4>Tipo de puntaje</h4>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}>
                                                            <h4>Descripción</h4>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', width: 0 }}>
                                                            <h4>Puntaje</h4>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', width: 0 }}>
                                                            <h4>Categoría</h4>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', fontWeight: 'bold' }}>
                                                            Puntaje global
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', }}>
                                                            {evaluacionData?.resultado?.resultado?.tipo_puntaje?.puntaje_global?.descripcion}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                            {evaluacionData?.resultado?.resultado?.tipo_puntaje?.puntaje_global?.puntaje.toFixed(1)}%
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                            <div
                                                                style={{
                                                                    padding: 10,
                                                                    borderRadius: 5,
                                                                    background: colores[evaluacionData?.resultado?.resultado?.tipo_puntaje?.puntaje_global?.categoria?.color]?.background || '#FFFFFF',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{ color: colores[evaluacionData?.resultado?.resultado?.tipo_puntaje?.puntaje_global?.categoria?.color]?.color || '#000000' }}
                                                                >
                                                                    {evaluacionData?.resultado?.resultado?.tipo_puntaje?.puntaje_global?.categoria?.categoria}
                                                                </span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                        </TableContainer>

                                        <TableContainer
                                            component={Paper}
                                            sx={{ marginBottom: 5 }}
                                            style={{ overflowY: 'visible', overflowWrap: 'break-word' }}
                                        >
                                            <Table style={{ overflowY: 'visible' }}>
                                                <TableHead className={`${styleScss['th-custom']}`} >
                                                    <TableRow>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}><h4>Oferta</h4></TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}><h4>Descripción</h4></TableCell>
                                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px' }}><h4>Se recomienda</h4></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {evaluacionData?.resultado?.resultado?.ofertas.map((oferta: any, index: any) => (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', fontWeight: 'bold' }}>{oferta.titulo}</TableCell>
                                                            <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>{oferta.descripcion}</TableCell>
                                                            <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>{oferta.se_recomienda}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>}
                            </>
                        </Stack>
                    </div>
                }
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                    {alertMsg}
                </Alert>
            </Snackbar>
        </>
    );
};

const RecordingAndWeightingVariables = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Registro y ponderación de variables VRD</PageTitle>
            <ListRegisterCounselors />
        </>
    );
};

export { RecordingAndWeightingVariables };