import { Alert, AlertColor, AlertTitle, Snackbar } from "@mui/material";
import { FC, useEffect, useState } from "react";

const AlertMessage: FC<{ isOpen: boolean, isAlert: boolean, message: string }> = ({ isOpen, isAlert, message }) => {

    const [open, setOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState(true);
    // const [severity, setSeverity] = useState<AlertColor>('success');

    useEffect(() => {
        setOpen(isOpen);
        setAlertMsg(message);
        setAlert(isAlert);
    })

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                variant={'filled'}
                severity={alert ? 'success' : 'error'}
                sx={{ whiteSpace: 'pre-line' }}
            >
                {isAlert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                {alertMsg}
            </Alert>
        </Snackbar>

    )
}

export default AlertMessage;
