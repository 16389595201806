import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,
	Snackbar,
	Alert,
	InputAdornment,
	CircularProgress,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
	getUserCompelmentoId,
	updateUserComplementoPut,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { getAuth } from '../../modules/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatMaskPhoneNumber12Digits, regExpPassword, regExpConsecutiveCharacteres, isValidColombianPhoneNumber, helperIsValidColombianPhoneNumber, regexValidAlphabetValueText, isValidFirstSecondNameFirstSecondLastName, helperValidFirstSecondNameFirstSecondLastName, isValidEmail, isValidFieldRequired } from '../../util/Index';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

const Profile = () => {
	const navigate = useNavigate();

	const [isChangePassword, setChangePassword] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const [showNewPass, setShowNewPass] = useState(false);
	const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isError, setIsError] = useState(false);
	const [msgNotification, setMsgNotification] = useState('');
	const [loadingParams, setLoadingParams] = useState(true);

	useEffect(() => {
		(async function fetchData() {
			const auth = getAuth();
			try {
				const response = await getUserCompelmentoId(auth!.user!.id);
				const valueFormatted: IUpdateUserCompelmento = {
					id_users: response.data.id_users ? response.data.id_users : '',
					primer_nome: response.data.primer_nome ? response.data.primer_nome : '',
					segundo_nome: response.data.segundo_nome ? response.data.segundo_nome : '',
					primer_apelido: response.data.primer_apelido ? response.data.primer_apelido : '',
					segundo_apelido: response.data.segundo_apelido ? response.data.segundo_apelido : '',
					correo: response.data.correo ? response.data.correo : (auth!.user!.email || ''),
					password: '',
					new_password: '',
					confirm_new_password: '',
					celular: response.data.celular ? response.data.celular : '',
				};
				formik.setValues(valueFormatted);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			}
			setLoadingParams(false)
		})();
	}, []);

	const passwordRequirement = useMemo(() => {
		if (isChangePassword) {
			return {
				password: Yup.string()
					.required('Campo requerido')
					.max(50, 'Contraseña debe tener como máximo 50 dígitos'),
				new_password: Yup.string()
					.required('Campo requerido')
					.min(8, 'Nueva Contraseña debe tener como mínimo 8 dígitos')
					.max(50, 'Nueva Contraseña debe tener como máximo 50 dígitos')
					.matches(
						regExpPassword(),
						`Nueva Contraseña debe tener al menos: 1 carácter especial, 1 letra mayúscula, 1 número`
					),
				confirm_new_password: Yup.string()
					.required('Campo requerido')
					.oneOf(
						[Yup.ref('new_password'), null],
						'Contraseña debe ser la misma que la contraseña ingresada'
					),
			};
		}
		return {
			password: Yup.string(),
			new_password: Yup.string(),
			confirm_new_password: Yup.string(),
		};
	}, [isChangePassword]);

	const profileDetailsSchema = useMemo(
		() =>
			Yup.object().shape({
				primer_nome: Yup.string()
					.min(2, 'Debe tener al menos 2 caracteres')
					.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
						return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
					})
					.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
					.required('Campo requerido'),
				segundo_nome: Yup.string()
					.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
						return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
					})
					.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos'),
				primer_apelido: Yup.string()
					.min(2, 'Debe tener al menos 2 caracteres')
					.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
						return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
					})
					.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
					.required('Campo requerido'),
				segundo_apelido: Yup.string()
					.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
						return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
					})
					.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos'),
				//genero: Yup.string().required('Género es requerido'),
				correo: Yup.string()
					.required('Campo requerido')
					.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
						return isValidEmail(value || '')
					}),
				celular: Yup.string()
					//.matches(/\+(\d{2})-(\d{3})-(\d{7})/, 'Teléfono de contacto es inválido')
					.test('isValidColombianPhoneNumber', (val) => helperIsValidColombianPhoneNumber(val, true), (val) => { return isValidColombianPhoneNumber(val, true) })
					.required('Campo requerido'),
				password: passwordRequirement.password,
				new_password: passwordRequirement.new_password,
				confirm_new_password: passwordRequirement.confirm_new_password,
			}),
		[passwordRequirement.confirm_new_password, passwordRequirement.new_password]
	);

	const formik = useFormik<IUpdateUserCompelmento>({
		initialValues: submitValues,
		validationSchema: profileDetailsSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: async (values) => {
			try {
				const response = await updateUserComplementoPut(values);
				setIsError(false);
				setMsgNotification(`¡Perfil de usuario actualizado con éxito!`);
			} catch (error: any) {
				if (error.response) {
					console.log(error);
					const errors = error.response.data.error ?? 'Intenta Nuevamente';
					setMsgNotification(`${errors}`);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
				setIsError(true);
			} finally {
				setOpenDialog((open) => !open);
			}
		},
	});

	const handleClose = () => {
		setOpenDialog(false);
		if (!isError) navigate('/', { replace: true });
	};

	useEffect(() => {
		const alterPass =
			formik.values.password.length > 0 ||
			formik.values.new_password.length > 0 ||
			formik.values.confirm_new_password.length > 0;
		setChangePassword(alterPass);
	}, [
		formik.values.confirm_new_password.length,
		formik.values.new_password.length,
		formik.values.password.length,
	]);

	return (loadingParams ? <LinearLoadingSpinner isLoading={true} /> :
		<>
			<PageTitle breadcrumbs={[]}>Perfil del usuario</PageTitle>
			<Box sx={{ backgroundColor: '#ffff', m: 5, p: 5 }}>
				<div className='row mb-10'>
					<div className='col-sm-12'>
						<div className='input-group'>
							<div
								className='col-sm-1'
								style={{
									display: 'contents',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<BackButton />
							</div>
							<div className='col-sm-10' style={{ padding: '5px 0px 0px 10px' }}>
								<h2 style={{ display: 'flex', gap: '10px' }}>Perfil del usuario</h2>
							</div>
						</div>
					</div>
				</div>
				<Grid item xs={12}>
					<form action='' onSubmit={formik.handleSubmit} noValidate>
						<Grid container item xs={12}>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label='Primer nombre'
									{...formik.getFieldProps('primer_nome')}
									error={formik.touched.primer_nome && !isValidFirstSecondNameFirstSecondLastName(formik.values.primer_nome, true)}
									helperText={formik.touched.primer_nome && !isValidFirstSecondNameFirstSecondLastName(formik.values.primer_nome, true) ? helperValidFirstSecondNameFirstSecondLastName(formik.values.primer_nome, true) : ''}
									size='medium'
									inputProps={{ minLength: 2, maxLength: 40 }}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label='Segundo nombre'
									{...formik.getFieldProps('segundo_nome')}
									size='medium'
									error={formik.touched.segundo_nome && !isValidFirstSecondNameFirstSecondLastName(formik.values.segundo_nome, false)}
									helperText={formik.touched.segundo_nome && !isValidFirstSecondNameFirstSecondLastName(formik.values.segundo_nome, false) ? helperValidFirstSecondNameFirstSecondLastName(formik.values.segundo_nome, false) : ''}
									inputProps={{ maxLength: 40 }}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label='Primer apellido'
									{...formik.getFieldProps('primer_apelido')}
									error={formik.touched.primer_apelido && !isValidFirstSecondNameFirstSecondLastName(formik.values.primer_apelido, true)}
									helperText={formik.touched.primer_apelido && !isValidFirstSecondNameFirstSecondLastName(formik.values.primer_apelido, true) ? helperValidFirstSecondNameFirstSecondLastName(formik.values.primer_nome, true) : ''}
									inputProps={{ minLength: 2, maxLength: 40 }}
									size='medium'
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label='Segundo apellido'
									{...formik.getFieldProps('segundo_apelido')}
									size='medium'
									error={formik.touched.segundo_apelido && !isValidFirstSecondNameFirstSecondLastName(formik.values.segundo_apelido, false)}
									helperText={formik.touched.segundo_apelido && !isValidFirstSecondNameFirstSecondLastName(formik.values.segundo_apelido, false) ? helperValidFirstSecondNameFirstSecondLastName(formik.values.segundo_apelido, false) : ''}
									inputProps={{ maxLength: 40 }}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									type='email'
									label='Correo electrónico'
									{...formik.getFieldProps('correo')}
									inputProps={{ maxLength: 80 }}
									error={formik.touched.correo && Boolean(formik.errors.correo)}
									helperText={formik.touched.correo && Boolean(formik.errors.correo) ? formik.errors.correo : ''}
									required
									disabled
								/>
							</Grid>

							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label='Telefóno de contacto'
									inputProps={{ maxLength: 10 }}
									{...formik.getFieldProps('celular')}
									size='medium'
									error={!isValidColombianPhoneNumber(formik.getFieldProps('celular').value, true)}
									helperText={helperIsValidColombianPhoneNumber(formik.getFieldProps('celular').value, true)}
									onChange={(e) => {
										let val = e.target.value
											?.replaceAll(/[^\d]/g, '')
											?.replaceAll('-', '')
											?.replaceAll('+', '');
										let reg = /[0-9]|\./;
										if (reg.test(val) || val === '') {
											e.target.value = formatMaskPhoneNumber12Digits(val);
											formik.handleChange(e);
										}
									}}
								/>
							</Grid>
						</Grid>
						<Grid container item direction='row' xs={12}>
							<Grid item xs={12} mb={3} md={12}>
								<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
									Cambiar la contraseña
								</Typography>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label='Contraseña'
									{...formik.getFieldProps('password')}
									error={Boolean(formik.errors.password) && isChangePassword}
									helperText={isChangePassword ? formik.errors.password : ''}
									size='medium'
									required
									type={showPass ? 'string' : 'password'}
									inputProps={{ maxLength: 50 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowPass(!showPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowPass(!showPass)}
													></i>
												)}
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label='Nueva contraseña'
									{...formik.getFieldProps('new_password')}
									error={Boolean(formik.errors.new_password) && isChangePassword}
									helperText={isChangePassword ? formik.errors.new_password : ''}
									size='medium'
									required={isChangePassword}
									type={showNewPass ? 'string' : 'password'}
									inputProps={{ maxLength: 50 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showNewPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowNewPass(!showNewPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowNewPass(!showNewPass)}
													></i>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label='Confirme la nueva contraseña'
									{...formik.getFieldProps('confirm_new_password')}
									error={Boolean(formik.errors.confirm_new_password) && isChangePassword}
									helperText={isChangePassword ? formik.errors.confirm_new_password : ''}
									size='medium'
									required={isChangePassword}
									type={showConfirmNewPass ? 'string' : 'password'}
									inputProps={{ maxLength: 50 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showConfirmNewPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowConfirmNewPass(!showConfirmNewPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowConfirmNewPass(!showConfirmNewPass)}
													></i>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						<Grid container item direction='row' justifyContent='end' width={'100%'}>
							<Button variant='contained' type='submit' className='btn btn-primary'>
								{!formik.isSubmitting && 'Actualizar'}
								{formik.isSubmitting && <CircularProgress size={20} color='inherit' />}
							</Button>
						</Grid>
					</form>

					<Snackbar
						open={openDialog}
						autoHideDuration={1500}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<Alert
							onClose={handleClose}
							variant='filled'
							severity={isError ? 'error' : 'success'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							<Link to='/' style={{ pointerEvents: isError ? 'none' : 'auto', color: 'white' }}>
								{msgNotification}
							</Link>
						</Alert>
					</Snackbar>
				</Grid>
			</Box>
		</>
	);
};

const submitValues: IUpdateUserCompelmento = {
	id_users: 0,
	primer_nome: '',
	segundo_nome: '',
	primer_apelido: '',
	segundo_apelido: '',
	celular: '',
	correo: '',
	password: '',
	new_password: '',
	confirm_new_password: '',
};
export interface IUpdateUserCompelmento {
	id_users: number;
	primer_nome: string;
	segundo_nome: string;
	primer_apelido: string;
	segundo_apelido: string;
	celular: string;
	correo: string;
	password: string;
	new_password: string;
	confirm_new_password: string;
}
export default Profile;
