const parameters={
    "CARGOADMINISTRADOR":"CARGOADMINISTRADOR",
    "CARGOCONTACTO":"CARGOCONTACTO",
    "CUOCGRUPOOCUPACION":"CUOCGRUPOOCUPACION",
    "CUOCSUBGRUPO":"CUOCSUBGRUPO",
    "LINCENCIACARRO": "LINCENCIACARRO",
    "LINCENCIAMOTO": "LINCENCIAMOTO",
    "DEPARTAMENTO": "DEPARTAMENTO",
    "MUNICIPIO": "MUNICIPIO",
    "FUENTE":"FUENTE",
    "DISCAPACIDADES":"DISCAPACIDADES",
    "POBLACIONES":"POBLACIONES",
    "DIASLABORALES": "DIASLABORALES",
    "ESTADOCERTIFICADO": "ESTADOCERTIFICADO",
    "ESTADOCIVIL": "ESTADOCIVIL",
    "ESTADOCURSO": "ESTADOCURSO",
    "GENERO": "GENERO",
    "GRUPOETNICO":"GRUPOETNICO",
    "GRUPOINDIGENA":"GRUPOINDIGENA",
    "HORARIOTRABAJO": "HORARIOTRABAJO",
    "IDIOMA": "IDIOMA",
    "INTERESESEMPRESA":"INTERESESEMPRESA",
    "JORNADALABORAL": "JORNADALABORAL",
    "NACIONALIDAD": "NACIONALIDAD",
    "NATURALEZAEMPRESA": "NATURALEZAEMPRESA",
    "NIVELEDUCATIVO": "NIVELEDUCATIVO",
    "NIVELIDIOMA": "NIVELIDIOMA",
    "ORIENTACIONSEXUAL": "ORIENTACIONSEXUAL",
    "PAIS": "PAIS",
    "PERSONACUIDADA":"PERSONACUIDADA",
    "PRESTADORPREFERENCIA": "PRESTADORPREFERENCIA",
    "PROGRAMASCORPORATIVOS":"PROGRAMASCORPORATIVOS",
    "PRESENCIALIDAD":"PRESENCIALIDAD",
    "PUNTOATENCION": "PUNTOATENCION",
    "SALARIO": "SALARIO",
    "SALARIOMENSUAL":"SALARIOMENSUAL",
    "SECTORINDUSTRIAL": "SECTORINDUSTRIAL",
    "SEXO": "SEXO",
    "SINO": "SINO",
    "SISBEN":"SISBEN",
    "SITUACIONLABORAL": "SITUACIONLABORAL",
    "TAMANOEMPRESA": "TAMANOEMPRESA",
    "TIPOCAPACITACION": "TIPOCAPACITACION",
    "TIPOCONTRATO": "TIPOCONTRATO",
    "TIPOCONVOCATORIA":"TIPOCONVOCATORIA",
    "TIPODOCUMENTOEMPRESA": "TIPODOCUMENTOEMPRESA",
    "TIPODOCUMENTO": "TIPODOCUMENTO",
    "TIPOEMPRESA": "TIPOEMPRESA",
    "TIPOEXPERIENCIALABORAL": "TIPOEXPERIENCIALABORAL",
    "TIPOLIBRETA": "TIPOLIBRETA",
    "TIPOCONOCIMIENTOS": "TIPOCONOCIMIENTOS",
    "TIPOVACANTE": "TIPOVACANTE",
    "VIAPRINCIPALBIS": "VIAPRINCIPALBIS",
    "VIAPRINCIPAL": "VIAPRINCIPAL",
    "VIAPRINCIPALDIRECCION": "VIAPRINCIPALDIRECCION",
    "VIAPRINCIPALCUADRANTE": "VIAPRINCIPALCUADRANTE",
    "VIAPRINCIPALEMPRESA": "VIAPRINCIPALEMPRESA",
    "VIAPRINCIPALPRIMERALETRA": "VIAPRINCIPALPRIMERALETRA",
    "ZONA": "ZONA",
    "SISTEMAPESOSVACANTES":"SISTEMAPESOSVACANTES",
    "SISTEMASWITCHESVACANTES":"SISTEMASWITCHESVACANTES",
    "TIPOBITACORA":"TIPOBITACORA"
}

export default parameters;