/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { verifyToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { Alert, Snackbar } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { eventOnKeyDownInputNumber, isValidEmail } from '../../../util/Index';


const documentTypes = [
	{ id: '', value: 'Seleccione' },
	{ id: 'EMAIL', value: '1. CORREO ELECTRÓNICO' },
	{ id: 'CC', value: '2. CÉDULA DE CIUDADANIA' },
	{ id: 'TI', value: '3. TARJETA DE IDENTIDAD' },
	{ id: 'CE', value: '4. CÉDULA DE EXTRANJERÍA' },
	{ id: 'PA', value: '5. PASAPORTE' },
	{ id: 'PEP', value: '6. PERMISO ESPECIAL DE PERMANENCIA' },
	{ id: 'PT', value: '7. PERMISO POR PROTECCIÓN TEMPORAL' },
	// { id: 'NIT', value: '8. NÚMERO DE IDENTIFICACIÓN TRUBUTARIA (NIT)' },
];

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const [loading, setLoading] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [verified, setVerified] = useState(false);
	const [documentLabel, setDocumentLabel] = useState('Seleccione');
	const [documentId, setDocumentId] = useState('EMAIL');
	const handleClose = () => {
		setOpen(false);
	};

	const [documentTypeSelected, setDocumentTypeSelected] = useState('');
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const initialValues = {
		documentType: '',
		numero_doc: '',
		password: '',
	};

	useEffect(() => {
		validationSchemaByTpDoc();
		return () => {
			//window.location.reload();
		};
	}, []);

	const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

	const validationSchemaByTpDoc = () => {

		const password = Yup.string().min(3, 'Mínimo 3 símbolos').max(50, 'Máximo 50 símbolos').required('Contraseña es requerida');
		const documentType = Yup.string().test('len', 'Seleccione un tipo de documento', (val) => { return (val as string) === '' ? false : true; }).required('Campo requerido');

		switch (documentTypeSelected) {
			case 'CC':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Campo requerido')
						.min(3, 'Debe tener al menos 3 dígitos')
						.max(11, 'Debe tener 11 o menos dígitos'),
					password: password,
				});
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(10);
				setMaxDocumentFieldLenght(11);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]*$/, 'Deben ser solo números y letras')
						.required('Campo requerido')
						.min(10, 'Debe tener al menos 10 dígitos')
						.max(11, 'Debe tener 11 o menos dígitos'),
					password: password,
				});
			case 'CE':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(7);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Campo requerido')
						.min(1, 'Debe tener al menos 1 dígitos')
						.max(7, 'Debe tener 7 o menos dígitos'),
					password: password,
				});
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(17);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
						.required('Campo requerido')
						.min(1, 'Debe tener al menos 1 dígitos')
						.max(17, 'Debe tener 17 o menos dígitos'),
					password: password,
				});
			case 'PEP':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(20);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Campo requerido')
						.min(15, 'Debe tener al menos 15 dígitos')
						.max(20, 'Debe tener 20 o menos dígitos'),
					password: password,
				});
			case 'PT':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Campo requerido')
						.min(4, 'Debe tener al menos 4 dígitos')
						.max(7, 'Debe tener 7 o menos dígitos'),
					password: password,
				});
			case 'EMAIL':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(80);
				setDocumentLabel('Correo');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.required('Campo requerido')
						.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
							return isValidEmail(value || '')
						})
						.min(3, 'Mínimo 3 símbolos')
						.max(80, 'Máximo 80 símbolos'),
					password: password,
				});
			case 'NIT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(9);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(9, 'Debe tener al menos 9 dígitos')
						.max(9, 'Debe tener 9 o menos dígitos'),
					password: password,
				});
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(50);
				setDocumentLabel('Seleccione');
				return Yup.object().shape({
					documentType: documentType,
					numero_doc: Yup.string()
						.required('Campo requerido')
						.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
							return isValidEmail(value || '')
						})
						.min(3, 'Mínimo 3 caracteres')
						.max(80, 'Máximo 80 caracteres'),
					password: password,
				});
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchemaByTpDoc,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			if (!verified) {
				saveAuth(undefined);
				setStatus('The login details are incorrect');
				setSubmitting(false);
				setLoading(false);
				setOpen(true);
				setIsError(true);
				setMsgAlert(`Completa el recaptcha`);
				return;
			}

			try {
				setLoading(true);
				setSubmitting(true);
				const { data: response } = await login(values.numero_doc, values.password, values.documentType);
				saveAuth(response);
				const { data: response2 } = await verifyToken(response.api_token);
				setCurrentUser(response2);
			} catch (error: any) {
				if (error.response) {
					setOpen(true);
					setIsError(true);
					setMsgAlert(`${error.response.data.error}`);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
				saveAuth(undefined);
				setStatus('The login details are incorrect');
			} finally {
				setLoading(false);
				setSubmitting(false);
			}
		},
	});

	const onChangeCaptcha = () => {
		setVerified(true);
	};

	return (
		<div className='d-flex flex-center'>
			<form
				className='form col-9 col-md-6'
				onSubmit={formik.handleSubmit}
				noValidate
				id='kt_login_signin_form'
			>
				{/* begin::Heading */}
				<div className='text-center mb-11'>
					<h1 className='text-dark fw-bolder mb-3'>Acceso</h1>
					<div className='text-black-500 text-center fw-semibold fs-6'>
						Ingresa tus datos para iniciar sesión.
					</div>
				</div>
				{/* end::Heading */}

				{/* begin::Form group */}
				<div className='fv-row mb-8'>
					{/* begin::Input group */}
					<div className='mb-8'>
						<label className='form-label fs-6 fw-bolder text-dark'>Medio de autenticación</label>
						<div>
							<select
								{...formik.getFieldProps('documentType')}
								onChange={(e) => {
									formik.handleChange(e);
									// formik.setFieldValue('numero_doc', '');
									setDocumentTypeSelected(e.target.value);
									// changeTypeDoc(event.target.value)
								}}
								className='form-select'
								data-kt-select2='true'
								data-placeholder='Seleccione un tipo'
								data-allow-clear='true'
							// defaultValue={'EMAIL'}
							>
								{documentTypes.map((doc, i) => (
									<option value={doc.id} key={doc.id}>
										{doc.value}
									</option>
								))}
							</select>
						</div>
					</div>
					{/* end::Input group */}

					<label className='form-label fs-6 fw-bolder text-dark'>{documentLabel}</label>
					{/* {documentId === '1' ? (
						<>
							<input
								placeholder='Email'
								{...formik.getFieldProps('email')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': formik.touched.email && formik.errors.email },
									{ 'is-valid': formik.touched.email && !formik.errors.email }
								)}
								type='email'
								name='email'
								autoComplete='off'
							/>
							{formik.touched.email && formik.errors.email && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>
										<span role='alert'>{formik.errors.email}</span>
									</div>
								</div>
							)}
						</>
					) : (
						<> */}
					<input
						placeholder={documentLabel}
						{...formik.getFieldProps('numero_doc')}
						onChange={(e) => {
							if (e.target.value.length <= maxDocumentFieldLenght) formik.handleChange(e);
						}}
						className={clsx('form-control bg-transparent',
							{ 'is-invalid': formik.touched.numero_doc && formik.errors.numero_doc },
							{ 'is-valid': formik.touched.numero_doc && !formik.errors.numero_doc }
						)}
						type={documentTypeField}
						name='numero_doc'
						autoComplete='off'
						onKeyDown={documentTypeSelected !== 'EMAIL' && documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen 
					/>
					{formik.touched.numero_doc && formik.errors.numero_doc && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.numero_doc}</span>
							</div>
						</div>
					)}
					{/* </>
					)} */}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='fv-row mb-3'>
					<label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
					<input
						type={showPass ? 'text' : 'password'}
						placeholder='Contraseña'
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control bg-transparent',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{showPass ? (
						<i
							className='bi bi-eye pe-auto'
							style={{
								fontSize: '20px',
								float: 'right',
								marginRight: '40px',
								marginTop: '-30px',
								position: 'relative',
								zIndex: '2',
							}}
							onClick={() => setShowPass(!showPass)}
						></i>
					) : (
						<i
							className='bi bi-eye-slash pe-auto'
							style={{
								fontSize: '20px',
								float: 'right',
								marginRight: '40px',
								marginTop: '-30px',
								position: 'relative',
								zIndex: '2',
							}}
							onClick={() => setShowPass(!showPass)}
						></i>
					)}
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				<div
					className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mt-6 mb-6'
					style={{ justifyContent: 'center', marginTop: 60 }}
				>
					<ReCAPTCHA
						sitekey={RECAPTCHA_SITE_KEY}
						hl='es'
						onChange={onChangeCaptcha}
					/>
				</div>

				{/* begin::Checkbox*/}
				{/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mt-6 mb-6'>
					<label className='form-check form-check-sm form-check-custom me-5'>
						<input className='form-check-input' type='checkbox' value='1' />
						<span className='form-check-label fw-normal text-dark'>Acuérdate de mí</span>
					</label>
				</div> */}
				{/* end::Checkbox*/}

				{/* begin::Action */}
				<div className='d-grid mb-10'>
					<button
						type='submit'
						id='kt_sign_in_submit'
						className='btn btn-primary'
						style={{ background: '#0A4396' }}
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && (
							<>
								<i className='bi bi-box-arrow-in-right' style={{ fontSize: '20px' }}></i>
								<span className='indicator-label'>Iniciar sesión</span>
							</>
						)}
						{loading && (
							<span className='indicator-progress' style={{ display: 'block' }}>
								Aguarde...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				</div>
				{/* end::Action */}

				{/* begin::Wrapper */}
				<div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
					<div />
					{/* begin::Link */}
					<Link to='/olvide-contrasena' className='link-primary'>
						Se me olvidó la contraseña
					</Link>
					{/* end::Link */}
				</div>
				{/* end::Wrapper */}
			</form>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</div>
	);
}
