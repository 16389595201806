import { FormEvent, useContext, useEffect, useState } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Typography,
	Box,
	Rating,
	Divider,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	SelectChangeEvent
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import { createCurriculo } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import _ from 'lodash';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import { initValueString, isValidFieldRequired, initValue, setValueOrDefault } from '../../../../../util/Index';
import { LoadingBackdrop } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useLanguageAndSkillsParams from '../../../../../hooks/parameters/curriculum/useLanguageAndSkillsParams';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const Step8 = () => {
	const { listIdioma, listNivelIdioma, listTipoOtrosConocimientos } = useLanguageAndSkillsParams();
	const { setStep, userData, setUserData, setCurrentProgress } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [idIdioma, setIdIdioma] = useState<string>('');
	const [idNivel, setIdNivel] = useState<string>('');
	const [idTipoHerramientas, setIdTipoHerramientas] = useState<string>('');
	const [idHerramientas, setIdHerramientas] = useState<string>('');
	const [idNivelHerramientas, setIdNivelHerramientas] = useState<number | null>(null);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [step, setStepUser] = useState<string>('step8');

	const [rowsIdioma, setRowsIdioma] = useState([{ id: 0, id_idioma: '', nivel: '' }]);

	const [rowsHerramientas, setRowsHerramientas] = useState([
		{ id: 0, tipo: '', herramientas: '', nivel: 0 },
	]);

	const [isEdit, setEdit] = useState(false);
	const [disable, setDisable] = useState(true);
	const [showConfirm, setShowConfirm] = useState(false);

	const [requestInProgress, setRequestInProgress] = useState(false);

	const [editOrCreateItemIdiomaInProgress, setEditOrCreateItemIdiomaInProgress] = useState(false);

	const [editOrCreateItemHerramientaInProgress, setEditOrCreateItemHerramientaInProgress] = useState(false);

	useEffect(() => {
		if (userData['CurriculoConocimientos'] !== undefined && listTipoOtrosConocimientos.length > 0) {
			(userData['CurriculoConocimientos'] as Array<any>).forEach((x) => {
				if (x.id_tipo_conocimientos !== undefined)
					var y = {
						id: x.id as number,
						herramientas: initValueString(x.herramientas),
						tipo: initValueString(
							listTipoOtrosConocimientos.find((y) => y.code === x.id_tipo_conocimientos?.toString())?.code
						),
						nivel: x.nivel as number,
					};
				else
					var y = {
						id: x.id as number,
						herramientas: initValueString(x.herramientas),
						tipo: initValueString(listTipoOtrosConocimientos.find((y) => y.code === x.tipo?.toString())?.code),
						nivel: x.nivel as number,
					};
				rowsHerramientas.push(y);
			});
		}


	}, [listTipoOtrosConocimientos])

	useEffect(() => {
		if (userData['CurriculoIdiomas'] !== undefined && listIdioma.length > 0 && listNivelIdioma.length > 0) {
			(userData['CurriculoIdiomas'] as Array<any>).forEach((x) => {
				if (x.id_idioma !== undefined)
					var y = {
						id: x.id as number,
						id_idioma: initValueString(listIdioma.find((y) => y.code === x.id_idioma?.toString())?.code),
						nivel: initValueString(listNivelIdioma.find((y) => y.code === x.nivel?.toString())?.code),
					};
				else
					var y = {
						id: x.id as number,
						id_idioma: initValueString(listIdioma.find((y) => y.code === x.idioma?.toString())?.code),
						nivel: initValueString(listNivelIdioma.find((y) => y.code === x.nivel?.toString())?.code),
					};
				rowsIdioma.push(y);
			});
			setRowsIdioma([...rowsIdioma]);

		}

	}, [listNivelIdioma, listIdioma])



	// Function For adding new row object
	useEffect(() => {
		setIdNivel('');
		setIdNivelHerramientas(null);

		const listRowsIdioma = rowsIdioma.find((obj) => {
			return obj.id === 0;
		});
		const listRowsHerramientas = rowsHerramientas.find((obj) => {
			return obj.id === 0;
		});

		if (listRowsIdioma) {
			const list = [...rowsIdioma];
			list.splice(_.findIndex(list, { id: 0 }), 1);
			setRowsIdioma(list);
		}
		if (listRowsHerramientas) {
			const list = [...rowsHerramientas];
			list.splice(_.findIndex(list, { id: 0 }), 1);
			setRowsHerramientas(list);
		}
	}, []);

	const isAddedLanguage = (): boolean => {
		const addedLanguage = rowsIdioma.find(idioma => idioma.id_idioma === idIdioma);
		if (addedLanguage) {
			setOpen(true);
			setAlert(false);
			setAlertMsg(`El Idioma ya ha sido agregado`);
			return true;
		}
		return false;
	}

	const handleAddIdioma = () => {
		if (idIdioma !== '' && idNivel !== '') {
			if (isAddedLanguage()) {
				return;
			}
			const idiomaModel = listIdioma.find((obj) => {
				return obj.code === idIdioma;
			});
			let ididioma = idiomaModel?.code as string;
			let idnivel = idNivel as string;
			setRowsIdioma([
				...rowsIdioma,
				{
					id: new Date().getTime(),
					id_idioma: ididioma,
					nivel: idnivel,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Idioma agregado con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			setEdit(true);
			setIdNivel('');
			setIdIdioma('');
			setEditOrCreateItemIdiomaInProgress(false);
		} else {
			setOpen(true);
			setAlert(false);
			if (idIdioma === '') {
				setAlertMsg('Seleccione un Idioma');
			} else {
				setAlertMsg('Seleccione un nivel de manejo del idioma');
			}
		}
	};
	const finalizar = () => {
		//console.log(userData);
	};

	const handleAddHerramientas = () => {
		if (idTipoHerramientas !== '' && idHerramientas !== '' && idNivelHerramientas !== null) {
			const listTipo = listTipoOtrosConocimientos.find((obj) => {
				return obj.code === idTipoHerramientas;
			});
			let idtipo = listTipo?.code as string;
			let idherramientas = idHerramientas as string;
			let idnivel = idNivelHerramientas as number;
			setRowsHerramientas([
				...rowsHerramientas,
				{
					id: new Date().getTime(),
					tipo: idtipo,
					herramientas: idherramientas,
					nivel: idnivel,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Otros conocimientos agregado con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			setEdit(true);
			setIdNivelHerramientas(null);
			setIdHerramientas('');
			setIdTipoHerramientas('');
			setEditOrCreateItemHerramientaInProgress(false);
		} else {
			setOpen(true);
			setAlert(false);
			if (idTipoHerramientas === '') {
				setAlertMsg('Seleccione un Tipo de Herramienta');
			} else if (idHerramientas === '') {
				setAlertMsg('Seleccione una Herramienta');
			} else {
				setAlertMsg('Seleccione un nivel de manejo de la herramienta');
			}
		}
	};

	// Function to handle save
	const handleSave = () => {
		setEdit(!isEdit);
		setRowsIdioma(rowsIdioma);
		//console.log('saved : ', rowsIdioma);
		setDisable(true);
		setOpen(true);
	};

	/**
	 * Realiza control de diligenciamiento de información para un Idioma
	 * @returns 
	 */
	const checkErrorsIdiomas = () => {
		let errors: { field: string; error: string }[] = [];
		//Validación de atributos para el Idioma
		if (isValidFieldRequired(idIdioma) && !isValidFieldRequired(idNivel)) {
			errors.push({ field: 'Nivel Idioma', error: 'Campo requerido' });
		} else if (!isValidFieldRequired(idIdioma) && isValidFieldRequired(idNivel)) {
			errors.push({ field: 'Idioma', error: 'Campo requerido' });
		}
		return errors;
	}

	/**
	 * Realiza control de diligenciamiento de información para una Herramienta
	 */
	const checkErrorsHerramientas = () => {
		let errors: { field: string; error: string }[] = [];
		if (isValidFieldRequired(idTipoHerramientas) && (!isValidFieldRequired(idHerramientas) || !isValidFieldRequired(idNivelHerramientas))) {
			if (!isValidFieldRequired(idHerramientas))
				errors.push({ field: 'Herramientas', error: 'Campo requerido' });
			if (!isValidFieldRequired(idNivelHerramientas))
				errors.push({ field: 'Nivel Herramientas', error: 'Campo requerido' });
		} else if (isValidFieldRequired(idHerramientas) && (!isValidFieldRequired(idTipoHerramientas) || !isValidFieldRequired(idNivelHerramientas))) {
			if (!isValidFieldRequired(idTipoHerramientas))
				errors.push({ field: 'Tipo Herramienta', error: 'Campo requerido' });
			if (!isValidFieldRequired(idNivelHerramientas))
				errors.push({ field: 'Nivel Herramientas', error: 'Campo requerido' });
		} else if (isValidFieldRequired(idNivelHerramientas) && (!isValidFieldRequired(idTipoHerramientas) || !isValidFieldRequired(idHerramientas))) {
			if (!isValidFieldRequired(idTipoHerramientas))
				errors.push({ field: 'Tipo Herramienta', error: 'Campo requerido' });
			if (!isValidFieldRequired(idHerramientas))
				errors.push({ field: 'Herramientas', error: 'Campo requerido' });
		}
		return errors;
	}

	const handleValidOnSave = () => {
		let errors: { field: string; error: string }[] = [];

		//Se establecen reglas de control para validar si hay registro no completados que se deben agregar
		//Reglas de control para Idiomas
		errors.push(...checkErrorsIdiomas());
		//Reglas de control para Herramientas
		errors.push(...checkErrorsHerramientas());

		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;

	};

	async function handleSubmit(event: FormEvent) {
		if (handleValidOnSave()) {
			try {
				setRequestInProgress(true);
				event.preventDefault();
				setUserData({ ...userData, listIdioma: rowsIdioma, listHerramientas: rowsHerramientas });
				let object = createObjectToSubmit();
				const response = await createCurriculo(object);
				setOpen(true);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setTimeout(() => {
					setOpen(false);
					setRequestInProgress(false);
					navigate(-1);
				}, 3000);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
				setRequestInProgress(false);
			}
		}
	}
	const handleRetorno = () => {
		//console.log('idi', rowsIdioma);
		setUserData({
			...userData,
			CurriculoIdiomas: rowsIdioma,
			CurriculoConocimientos: rowsHerramientas,
		});
		setStep(7);
	};

	// Showing delete confirmation to users
	const handleConfirm = () => {
		setShowConfirm(true);
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClickIdioma = (id: number) => {
		const list = [...rowsIdioma];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsIdioma(list);
	};

	const handleRemoveClickHerramientas = (id: number) => {
		const list = [...rowsHerramientas];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsHerramientas(list);
	};
	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (rowsIdioma !== null && rowsIdioma !== undefined && (rowsIdioma as Array<any>).length > 0) {
			(rowsIdioma as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			rowsHerramientas !== null &&
			rowsHerramientas !== undefined &&
			(rowsHerramientas as Array<any>).length > 0
		) {
			(rowsHerramientas as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						listTipoOtrosConocimientos.find((y) => y.code === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
            tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
            tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'],'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			finalizado: true,
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
		};

		return resume;
	};

	/**
	 * Metodo de evento para cambio de idioma
	 * @param e 
	 */
	const onChangeIdioma = (e: SelectChangeEvent) => {
		setIdIdioma(e.target.value)
		if (e.target.value) {
			setEditOrCreateItemIdiomaInProgress(true);
		}
	}

	/**
	 * Metodo de evento para cambio de nivel de idioma
	 * @param e 
	 */
	const onChangeNivelIdioma = (e: SelectChangeEvent) => {
		setIdNivel(e.target.value)
		if (e.target.value) {
			setEditOrCreateItemIdiomaInProgress(true);
		}
	}

	/**
	 * Limpia proceso de edicion o registro de idioma
	 */
	const handleCancelarIdioma = () => {
		setIdIdioma('')
		setIdNivel('')
		setEditOrCreateItemIdiomaInProgress(false);
	};

	/**
	 * Metodo de evento para cambio de tipo de herramienta
	 * @param e 
	 */
	const onChangeTipoHerramienta = (e: SelectChangeEvent) => {
		setIdTipoHerramientas(e.target.value)
		if (e.target.value) {
			setEditOrCreateItemHerramientaInProgress(true);
		}
	}

	/**
	 * Metodo de evento para cambio de nombre de herramienta
	 * @param e 
	 */
	const onChangeHerramienta = (e: any) => {
		setIdHerramientas(e)
		if (e) {
			setEditOrCreateItemHerramientaInProgress(true);
		}
	}

	/**
	 * Metodo de evento para cambio de nivel de herramienta
	 * @param e 
	 */
	const onChangeNivelHerramienta = (e: any) => {
		setIdNivelHerramientas(e)
		if (e) {
			setEditOrCreateItemHerramientaInProgress(true);
		}
	}

	/**
	 * Limpia proceso de edicion o registro de herramienta
	 */
	const handleCancelarHerramienta = () => {
		setIdTipoHerramientas('')
		setIdHerramientas('')
		setIdNivelHerramientas(null)
		setEditOrCreateItemHerramientaInProgress(false);
	};

	return (
		<>
			<LoadingBackdrop loading={requestInProgress} />
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Idiomas
					</Typography>
					<Typography color='white' variant='h6'>
						Agrega los idiomas que dominas
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
				}}
				item
			>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={!isValidFieldRequired(idIdioma) && isValidFieldRequired(idNivel)}>
						<InputLabel id={'idIdioma'} required={isValidFieldRequired(idNivel)}>Agregar idioma</InputLabel>
						<Select
							required={isValidFieldRequired(idNivel)}
							labelId={'idIdioma'}
							id={'idIdioma'}
							label='Agregar idiomas'
							value={idIdioma}
							onChange={(e) => onChangeIdioma(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listIdioma.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{!isValidFieldRequired(idIdioma) && isValidFieldRequired(idNivel) ? 'Campo requerido' : ''}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={isValidFieldRequired(idIdioma) && !isValidFieldRequired(idNivel)}>
						<InputLabel id={'idNivel'} required={isValidFieldRequired(idIdioma)}>Nivel idioma</InputLabel>
						<Select
							required={isValidFieldRequired(idIdioma)}
							labelId={'idNivel'}
							id={'idNivel'}
							label='Nivel idiomas'
							value={idNivel}
							onChange={(e) => onChangeNivelIdioma(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listNivelIdioma.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{isValidFieldRequired(idIdioma) && !isValidFieldRequired(idNivel) ? 'Campo requerido' : ''}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={4} md={2} mb={5} item />
				<Grid xs={1} md={1} mb={5} display='flex' justifyContent='end' alignItems='center' item>
					<Button
						variant='contained'
						onClick={handleAddIdioma}
						sx={{ width: 90, background: '#0A4396', mr: 1 }}
					>
						Agregar
					</Button>
					{editOrCreateItemIdiomaInProgress && (
						<Button
							variant='contained'
							sx={{ width: 95, background: '#6c757d', mr: 1 }}
							onClick={handleCancelarIdioma}
						>
							Cancelar
						</Button>
					)}
				</Grid>
				{rowsIdioma.length > 0 && (
					<Grid xs={12} item>
						<Typography color='#2B2B2B'>Agregados</Typography>
						<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
					</Grid>
				)}
				<Grid xs={12} item>
					{rowsIdioma.map((row, i) => (
						<Grid xs={12} key={i} item>
							<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
								<Grid xs={6} md={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										id={`idioma_${i}`}
										name={`idioma_${i}`}
										label='Idioma'
										value={initValue(listIdioma.find((y) => y.code === row.id_idioma)?.value)}
										size='medium'
										inputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid xs={6} md={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										id={`idioma_${i}`}
										name={`idioma_${i}`}
										label='Nivel'
										value={initValue(listNivelIdioma.find((y) => y.code === row.nivel)?.value)}
										size='medium'
										inputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid xs={4} md={2} mb={5} item />
								<Grid xs={2} md={1} mb={5} display='flex' justifyContent='end' alignItems='center' item>
									<div>
										<div className='d-flex justify-content-end flex-shrink-0'>
											<button
												disabled={editOrCreateItemIdiomaInProgress}
												className='btn btn-icon btn-active-color-secondary btn-sm'
												style={{ background: '#DD1342', width: 60 }}
												onClick={() => handleRemoveClickIdioma(row.id)}
											>
												<DeleteOutlinedIcon style={{ color: 'white' }} />
											</button>
										</div>
									</div>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>

			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Otros conocimientos
					</Typography>
					<Typography color='white' variant='h6'>
						Agrega herramientas que dominas
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
				}}
				item
			>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={!isValidFieldRequired(idTipoHerramientas) && (isValidFieldRequired(idHerramientas) || isValidFieldRequired(idNivelHerramientas))}>
						<InputLabel id='tipo_herramientas' required={isValidFieldRequired(idHerramientas) || isValidFieldRequired(idNivelHerramientas)}>Tipo</InputLabel>
						<Select
							required={isValidFieldRequired(idHerramientas)}
							labelId='tipo_herramientas'
							id='tipo_herramientas'
							label='Tipo'
							value={idTipoHerramientas}
							onChange={(e) => onChangeTipoHerramienta(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listTipoOtrosConocimientos.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{!isValidFieldRequired(idTipoHerramientas) && (isValidFieldRequired(idHerramientas) || isValidFieldRequired(idNivelHerramientas)) ? 'Campo requerido' : ''}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<TextField
						fullWidth
						id='herramientas'
						name='herramientas'
						label='Herramientas'
						value={idHerramientas}
						onChange={(e) => onChangeHerramienta(e.target.value)}
						size='medium'
						required={isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idNivelHerramientas)}
						error={(isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idNivelHerramientas)) && !isValidFieldRequired(idHerramientas)}
						helperText={(isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idNivelHerramientas)) && !isValidFieldRequired(idHerramientas) ? 'Campo requerido' : ''}
						inputProps={{ maxLength: 45 }}
					/>
				</Grid>
				<Grid xs={6} md={2} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={(isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idHerramientas)) && !isValidFieldRequired(idNivelHerramientas)}>
						<span
							className={
								((isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idHerramientas)) && !isValidFieldRequired(idNivelHerramientas)) ?
									'fw-semibold d-block fs-7' : 'text-muted fw-semibold d-block fs-7'
							}
							style={{ color: ((isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idHerramientas)) && !isValidFieldRequired(idNivelHerramientas)) ? '#d32f2f' : '' }}
						>Nivel {(isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idHerramientas)) ? <span>*</span> : ''}
						</span>
						<Rating
							size='large'
							name='simple-controlled'
							value={idNivelHerramientas}
							onChange={(e, newValue) => onChangeNivelHerramienta(newValue)}
						/>
						<FormHelperText>{((isValidFieldRequired(idTipoHerramientas) || isValidFieldRequired(idHerramientas)) && !isValidFieldRequired(idNivelHerramientas)) ? 'Campo requerido' : ''}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={2} md={1} mb={5} display='flex' justifyContent='end' alignItems='center' item>
					<Button
						variant='contained'
						onClick={handleAddHerramientas}
						sx={{ width: 90, background: '#0A4396', mr: 1 }}
					>
						Agregar
					</Button>
					{editOrCreateItemHerramientaInProgress && (
						<Button
							variant='contained'
							sx={{ width: 95, background: '#6c757d', mr: 1 }}
							onClick={handleCancelarHerramienta}
						>
							Cancelar
						</Button>
					)}
				</Grid>
				{rowsHerramientas.length > 0 && (
					<Grid xs={12} item>
						<Typography color='#2B2B2B'>Agregados</Typography>
						<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
					</Grid>
				)}
				<Grid xs={12} item>
					{rowsHerramientas.map((row, i) => (
						<Grid xs={12} key={i} item>
							<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
								<Grid xs={6} md={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										id='tipo_herramientas'
										name='tipo_herramientas'
										label='Tipo'
										value={initValue(listTipoOtrosConocimientos.find((y) => y.code === row.tipo)?.value)}
										size='medium'
										inputProps={{ readOnly: true }}
									//disabled
									/>
								</Grid>
								<Grid xs={6} md={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										id='herramientas'
										name='herramientas'
										label='Herramientas'
										value={row.herramientas}
										size='medium'
										inputProps={{ readOnly: true }}
									//disabled
									/>
								</Grid>
								<Grid xs={6} md={2} mb={5} pr={2} item>
									<span className='text-muted fw-semibold d-block fs-7'>Nivel</span>
									<Rating size='large' name='read-only' value={row.nivel} readOnly />
								</Grid>
								<Grid xs={2} md={1} mb={5} display='flex' justifyContent='end' alignItems='center' item>
									<div>
										<div className='d-flex justify-content-end flex-shrink-0'>
											<button
												disabled={editOrCreateItemHerramientaInProgress}
												className='btn btn-icon btn-active-color-secondary btn-sm'
												style={{ background: '#DD1342', width: 60 }}
												onClick={() => handleRemoveClickHerramientas(row.id)}
											>
												<DeleteOutlinedIcon style={{ color: 'white' }} />
											</button>
										</div>
									</div>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid >

			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
					disabled={requestInProgress}
				>
					Atrás
				</Button>
				{/* <Link to='/auth'> */}
				<Button variant='contained'
					sx={{ mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
					disabled={requestInProgress || (editOrCreateItemIdiomaInProgress || editOrCreateItemHerramientaInProgress)}>
					Finalizar
				</Button>
				{/* </Link> */}
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
