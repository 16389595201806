import { useContext } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';

import { multiStepContext } from '../../../contexts/UserRegistryContext';

import { Step1 } from './steps/user-management/Step1';
import { LinearLoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../../components/BackButton/BackButton';

const steps = ['', '', '', '', '', ''];

export const UserManagement = () => {
	const { currentStep, finalData, loading, setLoading } = useContext(multiStepContext);
	const navigate = useNavigate();

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
		}
	};

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%' }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>Datos del usuario</h2>
									<span style={{}}>Diligencie los campos con información del usuario.</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
				{/* Quitar comentario si se requiere añadir más steps */}
				{/* <Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
				</Stepper> */}
			</Box>
			<Box mt={5}>
				<LinearLoadingSpinner isLoading={loading} />
				{showStep(currentStep)}
			</Box>
		</Box>
	);
};
