import { PageTitle } from '../../../_metronic/layout/core'
import { EmployerManagementVacancyPage } from './EmployerManagementVacancyPage'

const EmployerManagementVacancyWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión Vacantes</PageTitle>
			<EmployerManagementVacancyPage />
		</>
	)
}

export { EmployerManagementVacancyWrapper }
