import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import parameters from '../../../util/parameters';
import parameterSingleton from '../../../services/Parameters.service';

const useStep3Parameters = () => {
	const [licenceCategoryList, setLicenceCategoryList] = useState<ParameterConfigMmodel[]>([]);
	const [licenceCategoryMotorList, setLicenceCategoryMotorList] = useState<ParameterConfigMmodel[]>(
		[]
	);
	const [listTipoOtrosConocimientos, setListTipoOtrosConocimientos] = useState<
		ParameterConfigMmodel[]
	>([]);
	const [listSalario, setListSalario] = useState<ParameterConfigMmodel[]>([]);
	const [listSalarioMensual, setListSalarioMensual] = useState<ParameterConfigMmodel[]>([]);
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);
	const [listSituacionLaboral, setListSituacionLaboral] = useState<ParameterConfigMmodel[]>([]);
	const [listNivelEducativo, setListNivelEducativo] = useState<ParameterConfigMmodel[]>([])
	const [loadingParams, setloadingParams] = useState(true);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchLicenseCarCategory();
		await fetchLicenseMotorcicleCategory();
		await fetchListTipoOtrosConocimientos();
		await fetchListSalario();
		await fetchListSalarioMensual();
		await fetchListSiNo();
		await fetchListSituacionLaboral();
		await fetchListNivelEducativo();
		setloadingParams(false);
	};

	const fetchLicenseCarCategory = async () => {
		const res = await parameterSingleton.fetchData(parameters.LINCENCIACARRO);
		setLicenceCategoryList(res);
	};

	const fetchLicenseMotorcicleCategory = async () => {
		const res = await parameterSingleton.fetchData(parameters.LINCENCIAMOTO);
		setLicenceCategoryMotorList(res);
	};

	const fetchListTipoOtrosConocimientos = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setListTipoOtrosConocimientos(res);
	};

	const fetchListSalario = async () => {
		const res = await parameterSingleton.fetchData(parameters.SALARIO);
		setListSalario(res);
	};

	const fetchListSalarioMensual = async () => {
		const res = await parameterSingleton.fetchData(parameters.SALARIOMENSUAL);
		const resOrderByCode = res.sort((a, b) => (Number(a.code) > Number(b.code)) ? 1 : -1);
		setListSalarioMensual(resOrderByCode);
	};

	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};

	const fetchListSituacionLaboral = async () => {
		const res = await parameterSingleton.fetchData(parameters.SITUACIONLABORAL);
		setListSituacionLaboral(res);
	};

	const fetchListNivelEducativo = async () => {
		const res = await parameterSingleton.fetchData(parameters.NIVELEDUCATIVO);
		setListNivelEducativo(res);
	}

	return {
		licenceCategoryList,
		licenceCategoryMotorList,
		listTipoOtrosConocimientos,
		listSalario,
		listSalarioMensual,
		listSiNo,
		listSituacionLaboral,
		listNivelEducativo,
		loadingParams,
	};
};

export default useStep3Parameters;
