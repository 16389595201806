import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { verifyToken, logoutUser, refreshToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { RoleEnum } from '../../../models/enums/RoleEnum'
import axios from 'axios'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  callRefreshToken: () => void
  isAdminRole: () => boolean | undefined
  isCandidateRole: () => boolean | undefined
  isEmployerRole: () => boolean | undefined
  isCounselorCandidateRole: () => boolean | undefined
  isCounselorEmployerRole: () => boolean | undefined
  configAxiosResponseInterceptor: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  callRefreshToken: () => { },
  isAdminRole: () => undefined,
  isCandidateRole: () => undefined,
  isEmployerRole: () => undefined,
  isCounselorCandidateRole: () => undefined,
  isCounselorEmployerRole: () => undefined,
  configAxiosResponseInterceptor: () => { }

}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const configAxiosResponseInterceptor = () => {
    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }

  //Almacenamiento de informacion de autenticacion
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth);
      callRefreshToken();
    } else {
      authHelper.removeAuth()
    }
  }

  //Llamado logout en el API
  const logout = () => {
    if (auth) {
      logoutUser()
        .then(() => {
          saveAuth(undefined);
          setCurrentUser(undefined);
          authHelper.removeAuth();
          console.log('Logout exitoso');
          window.location.reload();
        }
        )
        .catch((error) => {
          //Manejo cuando el error status code sea 401, el token ha expirado pero aun a si debe eliminar la data de sesion del cliente web
          if (error.response && error.response?.status === 401) {
            saveAuth(undefined);
            setCurrentUser(undefined);
            authHelper.removeAuth();
          } else {
            console.error(error?.response?.data?.error);
          }
        })
    } else {
      saveAuth(undefined);
      setCurrentUser(undefined);
      authHelper.removeAuth();
    }
  }

  /**
   * Realiza llamado al API para renovar el token si es necesario
   */
  const callRefreshToken = () => {
    const authModel = authHelper.getAuth();
    const jwtToken = authHelper.getAuthFromTokenJwt();
    if (authModel && jwtToken) {
      const tiempoActual = Math.floor(Date.now() / 1000); // Obtén el tiempo actual en segundos
      const tiempoRestante = jwtToken.exp - tiempoActual; // obtiene la diferencia de tiempo entre el sistema y la expiracion del token
      const nextCall = tiempoRestante <= 60 ? tiempoRestante : (tiempoRestante - 60);
      //console.log('jwtToken.exp: ' + jwtToken.exp);
      //console.log('jwtToken.exp: ' + new Date(jwtToken.exp));
      //console.log('tiempoRestante: ' + tiempoRestante);      
      //console.log('Siguiente llamado en :' + nextCall);
      setTimeout(() => {
        refreshToken()
          .then((response) => {
            if (response.data) {
              const authModel = authHelper.getAuth();
              if (authModel) {
                authModel.api_token! = response.data.api_token as string;
                authHelper.setAuth(authModel);
                callRefreshToken();
              }
            }
          })
          .catch((error: any) => {
            if (error.response) {
              console.error(`${error.response.data?.error}`);
            } else {
              console.error('Ocurrio un error procesando la solicitud.');
            }
            logout();
          })
      }, nextCall * 1000);
    } else {
      console.warn('No se encontro información de auth')
      logout();
    }
  }

  /**
   * Determina si el rol del usuario es Administrador
   * @returns 
   */
  const isAdminRole = () => {
    return authHelper.getAuth()?.user?.role === RoleEnum.ADMIN;
  }

  /**
   * Determina si el rol del usuario es Candidato
   * @returns 
   */
  const isCandidateRole = () => {
    return authHelper.getAuth()?.user?.role === RoleEnum.CANDIDATE;
  }

  /**
   * Determina si el rol del usuario es Consejero
   * @returns 
   */
  const isCounselorCandidateRole = () => {
    return authHelper.getAuth()?.user?.role === RoleEnum.COUNSELOR_CADIDATE;
  }
  const isCounselorEmployerRole = () => {
    return authHelper.getAuth()?.user?.role === RoleEnum.COUNSELOR_EMPLOYER;
  }

  /**
   * Determina si el rol del usuario es Empresa
   * @returns 
   */
  const isEmployerRole = () => {
    return authHelper.getAuth()?.user?.role === RoleEnum.EMPLOYER;
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, callRefreshToken, isAdminRole, isCandidateRole, isEmployerRole, isCounselorCandidateRole, isCounselorEmployerRole, configAxiosResponseInterceptor }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, callRefreshToken, configAxiosResponseInterceptor } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    configAxiosResponseInterceptor();
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data: response } = await verifyToken(apiToken)
          if (response) {
            const authModel = authHelper.getAuth();
            if (authModel) {
              authModel.api_token! = response.api_token as string;
              authHelper.setAuth(authModel);
              setCurrentUser(response);
            }
          }
        }
      } catch (error: any) {
        if (error.response) {
          console.error(error.response.data?.error);
        } else {
          console.error('Ocurrio un error procesando la solicitud.');
        }
        //Si no es exitoso el proceso de verificacion del token, se cierra la sesion
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token);
      //Estable nuevamente el timeout para el llamado del refresh token
      callRefreshToken();
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
