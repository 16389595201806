import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';





import BackButton from '../../../components/BackButton/BackButton';
import {
	Grid, Snackbar, Alert,
	AlertTitle, FormControl, Button,
	Autocomplete,
	TextField
} from '@mui/material';
import { DatePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getConvocatoriaById, getListOfTrainingPrograms, postCreateConvicatoria, updateConvocatoria } from '../../apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { Formik, Form } from "formik";
import { useAuth } from '../core/Auth';
import { useParams } from 'react-router-dom';




const bussineFormInitial = {
	codigoConvocatoria: '',
	tipoConvocatoria: '',
	idTrainingProgram: '',
	trainingProgram: '',
	fecha: null,
	idConsejeroCandidato: null,
	nombreConsejeroCandidato: '',
	estado: '',
}


export const AssistedCallRegistrationForm = () => {
	const { id, visible, create } = useParams();
	
	const { auth } = useAuth();


	// almacenamiento de conultas
	const [listOfTrainingPrograms, setListOfTrainigPrograms] = useState<[]>([])
	
	const [dataEdit, setDataEdit] = useState<any>()




	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [openAlert, setOpenAlert] = useState(false);


	const handleClose = () => {
		setOpen(false);
	};



	const navigate = useNavigate();

	const handleShowSnackbar = (type: boolean, message: string) => {
		setAlert(type);
		setAlertMsg(message);
		setOpenAlert(true);
	};




	// Esquema de validación con Yup
	const validationSchema = Yup.object({
		trainingProgram: Yup.string().required('El programa de formación es obligatorio'),
		fecha: Yup.date()
			.nullable()
			.required('La fecha es obligatoria')
			.typeError('Fecha inválida'),
	});

	const handleCloseModal = () => {
		setOpenAlert(false);
	};
	// Peticiones 

	// Get de Programas de formacion
	const handleGetListOfTrainingPrograms = async () => {
		try {
			const res = await getListOfTrainingPrograms();
			await setListOfTrainigPrograms(res.data)

		} catch (error) {
			console.error("Ocurrio un error procesando la solicitud.");
		}
	}

	// Post crear una convocatoria
	const handleCreateConvocatoria = async (data: any, resetForm: any) => {
		try {
			const response = await postCreateConvicatoria(data);
			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg(`¡Convocatoria creada con éxito!`);
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				resetForm();
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				handleCloseModal()
				setAlertMsg('Ocurrió un error al procesar la solicitud.');
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				resetForm();
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};

	// Get convocatoria  by id
	const handleGetconvocatoriaById = async (id: any) => {
		try {
			const response = await getConvocatoriaById(id);

			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg('¡Informacion cargada con éxito!')
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				setDataEdit(
					{
						codigoConvocatoria: response.data?.codigo_convocatoria || '',
						tipoConvocatoria: response.data?.TipoConvocatorium?.name || '',
						idTrainingProgram: response.data?.ProgramaFormacion?.id || '',
						trainingProgram: response.data?.ProgramaFormacion?.name || '',
						fecha: response.data?.fecha_registro || null,
						idConsejeroCandidato: response.data?.createdByUser?.id || auth?.user?.id,
						nombreConsejeroCandidato: `${response.data?.createdByUser?.UserComplemento?.primer_nome || ''} ${response.data?.createdByUser?.UserComplemento?.primer_apelido || ''}`,
						estado: response.data?.estado || '',
						numeroDePersonasAsistentes: response.data?.numero_personas_inscritas || '0',
						numeroDePersonasAprobadas: response.data?.numero_personas_aprobadas || '0'
					}
				)

			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	}

	const handleUpdateConvocatoria = async (data: any, id: any) => {
		try {
			const response = await updateConvocatoria(data, id);
			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg('¡Convocatoria actualizada con éxito!')
				setTimeout(() => {
					setOpen(false);
				}, 2500);

			} else {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				handleCloseModal()
				setAlertMsg('Ocurrió un error al procesar la solicitud.');
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};



	useEffect(() => {
		handleGetListOfTrainingPrograms()
	}, [])

	useEffect(() => {
		if (id) {
			handleGetconvocatoriaById(id)
		}
	}, [])






	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%', }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>{visible ? 'Información de la convocatoria' : 'Diligenciamiento de una convocatoria'}</h2>
									<span style={{}}>{visible ? 'Estos son los campos con la información de la convocatoria.' : 'Diligencie los campos con la información de la convocatoria.'}</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Box>

			<Box mt={5} sx={{ width: '95%', }}>
				<Formik
					enableReinitialize={true}
					initialValues={{
						...bussineFormInitial,
						...dataEdit
					}}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						

						const data = {
							id_programa_formacion: values.idTrainingProgram,
							id_consejero_candidato: auth?.user?.id,
							fecha_registro: values.fecha
						}

						if (id) {
							handleUpdateConvocatoria(data, id)
						} else { handleCreateConvocatoria(data, resetForm) }


						setSubmitting(false);
					}}
				>
					{({ setFieldValue, setFieldTouched, values, errors, touched, handleBlur, handleChange }) => (
						<Form>
							<Grid container direction='row' width={'100%'}>
								{
									visible || id ?
										<>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label='Código de convocatoria'
													value={dataEdit?.codigoConvocatoria}
												/>
											</Grid>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label='Tipo convocatoria'
													value={dataEdit?.tipoConvocatoria}
												/>
											</Grid>
										</>
										: null
								}

								{/* Campo Autocomplete */}
								<Grid lg={3} xs={12} md={6} mb={5} pr={2} item>
									{/* <Autocomplete
										disablePortal
										id="trainingPrograms"
										options={listOfTrainingPrograms}
										getOptionLabel={(option: any) => `${option.name}`}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.name}
											</li>
										)}
										onChange={(_, value) => {
											setFieldValue('trainingProgram', value?.name || '')
											setFieldValue('idTrainingProgram', value?.id || '')
										}
										}
										value={
											listOfTrainingPrograms.find(
												(option: any) => option.name === values.trainingProgram
											) || null
										}
										disabled={visible ?  true : false}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Programas de formación"
												error={!!(touched.trainingProgram && errors.trainingProgram)}
												helperText={touched.trainingProgram && errors.trainingProgram ? <>{errors.trainingProgram}</> : ''}
											/>
										)}
									/> */}
									<Autocomplete
    disablePortal
    id="trainingPrograms"
    options={[...listOfTrainingPrograms].sort((a: any, b: any) => a.name.localeCompare(b.name))}
    getOptionLabel={(option: any) => `${option.name}`}
    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
    renderOption={(props: any, option: any) => (
        <li {...props} key={option.id}>
            {option.name}
        </li>
    )}
    onChange={(_: any, value: any) => {
        setFieldValue('trainingProgram', value?.name || '');
        setFieldValue('idTrainingProgram', value?.id || '');
    }}
    value={
        listOfTrainingPrograms.find((option: any) => option.name === values.trainingProgram) || null
    }
    disabled={visible ? true : false}
    renderInput={(params: any) => (
        <TextField
            {...params}
            label="Programas de formación"
            error={!!(touched.trainingProgram && errors.trainingProgram)}
            helperText={touched.trainingProgram && errors.trainingProgram ? <>{errors.trainingProgram}</> : ''}
        />
    )}
/>

								</Grid>

								{/* Campo DatePicker */}
								<Grid lg={3} xs={12} md={3} mb={5} pr={2} item>
									<LocalizationProvider
									
										dateAdapter={AdapterDayjs}
										localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
										adapterLocale="es"
									>
										<FormControl sx={{ minWidth: '100%' }} size="medium">
											<DatePicker
											disabled={visible ?  true : false}
												disablePast
												format="DD/MM/YYYY"
												value={values.fecha ? dayjs(values.fecha) : null}
												onChange={(date) => setFieldValue('fecha', date ? dayjs(date).toISOString() : null)}
												slotProps={{
													textField: {
														fullWidth: true,
														id: 'fecha',
														label: 'Fecha de registro',
														variant: 'outlined',
														error: !!(touched.fecha && errors.fecha),
														helperText: touched.fecha && errors.fecha ? <>{errors.fecha}</> : ''
													},
													actionBar: { actions: ['clear', 'cancel', 'today'] },
												}}
											/>
										</FormControl>
									</LocalizationProvider>
								</Grid>

								{
									visible || id ?
										<>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label="Numero de personas asistentes"
													value={dataEdit?.numeroDePersonasAsistentes}
												/>

											</Grid>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label="Numero de personas aprobadas"
													value={dataEdit?.numeroDePersonasAprobadas}
												/>
											</Grid>
										</>
										: null
								}

								{
									visible || id ?
										<>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label='Consejero candidato'
													value={`${dataEdit?.nombreConsejeroCandidato} `}
												/>
											</Grid>
											<Grid lg={3} xs={12} md={6} mb={5} pr={2} item={true} >
												<TextField
													fullWidth
													InputProps={{
														readOnly: true,
													}}
													InputLabelProps={{
														shrink: true,
													}}
													id="outlined-disabled"
													label='Estado'
													value={dataEdit?.estado}
												/>
											</Grid>
										</>
										: null
								}





							</Grid>
							{/* Botón de enviar */}
							{
								visible ? 
									null 
								: 
								<div className="d-flex justify-content-center" style={{ width: '100%' }}>
									<Button
										variant="contained"
										sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
										type="submit"
									// disabled={isSubmitting}
									>
										{id ? "Modificar" : "Enviar"}
									</Button>
								</div>
							}


						</Form>
					)}

				</Formik>

				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={'filled'}
						severity={alert ? 'success' : 'error'}
						sx={{ whiteSpace: 'pre-line' }}
					>
						{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
						{alertMsg}
					</Alert>
				</Snackbar>

			</Box>

		</Box >
	);
};
