/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { CompanyRegistration } from '../modules/auth/components/CompanyRegistration';
import { FirstCompanyProps } from '../models/FirstCompanyModel';
import { FirstUserProps } from '../models/FirstUserModel';

export const multiStepContext = React.createContext<any>(null);

const CompanyContext = () => {
	const [currentStep, setStep] = useState(1);
	const [userData, setUserData] = useState([]);
	const [finalData, setFinalData] = useState<any[]>([]);
	const [data, setData] = useState([]);
	const [firstUser, setFirstUser] = useState<FirstUserProps>({
		user: {
			email: '',
			confirma_email: '',
			password: '',
			confirma_password: '',
			role: 1,
			active: true,
			acepto_termos: false,
			dados_personales: false,
		},
		complemento: {
			tipo_doc: '',
			numero_doc: '',
			primer_nome: '',
			segundo_nome: '',
			primer_apelido: '',
			segundo_apelido: '',
			fecha_nascimiento: '',
			ciudad: '',
			estrato: '',
			localidad: '',
			upz: '',
			bairro: '',
			celular: '',
			correo: '',
			cargo: '',
			pregunta_seguridad: '',
			respuesta: '',
		},
	});
	const [firstCompany, setFirstCompany] = useState<FirstCompanyProps>({
		company: {
			codigo_ciiu: undefined,
			razon_social: '',
			tipo_documento: '',
			numero_documento: '',
			naturaleza: '',
			tipo: '',
			Tamano_empleados: '',
			pertence: '',
			local: '',
			localidad: '',
			latitud: 0,
			longitud: 0,
			id_coordenada: '',
			id_coordenada_02: '',
			id_coordenada_03: '',
			id_coordenada_04: '',
			id_coordenada_05: '',
			id_coordenada_06: '',
			id_coordenada_07: '',
			id_coordenada_08: '',
			id_coordenada_09: '',
			tipo_complemento: '',
			codigo_postal: '',
			direccion_residencia: '',
			pais: '',
			departamento: '',
			municipio: '',
			prestador_principal: '',
			punta_atencion: '',
			descricion_employer: '',
		},
		complemento: [
			{
				tipo_complemento: '',
				complemento: '',
			},
		],
	});
	const [attachments, setAttachments] = useState<any[]>([]);

	const submitData = () => {
		setFinalData((finalData) => [...finalData, userData]);
		setUserData([]);
	};

	return (
		<div>
			<multiStepContext.Provider
				value={{
					currentStep,
					setStep,
					firstUser,
					setFirstUser,
					firstCompany,
					setFirstCompany,
					userData,
					setUserData,
					finalData,
					setFinalData,
					submitData,
					data,
					setData,
					attachments,
					setAttachments,
				}}
			>
				<CompanyRegistration />
			</multiStepContext.Provider>
		</div>
	);
};

export default CompanyContext;
