import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';





import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import useInfoAdicionalParams from '../../hooks/parameters/company-aditional-info/useInfoAdicionalParams';
import {
    Grid, Snackbar, Alert,
    AlertTitle,
    FormHelperText,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
    Autocomplete,
    TextField,
    debounce,
    CircularProgress
} from '@mui/material';
import { DatePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getAcompanimentById, getConvocatoriaById, getDireccionamientoById, getListaDireccionamientos, getlistadoDeCandidatos, getListOfTrainingPrograms, getParametos, getTipos, postCrearDireccionamiento, postCreateAcompanamento, postCreateConvicatoria, updateAcompanmientoCandidato, updateConvocatoria, updateDireccionamiento } from '../../modules/apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikConfig, } from "formik";
// import { useAuth } from '../core/Auth';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import { useStaticPicker } from '@mui/x-date-pickers/internals';

// import { CounselorAreaWrapper } from '../../../pages/counselor/CounselorAreaWrapper';




const bussineFormInitial = {
    id_consejero_candidato: '',
    estado: '',
    fecha_registro: '',
    tipo: '',
    subtipo: '',
}





export const RegisterAddressing = () => {
    const { id, visible, idCandidato, nombreCandidato } = useParams();

    const { auth } = useAuth()



    // almacenamiento de conultas
    const [listOfTrainingPrograms, setListOfTrainigPrograms] = useState<[]>([])
    const [dataEdit, setDataEdit] = useState<any>()
    console.log("🔥 ~ RegisterAddressing ~ dataEdit:", dataEdit)

    const [selectedOption, setSelectedOption] = useState<any>(null);


    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');

    const [openModalDeleteAcompaniment, setOpenModalDeleteAcompaniment] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };
    const [options, setOptions] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleShowSnackbar = (type: boolean, message: string) => {
        setAlert(type);
        setAlertMsg(message);
        setOpenAlert(true);
    };




    const validationSchema = Yup.object({

        tipo_direccionamiento: Yup.string().required("El tipo de direccionamiento es obligatorio"),
        subtipo_direccionamiento: Yup.string().required("El subtipo de direccionamiento es obligatorio"),



    });




    const handleCloseModal = () => {
        setOpenAlert(false);
    };

    // Post crear una convocatoria
    const handleCrearDireccionamiento = async (data: any, resetForm: any) => {
        try {
            const response = await postCrearDireccionamiento(data);
            if (response) {
                setOpen(true);
                setAlert(true);
                handleCloseModal()
                setAlertMsg(`Direccionamiento creado con éxito!`);
                setTimeout(() => {
                    setOpen(false);
                }, 1500);
                resetForm();
            }
        } catch (error: any) {
            if (error.response) {
                setOpen(true);
                setAlert(false);
                handleCloseModal()
                setAlertMsg('Ocurrió un error al procesar la solicitud.');
                setTimeout(() => {
                    setOpen(false);
                }, 2500);
                resetForm();
                console.error(error.response.data.error);
            } else {
                console.error("Ocurrio un error procesando la solicitud.");
            }
        }
    };

    // Get Direccionamiento  by id
    const handleGetDireccionamientoById = async (id: any) => {
        try {
            const response = await getDireccionamientoById(id);
            if (response) {
                setOpen(true);
                setAlert(true);
                handleCloseModal()
                setAlertMsg('¡Informacion cargada con éxito!')
                setTimeout(() => {
                    setOpen(false);
                }, 1500);
                setDataEdit(
                    {
                        id: response.data.id,
                        id_consejero_candidato: response.data.id_consejero_candidato,
                        codigo_direccionamiento: response.data.codigo_direccionamiento,
                        tipo_direccionamiento: response.data.tipo_direccionamiento,
                        subtipo_direccionamiento: response.data.subtipo_direccionamiento,
                        fecha_registro: response.data.fecha_registro,
                        estado: response.data.estado,
                        num_personas_creadas: response.data.num_personas_creadas,
                        num_personas_orientadas:response.data.num_personas_orientadas,
                        nombreConsejero: `${response.data.consejeroCandidato.UserComplemento.primer_nome} ${response.data.consejeroCandidato.UserComplemento.primer_apelido}`,
                        tipo: response.data.tipoDireccionamiento.label,
                        subtipo: response.data.subtipoDireccionamiento.label,
                    }
                )
            }
        } catch (error: any) {
            if (error.response) {
                handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
                console.error(error.response.data.error);
            } else {
                console.error("Ocurrio un error procesando la solicitud.");
            }
        }
    }

    // acctualizar el Direccionamiento
    const handleUpdateDireccionamiento = async (data: any, id: any) => {
        try {
            const response = await updateDireccionamiento(data, id);
            if (response) {
                setOpen(true);
                setAlert(true);
                handleCloseModal()
                setAlertMsg('¡Direccionamiento actualizado con éxito!')
                setTimeout(() => {
                    setOpen(false);
                }, 2500);

            } else {
                handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
            }
        } catch (error: any) {
            if (error.response) {
                setOpen(true);
                setAlert(false);
                handleCloseModal()
                setAlertMsg('Ocurrió un error al procesar la solicitud.');
                setTimeout(() => {
                    setOpen(false);
                }, 2500);
                console.error(error.response.data.error);
            } else {
                console.error("Ocurrio un error procesando la solicitud.");
            }
        }
    };


    useEffect(() => {
        if (id) {
            handleGetDireccionamientoById(id)
        }
        // if (idCandidato) {
        //     setDataEdit({
        //         id_user: idCandidato,
        //     })
        // }
    }, [])







    const [tipoDeDireccionamiento, setTipoDeDireccionamiento] = useState<any[]>([]);
    const [subTipoDeDireccionamiento, setSubTipoDeDireccionamiento] = useState<any[]>([]);


    // Get de los parametros de  Direccionamientos
    const handleGetParametros = async () => {
        setLoading(true);

        try {
            // Primer llamado con "TIPO_DIRECCIONAMIENTO"
            const resTipo: any = await getParametos("TIPO_DIRECCIONAMIENTO"); // Usa `any`
            setTipoDeDireccionamiento(resTipo.data)

            // Segundo llamado con "SUBTIPO_DIRECCIONAMIENTO"
            const resSubtipo: any = await getParametos("SUBTIPO_DIRECCIONAMIENTO"); // Usa `any`
            setSubTipoDeDireccionamiento(resSubtipo.data); // Guarda solo el array de `data`
        } catch (error: any) {
            console.error("Error obteniendo parámetros:", error.response?.data?.error || error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        handleGetParametros()
    }, [])


    return (
        <Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
            <Box sx={{ width: '95%', }}>
                <Box>
                    <div className='row mb-5'>
                        <div className='col-sm-12'>
                            <div className='input-group'>
                                <div
                                    className='col-sm-1'
                                    style={{
                                        display: 'contents',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <BackButton />
                                </div>
                                <div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
                                    <h2 style={{ display: 'flex', gap: '10px' }}>{visible ? 'Información del Direccionamiento / Orientación.' : 'Gestion de Direccionamiento / Orientación'}</h2>
                                    <span style={{}}>{visible ? 'Estos son los campos con la información del direccionamiento / Orientación.' : 'Le agradecemos que diligencie los siguientes campos.'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>

            <Box mt={5} sx={{ width: '95%', }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        ...bussineFormInitial,
                        ...dataEdit,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        const fechaRegistro = values.fecha_registro || dayjs().toISOString();

                        const data = {
                            id_consejero_candidato: id ? dataEdit?.id_consejero_candidato : auth?.user?.id,
                            tipo_direccionamiento: values.tipo_direccionamiento,
                            subtipo_direccionamiento: values.subtipo_direccionamiento,
                            fecha_registro: fechaRegistro,
                        };

                        if (id) {
                            handleUpdateDireccionamiento(data, id);
                        } else {
                            handleCrearDireccionamiento(data, resetForm);
                        }
                    }}
                >
                    {({ setFieldValue, setFieldTouched, values, errors, touched, handleBlur, handleChange }) => (
                        <Form>
                            <Grid container direction="row" width={'100%'}>
                                {/* Codigo de direccionamiento */}
                                {id && (
                                    <Grid lg={4} xs={12} md={6} mb={5} pr={2} item={true}>
                                        <TextField

                                            disabled={visible ? true : false}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="outlined-disabled"
                                            label="Código de direccionamiento"
                                            value={dataEdit?.codigo_direccionamiento?.toUpperCase()}
                                        />
                                    </Grid>
                                )}
                                {/* Tipo de direccionamiento */}
                                <Grid lg={4} xs={12} md={6} mb={5} pr={2} item={true}>
                                    <Autocomplete
                                        disabled={visible ? true : false}
                                        options={tipoDeDireccionamiento}
                                        getOptionLabel={(option: any) => option.label || ""}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.label}
                                            </li>
                                        )}
                                        onChange={(event, value) => {
                                            setFieldValue("tipo", value?.label || "");
                                            setFieldValue("tipo_direccionamiento", value?.id || "");
                                        }}
                                        value={tipoDeDireccionamiento.find((option: any) => option.id === values.tipo_direccionamiento) || null}
                                        loading={loading}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label="Tipo de direccionamiento"
                                                error={touched.tipo_direccionamiento && !!errors.tipo_direccionamiento}
                                                helperText={touched.tipo_direccionamiento && errors.tipo_direccionamiento}
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    />
                                </Grid>


                                {/* Subtipo de direccionamiento */}
                                <Grid lg={4} xs={12} md={6} mb={5} pr={2} item={true}>
                                    <Autocomplete
                                        disabled={visible ? true : false}
                                        options={subTipoDeDireccionamiento}
                                        getOptionLabel={(option: any) => option.label || ""}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.label}
                                            </li>
                                        )}
                                        onChange={(event, value) => {
                                            setFieldValue("subtipo", value?.label || "");
                                            setFieldValue("subtipo_direccionamiento", value?.id || "");
                                        }}
                                        value={subTipoDeDireccionamiento.find((option: any) => option.id === values.subtipo_direccionamiento) || null}
                                        loading={loading}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label="Subtipo de direccionamiento"
                                                error={touched.subtipo_direccionamiento && !!errors.subtipo_direccionamiento}
                                                helperText={touched.subtipo_direccionamiento && errors.subtipo_direccionamiento}
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* Campo DatePicker */}
                                <Grid lg={4} xs={12} md={3} mb={5} pr={2} item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                        adapterLocale="es"
                                    >
                                        <FormControl sx={{ minWidth: '100%' }} size="medium">
                                            <DatePicker
                                                disabled={visible ? true : false}
                                                disablePast
                                                format="DD/MM/YYYY"
                                                value={values.fecha_registro ? dayjs(values.fecha_registro) : dayjs()}
                                                onChange={(date) => setFieldValue('fecha_registro', date ? dayjs(date).toISOString() : null)}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        id: 'fecha',
                                                        label: 'Fecha de registro',
                                                        variant: 'outlined',
                                                        error: !!(touched.fecha && errors.fecha),
                                                        helperText: touched.fecha && errors.fecha ? <>{errors.fecha}</> : '',
                                                    },
                                                    actionBar: { actions: ['clear', 'cancel', 'today'] },
                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>
                                </Grid>

                                {/* Consejero candidato */}
                                <Grid lg={4} xs={12} md={6} mb={5} pr={2} item>
                                    <TextField
                                        disabled={visible ? true : false}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="outlined-disabled"
                                        label="Consejero candidato"
                                        value={id ? dataEdit?.nombreConsejero : auth?.user?.nick}
                                    />
                                </Grid>

                                {/* Estado */}
                                {id && (
                                    <Grid lg={4} xs={12} md={6} mb={5} pr={2} item>
                                        <TextField
                                            disabled={visible ? true : false}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="outlined-disabled"
                                            label="Estado"
                                            value={dataEdit?.estado}
                                        />
                                    </Grid>
                                )}

                                {/* Número de personas orientadas */}
                                {id && (
                                    <Grid lg={4} xs={12} md={6} mb={5} pr={2} item>
                                        <TextField
                                            disabled={visible ? true : false}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="outlined-disabled"
                                            label="Número de personas orientadas"
                                            // value={10}
                                            // value={dataEdit?.num_personas_creadas}
                                            value={dataEdit?.num_personas_orientadas}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {/* Botón de enviar */}
                            {!visible && (
                                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
                                        type="submit"
                                    >
                                        {id ? "Modificar" : "Crear"}
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>





                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        variant={'filled'}
                        severity={alert ? 'success' : 'error'}
                        sx={{ whiteSpace: 'pre-line' }}
                    >
                        {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                        {alertMsg}
                    </Alert>
                </Snackbar>

            </Box>

        </Box >
    );
};
