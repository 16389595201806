/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import CurriculumContext from '../contexts/CurriculumContext';
import { RoleEnum } from '../models/enums/RoleEnum';
import VacancyContext from '../contexts/VacancyContext';
import CompanyContext from '../contexts/CompanyContext';
import { PublicVacanciesWrapper } from '../pages/public/PublicVacanciesWrapper';
import { PublicForgotPasswordWrapper } from '../pages/public/PublicForgotPasswordWrapper';
import StepContext from '../hooks/StepContext';
import VacancyComponent from '../components/Vacancy/Index';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
	const { currentUser } = useAuth();

	const IndexPage = () => {		
		const hasCompany = currentUser?.user?.empresa_id ? true : false;
		switch (currentUser?.user?.role) {
			case RoleEnum.ADMIN:
				//Solucion temporal al problema de clic en logos y redireccion a admin area cuando es se creo una empresa con rol administrador
				return hasCompany ? '/empleador/area' : '/admin/area';
			case RoleEnum.CANDIDATE:
				return '/candidato/area';
			case RoleEnum.COUNSELOR_CADIDATE:
				return '/consejero/area';
			case RoleEnum.COUNSELOR_EMPLOYER:
				return '/consejero/area';
			case RoleEnum.EMPLOYER:
				return '/empleador/area';
			default:
				return '/auth';
		}
	};

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path='error/*' element={<ErrorsPage />} />
					<Route path='logout' element={<Logout />} />
					{currentUser ? (
						<>
							<Route
								path='/*'
								element={
									<>
										<PrivateRoutes />
									</>
								}
							/>
							{/* TODO: HM - SE O USUÁRIO ACESSAR O INDEX DA PAGINA, REDIRECIONAR PARA A AREA-DEL */}
							<Route index element={<Navigate to={IndexPage()} />} />
							{/* TODO: HM - REMOVER ESSES TRES LINKS APOS O RENATO TERMINAR */}
							<Route
								path='cadastro'
								element={
									<>
										<CurriculumContext />
									</>
								}
							/>
							<Route
								path='gestion-de-vacante'
								element={
									<>
										<VacancyContext />
									</>
								}
							/>
							<Route
								path='registro-de-empresa'
								element={
									<>
										<CompanyContext />
									</>
								}
							/>
							{/* <Route
								path='registro-de-empresa/:id'
								element={
									<>
										<CompanyEditContext />
									</>
								}
							/> */}
							<Route path='postulate' element={<PublicVacanciesWrapper />} />
							<Route
								path='registration'
								element={
									<>
										<StepContext />
									</>
								}
							/>
							<Route
								path='vacante/:id'
								element={
									<>
										<VacancyComponent />
									</>
								}
							/>
						</>
					) : (
						<>
							{/* TODO: HM - COLOCAR AQUI URLS QUE NÃO PRECISAM DE AUTENTICACAO */}
							<Route path='auth/*' element={<AuthPage />} />
							<Route path='olvide-contrasena' element={<PublicForgotPasswordWrapper />} />
							<Route path='cadastro' element={<CurriculumContext />} />
							<Route path='registro-de-empresa' element={<CompanyContext />} />
							<Route path='postulate' element={<PublicVacanciesWrapper />} />
							<Route path='*' element={<Navigate to='/auth' />} />
							<Route path='registration' element={<StepContext />} />
							<Route path='vacante/:id' element={<VacancyComponent />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
