import { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	TextField,
	InputAdornment,
	IconButton,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
	FormControl,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { Form, Formik } from 'formik';
import { object, string, ref } from 'yup';
import {
	formatMaskPhoneNumber12Digits,
	regExpPassword,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	isValidEmail,
} from '../../../../../util/Index';
import { getVerifyEmailExists } from '../../../core/_requests';

export const Step2 = () => {
	const { setStep, firstUser, setFirstUser, setLoading } = useContext(multiStepContext);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
	const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const [initialValues, setInitialValues] = useState({
		complemento: {
			celular: '',
		},
		user: {
			email: '',
			confirma_email: '',
			password: '',
			confirma_password: '',
		},
	});

	useEffect(() => {
		setInitialValues(firstUser);
	}, []);


	const handleNext = async (values: any, setErrors: any) => {

		const exists = await verifyEmailExists(values.user.email, setErrors);

		if (!exists) {
			setFirstUser({
				...firstUser,
				complemento: {
					...firstUser.complemento,
					celular: values.complemento.celular,
					correo: values.user.email,
				},
				user: {
					...firstUser.user,
					email: values.user.email,
					confirma_email: values.user.confirma_email,
					password: values.user.password,
					confirma_password: values.user.confirma_password,
				},
			});
			setStep(3);
		}
	};

	const verifyEmailExists = async (email: string, setErrors: any) => {
		try {
			setLoading(true);
			const response = await getVerifyEmailExists(`email=${email}`);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setOpen(true);
				setErrors({
					user: {
						email: error.response.data.error,
						confirma_email: error.response.data.error,
					},
				});
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			return true;
		} finally {
			setLoading(false);
		}
	};
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const handleBefore = (values: any) => {
		setFirstUser({
			...firstUser,
			complemento: {
				...firstUser.complemento,
				celular: values.complemento.celular,
				correo: values.user.email,
			},
			user: {
				...firstUser.user,
				email: values.user.email,
				confirma_email: values.user.confirma_email,
				password: values.user.password,
				confirma_password: values.user.confirma_password,
			},
		});
		setStep(1);
	};
	// debe tener como mínimo 8 dígitos con al menos 1 carácter especial y una letra mayúscula
	return (
		<Box
			sx={{
				'& .MuiTextField-root': { m: 2, width: '95%' },
			}}
		>
			<Formik
				validationSchema={object({
					complemento: object({
						celular: string()
							//.transform((value) => value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', ''))
							//.matches(regExpPhoneNumber12Digits(),'Debe ser un celular válido (DDI + DDD + numero celular)')
							.test('isValidColombianPhoneNumber', (val) => helperIsValidColombianPhoneNumber(val, true), (val) => { return isValidColombianPhoneNumber(val, true) })
							.required('Campo requerido'),
					}),
					user: object({
						email: string()
							.required('Campo requerido')
							.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
								return isValidEmail(value || '')
							})
							.max(80, 'Máximo 80 caracteres'),
						confirma_email: string()
							.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
								return isValidEmail(value || '')
							})
							.oneOf(
								[ref('email'), null],
								'El correo electrónico de confirmación debe ser el mismo que el ingresado'
							)
							.required('Campo requerido'),
						password: string()
							.required('Campo requerido')
							.min(8, 'Contraseña debe tener como mínimo 8 dígitos')
							.max(50, 'Contraseña debe tener como máximo 50 dígitos')
							.matches(
								regExpPassword(),
								`Contraseña debe tener al menos: un carácter especial, una letra minúscula, una letra mayúscula y un número`
							),
						confirma_password: string()
							.oneOf(
								[ref('password'), null],
								'Confirmar contraseña debe ser la misma que la contraseña ingresada'
							)
							.required('Campo requerido'),
					}),
				})}
				initialValues={initialValues}
				validateOnBlur
				validateOnMount
				enableReinitialize
				initialTouched={{
					complemento: {
						celular: true,
					},
					user: {
						email: true,
						confirma_email: true,
						password: true,
						confirma_password: true,
					},
				}}
				onSubmit={() => { }}
			>
				{({
					errors,
					values,
					setFieldValue,
					handleChange,
					isValid,
					setErrors,
				}) => (
					<Form autoComplete='off'>
						<Grid container direction='row' justifyContent='space-between' width={'100%'}>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(values.complemento.celular, true)}>
									<TextField
										autoComplete='off'
										fullWidth
										required
										name='complemento.celular'
										label='Celular'
										size='medium'
										onChange={(e) => {
											// let x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{2})(\d{3})(\d{7})/);
											// if (x !== null) e.currentTarget.value = '+' + x[1] + '-' + x[2] + '-' + x[3];
											e.currentTarget.value = formatMaskPhoneNumber12Digits(e.currentTarget.value);
											setFieldValue('complemento.celular', e.currentTarget.value);
										}}
										onBlur={() => handleBlur('telefono')}
										value={values.complemento.celular}
										error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(values.complemento.celular, true)}
										helperText={touchedFields.includes('telefono') ? helperIsValidColombianPhoneNumber(values.complemento.celular, true) : ''}
										inputProps={{
											autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
											maxLength: 10,
										}}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('email') && Boolean(errors.user?.email)}>
									<TextField
										fullWidth
										required
										name='user.email'
										label='Correo electrónico'
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('email')}
										value={values.user.email}
										error={touchedFields.includes('email') && Boolean(errors.user?.email)}
										helperText={touchedFields.includes('email') ? errors.user?.email : ''}
										inputProps={{
											autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
											maxLength: 80
										}}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('confirma_email') && Boolean(errors.user?.confirma_email)}>
									<TextField
										fullWidth
										required
										name='user.confirma_email'
										label='Confirmar correo electrónico'
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('confirma_email')}
										value={values.user.confirma_email}
										error={touchedFields.includes('confirma_email') && Boolean(errors.user?.confirma_email)}
										helperText={touchedFields.includes('confirma_email') ? errors.user?.confirma_email : ''}
										inputProps={{
											autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
											maxLength: 80
										}}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('password') && Boolean(errors.user?.password)}>
									<TextField
										autoComplete='off'
										fullWidth
										required
										type={showPassword ? 'text' : 'password'}
										name='user.password'
										label='Contraseña'
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('password')}
										value={values.user.password}
										error={touchedFields.includes('password') && Boolean(errors.user?.password)}
										helperText={touchedFields.includes('password') ? errors.user?.password : ''}
										inputProps={{ maxLength: 50 }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('confirma_password') && Boolean(errors.user?.confirma_password)}>
									<TextField
										autoComplete='off'
										fullWidth
										required
										type={showConfirmPassword ? 'text' : 'password'}
										name='user.confirma_password'
										label='Confirmar contraseña'
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('confirma_password')}
										value={values.user.confirma_password}
										error={touchedFields.includes('confirma_password') && Boolean(errors.user?.confirma_password)}
										helperText={touchedFields.includes('confirma_password') ? errors.user?.confirma_password : ''}
										inputProps={{ maxLength: 50 }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle confirm password visibility'
														onClick={handleClickShowConfirmPassword}
														onMouseDown={handleMouseDownConfirmPassword}
													>
														{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'end',
								pt: 0,
							}}
						>
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleBefore(values)}
							>
								Atrás
							</Button>
							{/* <Box sx={{ flex: '1 1 auto' }} /> */}
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleNext(values, setErrors)}
								disabled={!isValid}
							>
								Siguiente
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</Box>
	);
};
