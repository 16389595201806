import { useContext, useEffect, useState } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import {
	formatMaskPhoneNumber12Digits,
	isValidFieldRequired,
	matchEmail,
	isValidFirstSecondNameFirstSecondLastName,
	helperValidFirstSecondNameFirstSecondLastName,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	eventOnKeyDownInputNumber,
	helperIsValidDocumentNumber,
	isValidDocumentNumber
} from '../../../../../util/Index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getVerifyDocumentExists, getVerifyEmailExists } from '../../../core/_requests';
import useDocumentTypeParams from '../../../../../hooks/parameters/useDocumentTypeParams';
import useAdminPositioTypeParams from '../../../../../hooks/parameters/useAdminPositionTypeParams';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const Step3 = () => {
	const { setStep, userData, setUserData, firstUser, setFirstUser, firstCompany, setFirstCompany } =
		useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [validationsDocuments, setValidationsDocuments] = useState<string[]>([]);

	const [documentExist, setDocumentExists] = useState(false);
	const [emailExists, setEmailExists] = useState(false);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');
	const { listTipoDocumentoRepresentanteLegal } = useDocumentTypeParams();
	const { cargoAdministradorList } = useAdminPositioTypeParams();

	useEffect(() => {
		validationSchemaByTpDoc(firstUser.complemento['tipo_doc']);
	}, []);

	const handleClose = () => {
		setOpen(false);
	};


	const isValidEmail = (e: string): boolean => {
		if (e?.match(matchEmail())) return true;

		return false;
	};

	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const handleNext = async () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];
		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true)) {
			errors.push({ field: 'Primer Nombre', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true) });
			camposError.push('primer_nome');
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nombre'], false)) {
			errors.push({ field: 'Segundo Nombre', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nombre'], true) });
			camposError.push('segundo_nombre');
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true)) {
			errors.push({ field: 'Primer Apellido', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true) });
			camposError.push('primer_apelido');
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false)) {
			errors.push({ field: 'Segundo Apellido', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false) });
			camposError.push('segundo_apelido');
		}

		if (!isValidFieldRequired(firstUser.complemento['tipo_doc'])) {
			errors.push({ field: 'Tipo de documento', error: 'Campo requerido' });
			camposError.push('tipo_documento');
		}

		if (!isValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true)) {
			errors.push({ field: 'Número de documento', error: helperIsValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) });
			camposError.push('numero_doc');
		}

		if (!isValidFieldRequired(firstUser.user['email'])) {
			errors.push({ field: 'Correo electrónico', error: 'Campo requerido' });
			camposError.push('correo_electronico');
		}

		else if (!isValidEmail(firstUser.user['email'])) {
			errors.push({ field: 'Correo electrónico', error: 'Correo electrónico no es válido' });
			camposError.push('correo_electronico');
		}

		if (isValidEmail(firstUser.user['email']) && !(firstUser.user['confirma_email'] === firstUser.user['email'])) {
			errors.push({ field: 'Confirmar correo electrónico', error: 'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente' });
			camposError.push('correo_electronico_confirma');
		}

		if (!isValidColombianPhoneNumber(firstUser.complemento['celular'], true)) {
			errors.push({ field: 'Teléfono', error: helperIsValidColombianPhoneNumber(firstUser.complemento['celular'], true) });
			camposError.push('telefono');
		}

		if (!isValidFieldRequired(firstUser.complemento['cargo'])) {
			errors.push({ field: 'Cargo', error: 'Campo requerido' });
			camposError.push('cargo');
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		}
		let existsDocument = false;
		let existsEmail = false;
		if (isValidFieldRequired(firstUser.complemento['numero_doc']))
			existsDocument = await verifyDocumentExists(firstUser.complemento['numero_doc']);
		if (isValidFieldRequired(firstUser.user['email']) && isValidEmail(firstUser.user['email']))
			existsEmail = await verifyEmailExists(firstUser.user['email']);

		if (!existsDocument && !existsEmail) {
			//Se garantiza el mapeo de información del correo en el modelo de UserComplemento
			setFirstUser({
				...firstUser,
				complemento: { ...firstUser.complemento, correo: firstUser.user['email'] },
			})
			setStep(4);
			return;
		} else {
			setAlert(false);
			setAlertMsg(
				`\n${validationsDocuments.map((x) => {
					return `\n${x}`;
				})}`
			);
			setOpen(true);
			setValidationsDocuments([]);
			return;
		}
	};

	/**
	 * Verifica la existencia del numero de documento
	 * @param documentNumber 
	 * @returns 
	 */
	const verifyDocumentExists = async (documentNumber: string) => {
		try {
			const response = await getVerifyDocumentExists(`document=${documentNumber}`);
			setDocumentExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				validationsDocuments.push(error.response.data.error);
				setDocumentExists(true);
				return true;
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
				return true;
			}
		}
	};

	/**
	 * Llamado al servicio que valida la existencia del correo electronico
	 * @param email 
	 * @returns 
	 */
	const verifyEmailExists = async (email: string) => {
		try {
			const response = await getVerifyEmailExists(`email=${email}`);
			setEmailExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				validationsDocuments.push(error.response.data.error);
				setEmailExists(true);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			return true;
		}
	};

	//validation for candidate document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			case 'CC':
				//CC: entre 3 a 8 dígitos y 10 a 11 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				break;
			/*case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;*/
			case 'CE':
				//CE: 1 a 7 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(7);
				break;
			case 'PA':
				//PA: entre 1 a 17 caracteres alfanuméricos (acepta número y letras)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(17);
				break;
			case 'PEP':
				//PEP: 15 y 20 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(20);
				break;
			case 'PT':
				//PPT: El documento debe ser numérico y contener entre 4 y 7 dígitos
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					Datos del administrador de la cuenta en esta plataforma
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('primer_nome') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true)}>
						<TextField
							fullWidth
							required
							id='primer_nombre'
							name='primer_nombre'
							label='Primer nombre'
							value={firstUser.complemento['primer_nome']}
							inputProps={{ minLength: 2, maxLength: 40 }}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, primer_nome: e.target.value },
								})
							}
							onBlur={() => handleBlur('primer_nome')}
							size='medium'
							error={touchedFields.includes('primer_nome') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true)}
							helperText={touchedFields.includes('primer_nome') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true) : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('segundo_nombre') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nome'])}>
						<TextField
							fullWidth
							id='segundo_nombre'
							name='segundo_nombre'
							label='Segundo nombre'
							value={firstUser.complemento['segundo_nome']}
							inputProps={{ maxLength: 40 }}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, segundo_nome: e.target.value },
								})
							}
							onBlur={() => handleBlur('segundo_nombre')}
							size='medium'
							error={touchedFields.includes('segundo_nombre') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nome'])}
							helperText={touchedFields.includes('segundo_nombre') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nome']) : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('primer_apelido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true)}>
						<TextField
							fullWidth
							required
							id='primer_apelido'
							name='primer_apelido'
							label='Primer apellido'
							value={firstUser.complemento['primer_apelido']}
							inputProps={{ minLength: 2, maxLength: 40 }}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, primer_apelido: e.target.value },
								})
							}
							onBlur={() => handleBlur('primer_apelido')}
							size='medium'
							error={touchedFields.includes('primer_apelido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true)}
							helperText={touchedFields.includes('primer_apelido') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true) : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('segundo_apelido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false)}>
						<TextField
							fullWidth
							id='segundo_apelido'
							name='segundo_apelido'
							label='Segundo apellido'
							value={firstUser.complemento['segundo_apelido']}
							inputProps={{ maxLength: 40 }}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, segundo_apelido: e.target.value },
								})
							}
							onBlur={() => handleBlur('segundo_apelido')}
							size='medium'
							error={touchedFields.includes('segundo_apelido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false)}
							helperText={touchedFields.includes('segundo_apelido') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false) : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstUser.complemento['tipo_doc'])}
					>
						<InputLabel id='tipo_documento' required>Tipo de documento</InputLabel>
						<Select
							required
							labelId='tipo_documento'
							id='tipo_documento'
							label='Tipo de documento'
							size='medium'
							value={firstUser.complemento['tipo_doc']}
							onChange={(e) => {
								firstUser.complemento['numero_doc'] = '';
								validationSchemaByTpDoc(e.target.value);
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, tipo_doc: e.target.value },
								});
							}}
							onBlur={() => handleBlur('tipo_documento')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listTipoDocumentoRepresentanteLegal.map(({ code, value }) => (
								<MenuItem value={code} key={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstUser.complemento['tipo_doc']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstUser.complemento['tipo_doc']) ? 'Campo requerido' : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('numero_doc') && !isValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) || documentExist}>
						<TextField
							fullWidth
							required
							id='numero_documento'
							name='numero_documento'
							label='Número de documento'
							value={firstUser.complemento['numero_doc']}
							onKeyUp={() => setDocumentExists(false)}
							onChange={(e) => {
								if (e.target.value.length <= maxDocumentFieldLenght)
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, numero_doc: e.target.value },
									});
							}}
							onBlur={() => handleBlur('numero_doc')}
							type={documentTypeField}
							size='medium'
							inputProps={{
								maxLength: maxDocumentFieldLenght,
								minLength: minDocumentFieldLenght,
								readOnly: !isValidFieldRequired(firstUser.complemento['tipo_doc'])
							}}
							onKeyDown={documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen 
							error={touchedFields.includes('numero_doc') && !isValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) || documentExist}
							helperText={
								touchedFields.includes('numero_doc') && !isValidFieldRequired(firstUser.complemento['tipo_doc']) ? 'Primero seleccione el tipo de documento'
									:
									(
										documentExist ? 'Número de documento ya registrado!' : (touchedFields.includes('numero_doc') ? helperIsValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) : '')
									)
							}
						/>
					</FormControl>
				</Grid>{' '}
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={touchedFields.includes('cargo') && !isValidFieldRequired(firstUser.complemento['cargo'])}
					>
						<InputLabel id='cargo' required>Cargo</InputLabel>
						<Select
							required
							labelId='cargo'
							id='cargo'
							label='Cargo'
							value={firstUser.complemento['cargo']}
							onChange={(e) => {
								firstUser.complemento['cargo'] = '';
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, cargo: e.target.value },
								});
							}}
							onBlur={() => handleBlur('cargo')}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{cargoAdministradorList.map(({ code, value }) => (
								<MenuItem value={code} key={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('cargo') && !isValidFieldRequired(firstUser.complemento['cargo']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstUser.complemento['cargo']) ? 'Campo requerido' : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<OverlayTrigger
						placement='right'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								{/* (Código de país + Código de area + Numero del teléfono) */}
							</Tooltip>
						)}
					>
						<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(firstUser.complemento['celular'], true)}>
							<TextField
								required
								fullWidth
								id='telefono'
								name='telefono'
								label='Teléfono'
								value={firstUser.complemento['celular']}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
										?.replaceAll('-', '')
										?.replaceAll('+', '');
									let reg = /[0-9]|\./;
									if (reg.test(val) || val === '') {
										setFirstUser({
											...firstUser,
											complemento: {
												...firstUser.complemento,
												celular: formatMaskPhoneNumber12Digits(val),
											},
										});
									}
								}}
								onBlur={() => handleBlur('telefono')}
								size='medium'
								error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(firstUser.complemento['celular'], true)}
								helperText={touchedFields.includes('telefono') ? helperIsValidColombianPhoneNumber(firstUser.complemento['celular'], true) : ''}
								InputProps={{
									/* endAdornment: (
										<InputAdornment position='end'>
											<i
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></i>
										</InputAdornment>
									), */
								}}
								inputProps={{
									maxLength: 10,
								}}
							/>
						</FormControl>
					</OverlayTrigger>
				</Grid>
				<Grid xs={12} md={6} mb={5} pr={2} item={true}>
					<OverlayTrigger
						placement='left'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								Este correo electrónico será el usuário de login
							</Tooltip>
						)}
					>
						<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('correo_electronico') && !isValidEmail(firstUser.user['email']) || emailExists}>
							<TextField
								required
								fullWidth
								id='correo_eletronico'
								name='correo_eletronico'
								label='Correo electrónico'
								value={firstUser.user['email']}
								onKeyUp={() => setEmailExists(false)}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										user: { ...firstUser.user, email: e.target.value },
									})
								}
								onBlur={() => handleBlur('correo_electronico')}
								size='medium'
								error={touchedFields.includes('correo_electronico') && !isValidEmail(firstUser.user['email']) || emailExists}
								helperText={touchedFields.includes('correo_electronico') && !isValidFieldRequired(firstUser.user['email']) ? 'Campo requerido' : !isValidEmail(firstUser.user['email']) ? 'Correo electrónico no es válido' : emailExists ? '¡El usuario ya registrado!' : ''
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<i
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></i>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 80 }}
							/>
						</FormControl>
					</OverlayTrigger>
				</Grid>
				<Grid xs={12} md={6} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium' error={
						touchedFields.includes('correo_electronico_confirma') &&
						firstUser.user['email'] !== undefined &&
						firstUser.user['email'] !== '' &&
						!(firstUser.user['confirma_email'] === firstUser.user['email'])
					}>
						<TextField
							required={isValidEmail(firstUser.user['email'])}
							fullWidth
							id='correo_eletronico_confirma'
							name='correo_eletronico_confirma'
							label='Confirmar correo electrónico'
							value={firstUser.user['confirma_email']}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									user: { ...firstUser.user, confirma_email: e.target.value },
								})
							}
							onBlur={() => handleBlur('correo_electronico_confirma')}
							size='medium'
							error={
								touchedFields.includes('correo_electronico_confirma') &&
								firstUser.user['email'] !== undefined &&
								firstUser.user['email'] !== '' &&
								!(firstUser.user['confirma_email'] === firstUser.user['email'])
							}
							helperText={
								touchedFields.includes('correo_electronico_confirma') &&
									firstUser.user['email'] !== undefined &&
									firstUser.user['email'] !== '' &&
									!(firstUser.user['confirma_email'] === firstUser.user['email'])
									? 'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente'
									: ''
							}
							inputProps={{ maxLength: 80 }}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(2)}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={handleNext}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
