/* eslint-disable react/jsx-no-target-blank */
// import { useIntl } from 'react-intl'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import * as authHelper from '../../../../app/modules/auth/core/AuthHelpers';
import { RoleEnum } from '../../../../app/models/enums/RoleEnum';

const AsideMenuMain = () => {
	const { user } = authHelper.getAuth() || {};
	const role = user?.role;
	const hasCompany = user?.empresa_id ? true : false;
	const disableMatching = (/true/).test(process.env.REACT_APP_DISABLE_MATCHING_COMPONENTS || '');


	const AsideMenuAdmin = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/admin'
				title='Administrador'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/admin' title='Menú Administrador' hasBullet={false}>
					<AsideMenuItem
						to='/admin/area'
						title='Área Administrador'
						bsTitle='Área Administrador'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/admin/gestion-registros'
						title='Gestión Usuarios'
						bsTitle='Gestión Usuarios'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/admin/gestion-empresas'
						title='Gestión Empresas'
						bsTitle='Gestión Empresas'
						hasBullet={true}
						outside={false}
					/>
					{/* <AsideMenuItem
						to='/admin/nuevo-usuario'
						title='Nuevo Usuario'
						bsTitle='Nuevo Usuario'
						hasBullet={true}
						outside={false}
					/> */}
					<AsideMenuItem
						to='/admin/gestion-vacantes'
						title='Gestión Vacantes'
						bsTitle='Gestión Vacantes'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/admin/gestion-candidatos'
						title='Gestión Candidatos'
						bsTitle='Gestión Candidatos'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItemWithSub to='/admin/dashboard' title='Dashboards' hasBullet={false}>
						<AsideMenuItem
							to='/admin/dashboard/candidatos'
							title='Candidatos'
							bsTitle='Candidatos'
							hasBullet={true}
							outside={false}
						/>
						<AsideMenuItem
							to='/admin/dashboard/empresas'
							title='Empresas'
							bsTitle='Empresas'
							hasBullet={true}
							outside={false}
						/>
						<AsideMenuItem
							to='/admin/dashboard/politicas'
							title='Politicas'
							bsTitle='Politicas'
							hasBullet={true}
							outside={false}
						/>
					</AsideMenuItemWithSub>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const AsideMenuCandidate = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/candidato'
				title='Candidato'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/candidato' title='Menú Candidato' hasBullet={false}>
					<AsideMenuItem
						to='/candidato/area'
						title='Área Candidato'
						bsTitle='Área Candidato'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to={`/candidato/crear-hoja-vida/${user?.id}`}
						title='Registrar Hoja de Vida'
						bsTitle='Registrar Hoja de Vida'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/candidato/citas-programadas'
						title='Citas Programadas'
						bsTitle='Citas Programadas'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/candidato/postuladas-vacantes'
						title='Vacantes Postuladas'
						bsTitle='Vacantes Postuladas'
						hasBullet={true}
						outside={false}
					/>
					{!disableMatching && (
						<AsideMenuItem
							to='/candidato/matching-vacantes'
							title='Vacantes recomendadas'
							bsTitle='Vacantes recomendadas'
							hasBullet={true}
							outside={false}
						/>
					)}
					<AsideMenuItem
						to='/candidato/search-vacantes'
						title='Buscar vacantes'
						bsTitle='Buscar vacantes'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItemWithSub to='/candidato/dashboard' title='Dashboards' hasBullet={false}>
						<AsideMenuItem
							to='/candidato/dashboard/candidato'
							title='Candidato'
							bsTitle='Candidato'
							hasBullet={true}
							outside={false}
						/>
					</AsideMenuItemWithSub>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const AsideMenuCounselorEmloyer = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/consejero'
				title='Consejero'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/consejero' title='Menú Consejero' hasBullet={false}>
					<AsideMenuItem
						to='/consejero/area'
						title='Área Consejero'
						bsTitle='Área Consejero'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/consejero/gestion-empresas'
						title='Gestión Empresas'
						bsTitle='Gestión Empresas'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/consejero/gestion-vacantes'
						title='Gestión Vacantes'
						bsTitle='Gestión Vacantes'
						hasBullet={true}
						outside={false}
					/>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const AsideMenuCounselorCandidate = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/consejero'
				title='Consejero'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/consejero' title='Menú Consejero' hasBullet={false}>
					<AsideMenuItem
						to='/consejero/area'
						title='Área Consejero'
						bsTitle='Área Consejero'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/consejero/gestion-candidatos'
						title='Gestión Candidatos'
						bsTitle='Gestión Candidatos'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/consejero/citas-programadas'
						title='Citas programadas'
						bsTitle='Citas programadas'
						hasBullet={true}
						outside={false}
					/>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const AsideMenuEmployer = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/empleador'
				title='Empleador'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/empleador' title='Menú Empleador' hasBullet={false}>
					<AsideMenuItem
						to='/empleador/area'
						title='Área Empleador'
						bsTitle='Área Empleador'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/empleador/gestion-vacantes'
						title='Gestión Vacantes'
						bsTitle='Gestión Vacantes'
						hasBullet={true}
						outside={false}
					/>
					<AsideMenuItem
						to='/empleador/gestion-candidatos/0'
						title='Gestión Candidatos'
						bsTitle='Gestión Candidatos'
						hasBullet={true}
						outside={false}
					/>
					{!disableMatching && (
						<AsideMenuItem
							to='/empleador/matching-candidatos'
							title='Matching Candidatos'
							bsTitle='Matching Candidatos'
							hasBullet={true}
							outside={false}
						/>
					)}
					<AsideMenuItemWithSub to='/empleador/dashboard' title='Dashboards' hasBullet={false}>
						<AsideMenuItem
							to='/empleador/dashboard/empresa'
							title='Empresa'
							bsTitle='Empresa'
							hasBullet={true}
							outside={false}
						/>
					</AsideMenuItemWithSub>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const AsideMenuProvisional = () => {
		return (
			<AsideMenuItemWithSubMain
				to='/empleador'
				title='Empleador'
				fontIcon='bi-file-text'
				bsTitle=''
			>
				<AsideMenuItemWithSub to='/empleador' title='Menú Empleador' hasBullet={false}>
					<AsideMenuItem
						to='/empleador/area'
						title='Área Empleador'
						bsTitle='Área Empleador'
						hasBullet={true}
						outside={false}
					/>
				</AsideMenuItemWithSub>
			</AsideMenuItemWithSubMain>
		);
	};

	const buildContent = () => {
		switch (role) {
			case RoleEnum.ADMIN:
				return hasCompany ? <AsideMenuEmployer /> : <AsideMenuAdmin />;
			case RoleEnum.CANDIDATE:
				return <AsideMenuCandidate />;
			case RoleEnum.COUNSELOR_CADIDATE:
				return <AsideMenuCounselorCandidate />;
			case RoleEnum.COUNSELOR_EMPLOYER:
				return <AsideMenuCounselorEmloyer />;
			case RoleEnum.EMPLOYER:
				return <AsideMenuEmployer />;
			case RoleEnum.PROVISIONAL:
				return <AsideMenuProvisional />;
			default:
				return <></>;
		}
	}

	return (
		<>
			{buildContent()}
		</>
	)

}

const MenuWrapper = () => {
	return (<AsideMenuMain />);
};

export { MenuWrapper };