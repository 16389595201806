import { FormEvent, useContext, useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	Button,
	Box,
	Divider,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import { createCurriculo, getBarriosByLocalidad } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import _ from 'lodash';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import {
	initValue,
	setValueOrDefault,
	isValidFieldRequired,
	eventOnKeyDownInputNumber,
	eventOnKeyDownInputAlphaNumeric,
	isValidAddress,
	isValidValueOnList
} from '../../../../../util/Index';

import useAddressParams from '../../../../../hooks/parameters/useAddressParams';
import parameterSingleton from '../../../../../services/Parameters.service';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';


export const Step2 = () => {
	const { paisList,
		departamentoList,
		otrosConocimientosList,
		zonaList,
		viaPrincipalBisList,
		listViaPrincipalCuadrante,
		listViaPrincipalPrimeraLetra,
		listViaPrincipal,
		listViaPrincipalComplementoDireccion,
		listPrestadorPreferencia,
		listPuntoAtencion,
		listLocalidades,
		loadingParams } = useAddressParams();
	const { setStep, userData, setUserData, setCurrentProgress, setLoading, loading } = useContext(multiStepContext);
	
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [blockMuni, setBlockMuni] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [dispComple, setDispComple] = useState('none');
	const [step, setStepUser] = useState<string>('step2');
	const [rowsComplementos, setRowsComplementos] = useState([
		{ id: 0, tipo_complemento: '', complemento: '' },
	]);
	const [ddlLocalidad, setddlLocalidad] = useState<any[]>([]);
	const [ddlMunicipioResidencia, setDdlMunicipioResidencia] = useState<any[]>([
		{ code_dpto: '0', code: '0', value: 'Seleccione' },
	]);

	const [ddlLocal, setDdlLocal] = useState([{ id: '', name: '' }]);


	useEffect(() => {
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			const listRowsComplementos = rowsComplementos.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsComplementos) {
				const list = [...rowsComplementos];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsComplementos(list);
			}
			///rowsComplementos.push(createObjectDirecaoAtuliza());
			setRowsComplementos(userData['complementoDirecao']);
		}

		if (userData['departamento_residencia']) {
			fetchAndSetMunicipioResidenciaById(userData['departamento_residencia']);
			setBlockMuni(false);
		} else {
			setDdlMunicipioResidencia([]);
		}
		if ((userData['pais_residencia'] === null ||
			userData['pais_residencia'] === undefined ||
			userData['pais_residencia'] === '' ||
			userData['pais_residencia'] === 'CO') && !userData['departamento_residencia']) {
			setUserData({
				...userData,
				pais_residencia: 'CO',
				departamento_residencia: '11',
				municipio_residencia: '11001',
			});
			fetchAndSetMunicipioResidenciaById('11');
			setBlockMuni(false);
		}


		//Cargar la informacion de barrios
		if (isValidFieldRequired(userData['localidad'])) {
			getAsyncBarriosByLocalidad(userData['localidad']);
		}

	}, []);

	useEffect(() => {
		if (listLocalidades.length > 0) {
			setddlLocalidad([...listLocalidades]);
			const list = [...ddlLocal];
			(list as Array<any>).forEach((d) => {
				ddlLocal.splice(_.findIndex(ddlLocal, { id: d['id'] }), 1);
			});
			const tempListddLocal: any[] = listLocalidades.map((e) => {
				let x = {
					id: setValueOrDefault(e['id_localidad'], 'number'),
					name: setValueOrDefault(e['desc_localidad'], 'string'),
				};
				return x;
			});
			setDdlLocal(tempListddLocal);
		}
	}, [listLocalidades])


	const fetchAndSetMunicipioResidenciaById = async (id: string) => {
		const municipioList =
			await parameterSingleton.fetchMunicipioById(id);
		setDdlMunicipioResidencia(municipioList);
	}

	const handleDelete = (id: number) => {
		const list = [...rowsComplementos];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsComplementos(list);
		if (list.length === 0) {
			setDispComple('none');
		}
	};

	const handleChange = (e: any) => {
		e.preventDefault();
		setRowsComplementos([
			...rowsComplementos,
			{
				id: rowsComplementos.length + 1,
				tipo_complemento: '',
				complemento: '',
			},
		]);
		setDispComple('block');
	};
	const handleChangeTipoComplemento = (e: any, index: any) => {
		rowsComplementos[index].tipo_complemento = e.target.value;
		setRowsComplementos([...rowsComplementos]);
	};
	const handleChangeComplemento = (e: any, index: any) => {
		rowsComplementos[index].complemento = e.target.value;
		setRowsComplementos([...rowsComplementos]);
	};
	const handleNext = () => {
		setUserData({ ...userData, complementoDirecao: rowsComplementos });
		setStep(3);
	};
	const handleRetorno = () => {
		setUserData({
			...userData,
			complementoDirecao: rowsComplementos,
			pais_residencia: '',
			departamento_residencia: '',
			municipio_residencia: '',
		});
		setStep(1);
	};

	const strDirection = (val: any) => {
		return !isValidFieldRequired(val) ? '' : val;
	};

	const handleDirectionValue = () => {
		let val =
			strDirection(userData['local']) +
			' ' +
			strDirection(userData['id_coordenada']) +
			' ' +
			strDirection(userData['id_coordenada_02']) +
			' ' +
			strDirection(userData['id_coordenada_03']) +
			' ' +
			strDirection(userData['id_coordenada_04']) +
			' ' +
			strDirection(userData['id_coordenada_05']) +
			' ' +
			strDirection(userData['id_coordenada_06']) +
			' ' +
			strDirection(userData['id_coordenada_07']) +
			' ' +
			strDirection(userData['id_coordenada_08']) +
			' ' +
			strDirection(userData['id_coordenada_09']) +
			' ';

		if (
			rowsComplementos !== null &&
			rowsComplementos !== undefined &&
			(rowsComplementos as Array<any>).length > 0
		) {

			if (userData['complemento'] && userData['complemento'].length > 0 && isValidFieldRequired(userData['complemento'][0].tipo_complemento)) {
				val = val + ', ';
			}

			(rowsComplementos as Array<any>).forEach((e) => {
				let x =
					strDirection(setValueOrDefault(e['tipo_complemento'], 'string')) +
					' ' +
					strDirection(setValueOrDefault(e['complemento'], 'string')) +
					' ';
				val = val + x;
			});
		}
		const direccionResidencia = val.trimEnd() === '' ? '' : val.trimEnd() + '';
		if (isValidFieldRequired(userData['direccion_residencia']) && !isValidFieldRequired(direccionResidencia)) {
			return userData['direccion_residencia'];
		} else if (direccionResidencia !== userData['direccion_residencia']) {
			setUserData({ ...userData, direccion_residencia: direccionResidencia })
			return direccionResidencia;
		} else if (direccionResidencia === userData['direccion_residencia']) {
			return direccionResidencia;
		} else {
			return '';
		}
	};

	const onChangeDepartamento = (e: SelectChangeEvent) => {
		const departamento_residencia = e.target.value;
		if (isValidFieldRequired(departamento_residencia)) {
			fetchAndSetMunicipioResidenciaById(e.target.value);
			setBlockMuni(false);

		} else {
			setDdlMunicipioResidencia([]);
		}
		setUserData({
			...userData,
			departamento_residencia: departamento_residencia,
			municipio_residencia: '',
			localidad: '',
			latitud: 0,
			longitud: 0,
			bairro_residencia: '',
			pertence: '',
			prestador_preferencia: '',
			punta_atencion: ''
		});
	};

	//Lista de barrios
	const [ddlBarrio, setDdlBarrio] = useState([
		{ id_barrio: 0, nombre: 'Seleccione' },
	]);

	/**
	 * Realiza el llamado al servicio de consulta de barrios por localidad
	 */
	async function getAsyncBarriosByLocalidad(localidad: any) {
		try {
			setLoading(true);
			const id_localidad = Number(localidad)
			const response = await getBarriosByLocalidad(id_localidad);
			var results: { id_barrio: any, nombre: any }[] = [];
			(response.data as Array<any>).forEach((e) => {
				let x = {
					id_barrio: setValueOrDefault(e['id_barrio'], 'number'),
					nombre: setValueOrDefault(e['nombre'], 'string'),
				};
				results.push(x);
			});
			setDdlBarrio(results);
		} catch (error: any) {
			if (error.response) {
				console.log(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	}

	//Metodo que controla el cambio de la localidad y pre carga los valores de barrios
	const onChangeLocalidad = (e: SelectChangeEvent) => {
		const localidad = e.target.value;
		if (localidad !== '') {
			setDdlBarrio([]);
			//Llamado metodo asincrono carga de barrios
			getAsyncBarriosByLocalidad(localidad);
		} else {
			setDdlBarrio([]);
		}
		setUserData({
			...userData,
			localidad: localidad,
			bairro_residencia: '',
		});
	}

	const longitudmaximaDireccion = 500;
	const mensajeErrorLongitudMaximaDireccion = `La dirección no puede ser superior a ${longitudmaximaDireccion} caracteres`;

	const handleValidOnSubmit = () => {

		let errors: { field: string; error: string }[] = [];

		let camposError: string[] = [];

		if (!isValidFieldRequired(userData['pais_residencia'])) {
			errors.push({ field: 'País de residencia', error: 'Campo requerido' });
			camposError.push('pais_residencia');
		}

		else if (userData['pais_residencia'] === 'CO') {
			if (!isValidFieldRequired(userData['departamento_residencia'])) {
				errors.push({ field: 'Departamento de residencia', error: 'Campo requerido' });
				camposError.push('departamento_residencia');
			}

			if (!isValidFieldRequired(userData['municipio_residencia'])) {
				errors.push({ field: 'Municipio de residencia', error: 'Campo requerido' });
				camposError.push('municipio_residencia');
			}
			//Departamento: Bogotá D.C y Municipio: Bogotá D.C
			if (userData['departamento_residencia'] === '11' && isValidFieldRequired(userData['municipio_residencia'] == '11001')) {
				if (!isValidValueOnList(userData['localidad'], ddlLocal, 'id')) {
					errors.push({ field: 'Localidad', error: 'Campo requerido' });
					camposError.push('localidad');
				}
				if (!isValidValueOnList(userData['bairro_residencia'], ddlBarrio, 'id_barrio')) {
					errors.push({ field: 'Barrio', error: 'Campo requerido' });
					camposError.push('barrio_residencia');
				}
				if (!isValidFieldRequired(userData['prestador_preferencia'])) {
					errors.push({ field: 'Prestador de preferencia', error: 'Campo requerido' });
					camposError.push('prestador_preferencia');
				}
				if (!isValidFieldRequired(userData['punta_atencion'])) {
					errors.push({ field: 'Punto de atención', error: 'Campo requerido' });
					camposError.push('punta_atencion');
				}
			}

			if (!isValidFieldRequired(userData['pertence'])) {
				errors.push({ field: 'Pertenece a zona', error: 'Campo requerido' });
				camposError.push('pertenece');
			}

			if (!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', ''))) {
				errors.push({ field: 'Dirección de residencia', error: 'Campo requerido' });
				camposError.push('direccion_residencia');
			}

			if(userData['direccion_residencia']?.length > longitudmaximaDireccion){
				errors.push({ field: 'Dirección de residencia', error: mensajeErrorLongitudMaximaDireccion });
				camposError.push('direccion_residencia');
			}

			//Revision de complementos de direccion
			rowsComplementos?.forEach((element, index) => {
				if (isValidFieldRequired(element?.tipo_complemento) && !isValidFieldRequired(element?.complemento)) {
					errors.push({ field: `Complemento [${index + 1}]`, error: 'Campo requerido' });
					camposError.push('direccion_residencia');
				}
			});

		}

		setTouchedFields(camposError)
		setUserData({ ...userData, complementoDirecao: rowsComplementos });

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};

	async function handleSubmit(event: FormEvent) {
		const retorno = handleValidOnSubmit();
		if (retorno) {
			setUserData({ ...userData, complementoDirecao: rowsComplementos });
			setLoading(true);
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(3);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			rowsComplementos !== null &&
			rowsComplementos !== undefined &&
			(rowsComplementos as Array<any>).length > 0
		) {
			(rowsComplementos as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}
		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						otrosConocimientosList.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			municipio_residencia: setValueOrDefault(userData['municipio_residencia'], 'string'),
			departamento_residencia: setValueOrDefault(userData['departamento_residencia'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
			tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
			tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			//codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'], 'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
			tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			localidad: setValueOrDefault(userData['localidad'], 'string'),
			latitud: setValueOrDefault(ddlLocalidad.find((l: any) => l.id_localidad === parseInt(userData['localidad']))?.latitud, 'number'),
			longitud: setValueOrDefault(ddlLocalidad.find((l: any) => l.id_localidad === parseInt(userData['localidad']))?.longitud, 'number'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),

		};

		return resume;
	};

	/*
	 * Establece valores null para los atributos de: Departamento, municipio, localidad, pertenece a zona, prestador, punto de atencion
	 * Cuando el Pais es diferente a Colombia (CO)
	*/
	const onChangePais = (e: SelectChangeEvent) => {
		const pais_residencia = e.target.value;
		setUserData({
			...userData,
			pais_residencia: pais_residencia,
			departamento_residencia: '',
			municipio_residencia: '',
			localidad: '',
			latitud: 0,
			longitud: 0,
			bairro_residencia: '',
			pertence: '',
			prestador_preferencia: '',
			punta_atencion: '',
			local: '',
			id_coordenada: '',
			id_coordenada_02: '',
			id_coordenada_03: '',
			id_coordenada_04: '',
			id_coordenada_05: '',
			id_coordenada_06: '',
			id_coordenada_07: '',
			id_coordenada_08: '',
			id_coordenada_09: '',
			direccion_residencia: '',
			codigo_postal: ''
		});
	}

	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * Event onChange input Direccion de residencia
	 * @param value 
	 */
	const onChangeDireccionResidencia = (value: string) => {
		if (value) {
			setUserData({ ...userData, direccion_residencia: value })
		}
	}
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	return (
		<>
			{loading ? null : loadingParams
				? <LinearLoadingSpinner isLoading={true} />
				: <>
					<Box sx={{ mb: 3 }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
							Residencia
						</Typography>
					</Box>
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
						<Grid xs={12} md={userData['pais_residencia'] === 'CO' ? 4 : 6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('pais_residencia') && !isValidFieldRequired(userData['pais_residencia'])}
							>
								<InputLabel required id='pais_residencia'>
									País de residencia
								</InputLabel>
								<Select
									required
									labelId='pais_residencia'
									id='pais_residencia'
									label='País de residencia'
									value={initValue(userData['pais_residencia'])}
									onChange={(e) => onChangePais(e)}
									onBlur={() => handleBlur('pais_residencia')}
								>
									<MenuItem value='CO' key={0}>Colombia</MenuItem>
									{/* {paisList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))} */}
								</Select>
								{touchedFields.includes('pais_residencia') && !isValidFieldRequired(userData['pais_residencia']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['pais_residencia']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{userData['pais_residencia'] === 'CO' && (
							<>
								<Grid xs={12} md={4} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ minWidth: '100%' }}
										size='medium'
										error={touchedFields.includes('departamento_residencia') && !isValidFieldRequired(userData['departamento_residencia'])}
									>
										<InputLabel required id='departamento_residencia'>
											Departamento de residencia
										</InputLabel>
										<Select
											required
											labelId='departamento_residencia'
											id='departamento_residencia'
											label='Departamento de residencia'
											value={initValue(userData['departamento_residencia'])}
											onChange={(e) => onChangeDepartamento(e)}
											onBlur={() => handleBlur('departamento_residencia')}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{/* fix: Incidencia MDAE-55 -> BOGOTÁ, D.C. / CUNDINAMARCA*/}
											{departamentoList.filter((d) => d.code == '11' || d.code == '25').map(({ code, value }) => (
												<MenuItem key={code} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
										{touchedFields.includes('departamento_residencia') && !isValidFieldRequired(userData['departamento_residencia']) && (
											<FormHelperText>
												{!isValidFieldRequired(userData['departamento_residencia'])
													? 'Campo requerido'
													: ''}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid xs={12} md={4} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ minWidth: '100%' }}
										size='medium'
										error={touchedFields.includes('municipio_residencia') && !isValidFieldRequired(userData['municipio_residencia'])}
									>
										<InputLabel required id='municipio_residencia'>
											Municipio de residencia
										</InputLabel>
										<Select
											required
											labelId='municipio_residencia'
											id='municipio_residencia'
											disabled={blockMuni}
											label='Municipio de residencia'
											value={initValue(userData['municipio_residencia'])}
											onChange={(e) =>
												setUserData({ ...userData, municipio_residencia: e.target.value })
											}
											onBlur={() => handleBlur('municipio_residencia')}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{ddlMunicipioResidencia.map(({ code, value }) => (
												<MenuItem key={code} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
										{touchedFields.includes('municipio_residencia') && !isValidFieldRequired(userData['municipio_residencia']) && (
											<FormHelperText>
												{!isValidFieldRequired(userData['municipio_residencia'])
													? 'Campo requerido'
													: ''}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
							</>
						)}
						{/* Solo se habilitan estos campos cuando el pais de residencia es Colombia*/}
						{userData['pais_residencia'] === 'CO' && (
							<>
								{/* Solo se habilitan estos campos cuando el pais de residencia es Colombia y el Departamento es: Bogotá D.C */}
								{userData['departamento_residencia'] === '11' && (
									<>
										<Grid xs={12} md={4} mb={5} pr={2} item={true}>
											{loading || loadingParams
												? null
												:
												<FormControl
													sx={{ minWidth: '100%' }}
													size='medium'
													error={touchedFields.includes('localidad') && !isValidValueOnList(userData['localidad'], ddlLocal, 'id')}
												>
													<InputLabel required id='localidad'>
														Localidad
													</InputLabel>
													<Select
														required={userData['pais_residencia'] === 'CO' && userData['departamento_residencia'] === '11'}
														labelId='localidad'
														id='localidad'
														label='Localidad'
														value={initValue(userData['localidad'])}
														onChange={(e) => onChangeLocalidad(e)}
														onBlur={() => handleBlur('localidad')}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{ddlLocal.map(({ id, name }) => (
															<MenuItem key={id} value={id}>
																{name}
															</MenuItem>
														))}
													</Select>
													{touchedFields.includes('localidad') && !isValidValueOnList(userData['localidad'], ddlLocal, 'id') && (
														<FormHelperText>
															{!isValidValueOnList(userData['localidad'], ddlLocal, 'id')
																? 'Campo requerido'
																: ''}
														</FormHelperText>
													)}
												</FormControl>
											}
										</Grid>
										{/* Barrio */}
										<Grid xs={12} md={4} mb={5} pr={2} item={true}>
											{loading || loadingParams
												? null
												: <FormControl
													sx={{ minWidth: '100%' }}
													size='medium'
													required={userData['localidad'] ? true : false}
													error={touchedFields.includes('barrio_residencia') && !isValidValueOnList(userData['bairro_residencia'], ddlBarrio, 'id_barrio')}
												>
													<InputLabel required id='bairro_residencia'>
														Barrio
													</InputLabel>
													<Select
														required={true}
														labelId='bairro_residencia'
														id='bairro_residencia'
														label='bairro_residencia'
														value={initValue(userData['bairro_residencia'])}
														onChange={(e) =>
															setUserData({ ...userData, bairro_residencia: e.target.value })
														}
														onBlur={() => handleBlur('barrio_residencia')}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{ddlBarrio.map(({ id_barrio, nombre }, i) => (
															<MenuItem key={id_barrio} value={id_barrio}>
																{nombre}
															</MenuItem>
														))}
													</Select>
													{touchedFields.includes('barrio_residencia') && !isValidValueOnList(userData['bairro_residencia'], ddlBarrio, 'id_barrio') && (
														<FormHelperText>
															{!isValidValueOnList(userData['bairro_residencia'], ddlBarrio, 'id_barrio') ? 'Campo requerido' : ''}
														</FormHelperText>
													)}
												</FormControl>
											}
										</Grid>
									</>
								)}

								<Grid xs={12} md={4} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ minWidth: '100%' }}
										size='medium'
										error={touchedFields.includes('pertenece') && !isValidFieldRequired(userData['pertence'])}
									>
										<InputLabel required id='pertence'>
											Pertenece a zona:
										</InputLabel>
										<Select
											required={userData['pais_residencia'] === 'CO' && userData['departamento_residencia'] === '11'}
											labelId='pertence'
											id='pertence'
											label='Pertenece a zona:'
											value={userData['pertence']}
											onChange={(e) => setUserData({ ...userData, pertence: e.target.value })}
											onBlur={() => handleBlur('pertenece')}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{zonaList.map(({ code, value }) => (
												<MenuItem key={code} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
										{touchedFields.includes('pertenece') && !isValidFieldRequired(userData['pertence']) && (
											<FormHelperText>
												{!isValidFieldRequired(userData['pertence']) ? 'Campo requerido' : ''}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>

								{/* Solo se habilitan estos campos cuando el pais de residencia es Colombia y el Departamento es: Bogotá D.C */}
								{userData['departamento_residencia'] === '11' && (
									<>
										<Grid xs={12} md={4} mb={5} pr={2} item={true}>
											<OverlayTrigger
												placement='bottom'
												delay={{ show: 250, hide: 400 }}
												overlay={(props) => (
													<Tooltip id='button-tooltip' {...props}>
														Seleccione el punto de atención más accesible a su residencia.
													</Tooltip>
												)}
											>
												<FormControl
													sx={{ minWidth: '100%' }}
													size='medium'
													error={touchedFields.includes('prestador_preferencia') && !isValidFieldRequired(userData['prestador_preferencia'])}
												>
													<InputLabel required id='prestador_preferencia'>
														<span>
															{' '}
															Prestador de tu preferencia
															<span
																className='bi bi-question-circle-fill'
																style={{ color: '#2266cc', marginLeft: '5px' }}
															></span>
														</span>
													</InputLabel>

													<Select
														required={userData['pais_residencia'] === 'CO' && userData['departamento_residencia'] === '11'}
														labelId='prestador_preferencia'
														id='prestador_preferencia'
														label='Prestador de tu preferencia'
														value={userData['prestador_preferencia']}
														onChange={(e) =>
															setUserData({ ...userData, prestador_preferencia: e.target.value })
														}
														onBlur={() => handleBlur('prestador_preferencia')}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listPrestadorPreferencia.map(({ code, value }) => (
															<MenuItem key={code} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
													{touchedFields.includes('prestador_preferencia') && !isValidFieldRequired(userData['prestador_preferencia']) && (
														<FormHelperText>
															{!isValidFieldRequired(userData['prestador_preferencia'])
																? 'Campo requerido'
																: ''}
														</FormHelperText>
													)}
												</FormControl>
											</OverlayTrigger>
										</Grid>

										<Grid xs={12} md={4} mb={5} pr={2} item={true}>
											<FormControl
												sx={{ maxWidth: '100%', minWidth: '100%' }}
												size='medium'
												error={touchedFields.includes('punta_atencion') && !isValidFieldRequired(userData['punta_atencion'])}
											>
												<InputLabel required id='punta_atencion'>
													Punto de atención
												</InputLabel>
												<Select
													required={userData['pais_residencia'] === 'CO' && userData['departamento_residencia'] === '11'}
													labelId='punta_atencion'
													id='punta_atencion'
													label='Punto de atención'
													value={userData['punta_atencion']}
													onChange={(e) => setUserData({ ...userData, punta_atencion: e.target.value })}
													onBlur={() => handleBlur('punta_atencion')}
												>
													<MenuItem value=''>Seleccione...</MenuItem>
													{listPuntoAtencion.map(({ code, value }) => (
														<MenuItem key={code} value={code}>
															 {value}
														</MenuItem>
													))}
												</Select>
												{touchedFields.includes('punta_atencion') && !isValidFieldRequired(userData['punta_atencion']) && (
													<FormHelperText>
														{!isValidFieldRequired(userData['punta_atencion']) ? 'Campo requerido' : ''}
													</FormHelperText>
												)}
											</FormControl>
										</Grid>
										<Grid xs={12} md={4} mb={5} pr={2} item={true} />
									</>
								)}

							</>
						)}
						{userData['pais_residencia'] === 'CO' && userData['departamento_residencia'] !== '11' && (
							<>
								<Grid xs={12} md={3} mb={5} pr={2} item={true} />
							</>
						)}

						{userData['pais_residencia'] === 'CO' && (
							<>
								<Grid container direction='row' item={true}>
									<Box sx={{ mb: 1 }}>
										<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
											Dirección
										</Typography>
									</Box>

									<Grid xs={6} md={3} mb={5} pr={2} item={true} />
									<Grid xs={6} md={3} mb={5} pr={2} item={true} />
									<Grid xs={6} md={3} mb={5} item={true} />
									<Grid xs={5} mb={1} item={true} />
									<Grid xs={6} mb={1} item={true} />
									<Grid xs={12} md={2} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<Select
												labelId='local'
												id='local'
												value={userData['local']}
												onChange={(e) => setUserData({ ...userData, local: e.target.value })}
												inputProps={{ 'aria-label': 'Without label' }}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipal.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<TextField
											fullWidth
											required={userData['pais_residencia'] === 'CO'}
											id='id_coordenada'
											name='id_coordenada'
											label=''
											value={userData['id_coordenada']}
											onChange={(e) => setUserData({ ...userData, id_coordenada: e.target.value })}
											size='medium'
											onKeyDown={eventOnKeyDownInputAlphaNumeric}
											inputProps={{
												maxLength: 20,
												minLength: 1,
											}}
										/>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_02'></InputLabel>
											<Select
												labelId='id_coordenada_02'
												id='id_coordenada_02'
												label=''
												value={userData['id_coordenada_02']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_02: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipalPrimeraLetra.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_03'></InputLabel>
											<Select
												labelId='id_coordenada_03'
												id='id_coordenada_03'
												label=''
												value={userData['id_coordenada_03']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_03: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{viaPrincipalBisList.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_04'></InputLabel>
											<Select
												labelId='id_coordenada_04'
												id='id_coordenada_04'
												label=''
												value={userData['id_coordenada_04']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_04: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipalPrimeraLetra.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_05'></InputLabel>
											<Select
												labelId='id_coordenada_05'
												id='id_coordenada_05'
												label=''
												value={userData['id_coordenada_05']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_05: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipalCuadrante.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={1} md={0.2} mb={5} item={true}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												paddingTop: 1,
												fontWeight: 'bold',
											}}
										>
											#
										</div>
									</Grid>
									<Grid xs={5} md={1} mb={5} pr={2} item={true}>
										<TextField
											type='number'
											fullWidth
											required={userData['pais_residencia'] === 'CO'}
											id='id_coordenada_06'
											name='id_coordenada_06'
											label=''
											value={userData['id_coordenada_06']}
											onKeyDown={(e: any) => {
												eventOnKeyDownInputNumber(e);
											}}
											onChange={(e) => setUserData({ ...userData, id_coordenada_06: e.target.value.slice(0, 4) })}
											size='medium'
											InputProps={{ inputProps: { min: 0, maxLength: 45 } }}
										/>
									</Grid>
									<Grid xs={5} md={1} mb={5} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_07'></InputLabel>
											<Select
												labelId='id_coordenada_07'
												id='id_coordenada_07'
												label=''
												value={userData['id_coordenada_07']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_07: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipalPrimeraLetra.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={1} md={0.2} mb={5} item={true}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												paddingTop: 5,
												fontWeight: 'bold',
											}}
										>
											-
										</div>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<TextField
											type='number'
											fullWidth
											required={userData['pais_residencia'] === 'CO'}
											id='id_coordenada_08'
											name='id_coordenada_08'
											label=''
											value={userData['id_coordenada_08']}
											onKeyDown={(e: any) => {
												eventOnKeyDownInputNumber(e);
											}}
											onChange={(e) => setUserData({ ...userData, id_coordenada_08: e.target.value.slice(0, 4) })}
											size='medium'
											InputProps={{ inputProps: { min: 0, maxLength: 45 } }}
										/>
									</Grid>
									<Grid xs={12} md={1} mb={5} pr={2} item={true}>
										<FormControl sx={{ minWidth: '100%' }} size='medium'>
											<InputLabel id='id_coordenada_09'></InputLabel>
											<Select
												labelId='id_coordenada_09'
												id='id_coordenada_09'
												label=''
												value={userData['id_coordenada_09']}
												onChange={(e) => setUserData({ ...userData, id_coordenada_09: e.target.value })}
											>
												<MenuItem value=''>Seleccione...</MenuItem>
												{listViaPrincipalCuadrante.map(({ code, value }) => (
													<MenuItem key={code} value={code}>
														{value}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>

								<Grid xs={12} item={true}>
									{rowsComplementos.map((row, index: any) => (
										<Grid key={index} xs={12} item={true}>
											<Grid
												container
												direction='row'
												justifyContent='space-between'
												width={'100%'}
												item={true}
											>
												<Grid xs={12} md={4} mb={5} pr={2} item={true}>
													<FormControl sx={{ minWidth: '100%' }} size='small'>
														<InputLabel id={`tipo_complemento` + index}>Tipo de complemento</InputLabel>
														<Select
															labelId={`tipo_complemento` + index}
															id={`tipo_complemento` + index}
															label='Tipo de complemento'
															value={row.tipo_complemento}
															onChange={(e) => handleChangeTipoComplemento(e, index)}
															size='medium'
														>
															<MenuItem value=''>Seleccione...</MenuItem>
															{listViaPrincipalComplementoDireccion.map(({ code, value }) => (
																<MenuItem key={code} value={code}>
																	{value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid xs={12} md={4} mb={5} pr={2} item={true}>
													<FormControl sx={{ minWidth: '100%' }} size='small'>
														<TextField
															required={isValidFieldRequired(rowsComplementos[index]?.tipo_complemento)}
															fullWidth
															id={'complemento' + index}
															name='complemento'
															label='Complemento'
															value={row.complemento}
															onChange={(e) => handleChangeComplemento(e, index)}
															size='medium'
															inputProps={{ maxLength: 100 }}
															error={isValidFieldRequired(rowsComplementos[index]?.tipo_complemento) && !isValidFieldRequired(rowsComplementos[index]?.complemento)}
															helperText={isValidFieldRequired(rowsComplementos[index]?.tipo_complemento) && !isValidFieldRequired(rowsComplementos[index]?.complemento) ? 'Campo requerido' : ''}
														/>
													</FormControl>
												</Grid>
												{index > 0 && (
													<Grid xs={12} md={4} mb={5} pr={2} item={true}>
														<Button
															fullWidth
															style={{ width: '100%', height: '50px' }}
															variant='contained'
															onClick={() => handleDelete(row.id)}
														>
															Excluir complemento -
														</Button>
													</Grid>
												)}
												{index === 0 && (
													<Grid xs={12} md={4} mb={5} pr={2} item={true}>
														<div>
															<Button
																fullWidth
																style={{ height: '50px' }}
																variant='contained'
																onClick={(e) => handleChange(e)}
															>
																Nuevo complemento +
															</Button>
														</div>
													</Grid>
												)}
											</Grid>
										</Grid>
									))}
								</Grid>

							</>
						)}

						{userData['pais_residencia'] === 'CO' && (
							<>
								<Grid xs={12} md={12} mb={5} item={true}>
									<FormControl 
										sx={{ minWidth: '99%' }} 
										size='medium'
										error={
											touchedFields.includes('direccion_residencia') && (
												!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', '')) ||
												!isValidAddress(handleDirectionValue()) ||
												(userData['direccion_residencia']?.length > longitudmaximaDireccion)
											)
										}
									>
										<TextField
											fullWidth
											required={userData['pais_residencia'] === 'CO'}											
											id='direccion_residencia'
											name='direccion_residencia'
											label='Dirección de residencia'
											value={handleDirectionValue()}
											onBlur={() => handleBlur('direccion_residencia')}
											onChange={(e) => onChangeDireccionResidencia(e.target.value)}
											size='medium'
											error={
												touchedFields.includes('direccion_residencia') && (
													(!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', ''))) ||
													(!isValidAddress(handleDirectionValue())) ||
													(userData['direccion_residencia']?.length > longitudmaximaDireccion)
												)
											}
											helperText={
												(touchedFields.includes('direccion_residencia') && !isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', ''))) ? 'Campo requerido' :
													(touchedFields.includes('direccion_residencia') && !isValidAddress(handleDirectionValue())) ? 'Debe seleccinar un tipo de via e ingresar almenos un número en la dirección' :
														(touchedFields.includes('direccion_residencial') && (userData['direccion_residencia']?.length > longitudmaximaDireccion)) ? mensajeErrorLongitudMaximaDireccion : ''
											}
											inputProps={{ readOnly: true }}
										/>
									</FormControl>
								</Grid>
							</>
						)}

					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40, }}
							onClick={() => handleRetorno()}
						>
							Atrás
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90 }}
							onClick={handleSubmit}
						>
							Siguiente
						</Button>
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
							{alertMsg}
						</Alert>
					</Snackbar>
				</>
			}
		</>
	);
};
