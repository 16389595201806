import { useContext, useEffect, useMemo, useState } from 'react';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Grid,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,
} from '@mui/material';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { Form, Formik } from 'formik';
import { object, string, date } from 'yup';
import { differenceInYears } from 'date-fns';
import { getVerifyDocumentExists } from '../../../core/_requests';
import { isValidFieldRequired, checkRulesByBirthDateAndDocType, regExpConsecutiveCharacteres, isValidDocumentNumber, helperIsValidDocumentNumber, eventOnKeyDownInputNumber, regexValidAlphabetValueText } from '../../../../../util/Index';
import useDocumentTypeParams from '../../../../../hooks/parameters/useDocumentTypeParams';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs, { Dayjs } from 'dayjs';
import "dayjs/locale/es";
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

function goBack() {
	window.history.back();
}
export const Step1 = () => {
	const { listTipoDocumentoCandidato } = useDocumentTypeParams();
	const { setStep, firstUser, setFirstUser, setLoading } = useContext(multiStepContext);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [open, setOpen] = useState(false);

	const today = dayjs()
	const postToday = (date: Dayjs) => date > dayjs().subtract(15, 'year');
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [documentTypeSelected, setDocumentTypeSelected] = useState('');
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');


	const [initialValues, setInitialValues] = useState({
		complemento: {
			tipo_doc: '',
			numero_doc: '',
			primer_nome: '',
			segundo_nome: '',
			primer_apelido: '',
			segundo_apelido: '',
			fecha_nascimiento: '',
		},
	});
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setInitialValues(firstUser);
		setDocumentTypeSelected(firstUser.complemento.tipo_doc);
		validationSchemaByTpDoc();
	}, []);

	// console.log(firstUser);

	const handleNext = async (values: any, setErrors: any) => {
		//console.log(setErrors);

		let errors: { field: string; error: string }[] = [];

		if (!isValidDocumentNumber(values.complemento.numero_doc, documentTypeField, values.complemento.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true)) {
			errors.push({ field: 'Número de documento', error: helperIsValidDocumentNumber(values.complemento.numero_doc, documentTypeField, values.complemento.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true) });
		}

		//Se realiza chequeo de reglas para fecha de nacimiento y tipo documento
		errors.push(...checkRulesByBirthDateAndDocType(values.complemento.fecha_nascimiento, values.complemento.tipo_doc));
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return;
		}

		const exists = await verifyDocumentExists(values.complemento.numero_doc, setErrors);

		if (!exists) {
			setFirstUser({
				...firstUser,
				complemento: {
					...firstUser.complemento,
					numero_doc: values.complemento.numero_doc,
					tipo_doc: values.complemento.tipo_doc,
					primer_nome: values.complemento.primer_nome,
					segundo_nome: values.complemento.segundo_nome,
					primer_apelido: values.complemento.primer_apelido,
					segundo_apelido: values.complemento.segundo_apelido,
					fecha_nascimiento: values.complemento.fecha_nascimiento,
				},
			});
			setStep(2);
		}
	};

	const verifyDocumentExists = async (documentNumber: string, setErrors: any) => {
		try {
			setLoading(true);
			const response = await getVerifyDocumentExists(`document=${documentNumber}`);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setOpen(true);
				//TODO: Revisar este comportamiento
				setErrors({ complemento: { numero_doc: error.response.data.error } });
				return true;
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
				return true;
			}
		} finally {
			setLoading(false);
		}
	};

	const validationSchemaByTpDoc = () => {

		//Se establecen los criterios de validacion para atributos comunes
		//Primer Nombre
		const primer_nome = string().min(2, 'Debe tener al menos 2 caracteres')
			.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
				return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
			})
			.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
			.required('Campo requerido')
		//Segundo Nombre	
		const segundo_nome = string()
			.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
			.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
				return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
			})
		//Primer Apellido
		const primer_apelido = string()
			.min(2, 'Debe tener al menos 2 caracteres')
			.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
				return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
			})
			.required('Campo requerido')
			.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
		//Segundo Apellido	
		const segundo_apelido = string()
			.test('rule1', 'No debe contener 2 caracteres iguales consecutivos', (value) => {
				return value?.length === 2 && value?.match(regExpConsecutiveCharacteres) ? false : true
			})
			.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos')
		//Tipo documento
		const tipo_doc = string()
			.test('len', 'Seleccione un tipo de documento', (val) => {
				return (val as string) === '' ? false : true;
			})
			.required('Campo requerido')

		switch (documentTypeSelected) {
			case 'CC':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Campo requerido')
							.min(3, 'Debe tener al menos 3 dígitos')
							.max(11, 'Debe tener 11 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('ruleBirthDate', 'No puede seleccionar Cédula de Ciudadanía si es menor de 18 años de edad', (value) => {
								console.log('ruleBirthDate: ' + value)
								let errors = checkRulesByBirthDateAndDocType(value, documentTypeSelected);
								if (errors.length > 0)
									return false;
								return true;
							})
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(10);
				setMaxDocumentFieldLenght(11);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[0-9]*$/, 'Deben ser solo números')
							.required('Campo requerido')
							.min(10, 'Debe tener al menos 10 dígitos')
							.max(11, 'Debe tener 11 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('ruleBirthDate', 'No puede seleccionar Tarjeta de Identidad si es menor de 7 o mayor de 17 años de edad', (value) => {
								console.log('ruleBirthDate: ' + value)
								let errors = checkRulesByBirthDateAndDocType(value, documentTypeSelected);
								if (errors.length > 0)
									return false;
								return true;
							})
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'CE':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(7);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Campo requerido')
							.min(1, 'Debe tener al menos 1 dígitos')
							.max(7, 'Debe tener 7 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(17);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
							.required('Campo requerido')
							.min(1, 'Debe tener al menos 1 dígitos')
							.max(17, 'Debe tener 17 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PEP':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(20);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Campo requerido')
							.min(15, 'Debe tener al menos 15 dígitos')
							.max(20, 'Debe tener 20 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PT':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Campo requerido')
							.min(4, 'Debe tener al menos 4 dígitos')
							.max(7, 'Debe tener 7 o menos dígitos'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
				return object({
					complemento: object({
						tipo_doc: tipo_doc,
						numero_doc: string().required('Campo requerido'),
						primer_nome: primer_nome,
						segundo_nome: segundo_nome,
						primer_apelido: primer_apelido,
						segundo_apelido: segundo_apelido,
						fecha_nascimiento: date()
							.required('Campo requerido')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								console.log('value: ' + value)
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
		}
	};

	//Evento de escucha para onChange sobre el atributo "fecha de nacimiento"
	const onChangeBirthDate = (data: any, value: any) => {
		let errors = checkRulesByBirthDateAndDocType(value, data.complemento.tipo_doc);
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
		}
	}

	return (
		<Box sx={{ '& .MuiTextField-root': { m: 2, width: '95%' } }}>
			<Formik
				validationSchema={validationSchemaByTpDoc}
				initialValues={initialValues}
				validateOnBlur
				validateOnMount
				enableReinitialize
				initialTouched={{
					complemento: {
						tipo_doc: true,
						numero_doc: true,
						primer_nome: true,
						primer_apelido: true,
						fecha_nascimiento: true,
					},
				}}
				onSubmit={() => { }}
			>
				{({ errors, values, handleChange, isValid, setErrors, setFieldValue }) => (
					<Form autoComplete='off'>
						<Grid container direction='row' justifyContent='space-between' width={'100%'}>
							<Grid xs={12} md={6} item={true}>
								<FormControl
									sx={{ m: 2, minWidth: '95%' }}
									size='medium'
									error={touchedFields.includes('tipo_doc') && Boolean(errors.complemento?.tipo_doc)}
								>
									<InputLabel required>Tipo de documento</InputLabel>
									<Select
										required
										name='complemento.tipo_doc'
										label='Tipo de documento'
										onChange={(e) => {
											values.complemento.numero_doc = '';
											handleChange(e);
											setDocumentTypeSelected(e.target.value);
										}}
										onBlur={() => handleBlur('tipo_doc')}
										value={values.complemento.tipo_doc}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listTipoDocumentoCandidato.map(({ code, value }) => (
											<MenuItem value={code} key={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('tipo_doc') && Boolean(errors.complemento?.tipo_doc) && (
										<FormHelperText>
											{touchedFields.includes('tipo_doc') ? errors.complemento?.tipo_doc : ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('numero_doc') && !isValidDocumentNumber(values.complemento.numero_doc, documentTypeField, values.complemento.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true)}>
									<TextField
										fullWidth
										required
										name='complemento.numero_doc'
										label='Número de documento'
										type={documentTypeField}
										size='medium'
										inputProps={{
											maxLength: maxDocumentFieldLenght,
											minLength: minDocumentFieldLenght,
											min: 0,
											readOnly: !isValidFieldRequired(values.complemento.tipo_doc)
										}}
										onChange={(e) => {
											if (e.target.value.length <= maxDocumentFieldLenght) handleChange(e);
										}}
										onBlur={() => handleBlur('numero_doc')}
										value={values.complemento.numero_doc}
										onKeyDown={documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen 
										error={touchedFields.includes('numero_doc') && !isValidDocumentNumber(values.complemento.numero_doc, documentTypeField, values.complemento.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true)}
										helperText={
											touchedFields.includes('numero_doc') && !isValidFieldRequired(values.complemento.tipo_doc)
												? 'Primero seleccione el tipo de documento'
												: touchedFields.includes('numero_doc') ? helperIsValidDocumentNumber(values.complemento.numero_doc, documentTypeField, values.complemento.tipo_doc, minDocumentFieldLenght, maxDocumentFieldLenght, true) : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('primer_nome') && Boolean(errors.complemento?.primer_nome)}>
									<TextField
										fullWidth
										required
										name='complemento.primer_nome'
										label='Primer nombre'
										type='text'
										inputProps={{ minLength: 2, maxLength: 40 }}
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('primer_nome')}
										value={values.complemento.primer_nome}
										error={
											touchedFields.includes('primer_nome') && Boolean(errors.complemento?.primer_nome)
										}
										helperText={
											touchedFields.includes('primer_nome') ? errors.complemento?.primer_nome : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('segundo_nome') && Boolean(errors.complemento?.segundo_nome)}>
									<TextField
										fullWidth
										name='complemento.segundo_nome'
										label='Segundo nombre'
										type='text'
										inputProps={{ maxLength: 40 }}
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('segundo_nome')}
										value={values.complemento.segundo_nome}
										error={
											touchedFields.includes('segundo_nome') && Boolean(errors.complemento?.segundo_nome)
										}
										helperText={
											touchedFields.includes('segundo_nome') ? errors.complemento?.segundo_nome : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('primer_apelido') && Boolean(errors.complemento?.primer_apelido)}>
									<TextField
										fullWidth
										required
										name='complemento.primer_apelido'
										label='Primer apellido'
										type='text'
										inputProps={{ minLength: 2, maxLength: 40 }}
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('primer_apelido')}
										value={values.complemento.primer_apelido}
										error={
											touchedFields.includes('primer_apelido') &&
											Boolean(errors.complemento?.primer_apelido)
										}
										helperText={
											touchedFields.includes('primer_apelido') ? errors.complemento?.primer_apelido : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('segundo_apelido') && Boolean(errors.complemento?.segundo_apelido)}>
									<TextField
										fullWidth
										name='complemento.segundo_apelido'
										label='Segundo apellido'
										type='text'
										inputProps={{ maxLength: 40 }}
										size='medium'
										onChange={handleChange}
										onBlur={() => handleBlur('segundo_apelido')}
										value={values.complemento.segundo_apelido}
										error={
											touchedFields.includes('segundo_apelido') && Boolean(errors.complemento?.segundo_apelido)
										}
										helperText={
											touchedFields.includes('segundo_apelido') ? errors.complemento?.segundo_apelido : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} item={true}>
								<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
									<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('fecha_nascimiento') && Boolean(errors.complemento?.fecha_nascimiento)}>
										<DemoItem>
											<DatePicker
												disableFuture
												value={dayjs(values.complemento?.fecha_nascimiento, 'YYYY-MM-DD')}
												format='DD/MM/YYYY'
												onOpen={() => handleBlur('fecha_nascimiento')}
												onChange={(newValue) => {
													values.complemento.fecha_nascimiento = newValue !== null && newValue?.format('YYYY-MM-DD') !== 'Invalid Date' ? newValue?.format('YYYY-MM-DD') : '';
													setFieldValue(values.complemento.fecha_nascimiento, newValue?.format('YYYY-MM-DD'));
													onChangeBirthDate(values, newValue?.format('YYYY/MM/DD'));
												}}
												slotProps={{
													textField: {
														id: 'fecha_nascimiento2',
														name: 'fecha_nascimiento2',
														label: 'Fecha de nacimiento',
														required: true,
														variant: 'outlined',
														error: touchedFields.includes('fecha_nascimiento') && Boolean(errors.complemento?.fecha_nascimiento),
														helperText: touchedFields.includes('fecha_nascimiento') ? errors.complemento?.fecha_nascimiento : ''
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
											/>
										</DemoItem>
									</FormControl>
								</LocalizationProvider>
							</Grid>
						</Grid>
						{/* <Box sx={{ height: '68px' }} /> */}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'end',
								pt: 2,
							}}
						>
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => goBack()}
							>
								Atrás
							</Button>
							{/*<Box sx={{ flex: '1 1 auto' }} />*/}
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleNext(values, setErrors)}
								disabled={!isValid}
							>
								Siguiente
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</Box>
	);
};
