import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link, useNavigate } from 'react-router-dom';

import { multiStepContext } from '../../../contexts/CompanyContext';

import { Step1 } from './steps/company-registration/Step1';
import { Step2 } from './steps/company-registration/Step2';
import { Step3 } from './steps/company-registration/Step3';
import { Step4 } from './steps/company-registration/Step4';
import { Step5 } from './steps/company-registration/Step5';
import { Step6 } from './steps/company-registration/Step6';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import ColorlibStepIcon from './steps/Index';
import BackButton from '../../../components/BackButton/BackButton';

export const CompanyRegistration = () => {
	const { currentStep, finalData } = useContext(multiStepContext);
	const navigate = useNavigate();
	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			case 6:
				return <Step6 />;
		}
	};

	//Permite determinar si se carga el componente desde una sesion autenticada, ejemplo crear la empresa desde el perfil consejero
	const { auth } = useAuth();

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ width: '100%', height: '60%', display: 'flex', flexDirection: 'Column', justifyContent: 'center', alignItems: 'center' }}>
				<>
					{/* Si no existe una sesion autenticada muestra la seccion de logos */}
					{!auth && (
						<>
							{/* begin DIV Logo GOV.CO */}
							< div className='headergov2 d-flex flex-column pt-1 h-40px w-100' style={{ background: '#3366C9' }}>
								{/* begin::Logo */}
								<Link to='/' className='mb-1'>
									<img alt='Logo' src={'/media/logos/logo.png'} />
								</Link>
								{/* end::Logo */}
							</div>
							{/* begin DIV Logo Icons */}
							<div className='headergov2 d-flex flex-column pt-1 h-50px w-100' style={{ background: 'white' }} >
								<div>
									{/* begin::Aside mobile toggle */}

									<div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu' >
										<div className='btn btn-icon btn-active-color-primary w-40px h-40px' id='kt_aside_toggle' >
											<KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
										</div>
									</div>
									{/* end::Aside mobile toggle */}

									{/* begin::Mobile logo */}
									<div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
										<Link to='/dashboard' className='d-lg-none'>
											<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-bogota.png')} className='h-30px' />
										</Link>
									</div>
									{/* end::Mobile logo */}
									<Link to='/'>
										<div className='logobogota d-flex justify-content-left align-items-center' style={{ paddingRight: '15px' }} >
											<img alt='Logo-bogota' src={toAbsoluteUrl('/media/logos/logo-bogota.png')} className='h-40px' />
										</div>
									</Link>
								</div>
							</div>
						</>
					)}
				</>
				<Box sx={{ width: !auth ? '90%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFFF', borderRadius: '10px', m: 5, p: 5 }}>
					<Box sx={{ width: '95%' }}>
						<Box>
							<div className='row mb-4'>
								<div className='col-sm-12'>
									<div className='input-group'>
										<div
											className='col-sm-1'
											style={{
												display: 'contents',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<BackButton />
										</div>
										<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
											<h2 style={{ display: 'flex', gap: '10px' }}>Datos básicos del empleador</h2>
											<span style={{}}>Rellena los campos abajo con los datos de tu empresa.</span>
										</div>
									</div>
								</div>
							</div>
						</Box>
						<Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
						</Stepper>
						<Box mt={5} width={'100%'}>{showStep(currentStep)}</Box>
					</Box>
				</Box>
			</Box>
		</Box >
	);
};
