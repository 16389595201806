import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import BackButton from '../../components/BackButton/BackButton';
import { useEffect } from 'react';

const PoliticasDashboardPage = () => {

	const dashPoliticas = process.env.REACT_APP_DASH_POLITICAS || '';

	useEffect(() => {

	}, []);

	return (
		<>
			<div className='p-10 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div
								className='col-sm-1'
								style={{
									display: 'contents',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<BackButton to='/' />
							</div>
							<div className='col-sm-10' style={{ padding: '0px 0px 0px 10px' }}>
								<p>Métricas y estadísticas de la plataforma</p>
							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '100%' }}>
					<iframe
						width="100%"
						height='850px'
						frameBorder="0"
						style={{ border: '0' }}
						allowFullScreen
						src={dashPoliticas}
					>
					</iframe>
				</div>
			</div>
		</>
	);
};

const PoliticasDashboardWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Análisis de politica</PageTitle>
			<PoliticasDashboardPage />
		</>
	);
};

export { PoliticasDashboardWrapper };
