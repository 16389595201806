import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { ReportsPanel } from './ReportsPanel';


const AggregateReports = () => {
	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	

	return (
		<>
			<PageTitle breadcrumbs={[]}>Reportes Agregados</PageTitle>
				<ReportsPanel/>
		</>
	)
}

export { AggregateReports }
