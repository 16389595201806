import { useContext, useMemo, useState, useEffect } from 'react';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useAboutVacancyParams from '../../../../../hooks/parameters/vacancy-management/useAboutVacancyParams';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs';
import {
	initValue,
	isValidDateAndEqualOrAfterThan,
	helperIsValidDateAndEqualOrAfterThan,
	eventOnKeyDownInputNumber,
	formatNumber,
	salarioMinIsGreaterThanSalarioMax,
	helperSalarioMinIsGreaterThanSalarioMax,
	isValidFieldRequired,
	helperValidMinMaxText,
	isValidMinMaxText,
	concatArrayToStringJoinBy,
	stringToArrayBySeparator
} from '../../../../../util/Index';
import parameterSingleton from '../../../../../services/Parameters.service';

import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText,
	Chip,
	Checkbox,
	SelectChangeEvent,
} from '@mui/material';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';
import { useAuth } from '../../../../../../app/modules/auth';
import { ids } from 'webpack';
import { createVacancyTempral, putVacancyTempral } from '../../../../apps/user-management/users-list/core/_requests';

export const Step5 = () => {

	const { isCounselorEmployerRole } = useAuth();

	const { setStep, userData, setUserData, shouldDisable } = useContext(multiStepContext);

	const idsCargos = userData?.Cargos?.map((cargo: any) => cargo.codigo_cuoc);

	const {
		listSiNo, listDiasLaborales, listHorarioTrabajo, listSalarioMensual, listTipoVacante,
		listJornadaLaboral, listTipoContrato, listCUOCOcupacion, listCUOCSubGrupo, loadingParams,
		listPoblaciones, listDiscapacidades, listGrupoEtnico
	} = useAboutVacancyParams({
		CUOCSubGrupo: { reload: true, params: { id_param_parent: idsCargos?.join() } }
	});
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [ddlDescripcionCOUD, setddlDescripcionCOUD] = useState<ParameterConfigMmodel[]>([]);
	const [poblacionesAceptadas, setPoblacionesAceptadas] = useState<string[]>([]);
	const [isCounselorEmployer, setIsCounselorEmployer] = useState<any>(false);

	const CODE_POBLACIONES_GRUPOETNICO = '16';//Grupo Étnico (Afrocolombiano, Comunidad Negra, Raizal, Palenquero, Indígena, Rrom/Gitano)
	const CODE_POBLACIONES_DISCAPACIDAD = '17';//Discapacidad (discapacidad física, discapacidad auditiva, discapacidad visual, sordoceguera, discapacidad intelectual, discapacidad psicosocial - mental, discapacidad múltiple)

	const handleChangePoblaciones = (event: SelectChangeEvent<typeof poblacionesAceptadas>) => {
		const { target: { value } } = event;
		setUserData({ ...userData, poblaciones_aceptadas: typeof value === 'string' ? value.replaceAll(',', ',') : value })
		setPoblacionesAceptadas(stringToArrayBySeparator(value, ','));

	};

	useEffect(() => {
		if (userData.ocupacion) {
			fetchAndSetCUODById(userData.ocupacion);
		} else {
			setddlDescripcionCOUD([]);
		}
		setPoblacionesAceptadas(stringToArrayBySeparator(userData.poblaciones_aceptadas, ','));
		setIsCounselorEmployer(isCounselorEmployerRole())
	}, []);

	class validationForm {
		fecha_limite_aplicacion: string = initValue(userData['fecha_limite_aplicacion']);
		tipo_contrato: string = initValue(userData['tipo_contrato']);
		salario_mensual: string = initValue(userData['salario_mensual']);
		salario_mensual_max: string = initValue(userData['salario_mensual_max']);
		jornada_laboral: string = initValue(userData['jornada_laboral']);
		vacante_confidencial: string = initValue(userData['vacante_confidencial']);
		ocupacion: string = initValue(userData['ocupacion']);
		descripcion_ocupacion: string = initValue(userData['descripcion_ocupacion']);
		poblaciones_aceptadas: string[] = poblacionesAceptadas;
		discapacidad_aceptada: string = initValue(userData['discapacidad_aceptada']);
		grupo_etnico_aceptado: string = initValue(userData['grupo_etnico_aceptado'])
		tipo_vacante: string = initValue(userData['tipo_vacante']);
		trabajo_suplementario: string = initValue(userData['trabajo_suplementario']);
		numero_puestos: string = initValue(userData['numero_puestos']);
		vacante_gestionada_prestador: string = initValue(userData['vacante_gestionada_prestador']);
		dias_laborales: string = initValue(userData['dias_laborales']);
		horario_trabajo: string = initValue(userData['horario_trabajo']);
		empleo_verde: string = initValue(userData['empleo_verde']);
		pagos_adicionales: string = initValue(userData['pagos_adicionales']);
		shouldDisable = shouldDisable;
		forceRequired = isCounselorEmployer;
	}

	const formSchema = useMemo(
		() => {
			return Yup.object().shape({
				fecha_limite_aplicacion: Yup.string().required('Campo requerido'),
				tipo_contrato: Yup.string().required('Campo requerido'),
				salario_mensual: Yup.string().required('Campo requerido'),
				/* salario_mensual_max: Yup.string().required('Campo requerido'),	 */
				salario_mensual_max: Yup.string()
					.when('shouldDisable', {
						is: false, // solo aplica la validación cuando 'shouldDisable' es falso
						then: Yup.string().required('Campo requerido'),
						otherwise: Yup.string(), // cuando 'shouldDisable' no esta en lista de poblaciones, no se aplica la validación
					}),
				jornada_laboral: Yup.string().required('Campo requerido'),
				vacante_confidencial: Yup.string().required('Campo requerido'),
				poblaciones_aceptadas: Yup.array().of(Yup.string()),
				discapacidad_aceptada: Yup.string()
					.when('poblaciones_aceptadas', {
						is: (poblaciones_aceptadas: string[]) => {
							return poblaciones_aceptadas?.indexOf(CODE_POBLACIONES_DISCAPACIDAD) > -1 || poblaciones_aceptadas?.indexOf('DISCAPACIDAD') > -1;
						},
						then: Yup.string().required('Campo requerido'),
						otherwise: Yup.string().nullable(), // cuando 'discapacidad' no esta en lista de poblaciones, no se aplica la validación
					}),
				grupo_etnico_aceptado: Yup.string()
					.when('poblaciones_aceptadas', {
						is: (poblaciones_aceptadas: string[]) => {
							return poblaciones_aceptadas?.indexOf(CODE_POBLACIONES_GRUPOETNICO) > -1 || poblaciones_aceptadas?.indexOf('GRUPOETNICO') > -1;
						},
						then: Yup.string().required('Campo requerido'),
						otherwise: Yup.string().nullable(), //cuando 'grupoEtnico' no esta en lista de poblaciones, no se aplica la validación
					}),
				// ocupacion: Yup.string()
				// 	.when(['forceRequired', 'shouldDisable'], {
				// 		is: (forceRequired: boolean, shouldDisable: boolean) => forceRequired == true && shouldDisable == false,//Si es consejero empresarial y no esta en modo disable, lo hace obligatorio
				// 		then: Yup.string().required('Campo requerido'),
				// 		otherwise: Yup.string().nullable()
				// 	}),
				descripcion_ocupacion: Yup.string()
					.when(['forceRequired', 'shouldDisable'], {
						is: (forceRequired: boolean, shouldDisable: boolean) => forceRequired == true && shouldDisable == false,//Si es consejero empresarial y no esta en modo disable, lo hace obligatorio
						then: Yup.string().required('Campo requerido'),
						otherwise: Yup.string().nullable()
					}),
				tipo_vacante: Yup.string().required('Campo requerido'),
				trabajo_suplementario: Yup.string().required('Campo requerido'),
				dias_laborales: Yup.string().required('Campo requerido'),
				horario_trabajo: Yup.string().required('Campo requerido'),
				empleo_verde: Yup.string().when('shouldDisable', {
					is: false, // solo aplica la validación cuando 'shouldDisable' es falso
					then: Yup.string().required('Campo requerido'),
					otherwise: Yup.string(),
				}),
				pagos_adicionales: Yup.string().when('shouldDisable', {
					is: false, // solo aplica la validación cuando 'shouldDisable' es falso
					then: Yup.string()
						.required('Campo requerido')
						.min(20, 'Mínimo 20 caracteres')
						.max(200, 'Máximo 200 caracteres'),
					otherwise: Yup.string(),
				}),
				numero_puestos: Yup.string().required('Campo requerido').test('minValue', 'Valor mínimo permitido es 1 y máximo 1000', (val) => { return Number(val) < 1 || Number(val) > 1000 ? false : true; })
				// vacante_gestionada_prestador: Yup.string().required('Campo requerido'),
			})
		}, [shouldDisable, isCounselorEmployer]
	);

	const fetchAndSetCUODById = async (id: string) => {
		const selectedCOUD =
			await parameterSingleton.fetchCUODSubgrupoById(id);
		setddlDescripcionCOUD(selectedCOUD);
	}


	//Variable de estado para detectar si las fechas han sido cambiadas, esto permite no aplicar las reglas de control cuando se esta editando la vacante pero no se cambian los valores de estos campos
	//Si se "edita" una vacante cuya fecha de aplicación e incoporacion es menor a la del sistema entonces no aplica las reglas de control
	const [changedFechaLimiteAplicacion, setChangedFechaLimiteAplicacion] = useState(false);
	const [openFechaLimiteAplicacion, setOpenFechaLimiteAplicacion] = useState(false);

	const handleNext = async (useData: any) => {
		const fechaLimiteAplicacion = userData['fecha_limite_aplicacion'];
		//Mapeo lista de poblaciones aceptadas
		setUserData(userData)
		//Solo aplica la validación si alguna de las fechas se han cambiado
		if (changedFechaLimiteAplicacion) {
			if (!isValidDateAndEqualOrAfterThan(fechaLimiteAplicacion, changedFechaLimiteAplicacion, true)) {
				setOpen(true);
				setAlert(false);
				setAlertMsg('Fecha límite de aplicación no debe ser menor o igual que la fecha del sistema');
				setTimeout(() => {
					setOpen(false);
				}, 4000);
				return;
			}
		} else if (!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max'])) {
			setOpen(true);
			setAlert(false);
			setAlertMsg(`Salario disponible y Máxima compensación: ${helperSalarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max'], 'La compensación máxima no puede ser inferior al salario ofrecido')}`);
			setTimeout(() => {
				setOpen(false);
			}, 8000);
			return;
		}

		// if (formik.isValid) setStep(6);
	};

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				const data = { ...userData, poblaciones_aceptadas: concatArrayToStringJoinBy(userData.poblaciones_aceptadas, ',', null) }
				const nextStep = () => {
					handleNext(data)
					setSubmitting(true)
					if (formik.isValid) setStep(6)
					setStatus('')
				}
				if (data.status_approval === 'TEMPORAL') return putVacancyTempral(data).then((res) => {
					nextStep()
				}).catch((error) => {
					console.log(error);
				});
				else nextStep()
			} catch (error) {
				console.error(error);
			}
		},
	});

	return (
		<>
			{loadingParams
				? <LinearLoadingSpinner isLoading={true} />
				:
				<>
					<Box sx={{ mb: 3 }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
							Requisitos de la Vacante
						</Typography>
					</Box>
					<Grid container direction='row' justifyContent='left' width={'100%'} item>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={(formik.touched.fecha_limite_aplicacion && Boolean(formik.errors.fecha_limite_aplicacion)) || !isValidDateAndEqualOrAfterThan(formik.getFieldProps('fecha_limite_aplicacion').value, openFechaLimiteAplicacion || changedFechaLimiteAplicacion, true)}>
									<DatePicker
										disablePast
										format='DD/MM/YYYY'
										value={dayjs(formik.values.fecha_limite_aplicacion, 'YYYY-MM-DD')}
										onChange={(e) => {
											setUserData({ ...userData, fecha_limite_aplicacion: e?.format('YYYY-MM-DD') });
											setChangedFechaLimiteAplicacion(true);
											formik.setFieldTouched('fecha_limite_aplicacion', true);
											formik.setFieldValue("fecha_limite_aplicacion", e?.format('YYYY-MM-DD'))
										}}
										slotProps={{
											textField: {
												onClick: () => setOpenFechaLimiteAplicacion(true),
												required: true,
												fullWidth: true,
												id: 'fecha_limite_aplicacion',
												label: 'Fecha límite de aplicación',
												variant: 'outlined',
												error: (formik.touched.fecha_limite_aplicacion && Boolean(formik.errors.fecha_limite_aplicacion)) || !isValidDateAndEqualOrAfterThan(formik.getFieldProps('fecha_limite_aplicacion').value, openFechaLimiteAplicacion || changedFechaLimiteAplicacion, true),
												helperText: (formik.touched.fecha_limite_aplicacion && Boolean(formik.errors.fecha_limite_aplicacion)) ? 'Campo requerido' : helperIsValidDateAndEqualOrAfterThan(formik.getFieldProps('fecha_limite_aplicacion').value, openFechaLimiteAplicacion || changedFechaLimiteAplicacion, true)
											},
											actionBar: { actions: ["clear", "cancel", "today"] }
										}}
										disabled={shouldDisable}
									/>
								</FormControl>
							</LocalizationProvider>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.tipo_contrato && Boolean(formik.errors.tipo_contrato)}>
								<InputLabel required id='tipo_contrato'>
									Tipo de contrato
								</InputLabel>
								<Select
									{...formik.getFieldProps('tipo_contrato')}
									error={formik.touched.tipo_contrato && Boolean(formik.errors.tipo_contrato)}
									labelId='tipo_contrato'
									id='tipo_contrato'
									required
									label='Tipo de contrato'
									value={initValue(userData['tipo_contrato'])}
									onChange={(e) => {
										setUserData({ ...userData, tipo_contrato: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listTipoContrato.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.tipo_contrato ? formik.errors.tipo_contrato : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.jornada_laboral && Boolean(formik.errors.jornada_laboral)}>
								<InputLabel required id='jornada_laboral'>
									Jornada laboral
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('jornada_laboral')}
									error={formik.touched.jornada_laboral && Boolean(formik.errors.jornada_laboral)}
									labelId='jornada_laboral'
									id='jornada_laboral'
									label='Jornada laboral'
									value={initValue(userData['jornada_laboral'])}
									onChange={(e) => {
										setUserData({ ...userData, jornada_laboral: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listJornadaLaboral.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.jornada_laboral ? formik.errors.jornada_laboral : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.vacante_confidencial && Boolean(formik.errors.vacante_confidencial)}>
								<InputLabel required id='vacante_confidencial'>
									Esta vacante debe ser confidencial
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('vacante_confidencial')}
									error={formik.touched.vacante_confidencial && Boolean(formik.errors.vacante_confidencial)}
									labelId='vacante_confidencial'
									id='vacante_confidencial'
									label='Esta vacante debe ser confidencial'
									value={initValue(userData['vacante_confidencial'])}
									onChange={(e) => {
										setUserData({ ...userData, vacante_confidencial: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.vacante_confidencial ? formik.errors.vacante_confidencial : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.trabajo_suplementario && Boolean(formik.errors.trabajo_suplementario)}>
								<InputLabel required id='trabajo_suplementario'>
									Se requiere trabajo suplementario
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('trabajo_suplementario')}
									error={formik.touched.trabajo_suplementario && Boolean(formik.errors.trabajo_suplementario)}
									labelId='trabajo_suplementario'
									id='trabajo_suplementario'
									label='Se requiere trabajo suplementario'
									value={initValue(userData['trabajo_suplementario'])}
									onChange={(e) => {
										setUserData({ ...userData, trabajo_suplementario: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.trabajo_suplementario ? formik.errors.trabajo_suplementario : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.tipo_vacante && Boolean(formik.errors.tipo_vacante)}>
								<InputLabel required id='tipo_vacante'>
									Tipo de vacante
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('tipo_vacante')}
									error={formik.touched.tipo_vacante && Boolean(formik.errors.tipo_vacante)}
									labelId='tipo_vacante'
									id='tipo_vacante'
									label='Tipo de vacante'
									value={initValue(userData['tipo_vacante'])}
									onChange={(e) => {
										setUserData({ ...userData, tipo_vacante: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{/* TO-DO remover la logica del filter y dejar solo un map una vez haya terminado la homologacion de los datos del valor local*/}
									{listTipoVacante.filter(({ code, value }, i) => value !== 'Local').map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
									{listTipoVacante.filter(({ code, value }, i) => value === 'Local').map(({ code, value }, i) => (
										<MenuItem disabled value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.tipo_vacante ? formik.errors.tipo_vacante : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.dias_laborales && Boolean(formik.errors.dias_laborales)}>
								<InputLabel required id='dias_laborales'>
									Días laborales
								</InputLabel>
								<Select
									{...formik.getFieldProps('dias_laborales')}
									error={formik.touched.dias_laborales && Boolean(formik.errors.dias_laborales)}
									labelId='dias_laborales'
									id='dias_laborales'
									required
									label='Días laborales'
									value={initValue(userData['dias_laborales'])}
									onChange={(e) => {
										setUserData({ ...userData, dias_laborales: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listDiasLaborales.map(({ code, value }, i) => (
										<MenuItem value={code} key={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.dias_laborales ? formik.errors.dias_laborales : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.horario_trabajo && Boolean(formik.errors.horario_trabajo)}>
								<InputLabel required id='horario_trabajo'>
									Horario de trabajo
								</InputLabel>
								<Select
									{...formik.getFieldProps('horario_trabajo')}
									error={formik.touched.horario_trabajo && Boolean(formik.errors.horario_trabajo)}
									labelId='horario_trabajo'
									id='horario_trabajo'
									required
									label='Horario de trabajo'
									value={initValue(userData['horario_trabajo'])}
									onChange={(e) => {
										setUserData({ ...userData, horario_trabajo: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listHorarioTrabajo.map(({ code, value }, i) => (
										<MenuItem value={code} key={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.horario_trabajo ? formik.errors.horario_trabajo : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.numero_puestos && Boolean(formik.errors.numero_puestos)}>
								<TextField
									fullWidth
									required
									{...formik.getFieldProps('numero_puestos')}
									error={formik.touched.numero_puestos && Boolean(formik.errors.numero_puestos)}
									id='numero_puestos'
									name='numero_puestos'
									label='Número de puestos de trabajo'
									value={initValue(userData['numero_puestos'])}
									onKeyDown={(e: any) => {
										eventOnKeyDownInputNumber(e);
									}}
									onChange={(e) => {
										if (e?.target?.value?.length <= 4) {
											setUserData({ ...userData, numero_puestos: e.target.value });
											formik.handleChange(e);
										}
									}}
									disabled={shouldDisable}
									inputProps={{ type: 'number', maxLength: 4, min: 1, max: 1000 }}
									size='medium'
								/>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.numero_puestos ? formik.errors.numero_puestos : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4.5} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium' error={(formik.touched.salario_mensual && Boolean(formik.errors.salario_mensual)) || (!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']))}>
								<InputLabel required id='salario_mensual'>
									¿Cuál es el salario disponible para esta vacante?
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('salario_mensual')}
									error={(formik.touched.salario_mensual && Boolean(formik.errors.salario_mensual)) || (!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']))}
									labelId='salario_mensual'
									id='salario_mensual'
									label='¿Cuál es salario disponible para esta vacante?'
									value={initValue(userData['salario_mensual'])}
									onChange={(e) => {
										setUserData({ ...userData, salario_mensual: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSalarioMensual.map(({ code, value, label }, i) => (
										(code !== '0' && code !== '-1' || label === "A Convenir") &&
										<MenuItem value={code} key={i}>
											{label === "A Convenir" ? label : `${formatNumber(value)} - ${label}`}
										</MenuItem>
									))}

									{listSalarioMensual.map(({ code, label }, i) => (
										code === '-1' && <MenuItem disabled value={code} key={i}>
											{label}
										</MenuItem>
									))}
									{/* {listSalario.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))} */}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']) ? helperSalarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max'], 'La compensación máxima no puede ser inferior al salario ofrecido') : formik.touched.salario_mensual ? formik.errors.salario_mensual : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4.5} mb={5} pr={2} item>
							<OverlayTrigger
								placement='top'
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => (
									<Tooltip id='button-tooltip' {...props}>
										¿Si se presentase un candidato ideal, cuál es la máxima compensación que la empresa estaría dispuesta a proveer para esta vacante?
										<br />
										Recuerda que debe ser mayor al salario ofrecido
									</Tooltip>
								)}
							>
								<FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium' error={(formik.touched.salario_mensual_max && Boolean(formik.errors.salario_mensual_max)) || (!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']))}>
									<InputLabel required id='salario_mensual_max' >
										<span>
											{' '}
											¿Cual sería la máxima compensación al candidato ideal?
											<span
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></span>
										</span>
									</InputLabel>
									<Select
										required
										{...formik.getFieldProps('salario_mensual_max')}
										error={(formik.touched.salario_mensual_max && Boolean(formik.errors.salario_mensual_max)) || (!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']))}
										labelId='salario_mensual_max'
										id='salario_mensual_max'
										label='¿Cual sería la máxima compensación al candidato ideal? '
										value={initValue(userData['salario_mensual_max'])}
										onChange={(e) => {
											setUserData({ ...userData, salario_mensual_max: e.target.value });
											formik.handleChange(e);
										}}
										disabled={shouldDisable}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listSalarioMensual.map(({ code, value, label }, i) => (
											(code !== '0' && code !== '-1' || label === "A Convenir") &&
											<MenuItem value={code} key={i}>
												{label === "A Convenir" ? label : `${formatNumber(value)} - ${label}`}
											</MenuItem>
										))}

										{listSalarioMensual.map(({ code, label }, i) => (
											code === '-1' && <MenuItem disabled value={code} key={i}>
												{label}
											</MenuItem>
										))}
									</Select>
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{!shouldDisable && !salarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max']) ? helperSalarioMinIsGreaterThanSalarioMax(userData['salario_mensual'], userData['salario_mensual_max'], 'La compensación máxima no puede ser inferior al salario ofrecido') : formik.touched.salario_mensual_max ? formik.errors.salario_mensual_max : ''}
									</FormHelperText>
								</FormControl>
							</OverlayTrigger>
						</Grid>
						<Grid xs={12} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('pagos_adicionales')}
								required
								label='Pagos adicionales al salario'
								placeholder='Pagos adicionales al salario'
								onChange={(e) => {
									setUserData({ ...userData, pagos_adicionales: e.target.value })
									formik.handleChange(e)
								}}
								multiline
								value={initValue(userData['pagos_adicionales'])}
								minRows={2}
								maxRows={5}
								inputProps={{ minLength: 20, maxLength: 200 }}
								error={!shouldDisable && formik.touched.pagos_adicionales && !isValidMinMaxText(userData['pagos_adicionales'], 20, 200, true)}
								helperText={(!shouldDisable && (helperValidMinMaxText(userData['pagos_adicionales'], 20, 200, true) != 'Campo requerido' || formik.touched.pagos_adicionales)) ? helperValidMinMaxText(userData['pagos_adicionales'], 20, 200, true) : '0/200'}
								disabled={shouldDisable}
							/>
						</Grid>
						<Grid xs={12} md={1.5} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={!shouldDisable && formik.touched.empleo_verde && Boolean(formik.errors.empleo_verde)}>
								<InputLabel required id='empleo_verde'>
									Empleo verde
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('empleo_verde')}
									error={!shouldDisable && formik.touched.empleo_verde && Boolean(formik.errors.empleo_verde)}
									labelId='empleo_verde'
									id='empleo_verde'
									label='Empleo verde'
									value={initValue(userData['empleo_verde'])}
									onChange={(e) => {
										setUserData({ ...userData, empleo_verde: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{!shouldDisable && formik.touched.empleo_verde ? formik.errors.empleo_verde : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						{/* <Grid xs={12} md={5.25} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium' error={!shouldDisable && formik.touched.ocupacion && Boolean(formik.errors.ocupacion)}>
								<InputLabel required={isCounselorEmployerRole()} id='ocupacion'>
									Ocupación de la vacante
								</InputLabel>
								<Select
									required={isCounselorEmployerRole()}
									{...formik.getFieldProps('ocupacion')}
									error={!shouldDisable && formik.touched.ocupacion && Boolean(formik.errors.ocupacion)}
									labelId='ocupacion'
									id='ocupacion'
									label='Ocupacion de la vacante'
									value={initValue(userData['ocupacion'])}
									onChange={(e) => {
										setUserData({ ...userData, ocupacion: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listCUOCOcupacion.map(({ code, value, label, id }, i) => (
										<MenuItem value={code} key={i}>
											{label}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{!shouldDisable && formik.touched.ocupacion ? formik.errors.ocupacion : ''}
								</FormHelperText>
							</FormControl>
						</Grid> */}
						<Grid xs={12} md={5.25} mb={5} pr={2} item>
							<FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium'
								error={!shouldDisable && formik.touched.descripcion_ocupacion && Boolean(formik.errors.descripcion_ocupacion)}>
								<InputLabel required={isCounselorEmployerRole()} id='descripcion_ocupacion'>
									Descripción ocupación de la vacante
								</InputLabel>
								<Select
									required={isCounselorEmployerRole()}
									{...formik.getFieldProps('descripcion_ocupacion')}
									error={!shouldDisable && formik.touched.descripcion_ocupacion && Boolean(formik.errors.descripcion_ocupacion)}
									labelId='descripcion_ocupacion'
									id='descripcion_ocupacion'
									label='Descripcion Ocupacion de la vacante'
									value={initValue(userData['descripcion_ocupacion'])}
									onChange={(e) => {
										setUserData({ ...userData, descripcion_ocupacion: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{
										listCUOCSubGrupo.map(({ code, value, label, id_param_parent }, i) => (
											<MenuItem value={code} key={i}>
												{code} - {label}
											</MenuItem>
										))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{!shouldDisable && formik.touched.descripcion_ocupacion ? formik.errors.descripcion_ocupacion : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%', maxWidth: '100%', marginLeft: '0', marginTop: '0' }} size='medium' error={!shouldDisable && formik.touched.poblaciones_aceptadas && Boolean(formik.errors.poblaciones_aceptadas)}>
								<InputLabel>Poblaciones aceptadas</InputLabel>
								<Select
									labelId="poblaciones_aceptadas"
									id="poblaciones_aceptadas"
									multiple
									value={poblacionesAceptadas}
									label='Poblaciones aceptadas'
									onChange={handleChangePoblaciones}
									disabled={shouldDisable}
									renderValue={(selected) => (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((selected_value) => (
												<Chip key={selected_value} label={listPoblaciones.find(({ code }) => code == selected_value)?.label} />
											))

											}
										</Box>
									)}
								>
									{listPoblaciones.map(({ id, code, label }) => (
										<MenuItem
											key={id}
											value={code}
										>
											<Checkbox checked={poblacionesAceptadas?.indexOf(code) > -1} />
											{label}

										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{!shouldDisable && formik.touched.poblaciones_aceptadas ? formik.errors.poblaciones_aceptadas : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						{(poblacionesAceptadas?.indexOf(CODE_POBLACIONES_GRUPOETNICO) > -1 || poblacionesAceptadas?.indexOf('GRUPOETNICO') > -1) && (
							<>
								<Grid xs={12} md={6} pr={2} mb={5} item={true}>
									<FormControl fullWidth size='medium' error={!shouldDisable && formik.touched.grupo_etnico_aceptado && Boolean(formik.errors.grupo_etnico_aceptado)}>
										<InputLabel required id='grupo_etnico_aceptado'>
											Grupo étnico aceptado
										</InputLabel>
										<Select
											required={
												poblacionesAceptadas?.indexOf(CODE_POBLACIONES_GRUPOETNICO) > -1 || poblacionesAceptadas?.indexOf('GRUPOETNICO') > -1
											}
											{...formik.getFieldProps('grupo_etnico_aceptado')}
											error={!shouldDisable && formik.touched.grupo_etnico_aceptado && Boolean(formik.errors.grupo_etnico_aceptado)}
											labelId='grupo_etnico_aceptado'
											id='grupo_etnico_aceptado'
											label='Grupo étnico aceptado'
											value={initValue(userData['grupo_etnico_aceptado'])}
											onChange={(e) => {
												setUserData({ ...userData, grupo_etnico_aceptado: e.target.value });
												formik.handleChange(e);
											}}
											disabled={shouldDisable}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listGrupoEtnico.map(({ code, value, label }, i) => (
												<MenuItem value={code} key={i}>
													{value}
												</MenuItem>
											))}
										</Select>
										{
											(poblacionesAceptadas?.indexOf(CODE_POBLACIONES_GRUPOETNICO) > -1 || poblacionesAceptadas?.indexOf('GRUPOETNICO') > -1) &&
											!isValidFieldRequired(userData['grupo_etnico_aceptado']) && (
												<FormHelperText sx={{ color: '#d32f2f' }}>
													{!shouldDisable && formik.touched.grupo_etnico_aceptado ? formik.errors.grupo_etnico_aceptado : ''}
												</FormHelperText>
											)}
									</FormControl>
								</Grid>
							</>)}
						{(poblacionesAceptadas?.indexOf(CODE_POBLACIONES_DISCAPACIDAD) > -1 || poblacionesAceptadas?.indexOf('DISCAPACIDAD') > -1) && (
							<>
								<Grid xs={12} md={6} pr={2} mb={5} item={true}>
									<FormControl fullWidth size='medium' error={!shouldDisable && formik.touched.discapacidad_aceptada && Boolean(formik.errors.discapacidad_aceptada)}>
										<InputLabel required id='discapacidad_aceptada'>
											Discapacidad aceptada
										</InputLabel>
										<Select
											required={poblacionesAceptadas?.indexOf(CODE_POBLACIONES_DISCAPACIDAD) > -1 || poblacionesAceptadas?.indexOf('DISCAPACIDAD') > -1}
											{...formik.getFieldProps('discapacidad_aceptada')}
											error={formik.touched.discapacidad_aceptada && Boolean(formik.errors.discapacidad_aceptada)}
											labelId='discapacidad_aceptada'
											id='discapacidad_aceptada'
											label='Discapacidad aceptada'
											value={initValue(userData['discapacidad_aceptada'])}
											onChange={(e) => {
												setUserData({ ...userData, discapacidad_aceptada: e.target.value });
												formik.handleChange(e);
											}}
											disabled={shouldDisable}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listDiscapacidades.map(({ code, label }, i) => (
												<MenuItem value={code} key={i}>
													{label}
												</MenuItem>
											))}
										</Select>
										{
											(poblacionesAceptadas?.indexOf(CODE_POBLACIONES_DISCAPACIDAD) > -1 || poblacionesAceptadas?.indexOf('DISCAPACIDAD') > -1) &&
											!isValidFieldRequired(userData['discapacidad_aceptada']) && (
												<FormHelperText sx={{ color: '#d32f2f' }}>
													{!shouldDisable && formik.touched.discapacidad_aceptada ? formik.errors.discapacidad_aceptada : ''}
												</FormHelperText>
											)}
									</FormControl>
								</Grid>
							</>)}
					</Grid>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={() => {
								setStep(4);
							}}
						>
							Atrás
						</Button>
						<Button variant='contained'
							sx={{ mr: 1, width: 90 }}
							onClick={() => {
								formik.handleSubmit();
							}}
						>
							Siguiente
						</Button>
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={() => setOpen(false)}
					>
						<Alert
							onClose={() => setOpen(false)}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
							{alertMsg}
						</Alert>
					</Snackbar>
				</>
			}
		</>
	);
};
