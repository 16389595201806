import { useEffect, useState } from 'react';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { useNavigate, Link, useLocation, useOutletContext } from 'react-router-dom';
import styleScss from './styles.module.scss';
import {
	deleteVacancyById,
	updateVacancyStatus,
	getVagaCountCurriculoAll,
	updateVacancyStatusApproval,
	vacancyTempral,
	deleteVacancyTempral,
	createVacancy,
} from '../../modules/apps/user-management/users-list/core/_requests';
import ViewListIcon from '@mui/icons-material/ViewList';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { ListStatusApprovalModel } from '../../models/StatusApprovalModel'
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	Paper,
	Select,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { convertDateISOToDateString, initValue, truncateWords } from '../../util/Index';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { ManageAccounts, RemoveRedEye, StickyNote2, Verified, Unpublished, Flaky, AddCircle } from '@mui/icons-material';
import { useAuth } from '../../modules/auth';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import { OverlayTrigger, Tooltip as BootTip, Table } from 'react-bootstrap';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';







const EmployerManagementVacancyPage = () => {
	//alert y data de borrador 

	const [borrador, setBorrador] = useState<Record<string, any>>()

	const [openBorrador, setOpenBorrador] = useState(false);



	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [openModalConfirmDeleteVacante, setOpenModalConfirmDeleteVacante] = useState(false);
	const [qtdVacante, setQtdVacante] = useState('0');
	const [idVaga, setIdVaga] = useState(0);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [description, setDescription] = useState("")
	const [touchedDescription, setTouchedDescription] = useState(false)
	const [errorDescription, setErrorDescription] = useState(true)
	const [vacancyApproval, setVacancyAproval] = useState<any>({
		id: Number,
		status_approval: '',
		description: ''
	});
	const [alignment, setAlignment] = useState<string>('list');


	// Abre el diálogo
	const handleClickOpenBorrador = () => {
		setOpenBorrador(true);
	};

	// Cierra el diálogo solo si se hizo clic en "Agree" o "Disagree"
	const handleCloseBorrador = (action: any) => {
		if (action === 'agree') {
			console.log('Usuario aceptó');
			// Agregar la lógica cuando el usuario da su consentimiento
			if (borrador?.data?.id) {
				navigate(getPathEditarVacante(borrador?.data?.id))
			}
		} else if (action === 'disagree') {
			console.log('Usuario no aceptó');
			// Agregar la lógica cuando el usuario no da su consentimiento
			navigate(getPathRegistroVacante());
			setBorrador(undefined)
			deleteVacancyTempral().then((resp) => {
				console.log(resp)
			})
		}
		setOpenBorrador(false); // Cierra el diálogo
	};
	//alert y data de borrador 

	useEffect(() => {
		vacancyTempral().then((resp) => {
			setBorrador(resp)
		}).catch((error) => {
			console.log(error)
		})
	}, [])


	const handleChangeView = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		setAlignment(newAlignment);
	};
	const navigate = useNavigate();
	const ToggleButton = styled(MuiToggleButton)({
		'&.Mui-selected, &.Mui-selected:hover': {
			color: 'white',
			backgroundColor: '#4683DE',
		},
	});
	// Control del mensaje de rechazar vacante
	const [openRechazada, setOpenRechazada] = useState(false);
	const handleOpenRechazada = (id: number) => {
		setOpenRechazada(true);
		setVacancyAproval({ id: id, status_approval: 'RECHAZADA', description: '' });
	};
	const handleCloseRechazada = () => {
		setOpenRechazada(false);
		setVacancyAproval({ id: undefined, status_approval: '', description: '' });
		setTouchedDescription(false);
		setErrorDescription(true)
	};
	// Control de mensaje para aprobar vacante
	const [openAprobada, setOpenAprobada] = useState(false);
	const handleOpenAprobada = (id: number) => {
		setOpenAprobada(true);
		setVacancyAproval({ id: id, status_approval: 'APROBADA', description: '' });
	};
	const handleCloseAprobada = () => {
		setOpenAprobada(false);
		setVacancyAproval({ id: undefined, status_approval: '', description: '' })
	};
	// Control del mensaje para pasar vacante de rechazada a en analisis
	const [openAnalisis, setOpenAnalisis] = useState(false);
	const handleOpenAnalisis = (id: number) => {
		setOpenAnalisis(true);
		setVacancyAproval({ id: id, status_approval: 'EN_ANALISIS', description: '' });
	};
	const handleCloseAnalisis = () => {
		setOpenAnalisis(false);
		setVacancyAproval({ id: undefined, status_approval: '', description: '' })
	};
	// Control del mensaje informativo a cerca del motivo de rechazo de vacante
	const [openMotivo, setOpenMotivo] = useState(false);
	const handleOpenMotivo = (motivo: string) => {
		setDescription(motivo);
		setOpenMotivo(true);
	}
	const handleCloseMotivo = () => {
		setDescription("");
		setOpenMotivo(false);
	}

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
		estado: ''
	});


	const disableMatching = (/true/).test(process.env.REACT_APP_DISABLE_MATCHING_COMPONENTS || '');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);


	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, name: filter.name, role: filter.role, estado: filter.estado };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), name: filter.name, role: filter.role, estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};


	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getVacancyAPI();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = {
			...filter, // Copia todas as propriedades existentes do objeto filter
			page: pf.page, // Atualiza a propriedade page com o novo valor
			limit: pf.limit, // Atualiza a propriedade limit com o novo valor
		};
		setFilter(f);
	};

	/**
	 * Obtiene todas las vacantes
	 */
	const getVacancyAPI = async () => {
		try {
			setLoading(true);
			const response = await getVagaCountCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}${filter.name !== '' ? '&vacante=' + filter.name : ''
				}${filter.estado !== '' ? '&estado=' + filter.estado : ''}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseModalConfirmDeleteVacante = () => {
		setOpenModalConfirmDeleteVacante(false);
	};

	const modalConfirmDeleteVacante = (vacante: any) => {
		setQtdVacante(vacante.numero_candidatos);
		setIdVaga(vacante.id);
		setOpenModalConfirmDeleteVacante(true);
	};

	const deleteVacancy = async () => {
		try {
			setOpenModalConfirmDeleteVacante(false);
			setRequestInProgress(true);
			await deleteVacancyById(`id=${idVaga}`);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Vacante eliminada con éxito!`);
			setFilter({ ...filter });
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setTimeout(() => {
				setOpen(false);
			}, 3000);
			setRequestInProgress(false);
		}
	};
	const changeVacancyStatus = async () => {
		try {
			setIsSubmitting(true);
			const respuesta = await updateVacancyStatusApproval(vacancyApproval);
			setLoading(true);
			setAlertMsg(respuesta.data?.message);
			setOpen(true);
			setAlert(true);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 6000);
			getVacancyAPI();
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 6000);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
			setTimeout(() => {
				setIsSubmitting(false);
			}, 2000);
			setOpenRechazada(false);
			setOpenAprobada(false);
			setOpenAnalisis(false);
		}
	};
	/**
	* Retorna el path del usuario acorde al rol para edicion de vacante
	* @returns 
	*/
	const getPathEditarVacante = (idVacante: number) => {
		if (isAdminRole()) {
			return `/admin/gestion-de-vacante/${idVacante}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/gestion-de-vacante/${idVacante}`;
		} else if (isEmployerRole()) {
			return `/empleador/gestion-de-vacante/${idVacante}`;
		} else {
			return 'auth/*';
		}
	}

	/**
* Retorna el path del usuario acorde al rol para la informacion adicional de vacante
* @returns 
*/
	const getPathInformacionAdicionalVacante = (idVacante: number) => {
		if (isAdminRole()) {
			return `/admin/adicional-vacante/${idVacante}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/adicional-vacante/${idVacante}`;
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path del usuario acorde al rol para matchin de la vacante
	 * @returns 
	 */
	const getPathMatchingCandidatos = (idVacante: number) => {
		if (isAdminRole()) {
			return `/admin/matching-candidatos/${idVacante}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/matching-candidatos/${idVacante}`;
		} else if (isEmployerRole()) {
			return `/empleador/matching-candidatos/${idVacante}`;
		} else {
			return 'auth/*';
		}
	}

	



	/**
	* Retorna el path del usuario acorde al rol para registro de la vacante
	* @returns 
	*/
	const getPathRegistroVacante = () => {
		if (isAdminRole()) {
			return '/admin/gestion-de-vacante';
		} else if (isCounselorEmployerRole()) {
			return '/consejero/gestion-de-vacante';
		} else if (isEmployerRole()) {
			return '/empleador/gestion-de-vacante';
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */
	const isAdminOrCounselor = () => {
		return isAdminRole() || isCounselorEmployerRole();
	}

	/**
	* Retorna el path del usuario acorde al rol para ver los candidatos postulados
	* @returns 
	*/
	const getPathCandidatosPostulados = (idVacante: number) => {
		if (isAdminRole()) {
			return `/admin/candidatos-postula/${idVacante}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/candidatos-postula/${idVacante}`;
		} else if (isEmployerRole()) {
			return `/empleador/candidatos-postula/${idVacante}`;
		} else {
			return 'auth/*';
		}
	}


	/**
	 * Construye las opciones del menú
	 * @param props 
	 * @returns 
	 */
	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const { data } = props;

		const updateUserStatus = async () => {
			try {
				setRequestInProgress(true);
				await updateVacancyStatus({ id: data.id, status: !data.status });
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				setAlertMsg(`Vacante ${data.status ? 'inactivada' : 'activada'} con éxito!`)
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 2000);
				setRequestInProgress(false);
			}
		};

		return (
			<>



				{/* alertBorrador */}

				<Dialog
					open={openBorrador}
					onClose={(e, reason) => {
						// Si el motivo del cierre es un clic en el fondo, no hacer nada
						if (reason === 'backdropClick') {
							return;
						}
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					sx={{
						'& .MuiBackdrop-root': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)', // Fondo gris claro con transparencia
						},
						'& .MuiPaper-root': {
							boxShadow: 'none', // Elimina la sombra del contenedor principal
						},
					}}


				>
					<DialogTitle id="alert-dialog-title">
						{"Tienes un borrador pendiente"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Has dejado un borrador sin terminar. Puedes retomarlo para continuar editándolo o descartarlo. <b>Si decides descartarlo, se eliminará de forma permanente.</b>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						{/* Al hacer clic en Disagree o Agree, se pasa la acción correspondiente */}
						<Button onClick={() => handleCloseBorrador('disagree')} variant="contained" style={{ background: '#D3312F' }}>Descartar y crear nueva vacante</Button>
						<Button onClick={() => handleCloseBorrador('agree')} autoFocus variant="contained" style={{ backgroundColor: '#2266CC', }}>
							Seguir editando este borrador de vacante
						</Button>
					</DialogActions>
				</Dialog>
				{/* alertBorrador */}






				{/* <LoadingBackdrop loading={isSubmitting} /> */}
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose}>
						<Link to={getPathEditarVacante(data.id)}>
							{(data.status_approval === 'EN_ANALISIS') ? (
								<>
									<BorderColorIcon />
									&nbsp; Editar
								</>
							) : (
								<>
									<RemoveRedEye />
									&nbsp; Ver
								</>
							)}
						</Link>
					</MenuItem>



					{(isAdminOrCounselor()) && (
						<MenuItem onClick={handleClose}>
							<Link to={getPathInformacionAdicionalVacante(data.id)}>
								<AddCircle />
								&nbsp; Información Adicional
							</Link>
						</MenuItem>
					)}
					{isAdminRole() && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => modalConfirmDeleteVacante(data)}>
								<DeleteIcon />
								&nbsp; Eliminar
							</a>
						</MenuItem>
					)}
					{!disableMatching && (
						<MenuItem onClick={handleClose}>
							<Link to={getPathMatchingCandidatos(data.id)}>
								<EqualizerIcon />
								&nbsp; Matching
							</Link>
						</MenuItem>
					)}
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={updateUserStatus}>
							<ManageAccounts />
							&nbsp; Activar/Inactivar
						</a>
					</MenuItem>
					{(isAdminOrCounselor() && data.status_approval == 'EN_ANALISIS') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => handleOpenAprobada(data.id)}>
								<Verified />
								&nbsp; Aprobar
							</a>
						</MenuItem>
					)}
					{(isAdminOrCounselor() && data.status_approval == 'EN_ANALISIS') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => handleOpenRechazada(data.id)}>
								<Unpublished />
								&nbsp; Rechazar
							</a>
						</MenuItem>
					)}
					{(isAdminOrCounselor() && data.status_approval == 'RECHAZADA') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => handleOpenAnalisis(data.id)}>
								<Flaky />
								&nbsp; En análisis
							</a>
						</MenuItem>
					)}{(data.status_approval == 'RECHAZADA') && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => handleOpenMotivo(data.description_approval)}>
								<StickyNote2 />
								&nbsp; Motivo de rechazo
							</a>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		setPage(0);
		setFilter({ ...filter, page: 1, name: textoBuscar });
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-xs-12 col-sm-10 col-md-10'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 1px 1px 15px' }}>
								<div className='input-group mb-3'>
									<FormControl sx={{ minWidth: 120 }} fullWidth size="small">
										<InputLabel id='estado_aprobacion'
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Estado</InputLabel>
										<Select
											labelId='estado_aprobacion'
											id='estado_aprobacion'
											label='Estado'
											value={initValue(filter.estado)}
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
											onChange={(e) => {
												setFilter({
													...filter,
													page: 1,
													estado: e.target.value
												});
												setPage(0);
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{ListStatusApprovalModel.map((item) => (
												<MenuItem key={item.code} value={item.code}>
													{item.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className='col-xs-3 col-sm-5 col-md-5' style={{ padding: '10px 0px 0px 15px' }}>
								<div className='input-group mb-3'>
									<input type='text'
										className='form-control'
										aria-label='Buscar por Nombre/Código Vacante'
										aria-describedby='basic-addon2'
										placeholder='Buscar por Nombre/Código Vacante'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										id='searchVacancy'
										onClick={filtraTextoBuscar}>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							<div className='col-sm-5 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<Button className='btn btn-primary btn-lg'
									disabled={borrador ? false : true}
									onClick={borrador?.data?.status_approval ? handleClickOpenBorrador : () => navigate(getPathRegistroVacante())}
									style={{
										fontFamily: 'Poppins',
										padding: '9px 20px',
										backgroundColor: '#2266CC',
										fontSize: '15px',
										color: 'white',
										textTransform: 'initial',
										borderRadius: '10px'
									}} >
									Registrar vacante
								</Button>

							</div>
						</div>
					</div>
					<div className='col-xs-12 col-sm-2 col-md-2 d-flex justify-content-end' style={{ float: 'right' }}>
						<ToggleButtonGroup
							value={alignment}
							exclusive
							onChange={handleChangeView}
							aria-label='view'
							size='medium'
						>
							<ToggleButton value='list' aria-label='list'>
								<ViewListIcon />
							</ToggleButton>
							<ToggleButton value='module' aria-label='module'>
								<ViewModuleIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{(alignment == 'list') ? (
					<div className={`${styleScss['table2']} ${styleScss['table-limit']}`} style={{ width: '100%' }}>
						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', width: '10%' }}>
											<h4>Fecha creación</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Código</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word' }}>
											<h4>Nombre de la vacante</h4>
										</TableCell>
										{!isEmployerRole() ? (
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', paddingLeft: '8px' }}>
												<h4>Empresa</h4>
											</TableCell>
										) : null}
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', width: '5%' }}>
											<h4>N° Puestos disponibles</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', width: '5%' }}>
											<h4>N° Aplicantes</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Estado</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Activo</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Acciones</h4>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{!loading && data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'nowrap', maxWidth: '1%', textAlign: 'center' }} title={convertDateISOToDateString(x['createdAt'])}>
												{convertDateISOToDateString(x['createdAt'])}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'nowrap', textAlign: 'center' }} title={x['codigo_vacante']} className={`${styleScss['td-break']}`}>
												{x['codigo_vacante']}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['td-break']}`}>
												<OverlayTrigger
													placement='left'
													delay={{ show: 40, hide: 400 }}
													overlay={(props) => (
														<BootTip id='tooltip' {...props}>
															<div dangerouslySetInnerHTML={{ __html: x['nombre_vacante'] }} />
														</BootTip>
													)}
												>
													<p className={`card-text`}>
														{truncateWords(x['nombre_vacante'], 40)}
													</p>
												</OverlayTrigger>
											</TableCell>
											{!isEmployerRole() ? (
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} title={x.Empresa?.razon_social}>{x.Empresa?.razon_social}</TableCell>
											) : null}
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} title={x['numero_puestos']} className={`${styleScss['td-break']}`}>
												{x['numero_puestos']}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} title={x['candidatos']} className={`${styleScss['td-break']}`}>
												<Link to={getPathCandidatosPostulados(x['id'])}>
													{x?.CurriculoVagaEmpresas?.length ? x?.CurriculoVagaEmpresas?.length : '0'}
												</Link>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', textAlign: 'center' }} >
												{(() => {
													const statusKey = x['status_approval'];
													const statusObject = ListStatusApprovalModel.find(item => item.code === statusKey);

													return statusObject ? statusObject.value : statusKey;
												})()}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} title={x['status'] ? 'Sí' : 'No'}>{x['status'] ? 'Sí' : 'No'}</TableCell>
											<TableCell style={{ textAlign: 'center' }}>
												<MenuAction
													data={{
														id: x.id,
														status: x.status,
														status_approval: x.status_approval,
														description_approval: x.description_approval,
														numero_candidatos: x?.CurriculoVagaEmpresas?.length
															? x?.CurriculoVagaEmpresas?.length
															: 0,
													}}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>

								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={7}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}
							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>

					</div>
				) : (

					<><Grid container item xs={12}>
						{loading && <LinearLoadingSpinner isLoading={loading} />}
						{!loading && data.map((x: any, i: number) => (
							<Grid key={x.id} item xs={12} md={6} mb={2} pr={2}>
								<Card
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										backgroundColor: '#f3f6f9',
										boxShadow: 0,
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											backgroundColor: 'transparent',
										}}
									>
										<CardContent sx={{ flex: '1 0 auto' }}>
											<Typography variant='subtitle1' component='div'>
												{convertDateISOToDateString(x['createdAt'])}
											</Typography>
											<Typography component='div' variant='h6' fontWeight='bold'>
												{x['nombre_vacante']}
											</Typography>
											{x['codigo_vacante'] && (
												<Typography component='div' variant='subtitle2' fontWeight='bold'>
													Código: {x['codigo_vacante']}
												</Typography>
											)}
											<Typography component='div' variant='subtitle2'>
												Descripción: {String(x['Describa_vacante']).slice(0, 180)}...
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												{x['numero_puestos']} puestos.
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												<Link to={getPathCandidatosPostulados(x['id'])}>
													{x['candidatos']} candidatos para esa vacante.
												</Link>
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												Estado: {(() => {
													const statusKey = x['status_approval'];
													const statusObject = ListStatusApprovalModel.find(item => item.code === statusKey);

													return statusObject ? statusObject.value : statusKey;
												})()}
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												Activo: {x['status'] ? 'Sí' : 'No'}
											</Typography>
										</CardContent>
									</Box>
									<Stack direction='row' sx={{ alignItems: 'center' }} p={2} spacing={2}>
										<Tooltip title="Matching">
											<Button
												variant='contained'
												sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
											>
												<Link to={getPathMatchingCandidatos(x.id)}>
													<CheckCircleOutlineIcon color='action' />{' '}
												</Link>
											</Button>
										</Tooltip>
										<Tooltip title={isAdminOrCounselor() && "Información Adicional"}>
											<Button
												variant='contained'
												sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
											>
												<Link to={getPathInformacionAdicionalVacante(x.id)}>
													{isAdminOrCounselor() && <AddCircle color='action' />}
												</Link>
											</Button>
										</Tooltip>
										<Tooltip title={(isAdminRole() || x.status_approval == 'EN_ANALISIS') ? "Editar" : "Ver"}>
											<Button
												variant='contained'
												sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
											>
												<Link to={getPathEditarVacante(x.id)}>
													{(isAdminRole() || x.status_approval == 'EN_ANALISIS') ? <EditIcon color='action' /> : <RemoveRedEye color='action' />}
													{' '}
												</Link>
											</Button>
										</Tooltip>
										{(x.status_approval === 'EN_ANALISIS' && isAdminOrCounselor()) && (
											<Tooltip title="Aprobar">
												<Button
													variant='contained'
													sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
													onClick={() => handleOpenAprobada(x.id)}
												>
													<Verified color='action' />{' '}
												</Button>
											</Tooltip>
										)}
										{(x.status_approval === 'EN_ANALISIS' && isAdminOrCounselor()) && (
											<Tooltip title="Rechazar">
												<Button
													variant='contained'
													sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
													onClick={() => handleOpenRechazada(x.id)}
												>
													<Unpublished color='action' />{' '}
												</Button>
											</Tooltip>
										)}
										{(x.status_approval === 'RECHAZADA' && isAdminOrCounselor()) && (
											<Tooltip title="En análisis">
												<Button
													variant='contained'
													sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
													onClick={() => handleOpenAnalisis(x.id)}
												>
													<Flaky color='action' />{' '}
												</Button>
											</Tooltip>
										)}
										{(x.status_approval === 'RECHAZADA') && (
											<Tooltip title="Motivo del rechazo">
												<Button
													variant='contained'
													sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
													onClick={() => handleOpenMotivo(x.description_approval)}
												>
													<StickyNote2 color='action' />{' '}
												</Button>
											</Tooltip>
										)}
										{isAdminRole() && (
											<Tooltip title="Eliminar">
												<Button
													variant='contained'
													color='error'
													sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40 }}
													onClick={() => modalConfirmDeleteVacante(x)}
												>
													<DeleteIcon />{' '}
												</Button>
											</Tooltip>
										)}
									</Stack>
								</Card>
							</Grid>
						))}
					</Grid><div>
							<PaginationComponent
								totalPage={totalPage}
								total={total}
								customClass={''}
								paginate={changePaginate} />
						</div></>
				)}

			</div>
			<Dialog
				open={openModalConfirmDeleteVacante}
				onClose={handleCloseModalConfirmDeleteVacante}
				keepMounted
				aria-describedby='alert-dialog-description'
				aria-labelledby="responsive-dialog-title"
			//sx={{height: '50%'}}
			>
				<DialogTitle>{'Eliminar Vacante'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						La vacante tiene {qtdVacante} postulaciones.
						<br /><br />
						Si elige continuar, toda la información relacionada (Postulaciones, Matching) también se eliminará.
					</DialogContentText>
					<br />
					<DialogContentText id='alert-dialog-description'>
						Esta acción no es posible revertirla.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						sx={{ background: '#960a36', width: 90 }}
						onClick={handleCloseModalConfirmDeleteVacante}
						autoFocus
						disabled={!loading ? false : true}
					>
						Cancelar
					</Button>
					<Button
						variant='contained'
						sx={{ background: '#0A4396', width: 90 }}
						onClick={deleteVacancy}
						autoFocus
						disabled={!loading ? false : true}
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
			<div>
				<Dialog
					open={openAprobada}
					keepMounted
					onClose={handleCloseAprobada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Confirmación de aprobación de vacante'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea aprobar esta vacante?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseAprobada}
							disabled={isSubmitting}
							autoFocus
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeVacancyStatus()}
							disabled={isSubmitting}
							autoFocus
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Aprobar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openAnalisis}
					keepMounted
					onClose={handleCloseAnalisis}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Confirmación de cambio de estado'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea cambiar el estado de la vacante a: "En análisis"?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseAnalisis}
							disabled={isSubmitting}
							autoFocus
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeVacancyStatus()}
							disabled={isSubmitting}
							autoFocus
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Cambiar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openMotivo}
					keepMounted
					onClose={handleCloseMotivo}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Motivo de rechazo de la vacante'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{description}.
							{isAdminOrCounselor() && (
								<>
									<br />
									<br />
									Si desea editar la vacante, es necesario cambiar su estado a: "En análisis".
								</>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={handleCloseMotivo}
							disabled={isSubmitting}
							autoFocus
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openRechazada}
					keepMounted
					onClose={handleCloseRechazada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Rechazar la vacante'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea rechazar esta vacante?
						</DialogContentText>
						<DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
							Escriba las razones del rechazo a continuación
						</DialogContentText>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								sx={{ width: 500, height: 250 }}
								rows={10}
								id='observaciones'
								name='observaciones'
								label='Observaciones'
								required
								value={initValue(vacancyApproval.description)}
								onChange={(e) => {
									setVacancyAproval({ ...vacancyApproval, description: e.target.value });
									setTouchedDescription(true);
									(e.target.value?.length >= 20) ? setErrorDescription(false) : setErrorDescription(true);
								}}
								onTouchStart={() => setTouchedDescription(true)}
								size='medium'
								multiline
								inputProps={{ maxLength: 1200 }}
								disabled={loading}
								helperText={(errorDescription && touchedDescription) ? `${vacancyApproval.description === undefined ? '0' : vacancyApproval.description?.length}/${1200} Las observaciones deben ser mayores a 20 carácteres*` : `${vacancyApproval.description === undefined ? '0' : vacancyApproval.description?.length}/${1200}`}
								error={errorDescription && touchedDescription}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseRechazada}
							autoFocus
							disabled={isSubmitting}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeVacancyStatus()}
							autoFocus
							disabled={vacancyApproval.description === undefined || vacancyApproval.description == '' || vacancyApproval.description?.trim() == '' || errorDescription}
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Rechazar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

export { EmployerManagementVacancyPage };
