import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import {
	deleteUserById,
	getUsers,
	updateStatusUserToActive,
	updateStatusUserToDesactive,
} from '../../modules/apps/user-management/users-list/core/_requests';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Grid
} from '@mui/material';
import { RoleEnum } from '../../models/enums/RoleEnum';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Alert, Snackbar, AlertTitle, Select, FormControl } from '@mui/material';
import { LinearLoadingSpinner, LoadingBackdrop, LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { concatArrayToStringJoinBy } from '../../util/Index';

const RegistroManagementPage = () => {
	const [data, setData]: any[] = useState([]);

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
		role: 0,
		active: '',
		status: '',
	});
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);



	const tpUserRole = [
		{ id: RoleEnum.ADMIN, name: 'Administrador' },
		{ id: RoleEnum.CANDIDATE, name: 'Candidato' },
		{ id: RoleEnum.COUNSELOR_CADIDATE, name: 'Consejero Candidato' },
		{ id: RoleEnum.COUNSELOR_EMPLOYER, name: 'Consejero Empleador' },
		{ id: RoleEnum.EMPLOYER, name: 'Empleador' }
	];

	const navigate = useNavigate();

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getUsersAPI();
	}, [filter]);

	const changePaginate = (pf: any) => {
		// const f = { page: pf.page, limit: pf.limit };
		setFilter({ ...filter, page: pf.page, limit: pf.limit });
	};
	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { ...filter, page: newPageNumber, limit: filter.limit, name: filter.name, role: filter.role };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { ...filter, page: 1, limit: parseInt(event.target.value, 10), name: filter.name, role: filter.role }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};

	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const { data } = props;

		const [userToDelete, setUserToDelete] = useState(null);

		const [openModalConfirmDeleteUsuario, setOpenModalConfirmDeleteUsuario] = useState(false);
		const handleCloseModalConfirmDeleteUsuario = () => {
			setOpenModalConfirmDeleteUsuario(false);
			setUserToDelete(null);
		};
		const modalConfirmDeleteUsuario = (id: any) => {
			setOpenModalConfirmDeleteUsuario(true);
			setUserToDelete(id);
		};


		//0 = ACTIVE
		//1 = DESACTIVE
		const updateStatusUserAPI = async (id: number, type: number) => {
			try {
				setSubmitting(true);
				type === 0
					? await updateStatusUserToActive(`id=${id}`)
					: await updateStatusUserToDesactive(`id=${id}`);
				setOpen(true);
				setAlert(true);
				setAlertMsg(`¡Usuario actualizado con éxito!`);
				setFilter({ ...filter });
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
					setTimeout(() => {
						setOpen(false);
					}, 2000);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setSubmitting(false);
			}
		};

		const deleteUserAPI = async () => {
			try {
				setSubmitting(true);
				await deleteUserById(`id=${userToDelete}`);
				setOpen(true);
				setAlert(true);
				setAlertMsg(`¡Usuario eliminado con éxito!`);
				setFilter({ ...filter });
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
					setTimeout(() => {
						setOpen(false);
					}, 2000);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setSubmitting(false);
			}
		};

		return (
			<>
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose}>
						<Link to={`/admin/editar-usuario/${data.id}`}>
							<BorderColorIcon />
							&nbsp; Editar
						</Link>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						{data.active ? (
							<a className='pointer' onClick={() => updateStatusUserAPI(data.id, 1)}>
								<ToggleOffIcon />
								&nbsp; Inactivar
							</a>
						) : (
							<a className='pointer' onClick={() => updateStatusUserAPI(data.id, 0)}>
								<ToggleOnIcon />
								&nbsp; Activar
							</a>
						)}
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={() => modalConfirmDeleteUsuario(data.id)}>
							<DeleteIcon />
							&nbsp; Eliminar
						</a>
					</MenuItem>
				</Menu>

				<Dialog
					open={openModalConfirmDeleteUsuario}
					onClose={handleCloseModalConfirmDeleteUsuario}
					keepMounted
					aria-describedby='alert-dialog-description'
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle>{'Eliminar Usuario'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Esta acción excluirá el usuario de cuálquier proceso en la plataforma de empleo.
						</DialogContentText>
						<br />
						<DialogContentText id='alert-dialog-description'>
							Esta acción no es posible revertirla.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalConfirmDeleteUsuario}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={deleteUserAPI}
							autoFocus
							disabled={!loading ? false : true}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getUsersAPI = async () => {
		try {
			setLoading(true);
			const response = await getUsers(
				`page=${filter.page}&limit=${filter.limit}${filter.name !== '' ? '&name=' + filter.name : ''
				}${filter.role > 0 ? '&role=' + filter.role : ''}${filter.active !== '' ? '&active=' + filter.active : ''}${filter.status !== '' ? '&status=' + filter.status : ''}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		setPage(0);
		setFilter({ ...filter, page: 1, name: textoBuscar });
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={isSubmitting} />					
				<Grid container direction='row' width={'100%'} item={true}>
					<Grid xs={2} sm={1} md={1} mb={1} item={true}>								
							<BackButton />								
					</Grid>
					<Grid xs={10} sm={3} md={1.5} pr={1} mb={1} item={true}>
						<FormControl fullWidth size="small">
							<Select
								style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
								name='documentType'
								id='documentType'
								value={filter.role === 0 ? '' : filter.role}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								onChange={(e) => {
									setPage(0);
									setFilter({ ...filter, page: 1, role: e.target.value });
								}}
							>
								<MenuItem value='' key={''}>Rol</MenuItem>
								{tpUserRole.map((item, i) => (
									<MenuItem value={item.id} key={i}>{item.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={3} md={2} pr={1} mb={1} item={true}>
						<FormControl fullWidth size="small">
							<Select
								style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
								name='activefilter'
								id='activefilter'
								value={filter.active}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								onChange={(e) => {
									setPage(0);
									setFilter({ ...filter, page: 1, active: e.target.value });
								}}
							>
								<MenuItem value='' key={''}>Activo/Inactivo</MenuItem>
								<MenuItem value='1' key={'Activo'}>Activo</MenuItem>
								<MenuItem value='0' key={'Inactivo'}>Inactivo</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={2} md={1.5} pr={1} mb={1} item={true}>
						<FormControl fullWidth size="small">
							<Select
								style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
								name='statusfilter'
								id='statusfilter'
								value={filter.status}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								onChange={(e) => {
									setPage(0);
									setFilter({ ...filter, page: 1, status: e.target.value });
								}}
							>
								<MenuItem value='' key={''}>Estado</MenuItem>
								<MenuItem value="Disponible" key={'Disponible'}>Disponible</MenuItem>
								<MenuItem value="Excluido" key={'Excluido'}>Excluído</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={3} md={3.5} pr={1} mb={1} item={true}>
						<div className={`input-group`}>
							<input type='text'
								className='form-control'
								placeholder='Nombres/Correo'
								aria-label='Nombres/Correo'
								aria-describedby='basic-addon2'
								value={textoBuscar}
								onChange={handleChageTextoBuscar}
								onKeyDown={handleKeyDownEnterTextoBuscar} />
							<span className={`input-group-text ${styleScss['btn-buscar']}`}
								id='searchCandidato'
								onClick={filtraTextoBuscar}>
								<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
									style={{ fontSize: '20px' }}></i>
							</span>
						</div>
					</Grid>
					<Grid xs={6} sm={3} md={2} pr={1} mb={1} item={true}>
						<Link to='/admin/nuevo-usuario'
							className='btn btn-primary btn-lg'
							role="button">Registrar usuario</Link>
					</Grid>
				</Grid>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				<div className={`${styleScss['table-limit']}`} style={{ width: '100%' }}>
					<Stack padding={2}>
						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Nombres</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Apellidos</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Correo electrónico</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Estado</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Activo</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Rol</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Acciones</h4>
										</TableCell>
									</TableRow>
								</TableHead>
								{!loading && <TableBody>
									{data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
												{concatArrayToStringJoinBy([x.UserComplemento?.primer_nome?.toLowerCase(), x.UserComplemento?.segundo_nome?.toLowerCase()], ' ')}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{concatArrayToStringJoinBy([x.UserComplemento?.primer_apelido?.toLowerCase(), x.UserComplemento?.segundo_apelido?.toLowerCase()], ' ')}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.email?.toLowerCase()}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.status}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.active ? 'Sí' : 'No'}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{tpUserRole.find((r) => r.id === x.role)?.name}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x.status != 'Excluido' && (<MenuAction data={{ id: x.id, active: x.active, status: x.status }} />)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>}
								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={8}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}
							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>
					</Stack>
				</div>

				{/* <PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/> */}
			</div >
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const AdminRegistroManagementWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión usuarios</PageTitle>
			<RegistroManagementPage />
		</>
	);
};

export { AdminRegistroManagementWrapper };
