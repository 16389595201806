import BackButton from '../../../components/BackButton/BackButton';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	Divider,
	MenuItem,
	Select,
	Button,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isValidFieldRequired, initValue } from '../../../util/Index';
import { createInformacionAdicionalVacante, getUsersConsejeroEmpresarial, updateInformacionAdicionalVacante, getInformacionAdicionalVacante } from '../../apps/user-management/users-list/core/_requests';
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import { multiStepContext } from '../../../contexts/InformacionAdicionalVacancyContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../components/LoadingSpinner/LoadingSpinner';
import useInfoAdicionalParams from '../../../hooks/parameters/company-aditional-info/useInfoAdicionalParams';

export const InformacionAdicionalVacancy = () => {
	const [listCounselorsEmpresa, setListCounselorsEmpresa] = useState<[]>([]);
	const [loading, setLoading] = useState(false);
	const { userData, setUserData } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);
	const { listTipoConvocatoria, listSiNo, loadingParams } = useInfoAdicionalParams();
	const [correo, setCorreo] = useState('');
	// id es el id de la vacante que se encuentra en los parametros del navegador
	const { id } = useParams();
	const navigate = useNavigate();

	const handleClose = () => {
		setOpen(false);
	};

	class validationForm {
		consejero: string = initValue(userData['id_consejero']);
		tipo_convocatoria: string = initValue(userData['tipo_convocatoria']);
		tipo_vacante: string = initValue(userData['tipo_vacante']);
		disponible_convocatoria: string = initValue(userData['disponible_convocatoria']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				disponible_convocatoria: Yup.string().required('Campo requerido'),
				tipo_convocatoria: Yup.string().required('Campo requerido'),
				consejero: Yup.string().required('Campo requerido'),
				tipo_vacante: Yup.string().required('Campo requerido').max(45, 'Máximo 45 caracteres').min(15, 'Minimo 15 caracteres'),
			}),
		[]
	);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			console.log(values);
			try {
				setSubmitting(true);
				setStatus('');
			} catch (error) {
				console.error(error);
			}
		},
	});

	const getAllParameters = async () => {
		await fetchListsCounselorEmpresa();
	};

	useEffect(() => {
		getAllParameters();
		if (id !== undefined && parseInt(id) > 0) {
			setLoading(true);
			getInformacionAdicionalVacante(parseInt(id)).then((response) => {
				if (Object.entries(response.data).length == 0) {
					setUserData({ ...userData, id_vaga: id });
				} else {
					setUserData(response.data);
				}
			}).catch((error: any) => {
				if (error.response) {
					console.error('error', error.response.data.error);

				} else {
					setUserData({ ...userData, id_vaga: id });
					console.error('Ocurrio un error procesando la solicitud.');
				}
			}).finally(() =>
				setLoading(false));
		}
		else {
		}
	}, []);

	const fetchListsCounselorEmpresa = async () => {
		const res = await getUsersConsejeroEmpresarial();
		await setListCounselorsEmpresa(res.data.data);
	};

	useEffect(() => {
		const consejero = listCounselorsEmpresa.find(({ id }) => id === userData.id_consejero) || { email: '' };
		setCorreo(consejero.email);
	}, [listCounselorsEmpresa, userData]);

	const fieldsAreValid = async () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(userData['id_consejero']))
			errors.push({ field: 'consejero', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['tipo_convocatoria']))
			errors.push({ field: 'tipo_convocatoria', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['disponible_convocatoria']))
			errors.push({ field: 'disponible_convocatoria', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['tipo_vacante']))
			errors.push({ field: 'tipo_vacante', error: 'Campo requerido' });
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}
		if (Object.keys(formik.errors).length) {
			return false;
		}
		return true;
	};

	const handleSubmit = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		try {
			formik.handleSubmit();
			setRequestInProgress(true);
			const isValidToContinue = await fieldsAreValid();
			if (!isValidToContinue)
				throw new Error('Fields not valid');
			if (userData.id !== undefined && parseInt(userData.id) > 0) {
				await updateInformacionAdicionalVacante(userData);
				setAlert(true);
				setAlertMsg('¡Registro editado con éxito!');
			} else {
				await createInformacionAdicionalVacante(userData);
				setAlert(true);
				setAlertMsg('¡Registro creado con éxito!');
			}
			setOpen(true);
			setTimeout(() => {
				navigate(-1);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setTimeout(() => {
				setRequestInProgress(false);
			}, 2000);
		}

	};

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%' }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>Información Adicional</h2>
									<span style={{}}>Consejero, diligencie los campos con información adicional de la vacante.</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Box>
			<Box mt={5}>
				<LoadingBackdrop loading={requestInProgress} />
				{loading ? (<LinearLoadingSpinner isLoading={true}/>) : (
					loadingParams ? <LinearLoadingSpinner isLoading={true} /> :
						<>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										fullWidth
										size='medium'
									>
										<InputLabel required>Consejero asignado</InputLabel>
										<Select
											{...formik.getFieldProps('consejero')}
											error={formik.touched.consejero && Boolean(formik.errors.consejero)}
											id='consejero'
											name='consejero'
											label='Consejero asignado'
											value={initValue(userData['id_consejero'])}
											onChange={(e) => {
												const consejero = listCounselorsEmpresa.find(({ id }) => id === e.target.value) || { email: '' };
												setUserData({ ...userData, id_consejero: e.target.value, correo_consejero: consejero.email });
												formik.setFieldTouched('consejero', true);
												formik.setFieldValue("consejero", e.target.value)
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>

											{listCounselorsEmpresa.map(({ email, id, UserComplemento: { primer_nome, segundo_nome, segundo_apelido, primer_apelido } }) => (
												<MenuItem value={id} key={id}>
													{primer_nome} {segundo_nome} {primer_apelido} {segundo_apelido}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.consejero ? formik.errors.consejero : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										fullWidth
										size='medium'
									>
										<TextField
											required
											fullWidth
											id='correo_consejero'
											name='correo_consejero'
											label='Correo electrónico del consejero'
											placeholder='Correo electrónico del consejero'
											value={initValue(correo)}
											disabled={true}
											size='medium'
											inputProps={{ maxLength: 300 }}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										fullWidth
										size='medium'
									>
										<TextField
											required
											fullWidth
											id='tipo_vacante'
											{...formik.getFieldProps('tipo_vacante')}
											error={formik.touched.tipo_vacante && Boolean(formik.errors.tipo_vacante)}
											helperText={formik.touched.tipo_vacante ? formik.errors.tipo_vacante : ''}
											name='tipo_vacante'
											label='Tipo vacante'
											value={initValue(userData.tipo_vacante)}
											disabled={false}
											onChange={(e) => {
												setUserData({ ...userData, tipo_vacante: e.target.value });
												formik.handleChange(e);
											}}
											size='medium'
											inputProps={{ maxLength: 45 }}
										/>
									</FormControl>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										fullWidth
										size='medium'
									>
										<InputLabel required>Vacante para convocatoria</InputLabel>
										<Select
											{...formik.getFieldProps('disponible_convocatoria')}
											error={formik.touched.disponible_convocatoria && Boolean(formik.errors.disponible_convocatoria)}
											required
											labelId="disponible_convocatoria"
											id="disponible_convocatoria"
											name='disponible_convocatoria'
											label='Vacante para convocatoria'
											value={initValue(userData['disponible_convocatoria'])}
											onChange={(e) => {
												setUserData({ ...userData, disponible_convocatoria: e.target.value });
												formik.setFieldTouched('disponible_convocatoria', true);
												formik.setFieldValue("disponible_convocatoria", e.target.value)
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listSiNo.map(({ value, code }) => (
												<MenuItem
													key={value}
													value={code}
												>
													{value}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.disponible_convocatoria ? formik.errors.disponible_convocatoria : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										fullWidth
										size='medium'
									>
										<InputLabel required>Tipo convocatoria</InputLabel>
										<Select
											{...formik.getFieldProps('tipo_convocatoria')}
											error={formik.touched.tipo_convocatoria && Boolean(formik.errors.tipo_convocatoria)}
											required
											labelId="tipo_convocatoria"
											id="tipo_convocatoria"
											name='tipo_convocatoria'
											label='Tipo convocatoria'
											value={initValue(userData['tipo_convocatoria'])}
											onChange={(e) => {
												setUserData({ ...userData, tipo_convocatoria: e.target.value });
												formik.setFieldTouched('tipo_convocatoria', true);
												formik.setFieldValue("tipo_convocatoria", e.target.value)
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listTipoConvocatoria.map(({ value, code, label }) => (
												<MenuItem
													key={value}
													value={code}
												>
													{label}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.tipo_convocatoria ? formik.errors.tipo_convocatoria : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}></Grid>
							</Grid>
							<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
							<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
								<Button
									variant='contained'
									sx={{ background: '#0A4396', width: 90 }}
									onClick={(e) => {
										handleSubmit(e)
									}}
									disabled={formik.isSubmitting || !formik.isValid}
								>
									Enviar
								</Button>
							</Box>
							<Snackbar
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={open}
								onClose={handleClose}
							>
								<Alert
									onClose={handleClose}
									variant={'filled'}
									severity={alert ? 'success' : 'error'}
									sx={{ whiteSpace: 'pre-line' }}
								>
									{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
									{alertMsg}
								</Alert>
							</Snackbar>
						</>
				)}
			</Box>
		</Box>
	);
};
