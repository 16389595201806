import { Skeleton } from "@mui/material"

export const ResumeCandidatoSkeleton = () => {
    return (
        <>
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <Skeleton className="mt-8" variant="text" sx={{ fontSize: '1.5rem' }} width={300} />
            <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

            {/* Datos de la Entrevista */}
            <Skeleton className="mt-6 mb-3" variant="text" sx={{ fontSize: '2rem' }} width={300} />
            <hr />

            <div className='row mt-3'>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
                <div className='col-md-4 col-sm-12 p-2'>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                </div>
            </div>

        </>
    )
}
