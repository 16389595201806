import { useEffect, useState } from 'react';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';
import { ParameterConfigMmodel } from '../../models/parameter.model';

const useEducationParams = () => {
	const [listSiNo, setListSiNo] = useState<ParameterConfigMmodel[]>([]);
	const [listPais, setPaisList] = useState<ParameterConfigMmodel[]>([]);
	const [otrosConocimientosList, setOtrosConocimientosList] = useState<ParameterConfigMmodel[]>([]);
	const [listEstadoCertificado, setListEstadoCertificado] = useState<ParameterConfigMmodel[]>([]);
	const [listTipoCapacitacion, setListTipoCapacitacion] = useState<ParameterConfigMmodel[]>([]);
	const [listEstadoCurso, setListEstadoCurso] = useState<ParameterConfigMmodel[]>([]);

	useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = () => {
		fetchPaisParams();
		fetchListSiNo();
		fetchOtrosConocimientosList();
		fetchEstadoCurso();
		fetchListEstadoCertificado();
		fetchListTipoCapacitacion();
	};

	const fetchPaisParams = async () => {
		const res = await parameterSingleton.fetchData(parameters.PAIS);
		setPaisList(res);
	};

	const fetchListSiNo = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNo(res);
	};

	const fetchOtrosConocimientosList = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONOCIMIENTOS);
		setOtrosConocimientosList(res);
	};

	const fetchEstadoCurso = async () => {
		const res = await parameterSingleton.fetchData(parameters.ESTADOCURSO);
		setListEstadoCurso(res);
	};

	const fetchListEstadoCertificado = async () => {
		const res = await parameterSingleton.fetchData(parameters.ESTADOCERTIFICADO);
		setListEstadoCertificado(res);
	};

	const fetchListTipoCapacitacion = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCAPACITACION);
		setListTipoCapacitacion(res);
	};

	return {
		listPais,
		otrosConocimientosList,
		listEstadoCurso,
		listSiNo,
		listEstadoCertificado,
		listTipoCapacitacion,
	};
};

export default useEducationParams;
