import { PageTitle } from '../../../_metronic/layout/core'
import { EmployerManagementVacancyPage } from './EmployerManagementVacancyPage'
import { TraceabilityManagementOfCare } from './TraceabilityManagementOfCare'

const TraceabilityOfCare = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Trazabilidad de Atención</PageTitle>
			<TraceabilityManagementOfCare />
		</>
	)
}

export { TraceabilityOfCare }
