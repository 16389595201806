import { useEffect, useState } from 'react';
import { Pagination } from '../../models/Pagination';
import styleCss from './styles.module.scss';

const PaginationComponent = ({ totalPage, total, customClass, paginate }: Pagination) => {
	const [current, setCurrent] = useState<number>(0);
	const [selectedLimit, setSelectedLimit] = useState<number>(10);
	const totalPages: number = totalPage ? totalPage : Math.ceil(total / selectedLimit);

	const onPageChange = (page: number) => {
		const currentPage: number = page < 1 ? 1 : page > totalPages ? totalPages : page;
		paginate({ page: currentPage, limit: selectedLimit });
		setCurrent(currentPage);
	};

	const onLimitChange = (limit: number) => {
		paginate({ page: 1, limit: limit });
		setSelectedLimit(limit);
		setCurrent(1);
	};

	useEffect(() => {
		setCurrent(1);
	}, [total]);

	return (
		<>
			<div className={`row mt-3 ${customClass}`}>
				<div className='col-md-6 pt-4 text-center'>
					<label htmlFor='show' className='mr-2'>
						Mostrar
					</label>
					<select
						name='take'
						id='take'
						className='mr2'
						value={selectedLimit}
						onChange={(e) => onLimitChange(parseInt(e.target.value))}
					>
						<option value='10'>10</option>
						<option value='20'>20</option>
						<option value='50'>50</option>
						<option value='100'>100</option>
					</select>
					<label htmlFor=''>de {total} registro(s).</label>
				</div>
				<div className='col-md-6 text-center'>
					<ul className={styleCss.pagination}>
						<li>
							<button onClick={() => onPageChange(1)} disabled={current === 1}>
								<i className='bi bi-chevron-double-left'></i>
							</button>
						</li>
						<li>
							<button onClick={() => onPageChange(current - 1)} disabled={current === 1}>
								<i className='bi bi-chevron-left'></i>
							</button>
						</li>

						<li>
							<div className={styleCss.pageDescription}>
								<span>Página</span>
								<span>
									{current} de {totalPages}
								</span>
							</div>
						</li>
						<li>
							<button
								onClick={() => onPageChange(current + 1)}
								disabled={current === totalPages || totalPages === 0}
							>
								<i className='bi bi-chevron-right'></i>
							</button>
						</li>
						<li>
							<button
								onClick={() => onPageChange(totalPages)}
								disabled={current === totalPages || totalPages === 0}
							>
								<i className='bi bi-chevron-double-right'></i>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default PaginationComponent;
