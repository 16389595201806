import { useContext, useEffect, useState } from 'react';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { concatArrayToStringJoinBy, initValue } from '../../../../../util/Index';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useAboutVacancyParams from '../../../../../hooks/parameters/vacancy-management/useAboutVacancyParams';
import useBasicInfoVacancyPrarams from '../../../../../hooks/parameters/vacancy-management/useBasicInfoVacancyPrarams'
import { createVacancy, putPreforma, updateVacancy } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
    FormControlLabel,
    Switch,
    Box,
    Typography,
    Grid,
    Divider,
    Button,
    Snackbar,
    Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useAuth } from '../../../core/Auth';

export const Step7 = () => {
    const { isAdminRole, isCounselorEmployerRole } = useAuth();
    const [preforma, setPreforma] = useState(false)
    const { setStep, userData, setUserData, shouldDisable } = useContext(multiStepContext);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const { listSwitchesVacantes, loadingParams } = useAboutVacancyParams();
    const { listNivelEducativo } = useBasicInfoVacancyPrarams();
    const { id } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [openAprobada, setOpenAprobada] = useState(false);
    const [vacancyMsg, setVacancyMsg] = useState('');
    const [titleVacancyMsg, setTitleVacancyMsg] = useState('');

    interface DataItem {
        label?: string;
        answer: string;
        id: number;
        id_vaga: number;
        id_parameter: number;
        value: boolean;
    }
    const [data, setData] = useState<DataItem[]>([]);

    interface switches {
        id: number;
        id_vaga: number;
        id_parameter: number;
        value: number;
    }

    useEffect(() => {
        const getAnswerVacancy = (code: string) => {
            let answer = '';

            type LabelSwitch = {
                [key: string]: string;
            }

            const labelSwitch: LabelSwitch = {
                'nivel_educativo': 'nivel_estudio',
                'titulo_homologado': 'formacion_titulo',
                'experiencia_tiempo': 'rango_experiencia'
            };

            const campo = labelSwitch[code];
            answer = initValue(userData[campo]);

            if (code === 'nivel_educativo') {
                const nivelEducativo = listNivelEducativo.find(nivelEducativo => nivelEducativo.code === answer);
                answer = nivelEducativo ? nivelEducativo.value : answer;
            } else if (code === 'experiencia_tiempo') {
                answer = answer + " meses";
            }

            return answer;
        }

        const vagaSwitches = userData['VagaSwitches'];

        setData(
            listSwitchesVacantes.map((item) => {
                // Encuentra el switch correspondiente en userData['VagaSwitches'] usando el id de la parametrica
                const matchingSwitch = vagaSwitches?.find((vaga: switches) => vaga.id_parameter === item.id);
                // Inicializa la variable a pintar usando los datos de la vacante encontrada si existe, si no existe usa la parametrica
                const itemData: DataItem = {
                    id_parameter: item.id,
                    label: item.label,
                    answer: getAnswerVacancy(item.code),
                    value: matchingSwitch ? matchingSwitch.value === 1 : item.value === '1',
                    id: matchingSwitch ? matchingSwitch.id : 0,
                    id_vaga: matchingSwitch ? matchingSwitch.id_vaga : 0
                }

                return itemData;
            })
        );

        if (!vagaSwitches) {
            const switchesIniciales = listSwitchesVacantes.map((item) => {
                return {
                    id_parameter: item.id,
                    value: item.value === '1' ? 1 : 0
                };
            });
            setUserData((prevUserData: { [x: string]: any; }) => ({
                ...prevUserData,
                VagaSwitches: switchesIniciales
            }));
        } else {
            listSwitchesVacantes.map((item) => {
                // Encuentra el switch correspondiente en userData['VagaSwitches'] usando el id de la parametrica
                const matchingSwitch = vagaSwitches?.find((vaga: switches) => vaga.id_parameter === item.id);

                if (!matchingSwitch) {
                    setUserData((prevUserData: { [x: string]: any; }) => ({
                        ...prevUserData,
                        VagaSwitches: [...(prevUserData['VagaSwitches']), {
                            id_parameter: item.id,
                            value: item.value === '1' ? 1 : 0
                        }]
                    }));
                }
            });
        }

    }, [listSwitchesVacantes, listNivelEducativo, userData, setUserData]);

    const handleToggle = (index: number) => {
        const newData = [...data];
        newData[index].value = !newData[index].value;

        // Encuentra el switch correspondiente en userData['VagaSwitches']        
        const matchingSwitchIndex = userData['VagaSwitches']?.findIndex((vaga: switches) => vaga.id_parameter === newData[index].id_parameter);

        if (matchingSwitchIndex !== -1 && matchingSwitchIndex !== undefined) {
            // Si el switch existe, actualiza su valor
            setUserData((prevUserData: { [x: string]: any; }) => {
                const newVagaSwitches = [...prevUserData['VagaSwitches']];
                newVagaSwitches[matchingSwitchIndex].value = newData[index].value ? 1 : 0;
                return { ...prevUserData, VagaSwitches: newVagaSwitches };
            });
        } else {
            // Si el switch no existe, añade un nuevo elemento
            setUserData((prevUserData: { [x: string]: any; }) => ({
                ...prevUserData,
                VagaSwitches: [...(prevUserData['VagaSwitches']), {
                    id_parameter: newData[index].id_parameter,
                    value: newData[index].value ? 1 : 0
                }]
            }));
        }

        setData(newData);
    };

    const handleSubmit = async () => {
        try {
            const data = { ...userData, poblaciones_aceptadas: concatArrayToStringJoinBy(userData.poblacionesAceptadas, ',', null) }
            setRequestInProgress(true);

            if (id !== undefined && parseInt(id) > 0 && userData.status_approval !== 'TEMPORAL' && userData.status_approval !== 'PREFORMA') {
                await updateVacancy(data);
                setOpen(true);
                setAlert(true);
                setAlertMsg('¡Registro actualizado con éxito!');
                setTimeout(() => {
                    setOpen(false);
                    setRequestInProgress(false);
                    navigate(-1);
                }, 2000);
            } else if (userData?.id !== undefined && parseInt(userData?.id) > 0 && userData.status_approval === 'PREFORMA') {
                await putPreforma(data);
                setOpen(true);
                setAlert(true);
                setAlertMsg(`Preforma actualizada con exito!`);
                setTimeout(() => {
                    setOpen(false);
                    setRequestInProgress(false);
                    navigate(-1);
                }, 2000);
            } else {

                const response = await createVacancy(data);
                if (response.data?.codigo_vacante) {
                    setTitleVacancyMsg(`Registro de vacante`);
                    setVacancyMsg(`Vacante registrada con código: ${response.data?.codigo_vacante} `);
                    setOpenAprobada(true);
                }
                else {
                    setOpen(true);
                    setAlert(true);
                    setAlertMsg('¡Registro completado con éxito!');
                    setTimeout(() => {
                        setOpen(false);
                        setRequestInProgress(false);
                        navigate(-1);
                    }, 2000);
                }
            }
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg((error.response.data.error === '[fecha_limite_aplicacion] No debe ser inferior a la fecha actual del sistema') ? 'La fecha limite de aplicación no debe ser inferior a la fecha actual del sistema' : error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 3000);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
            setRequestInProgress(false);
        } finally {
            setTimeout(() => {
                setRequestInProgress(false);
            }, 2000);
        }

    }
    /**
 * Valida si el rol del usuario en sesion es Administrador o Consejero
 * @returns 
 */
    const isAdminOrCounselor = () => {
        return isAdminRole() || isCounselorEmployerRole();
    }

    return (
        <>
            {loadingParams ? (
                <LinearLoadingSpinner isLoading={true} />
            ) : (
                <>
                    <Box sx={{ mb: 4 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }} mb={2}>
                            Gestión de condiciones de parametros de vacante
                        </Typography>
                        <Typography variant='inherit'>
                            Por favor, clasifica los siguientes parámetros de la vacante en:<span><Switch checked={false} /><b>Parecido</b></span> ó<span><Switch checked={true} /><b>Igual</b></span>. Para determinar cuales son estrictos y cuales son negociables en el proceso de selección.
                        </Typography>
                    </Box>
                    <Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
                        <Grid container spacing={2}>
                            {data.map((item: DataItem, index: number) => (
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={7} container justifyContent="flex-end">
                                            <Typography variant='inherit'><b>{item.label}: </b>{item.answer}</Typography>
                                        </Grid>
                                        <Grid item xs={5} container justifyContent="flex-start">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={item.value}
                                                        onChange={() => handleToggle(index)}
                                                        disabled={shouldDisable}
                                                    />
                                                }
                                                label={<Typography variant='inherit'>{item.value ? "Igual" : "Parecido"}</Typography>}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            {isAdminOrCounselor() && (
                                userData?.status_approval === "PREFORMA" ? <></> :
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center"
                                            style={{
                                                borderWidth: 1,
                                                background: 'rgb(10, 67, 150, 0.2)',
                                                borderStyle: 'solid',
                                                borderColor: '#0A4396',
                                                marginTop: 20,
                                                marginBottom: 20,
                                                borderRadius: 10,
                                                paddingBottom: 15
                                            }}
                                        >
                                            <Grid item xs={7} container justifyContent="flex-end">
                                                <Typography variant='inherit'><b>¿Desea guardar esta vacante como preforma para futuras vacantes?</b></Typography>
                                            </Grid>

                                            <Grid item xs={5} container justifyContent="flex-start">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={(value) => {
                                                                setUserData({ ...userData, preforma: value.target.checked })
                                                            }
                                                            }
                                                        />
                                                    }
                                                    label={<Typography variant='inherit'>{userData?.preforma ? "Guardar" : "No guardar"}</Typography>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                        <LoadingBackdrop loading={requestInProgress} />
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
                            onClick={() => setStep(6)}
                            disabled={requestInProgress}
                        >
                            Atrás
                        </Button>
                        {!shouldDisable && (
                            <Button variant='contained'
                                sx={{ mr: 1, width: 90 }}
                                onClick={handleSubmit}
                                disabled={requestInProgress || shouldDisable}
                            >
                                Finalizar
                            </Button>
                        )}
                    </Box>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        <Alert
                            onClose={() => setOpen(false)}
                            variant={'filled'}
                            severity={alert ? 'success' : 'error'}
                            sx={{ whiteSpace: 'pre-line' }}
                        >
                            {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                            {alertMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openAprobada}
                        keepMounted
                        onClose={() => { setOpenAprobada(false); navigate(-1); }}
                        aria-describedby='alert-dialog-description'
                    >
                        <DialogTitle id='alert-dialog-title'>
                            {titleVacancyMsg}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id='alert-dialog-description'>
                                {vacancyMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant='contained'
                                sx={{ background: '#0A4396', width: 90 }}
                                onClick={() => navigate(-1)}
                                autoFocus
                            >
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}

        </>
    );
};