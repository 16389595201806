// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_MuiBackdrop-root-two__QO5w2 {\n  background-color: rgba(205, 205, 205, 0.65) !important;\n}", "",{"version":3,"sources":["webpack://./src/app/components/LoadingSpinner/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,sDAAA;AACD","sourcesContent":[".MuiBackdrop-root-two{\n\tbackground-color: rgba(205, 205, 205, 0.65) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiBackdrop-root-two": "styles_MuiBackdrop-root-two__QO5w2"
};
export default ___CSS_LOADER_EXPORT___;
