import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link, useNavigate } from 'react-router-dom';

import { multiStepContext } from '../../../contexts/VacancyContext';

import { Step1 } from './steps/vacancy-management/Step1';
import { Step2 } from './steps/vacancy-management/Step2';
import { Step3 } from './steps/vacancy-management/Step3';
import { Step4 } from './steps/vacancy-management/Step4';
import { Step5 } from './steps/vacancy-management/Step5';
import { Step6 } from './steps/vacancy-management/Step6';
import { Step7 } from './steps/vacancy-management/Step7';

import ColorlibStepIcon from './steps/Index';
import { LinearLoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../../components/BackButton/BackButton';

const steps = ['', '', '', '', '', ''];

export const VacancyManagement = () => {
	const { currentStep, finalData, loading, setLoading } = useContext(multiStepContext);
	const navigate = useNavigate();

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			case 6:
				return <Step6 />; 
			case 7:
				return <Step7 />; 
		}
	};

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%' }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>Información básica</h2>
									<span style={{}}>Diligencie los campos con información de la vacante.</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
				<Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
					<Step>
						<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
					</Step>
				</Stepper>
			</Box>
			<Box mt={5}>
				<LinearLoadingSpinner isLoading={loading} />
				{showStep(currentStep)}
			</Box>
		</Box>
	);
};
