import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';





import BackButton from '../../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../components/LoadingSpinner/LoadingSpinner';
import useInfoAdicionalParams from '../../../hooks/parameters/company-aditional-info/useInfoAdicionalParams';
import {
	Grid, Snackbar, Alert,
	AlertTitle,
	FormHelperText,
	FormControl,
	InputLabel,
	MenuItem,
	Button,
	Autocomplete,
	TextField,
	debounce,
	CircularProgress
} from '@mui/material';
import { DatePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getAcompanimentById, getConvocatoriaById, getlistadoDeCandidatos, getListOfTrainingPrograms, postCreateAcompanamento, postCreateConvicatoria, updateAcompanmientoCandidato, updateConvocatoria } from '../../apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikConfig, } from "formik";
import { useAuth } from '../core/Auth';
import { useParams } from 'react-router-dom';
import { CounselorAreaWrapper } from '../../../pages/counselor/CounselorAreaWrapper';
import { number } from 'prop-types';




const bussineFormInitial = {
	id_user: '',
	observaciones: '',
	estado: '',
	fecha: ''
}





export const ManageAccompaniment = () => {
	const { id, visible, idCandidato, nombreCandidato } = useParams();

	const { auth } = useAuth();



	// almacenamiento de conultas
	const [listOfTrainingPrograms, setListOfTrainigPrograms] = useState<[]>([])
	const [dataEdit, setDataEdit] = useState<any>()
	const [selectedOption, setSelectedOption] = useState<any>(null);


	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [openAlert, setOpenAlert] = useState(false);


	const handleClose = () => {
		setOpen(false);
	};
	const [options, setOptions] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const handleShowSnackbar = (type: boolean, message: string) => {
		setAlert(type);
		setAlertMsg(message);
		setOpenAlert(true);
	};




	const validationSchema = Yup.object({
		id_user: Yup.string(),
		fecha: Yup.date()
			.nullable()
			.required('La fecha es obligatoria.')
			.typeError('Fecha inválida.'),
		observaciones: Yup.string()
			.required('La observación es obligatoria.')
			.min(50, 'La observación debe tener al menos 50 caracteres y máximo 700.'),
	});




	const handleCloseModal = () => {
		setOpenAlert(false);
	};

	// Post crear una convocatoria
	const handleCreateAcompanamientoCandidato = async (data: any, resetForm: any) => {
		try {
			const response = await postCreateAcompanamento(data);
			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg(`¡Acompañamiento creado con éxito!`);
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				resetForm();
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				handleCloseModal()
				setAlertMsg('Ocurrió un error al procesar la solicitud.');
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				resetForm();
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};

	// Get ampañamiento  by id
	const handleGetAcompanimentById = async (id: any) => {
		try {
			const response = await getAcompanimentById(id);


			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg('¡Informacion cargada con éxito!')
				setTimeout(() => {
					setOpen(false);
				}, 1500);
				setDataEdit(
					{
						idAcompanamiento: response.data.id,
						nameConsejeroCandidato: `${response.data.consejeroCandidato.UserComplemento.primer_nome} ${response.data.consejeroCandidato.UserComplemento.primer_apelido}`,
						name: response?.data?.User?.UserComplemento?.primer_nome,
						nameCandidato: `${response?.data?.User?.UserComplemento?.primer_nome} ${response?.data?.User?.UserComplemento?.primer_apelido}`,
						idCandidato: response.data.consejeroCandidato.id,
						id_user: response.data.id_user,
						observaciones: response.data.observaciones,
						estado: response.data.estado,
						fecha: response.data.fecha
					}
					// setOptions()
				)

			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	}

	// acctualizar el acompañamiento
	const handleUpdateAcompaniment = async (data: any, id: any) => {
		try {
			const response = await updateAcompanmientoCandidato(data, id);
			if (response) {
				setOpen(true);
				setAlert(true);
				handleCloseModal()
				setAlertMsg('¡Acompañamiento actualizado con éxito!')
				setTimeout(() => {
					setOpen(false);
				}, 2500);

			} else {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				handleCloseModal()
				setAlertMsg('Ocurrió un error al procesar la solicitud.');
				setTimeout(() => {
					setOpen(false);
				}, 2500);
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};



	useEffect(() => {
		if (id) {
			handleGetAcompanimentById(id)
		}
		if (idCandidato) {
			setDataEdit({
				id_user: idCandidato,
			})
		}

	}, [])



	const hanbleGetListadoDeCandidatos = async (query: any) => {
		try {
			setLoading(true);
			const response: any = await getlistadoDeCandidatos(
				`&nome=${query?.trim()}`
			);

			// const data = await response.data.data.json();
			setOptions(response.data.data);

		} catch (error: any) {
			console.error(error.response.data.error);
		} finally {
			setLoading(false);
		}

	};


	const handleInputChange = debounce((event: any, value: any) => {
		if (value.trim() !== "") {
			hanbleGetListadoDeCandidatos(value);
		} else {
			setOptions([]);
		}
	}, 300);


	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%', }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>{id ? 'Información del acompañamiento' : 'Creación de acompañamiento consejero candidatos.'}</h2>
									<span style={{}}>{id ? 'Estos son los campos con la información del acompañamiento.' : 'Le agradecemos que diligencie los siguientes campos.'}</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Box>

			<Box mt={5} sx={{ width: '95%', }}>
				<Formik
					enableReinitialize={true}
					initialValues={{
						...bussineFormInitial,
						...dataEdit
					}}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						const data = Object.fromEntries(Object.entries({
							id_consejero_candidato: id ? dataEdit?.idCandidato : auth?.user?.id,
							id_user: idCandidato ?? (id ? dataEdit?.id_user : values.id_user),
							observaciones: values.observaciones,
							fecha: id ? dataEdit?.fecha : values.fecha,
						}).filter(({ '1': value }) => value))
						if (id) {
							handleUpdateAcompaniment(data, id)
						} else { handleCreateAcompanamientoCandidato(data, resetForm) }
					}}
				>
					{({ setFieldValue, setFieldTouched, values, errors, touched, handleBlur, handleChange }) => (
						<Form>
							<Grid container direction='row' width={'100%'}>
								{/* Campo Autocomplete */}
								<Grid lg={4} xs={12} md={6} mb={5} pr={2} item>


									<TextField
										fullWidth
										InputProps={{
											readOnly: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										id="outlined-disabled"
										label='Consejero candidato'
										value={id ? dataEdit?.nameConsejeroCandidato : auth?.user?.nick}
									/>
								</Grid>

								{/* Campo DatePicker */}
								<Grid lg={4} xs={12} md={3} mb={5} pr={2} item>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
										adapterLocale="es"
									>
										<FormControl sx={{ minWidth: '100%' }} size="medium">
											<DatePicker
												disabled={visible ? true : false}
												disablePast
												format="DD/MM/YYYY"
												value={values.fecha ? dayjs(values.fecha) : null}
												onChange={(date) => setFieldValue('fecha', date ? dayjs(date).toISOString() : null)}
												slotProps={{
													textField: {
														fullWidth: true,
														id: 'fecha',
														label: 'Fecha de registro',
														variant: 'outlined',
														error: !!(touched.fecha && errors.fecha),
														helperText: touched.fecha && errors.fecha ? <>{errors.fecha}</> : ''
													},
													actionBar: { actions: ['clear', 'cancel', 'today'] },
												}}
											/>
										</FormControl>
									</LocalizationProvider>
								</Grid>

								<Grid lg={4} xs={12} md={6} mb={5} pr={2} item={true} >

									{nombreCandidato || id ? (
										<Autocomplete
											options={options}
											getOptionLabel={(option) => {
												// Si es un string, devuelve el string directamente
												if (typeof option === 'string') return option;
												// Si es un objeto, obtén el nombre completo
												return `${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}`;
											}}
											value={options.find(option =>
												`${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}` === (nombreCandidato || dataEdit?.nameCandidato)
											) || null}
											isOptionEqualToValue={(option, value) => {
												if (!option || !value) return false;
												return option.id === value.id;
											}}
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													{`${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}`}
												</li>
											)}
											onChange={(event, value) => {
												// Actualizar el id_user con el nuevo valor seleccionado
												if (value && value.id) {
													setFieldValue('id_user', value.id);
													// Si estás usando dataEdit, también actualízalo
													if (dataEdit) {
														setDataEdit({
															...dataEdit,
															id_user: value.id,
															nameCandidato: `${value.UserComplemento.primer_nome} ${value.UserComplemento.primer_apelido}`
														});
													}
												} else {
													setFieldValue('id_user', '');
												}
											}}
											loading={loading}
											onInputChange={handleInputChange}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														label="Nombre del candidato"
														InputLabelProps={{
															...params.InputLabelProps,
															shrink: true
														}}
														// Mostrar el nombre del candidato en el input cuando no hay una opción seleccionada
														InputProps={{
															...params.InputProps,
															readOnly: false,
															placeholder: dataEdit?.name === undefined ? 'Agrega un candidato' : dataEdit?.nameCandidato
															// placeholder: nombreCandidato || dataEdit?.nameCandidato ? nombreCandidato || dataEdit?.nameCandidato : ''
														}}
													/>

												)
											}}
										/>

									)
										:
										<Autocomplete
											options={options}
											getOptionLabel={(option) => `${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}`}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													{`${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}`}
												</li>
											)}
											onChange={(event: any, value: any) => {
												setFieldValue('id_user', value?.id || '');
											}}
											loading={loading}
											onInputChange={handleInputChange}
											renderInput={(params: any) => (
												<TextField
													{...params}
													label="Nombre del candidato"
												/>
											)}
										/>
									}





								</Grid>

							</Grid>
							<Grid container direction='row' width={'100%'}>
								<Grid lg={8} xs={12} md={6} mb={5} pr={2} item={true} >
									<TextField
										fullWidth
										id="observaciones"
										name="observaciones"
										label="Observaciones"
										multiline
										rows={4}
										value={values.observaciones}
										onChange={handleChange}
										onBlur={handleBlur} // Marca el campo como "tocado"
										error={touched.observaciones && Boolean(errors.observaciones)}
										helperText={
											touched.observaciones && typeof errors.observaciones === "string"
												? errors.observaciones
												: undefined
										}
									/>
								</Grid>
							</Grid>
							{/* Botón de enviar */}
							{
								visible ?
									null
									:
									<div className="d-flex justify-content-center" style={{ width: '100%' }}>
										<Button
											variant="contained"
											sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
											type="submit"
										// disabled={isSubmitting}
										>
											{id ? "Modificar" : "Enviar"}
										</Button>
									</div>
							}


						</Form>
					)}

				</Formik>

				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={'filled'}
						severity={alert ? 'success' : 'error'}
						sx={{ whiteSpace: 'pre-line' }}
					>
						{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
						{alertMsg}
					</Alert>
				</Snackbar>

			</Box>

		</Box >
	);
};
