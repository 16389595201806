import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../models/parameter.model';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';

const useDocumentTypeParams = () => {
	const [listTipoDocumentoEmpresa, setlistTipoDocumentoEmpresa] = useState<ParameterConfigMmodel[]>(
		[]
	);
	const [listTipoDocumentoCandidato, setlistTipoDocumentoCandidato] = useState<
		ParameterConfigMmodel[]
	>([]);
	const [listTipoDocumentoRepresentanteLegal, setlistTipoDocumentoRepresentanteLegal] = useState<
		ParameterConfigMmodel[]
	>([]);

	useEffect(() => {
		fetchListTipoDocumentoEmpresa();
		fetchListTipoDocumentoCandidato();
	}, []);

    useEffect(() => {
       const empresaTipoDoc = listTipoDocumentoCandidato.filter((value) => value.code !== 'TI');
       setlistTipoDocumentoRepresentanteLegal(empresaTipoDoc);
    }, [listTipoDocumentoCandidato])
    

	const fetchListTipoDocumentoEmpresa = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPODOCUMENTOEMPRESA);
		setlistTipoDocumentoEmpresa(res);
	};

	const fetchListTipoDocumentoCandidato = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPODOCUMENTO);
		setlistTipoDocumentoCandidato(res);
	};

	return {
		listTipoDocumentoEmpresa,
		listTipoDocumentoCandidato,
		listTipoDocumentoRepresentanteLegal,
	};
};

export default useDocumentTypeParams;
