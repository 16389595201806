import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link, useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../contexts/CompanyEditContext';
import { Step1 } from './steps/company-edition/Step1';
import { Step2 } from './steps/company-edition/Step2';
import ColorlibStepIcon from './steps/Index';
import { LinearLoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../../components/BackButton/BackButton';
import { Step3 } from './steps/company-edition/Step3';

export const CompanyEdition = () => {
	const { currentStep, finalData, loading } = useContext(multiStepContext);
	const navigate = useNavigate();

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
		}
	};

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ width: '100%', height: '60%', display: 'flex', flexDirection: 'Column', justifyContent: 'center', alignItems: 'center' }}>
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', borderRadius: '10px', m: 5, p: 5 }}>
					<Box sx={{ width: '95%' }}>
						<Box>
							<div className='row mb-4'>
								<div className='col-sm-12'>
									<div className='input-group'>
										<div
											className='col-sm-1'
											style={{
												display: 'contents',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<BackButton />
										</div>
										<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
											<h2 style={{ display: 'flex', gap: '10px' }}>Datos básicos del empleador</h2>
											<span style={{}}>Rellena los campos abajo con los datos de tu empresa.</span>
										</div>
									</div>
								</div>
							</div>
						</Box>
						<Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
							<Step>
								<StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
							</Step>
						</Stepper>
						<Box mt={5}>{loading ? <LinearLoadingSpinner isLoading={loading} /> : showStep(currentStep)}</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
