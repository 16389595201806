import * as Yup from 'yup'
import { useFormik } from 'formik'
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useEffect, useState } from 'react'
import { multiStepContext } from '../../../../../contexts/VacancyContext'
import parameterSingleton from '../../../../../services/Parameters.service'
import useAddressParams from '../../../../../hooks/parameters/useAddressParams';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner'
import useBasicInfoVacancyPrarams from '../../../../../hooks/parameters/vacancy-management/useBasicInfoVacancyPrarams'
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,
	IconButton,
} from '@mui/material'
import {
	initValue,
	isValidFieldRequired,
	setValueOrDefault,
	isValidAddress,
	eventOnKeyDownInputNumber,
	eventOnKeyDownInputAlphaNumeric,
	isValidMaxText,
	helperValidMaxText,
	concatArrayToStringJoinBy
} from '../../../../../util/Index';
import _ from 'lodash'
import { createVacancyTempral, putVacancyTempral } from '../../../../apps/user-management/users-list/core/_requests';

export const Step4 = () => {

	const { setStep, userData, setUserData, shouldDisable } = useContext(multiStepContext)
	const { listSiNo, listPresencialidad, listDepartamento, listNivelEducativo, listLocalidades, loadingParams } = useBasicInfoVacancyPrarams();
	const [reqExperience, setReqExperience] = useState(false)
	const [wordCount, setWordCount] = useState(0)
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [capacitacion, setCapacitacion] = useState<string>('');
	const [ddlMunicipio, setDdlMunicipio] = useState<any[]>([
		{ code_dpto: '0', code: '0', value: 'Seleccione' }
	]);
	const {
		viaPrincipalBisList,
		listViaPrincipalCuadrante,
		listViaPrincipalEmpresa,
		listViaPrincipalComplementoDireccion,
		listViaPrincipalPrimeraLetra,
		loadingParams: loadingParamsAddress } = useAddressParams();
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [complementosDireccion, setComplementosDireccion] = useState(userData.VagaComplementos);
	const [experienciasExcedidas, setExperienciasExcedidas] = useState(false);
	const [disableExperiencias, setDisableExperiencias] = useState(false)
	const [touchedExperiencias, setTouchedExperiencias] = useState<number[]>([]);
	const [sumaExperiencias, setSumaExperiencias] = useState();

	useEffect(() => {
		// Calcula la suma de las experiencias específicas
		if (userData.VagaExperienciaEspecificas?.length > 0) {
			const sumaExperienciasEspecificas = userData.VagaExperienciaEspecificas.reduce((total: any, experiencia: any) => {
				const duracionNumerica = isNaN(parseFloat(experiencia.duracion)) ? 0 : parseFloat(experiencia.duracion);
				return total + duracionNumerica;
			}, 0);
			setSumaExperiencias(sumaExperienciasEspecificas);

			// Actualiza la variable de estado según si la suma supera el rango de experiencia
			const nuevasExperienciasExcedidas = sumaExperienciasEspecificas > userData.rango_experiencia;
			setExperienciasExcedidas(nuevasExperienciasExcedidas);
		} else {
			setExperienciasExcedidas(false);
			setSumaExperiencias(undefined);
		}
	}, [userData.VagaExperienciaEspecificas, userData['rango_experiencia'], touchedExperiencias]);

	useEffect(() => {
		// Actualiza la variable de estado disableExperiencias
		let nuevasDisableExperiencias = false;
		if (userData.VagaExperienciaEspecificas?.length > 0 && sumaExperiencias !== undefined) {
			nuevasDisableExperiencias = sumaExperiencias >= userData.rango_experiencia || userData['rango_experiencia'] === '0';
		}

		setDisableExperiencias(nuevasDisableExperiencias);
	}, [experienciasExcedidas, userData['rango_experiencia'], sumaExperiencias]);


	useEffect(() => {
		if (userData['departamento']) {
			fetchAndSetMunicipioById(userData['departamento']);
		} else {
			setDdlMunicipio([]);
		}
	}, []);

	useEffect(() => {
		if (!userData.VagaComplementos || userData.VagaComplementos?.length === 0) {
			setComplementosDireccion([{ tipo_complemento: '', complemento: '' }]); // Adiciona objeto vacio para nuevo complemento			
		}

		if (userData.VagaComplementos && userData.VagaComplementos?.length > 0) {
			setComplementosDireccion(userData.VagaComplementos);
		}

		handleDirectionValue();

		if (_.isEqual(userData.VagaComplementos, [{ tipo_complemento: '', complemento: '' }]) && userData.VagaComplementos.length !== 0) {
			setUserData((prevUserData: { VagaComplementos: any[] }) => {
				return {
					...prevUserData,
					VagaComplementos: [],
				};
			});
		}

	}, [userData, setComplementosDireccion])

	const fetchAndSetMunicipioById = async (id: string) => {
		const municipioList =
			await parameterSingleton.fetchMunicipioById(id);
		setDdlMunicipio(municipioList);
	}

	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const addExperienciaEspecifica = () => {
		let updatedExperiencias;
		if (userData.VagaExperienciaEspecificas === undefined) {
			updatedExperiencias = [{ nombre_experiencia: '', duracion: '0' }];
		} else {
			updatedExperiencias = [...userData.VagaExperienciaEspecificas, { nombre_experiencia: '', duracion: '' }];
		}

		setUserData({
			...userData,
			VagaExperienciaEspecificas: updatedExperiencias
		});
	};

	const removeExperienciaEspecifica = (index: number) => {
		const updatedExperiencias = [...userData.VagaExperienciaEspecificas];
		updatedExperiencias.splice(index, 1);
		setUserData({
			...userData,
			VagaExperienciaEspecificas: updatedExperiencias
		});
		const updatedTouchedExperiencias = touchedExperiencias.filter((touchedIndex) => touchedIndex !== index);
		setTouchedExperiencias(updatedTouchedExperiencias);
	};

	const handleFieldTouch = (index: number) => {
		if (!touchedExperiencias.includes(index)) {
			setTouchedExperiencias([...touchedExperiencias, index]);
		}
	};
	const helperText = (index: number, duracion: string) => {
		const isTouched = touchedExperiencias.includes(index);
		const isInvalidDuration = isTouched && parseInt(duracion) <= 0;
		const isRequired = isTouched && duracion === '';
		const isExceeding = isTouched && experienciasExcedidas;

		return (
			(isInvalidDuration ? 'La cantidad de experiencia debe ser válida' : '') +
			(isRequired ? 'Campo requerido' : '') +
			(isExceeding ? 'Las experiencias específicas no pueden ser mayores a la experiencia mínima' : '')
		);
	};

	/**
	 * Gestiona el evento desencadenado por el cambio de la selección de "departamento"
	 * 	 
	 * @param {SelectChangeEvent} e - Objeto de evento desencadenado por el cambio de la entrada de selección de "departamento".	
	 */
	const onChangeDepartamento = (e: SelectChangeEvent) => {
		const departamento = e.target.value;
		if (isValidFieldRequired(departamento)) {
			fetchAndSetMunicipioById(e.target.value);
		} else {
			setDdlMunicipio([]);
		}
		setUserData({
			...userData,
			departamento: departamento,
			municipio: '',
			localidad: '',
			latitud: 0,
			longitud: 0
		});
	};

	const onChangeRequiereCapacitacionEspecifica = (e: SelectChangeEvent) => {
		if (e.target.value !== '') {
			setCapacitacion(e.target.value);
			//Si la opcion seleccionado es NO, entonces se limpia el contenido del atributo "Descripcion de la capacitación especifica"
			if (e.target.value === '1') {
				setUserData({ ...userData, requiere_capacitacion_especifica: e.target.value, descricion_capacitacion_especifica: '' });
			} else {
				setUserData({ ...userData, requiere_capacitacion_especifica: e.target.value });
			}
		} else {
			setUserData({ ...userData, requiere_capacitacion_especifica: e.target.value });
			setCapacitacion('');
		}
	};

	const handleAddComplemento = () => {
		setUserData((prevUserData: { VagaComplementos: any[] }) => ({
			...prevUserData,
			VagaComplementos: [
				...prevUserData.VagaComplementos,
				{ tipo_complemento: '', complemento: '' }, // Adiciona objeto vacio para nuevo complemento
			],
		}));
	};

	const handleDelComplemento = (index: any) => {
		setUserData((prevUserData: { VagaComplementos: any[] }) => {
			const updatedComplemento = prevUserData?.VagaComplementos?.filter((item, idx) => idx !== index);
			return {
				...prevUserData,
				VagaComplementos: updatedComplemento,
			};
		});
	};

	const strDirection = (val: any) => {
		return !isValidFieldRequired(val) ? '' : val;
	};

	const changeDosEspaciosPoUno = (direccion: string) => {
		return direccion.replace(/\s+/g, ' ');
	}

	const handleDirectionValue = () => {
		let val =
			strDirection(userData?.['local']) +
			' ' +
			strDirection(userData?.['id_coordenada']) +
			' ' +
			strDirection(userData?.['id_coordenada_02']) +
			' ' +
			strDirection(userData?.['id_coordenada_03']) +
			' ' +
			strDirection(userData?.['id_coordenada_04']) +
			' ' +
			strDirection(userData?.['id_coordenada_05']) +
			' ' +
			strDirection(userData?.['id_coordenada_06']) +
			' ' +
			strDirection(userData?.['id_coordenada_07']) +
			' ' +
			strDirection(userData?.['id_coordenada_08']) +
			' ' +
			strDirection(userData?.['id_coordenada_09']) +
			' ';

		if (
			userData['VagaComplementos'] !== null &&
			userData['VagaComplementos'] !== undefined &&
			(userData['VagaComplementos'] as Array<any>).length > 0
		) {
			if (
				userData['VagaComplementos'] &&
				userData['VagaComplementos'].length > 0 &&
				isValidFieldRequired(userData['VagaComplementos'][0].tipo_complemento)
			) {
				val = val + ', ';
			}

			(userData['VagaComplementos'] as Array<any>).forEach((e) => {
				let x =
					strDirection(
						setValueOrDefault(
							listViaPrincipalComplementoDireccion.find((y) => y.code === e['tipo_complemento'])?.value,
							'string'
						)
					) +
					' ' +
					strDirection(setValueOrDefault(e['complemento'], 'string')) +
					' ';
				val = val + x;
			});
		}

		let direccion = val.trimEnd() === '' ? '' : val.trimEnd() + '';

		direccion = changeDosEspaciosPoUno(direccion);

		if (direccion !== userData?.['direccion_vaga']) {
			setUserData({ ...userData, direccion_vaga: direccion })
			return direccion;
		} else if (direccion === userData?.['direccion_vaga']) {
			return direccion;
		} else {
			return '';
		}
	};

	const onChangeDireccionResidencia = (value: string) => {
		setUserData({ ...userData, direccion_vaga: value })
	};

	const onChangeVacanteTeletrabajo = (value: string) => {
		if (value !== '0' && value !== '3') {
			setUserData({
				...userData,
				vacante_susceptible_teletrabajo: value,
				direccion_vaga: null,
				local: null,
				id_coordenada: null,
				id_coordenada_02: null,
				id_coordenada_03: null,
				id_coordenada_04: null,
				id_coordenada_05: null,
				id_coordenada_06: null,
				id_coordenada_07: null,
				id_coordenada_08: null,
				id_coordenada_09: null,
				VagaComplementos: []
			});
		} else {
			setUserData({ ...userData, vacante_susceptible_teletrabajo: value });
		}
	};

	const longitudmaximaDireccion = 500;
	const mensajeErrorLongitudMaximaDireccion = `La dirección no puede ser superior a ${longitudmaximaDireccion} caracteres`;

	class validationForm {
		nivel_estudio: string = initValue(userData['nivel_estudio']);
		graduado: string = initValue(userData['graduado']);
		formacion_titulo: string = initValue(userData['formacion_titulo']);
		requiere_experiencia_relacionada: string = initValue(userData['requiere_experiencia_relacionada']);
		requiere_capacitacion_especifica: string = initValue(userData['requiere_capacitacion_especifica']);
		vacante_susceptible_teletrabajo: string = initValue(userData['vacante_susceptible_teletrabajo']);
		rango_experiencia: string = initValue(userData['rango_experiencia']);
		descricion_capacitacion_especifica: string = initValue(userData['descricion_capacitacion_especifica']);
		departamento: string = initValue(userData['departamento']);
		municipio: string = initValue(userData['municipio']);
		localidad: string = initValue(userData['localidad']);
		direccion: string = initValue(userData['direccion_vaga']);
		experienciasEspecificas: string[] = [];
	}

	const formSchema = () => {

		const nivel_estudio = Yup.string().required('Campo requerido');
		const graduado = Yup.string().required('Campo requerido');
		const formacion_titulo = Yup.string().required('Campo requerido');
		const requiere_experiencia_relacionada = Yup.string().required('Campo requerido');
		const requiere_capacitacion_especifica = Yup.string().required('Campo requerido');
		const vacante_susceptible_teletrabajo = Yup.string()
			.test('checkVacanteSuceptibleTeleTrabajo', 'Municipio diferente a Bogotá y la vacante no es susceptible a teletrabajo o virtual. La Agencia Distrital de Empleo gestiona vacantes únicamente para distrito capital', (value) => {
				//'La Agencia Distrital de Empleo gestiona vacantes únicamente para el distrito de Bogotá'
				if (isValidFieldRequired(userData['departamento']) || isValidFieldRequired(userData['municipio'])) {
					if ((userData['departamento'] !== '11' && userData['municipio'] !== '11001' && (value === '0' || value == '3'))) {
						setAlert(false);
						setAlertMsg(
							`Error en el diligenciamiento de la información\n 
							Municipio diferente a Bogotá y la vacante no es susceptible a teletrabajo o virtual. La Agencia Distrital de Empleo gestiona vacantes únicamente para distrito capital`);
						setOpen(true);
						setTimeout(() => {
							setOpen(false);
						}, 5000);
						formik.setFieldError('vacante_susceptible_teletrabajo', 'Municipio diferente a Bogotá y la vacante no es susceptible a teletrabajo o virtual. La Agencia Distrital de Empleo gestiona vacantes únicamente para distrito capital');
						return false;
					}
				}
				return true;
			});
		const rango_experiencia = Yup.number().required('Campo requerido').test('minValue', 'Valor mínimo permitido es 0', (val) => { return Number(val) < 0 ? false : true; });
		const departamento = Yup.string().required('Campo requerido');
		const municipio = Yup.string().required('Campo requerido');
		const experienciaEspecifica = Yup.object().shape({
			nombre_experiencia: Yup.string().required('Campo requerido'),
			duracion: Yup.number().required('Campo requerido').min(0, 'El valor mínimo permitido es 0'),
		});

		if (capacitacion === '1') {
			return Yup.object().shape({
				nivel_estudio: nivel_estudio,
				graduado: graduado,
				formacion_titulo: formacion_titulo,
				requiere_experiencia_relacionada: requiere_experiencia_relacionada,
				requiere_capacitacion_especifica: requiere_capacitacion_especifica,
				vacante_susceptible_teletrabajo: vacante_susceptible_teletrabajo,
				rango_experiencia: rango_experiencia,
				descricion_capacitacion_especifica: Yup.string().required('Campo requerido'),
				departamento: departamento,
				municipio: municipio,

				//	Se establece el caso de cuando el departamento corresponde a Bogota
				localidad: Yup.array().of(Yup.string()).when('departamento', (value) => {
					if (value === '11') {
						return Yup.string().required('Campo requerido');
					}
					return Yup.string()
				}),
				experienciasEspecificas: Yup.array().of(experienciaEspecifica),
				//	Se establece que la direccion es requerida y debe ser valida
				direccion: Yup.string().when('vacante_susceptible_teletrabajo', (value) => {
					if (value === '0' || value === '3') {
						return Yup.string().
							when('direccion', {
								is: (direccion: any) => shouldDisable == false,
								then: Yup.string()
							})
							// .test('checkDireccionValida', 'Debe seleccionar un tipo de via e ingresar al menos un número en la dirección', () => {
							// 	if (!shouldDisable && !isValidAddress(userData?.['direccion_vaga'])) {
							// 		formik.setFieldError('direccion', 'Debe seleccionar un tipo de via e ingresar al menos un número en la dirección');
							// 		return false;
							// 	}
							// 	return true;
							// })
							.test('checkDireccionLongitud', mensajeErrorLongitudMaximaDireccion, () => {
								if (!shouldDisable && (userData?.['direccion_vaga']?.length > longitudmaximaDireccion)) {
									formik.setFieldError('direccion', mensajeErrorLongitudMaximaDireccion);
									return false;
								}
								return true;
							});
					}
					return Yup.string();
				})
			});
		} else {
			return Yup.object().shape({
				nivel_estudio: nivel_estudio,
				graduado: graduado,
				formacion_titulo: formacion_titulo,
				requiere_experiencia_relacionada: requiere_experiencia_relacionada,
				requiere_capacitacion_especifica: requiere_capacitacion_especifica,
				vacante_susceptible_teletrabajo: vacante_susceptible_teletrabajo,
				rango_experiencia: rango_experiencia,
				descricion_capacitacion_especifica: Yup.string(),
				departamento: departamento,
				municipio: municipio,

				//	Se establece el caso de cuando el departamento corresponde a Bogota D.C
				localidad: Yup.array().of(Yup.string()).when('departamento', (value) => {
					if (value === '11') {
						return Yup.string().required('Campo requerido');
					}
					return Yup.string()
				}),
				experienciasEspecificas: Yup.array().of(experienciaEspecifica),
				//	Se establece que la direccion es requerida y debe ser valida
				direccion: Yup.string().when('vacante_susceptible_teletrabajo', (value) => {
					if (value === '0' || value === '3') {
						return Yup.string()
							.when('direccion', {
								is: (direccion: any) => shouldDisable == false,
								then: Yup.string()
							})
							// .test('checkDireccionValida', 'Debe seleccionar un tipo de via e ingresar al menos un número en la dirección', () => {
							// 	if (!shouldDisable && !isValidAddress(userData?.['direccion_vaga'])) {
							// 		formik.setFieldError('direccion', 'Debe seleccionar un tipo de via e ingresar al menos un número en la dirección');
							// 		return false;
							// 	}
							// 	return true;
							// })
							.test('checkDireccionLongitud', mensajeErrorLongitudMaximaDireccion, () => {
								if (!shouldDisable && (userData?.['direccion_vaga']?.length > longitudmaximaDireccion)) {
									formik.setFieldError('direccion', mensajeErrorLongitudMaximaDireccion);
									return false;
								}
								return true;
							});
					}
					return Yup.string();
				})
			});
		}
	};

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				const data = { ...userData,  }
				const nextStep = () => {
					handleNext()
					setSubmitting(true)
					if (formik.isValid) setStep(5)
						setStatus('')
				}
				if (data.status_approval === 'TEMPORAL') return putVacancyTempral(data).then((res) => {
					nextStep()
				}).catch((error) => {
					console.log(error);
				}); else nextStep()
			} catch (error) {
				setSubmitting(false)
				setStatus('Información incorrecta')
			}
		},
	})

	const handleNext = async () => {

		const allIndexes = userData.VagaExperienciaEspecificas?.map((_: any, index: any) => index);
		setTouchedExperiencias(allIndexes);

		let errors: { field: string; error: string }[] = [];

		if (!isValidFieldRequired(userData['nivel_estudio']))
			errors.push({ field: 'nivel_estudio', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['graduado']))
			errors.push({ field: 'graduado', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['formacion_titulo']))
			errors.push({ field: 'formacion_titulo', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['requiere_experiencia_relacionada']))
			errors.push({ field: 'Requiere experiencia relacionadas', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['requiere_capacitacion_especifica']))
			errors.push({ field: 'Requiere capacitación especifica', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['vacante_susceptible_teletrabajo']))
			errors.push({ field: 'Vacante susceptible a teletrabajo', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['rango_experiencia']))
			errors.push({ field: 'Rango de experiencia', error: 'Campo requerido' });

		if (!isValidMaxText(userData['descricion_capacitacion_especifica'], 1000, userData['requiere_capacitacion_especifica'] === '1')) {
			errors.push({ field: 'descricion_capacitacion_especifica', error: helperValidMaxText(userData['descricion_capacitacion_especifica'], 1000, userData['requiere_capacitacion_especifica'] === '1') })
		}

		if (!isValidFieldRequired(userData['departamento']))
			errors.push({ field: 'Departamento', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['municipio']))
			errors.push({ field: 'Municipio', error: 'Campo requerido' });

		//Departamento: Bogotá D.C y Municipio: Bogotá D.C
		if (userData['departamento'] === '11' && isValidFieldRequired(userData['municipio'] === '11001')) {
			if (!isValidFieldRequired(userData['localidad']))
				errors.push({ field: 'Localidad', error: 'Campo requerido' });
		}

		// Aplica reglas de validación para la vacante de teletrabajo, considerando si está ubicada en Bogotá o no
		if (userData['departamento'] !== '11' && userData['municipio'] !== '11001' && (userData['vacante_susceptible_teletrabajo'] === '0' || userData['vacante_susceptible_teletrabajo'] == '3')) {
			formik.setFieldError('vacante_susceptible_teletrabajo', 'Municipio diferente a Bogotá y la vacante no es susceptible a teletrabajo o virtual. La Agencia Distrital de Empleo gestiona vacantes únicamente para distrito capital');
			errors.push({ field: 'Vacante susceptible a teletrabajo', error: 'Municipio diferente a Bogotá y la vacante no es susceptible a teletrabajo o virtual. La Agencia Distrital de Empleo gestiona vacantes únicamente para distrito capital' });
		}

		if (userData.VagaExperienciaEspecificas?.length > 0) {
			const sumaExperienciasEspecificas = userData.VagaExperienciaEspecificas.reduce((total: any, experiencia: any) => {
				return total + experiencia.duracion; // Suma las duraciones
			}, 0);

			if (userData.rango_experiencia > 0 && sumaExperienciasEspecificas > userData.rango_experiencia) {
				errors.push({ field: 'Experiencias especificas', error: 'Las experiencias especificas no pueden ser mayores a la experiencia minima requerida' });
			}

			const todasLasExperienciasTienenNombre = userData.VagaExperienciaEspecificas?.every((experiencia: any) => experiencia.nombre_experiencia.trim() !== '');
			if (!todasLasExperienciasTienenNombre) {
				errors.push({ field: 'Experiencias especificas', error: 'Todas las experiencias especificas requieren un nombre' });
			}

			const todasLasExperienciasTienenCantidad = userData.VagaExperienciaEspecificas.every((experiencia: any) => experiencia.duracion > 0);
			if (!todasLasExperienciasTienenCantidad) {
				errors.push({ field: 'Experiencias especificas', error: 'Todas las experiencias especificas requieren un valor válido' });
			}
		}

		//Reglas de validación de la dirección
		if (userData['vacante_susceptible_teletrabajo'] === '0' || userData['vacante_susceptible_teletrabajo'] === '3') {
			// if (!shouldDisable && !isValidFieldRequired(userData?.['direccion_vaga'].trim().replaceAll(',', '')))
			// 	errors.push({ field: 'Dirección vacante', error: 'Campo requerido' });
			// // else if (!shouldDisable && !isValidAddress(userData?.['direccion_vaga']))
			// // 	errors.push({ field: 'Dirección vacante', error: 'Debe seleccionar un tipo de via e ingresar al menos un número en la dirección' });
			// else if (userData?.['direccion_vaga']?.length > longitudmaximaDireccion){
			// 	errors.push({ field: 'Dirección vacante', error: mensajeErrorLongitudMaximaDireccion });				
			// }
			//Revision de complementos de direccion
			complementosDireccion?.forEach((element: any, index: any) => {
				if (isValidFieldRequired(element?.tipo_complemento) && !isValidFieldRequired(element?.complemento)) {
					errors.push({ field: `Complemento [${index + 1}]`, error: 'Campo requerido' });
				}
			});
		}

		setUserData({
			...userData,
			latitud: setValueOrDefault(listLocalidades.find((l: any) => l.id_localidad === userData['localidad'])?.latitud, 'number'),
			longitud: setValueOrDefault(listLocalidades.find((l: any) => l.id_localidad === userData['localidad'])?.longitud, 'number')
		});

		if (errors.length > 0) {			
			setAlert(false);
			setAlertMsg(
				`\Error en diligenciamiento de la información\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		} else {
			setStep(5);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeTipoComplemento = (e: any, index: any) => {
		setUserData((prevUserData: { VagaComplementos: any[] }) => {
			const updatedComplemento = (complementosDireccion).map(
				(item: any, idx: any) => {
					if (idx === index) {
						return {
							...item,
							tipo_complemento: e.target.value,
						};
					} else {
						return item;
					}
				}
			);
			return {
				...prevUserData,
				VagaComplementos: updatedComplemento,
			};
		});
	};

	const handleChangeComplemento = (e: any, index: any) => {
		setUserData((prevUserData: { VagaComplementos: any[] }) => {
			const updatedComplemento = (complementosDireccion).map(
				(item: any, idx: any) => {
					if (idx === index) {
						return {
							...item,
							complemento: e.target.value,
						};
					} else {
						return item;
					}
				});
			return {
				...prevUserData,
				VagaComplementos: updatedComplemento,
			};
		})
	};

	return (
		<>
			{loadingParams && loadingParamsAddress
				? <LinearLoadingSpinner isLoading={true} />
				: <>
					<Box sx={{ mb: 3 }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
							Requisitos de la Vacante
						</Typography>
					</Box>
					<Grid container direction='row' justifyContent='left' width={'100%'} item={true}>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.nivel_estudio && Boolean(formik.errors.nivel_estudio)}>
								<InputLabel required id='nivel_estudio'>
									Nivel de estudio
								</InputLabel>
								<Select
									fullWidth
									labelId='nivel_estudio'
									{...formik.getFieldProps('nivel_estudio')}
									error={formik.touched.nivel_estudio && Boolean(formik.errors.nivel_estudio)}
									required
									name='nivel_estudio'
									id='nivel_estudio'
									label='Nivel de estudio'
									value={initValue(userData['nivel_estudio'])}
									onChange={(e) => {
										setUserData({ ...userData, nivel_estudio: e.target.value })
										formik.handleChange(e)
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listNivelEducativo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.nivel_estudio ? formik.errors.nivel_estudio : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.graduado && Boolean(formik.errors.graduado)}>
								<InputLabel required id='graduado'>Graduado</InputLabel>
								<Select
									labelId='graduado'
									{...formik.getFieldProps('graduado')}
									error={formik.touched.graduado && Boolean(formik.errors.graduado)}
									required
									name='graduado'
									id='graduado'
									label='Graduado'
									value={initValue(userData['graduado'])}
									onChange={(e) => {
										setUserData({ ...userData, graduado: e.target.value })
										formik.handleChange(e)
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.graduado ? formik.errors.graduado : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.formacion_titulo && Boolean(formik.errors.formacion_titulo)}>
								<TextField
									fullWidth
									{...formik.getFieldProps('formacion_titulo')}
									error={formik.touched.formacion_titulo && Boolean(formik.errors.formacion_titulo)}
									helperText={formik.touched.formacion_titulo ? formik.errors.formacion_titulo : ''}
									required
									label='Formación o título requerido'
									value={initValue(userData['formacion_titulo'])}
									inputProps={{ type: 'text', maxLength: 1000 }}
									onChange={(e) => {
										setUserData({ ...userData, formacion_titulo: e.target.value })
										formik.handleChange(e)
									}}
									size='medium'
									disabled={shouldDisable}
								/>
							</FormControl>
						</Grid>
						<Grid xs={12} md={userData['departamento'] === '11' ? 4 : 6} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.departamento && Boolean(formik.errors.departamento)}>
								<InputLabel required id='departamento'>
									Departamento
								</InputLabel>
								<Select
									fullWidth
									labelId='departamento'
									{...formik.getFieldProps('departamento')}
									error={formik.touched.departamento && Boolean(formik.errors.departamento)}
									required
									name='departamento'
									id='departamento'
									label='Departamento'
									value={initValue(userData['departamento'])}
									onChange={(e) => {
										onChangeDepartamento(e)
										formik.handleChange(e)
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listDepartamento.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>

								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.departamento ? formik.errors.departamento : ''}
								</FormHelperText>

							</FormControl>
						</Grid>
						<Grid xs={12} md={userData['departamento'] === '11' ? 4 : 6} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.municipio && Boolean(formik.errors.municipio)}>
								<InputLabel required id='municipio'>
									Municipio
								</InputLabel>
								<Select
									fullWidth
									labelId='municipio'
									{...formik.getFieldProps('municipio')}
									error={formik.touched.municipio && Boolean(formik.errors.municipio)}
									required
									name='municipio'
									id='municipio'
									label='Municipio'
									value={initValue(userData['municipio'])}
									onChange={(e) => {
										setUserData({ ...userData, municipio: e.target.value })
										formik.handleChange(e)
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlMunicipio.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.municipio ? formik.errors.municipio : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						{/* Solo se habilita este campo cuando el país de residencia es Colombia y el Departamento es: Bogotá D.C */}
						{userData['departamento'] === '11' && (
							<>
								<Grid xs={12} md={4} mb={5} pr={2} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.localidad && Boolean(formik.errors.localidad)}>
										<InputLabel required id='localidad'>
											Localidad
										</InputLabel>
										<Select
											fullWidth
											labelId='localidad'
											{...formik.getFieldProps('localidad')}
											error={formik.touched.localidad && Boolean(formik.errors.localidad)}
											required={userData['departamento'] === '11'}
											name='localidad'
											id='localidad'
											label='Localidad'
											value={initValue(userData['localidad'])}
											onChange={(e) => {
												setUserData({ ...userData, localidad: e.target.value })
												formik.handleChange(e)
											}}
											disabled={shouldDisable}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listLocalidades.map(({ id_localidad, desc_localidad }) => (
												<MenuItem key={id_localidad} value={id_localidad}>
													{desc_localidad}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.localidad ? formik.errors.localidad : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
							</>
						)}
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.requiere_experiencia_relacionada && Boolean(formik.errors.requiere_experiencia_relacionada)}>
								<InputLabel required id='requiere_experiencia_relacionada'>
									¿Requiere experiencia relacionada?
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('requiere_experiencia_relacionada')}
									labelId='requiere_experiencia_relacionada'
									id='requiere_experiencia_relacionada'
									label='¿Requiere experiencia relacionada?'
									error={formik.touched.requiere_experiencia_relacionada && Boolean(formik.errors.requiere_experiencia_relacionada)}
									value={initValue(userData['requiere_experiencia_relacionada'])}
									onChange={(e) => {
										setUserData({ ...userData, requiere_experiencia_relacionada: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.requiere_experiencia_relacionada ? formik.errors.requiere_experiencia_relacionada : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.requiere_capacitacion_especifica && Boolean(formik.errors.requiere_capacitacion_especifica)}>
								<InputLabel required id='requiere_capacitacion_especifica'>
									¿Requiere capacitación específica?
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('requiere_capacitacion_especifica')}
									labelId='requiere_capacitacion_especifica'
									id='requiere_capacitacion_especifica'
									label='¿Requiere capacitación específica?'
									value={initValue(userData['requiere_capacitacion_especifica'])}
									onChange={(e) => {
										onChangeRequiereCapacitacionEspecifica(e);
										formik.handleChange(e);
									}
									}
									onBlur={formik.handleBlur}
									error={formik.touched.requiere_capacitacion_especifica && Boolean(formik.errors.requiere_capacitacion_especifica)}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.requiere_capacitacion_especifica ? formik.errors.requiere_capacitacion_especifica : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.vacante_susceptible_teletrabajo && Boolean(formik.errors.vacante_susceptible_teletrabajo)}>
								<InputLabel required id='vacante_susceptible_teletrabajo'>
									¿Vacante susceptible a teletrabajo?
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('vacante_susceptible_teletrabajo')}
									labelId='vacante_susceptible_teletrabajo'
									id='vacante_susceptible_teletrabajo'
									label='¿Vacante susceptible a teletrabajo?'
									value={initValue(userData['vacante_susceptible_teletrabajo'])}
									error={formik.touched.vacante_susceptible_teletrabajo && Boolean(formik.errors.vacante_susceptible_teletrabajo)}
									onChange={(e) => {
										onChangeVacanteTeletrabajo(e.target.value);
										formik.handleChange(e);
									}
									}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listPresencialidad.map(({ code, value }, i) => (
										<MenuItem value={code} key={i}>
											{value}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.vacante_susceptible_teletrabajo ? formik.errors.vacante_susceptible_teletrabajo : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						{userData['requiere_capacitacion_especifica'] === '1' && (
							<Grid xs={12} mb={5} pr={2} item={true}>
								<FormControl sx={{ minWidth: '100%' }} size='medium' error={formik.touched.descricion_capacitacion_especifica && !isValidMaxText(userData['descricion_capacitacion_especifica'], 1000, userData['requiere_capacitacion_especifica'] === '1')}>
									<TextField
										fullWidth
										{...formik.getFieldProps('descricion_capacitacion_especifica')}
										error={
											//formik.touched.descricion_capacitacion_especifica && Boolean(formik.errors.descricion_capacitacion_especifica)
											!isValidMaxText(userData['descricion_capacitacion_especifica'], 1000, userData['requiere_capacitacion_especifica'] === '1')
										}
										helperText={
											//formik.touched.descricion_capacitacion_especifica ? formik.errors.descricion_capacitacion_especifica : ''
											helperValidMaxText(userData['descricion_capacitacion_especifica'], 1000, userData['requiere_capacitacion_especifica'] === '1')
										}
										required
										id='descricion_capacitacion_especifica'
										name='descricion_capacitacion_especifica'
										label='Descripción de la capacitación específica'
										value={initValue(userData['descricion_capacitacion_especifica'])}
										onChange={(e) => {
											setUserData({ ...userData, descricion_capacitacion_especifica: e.target.value })
											formik.handleChange(e)
											setWordCount(() => e.target.value.length)
										}}
										size='medium'
										multiline
										rows={5}
										inputProps={{ maxLength: 1000 }}
										disabled={shouldDisable}
									/>
									{/* <p>{1000 - wordCount} caracteres restantes </p> */}
								</FormControl>
							</Grid>
						)}
						{(userData.vacante_susceptible_teletrabajo === '0' || userData.vacante_susceptible_teletrabajo === '3') && (
							<>
								<Grid container direction={'row'} item={true}>
									<Box sx={{ mb: 1 }}>
										<Typography variant='h6' sx={{ fontWeight: 'bold' }} mb={1}>
											Dirección
										</Typography>
										<Typography variant='inherit' mb={1}>
											Por favor, complete la dirección del puesto de trabajo.
										</Typography>
									</Box>
								</Grid>
								<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
									<Grid item xs={12} md={12} mb={2}>
										<Grid container spacing={2} >
											<Grid xs={2} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='local'
														id='local'
														value={initValue(userData['local'])}
														onChange={(e) =>
															setUserData({ ...userData, local: e.target.value })
														}
														inputProps={{ 'aria-label': 'Without label' }}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalEmpresa.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid xs={1} mb={5} item={true}>
												<TextField
													fullWidth
													required
													id='id_coordenada'
													name='id_coordenada'
													value={initValue(userData['id_coordenada'])}
													onKeyDown={eventOnKeyDownInputAlphaNumeric}
													inputProps={{
														maxLength: 20,
														minLength: 1,
													}}
													onChange={(e) =>
														setUserData({ ...userData, id_coordenada: e.target.value })
													}
													onBlur={() => handleBlur('direccion')}
													size='medium'
													disabled={shouldDisable}
												/>
											</Grid>
											<Grid xs={1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_02'
														id='id_coordenada_02'
														value={initValue(userData['id_coordenada_02'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_02: e.target.value })
														}
														onBlur={() => handleBlur('direccion')}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid xs={1.1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_03'
														id='id_coordenada_03'
														value={initValue(userData['id_coordenada_03'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_03: e.target.value })
														}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{viaPrincipalBisList.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid xs={1.1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_04'
														id='id_coordenada_04'
														value={initValue(userData['id_coordenada_04'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_04: e.target.value })
														}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid xs={1.1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_05'
														id='id_coordenada_05'
														value={initValue(userData['id_coordenada_05'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_05: e.target.value })
														}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalCuadrante.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item container xs={0.2} mb={5} justifyContent="center" alignItems="center">
												<span
													style={{
														display: 'flex',
														justifyContent: 'center',
														fontWeight: 'bold',
													}}
												>#</span>
											</Grid>
											<Grid xs={1} mb={5} item={true}>
												<TextField
													fullWidth
													required
													id='id_coordenada_06'
													name='id_coordenada_06'
													value={initValue(userData['id_coordenada_06'])}
													onKeyDown={(e: any) => {
														eventOnKeyDownInputNumber(e);
													}}
													onChange={(e) =>
														setUserData({ ...userData, id_coordenada_06: e.target.value.slice(0, 4) })
													}
													size='medium'
													type='number'
													InputProps={{ inputProps: { min: 0, maxLength: 4 } }}
													disabled={shouldDisable}
												/>
											</Grid>
											<Grid xs={1.1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_07'
														id='id_coordenada_07'
														value={initValue(userData['id_coordenada_07'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_07: e.target.value })
														}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item container xs={0.2} mb={5} justifyContent="center" alignItems="center">
												<span
													style={{
														display: 'flex',
														justifyContent: 'center',
														fontWeight: 'bold',
													}}
												>-</span>
											</Grid>
											<Grid xs={1} mb={5} item={true}>
												<TextField
													fullWidth
													required
													id='id_coordenada_08'
													name='id_coordenada_08'
													value={initValue(userData['id_coordenada_08'])}
													inputProps={{
														maxLength: 4,
														minLength: 1,
													}}
													onKeyDown={(e: any) => {
														eventOnKeyDownInputNumber(e);
													}}
													onChange={(e) =>
														setUserData({ ...userData, id_coordenada_08: e.target.value.slice(0, 4) })
													}
													size='medium'
													type='number'
													InputProps={{ inputProps: { min: 0, maxLength: 4 } }}
													disabled={shouldDisable}
												/>
											</Grid>
											<Grid xs={1} mb={5} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<Select
														labelId='id_coordenada_09'
														id='id_coordenada_09'
														value={initValue(userData['id_coordenada_09'])}
														onChange={(e) =>
															setUserData({ ...userData, id_coordenada_09: e.target.value })
														}
														disabled={shouldDisable}
													>
														<MenuItem value=''>Seleccione...</MenuItem>
														{listViaPrincipalCuadrante.map(({ code, value }, i) => (
															<MenuItem key={i} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid xs={12} item={true}>
									{complementosDireccion?.map((row: any, key: any) => (
										<Grid xs={12} key={key} item={true}>
											<Grid
												container
												direction='row'
												justifyContent='space-between'
												width={'100%'}
												item={true}
											>
												<Grid xs={12} md={4} mb={5} pr={1} item={true}>
													<FormControl sx={{ minWidth: '100%' }} size='medium'>
														<InputLabel id={'tipo_complemento' + key}>Tipo de complemento</InputLabel>
														<Select
															labelId={'tipo_complemento' + key}
															id={'tipo_complemento' + key}
															label='Tipo de complemento'
															value={initValue(complementosDireccion[key]['tipo_complemento'])}
															onChange={(e) => handleChangeTipoComplemento(e, key)}
															disabled={shouldDisable}
														>
															<MenuItem value='' key=''>
																Seleccione...
															</MenuItem>
															{listViaPrincipalComplementoDireccion.map(({ code, value }) => (
																<MenuItem key={code} value={code}>
																	{value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid xs={12} md={5} mb={5} pr={1} item={true}>
													<FormControl sx={{ minWidth: '100%' }} size='small'>
														<TextField
															required={isValidFieldRequired(complementosDireccion[key]?.tipo_complemento)}
															fullWidth
															id={'complemento' + key}
															name='complemento'
															label='Complemento'
															value={initValue(complementosDireccion[key]['complemento'])}
															onChange={(e) => handleChangeComplemento(e, key)}
															disabled={shouldDisable}
															size='medium'
															inputProps={{ maxLength: 100 }}
															error={isValidFieldRequired(complementosDireccion[key]?.tipo_complemento) && !isValidFieldRequired(complementosDireccion[key]?.complemento)}
															helperText={isValidFieldRequired(complementosDireccion[key]?.tipo_complemento) && !isValidFieldRequired(complementosDireccion[key]?.complemento) ? 'Campo requerido' : ''}
														/>
													</FormControl>
												</Grid>
												{key === 0 ? (
													<Grid xs={12} md={3} mb={5} pr={1} item={true}>
														<Button
															fullWidth
															variant='contained'
															onClick={handleAddComplemento}
															sx={{ background: '#1976d2', color: 'white', fontSize: 10, height: '50px' }}
															disabled={shouldDisable && userData.VagaComplementos.length === 0}
														>
															Nuevo complemento +
														</Button>
													</Grid>
												) : (
													<Grid xs={12} md={3} mb={5} pr={1} item={true}>
														<Button
															fullWidth
															variant='contained'
															onClick={() => handleDelComplemento(key)}
															sx={{ background: '#C4D2E8', color: 'black', fontSize: 10, height: '50px' }}
															disabled={shouldDisable}
														>
															Excluir complemento -
														</Button>
													</Grid>
												)}
											</Grid>
										</Grid>
									))}
								</Grid>
								<Grid xs={12} md={12} mb={5} pr={1} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium' 
									error={formik.touched.direccion && Boolean(formik.errors.direccion)}
									>
										<TextField
											fullWidth
											// required
											id='direccion'
											name='direccion'
											label='Dirección vacante'
											value={initValue(userData['direccion_vaga'])}
											onChange={(e) => onChangeDireccionResidencia(e.target.value)}
											size='medium'
											error={formik.touched.direccion && Boolean(formik.errors.direccion)}
											helperText={formik.touched.direccion ? formik.errors.direccion : ''}
											inputProps={{ readOnly: true }}
										/>
									</FormControl>
								</Grid>
							</>
						)}
						<Box sx={{ mb: 3 }} style={{ textAlign: 'justify' }}>
							<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
								Experiencia requerida para la vacante
							</Typography>
							<br />
							Puedes agregar cuanta experiencia deseas que tenga el candidato en meses. También, si quieres, puedes agregar experiencias específicas para tener una búsqueda más efectiva de candidatos (ejemplo: Necesito candidato con 24 meses de experiencia general, pero minimo 6 meses en atención al cliente). Esto te permitirá encontrar candidatos más acordes.
						</Box>
						<Grid container direction='row' justifyContent='left' width={'100%'} item={true}>
							<Grid xs={12} md={4} mb={5} pr={2} item={true}>
								<TextField
									required
									{...formik.getFieldProps('rango_experiencia')}
									fullWidth
									id='rango_experiencia'
									name='rango_experiencia'
									label='Experiencia mínima requerida (en meses)'
									value={initValue(userData['rango_experiencia'])}
									onKeyDown={(e: any) => {
										eventOnKeyDownInputNumber(e);
									}}
									onChange={(e) => {
										if (e?.target?.value?.length <= 4) {
											setUserData({ ...userData, rango_experiencia: e.target.value })
										}
									}}
									size='medium'
									disabled={reqExperience || shouldDisable}
									inputProps={{ type: 'number', maxLength: 4, min: 0 }}
									error={formik.touched.rango_experiencia && Boolean(formik.errors.rango_experiencia)}
									helperText={formik.touched.rango_experiencia ? formik.errors.rango_experiencia : ''}
								/>
							</Grid>
							{userData.VagaExperienciaEspecificas?.map((experiencia: any, index: any) => (
								<Grid key={index} xs={12} md={9} mb={1} pr={2} item={true}>
									<Grid container spacing={2} alignItems="left" item>
										<Grid item xs={4} pr={2} mb={1}>
											<TextField
												required
												fullWidth
												label={`Nombre o tipo de la experiencia ${index + 1}`}
												value={experiencia.nombre_experiencia}
												onChange={(e) => {
													const updatedExperiencias = [...userData.VagaExperienciaEspecificas];
													updatedExperiencias[index].nombre_experiencia = e.target.value;
													setUserData({ ...userData, VagaExperienciaEspecificas: updatedExperiencias });
													const updatedTouchedExperiencias = touchedExperiencias.filter((touchedIndex) => touchedIndex !== index);
													setTouchedExperiencias(updatedTouchedExperiencias);
												}}
												disabled={reqExperience || shouldDisable}
												error={touchedExperiencias.includes(index) && experiencia.nombre_experiencia.trim() === ''}
												helperText={(touchedExperiencias.includes(index) && experiencia.nombre_experiencia.trim() === '') ? 'Campo requerido' : ''}
												onBlur={() => handleFieldTouch(index)}
											/>
										</Grid>
										<Grid xs={4} pr={2} mb={1} item={true}>
											<TextField
												required
												fullWidth
												label={`Cantidad en esa experiencia ${index + 1} (en meses)`}
												value={experiencia.duracion.toString()}
												onKeyDown={(e: any) => {
													eventOnKeyDownInputNumber(e);
												}}
												onChange={(e) => {
													if (e?.target?.value?.length <= 4) {
														const updatedExperiencias = [...userData.VagaExperienciaEspecificas];
														updatedExperiencias[index].duracion = parseInt(e.target.value);
														setUserData({ ...userData, VagaExperienciaEspecificas: updatedExperiencias });
														const updatedTouchedExperiencias = touchedExperiencias.filter((touchedIndex) => touchedIndex !== index);
														setTouchedExperiencias(updatedTouchedExperiencias);
													}
												}}
												type="number"
												inputProps={{ min: 0 }}
												disabled={reqExperience || shouldDisable}
												error={touchedExperiencias.includes(index) && ((parseInt(experiencia.duracion) <= 0) || experiencia.duracion === '' || experienciasExcedidas)}
												helperText={helperText(index, experiencia.duracion)}
												onBlur={() => handleFieldTouch(index)}
											/>
										</Grid>
										{!shouldDisable && <Grid item xs={4}>
											<IconButton onClick={() => removeExperienciaEspecifica(index)}>
												<DeleteIcon />
											</IconButton>
										</Grid>}
									</Grid>
								</Grid>
							))}
							{sumaExperiencias !== undefined && <Grid xs={12} pr={2} mb={5} item>
								<Box sx={{ mb: 3, textAlign: 'justify' }}>
									Experiencia específica asignada = {sumaExperiencias}
								</Box>
							</Grid>}
							{!shouldDisable && <Grid xs={12} pr={2} mb={5} item>
								<Button variant='contained' color='primary' disabled={disableExperiencias} onClick={addExperienciaEspecifica}>
									Agregar Experiencia Específica
								</Button>
							</Grid>}
						</Grid>
					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={() => setStep(3)}
						>
							Atrás
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90, height: 40 }}
							onClick={() => { formik.handleSubmit() }}
						>
							Siguiente
						</Button>
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
							{alertMsg}
						</Alert>
					</Snackbar>
				</>
			}
		</>
	)
}
