// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dft-messenger__xzMER {\n  height: 30px !important;\n}\n\n.styles_message-list-wrapper__bqLy1 {\n  height: 100px !important;\n}\n\n.styles_dft-message-list__v-yXy {\n  height: 10px !important;\n}", "",{"version":3,"sources":["webpack://./src/app/components/Chatbot/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;AACD;;AACA;EACC,wBAAA;AAED;;AAAA;EACC,uBAAA;AAGD","sourcesContent":[".dft-messenger {\n\theight: 30px !important;\n  }\n.message-list-wrapper {\n\theight: 100px !important;\n}\n.dft-message-list {\n\theight: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dft-messenger": "styles_dft-messenger__xzMER",
	"message-list-wrapper": "styles_message-list-wrapper__bqLy1",
	"dft-message-list": "styles_dft-message-list__v-yXy"
};
export default ___CSS_LOADER_EXPORT___;
