import React, { useState } from 'react';
import { VacancyManagement } from '../modules/auth/components/VacancyManagement';

export const multiStepContext = React.createContext();

const VacancyContext = () => {
	const [currentStep, setStep] = useState(1);
	const [userData, setUserData] = useState([]);
	const [finalData, setFinalData] = useState([]);
	const [data, setData] = useState([]);
	const [loadedData, setLoadedData] =
		useState(false); /*Used to know if the information has been loaded once (edit mode)*/
	const [loading, setLoading] =useState(false);
	const [shouldDisable, setShouldDisable] = useState(false);

	const submitData = () => {
		setFinalData((finalData) => [...finalData, userData]);
		setUserData('');
	};

	return (
		<div>
			<multiStepContext.Provider
				value={{
					currentStep,
					setStep,
					userData,
					setUserData,
					finalData,
					setFinalData,
					submitData,
					data,
					setData,
					loadedData,
					setLoadedData,
					loading,
					setLoading,
					shouldDisable,
					setShouldDisable
				}}
			>
				<VacancyManagement />
			</multiStepContext.Provider>
		</div>
	);
};

export default VacancyContext;
