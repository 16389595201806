import { useState } from 'react';
import { Alert, Snackbar, AlertTitle, Select, MenuItem, Button, Grid, OutlinedInput, Box, Chip } from '@mui/material';
import { useAuth } from '../../modules/auth';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { reportesDetallados } from '../../modules/apps/user-management/users-list/core/_requests';
import DatePicker from 'react-multi-date-picker';
import useStep1Parameters from '../../hooks/parameters/curriculum/useStep1Parameters';






const ReportsPanel = () => {

	const {
		listGrupoEtnico,
		listDiscapacidad,
		listPoblaciones
	} = useStep1Parameters();



	// reportes
	const [selectedKey, setSelectedKey] = useState<string | null>(null);
	// reportes

	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [requestInProgress, setRequestInProgress] = useState(false);

	const [open, setOpen] = useState(false);

	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');



	const handleClose = () => {
		setOpen(false);
	};

	const colombia_es_lowercase: any = {
		name: "gregorian_es_lowercase",
		months: [
			["enero", "ene"],
			["febrero", "feb"],
			["marzo", "mar"],
			["abril", "abr"],
			["mayo", "may"],
			["junio", "jun"],
			["julio", "jul"],
			["agosto", "ago"],
			["septiembre", "sep"],
			["octubre", "oct"],
			["noviembre", "nov"],
			["diciembre", "dic"],
		],
		weekDays: [
			["sábado", "sáb"],
			["domingo", "dom"],
			["lunes", "lun"],
			["martes", "mar"],
			["miércoles", "mié"],
			["jueves", "jue"],
			["viernes", "vie"],
		],
		digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		meridiems: [
			["AM", "am"],
			["PM", "pm"],
		],
	};


	const SeccionDescarga = ({ endpoint, data }: { endpoint: string; data: any }) => (
		<div style={{ width: '100%' }}>
			<div className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
				<h4>¿En cuál formato deseas el documento?</h4>
			</div>

			<div className="d-flex justify-content-around">
				<Button
					variant="contained"
					size="large"
					startIcon={<CloudDownloadIcon />}
					onClick={() => handleDescargarReporteCSV("csv", data, endpoint)}
				>
					Formato .CSV
				</Button>
				<Button
					variant="contained"
					size="large"
					startIcon={<CloudDownloadIcon />}
					onClick={() => handleGetDescargarExcel("xlsx", data, endpoint)}
				>
					Formato .XLSX
				</Button>
			</div>
		</div>
	);

	const handleGetDescargarExcel = async (tipoDoc: any, data: any, endpoint: any) => {
		try {
			const blob = await reportesDetallados(tipoDoc, data, endpoint);
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = `reporte_${endpoint}.xlsx`;
			link.click();
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Iniciando descarga del .XLSX!`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);

		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const handleDescargarReporteCSV = async (tipoDoc: any, data: any, endpoint: any) => {
		// console.log("🔥 endpoint:", endpoint)
		// console.log("🔥 tipoDoc:", tipoDoc)
		// console.log("🔥 data:", data)
		try {
			const response = await reportesDetallados(tipoDoc, data, endpoint);
			const blob = new Blob([response], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `reporte_${endpoint}.${tipoDoc}`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Iniciando descarga del .CSV!`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			console.error('Error capturado:', error);
			if (error.response) {
				console.error('Response completa:', error.response);
				const errorMessage = error.response.data?.error || 'Ocurrió un error inesperado';
				setOpen(true);
				setAlert(false);
				setAlertMsg(errorMessage);
				setTimeout(() => {
					setOpen(false);
				}, 2500);
			} else {
				console.error('Ocurrió un error procesando la solicitud.');
			}
		}
	};





	// Inicio Filtro Candidato
	const [registroFechas, setRegistroFechas] = useState<any>([]);
	const [actualizacionFechas, setActualizacionFechas] = useState<any>([]);
	const [tipoDePoblación, setTipoDePoblación] = useState<string[]>([]);
	const [grupoEtnico, setGrupoEtnico] = useState("");
	const [tipoDeDiscapacidad, setTipoDeDiscapacidad] = useState("");
	const [userData, setUserData] = useState<any>({});

	const handleRegistroFechasChange = (dates: any) => {
		setRegistroFechas(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserData({
				...userData,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY")
			});

		}
	};
	const handleActualizacionFechasChange = (dates: any) => {
		setActualizacionFechas(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserData({
				...userData,
				fecha_cv_inicio: startDate.format("MM-DD-YYYY"),
				fecha_cv_fin: endDate.format("MM-DD-YYYY")
			});
		}
	};

	// Tipo de población
	const handleChangeTipoDePoblacion = (event: any) => {
		setUserData({
			...userData,
			tipo_poblacion: event.target.value
		})
		setTipoDePoblación(event.target.value);
	};
	// Grupo Étnico
	const handleChangeGurpoEtnico = (event: any) => {
		setUserData({
			...userData,
			grupo_etnico: event.target.value
		})
		setGrupoEtnico(event.target.value);
	};
	// Tipo de discapacidad
	const handleChangeTipoDeDiscapacidad = (event: any) => {
		setUserData({
			...userData,
			persona_discapacidad: event.target.value
		})
		setTipoDeDiscapacidad(event.target.value);
	};
	// Limpiar campos
	const handleClearFiltersCandidatos = () => {
		setRegistroFechas([]);
		setActualizacionFechas([]);
		setTipoDePoblación([]);
		setGrupoEtnico('');
		setTipoDeDiscapacidad('');
		setUserData({});
	};


	const Candidatos = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Candidatos</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de registro hoja de vida  */}
				<Grid lg={3} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro hoja de vida
						</label>
						<DatePicker
						rangeHover
							id="registro-date-picker"
							value={registroFechas}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='MM/DD/YY'
							onChange={handleRegistroFechasChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Última fecha de actualización hoja de vida */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Última fecha de actualización hoja de vida
						</label>
						<DatePicker
						rangeHover
							id="actualizacion-date-picker"
							value={actualizacionFechas}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='MM/DD/YY'
							onChange={handleActualizacionFechasChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Tipo de población */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de población
					</label>
					<Select
						fullWidth
						labelId="demo-multiple-chip-label"
						id="demo-multiple-chip"
						multiple
						value={tipoDePoblación}
						onChange={handleChangeTipoDePoblacion}
						input={<OutlinedInput id="select-multiple-chip" />}
						renderValue={(selected) => (
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
								{selected.map((code) => {
									const selectedItem = listPoblaciones.find((item) => item.code === code);
									return <Chip key={code} label={selectedItem ? selectedItem.label : code} />;
								})}

							</Box>
						)}
					>
						{listPoblaciones.map((item) => (
							<MenuItem key={item.id} value={item.code} >
								{item.label}
							</MenuItem>
						))}
					</Select>


				</Grid>
				{/* Grupo étnico */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Grupo Étnico
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={grupoEtnico}
						onChange={handleChangeGurpoEtnico}
					>
						{listGrupoEtnico.map((item) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{/* Tipo de discapacidad */}
				<Grid lg={4} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Tipo de discapacidad
					</label>
					<Select
						fullWidth
						labelId="grupo-etnico-label"
						id="grupo-etnico-select"
						value={tipoDeDiscapacidad}
						onChange={handleChangeTipoDeDiscapacidad}
					>
						{listDiscapacidad.map((item) => (
							<MenuItem key={item.id} value={item.code}>
								{item.value}
							</MenuItem>
						))}
					</Select>
					{/* <Select
					fullWidth
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={tipoDeDiscapacidad}
						onChange={handleChangeTipoDeDiscapacidad}
					>
						<MenuItem value={0}>No</MenuItem>
						<MenuItem value={1}>Si</MenuItem>
						
					</Select> */}
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersCandidatos} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userData} endpoint="candidatos" />

		</div>
	);
	// Fin Filtro Candidato


	// Inicio Filtro Empresa
	const [registroEmpresa, setRegistroEmpresa] = useState<any>([]);
	const [aprobacionEmpresa, setAprobacionEmpresa] = useState<any>([]);
	const [creacionDeVacantes, setCreacionDeVacantes] = useState<any>([]);
	const [aprovacionVacantes, setAprovacionVacantes] = useState<any>([]);
	const [userEmpresa, setUserEmpresa] = useState<any>({});


	const handleRegistroEmpresaChange = (dates: any) => {
		setRegistroEmpresa(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY"),
			});

		}
	};

	const handleAprobacionEmpresaChange = (dates: any) => {
		setAprobacionEmpresa(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_aprobacion_inicio: startDate.format("DD-MM-YYYY"),
				fecha_aprobacion_fin: endDate.format("DD-MM-YYYY")
			});
		}
	};

	const handleCreacionDeVacantesChange = (dates: any) => {
		setCreacionDeVacantes(dates);
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserEmpresa({
				...userEmpresa,
				fecha_vagas_inicio: startDate.format("MM-DD-YYYY"),
				fecha_vagas_fin: endDate.format("MM-DD-YYYY")
			});

		}
	};

	const handleAprobacionDeVacantesChange = (dates: any) => {
		setAprovacionVacantes(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			// setUserEmpresa({
			// 	...userEmpresa,
			// 	fecha_de_aprobacion_vacantes_inicio: startDate.format("DD-MM-YYYY"),
			// 	fecha_de_aprobacion_vacantes_fin: endDate.format("DD-MM-YYYY")
			// });
		}
	};


	// Limpiar campos
	const handleClearFiltersEmpresas = () => {
		setRegistroEmpresa([]);
		setAprobacionEmpresa([]);
		setCreacionDeVacantes([]);
		setAprovacionVacantes([]);
		setUserEmpresa({});
	};

	const Empresas = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Empresas</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de registro empresa */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de registro empresa
						</label>
						<DatePicker
						rangeHover
							id="registro-date-picker"
							value={registroEmpresa}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleRegistroEmpresaChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de aprobación de empresa  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de aprobación de empresa
						</label>
						<DatePicker
						rangeHover
							id="actualizacion-date-picker"
							value={aprobacionEmpresa}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleAprobacionEmpresaChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de creación vacantes  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Fecha de creación vacantes
					</label>
					<DatePicker
					rangeHover
						id="actualizacion-date-picker"
						value={creacionDeVacantes}
						numberOfMonths={2}
						range={true}
						locale={colombia_es_lowercase}
						title='Rango de fechas'
						name='creacionDeVacantes'
						placeholder='Rango de fechas'
						dateSeparator=' - '
						format='DD/MM/YY'
						onChange={handleCreacionDeVacantesChange}
						style={{ padding: 20, fontSize: 14, height: 50 }}
					/>


				</Grid>
				{/* Fecha de aprobación vacantes  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
						Fecha de aprobación vacantes
					</label>
					<DatePicker
					rangeHover
						id="actualizacion-date-picker"
						value={aprovacionVacantes}
						numberOfMonths={2}
						range={true}
						locale={colombia_es_lowercase}
						title='Rango de fechas'
						name='actualizacionFechas'
						placeholder='Rango de fechas'
						dateSeparator=' - '
						format='DD/MM/YY'
						onChange={handleAprobacionDeVacantesChange}
						style={{ padding: 20, fontSize: 14, height: 50 }}
					/>
				</Grid>

			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersEmpresas} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userEmpresa} endpoint='empresas' />
		</div>
	);
	// Fin Filtro Empresa




	// Inicio Filtro Vacantes
	const [reporteCreacionDeVacantes, setReporteCreacionDeVacantes] = useState<any>([]);
	const [reporteAprovacionVacantes, setReporteAprovacionVacantes] = useState<any>([]);
	const [userReporteVacantes, setUserReporteVacantes] = useState<any>({});

	const handleReporteCreacionDeVacantesChange = (dates: any) => {
		setReporteCreacionDeVacantes(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserReporteVacantes({
				...userReporteVacantes,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY"),
			});

		}
	};

	const handleReporteAprobacionDeVacantesChange = (dates: any) => {
		setReporteAprovacionVacantes(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			// setUserReporteVacantes({
			// 	...userReporteVacantes,
			// 	fecha_reporte_de_aprobacion_vacantes_inicio: startDate.format("DD-MM-YYYY"),
			// 	fecha_reporte_de_aprobacion_vacantes_fin: endDate.format("DD-MM-YYYY")
			// });
		}
	};

	// Limpiar campos
	const handleClearFiltersVacantes = () => {
		setReporteCreacionDeVacantes([]);
		setReporteAprovacionVacantes([]);
		setUserReporteVacantes({});
	};

	const Vacantes = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Vacantes</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de creación vacantes  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de creación vacantes
						</label>
						<DatePicker
							id="registro-date-picker2"
							value={reporteCreacionDeVacantes}
							numberOfMonths={2}
							rangeHover
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa2'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleReporteCreacionDeVacantesChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
							hideOnScroll={false} 
						/>
						
					</div>
				</Grid>
				{/* Fecha de aprobación vacantes   */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de aprobación vacantes
						</label>
						<DatePicker
							id="actualizacion-date-picker"
							value={reporteAprovacionVacantes}
							numberOfMonths={2}
							rangeHover
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleReporteAprobacionDeVacantesChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>


			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersVacantes} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userReporteVacantes} endpoint='vacantes' />
		</div>
	);
	// Fin Filtro Vacantes

	// Inicio Filtro Ocupaciones

	const [reporteCreacionDeOcupaciones, setReporteCreacionDeOcupaciones] = useState<any>([]);
	const [reporteAprovacionDeOcupaciones, setReporteAprovacionDeOcupaciones] = useState<any>([]);
	const [userReporteOcupaciones, setUserReporteOcupaciones] = useState<any>({});

	const handleReporteCreacionDeOcupacionesChange = (dates: any) => {
		setReporteCreacionDeOcupaciones(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			setUserReporteOcupaciones({
				...userReporteOcupaciones,
				fecha_inicio: startDate.format("MM-DD-YYYY"),
				fecha_fin: endDate.format("MM-DD-YYYY"),
			});

		}
	};

	const handleReporteAprobacionDeOcupacionesChange = (dates: any) => {
		setReporteAprovacionDeOcupaciones(dates);

		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			// setUserReporteOcupaciones({
			// 	...userReporteOcupaciones,
			// 	fecha_reporte_de_aprobacion_ocupaciones_inicio: startDate.format("DD-MM-YYYY"),
			// 	fecha_reporte_de_aprobacion_ocupaciones_fin: endDate.format("DD-MM-YYYY")
			// });
		}
	};

	// Limpiar campos
	const handleClearFiltersOcupaciones = () => {
		// console.log(userReporteOcupaciones)
		setReporteCreacionDeOcupaciones([]);
		setReporteAprovacionDeOcupaciones([]);
		setUserReporteOcupaciones({});
	};

	const Ocupaciones = () => (
		<div className="d-flex flex-column align-items-center " style={{ width: '100%', height: '100%' }}>
			<div style={{ background: '#4683DE', marginBottom: 20, width: '100%', padding: 10 }}>
				<h4 style={{ color: '#fff', textAlign: 'center' }}>Filtros Reporte Ocupaciones</h4>
			</div>
			<Grid container direction='row' width={'100%'} >
				{/* Fecha de creación Ocupaciones  */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column'>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de creación vacantes
						</label>
						<DatePicker
						rangeHover
							id="registro-date-picker"
							value={reporteCreacionDeOcupaciones}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='registroEmpresa'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleReporteCreacionDeOcupacionesChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
				{/* Fecha de aprobación Ocupaciones   */}
				<Grid lg={5} xs={3} md={6} mb={5} pr={2} item={true}>
					<div className='d-flex flex-column '>
						<label htmlFor="date-range-picker" style={{ marginBottom: '8px', fontWeight: 500 }}>
							Fecha de aprobación ocupaciones
						</label>
						<DatePicker
						rangeHover
							id="actualizacion-date-picker"
							value={reporteAprovacionDeOcupaciones}
							numberOfMonths={2}
							range={true}
							locale={colombia_es_lowercase}
							title='Rango de fechas'
							name='actualizacionFechas'
							placeholder='Rango de fechas'
							dateSeparator=' - '
							format='DD/MM/YY'
							onChange={handleReporteAprobacionDeOcupacionesChange}
							style={{ padding: 20, fontSize: 14, height: 50 }}
						/>
					</div>
				</Grid>
			</Grid>
			{/* Botón para limpiar filtros */}
			<button onClick={handleClearFiltersOcupaciones} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: 30 }}>
				Limpiar Filtros
			</button>
			<SeccionDescarga data={userReporteOcupaciones} endpoint='ocupaciones' />
		</div>
	);

	// Fin Filtro Ocupaciones

	// Mapeo de los botones a sus respectivos componentes
	const components = {
		Candidatos: <Candidatos />,
		Empresas: <Empresas />,
		Vacantes: <Vacantes />,
		Ocupaciones: <Ocupaciones />,
	};


	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-xs-12 col-sm-10 col-md-10'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>

						</div>
					</div>

				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className='d-flex ' style={{}}>
					<div className='col-lg-3 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px', background: '#B0CFF7' }}>
						<div style={{ background: '#4683DE', padding: 15, marginBottom: 20 }}>
							<h5 style={{ color: '#fff', textAlign: 'center' }}>Tipo de Reporte</h5>
						</div>
						<div className="d-flex flex-column">
							{Object.keys(components).map((key) => (
								<button
									key={key}
									className="btn my-1"
									style={{
										backgroundColor: selectedKey === key ? "#4683DE" : "white",
										color: selectedKey === key ? "white" : "black",

									}}
									onClick={() => setSelectedKey(key)}
								>
									{key}
								</button>
							))}
						</div>
					</div>
					<div className='col-lg-9 col-xs-12 col-sm-6 col-md-4 d-flex justify-content-center align-items-center' style={{ padding: '10px' }}>

						{selectedKey ? components[selectedKey as keyof typeof components] : (
							<h4>SECCIÓN DE CONFIGURACIÓN DE REPORTE Y DESCARGA</h4>
						)}

					</div>
				</div>



			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

export { ReportsPanel };
