import { FormEvent, useContext, useState, useEffect, useRef, useCallback } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	Button,
	Box,
	Divider,
	FormHelperText,
	Alert,
	Snackbar,
	AlertTitle,
} from '@mui/material';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { multiStepContext } from '../../../../../contexts/CompanyEditContext';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
	initValue,
	setValueOrDefault,
	formatMaskPhoneNumber12Digits,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	eventOnKeyDownInputNumber,
	eventOnKeyDownInputAlphaNumeric,
	isValidAddress,
	isValidFieldRequired,
	helperIsValidNumber,
	isValidNumber
} from '../../../../../util/Index';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useAddressParams from '../../../../../hooks/parameters/useAddressParams';
import parameterSingleton from '../../../../../services/Parameters.service';
import { ParameterConfigMmodel } from '../../../../../models/parameter.model';

export const Step2 = () => {
	const {
		listPuntoAtencion,
		listPrestadorPreferencia,
		paisList,
		departamentoList,
		zonaList,
		viaPrincipalBisList,
		listViaPrincipalCuadrante,
		listViaPrincipalEmpresa,
		listViaPrincipalComplementoDireccion,
		listViaPrincipalPrimeraLetra,
		listLocalidades,
		loadingParams } = useAddressParams();

	const { setStep, firstCompany, setFirstCompany } = useContext(multiStepContext);

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	//DIRECCION
	const [dispComple, setDispComple] = useState('none');
	const [rowsComplementos, setRowsComplementos] = useState([{ id: 0, descId: '' }]);
	const [ddlMunicipioResidencia, setDdlMunicipioResidencia] = useState<ParameterConfigMmodel[]>([
		{ id: 0, type: '', code: '0', value: 'Seleccione' },
	]);

	const handleClose = () => {
		setOpen(false);
	};
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const fetchAndSetMunicipioById = async (id: string) => {
		const selectedMunicipio =
			await parameterSingleton.fetchMunicipioById(id);
		setDdlMunicipioResidencia(selectedMunicipio);
	}

	useEffect(() => {
		const listRowsComplementos = rowsComplementos.find((obj) => {
			return obj.id === 0;
		});

		if (listRowsComplementos) {
			const list = [...rowsComplementos];
			list.splice(_.findIndex(list, { id: 0 }), 1);
			setRowsComplementos(list);
		}

		//Procesa y precarga la informacion del pais. Si no hay informacion del pais establece Colombia por defecto
		if (!firstCompany.company['pais']) {
			const idPais = paisList.find(p => p.code === 'CO')?.code
			setFirstCompany({
				...firstCompany,
				company: { ...firstCompany.company, pais: idPais },
			})
		}

		//preenche listagem de municipio (cidade) de acordo com o id_departamento
		if (firstCompany.company['departamento']) {
			fetchAndSetMunicipioById(firstCompany.company['departamento'])
		} else {
			setDdlMunicipioResidencia([]);
		}

	}, []);

	const handleAddComplemento = () => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => ({
			...prevFirstCompany,
			complemento: [
				...prevFirstCompany.complemento,
				{ tipo_complemento: '', complemento: '' }, // Adicione um novo objeto vazio ao array de complemento
			],
		}));
	};

	const strDirection = (val: any) => {
		return !isValidFieldRequired(val) ? '' : val;
	};

	const handleDelComplemento = (index: any) => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
			const updatedComplemento = prevFirstCompany.complemento.filter((item, idx) => idx !== index);
			return {
				...prevFirstCompany,
				complemento: updatedComplemento,
			};
		});
	};

	const handleSelect = (e: SelectChangeEvent) => {
		if (e.target.value !== '') {
			fetchAndSetMunicipioById(e.target.value);
		} else {
			setDdlMunicipioResidencia([]);
		}
	};

	const handleDelete = (id: number) => {
		const list = [...rowsComplementos];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsComplementos(list);
		if (list.length === 0) {
			setDispComple('none');
		}
	};
	const handleChange = () => {
		setRowsComplementos([
			...rowsComplementos,
			{
				id: rowsComplementos.length + 1,
				descId: 'tipo_complemento' + (rowsComplementos.length + 1),
			},
		]);
		setDispComple('block');
	};

	const handleDirectionValue = () => {
		let val =
			strDirection(firstCompany.company['local']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_02']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_03']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_04']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_05']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_06']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_07']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_08']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_09']) +
			' ';

		if (
			firstCompany['complemento'] !== null &&
			firstCompany['complemento'] !== undefined &&
			(firstCompany['complemento'] as Array<any>).length > 0
		) {

			if (firstCompany['complemento'] && firstCompany['complemento'].length > 0 && isValidFieldRequired(firstCompany['complemento'][0].tipo_complemento)) {
				val = val + ', ';
			}

			(firstCompany['complemento'] as Array<any>).forEach((e) => {
				let x =
					strDirection(
						setValueOrDefault(
							listViaPrincipalComplementoDireccion.find((y) => y.code === e['tipo_complemento'])?.value,
							'string'
						)
					) +
					' ' +
					strDirection(setValueOrDefault(e['complemento'], 'string')) +
					' ';
				val = val + x;
			});
		}
		const direccionResidencia = val.trimEnd() === '' ? '' : val.trimEnd() + '';
		if (isValidFieldRequired(firstCompany.company['direccion_residencia']) && !isValidFieldRequired(direccionResidencia)) {
			return firstCompany.company['direccion_residencia'];
		} else if (direccionResidencia !== firstCompany.company['direccion_residencia']) {
			setFirstCompany({
				...firstCompany,
				company: { ...firstCompany.company, direccion_residencia: direccionResidencia },
			})
			return direccionResidencia;
		} else if (direccionResidencia === firstCompany.company['direccion_residencia']) {
			return direccionResidencia;
		} else {
			return '';
		}
	};

	async function handleSubmit(event: FormEvent) {
		if (handleValidOnSubmit()) {
			firstCompany.company.telefono = firstCompany.company?.telefono
				?.replace(/[^\d]/g, '')
				?.replaceAll('-', '')
				?.replaceAll('+', '');
			setStep(3);
		}

	}

	const isBogotaDepartment = (firstCompany: any): boolean => {
		return firstCompany.company['departamento'] === '11';
	}

	const longitudmaximaDireccion = 500;
	const mensajeErrorLongitudMaximaDireccion = `La dirección no puede ser superior a ${longitudmaximaDireccion} caracteres`;

	const handleValidOnSubmit = (): boolean => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];

		if (!isValidFieldRequired(firstCompany.company['pertence'])) {
			camposError.push('pertence');
			errors.push({ field: 'Pertenece a zona', error: 'Campo requerido' });
		}

		if (!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', ''))) {
			camposError.push('direccion_residencia');
			errors.push({ field: 'Dirección sede', error: 'Campo requerido' });
		}

		if(firstCompany.company['direccion_residencia']?.length > longitudmaximaDireccion){
			errors.push({ field: 'Dirección sede', error: mensajeErrorLongitudMaximaDireccion });
			camposError.push('direccion_residencia');
		}

		//Revision de complementos de direccion
		firstCompany.complemento?.forEach((element: any, index: any) => {
			if (isValidFieldRequired(element?.tipo_complemento) && !isValidFieldRequired(element?.complemento)) {
				errors.push({ field: `Complemento [${index}]`, error: 'Campo requerido' });
			}
		});

		if (!isValidFieldRequired(firstCompany.company['nombre_sede'])) {
			camposError.push('nombre_sede');
			errors.push({ field: 'Nombre sede', error: 'Campo requerido' });
		}

		if (!isValidColombianPhoneNumber(firstCompany.company['telefono'], true)) {
			camposError.push('telefono');
			errors.push({ field: 'Teléfono', error: helperIsValidColombianPhoneNumber(firstCompany.company['telefono'], true) });
		}

		if (!isValidNumber(firstCompany.company['extension_telefono'], 1, 5, false)) {
			camposError.push('extension_telefono');
			errors.push({ field: 'Extensión del teléfono', error: helperIsValidNumber(firstCompany.company['extension_telefono'], 1, 5, false) });
		}

		if (!isValidFieldRequired(firstCompany.company['departamento'])) {
			camposError.push('departamento');
			errors.push({ field: 'Departamento', error: 'Campo requerido' });
		}

		if (!isValidFieldRequired(firstCompany.company['municipio'])) {
			camposError.push('municipio');
			errors.push({ field: 'Municipio', error: 'Campo requerido' });
		}

		if (isBogotaDepartment(firstCompany)) {
			if (!isValidFieldRequired(firstCompany.company['localidad'])) {
				camposError.push('localidad');
				errors.push({ field: 'Localidad', error: 'Campo requerido' });
			}

			if (!isValidFieldRequired(firstCompany.company['prestador_principal'])) {
				camposError.push('prestador_principal');
				errors.push({ field: 'Prestador principal', error: 'Campo requerido' });
			}

			if (!isValidFieldRequired(firstCompany.company['punta_atencion'])) {
				camposError.push('punta_atencion');
				errors.push({ field: 'Punto de atención', error: 'Campo requerido' });
			}
		}

		setTouchedFields(camposError);

		setFirstCompany({
			...firstCompany,
			company: {
				...firstCompany.company,
				latitud: setValueOrDefault(listLocalidades.find((l: any) => l.id_localidad === firstCompany.company['localidad'])?.latitud, 'number'),
				longitud: setValueOrDefault(listLocalidades.find((l: any) => l.id_localidad === firstCompany.company['localidad'])?.longitud, 'number')
			},
		})

		if (!isBogotaDepartment(firstCompany)) {
			setFirstCompany({
				...firstCompany, company: {
					...firstCompany.company,
					prestador_principal: '',
					punta_atencion: '',
					localidad: '',
					latitud: 0,
					longitud: 0
				}
			})
		}

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}

		return true;
	};

	/**
		 * Event onChange input Direccion de residencia
		 * @param value 
		 */
	const onChangeDireccionResidencia = (value: string) => {
		if (value) {
			setFirstCompany({
				...firstCompany,
				company: { ...firstCompany.company, direccion_residencia: value },
			})
		}
	}

	const handleChangeTipoComplemento = (e: any, index: any) => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
			const updatedComplemento = prevFirstCompany.complemento.map(
				(item, idx) => {
					if (idx === index) {
						return {
							...item,
							tipo_complemento: e.target.value,
						};
					} else {
						return item;
					}
				}
			);
			return {
				...prevFirstCompany,
				complemento: updatedComplemento,
			};
		})
	};

	const handleChangeComplemento = (e: any, index: any) => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
			const updatedComplemento = prevFirstCompany.complemento.map(
				(item, idx) => {
					if (idx === index) {
						return {
							...item,
							complemento: e.target.value,
						};
					} else {
						return item;
					}
				}
			);
			return {
				...prevFirstCompany,
				complemento: updatedComplemento,
			};
		})
	};

	return (
		<>{loadingParams
			? <LinearLoadingSpinner isLoading={loadingParams} />
			: <>
				<LoadingBackdrop loading={requestInProgress} />
				<Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
					Datos de la compañía
				</Typography>
				<Grid container direction='row' width={'100%'} item={true}>
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('nombre_sede') && !isValidFieldRequired(firstCompany.company['nombre_sede'])}
						>
							<TextField
								fullWidth
								id='nombre_sede'
								name='nombre_sede'
								label='Nombre de la sede'
								required
								error={touchedFields.includes('nombre_sede') && !isValidFieldRequired(firstCompany.company['nombre_sede'])}
								helperText={touchedFields.includes('nombre_sede') && !isValidFieldRequired(firstCompany.company['nombre_sede']) ? 'Campo requerido' : ''}
								placeholder='Ingrese el nombre'
								value={initValue(firstCompany.company['nombre_sede'])}
								onChange={(e) => {
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, nombre_sede: e.target.value }
									})
								}
								}
								onBlur={() => handleBlur('nombre_sede')}
								size='medium'
								inputProps={{ maxLength: 300 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('pertenece') && !isValidFieldRequired(firstCompany.company['pertence'])}
						>
							<InputLabel required id='pertence'>
								Pertenece a zona:
							</InputLabel>
							<Select
								required
								labelId='pertence'
								id='pertence'
								label='Pertenece a zona:'
								value={firstCompany.company['pertence']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, pertence: e.target.value },
									})
								}
								onBlur={() => handleBlur('pertenece')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{zonaList.map(({ code, value }, i) => (
									<MenuItem key={i} value={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('pertenece') && !isValidFieldRequired(firstCompany.company['pertence']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['pertence']) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
						<Box sx={{ mb: 3, width: '100%' }}>
							<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
								Dirección
							</Typography>
						</Box>
					</Grid>
					<Grid container direction={'row'} justifyContent='space-between' width={'100%'} item={true}>
						<Grid xs={12} md={12} mb={2} pr={2} item={true}>
							<Grid container spacing={2} pr={2}>
								<Grid xs={2} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										{/* <InputLabel id='local'>Sección Principal</InputLabel> */}
										<Select
											labelId='local'
											id='local'
											// label='Sección Principal'
											value={firstCompany.company['local']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, local: e.target.value },
												})
											}
											inputProps={{ 'aria-label': 'Without label' }}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalEmpresa.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={1} mb={5} item={true}>
									<TextField
										sx={{ minWidth: '100%' }}
										fullWidth
										required
										id='id_coordenada'
										name='id_coordenada'
										label=''
										value={firstCompany.company['id_coordenada']}
										onKeyDown={eventOnKeyDownInputAlphaNumeric}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, id_coordenada: e.target.value },
											})
										}
										size='medium'
										inputProps={{
											maxLength: 20,
											minLength: 1,
										}}
									/>
								</Grid>
								<Grid xs={1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_02'></InputLabel>
										<Select
											labelId='id_coordenada_02'
											id='id_coordenada_02'
											label=''
											value={firstCompany.company['id_coordenada_02']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_02: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={1.1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_03'></InputLabel>
										<Select
											labelId='id_coordenada_03'
											id='id_coordenada_03'
											label=''
											value={firstCompany.company['id_coordenada_03']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_03: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{viaPrincipalBisList.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={1.1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_04'></InputLabel>
										<Select
											labelId='id_coordenada_04'
											id='id_coordenada_04'
											label=''
											value={firstCompany.company['id_coordenada_04']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_04: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid xs={1.1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_05'></InputLabel>
										<Select
											labelId='id_coordenada_05'
											id='id_coordenada_05'
											label=''
											value={firstCompany.company['id_coordenada_05']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_05: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalCuadrante.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item container xs={0.2} mb={5} justifyContent="center" alignItems="center">
									<span
										style={{
											display: 'flex',
											justifyContent: 'center',
											fontWeight: 'bold',
										}}
									>#</span>
								</Grid>
								<Grid xs={1} mb={5} item={true}>
									<TextField
										fullWidth
										required
										id='id_coordenada_06'
										name='id_coordenada_06'
										label=''
										value={firstCompany.company['id_coordenada_06']}
										onKeyDown={(e: any) => {
											eventOnKeyDownInputNumber(e);
										}}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, id_coordenada_06: e.target.value.slice(0, 4) },
											})
										}
										size='medium'
										type='number'
										InputProps={{ inputProps: { min: 0, maxLength: 4 } }}
									/>
								</Grid>
								<Grid xs={1.1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_07'></InputLabel>
										<Select
											labelId='id_coordenada_07'
											id='id_coordenada_07'
											label=''
											value={firstCompany.company['id_coordenada_07']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_07: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalPrimeraLetra.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item container xs={0.2} mb={5} justifyContent="center" alignItems="center">
									<span
										style={{
											display: 'flex',
											justifyContent: 'center',
											fontWeight: 'bold',
										}}
									>-</span>
								</Grid>
								<Grid xs={1} mb={5} item={true}>
									<TextField
										fullWidth
										required
										id='id_coordenada_08'
										name='id_coordenada_08'
										label=''
										value={firstCompany.company['id_coordenada_08']}
										onKeyDown={(e: any) => {
											eventOnKeyDownInputNumber(e);
										}}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, id_coordenada_08: e.target.value.slice(0, 4) },
											})
										}
										size='medium'
										type='number'
										InputProps={{ inputProps: { min: 0, maxLength: 4 } }}
									/>
								</Grid>
								<Grid xs={1} mb={5} item={true}>
									<FormControl sx={{ minWidth: '100%' }} size='medium'>
										<InputLabel id='id_coordenada_09'></InputLabel>
										<Select
											labelId='id_coordenada_09'
											id='id_coordenada_09'
											label=''
											value={firstCompany.company['id_coordenada_09']}
											onChange={(e) =>
												setFirstCompany({
													...firstCompany,
													company: { ...firstCompany.company, id_coordenada_09: e.target.value },
												})
											}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listViaPrincipalCuadrante.map(({ code, value }, i) => (
												<MenuItem key={i} value={code}>
													{value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12} pr={2} item={true}>
						{firstCompany.complemento.map(
							(row: any, key: any) =>
								row.tipo_complemento !== '' && (
									<Grid container key={key}>
										<Grid container direction='row' item={true} justifyContent="space-between">
											<Grid xs={12} md={4} mb={5} pr={2} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='medium'>
													<InputLabel id={'tipo_complemento' + key}>Tipo de complemento</InputLabel>
													<Select
														labelId={'tipo_complemento' + key}
														id={'tipo_complemento' + key}
														label='Tipo de complemento'
														value={firstCompany.complemento[key]['tipo_complemento']}
														onChange={(e) => handleChangeTipoComplemento(e, key)}
													>
														<MenuItem value='' key=''>
															Seleccione...
														</MenuItem>
														{listViaPrincipalComplementoDireccion.map(({ code, value }) => (
															<MenuItem key={code} value={code}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid xs={12} md={5} mb={5} pr={2} item={true}>
												<FormControl sx={{ minWidth: '100%' }} size='small'>
													<TextField
														required={isValidFieldRequired(firstCompany.complemento[key]['complemento'])}
														fullWidth
														id={'complemento' + key}
														name='complemento'
														label='Complemento'
														value={firstCompany.complemento[key]['complemento']}
														onChange={(e) => handleChangeComplemento(e, key)}
														size='medium'
														inputProps={{ maxLength: 100 }}
														error={isValidFieldRequired(firstCompany.complemento[key]?.tipo_complemento) && !isValidFieldRequired(firstCompany.complemento[key]?.complemento)}
														helperText={isValidFieldRequired(firstCompany.complemento[key]?.tipo_complemento) && !isValidFieldRequired(firstCompany.complemento[key]?.complemento) ? 'Campo requerido' : ''}
													/>
												</FormControl>
											</Grid>
											{key === 0 ? (
												<Grid xs={12} md={3} mb={5} pr={2} item={true}>
													<Button
														fullWidth
														variant='contained'
														onClick={handleAddComplemento}
														style={{ width: '400px', height: '50px' }}
													>
														Nuevo complemento +
													</Button>
												</Grid>
											) : (
												<Grid xs={12} md={3} mb={5} pr={2} item={true}>
													<Button
														fullWidth
														variant='contained'
														onClick={() => handleDelComplemento(key)}
														// sx={{ minWidth: '100%' }}
														style={{ width: '100%', height: '50px' }}
													>
														Excluir complemento -
													</Button>
												</Grid>
											)}
										</Grid>
									</Grid>
								)
						)}
					</Grid>
					<Grid xs={12} md={3} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								id='codigo_postal'
								name='codigo_postal'
								label='Código postal'
								value={firstCompany.company['codigo_postal']}
								onKeyDown={(e: any) => {
									eventOnKeyDownInputNumber(e);
								}}
								onChange={(e) => {
									const value = e.target.value;
									if (value.length <= 6) {
										if (value.length === 1 && value[0] === '0') {
											// Evita que el primer carácter sea 0
											return;
										}
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, codigo_postal: value },
										});
									}
								}}
								type='number'
								size='medium'
								inputProps={{ min: 1, maxLength: 6 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={9} mb={5} pr={2} item={true}>
						<FormControl 
							sx={{ minWidth: '100%' }} 
							size='medium'
							error={
								touchedFields.includes('direccion_residencia') 
								&& (
									!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', '')) ||
									!isValidAddress(handleDirectionValue()) ||
									firstCompany.company['direccion_residencia']?.length > longitudmaximaDireccion
								)
							}
						>
							<TextField
								fullWidth
								required
								id='direccion_residencia'
								name='direccion_residencia'
								label='Dirección sede'
								value={handleDirectionValue()}
								onChange={(e) => onChangeDireccionResidencia(e.target.value)}
								onBlur={() => handleBlur('direccion_residencia')}
								size='medium'
								error={
									touchedFields.includes('direccion_residencia') && (
										!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', '')) ||
										!isValidAddress(handleDirectionValue()) ||
										firstCompany.company['direccion_residencia']?.length > longitudmaximaDireccion
									)
								}
								helperText={
									(touchedFields.includes('direccion_residencia') && !isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', ''))) ? 'Campo requerido' :
										(!isValidAddress(handleDirectionValue())) ? 'Debe seleccinar un tipo de via e ingresar almenos un número en la dirección' : 
											(touchedFields.includes('direccion_residencia') && (firstCompany.company['direccion_residencia']?.length > longitudmaximaDireccion)) ? mensajeErrorLongitudMaximaDireccion : ''
								}
								inputProps={{ maxLength: 500 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
						<OverlayTrigger
							placement='left'
							delay={{ show: 250, hide: 400 }}
							overlay={(props) => (
								<Tooltip id='button-tooltip' {...props}>
									{/* (Código de país + Código de área + Número de teléfono) */}
								</Tooltip>
							)}
						>
							<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(firstCompany.company['telefono'], true)}>
								<TextField
									fullWidth
									required
									id='telefono'
									name='telefono'
									label='Teléfono'
									placeholder='Ingrese teléfono'
									value={firstCompany.company['telefono']}
									onChange={(e) => {
										let val = e.target.value
											?.replaceAll(/[^\d]/g, '')
											?.replaceAll('-', '')
											?.replaceAll('+', '');
										let reg = /[0-9]|\./;
										if (reg.test(val) || val === '') {
											setFirstCompany({
												...firstCompany,
												company: {
													...firstCompany.company,
													telefono: formatMaskPhoneNumber12Digits(val),
												},
											});
										}
									}}
									onBlur={() => handleBlur('telefono')}
									size='medium'
									error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(firstCompany.company['telefono'], true)}
									helperText={touchedFields.includes('telefono') ? helperIsValidColombianPhoneNumber(firstCompany.company['telefono'], true) : ''}
									InputProps={{
										/*endAdornment: (
											<InputAdornment position='end'>
												<i
													className='bi bi-question-circle-fill'
													style={{ color: '#2266cc', marginLeft: '5px' }}
												></i>
											</InputAdornment>
										),*/
									}}
									inputProps={{
										maxLength: 10,
									}}
								/>
							</FormControl>
						</OverlayTrigger>
					</Grid>
					<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium' error={touchedFields.includes('extension_telefono') && !isValidNumber(firstCompany.company['extension_telefono'], 1, 5, false)}>
							<TextField
								fullWidth
								id='extension_telefono'
								name='extension_telefono'
								label='Extensión del teléfono'
								placeholder='Ingrese la extensión del télefono'
								value={initValue(firstCompany.company['extension_telefono'])}
								onChange={(e) => {
									const val = e.target.value;
									if (isValidNumber(val, 1, 5, false)) {
										setFirstCompany({
											...firstCompany,
											company: {
												...firstCompany.company,
												extension_telefono: val,
											}
										});
									}
								}}
								onBlur={() => handleBlur('extension_telefono')}
								size='medium'
								error={touchedFields.includes('extension_telefono') && !isValidNumber(firstCompany.company['extension_telefono'], 1, 5, false)}
								helperText={touchedFields.includes('extension_telefono') ? helperIsValidNumber(firstCompany.company['extension_telefono'], 1, 5, false) : ''}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel required id='pais'>
								País
							</InputLabel>
							<Select
								readOnly
								labelId='pais'
								id='pais'
								label='País'
								value={firstCompany.company['pais'] ? firstCompany.company['pais'] : 'CO'}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, pais: e.target.value },
									})
								}
							>
								<MenuItem key={0} value='CO'>
									Colombia
								</MenuItem>
								{/* <MenuItem value=''>Seleccione...</MenuItem>
								{paisList.map(({ code, value }, i) => (
									<MenuItem key={i} value={code}>
										{value}
									</MenuItem>
								))} */}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('departamento') && !isValidFieldRequired(firstCompany.company['departamento'])}
						>
							<InputLabel required id='departamento'>
								Departamento
							</InputLabel>
							<Select
								required
								labelId='departamento'
								id='departamento'
								label='Departamento'
								value={firstCompany.company['departamento']}
								onChange={(e) => {
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, departamento: e.target.value, municipio: '', localidad: '', latitud: 0, longitud: 0 },
									});
									handleSelect(e);
								}}
								onBlur={() => handleBlur('departamento')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{departamentoList.map(({ code, value }, i) => (
									<MenuItem key={i} value={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('departamento') && !isValidFieldRequired(firstCompany.company['departamento']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['departamento'])
										? 'Campo requerido'
										: ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('municipio') && !isValidFieldRequired(firstCompany.company['municipio'])}
						>
							<InputLabel required id='municipio'>
								Municipio
							</InputLabel>
							<Select
								required
								labelId='municipio'
								id='municipio'
								label='Municipio'
								value={firstCompany.company['municipio']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, municipio: e.target.value },
									})
								}
								onBlur={() => handleBlur('municipio')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{ddlMunicipioResidencia.map(({ code, value }, i) => (
									<MenuItem key={i} value={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('municipio') && !isValidFieldRequired(firstCompany.company['municipio']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['municipio'])
										? 'Campo requerido'
										: ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{firstCompany.company['pais'] === 'CO' && isBogotaDepartment(firstCompany) && (
						<>
							<Grid xs={12} sm={6} md={4} mb={5} pr={2} item={true}>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('localidad') && !isValidFieldRequired(firstCompany.company['localidad'])}
								>
									<InputLabel required id='localidad'>
										Localidad
									</InputLabel>
									<Select
										required
										labelId='localidad'
										id='localidad'
										label='Localidad'
										value={initValue(firstCompany.company['localidad'])}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, localidad: e.target.value },
											})
										}
										onBlur={() => handleBlur('localidad')}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listLocalidades.map(({ id_localidad, desc_localidad }) => (
											<MenuItem key={id_localidad} value={id_localidad}>
												{desc_localidad}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('localidad') && !isValidFieldRequired(firstCompany.company['localidad']) && (
										<FormHelperText>
											{!isValidFieldRequired(firstCompany.company['localidad'])
												? 'Campo requerido'
												: ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid xs={12} sm={12} md={4} mb={5} pr={2} item={true}>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('prestador_principal') && !isValidFieldRequired(firstCompany.company['prestador_principal'])}>
									<InputLabel required id='prestador_principal'>
										Prestador principal
									</InputLabel>
									<Select
										required
										labelId='prestador_principal'
										id='prestador_principal'
										label='Prestador principal'
										value={firstCompany.company['prestador_principal']}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, prestador_principal: e.target.value },
											})
										}
										onBlur={() => handleBlur('prestador_principal')}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listPrestadorPreferencia.map(({ code, value }, i) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										{touchedFields.includes('prestador_principal') && !isValidFieldRequired(firstCompany.company['prestador_principal'])
											? 'Campo requerido'
											: ''}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid xs={12} sm={12} md={8} mb={5} pr={2} item={true}>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('punta_atencion') && !isValidFieldRequired(firstCompany.company['punta_atencion'])}
								>
									<InputLabel required id='punta_atencion'>
										Punto de atención
									</InputLabel>
									<Select
										labelId='punta_atencion'
										id='punta_atencion'
										label='Punto de atención'
										value={firstCompany.company['punta_atencion']}
										onChange={(e) =>
											setFirstCompany({
												...firstCompany,
												company: { ...firstCompany.company, punta_atencion: e.target.value },
											})
										}
										onBlur={() => handleBlur('punta_atencion')}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listPuntoAtencion.map(({ code, value }, i) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('punta_atencion') && !isValidFieldRequired(firstCompany.company['punta_atencion']) && (
										<FormHelperText>
											{!isValidFieldRequired(firstCompany.company['punta_atencion'])
												? 'Campo requerido'
												: ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</>
					)}
				</Grid>

				<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
					<Button
						variant='contained'
						sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
						onClick={() => setStep(1)}
						disabled={requestInProgress}
					>
						Atrás
					</Button>
					{/*<Box sx={{ flex: '1 1 auto' }} />*/}
					<Button
						variant='contained'
						sx={{ background: '#0A4396', mr: 1, width: 90 }}
						onClick={handleSubmit}
						disabled={requestInProgress}
					>
						Siguiente
					</Button>
				</Box>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={'filled'}
						severity={alert ? 'success' : 'error'}
						sx={{ whiteSpace: 'pre-line' }}
					>
						{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
						{alertMsg}
					</Alert>
				</Snackbar>
			</>}
		</>
	);
};
