import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import parameters from '../../../util/parameters';
import { esES } from '@mui/x-date-pickers/locales/esES';
import { useNavigate, useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BackButton from '../../../components/BackButton/BackButton';
import { multiStepContext } from '../../../contexts/BitacoraContext';
import { isValidFieldRequired, initValue } from '../../../util/Index';
import parameterSingleton from '../../../services/Parameters.service';
import { ParameterConfigMmodel } from '../../../models/parameter.model';
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../components/LoadingSpinner/LoadingSpinner';
import { createBitacora, getBitacora, putBitacora } from '../../apps/user-management/users-list/core/_requests';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	Divider,
	MenuItem,
	Select,
	Button,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText,
    Typography
} from '@mui/material';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const Bitacora = () => {	
	const [listTipoBitacora, setListTipoBitacora] = useState<ParameterConfigMmodel[]>([]);
	const {
        userData, 
        setUserData, 
        loading,
        setLoading,
        shouldDisable,
        setShouldDisable 
	} = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);	
	const { id_postulacion, id } = useParams();
	const navigate = useNavigate();
	const handleClose = () => {
		setOpen(false);
	};    
	const [mensaje, setMensaje] = useState("Le agradecemos que diligencie los siguientes campos:");
	const [titulo, setTitulo] = useState("Creación de la bitácora");

	useEffect(() => {
		if(shouldDisable){
			setMensaje("A continuación, le presentamos la información que ha sido registrada en la bitácora:");
			setTitulo("Visualización de la bitácora");
		} else if (id !== undefined && parseInt(id) > 0) {
			setMensaje("Le agradecemos que edite la información que ha sido registrada en la bitácora:");
			setTitulo("Edición de la bitácora");
		};				
	}, [shouldDisable]);	

	useEffect(() => {
		setShouldDisable(userData['status'] === 'REALIZADA');		
	}, [userData['status'], setShouldDisable]);	

	const obtenerBitacoraInicial = async () => {
		setLoading(true);		
		await fetchListaTipoBitacora();	
		await getBitacoraById();						
		setLoading(false);
	};

	const fetchListaTipoBitacora = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOBITACORA);
		setListTipoBitacora(res);
	};

	const getBitacoraById = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			const query = `id=${id}`
			const response = await getBitacora(query);			
			if (response && response.data.data) {				
				setUserData({
					...response.data.data,
					hora: dayjs(response.data.data.fecha),
					fechaAuxiliar: dayjs(response.data.data.fecha)
				});
			} 
		} else {
			setUserData({ 
				...userData, 
				id_postulacion: id_postulacion, 
				status: "PENDIENTE"
			});
		}
	};

	useEffect(() => {
		obtenerBitacoraInicial();
	}, []);	

	class validationForm {                 
		entrevistador: string = initValue(userData['entrevistador']);
		fecha: string = initValue(userData['fechaAuxiliar']);
		hora: string = initValue(userData['hora']);
		tipo: string = initValue(userData['tipo']);
		detalle: string = initValue(userData['detalle']);
        status: string = initValue(userData['status']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({				
                entrevistador: Yup.string().required('Campo requerido').max(100, 'Máximo 100 caracteres'),
				fecha: Yup.string().required('Campo requerido'),
				hora: Yup.string().required('Campo requerido'),				
				tipo: Yup.string().required('Campo requerido'),
                detalle: Yup.string(),
                status: Yup.string()
			}),
		[]
	);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		initialTouched: {
			entrevistador: false,
			fecha: false,
			hora: false,
			tipo: false,
			detalle: false,
			status: false
		},
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {			
			try {
				setSubmitting(true);
				setStatus('');
			} catch (error) {
				console.error(error);
			}
		},
	});	    
	
	const camposValidos = async () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(userData['entrevistador']))
			errors.push({ field: 'Responsable', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['fecha']))
			errors.push({ field: 'Fecha y hora de la entrevista', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['tipo']))
			errors.push({ field: 'Tipo de entrevista', error: 'Campo requerido' });
		
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}
		if (Object.keys(formik.errors).length) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		if ( userData?.fechaAuxiliar?.isValid() && userData?.hora?.isValid()){
			const componenteHora = userData.hora.format('HH:mm');
			const fechaFormateada = userData.fechaAuxiliar.format('YYYY-MM-DD');																													
			const fechaCampo = dayjs(`${fechaFormateada}T${componenteHora}`);
			setUserData({ ...userData, fecha: fechaCampo });
		}
	}, [userData.fechaAuxiliar, userData.hora]);	

	const handleSubmit = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		try {
			formik.handleSubmit();
			setRequestInProgress(true);			
			const isValidToContinue = await camposValidos();            
			if (!isValidToContinue)
				console.log('Fields not valid');
			if (userData.id !== undefined && parseInt(userData.id) > 0) {
				const response = await putBitacora(userData);				
				setAlert(true);
				setAlertMsg(`¡${response.data.message}!`);
			} else {
				const response = await createBitacora(userData);				
				setAlert(true);
				setAlertMsg(`¡${response.data.message}!`);
			}
			setOpen(true);
			setTimeout(() => {
				navigate(-1);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setTimeout(() => {
				setRequestInProgress(false);
			}, 2000);
		}

	};

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>
			<Box sx={{ width: '95%' }} mb={3}>
				<Grid container direction='row' width={'100%'}>
                    <Grid xs={1} sm={1} md={1} pr={1} mb={1} item={true}>								
                        <BackButton />								
					</Grid>
                    <Grid xs={9} sm={9} md={9} pr={1} mb={1} item={true}>
                        <Typography variant='h4'><b>{titulo}</b></Typography>
                        <Typography variant='inherit'>{mensaje}</Typography> 									
                    </Grid>							
				</Grid>
			</Box>
			<Box>
				<LoadingBackdrop loading={requestInProgress} />
				{loading ? (<LinearLoadingSpinner isLoading={true}/>) : (					
					<>
						<Grid container direction='row' width={'100%'}>
							<Grid xs={12} md={6} mb={5} pr={2} item={true}>
								<FormControl
									fullWidth
									size='medium'
									error={formik.touched.entrevistador && Boolean(formik.errors.entrevistador) && !shouldDisable}
								>
									<TextField
										{...formik.getFieldProps('entrevistador')}
										required
										fullWidth
										id='entrevistador'
										name='entrevistador'                                               										                                      
										label='Responsable'
										placeholder='Responsable'
										value={initValue(userData['entrevistador'])}											
										onChange={(e) => {												
											setUserData({ ...userData, entrevistador: e.target.value});
											formik.handleChange(e);																								
										}}
										disabled={shouldDisable}
										size='medium'
										inputProps={{ maxLength: 100 }}
										error={formik.touched.entrevistador && Boolean(formik.errors.entrevistador) && !shouldDisable}
										helperText={formik.touched.entrevistador && Boolean(formik.errors.entrevistador) && !shouldDisable ? formik.errors.entrevistador : ''}
									/>
								</FormControl>
							</Grid>
							<Grid xs={12} md={6} mb={5} pr={2} item={true}>
								<FormControl
									fullWidth
									size='medium'
									error={formik.touched.tipo && Boolean(formik.errors.tipo) && !shouldDisable}
								>
									<InputLabel required>Tipo de reunión</InputLabel>
									<Select
										{...formik.getFieldProps('tipo')}
										error={formik.touched.tipo && Boolean(formik.errors.tipo) && !shouldDisable}
										id='tipo'
										name='tipo'
										label='Tipo de reunión'
										disabled={shouldDisable}
										value={initValue(userData['tipo'])}
										onChange={(e) => {												
											setUserData({ ...userData, tipo: e.target.value});												
											formik.handleChange(e);
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listTipoBitacora.map(({ code, label }, i) => (
											<MenuItem value={code} key={i}>
												{label}
											</MenuItem>
										))}
									</Select>
									<FormHelperText sx={{ color: '#d32f2f' }}>
										{formik.touched.tipo && Boolean(formik.errors.tipo) && !shouldDisable ? formik.errors.tipo : ''}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid xs={12} md={4} mb={5} pr={2} item={true}>
								<LocalizationProvider 
									dateAdapter={AdapterDayjs} 
									localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} 
									adapterLocale="es"
								>
									<FormControl 
										fullWidth
										size='medium' 
										error={formik.touched.fecha && Boolean(formik.errors.fecha) && !shouldDisable}
									>
										<DatePicker	
											{...formik.getFieldProps('fecha')}											
											disabled={shouldDisable}																															
											value = {dayjs(formik.values.fecha)}
											format="DD-MM-YYYY"
											views={['year', 'month', 'day']}
											onChange={(e) => {																						
												if (e === null || !(e?.isValid())) {																																							
													formik.setFieldValue("fecha", null);
													setUserData({ ...userData, fechaAuxiliar: null });													
												} else {
													formik.setFieldValue("fecha", e);													
													setUserData({ ...userData, fechaAuxiliar: e });
												}
												formik.setFieldTouched('fecha', true);
											}}												
											onOpen={() => formik.setFieldTouched('fecha', true)}
											onClose={() => formik.setFieldTouched('fecha', true)}
											onError={() => { formik.setFieldTouched('fecha', true); }}											
											slotProps={{
												textField: {
													onBlur: () => formik.setFieldTouched('fecha', true),
													onClick: () => formik.setFieldTouched('fecha', true),
													required: true,
													fullWidth: true,														
													id: 'fecha',
													name: 'fecha',
													label: 'Fecha',
													variant: 'outlined',
													error: (formik.touched.fecha && Boolean(formik.errors.fecha) && !shouldDisable),
													helperText: (formik.touched.fecha && Boolean(formik.errors.fecha) && !shouldDisable) ? 'Campo requerido' : ''
												},
												actionBar: { actions: ["clear", "cancel", "today"] }
											}}
										/>
									</FormControl>
								</LocalizationProvider>
							</Grid>		
							<Grid xs={12} md={4} mb={5} pr={2} item={true}>
								<LocalizationProvider 
									dateAdapter={AdapterDayjs} 
									localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} 
									adapterLocale="es"
								>
									<FormControl 
										fullWidth
										size='medium' 
										error={formik.touched.hora && Boolean(formik.errors.hora) && !shouldDisable}
									>
										<TimePicker	
											{...formik.getFieldProps('hora')}											
											disabled={shouldDisable}																															
											value = {dayjs(formik.values.hora)}
											format="HH:mm"
											views={['hours', 'minutes']}
											onChange={(e) => {													
												if (e === null || !(e?.isValid())) {																																						
													formik.setFieldValue("hora", null);
													setUserData({ ...userData, hora: null });
												} else {																																							
													formik.setFieldValue("hora", e);																						
													setUserData({ ...userData, hora: e});
												}
												formik.setFieldTouched('hora', true);												
											}}												
											onOpen={() => formik.setFieldTouched('hora', true)}
											onClose={() => formik.setFieldTouched('hora', true)}
											onError={() => { formik.setFieldTouched('hora', true); }}											
											slotProps={{
												textField: {
													onBlur: () => formik.setFieldTouched('hora', true),
													onClick: () => formik.setFieldTouched('hora', true),
													required: true,
													fullWidth: true,														
													id: 'hora',
													name: 'hora',
													label: 'Hora',
													variant: 'outlined',
													error: (formik.touched.hora && Boolean(formik.errors.hora) && !shouldDisable),
													helperText: (formik.touched.hora && Boolean(formik.errors.hora) && !shouldDisable) ? 'Campo requerido' : ''
												}
											}}
										/>
									</FormControl>
								</LocalizationProvider>
							</Grid>															
							{(shouldDisable) && (
								<>
									<Grid xs={12} md={4} mb={5} pr={2} item={true}>
										<FormControl
											fullWidth
											size='medium'
											error={formik.touched.status && Boolean(formik.errors.status) && !shouldDisable}
										>
											<TextField
												required
												fullWidth
												id='status'
												name='status'
												label='Estado'
												placeholder='Estado'
												value={initValue(userData['status'])}
												disabled={shouldDisable}
												size='medium'                                                    
												error={formik.touched.status && Boolean(formik.errors.status) && !shouldDisable}
												helperText={formik.touched.status && Boolean(formik.errors.status) && !shouldDisable ? formik.errors.status : ''}
											/>
										</FormControl>
									</Grid>
									<Grid xs={12} md={12} mb={5} pr={2} item={true}>
										<FormControl 
											fullWidth
											size='medium'
											error={formik.touched.detalle && Boolean(formik.errors.detalle) && !shouldDisable}
										>
											<TextField
												fullWidth								
												rows={10}
												id='detalle'
												name='detalle'
												label='Conclusiones'
												required
												disabled={shouldDisable}                                                    
												value={initValue(userData['detalle'])}                                                    
												size='medium'
												multiline                                                    
												error={formik.touched.status && Boolean(formik.errors.status) && !shouldDisable}
												helperText={formik.touched.status && Boolean(formik.errors.status) && !shouldDisable ? formik.errors.status : ''}
											/>
										</FormControl>
									</Grid>
								</>
							)}								
						</Grid>							
						<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
						<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
							<Button
								variant='contained'								
								sx={{ background: '#0A4396', width: 90, height: 40 }}
								onClick={(e) => {
									handleSubmit(e)
								}}
								disabled={formik.isSubmitting || !formik.isValid || shouldDisable}
							>
								Enviar
							</Button>
						</Box>
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={open}
							onClose={handleClose}
						>
							<Alert
								onClose={handleClose}
								variant={'filled'}
								severity={alert ? 'success' : 'error'}
								sx={{ whiteSpace: 'pre-line' }}
							>
								{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
								{alertMsg}
							</Alert>
						</Snackbar>
					</>
				)}
			</Box>
		</Box>
	);
};
