import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { CardModel } from '../../models/CardModel';
import styleScss from './styles.module.scss';
import Chatbot from '../../components/Chatbot';

const AdminAreaPage = () => {

	const listManageLeft: CardModel[] = [
		{
			title: 'Gestionar usuarios',
			content: 'Crear, editar, aprobar, excluir, activar e inactivar usuarios.',
			action: '/admin/gestion-registros',
			actionName: 'Ver más',
			icon: 'bi-person'
		},
		{
			title: 'Gestionar candidatos',
			content: 'Crear, editar, activar e inactivar candidatos',
			action: '/admin/gestion-candidatos',
			actionName: 'Ver más',
			icon: 'bi-people'
		},
	];

	const listManageRight: CardModel[] = [
		{
			title: 'Gestionar empresas',
			content: 'Crear, editar, eliminar, activar y desactivar empresas.',
			action: '/admin/gestion-empresas',
			actionName: 'Ver más',
			icon: 'bi-newspaper'
		},
		{
			title: 'Gestionar vacantes',
			content: 'Crear, editar, activar e inactivar vacantes.',
			action: '/admin/gestion-vacantes',
			actionName: 'Ver más',
			icon: 'bi-layout-text-sidebar'
		}
	];

	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard de Candidatos',
			content: 'Métricas e indicadores de las vacantes.',
			action: '/admin/dashboard/candidatos',
			icon: 'bi-rocket-takeoff-fill'
		},
		{
			title: 'Dashboard de los Empleadores',
			content: 'Métricas e indicadores de las vacantes y candidatos.',
			action: '/admin/dashboard/empresas',
			icon: 'bi-rocket-takeoff-fill'
		},
		{
			title: 'Métricas e indicadores ',
			content: 'Estadísticas de la plataforma.',
			action: '/admin/dashboard/politicas',
			icon: 'bi-rocket-takeoff-fill'
		},
	];

	return (
		<>
			{/* <h2>Área del administrador</h2> */}
			<div className='row mt-15' style={{overflow:'auto'}}>
				{/* Cards Left */}
				<div className={`col-sm-4 mt-5`}>
					{listManageLeft.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['card']}`}
							style={{ borderRadius: '10px' }}
							key={i}
							id={i.toString()}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-3'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
				{/* Cards Left */}
				<div className={`col-sm-4 mt-5`}>
					{listManageRight.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['card']}`}
							style={{ borderRadius: '10px' }}
							key={i}
							id={i.toString()}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-3'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
				{/* Dashboards */}
				<div className='col-sm-4 mt-5'>
					{listDashboard.map((x: CardModel, i: number) => (
						<div className={`card mb-5 ${styleScss['blue-card']}`} key={i} id={i.toString()}>
							{x.action ? (
								<>
									<Link to={x.action!}>
										<div className='card-body mt-3'>
											<i className={`bi ${x.icon} ${styleScss['icon-custom-white']}`}></i>
											<h3 className='card-title text-white mt-2'>{x.title}</h3>
											<p className='card-text text-white'>{x.content}</p>
										</div>
									</Link>
								</>
							) : (
								<div className='card-body  mt-3'>
									<i className={`bi ${x.icon} ${styleScss['icon-custom-white']}`}></i>
									<h3 className='card-title text-white mt-2'>{x.title}</h3>
									<p className='card-text text-white'>{x.content}</p>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const AdminAreaWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Área Administrador</PageTitle>
			<AdminAreaPage />
			<Chatbot />
		</>
	);
};

export { AdminAreaWrapper };
