import { useContext, useEffect, useState } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	Checkbox,
	Button,
	Box,
	Typography,
	Divider,
	FormControlLabel,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CompanyContext';

export const Step4 = () => {
	const { setStep, userData, setUserData, firstUser, setFirstUser, firstCompany, setFirstCompany } =
		useContext(multiStepContext);
	const [documentType, setDocumentType] = useState('');

	const handleChange = (event: SelectChangeEvent) => {
		setDocumentType(event.target.value);
	};

	useEffect(() => {
		setUserData({
			...userData,
			termino_condiciones: `
			1. Uso del Contenido
			El sitio Web tiene por finalidad brindar al usuario todo tipo de información relacionada con la gestión de LA ENTIDAD en todos los planes, programas y consejerías, por medio de boletines, cifras, noticias. En ningún caso esta información deberá considerarse como exhaustiva, completa o que de cualquier forma satisfaga todas las necesidades del Usuario.
			
			El Sitio Web puede tener enlaces a otros sitios de interés o a documentos localizados en otras páginas web de propiedad de otras entidades, personas u organizaciones diferentes a LA ENTIDAD. Solamente por el hecho de que el usuario acceda a otro sitio web o a un documento individual localizado en otra página, a través de un link o un enlace establecido en el Sitio Web, el usuario deberá someterse a las condiciones de uso y a la política de privacidad del Sitio Web al que envía el enlace.
			
			El establecimiento de un enlace (link) con el sitio web de otra empresa, entidad o programa no implica necesariamente la existencia de relaciones entre LA ENTIDAD y el propietario del sitio o página Web vinculada, ni la aceptación o aprobación por parte de LA ENTIDAD de sus contenidos o servicios. Aquellas personas que se propongan establecer un enlace (link) se asegurarán de que el mismo únicamente permita el acceso a la página de inicio Web.
			
			Así mismo, LA ENTIDAD no se hace responsable respecto a la información que se halle fuera de este Sitio Web y no sea gestionada directamente por el administrador del Sitio Web. Los enlaces (links) que aparecen en el Sitio Web tienen como propósito informar al Usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece el Sitio Web, o que guardan relación con aquéllos. LA ENTIDAD no garantiza ni se responsabiliza del funcionamiento o accesibilidad de las páginas web enlazadas; ni sugiere, invita o recomienda la visita a las mismas, por lo que tampoco será responsable del resultado obtenido. Por lo tanto, el acceso a las mismas a través del Sitio Web tampoco implica que LA ENTIDAD recomiende o apruebe sus contenidos.
			
			Por otra parte, la prestación del servicio del Sitio Web es de carácter libre y gratuito para los usuarios.
			
			El Sitio Web contiene artículos u obras de carácter literario y científico (en adelante, Información) elaborados por LA ENTIDAD o por terceros, con fines informativos, y divulgativos. LA ENTIDAD puede modificar o retirar la Información en cualquier momento y sin aviso previo. Las opiniones vertidas en los comentarios realizados por los Usuarios no reflejan necesariamente los puntos de vista de LA ENTIDAD.
			
			Queda expresamente prohibido el uso del Sitio Web que de cualquier forma sobrecarguen, dañen o inutilicen las redes, servidores y demás equipos informáticos o productos y aplicaciones informáticas de LA ENTIDAD o de terceros.
			
			LA ENTIDAD no se hace responsable del servicio ininterrumpido o libre de error de la página.  LA ENTIDAD hace sus mejores esfuerzos para que el contenido suministrado sea de óptima calidad, y en tal sentido el Usuario acepta utilizar el servicio.
			
			El Usuario no puede emplear los contenidos y, en particular, la información de cualquier otra clase obtenida a través de LA ENTIDAD o de los servicios, para emitir publicidad.
			
			El Usuario del Sitio Web no alterará, bloqueará o realizará cualquier otro acto que impida mostrar o acceder a cualquier contenido, información o servicios del Sitio Web o que estén incorporados en las páginas web vinculadas.
			
			1.1 Responsabilidad por la información contenida
			 
			
			Teniendo en cuenta que los medios técnicos disponibles en la actualidad no garantizan la absoluta falta de injerencia de la acción de terceras personas en el Sitio Web, LA ENTIDAD de ninguna manera asegura la exactitud y/o veracidad de todo o parte de la información contenida en su página, ni su actualización, ni que dicha información haya sido alterada o modificada en todo o en parte, luego de haber sido publicada en la página, ni cualquier otro aspecto o característica de lo publicado en el sitio o en los enlaces, respectivamente.
			
			LA ENTIDAD no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático.
			
			En consecuencia con lo anterior, LA ENTIDAD no se hará responsable de ningún daño ocasionado en virtud de cualquier alteración que se haya efectuado a los materiales o archivos de descarga suministrados directamente por la entidad.
			
			El Usuario no enviará o transmitirá en el Sitio Web o hacia el mismo, a otros usuarios usuarios o a cualquier persona, cualquier información de contenido obsceno, difamatorio, injuriante, calumniante o discriminatorio contra cualquier persona, o contra LA ENTIDAD, sus filiales o entidades adscritas, sus funcionarios o contra los responsables de la administración del Sitio Web.
			
			En ningún caso se aceptarán contenidos que pueden ser considerados como ofensivos, sexistas, racistas, discriminatorios, obscenos, en la medida que contenidos ofensivos atentan contra derechos fundamentales de los particulares.
			
			1.2 Propiedad Intelectual
			 
			
			La propiedad intelectual sobre los contenidos del Sitio Web o bien hacen parte del patrimonio de LA ENTIDAD o, en su caso, su titularidad es de terceros que autorizaron el uso de los mismos en el Sitio Web o es información pública que se rige por las leyes colombianas respecto al acceso a la información pública.
			
			Los textos y elementos gráficos que constituyen la página Web, así como su presentación y montaje, o son titularidad exclusiva de LA ENTIDAD o ésta ostenta los derechos de explotación necesarios. Sin perjuicio de lo anterior, los nombres comerciales, marcas o signos distintivos que aparecen o a los que se hace alusión en el Sitio Web, pertenecen a sus respectivos propietarios y se encuentran protegidos por la legislación vigente al respecto.
			
			Se prohíbe cualquier uso, transformación o explotación de los contenidos incluidos en el Sitio Web con finalidades comerciales o promocionales salvo autorización previa de LA ENTIDAD; en cualquier caso se prohíbe cualquier uso contrario a la ley y del Sitio Web para uso personal y no comercial, siempre y cuando se haga expresa mención de la propiedad en cabeza del autor del contenido.
			
			Todos los logotipos y marcas del Sitio Web son de propiedad de LA ENTIDAD o su uso ha sido autorizado por sus titulares a LA ENTIDAD, siendo, en todo caso, los titulares los responsables de cualquier posible controversia que pudiera darse respecto de ellos. Los titulares de dichas marcas y logotipos se reservan el derecho de entablar las acciones legales que consideren convenientes para hacer valer sus derechos tanto en Colombia como en el exterior.
			
			El Usuario acepta que los contenidos generados y subidos por él serán de propiedad de LA ENTIDAD, conservando el Usuario los derechos morales sobre dichos contenidos.
			
			En caso de reclamaciones que pudieran interponerse por los usuarios o por terceros en relación con posibles incumplimientos de los derechos de propiedad intelectual sobre cualquiera de los contenidos del Sitio Web deberán dirigirse a la siguiente dirección de correo electrónico: lcaicedo@desarrolloeconomico.gov.co Una vez notificado a este correo, dicho contenido será  eliminado del Sitio Web hasta que quien haya publicado el contenido en disputa haya resuelto el conflicto con quien envía la reclamación.
			
			1.3 Uso correcto de los Contenidos
			 
			
			El Usuario se obliga a utilizar los Contenidos de forma diligente, correcta y lícita. En general se compromete a no utilizar los Contenidos de forma ilícita y para fines contrarios a la Ley o el Orden Público. Entre otras obligaciones, a manera meramente referencial y sin que implique limitación alguna, al Usuario se le prohíbe especialmente: (a) suprimir, eludir o manipular el “copyright” y demás datos identificadores de los derechos de los productos web de LA ENTIDAD o de sus titulares, incorporados a los Contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualesquiera mecanismos de información que pudieren contener los Contenidos; (b) no respetar la privacidad, opiniones, punto de vista, ideología, religión y etnia de otros Usuarios, así como aquellas otras opciones personales o aspectos pertenecientes su esfera de intimidad y privacidad; (c) usar los Contenidos con propósitos comerciales, incluyendo la promoción de cualquier bien o servicio; (d) proporcionar información obscena, difamatoria, dañina o conocidamente falsa; (e) obstaculizar, entorpecer, restringir o limitar el uso de los Contenidos por parte de otros Usuarios; (f) en general, el Usuario deberá abstenerse de utilizar los productos Web de LA ENTIDAD de manera que atente contra los legítimos derechos de terceros, o bien que pueda dañar, inutilizar, sobrecargar o deteriorar los Contenidos o impedir su normal uso por parte de otros Usuarios.
			
			1.4 Utilización y Reproducción de los Contenidos
			 
			
			Se permite utilizar y/o reproducir Contenidos, bajo el cumplimiento de las siguientes reglas: (a) la utilización y/o reproducción debe ser compatible con el objetivo informativo, educacional y recreativo de los productos web de LA ENTIDAD; (b) la información no puede utilizarse con fines comerciales; (c) los Contenidos de cada uno de los productos, tales como datos, documentos, información, gráficos o imágenes, no pueden ser modificados de forma alguna, copiados o distribuidos separadamente de su contexto, texto, gráfico o imágenes que lo acompañen. En resumen se permite el Uso de los contenidos con fines estrictamente informativos, académicos, educacionales, de investigación o personales, que en ninguna caso impliquen la utilización de los mismos para fines de lucro.
			
			1.5 Utilización de Claves de Acceso y Registro de Usuarios
			 
			
			Los Contenidos de los productos Web de LA ENTIDAD tienen carácter gratuito para los Usuarios y no exigen la suscripción previa o registro del Usuario.
    `,
		});
	}, []);

	return (
		<>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item={true}
			>
				<Grid container direction='column' item={true}>
					<Typography color='white' variant='h5'>
						Términos y condiciones
					</Typography>
				</Grid>

				<Box display='flex' justifyContent='end' alignItems='center'></Box>
			</Grid>

			<Grid xs={12} mb={5} item={true}>
				<TextField
					fullWidth
					required
					id='termino_condiciones'
					name='termino_condiciones'
					value={userData['termino_condiciones']}
					disabled={true}
					//onChange={(e) => setUserData({ ...userData, termino_condiciones: e.target.value })}
					multiline
					rows={15}
				/>
			</Grid>
			<Grid
				xs={12}
				mb={3}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				item={true}
			>
				<FormControlLabel
					value='acepto_termos'
					control={
						<Checkbox
							color='default'
							checked={firstUser.user['acepto_termos']}
							value={firstUser.user['acepto_termos']}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									user: { ...firstUser.user, acepto_termos: e.target.checked },
								})
							}
						/>
					}
					label='Acepto términos y condiciones'
					labelPlacement='end'
				/>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(3)}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					disabled={!firstUser.user['acepto_termos']}
					onClick={() => setStep(5)}
				>
					Siguiente
				</Button>
			</Box>
		</>
	);
};
