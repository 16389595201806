import { FormEvent, useContext, useState } from 'react';
import {
	Alert,
	Grid,
	Button,
	Box,
	Typography,
	Divider,
	Snackbar,
	CircularProgress,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { createCompany } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import { isValidFieldRequired } from '../../../../../util/Index';
import { uploadFileCompany } from '../../../core/_requests';
import { LoadingBackdrop } from '../../../../../components/LoadingSpinner/LoadingSpinner';

export const Step6 = () => {
	const {
		setStep,
		userData,
		setUserData,
		firstUser,
		setFirstUser,
		firstCompany,
		setFirstCompany,
		attachments,
	} = useContext(multiStepContext);
	const [loading, setLoading] = useState(false);
	const [verified, setVerified] = useState(false);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();
	const [requestInProgress, setRequestInProgress] = useState(false);
	const REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS = process.env.REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS || '';
	const REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES = process.env.REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES || '';
	const REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA = process.env.REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA || '';

	const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

	//console.log('attachments', attachments);
	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
		const companyBody = {
			firstCompany,
			firstUser,
		};
		try {
			if (companyBody.firstCompany.company.telefono !== undefined)
				companyBody.firstCompany.company.telefono = (
					companyBody.firstCompany.company.telefono as string
				)
					.trim()
					.replaceAll('+', '')
					.replaceAll('-', '');
			if (companyBody.firstUser.complemento.celular !== undefined)
				companyBody.firstUser.complemento.celular = isValidFieldRequired(
					companyBody.firstUser.complemento?.celular
				)
					? (companyBody.firstUser.complemento?.celular as string)
						.trim()
						.replaceAll('+', '')
						.replaceAll('-', '')
					: '';
			companyBody.firstUser.user.role = 4;
			setLoading(true);
			setRequestInProgress(true);
			const response = await createCompany(companyBody);
			//todo: enviar anexos
			if (Object.keys(attachments).length > 0) {
				const res = uploadFilesCompany(response.data.id);
			} else {
				setIsError(false);
				setMsgAlert('¡Email enviado con éxito!');
				setOpen(true);
				setSuccess(true);
				setTimeout(() => {
					setLoading(false);
					setRequestInProgress(false);
					navigate(-1);
				}, 15000);
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setIsError(true);
				setMsgAlert(`${error.response.data.error}`);
			} else {
				// Erro de rede ou outro erro inesperado
				console.error('Ocurrio un error procesando la solicitud.');
			}
			setLoading(false);
			setRequestInProgress(false);
		}
	}

	const uploadFilesCompany = (id: string) => {
		try {
			let teminou = 0;
			attachments.forEach(async (element: any) => {
				let dataForm = new FormData();
				dataForm.append(`id_empresa`, id);
				dataForm.append(`file`, element, element.name);
				const response = await uploadFileCompany(dataForm);
				teminou++;
				if (Object.keys(attachments).length === teminou) {
					setIsError(false);
					setMsgAlert('¡Email enviado con éxito!');
					setOpen(true);
					setLoading(false);
					setSuccess(true);
					setTimeout(() => {
						navigate(-1);
					}, 15000);
				}
			});
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setIsError(true);
				setMsgAlert(`${error.response.data.error}`);
			} else {
				// Erro de rede ou outro erro inesperado
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	function onChange(value: any) {
		setVerified(true);
		console.log(success);
	}

	return !success ? (
		<>
			<LoadingBackdrop loading={requestInProgress} />
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width='100%'
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Confirma tu registro
					</Typography>
				</Grid>

				<Box display='flex' justifyContent='end' alignItems='center'></Box>
			</Grid>

			<Grid xs={12} mb={5} item>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
					<ReCAPTCHA
						sitekey={RECAPTCHA_SITE_KEY}
						hl='es'
						onChange={onChange}
					/>
				</div>
			</Grid>
			<Grid xs={12} mb={5} item>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: 60,
						marginBottom: 30,
						width: '100%',
						fontSize: 14,
						textAlign: 'justify'
					}}
				>
					<div style={{ textAlign: 'justify' }}>
						<div>
							Al enviar el formulario de registro estás aceptando la{' '}
							<a href={`${REACT_APP_LINK_POLITICA_PRIVACIDAD_TRATAMIENTOS_DATOS}`}
								target='_blank' rel='noopener noreferrer'>
								Política de Privacidad y Tratamiento de Datos
							</a>{' '}
						</div>
						<div>
							Personales, el{' '}
							<a href={`${REACT_APP_LINK_MANUAL_POLITICAS_PROCEDIMIENTOS_TRATAMIENTO_DATOS_PERSONALES}`}
								target='_blank' rel='noopener noreferrer'>
								"Manual de Políticas y Procedimientos para el tratamiento de Datos Personales"
							</a>{' '}
							de la
						</div>
						<div>
							Secretaria General de la Alcaldia Mayor de Bogotá, D.C. y la{' '}
							<a href={`${REACT_APP_LINK_POLITICA_PRIVACIDAD_GOBIERNO_ABIERTO_BOGOTA}`}
								target='_blank' rel='noopener noreferrer'>
								Política de Privacidad de GOBIERNO ABIERTO BOGOTÁ
							</a>{' '}
						</div>
					</div>
				</div>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(5)}
					disabled={loading}
				>
					Atrás
				</Button>

				<Link to='/auth'>
					<Button
						variant='contained'
						disabled={!verified || loading}
						sx={{ mr: 1, width: 90 }}
						onClick={handleSubmit}
					>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							{loading && <CircularProgress size={20} sx={{ marginRight: 2, color: 'white' }} />}
							Enviar
						</span>
					</Button>
				</Link>
			</Box>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</>
	) : (
		<div
			className='mb-3'
			style={{
				alignContent: 'center',
				textAlign: 'center',
				//marginTop: '15%',
				color: '#3366C8',
				fontWeight: 'bold',
			}}
		>
			<div className='card' style={{ width: '100%' }}>
				<i
					className='bi bi-envelope-check card-img-top mt-5'
					style={{ fontSize: '100px', color: '#60C98C' }}
				></i>
				<div className='card-body'>
					<p className='card-text' style={{ fontSize: '16pt' }}>
						¡Su solicitud de registro está en análisis! Muy pronto usted recibirá un correo con sus credenciales de acceso. <br />
						Por favor realice el trámite enviado al correo electrónico: {firstUser.user.email}
					</p>
				</div>
			</div>
		</div>
	);
};
