import { Backdrop, Box, CircularProgress, LinearProgress } from "@mui/material";
import styleCss from './styles.module.scss';

const LoadingSpinner: React.FC<{ isLoading: Boolean }> = ({ isLoading }) => {
    return isLoading &&
        (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2em' }}>
            <CircularProgress size={60} />
        </Box>)
}

const LinearLoadingSpinner: React.FC<{ isLoading: Boolean }> = ({ isLoading }) => {
    // const l:boolean = isLoading;
    return isLoading && (
        <LinearProgress
            variant="indeterminate"
            sx={{ marginBottom: '.3em', marginTop: '.1em', height: '.45em' }} />

    )
}

const LoadingBackdrop: React.FC<{ loading: boolean }> = ({ loading }) => {
    return (<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        className={`${styleCss['MuiBackdrop-root-two']}`}
    >
        <CircularProgress color="primary" />
    </Backdrop>)
}

export { LoadingSpinner, LinearLoadingSpinner, LoadingBackdrop };
