import { useContext, useState, useEffect, useMemo, SyntheticEvent } from 'react';
import {
	TextField,
	Grid,
	Typography,
	Button,
	Box,
	Divider,
	Chip,
	Autocomplete,
	Stack,
	Alert,
	Snackbar,
	AlertTitle
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import _ from 'lodash';
import { concatArrayToStringJoinBy, initValueString } from '../../../../../util/Index';
import { getCargos, putVacancyTempral } from '../../../../apps/user-management/users-list/core/_requests';
import { useAuth } from '../../../core/Auth';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const primeira = [{ valid: false }]
export const Step2 = () => {
	const { isAdminRole } = useAuth();
	const { setStep, userData, setUserData, setLoading, shouldDisable } = useContext(multiStepContext);

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [rowsPuestos, setRowsPuestos] = useState<any[]>([]);

	const [name, setName] = useState('');
	const [isRowsValid, setIsValidRows] = useState(true);

	//Lista de referencia de los cargos desde la BD
	const [listCargos, setListCargos] = useState<any[]>([]);

	useEffect(() => {
		fetchDataCargos();
	}, []);

	/**
	 * Se genera un listener sobre la variable "listCargos", cuando se detecte un cambio se precarga 
	 * la informacion de cargos seleccionados.
	 */
	useEffect(() => {
		if (listCargos && listCargos.length > 0) {
			const listRowsPuestos = rowsPuestos.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsPuestos) {
				const list = [...rowsPuestos];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsPuestos(list);
			}
			if (userData['Cargos'] !== undefined) {
				let arr = [{ id: 0, puestos: '' }];
				(userData['Cargos'] as Array<any>).forEach((x) => {
					arr.push({ id: x.id as number, codigo_cuoc: x.codigo_cuoc as string, puestos: initValueString(x.desc_cargo) } as any);
				});
				arr.shift();
				setRowsPuestos(arr);
				setIsValidRows(arr.length <= 0 ? false : true);
			}
		}
	}, [listCargos]);

	/**
	 * Realiza llamado a consulta de cargos
	 */
	const fetchDataCargos = async () => {
		try {
			setLoading(true);
			const response = await getCargos();
			if (response && response.data) {
				setListCargos(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = (id: number) => {
		const list = [...rowsPuestos];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsPuestos(list);
		setIsValidRows(list.length <= 0 ? false : true);
	};

	const handleChange = (eventElement: SyntheticEvent, eventValue: any) => {
		if (!eventValue) {
			eventElement.preventDefault();
			return;
		}

		setIsValidRows(true);
		let selectItem = eventValue.desc_cargo as string;
		const cargoSelected = listCargos.find((obj) => { return obj.desc_cargo === selectItem; });
		const cargoExist = rowsPuestos.find((puesto) => puesto.puestos === selectItem);

		if (cargoSelected) {
			if (!cargoExist) {
				if (rowsPuestos.length < 3) {
					setRowsPuestos([
						...rowsPuestos,
						{
							id: cargoSelected?.id as number,
							puestos: cargoSelected?.desc_cargo as string,
							codigo_cuoc: cargoSelected?.codigo_cuoc as string,
						},
					]);
				} else {
					setAlert(false);
					setAlertMsg('Están permitidos seleccionar máximo 3 cargos');
					setOpen(true);
				}
			} else {
				setAlert(false);
				setAlertMsg('El cargo seleccionado "' + cargoExist.puestos + '" ya existe');
				setOpen(true);
			}
		}
	};

	const handleNext = () => {
		const data = {
			...userData,
			poblaciones_aceptadas: concatArrayToStringJoinBy(userData.poblacionesAceptadas, ',', null),
			listPuestos: rowsPuestos,
			Cargos: rowsPuestos.map((x) => {
				return {
					id: x.id,
					desc_cargo: x.puestos,
					codigo_cuoc: x.codigo_cuoc,
				};
			}),
		}
		
		const nextStep = () => {
			if (rowsPuestos.length === 0) {
				setIsValidRows(false);
				return;
			}

			setIsValidRows(true);

			setUserData(data);
			setStep(3);

		}

		if (userData.status_approval === 'TEMPORAL') putVacancyTempral(data).then((res) => {

			nextStep()

		}).catch((error) => {
			console.log(error);
		});
		else nextStep()



	};
	const handleRetorno = () => {
		setUserData({ ...userData, listPuestos: rowsPuestos });
		setStep(1);
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ color: '#2A2A2A', fontWeight: 'bold' }}>
					Cargos equivalentes a la vacante
				</Typography>
				<Typography sx={{ color: '#888888' }}>Seleccione máximo 3 cargos:</Typography>
			</Box>
			<Grid container direction='column' width={'100%'} item={true}>
				<Grid xs={4} mb={5} item={true}>
					<Autocomplete
						freeSolo
						onChange={(e, newValue) => handleChange(e, newValue)}
						clearText=''
						getOptionLabel={(opt: any) => initValueString(opt.desc_cargo)}
						options={listCargos}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.codigo_cuoc} - {option.desc_cargo}
								</li>
							);
						}}
						renderInput={
							(params) => {
								return <TextField required {...params} label='Buscar cargos' error={!isRowsValid} helperText={!isRowsValid ? 'Seleccione al menos un cargo' : ''} />
							}
						}
						disabled={shouldDisable}
					/>
				</Grid>
				<Grid xs={12} mb={5} item={true}>
					{rowsPuestos.map((row, key) => (
						<Grid xs={4} mb={1} key={key} item={true}>
							<Stack direction='column' spacing={1}>
								<Chip
									label={key + 1 + ' - ' + row.puestos}
									sx={{ borderRadius: 1, fontSize: 14, justifyContent: 'space-between' }}
									onDelete={() => handleDelete(row.id)}
									disabled={shouldDisable}
								/>
							</Stack>
						</Grid>
					))}
					{/* <p style={{ color: '#d32f2f' }}>{!isRowsValid ? 'Seleccione mínimo 1 y máximo 3 cargos' : ''}</p> */}
				</Grid>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={() => handleNext()}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
