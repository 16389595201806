import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const VERIFY_TOKEN_URL = `${API_URL}/verify_token`;
export const REFRESH_TOKEN_URL = `${API_URL}/refresh_token`;
export const LOGIN_URL = `${API_URL}/auth`;
export const LOGOT_URL = `${API_URL}/logout`;
export const REGISTER_URL = `${API_URL}/user`;
export const RESET_PASSWORD_URL = `${API_URL}/reset_pass`;
export const CANDIDATO_MATCHING = `${API_URL}/match/candidatos`;
export const VACANTE_MATCHING = `${API_URL}/match/vacantes`;
export const CURRICULO_PDF = `${API_URL}/cv_pdf`;
export const VERIFY_DOCUMENT = `${API_URL}/user/verifyDocument`;
export const VERIFY_EMAIL = `${API_URL}/user/verifyEmail`;
export const VERIFY_COMPANY_DOCUMENT = `${API_URL}/empresa/verifyCompanyDocument`;
export const UPLOAD_FILE_COMPANY = `${API_URL}/empresa/archivo`;

// Server should return AuthModel
export function login(numero_doc: string, password: string, tipo_doc: string) {
	const loginData: any = {};
	if (tipo_doc === 'EMAIL') {
		loginData.email = numero_doc;
	} else {
		loginData.numero_doc = numero_doc;
	}
	loginData.password = password;

	return axios.post<AuthModel>(LOGIN_URL, loginData).then((d: any) => d.data);
}

// Server should return AuthModel
export function register(data: any) {
	return axios.post(REGISTER_URL, data).then((d: any) => d.data);
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function resetPassword(data: any) {
	return axios.post(RESET_PASSWORD_URL, data).then((d: any) => d.data);
}

export function verifyToken(token: string) {
	return axios.post(VERIFY_TOKEN_URL, {api_token: token}).then((d: any) => d.data);
}
export function refreshToken() {
	return axios.post(REFRESH_TOKEN_URL, {}).then((d: any) => d.data);
}

export async function getCandidatoMatching() {
	return axios.get(CANDIDATO_MATCHING).then((d: any) => d.data);
}

export async function getVacanteMatching() {
	return axios.get(VACANTE_MATCHING).then((d: any) => d.data);
}

export async function getCurriculoPDF(userCandidateId: any = undefined) {	
	const url = userCandidateId ? `${CURRICULO_PDF}?userCandidateId=${userCandidateId}` : CURRICULO_PDF;
	return axios.get(url, { responseType: 'blob' });
}

export async function getCandidatoMatchingByVacancy(query: string) {
	return axios.get(`${CANDIDATO_MATCHING}?${query}`).then((d: any) => d.data);
}

export async function getVerifyDocumentExists(query: string) {
	return axios.get(`${VERIFY_DOCUMENT}?${query}`).then((d: any) => d.data);
}

export async function getVerifyEmailExists(query: string) {
	return axios.get(`${VERIFY_EMAIL}?${query}`).then((d: any) => d.data);
}

export async function getVerifyCompanyDocumentExists(query: string) {
	return axios.get(`${VERIFY_COMPANY_DOCUMENT}?${query}`).then((d: any) => d.data);
}

export async function uploadFileCompany(data: any) {
	return axios.post(UPLOAD_FILE_COMPANY, data).then((d: any) => d.data);
}

export function logoutUser() {
	return axios.post(`${LOGOT_URL}`).then((d: any) => d);
}
