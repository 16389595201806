import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import Button from '@mui/material/Button';
import styleScss from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getVacanteMatching } from '../../modules/auth/core/_requests';
import {
	Box,
	LinearProgress,
	Tooltip,
	TooltipProps,
	Typography,
	tooltipClasses,
	styled,
} from '@mui/material';
import { OverlayTrigger, Tooltip as BootTip } from 'react-bootstrap';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';

const CandidateMatchingVacancyPage = () => {
	const navigate = useNavigate();
	const [data, setData]: any[] = useState([]);
	const [loading, setLoading] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [idVaga, setIdVaga] = useState<string>('');
	const openMenu = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
		setAnchorEl(event.currentTarget);
		setIdVaga(id);
	};
	const handleCloseMenu = () => {
		setIdVaga('');
		setAnchorEl(null);
	};

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	async function getVacantes() {
		setLoading(true);
		try {
			const response = await getVacanteMatching();
			if (response && response.data && response.data.results) {
				setData(response.data.results);
			} else {
				setData([]);
			}
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getVacantes();
	}, []);

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	/**
	 * Valida si la vacante esta abierta o cerrada
	 * @param date 
	 * @returns 
	 */
	const checkVacanteIsOpen = (vacancy: any) => {
		if (!vacancy['fecha_limite_aplicacion'])
			return true
		else
			return moment(vacancy['fecha_limite_aplicacion']).isSameOrAfter(new Date(), 'day');
	}

	return (
		<>

			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div
								className='col-xs-2 col-sm-1 col-md-1'
								style={{
									display: 'contents',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<BackButton />
							</div>
						</div>
					</div>
				</div>

				{loading ? (
					<LinearLoadingSpinner isLoading={loading} />
				) : (
					<>
						<div className='row row-cols-1 row-cols-md-3 g-4' style={{ textAlign: 'center' }}>
							{data && data?.length > 0 ? (
								data.map((x: any, i: number) => (
									<div className='col' key={i} id={x.id_vacante}>
										<div
											className='card h-100'
											style={{ borderRadius: '10px', backgroundColor: '#F3F6F9' }}
										>
											<div className='card-body'>
												<h5 className='card-title'>{x.codigo_vacante ? x.codigo_vacante : x.razon_social}</h5>
												<h6 className={`card-text`}>{x.nombre_vacante}</h6>
												<OverlayTrigger
													placement='left'
													delay={{ show: 250, hide: 400 }}
													overlay={(props) => (
														<BootTip id='tooltip' {...props}>
															<div dangerouslySetInnerHTML={{ __html: x.Describa_vacante }} />
														</BootTip>
													)}
												>
													<p className={`card-text text-primary`}>
														<i className='bi bi-question-circle-fill'
															style={{ color: '#2266cc' }}></i>
														{'  '}{x.Describa_vacante}{' '}
													</p>
												</OverlayTrigger>
												<div className={`card-text mt-2`}>
													<OverlayTrigger
														placement='left'
														delay={{ show: 250, hide: 400 }}
														overlay={(props) => (
															<BootTip id='tooltip' {...props}>
																{`La matching con esta vacante es del ${Math.round(x.matching)}%`}
															</BootTip>
														)}
													>
														<Box sx={{ position: 'relative', display: 'inline-flex' }}>
															<CircularProgress variant='determinate' value={x.matching ? Number(x.matching) : 0} />
															<Box
																sx={{
																	top: 0,
																	left: 0,
																	bottom: 0,
																	right: 0,
																	position: 'absolute',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<Typography
																	variant='caption'
																	component='div'
																	color='text.secondary'
																>{`${Math.round(x.matching)}%`}</Typography>
															</Box>
														</Box>
													</OverlayTrigger>
												</div>
											</div>
											<div style={{ marginTop: '-20px' }}>
												<div className='container'>
													<span className={`badge ${styleScss[`text-${checkVacanteIsOpen(x) ? 'abierta' : 'cerrada'}`]}`}
														style={{ float: 'left' }}
													>
														{checkVacanteIsOpen(x) ? <LockOpenIcon /> : <LockIcon />}{' '}
														{checkVacanteIsOpen(x) ? 'Abierta' : 'Cerrada'}
													</span>
													<Link to={`/candidato/vacante/${x.id_vacante}`}>
														<Button
															id='basic-button'
															sx={{
																float: 'right',
																backgroundColor: '#2266CC',
																color: '#FFFF',
																':hover': {
																	bgcolor: '#6c757d',
																	color: 'white',
																},
																textTransform: 'none'
															}}
														>
															Ver más
														</Button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<>
									<div
										className='alert alert-info'
										role='alert'
										style={{
											marginTop: '10%',
											textAlign: 'center',
											width: '50%',
											fontSize: '12pt',
											marginLeft: '20%',
										}}
									>
										No se encontraron resultados
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

const CandidateMatchingVacancyWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Vacantes recomendadas</PageTitle>
			<CandidateMatchingVacancyPage />
		</>
	);
};

export { CandidateMatchingVacancyWrapper };
