import React, { useState, useEffect, useRef } from 'react'
import { PageTitle } from "../../../_metronic/layout/core";
import { LinearLoadingSpinner, LoadingBackdrop } from "../../components/LoadingSpinner/LoadingSpinner";
import { useAuth } from "../../modules/auth";
import BackButton from '../../components/BackButton/BackButton';
import { Alert, AlertTitle, Box, Button, FormControlLabel, Grid, Menu, Paper, Snackbar, Switch, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import styleScss from './styles.module.scss'


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getListaDeParametros, getListaDeValores, getValores, postCrearValor, putValor, } from '../../modules/apps/user-management/users-list/core/_requests';
import { Table } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



const ValueListPanel = () => {
    const { currentUser, isAdminRole, isCounselorCandidateRole, isCounselorEmployerRole, } = useAuth();
    const inputRef = useRef<any>(undefined);

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [requestInProgress, setRequestInProgress] = useState(true);






    const [formularios, setFormularios] = useState<any>([]);
    const [selectedFormulario, setSelectedFormulario] = useState<any>("");

    const [listaDeValores, setListaDeValores] = useState<any>([])
    const [selectedListaDeValor, setSelectedListaDeValor] = useState<any>('')

    const [inputValueCrear, setInputValueCrear] = useState("");



    // Modals
    const [openCrearValor, setOpenCrearValor] = useState<any>(false);
    const [openModificar, setOpenModificar] = useState<any>(false);
    const [openActivarInactivar, setOpenActivarInactivar] = useState<any>(false);

    const [data, setData] = useState<any>()

    // Crear una valor
    const handleOpenCrearValor = () => {
        setOpenCrearValor(true);
    };

    const handleCloseCrearValor = () => {
        setOpenCrearValor(false);
    };

    // Modificar una valor
    const handleOpenModificar = (
        data: any) => {
        setData(data)
        setOpenModificar(true);
    };

    const handleCloseModificar = () => {
        setOpenModificar(false);
    };

    // Activar o Inactivar valor 
    const handleOpenActivarInactivar = (data: any) => {
        setData(data)
        setOpenActivarInactivar(true);
    };

    const handleCloseActivarInactivar = () => {
        setOpenActivarInactivar(false);
    };

    // Modals


    // acciones de los botones
    const [tablaDeValores, setTablaDeValores] = useState<any>()
    const [reload, setReload] = useState(false)


    useEffect(() => {
        if (selectedFormulario && selectedListaDeValor) {
            handleGetListas()
        }
    }, [selectedFormulario, selectedListaDeValor, reload])



    const handleCloseAlert = () => {
        setOpen(false);
    };


    // getParametos
    // const TYPE_LISTA_VALORES = {
    //     LISTA_VALORES_FORMULARIO: "LISTA_VALORES_FORMULARIO",
    //     LISTA_VALORES: "LISTA_VALORES"
    //   }
    const handleGetParametros = async () => {
        try {
            const response: any = await getListaDeParametros('LISTA_VALORES_FORMULARIO');
            setFormularios(response.data)
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }


    const handleGetListaDeValores = async () => {
        try {

            // const response: any = await getListaDeValores(selectedFormulario);
            const response: any = await getListaDeParametros('LISTA_VALORES', selectedFormulario);
            setListaDeValores(response.data)
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }

    const handleActivaInactivarValor = async () => {
        try {
            const response: any = await putValor(data.id, { estado: data.estado });
            setListaDeValores(response.data)
            setOpen(true);
            setAlert(true);
            // setAlertMsg(`Valor ${data.estado} con exito.`);
            // La opción de respuesta ha sido Inactivada correctamente en la lista de valores
            // La opción de respuesta ha sido activada correctamente en la lista de valores
            setAlertMsg(`La opción de respuesta ha sido ${data.estado === 'activo' ? 'activada' : 'inactivada'} correctamente en la lista de valores`);
            handleCloseActivarInactivar()
            setReload(prev => !prev);
            setTimeout(() => {
                setOpen(false);
            }, 5000);


        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }

    const handleModificarValor = async () => {
        const valor = inputRef.current.value
        try {
            const response: any = await putValor(data.id, { estado: data.estado, valor });
            setListaDeValores(response.data)
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Valor Modificado con exito.`);
            handleCloseModificar()
            setReload(prev => !prev);
            setTimeout(() => {
                setOpen(false);
            }, 5000);


        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }

    const handleCrearValor = async () => {
        const valor = inputRef.current.value
        try {
            const response: any = await postCrearValor({ selectedFormulario, selectedListaDeValor, valor });
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Valor Creado con exito.`);
            handleCloseCrearValor()
            setReload(prev => !prev);
            setTimeout(() => {
                setOpen(false);
            }, 5000);

        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }

    const handleGetListas = async () => {
        try {
            const response: any = await getValores({ selectedFormulario, selectedListaDeValor, });

            setTablaDeValores(response.data.data)


        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud con la API de parametros.', error);
            }
        }
    }

    useEffect(() => {
        handleGetParametros()
        setRequestInProgress(false)
        setLoading(false)
    }, [])

    useEffect(() => {
        if (selectedFormulario) {
            handleGetListaDeValores()
        }
    }, [selectedFormulario])


    const handleChangeFormularios = (event: any) => {
        setSelectedFormulario(event.target.value);
    };

    const handleChangeListaDeValores = (event: any) => {
        setSelectedListaDeValor(event.target.value);
    };



    const MenuAction = (props: any) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const handleClick = (e: any) => {
            setAnchorEl(e.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const { id,
            estado,
            id_formulario,
            id_lista_valor,
            valor, } = props.data;

        return (
            <>
                <Button onClick={handleClick}>
                    <i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
                </Button>
                <Menu
                    id='card-actions-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {estado !== 'inactivo' && <MenuItem>
                        <Button onClick={() => { handleOpenModificar({ id, estado, id_formulario, id_lista_valor, valor }) }}
                        >
                            &nbsp; Modificar Valor
                        </Button>
                    </MenuItem>
                    }
                    {
                        estado === 'inactivo' ?
                            <MenuItem>
                                <Button onClick={() => { handleOpenActivarInactivar({ id, estado, id_formulario, id_lista_valor, valor }) }}>
                                    &nbsp;  Activar valor
                                </Button>
                            </MenuItem>
                            : null
                    }

                    {
                        estado === 'activo' ?
                            <MenuItem>
                                <Button onClick={() => { handleOpenActivarInactivar({ id, estado, id_formulario, id_lista_valor, valor }) }}>
                                    &nbsp;  Inactivar valor
                                </Button>
                            </MenuItem> : null
                    }



                </Menu>
            </>
        );
    };


    const handleSwitchChange = () => {
        setData((prevData: any) => ({
            ...prevData,
            estado: prevData.estado === "activo" ? "inactivo" : "activo",
        }));
    };

    return (
        <>
            {
                <div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <div className='input-group d-flex' style={{ width: '100%' }}>
                                <div style={{ padding: '10px 0px 0px 0px' }}>
                                    <div className='col-xs-2 col-sm-1 col-md-1'
                                        style={{
                                            display: 'contents',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} >
                                        <BackButton />

                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 1px 1px 15px' }}>

                                </div>
                            </div>
                        </div>
                    </div>
                    {loading && <LinearLoadingSpinner isLoading={loading} />}
                    {
                        !loading &&
                        <div className="container">


                            <Grid container direction='row' justifyContent='space-between' width={'100%'}>
                                <Grid lg={5} md={12} sm={12} mb={5} pr={2} item={true}>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">FORMULARIOS</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedFormulario}
                                            label="FORMULARIOS"
                                            onChange={handleChangeFormularios}
                                        >
                                            {formularios.map((formulario: any) => (
                                                <MenuItem key={formulario.id} value={formulario.id}>
                                                    {formulario.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid lg={5} md={12} sm={12} mb={5} pr={2} item={true}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">LISTA DE VALORES</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label2"
                                            id="demo-simple-select2"
                                            value={selectedListaDeValor}
                                            label="LISTA DE VALORES"
                                            onChange={handleChangeListaDeValores}
                                            displayEmpty
                                        >
                                            {listaDeValores.length > 0 ? (
                                                listaDeValores.map((valores: any) => (
                                                    <MenuItem key={valores.id} value={valores.id}>
                                                        {valores.value}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No hay lista de valores</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid lg={2} md={12} sm={12} mb={5} pr={2} item={true} display="flex"
                                    alignItems="center"
                                    justifyContent="center">
                                    {
                                        selectedFormulario && selectedListaDeValor ?
                                            <Button variant="contained" size="large" onClick={handleOpenCrearValor}>
                                                Agregar Valor
                                            </Button>
                                            : null
                                    }
                                </Grid>





                            </Grid>

                            <Grid container spacing={3} justifyContent="center" alignItems="center">
                                {
                                    selectedFormulario && selectedListaDeValor ?
                                        <div className={`${styleScss['table2']} ${styleScss['table-limit']} container`} style={{ width: '100%' }}>
                                            <TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
                                                <Table style={{ overflowY: 'visible' }}>
                                                    <TableHead className={`${styleScss['th-custom']}`}>
                                                        <TableRow>
                                                            <TableCell
                                                                style={{
                                                                    fontFamily: 'Poppins',
                                                                    fontSize: '16.25px',
                                                                    textAlign: 'center',
                                                                    paddingLeft: 10,
                                                                    borderBottom: '2px solid #ccc', // Añadir línea divisoria en la parte inferior
                                                                }}
                                                            >
                                                                <h4>Valores</h4>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontFamily: 'Poppins',
                                                                    fontSize: '16.25px',
                                                                    textAlign: 'center',
                                                                    overflowWrap: 'break-word',
                                                                    borderBottom: '2px solid #ccc', // Añadir línea divisoria en la parte inferior
                                                                }}
                                                            >
                                                                <h4>Estado</h4>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontFamily: 'Poppins',
                                                                    fontSize: '16.25px',
                                                                    textAlign: 'center',
                                                                    overflowWrap: 'break-word',
                                                                    borderBottom: '2px solid #ccc', // Añadir línea divisoria en la parte inferior
                                                                }}
                                                            >
                                                                <h4>Acciones</h4>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {tablaDeValores?.map((row: any) => (
                                                            <TableRow key={row.id} style={{ borderBottom: '1px solid #ddd' }}> {/* Línea divisoria entre filas */}
                                                                <TableCell
                                                                    style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '14.5px',
                                                                        overflowWrap: 'break-word',
                                                                        wordBreak: 'break-all',
                                                                        whiteSpace: 'normal',
                                                                        textAlign: 'center',
                                                                        maxWidth: '250px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        paddingLeft: 10,
                                                                        borderRight: '1px solid #ddd', // Línea divisoria entre celdas
                                                                    }}
                                                                >
                                                                    {row.valor} {/* Aquí se muestra el valor de cada fila */}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '14.5px',
                                                                        overflowWrap: 'break-word',
                                                                        wordBreak: 'break-all',
                                                                        whiteSpace: 'normal', // Permite el salto de línea si es necesario
                                                                        textAlign: 'center',
                                                                        borderLeft: '1px solid #ddd', // Línea divisoria entre celdas
                                                                    }}
                                                                >
                                                                    {row.estado} {/* Aquí se muestra el estado de cada fila */}
                                                                </TableCell>

                                                                <TableCell style={{ textAlign: 'center' }}>
                                                                    <MenuAction
                                                                        data={{
                                                                            id: row.id,
                                                                            estado: row.estado,
                                                                            id_formulario: row.id_formulario,
                                                                            id_lista_valor: row.id_lista_valor,
                                                                            valor: row.valor,
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        : null
                                }


                            </Grid>


                        </div>
                    }

                </div>
            }

            {/* Mondal para crear un valor */}
            <Dialog
                open={openCrearValor}
                onClose={handleCloseCrearValor}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Crear un nuevo valor"}
                </DialogTitle>
                <DialogContent>


                    <TextField
                        multiline
                        id="outlined-basic"
                        label="Por favor escribe el nuevo valor. "
                        variant="outlined"
                        inputRef={inputRef}
                        sx={{
                            width: 300,
                            height: 50,
                            marginTop: 2
                        }}
                    />



                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseCrearValor}>Cancelar</Button>
                    <Button variant="contained" onClick={handleCrearValor} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal para Modificar un valor */}
            <Dialog
                open={openModificar}
                onClose={handleCloseModificar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Modificar un valor"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" mb={1}>
                        <b> {`El valor actual es: ${data?.valor}`}</b>


                    </DialogContentText>

                    <TextField
                        multiline
                        id="outlined-basic"
                        label="Por favor escribe el nuevo valor. "
                        variant="outlined"
                        inputRef={inputRef}
                        sx={{
                            width: 300,
                            height: 50,
                            marginTop: 2
                        }}
                    />



                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseModificar}>Cancelar</Button>
                    <Button variant="contained" onClick={handleModificarValor} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal para activar o inactovar un valor  */}
            <Dialog
                open={openActivarInactivar}
                onClose={handleCloseActivarInactivar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Activar o Inactivar este valor"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b> {`El valor ${data?.valor}`}</b>
                    </DialogContentText>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={data?.estado === "activo"}
                                onChange={handleSwitchChange}
                                color="primary"
                            />
                        }
                        label={data?.estado === "activo" ? "Activo" : "Inactivo"}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseActivarInactivar}>Cancelar</Button>
                    <Button variant="outlined" onClick={handleActivaInactivarValor} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Toats  */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                    {alertMsg}
                </Alert>
            </Snackbar>
        </>
    )
}

const ListsOfValues = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Panel de Gestión de lista de valores.</PageTitle>
            <ValueListPanel />
        </>
    );
};

export { ListsOfValues };
