import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../../modules/auth/core/Auth';
import {
	getCandidatoMatching,
	getCandidatoMatchingByVacancy,
} from '../../modules/auth/core/_requests';
import { OverlayTrigger, Tooltip as BootTip } from 'react-bootstrap';
import { truncateWords } from '../../util/Index';
import Button from '@mui/material/Button';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

const EmployerMatchingCandidatePage = () => {
	const { id } = useParams();
	const { currentUser, auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [candidates, setCandidates] = useState<any[]>();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [tituloVacancy, setTituloVacancy] = useState('');

	const requestByVacante = id !== undefined && parseInt(id) > 0 ? true : false;

	async function getCandidatos() {
		setLoading(true);
		try {
			const response = requestByVacante ? await getCandidatoMatchingByVacancy(`vacante=${id}`) : await getCandidatoMatching();
			if (response && response?.data) {
				setCandidates(response.data.results ? response.data.results : []);
				if (requestByVacante) {
					setTituloVacancy(response?.data?.results && response?.data?.results?.length > 0 ? ` - ${response?.data?.results[0].codigo_vacante}` : '');
				}
			} else {
				setCandidates([]);
			}
		} catch (error: any) {
			if (error.response) {
				setMessage(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	}

	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		getCandidatos();
	}, []);

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	/**
	* Retorna el path del usuario acorde al rol para ver resumen de la hoja de vida del candidato postulado
	* @returns 
	*/
	const getPathVerCandidato = (opt: any, x: any) => {
		if (isAdminRole()) {
			return opt === 1 ? `/admin/hoja-vida/${x.id_candidato}/${x.idEmpresa}/${x.id_vacante}` : `/admin/hoja-vida/${x}/0/0`;
		} else if (isCounselorEmployerRole()) {
			return opt === 1 ? `/consejero/hoja-vida/${x.id_candidato}/${x.idEmpresa}/${x.id_vacante}` : `/consejero/hoja-vida/${x}/0/0`;
		} else if (isEmployerRole()) {
			return opt === 1 ? `/empleador/hoja-vida/${x.id_candidato}/${x.idEmpresa}/${x.id_vacante}` : `/empleador/hoja-vida/${x}/0/0`;
		} else {
			return 'auth/*';
		}
	}

	return (
		<>
			{/* <!-- Jumbotron --> */}
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div className='col-xs-2 col-sm-1 col-md-1'>
								<button
									onClick={() => {
										navigate(-1);
									}}
									className='btn btn-primary'
									style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
								>
									<i
										className='bi bi-arrow-left'
										style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
									></i>
								</button>
							</div>
							<div className='col-xs-12 col-sm-11 col-md-11' style={{ padding: '0px 0px 0px 5px', lineHeight: '0.5em' }}>
								<h3 style={{}}>Matching de los candidatos {tituloVacancy} </h3>
								<span style={{}}>Haz clic en el candidato para ver los detalles.</span>
							</div>
						</div>
					</div>
				</div>

				{loading ? (
					<LinearLoadingSpinner isLoading={loading} />
				) : (
					<div className='row row-cols-1 row-cols-md-3 g-4' style={{ textAlign: 'center' }}>
						{candidates !== undefined && candidates?.length > 0 ? (
							candidates?.map((x, i) => (
								<div className='col' key={i} id={x.id_candidato}>
									<div className='card h-100' style={{ borderRadius: '10px', backgroundColor: '#F3F6F9' }}>
										<div className='card-body'>
											<h5 className='card-title'>{truncateWords(x.candidato, 50)}</h5>
											{requestByVacante ? '' : <h6 className='card-title'>{x.codigo_vacante ? x.codigo_vacante : x.nombre_vacante}</h6>}
											<OverlayTrigger
												placement='left'
												delay={{ show: 250, hide: 400 }}
												overlay={(props) => (
													<BootTip id='tooltip' {...props}>
														<div dangerouslySetInnerHTML={{ __html: x.perfil_laboral }} />
													</BootTip>
												)}
											>
												<p className={`card-text text-primary`}>
													<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
													{'  '}{x.perfil_laboral}{' '}
												</p>
											</OverlayTrigger>
											<div className={`card-text mt-2`}>
												<OverlayTrigger
													placement='left'
													delay={{ show: 250, hide: 400 }}
													overlay={(props) => (
														<BootTip id='tooltip' {...props}>
															{`El matching con este candidato es del ${Math.round(x.matching)}%`}
														</BootTip>
													)}
												>
													<Box sx={{ position: 'relative', display: 'inline-flex' }}>
														<CircularProgress variant='determinate' value={x.matching ? Number(x.matching) : 0} />
														<Box
															sx={{
																top: 0,
																left: 0,
																bottom: 0,
																right: 0,
																position: 'absolute',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
															}}
														>
															<Typography
																variant='inherit'																
																component='div'
																color='text.secondary'
																sx={{ fontSize: 14}}
															>{`${Math.round(x.matching)}%`}</Typography>
														</Box>
													</Box>
												</OverlayTrigger>
											</div>
										</div>
										<div style={{ marginTop: '-20px' }}>
											<div className='container'>
												<Link to={getPathVerCandidato(1, x)}>
													<Button
														id='basic-button'
														sx={{
															float: 'right',
															backgroundColor: '#2266CC',
															color: '#FFFF',
															':hover': {
																bgcolor: '#6c757d',
																color: 'white',
															},
															textTransform: 'none'
														}}>
														Ver más
													</Button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<>
								<div
									className='alert alert-info'
									role='alert'
									style={{
										marginTop: '10%',
										textAlign: 'center',
										width: '50%',
										fontSize: '12pt',
										marginLeft: '20%',
									}}
								>
									No se encontraron resultados
								</div>
							</>
						)}
					</div>
				)}
			</div>
			{/* <PaginationComponent totalPage={10} total={10} customClass={''} paginate={changePaginate} /> */}
			{/* <!-- Jumbotron --> */}
		</>
	);
};

const EmployerMatchingCandidateWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Mejores Candidatos</PageTitle>
			<EmployerMatchingCandidatePage />
		</>
	);
};

export { EmployerMatchingCandidateWrapper };
