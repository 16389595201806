import { useEffect, useState } from "react"
import { ParameterConfigMmodel } from "../../../models/parameter.model"
import parameters from "../../../util/parameters";
import parameterSingleton from "../../../services/Parameters.service";


const useInfoAdicionalParams = () => {

    const [listFuentes, setListFuentes] = useState<ParameterConfigMmodel[]>([]);
    const [listTipoConvocatoria, setListTipoConvocatoria] = useState<ParameterConfigMmodel[]>([]);
	const [listSiNo, setListSiNO] = useState<ParameterConfigMmodel[]>([]);
	const [loadingParams, setloadingParams] = useState(true);


    useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		setloadingParams(true);
		await fetchListTipoConvocatoria();
		await fetchListFuente();
		await fetchListSINO();
		setloadingParams(false);
	};

	const fetchListTipoConvocatoria = async () => {
		const res = await parameterSingleton.fetchData(parameters.TIPOCONVOCATORIA);
		setListTipoConvocatoria(res);	
	}

	
	const fetchListSINO = async () => {
		const res = await parameterSingleton.fetchData(parameters.SINO);
		setListSiNO(res);	
	}
	const fetchListFuente = async () => {
		const res = await parameterSingleton.fetchData(parameters.FUENTE);
		setListFuentes(res);
	};


    return {
		listFuentes,
		listTipoConvocatoria,
		listSiNo,
		loadingParams
    }

}

export default useInfoAdicionalParams;