import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { getUserCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { getCurriculoPDF } from '../../modules/auth/core/_requests';
import BackButton from '../../components/BackButton/BackButton';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { concatArrayToStringJoinBy, initValue } from '../../util/Index';
import { TipoEstadoHv } from '../../models/TipoEstadoHvModel'
import {
	Chip,
	Alert,
	AlertTitle,
	Snackbar,
	Stack,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	TablePagination,
	IconButton,
	Paper,
	FormControl,
	InputLabel,
	Select
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const ManagementCandidatosPage = () => {
	const { currentUser, isAdminRole, isCounselorCandidateRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
		estadohv: '',
	});
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, name: filter.name, estadohv: filter.estadohv };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), name: filter.name, estadohv: filter.estadohv }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};

	const handleCloseAlert = () => {
		setOpen(false);
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await getUserCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}&nome=${userData.nombre_candidato?.trim()}&estadohv=${filter.estadohv}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setOpen(true);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}

		} finally {
			setLoading(false);
		}
	};

	//Genera la hoja de vida en formato PDF para el candidato
	async function getCurriculo(id: any) {
		try {
			setRequestInProgress(true);
			const candidate = data.find((o: any) => o.id === id);
			if (candidate.UserCurriculo?.id && candidate.UserCurriculo?.porcentaje_diligenciamiento >= 100) {
				const response = await getCurriculoPDF(id);
				const url = window.URL.createObjectURL(
					new Blob([response.data], { type: 'application/pdf' })
				);
				const link = document.createElement('a');
				link.href = url;
				//link.setAttribute('download', `hoja_de_vida_.pdf`); //Opção para download direto do PDF
				link.setAttribute('target', '_blank'); // Abre o PDF em uma nova aba
				link.setAttribute('rel', 'noopener noreferrer'); // Adiciona o atributo rel para segurança
				document.body.appendChild(link);
				link.click();
			} else {
				setAlert(false);
				setAlertMsg('Debe completar el registro de su hoja de vida!');
				setOpen(true);
				return;
			}
		} catch (error: any) {
			if (error.response) {
				const data = JSON.parse(await error.response.data.text());
				console.error(data.error)
				setAlert(false);
				setOpen(true);
				setAlertMsg(`${data.error}`);
			} else {
				// Erro de rede ou outro erro inesperado
				console.error('Ocurrio un error procesando la solicitud.');
			}
			return;
		} finally {
			setRequestInProgress(false);
		}
	}

	/**
 * Renderiza un botón con tres puntos que abre un menú con diferentes opciones basadas en 
 * los datos pasados en la variable props.
 *
 * @param {object} props - Contiene los datos que se utilizarán para renderizar las 
 * opciones del menu según la información pasada.
 * @returns {JSX.Element} Componente MenuAction.
 */
	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const { data } = props;

		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<>
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>

				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{data.UserCurriculo?.id && (
						[
							<MenuItem onClick={handleClose} key='view'>
								<Link to={getPathVerHVCandidato(data.id)}>
									<VisibilityIcon />
									&nbsp; Ver hoja de vida
								</Link>
							</MenuItem>,
							<MenuItem onClick={handleClose} key='edit'>
								<Link to={getPathGestionarHVCandidato(data.id)}>
									<NoteAltIcon />
									&nbsp; Editar hoja de vida
								</Link>
							</MenuItem>
						]
					)}

					{data.UserCurriculo?.id && data.UserCurriculo?.porcentaje_diligenciamiento >= 100 && (
						<MenuItem onClick={handleClose} key='pdf'>
							<Link to={''} onClick={() => getCurriculo(data.id)}>
								<PictureAsPdf />
								&nbsp; Generar hoja de vida PDF
							</Link>
						</MenuItem>
					)}

					{(data.UserCurriculo?.id && data.UserCurriculo?.porcentaje_diligenciamiento >= 100 && isCounselorCandidateRole()) && (
						<MenuItem onClick={handleClose} key='postular'>
							<Link to={getPathVacantesCandidato(data.UserCurriculo.id_users)}>
								<FindInPageIcon />
								&nbsp; Postular candidato
							</Link>
						</MenuItem>
					)}

					{(data.UserCurriculo?.id && data.UserCurriculo?.porcentaje_diligenciamiento >= 100 && (isCounselorCandidateRole())) && (
						<MenuItem onClick={handleClose} key='postulaicones'>
							<Link to={getPathPostulacionesCandidato(data.UserCurriculo.id_users)}>
								<FormatListBulletedIcon />
								&nbsp; Ver Postulaciones
							</Link>
						</MenuItem>
					)}


					{data.UserCurriculo === null && (
						<MenuItem onClick={handleClose}>
							<Link to={getPathGestionarHVCandidato(data.id)}>
								<NoteAddIcon />
								&nbsp; Crear hoja de vida
							</Link>
						</MenuItem>
					)}

				</Menu >
			</>
		)
	}

	/**
		* Retorna el path del usuario acorde al rol para ver resumen de la hoja de vida del candidato postulado
		* @returns
		*/
	const getPathNuevoUsuario = () => {
		if (isAdminRole()) {
			return `/admin/nuevo-usuario`;
		} else if (isCounselorCandidateRole()) {
			return `/consejero/nuevo-usuario`;
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path para que el consejero visualize las vacantes a las que puede postular un candidato
	 * @param idCandidato 
	 * @returns 
	 */
	const getPathVacantesCandidato = (idCandidato: number) => {
		if (isCounselorCandidateRole()) {
			return `/consejero/search-vacantes/${idCandidato}`;
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path para ver las postulaciones del candidato
	 * @param idCandidato 
	 * @returns 
	 */
	const getPathPostulacionesCandidato = (idCandidato: number) => {
		if (isCounselorCandidateRole()) {
			return `/consejero/postuladas-vacantes/${idCandidato}`;
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */
	const getPathVerHVCandidato = (idCandidato: number) => {
		if (isAdminRole()) {
			return `/admin/hoja-vida/${idCandidato}`;
		} else if (isCounselorCandidateRole()) {
			return `/consejero/hoja-vida/${idCandidato}`;
		} else {
			return 'auth/*';
		}
	}

	/**
 * Retorna el path del usuario acorde al rol
 * @returns 
 */
	const getPathGestionarHVCandidato = (idCandidato: number) => {
		if (isAdminRole()) {
			return `/admin/crear-hoja-vida/${idCandidato}`;
		} else if (isCounselorCandidateRole()) {
			return `/consejero/crear-hoja-vida/${idCandidato}`;
		} else {
			return 'auth/*';
		}
	}

	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		setPage(0);
		setFilter({ ...filter, page: 1 });
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, nombre_candidato: event.target.value });
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	return (
		<>
			<LoadingBackdrop loading={requestInProgress} />
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 1px 1px 15px' }}>
								<div className='input-group mb-3'>
									<FormControl sx={{ minWidth: 120 }} fullWidth size="small">
										<InputLabel id='estado_hv'
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Estado CV</InputLabel>
										<Select
											labelId='estado_hv'
											id='estado_hv'
											label='Estado CV'
											value={initValue(filter.estadohv)}
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
											onChange={(e) => {
												setPage(0);
												setFilter({
													...filter,
													page: 1,
													estadohv: e.target.value
												});
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{TipoEstadoHv.map((item) => (
												<MenuItem key={item.code} value={item.code}>
													{item.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className='input-group mb-3'>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Busca por Documento/Candidato/Correo'
										aria-label='Busca por Documento/Candidato/Correo'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchCandidato'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							<div className='col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<Link to={getPathNuevoUsuario()}
									state='gestion-candidatos'
									className='btn btn-primary btn-lg'
									role="button">Registrar candidato</Link>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.lenght !== 0 && (
					<div className={`${styleScss['table-limit']}`}>
						<Stack padding={2} >
							<TableContainer component={Paper} className={`${styleScss['table-limit']}`} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
								<Table style={{ overflowY: 'visible' }}>

									<TableHead className={`${styleScss['th-custom']}`}>
										<TableRow>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>CV creador por</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Candidato</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>N° Documento</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Correo electrónico</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Teléfono</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>¿Hay CV?</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>¿CV terminado?</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Fecha de creacion y modificación</h4>
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Acciones</h4>
											</TableCell>
										</TableRow>
									</TableHead>
									{!loading && <TableBody>
										{data.map((x: any, i: any) => (
											<TableRow key={i} id={i.toString()}>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													{x.UserCurriculo
														? `${x.UserCurriculo.createdByUser.primer_nome} ${x.UserCurriculo.createdByUser.primer_apelido}`
														: 'Por crear'}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', textAlign: 'center' }}>
													{concatArrayToStringJoinBy([x.UserComplemento?.primer_nome?.trim(), x.UserComplemento?.segundo_nome?.trim(), x.UserComplemento?.primer_apelido?.trim(), x.UserComplemento?.segundo_apelido?.trim()], ' ')}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													{x.UserComplemento?.numero_doc}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.email}</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.UserComplemento?.celular}</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													{x.UserCurriculo?.id ? (
														<Chip label='Sí' color='success' />
													) : (
														<Chip label='No' color='error' />
													)}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													{x.UserCurriculo?.porcentaje_diligenciamiento >= 100 ? (
														<Chip label='Sí' color='success' />
													) : (
														<Chip label='No' color='error' />
													)}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													{x.UserCurriculo ? (
														<>
															{new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium' }).format(new Date(x.UserCurriculo.createdAt))}
															<br />
															{new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium' }).format(new Date(x.UserCurriculo.updatedAt))}
														</>
													) : (
														'Por crear'
													)}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
													<MenuAction data={{ id: x.id, UserCurriculo: x.UserCurriculo }} />
												</TableCell>
											</TableRow>
										))}
									</TableBody>}
									{data.length === 0 && (
										<tfoot>
											<TableRow>
												<TableCell colSpan={8}>
													<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
														No se encontraron registros
													</p>
												</TableCell>
											</TableRow>
										</tfoot>
									)}
								</Table>
								<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
									<TablePagination
										rowsPerPageOptions={[10, 20, 50, 100]}
										component="div"
										style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
										count={total}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={(event, newPage) => handleChangePage(newPage)}
										onRowsPerPageChange={handleChangeRowsPerPage}
										labelRowsPerPage="Filas por página:"
										labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
										ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
											<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
												<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
													<FirstPageIcon />
												</IconButton>
												<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
													<ChevronLeftIcon />
												</IconButton>
												<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
													<ChevronRightIcon />
												</IconButton>
												<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
													<LastPageIcon />
												</IconButton>
											</div>
										)}
									/>
								</div>
							</TableContainer>
						</Stack>
					</div>
				)}
				{/* <PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/> */}
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleCloseAlert}
			>
				<Alert
					onClose={handleCloseAlert}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidatosManagementWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión de candidatos</PageTitle>
			<ManagementCandidatosPage />
		</>
	);
};

export { CandidatosManagementWrapper };
