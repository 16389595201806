import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PageTitle } from '../../../_metronic/layout/core';
import moment from 'moment';
import SavingsIcon from '@mui/icons-material/Savings';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CottageIcon from '@mui/icons-material/Cottage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { getVacancyId, registerForVacancy, getVacanteWordpress } from '../../modules/apps/user-management/users-list/core/_requests';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY, formatNumber, initValue, setValueOrDefault } from '../../util/Index';
import { useAuth } from '../../modules/auth';
import usePublicVacancyParams from '../../hooks/parameters/public-vacancy/usePublicVacancyParams';
import useBasicInfoVacancyPrarams from '../../hooks/parameters/vacancy-management/useBasicInfoVacancyPrarams'
import { Alert, CardHeader, IconButton, CardContent, Snackbar, AlertTitle } from '@mui/material';
import BackButton from '../BackButton/BackButton';
import { LoadingBackdrop, LinearLoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const VacancyPage = () => {
	const { id, id_candidato } = useParams();
	const { currentUser, isCandidateRole, isCounselorCandidateRole } = useAuth();
	const { listSalarioMensual, listTipoContrato } = usePublicVacancyParams();
	const { listPresencialidad } = useBasicInfoVacancyPrarams();


	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [postulateLabel, setPostulateLabel] = useState('Postúlate');
	const [postulateColor, setPostulateColor] = useState('#153E7B');
	const [descNivelEducativo, setDescNivelEducativo] = useState('');
	const [openInformationVacancy, setOpenInformationVacancy] = useState(true);
	const [openDescription, setOpenDescription] = useState(true);

	const [vacancy, setVacancy] = useState<any>({});
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [loading, setLoading] = useState(false);
	const baseUrl = process.env.PUBLIC_URL;
	const [postulado, setPostulado] = useState(false);


	const handlePostulateLabel = (data: any) => {
		if (data['UserCurriculos'].length > 0) {
			setPostulateLabel('Postulado');
			setPostulateColor('green');
			setPostulado(true);
		} else {
			setPostulateLabel(isCandidateRole() ? 'Postúlate' : 'Postular');
		}
	};

	useEffect(() => {
		getVacanteId();
	}, []);

	const getVacanteId = async () => {
		try {
			setLoading(true)
			if (!id) {
				throw new Error('La identificación no está definida');
			}
			const queryParam = isCounselorCandidateRole() ? `idUser=${id_candidato}` : undefined;
			const response = (currentUser === undefined) ? await getVacanteWordpress(`id=${id}`) : await getVacancyId(parseInt(id), queryParam);
			const formattedDate = moment(response.data.createdAt).format('DD/MM/YYYY HH:mm:ss');
			setVacancy({
				...response.data,
				createdAt: formattedDate,
			});
			setDescNivelEducativo(response.data.NivelEducativo.desc_nivel_educativo);
			if (currentUser !== undefined) await handlePostulateLabel(response.data);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false)
		}
	};

	const iconJornada = (mode: string) => {
		if (mode === '1')
			return (
				<>
					<WbSunnyIcon />
					&nbsp;Jornada laboral:&nbsp;Diurna
				</>
			);
		if (mode === '2')
			return (
				<>
					<BedtimeIcon />
					&nbsp;Jornada laboral:&nbsp;Nocturna
				</>
			);
		if (mode === '3')
			return (
				<>
					<NightsStayIcon />
					&nbsp;Jornada laboral:&nbsp;Mixta
				</>
			);

		return <></>;
	};

	const registerVacancy = async (): Promise<void> => {
		if (currentUser === undefined) {
			setAlert(false);
			setAlertMsg('Debes estar autenticado en el sistema para aplicar a la vacante');
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
				window.parent.location.href = `${baseUrl}/auth`;
			}, 4000);
		} else {
			try {
				setRequestInProgress(true);
				const register = { id_vagas: parseInt(setValueOrDefault(id, 'number')), id_candidato: isCandidateRole() ? null : parseInt(setValueOrDefault(id_candidato, 'number')) };
				const response = await registerForVacancy(register);
				setAlert(true);
				setAlertMsg(response.data.message);
				setOpen(true);
				setPostulateLabel('Postulado');
				setPostulateColor('green');
				setPostulado(true);
				setTimeout(() => {
					setRequestInProgress(false);
					navigate(-1);
				}, 5000);
			} catch (error: any) {
				if (error.response) {
					console.error('error', error.response.data.error);
					setAlert(false);
					setAlertMsg(`${error.response.data.error}`);
					setOpen(true);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
				setRequestInProgress(false);
			}
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * Valida si la vacante esta abierta o cerrada
	 * @param date 
	 * @returns 
	 */
	const checkVacanteIsOpen = () => {
		if (!vacancy['fecha_limite_aplicacion'])
			return true
		else
			return moment(vacancy['fecha_limite_aplicacion']).isSameOrAfter(new Date(), 'day');
	}

	/**
	 * Obtiene el label del tipo de vacante con relacion a la modalidad presencial o virtual
	 * @param value 
	 * @returns 
	 */
	const getLabelSusceptibleTeletrabajo = (value: any) => {
		const item = listPresencialidad.find((i) => i.code == value);
		return (item?.label || item?.value)?.toLowerCase();
	}

	const getIconSusceptibleTeleTrabajo = (value: any) => {
		//PRESENCIAL ó HIBRIDO
		return value == '0' || value == '3' ? (<ApartmentIcon />) : (<CottageIcon />)
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				{loading ? <LinearLoadingSpinner isLoading={loading} /> :
					<>
						<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
							<div className='row'>
								<div className='col-sm-12'>
									<div className='input-group'>
										<div
											className='col-sm-1'
											style={{
												display: 'contents',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<BackButton />
										</div>
										<div className='col-sm-10' style={{ padding: '10px 0px 0px 20px' }}>
											<span>
												<WatchLaterIcon sx={{ color: '#153E7B' }} />
												&nbsp;&nbsp;Publicada en: {convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(vacancy['createdAt'])} - Cierra el:{' '} {convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(vacancy['fecha_limite_aplicacion'])}
											</span>
										</div>
										<div className='col d-flex justify-content-end'>
											<span
												className='badge rounded-pill'
												style={{
													boxSizing: 'border-box',
													color: checkVacanteIsOpen() && initValue(vacancy['status']) ? 'black' : 'red',
													backgroundColor: '#DEEAFB',
													marginRight: '15px',
													padding: '5px 25px 5px 25px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '11pt',
													width: 'auto'
												}}
											>
												{!initValue(vacancy['status']) ? 'Inactiva' : (checkVacanteIsOpen() ? 'Abierta' : 'Cerrada')}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='row mt-10'>
								<div className='col-sm-10' style={{ lineHeight: '0.5em', overflowWrap: 'break-word' }}>
									<h3
										style={{
											display: 'flex',
											gap: '10px',
											wordBreak: 'break-all'
										}}
										title={initValue(vacancy['nombre_vacante'])}
									>
										{initValue(vacancy['nombre_vacante'])}
									</h3>
									{vacancy['codigo_vacante'] && (
										<h5 style={{ display: 'flex', gap: '10px' }}>
											{initValue(vacancy['codigo_vacante'])}
										</h5>
									)}
									<span style={{}}>
										{initValue(vacancy['Empresa']?.['razon_social']).toUpperCase()}
									</span>
								</div>
								<div className='col d-flex justify-content-end' style={{ marginRight: '15px' }}>
									{(checkVacanteIsOpen() && initValue(vacancy['status'])) && (
										<button
											className='btn btn-primary'
											style={{ padding: '8px 20px', background: postulateColor }}
											onClick={registerVacancy}
											disabled={requestInProgress || postulado == true}>
											{postulateLabel}
										</button>
									)}
								</div>
							</div>
							{/* Informaciónes de la vacante */}
							<div className='row mt-10'>
								<Card
									sx={{
										border: 'none',
										boxShadow: 'none',
										width: '100%',
									}}
								>
									<CardHeader
										title='Interés ocupacional'
										titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
										action={
											<IconButton
												onClick={() => setOpenInformationVacancy(!openInformationVacancy)}
												aria-label='expand'
												size='small'
											>
												{openInformationVacancy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
											</IconButton>
										}
									/>
									<div>
										<Collapse in={openInformationVacancy} timeout='auto' unmountOnExit>
											<CardContent>
												<hr style={{ marginTop: '-10px' }} />
												<div className='mt-10'>
													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
														}}
													>
														<SavingsIcon />
														&nbsp;Salario:&nbsp;
														{formatNumber(listSalarioMensual.find((x: any) => x.code === vacancy.salario_mensual)?.value || '')}
													</span>
													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
														}}
													>
														<ArticleIcon />
														&nbsp;Contrato:&nbsp;
														{listTipoContrato.find((x: any) => x.code === vacancy.tipo_contrato)?.value || ''}

													</span>
													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
														}}
													>
														<MenuBookIcon />
														&nbsp;Nivel de estudio: {descNivelEducativo}
													</span>
													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
														}}
													>
														<HistoryToggleOffIcon />
														&nbsp;Experiencia: {vacancy.rango_experiencia !== '' ? vacancy.rango_experiencia : 0} {vacancy.rango_experiencia <= 1 ? 'mes' : 'meses'}
													</span>
													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
															textTransform: 'capitalize'
														}}
													>
														{getIconSusceptibleTeleTrabajo(vacancy['vacante_susceptible_teletrabajo'])}
														&nbsp;
														{getLabelSusceptibleTeletrabajo(vacancy['vacante_susceptible_teletrabajo'])}
													</span>

													<span
														className='badge rounded-pill'
														style={{
															boxSizing: 'border-box',
															color: '#153E7B',
															border: '1px solid #153E7B',
															backgroundColor: 'transparent',
															marginRight: '15px',
															marginBottom: '10px',
															padding: '15px 30px 15px 30px',
															textAlign: 'center',
															height: '46px',
															alignContent: 'center',
															fontSize: '11pt',
														}}
													>
														{iconJornada(initValue(vacancy['jornada_laboral']))}
													</span>
												</div>
											</CardContent>
										</Collapse>
									</div>
								</Card>
							</div>
							{/* Descrição */}
							<div className='row'>
								<Card
									sx={{
										border: 'none',
										boxShadow: 'none',
										width: '100%',
									}}
								>
									<CardHeader
										title='Descripción'
										titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
										action={
											<IconButton
												onClick={() => setOpenDescription(!openDescription)}
												aria-label='expand'
												size='small'
											>
												{openDescription ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
											</IconButton>
										}
									/>
									<div>
										<Collapse in={openDescription} timeout='auto' unmountOnExit>
											<CardContent>
												<hr style={{ marginTop: '-10px' }} />
												<div className='row mt-10'>
													<span style={{
														maxWidth: '100%',
														textAlign: 'justify',
														overflowWrap: 'break-word'
													}}
													>{initValue(vacancy['Describa_vacante'])}
													</span>
												</div>
											</CardContent>
										</Collapse>
									</div>
								</Card>
							</div>
						</div>
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={open}
							onClose={handleClose}
						>
							<Alert
								onClose={handleClose}
								variant={'filled'}
								severity={alert ? 'success' : 'error'}
								sx={{ whiteSpace: 'pre-line' }}
							>
								{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
								{alertMsg}
							</Alert>
						</Snackbar>
					</>
				}
			</div>
		</>
	);
};

const VacancyComponent = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Vacante</PageTitle>
			<VacancyPage />
		</>
	);
};

export default VacancyComponent;