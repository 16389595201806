import { FormEvent, useContext, useState, useEffect, Key, ReactChild, ReactFragment, ReactPortal } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
    TextField,
    SelectChangeEvent,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Box,
    Button,
    Divider,
    Typography,
    Alert,
    Snackbar,
    AlertTitle,
    FormHelperText,
    InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import { useAuth } from '../../../../../../app/modules/auth';
import { useParams } from 'react-router-dom';
import { getCurriculoById } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
    formatMaskPhoneNumber12Digits,
    initValue,
    isValidEmail,
    isValidFieldRequired,
    isValidPhoneNumber,
    isValidMinMaxText,
    helperValidMinMaxText,
    isValidFirstSecondNameFirstSecondLastName,
    helperValidFirstSecondNameFirstSecondLastName,
    isValidColombianPhoneNumber,
    helperIsValidColombianPhoneNumber,
    isValidDocumentNumber,
    helperIsValidDocumentNumber,
    eventOnKeyDownInputNumber,
    concatArrayToStringJoinBy
} from '../../../../../util/Index';
import { createCurriculo } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
    CapacitacionCertificacionModel,
    ComplementoDirecaoModel,
    HerramientasModel,
    IdiomaModel,
    PuestosModel,
    NivelEducativoModel,
    ResumeModel,
    UserComplementoModel,
    ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import {
    setValueOrDefault,
    checkRulesByBirthDateAndDocType,
    checkRulesByCountryAndDocumentType
} from '../../../../../util/Index';
import useStep1Parameters from '../../../../../hooks/parameters/curriculum/useStep1Parameters';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import parameterSingleton from '../../../../../services/Parameters.service';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

const preload = [{ valid: false }];
const TOOL_TIP_TELEPHONE_TEXT: string = 'El número de teléfono debera ser de 7 dígitos Ej. (6010000) ó 10 dígitos Ej. (3220001100)';

export const Step1 = () => {
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [blockNacion, setBlockNacion] = useState(true);
    const [blockMuni, setBlockMuni] = useState(true);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const { setStep, userData, setUserData, loadedData, setLoadedData, setCurrentProgress, loading, setLoading } =
        useContext(multiStepContext);
    //PARAMETROS
    /****************************** */
    const {
        sexoList,
        generoList,
        otrosConocimientosList,
        siNoList,
        orientacionSexualList,
        paisList,
        departamentoList,
        estadoCivilList,
        tipoLibretaMilList,
        tipoSisbenList,
        tipoCuidadorList,
        nacionalidadList,
        listTipoDocumentoCandidato,
        loadingParams,
        listGrupoEtnico,
        listGrupoIndigena,
        listDiscapacidad,
        listPoblaciones
    } = useStep1Parameters();
    /****************************** */
    const [step, setStepUser] = useState<string>('step1');
    const { id } = useParams();
    const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
    const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
    const [documentTypeField, setDocumentTypeField] = useState('text');
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const [ddlMunicipioNacimiento, setDdlMunicipioNacimiento] = useState<any[]>([
        { code_dpto: '0', code: '0', value: 'Seleccione' }
    ]);
    const [ddlNacionalidad, setDdlNacionalidad] = useState([{ code: '0', value: 'Seleccione' }]);

    const CODIGO_SI = '1';
    const GRUPO_ETNICO_CODIGO_INDIGENA = '4';
    const GRUPO_ETNICO_NO_RECONOCE_NINGUNA = '7';//No se autorreconoce en ninguno de los anteriores
    const TIPO_POBLACION_CODIGO_RETORNADO = '11' //RETORNADO
    const [tipoPoblacionChecked, setTipoPoblacionChecked] = useState<string[]>([]);

    useEffect(() => {
        setUserData({ ...userData, id_users: id });
        if (!loadedData as boolean) {
            fetchData();
        } else {
            if (userData['municipio_nacimiento']) {
                fetchAndSetMunicipioById(userData['departamento_nacimiento']);
                setBlockMuni(false);
            } else {
                setDdlMunicipioNacimiento([]);
            }
            if (userData['nacionalidad']) {
                const listddlNacionalidad = nacionalidadList.filter(
                    (obj) => obj.code.indexOf(userData['pais_nascimento']) !== -1
                );
                setDdlNacionalidad(listddlNacionalidad);
                setBlockNacion(false);
            } else {
                setDdlNacionalidad([]);
            }
            //Mapea los valores seleccionados en grupo de informacion: Tipo de poblacion
            const itemsChecked = userData['tipo_poblacion']?.split(',');
            setTipoPoblacionChecked(listPoblaciones?.filter(i => itemsChecked?.find((c: any) => c == i.code)).map(i => i.code));
            validationSchemaByTpDoc(userData['tipo_de_documento']);
        }
    }, []);

    useEffect(() => {
        if (userData['nacionalidad']) {
            const listddlNacionalidad = nacionalidadList.filter(
                (obj) => obj.code.indexOf(userData['pais_nascimento']) !== -1
            );
            setDdlNacionalidad(listddlNacionalidad);
            setBlockNacion(false);
        }

    }, [nacionalidadList, userData.pais_nascimento]);

    /**
     * Garantiza la carga de items seleccionados en el grupo de informacion: Tipo de población
     */
    useEffect(() => {
        if (listPoblaciones?.length > 0) {
            //Mapea los valores seleccionados en grupo de informacion: Tipo de poblacion
            const itemsChecked = tipoPoblacionChecked?.length > 0 ? tipoPoblacionChecked : userData['tipo_poblacion']?.split(',');
            setTipoPoblacionChecked(listPoblaciones?.filter(i => itemsChecked?.find((c: any) => c == i.code)).map(i => i.code));
        }
    }, [listPoblaciones]);

    const fetchAndSetMunicipioById = async (id: string) => {
        const municipioList =
            await parameterSingleton.fetchMunicipioById(id);
        setDdlMunicipioNacimiento(municipioList);
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getCurriculoById(`id_users=${currentUser?.user?.id}&id=${id}`);
            //Elimina atributo sexo para prevenir problemas de cruza entre el atrinuto genero (user_complemento) y sexo (user_curriculo)
            delete response.sexo;
            preload[0].valid = true;
            const {
                curriculoListId,
                usercomplemento
            } = response.data[0];

            //Exract atributos
            const {
                CurriculoConocimiento,
                CurriculoExperienciaLaboral,
                CurriculoIdioma,
                CurriculoCapacitacione,
                CurriculoNivelEducativo,
                Cargos,
                complementoDirecao,
                ...data
            } = curriculoListId;
            //Set userData
            setUserData({
                ...userData,
                id_users: usercomplemento.id_users,
                primer_nombre: usercomplemento.primer_nome,
                segundo_nombre: usercomplemento.segundo_nome,
                primer_apelido: usercomplemento.primer_apelido,
                segundo_apelido: usercomplemento.segundo_apelido,
                nombre_identitario: usercomplemento.nombre_identitario,
                tipo_de_documento: usercomplemento.tipo_doc,
                numero_documento: usercomplemento.numero_doc,
                fecha_nascimiento: usercomplemento.fecha_nascimiento,
                experienciaLaboral: CurriculoExperienciaLaboral,
                listHerramientas: CurriculoConocimiento,
                listIdioma: CurriculoIdioma,
                capacitacionCertificacion: CurriculoCapacitacione,
                nivelEducativo: CurriculoNivelEducativo,
                listPuestos: Cargos,
                complementoDirecao: complementoDirecao,
                telefono_contacto: formatMaskPhoneNumber12Digits(
                    usercomplemento?.celular
                        ?.replaceAll(/[^\d]/g, '')
                        ?.replaceAll('-', '')
                        ?.replaceAll('+', '') || ''
                ),
                correo_Electronico: usercomplemento?.User?.email || '',
                sexoFinal: usercomplemento.genero ?? curriculoListId.sexo,
                tipo_poblacion: curriculoListId?.tipo_poblacion,
                persona_discapacidad: curriculoListId?.condicion_discapacidad != null ? CODIGO_SI : curriculoListId?.persona_discapacidad,
                ...data,
            });

            //Establece el porcentaje de avance de diligenciamiento
            setCurrentProgress(curriculoListId.porcentaje_diligenciamiento)

            //Valid Schema Document on first render
            if (isValidFieldRequired(usercomplemento.tipo_doc)) {
                validationSchemaByTpDoc(usercomplemento.tipo_doc)
                //enable: problem with lazy load => validationSchemaByTpDoc(listTipoDocumentoCandidato?.find((x) => x.code === usercomplemento.tipo_doc)?.code || '');
            }

            //Mapea los valores seleccionados en grupo de informacion: Tipo de poblacion
            setTipoPoblacionChecked(curriculoListId?.tipo_poblacion?.split(','));

            if (curriculoListId.nacionalidad) {
                const listddlNacionalidad = nacionalidadList.filter(
                    (obj) => obj.code.indexOf(curriculoListId.pais_nascimento) !== -1
                );
                setDdlNacionalidad(listddlNacionalidad);
                setBlockNacion(false);
            } else {
                setDdlNacionalidad([]);
            }

            if (curriculoListId.municipio_nacimiento) {
                fetchAndSetMunicipioById(curriculoListId.departamento_nacimiento);
                setBlockMuni(false);
            } else {
                setDdlMunicipioNacimiento([]);
            }
        } catch (error: any) {
            if (error.response) {
                setOpen(true);
                setAlert(false);
                setAlertMsg(`Ha ocurrido un error registrando la información!`);
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud del curriculo.', error);
            }
        } finally {
            setLoading(false);
            setLoadedData(true);
        }
    };

    const handleSelectPais = (e: SelectChangeEvent) => {
        if (e.target.value !== '') {
            setUserData({ ...userData, pais_nascimento: e.target.value, nacionalidad: e.target.value });
            const listddlNacionalidad = nacionalidadList.filter(
                (obj) => obj.code.indexOf(e.target.value) !== -1
            );

            setDdlNacionalidad(listddlNacionalidad);
            //setUserData({ ...userData, nacionalidad: e.target.value })
            setBlockNacion(false);
        } else {
            setDdlNacionalidad([]);
        }
    };

    const handleSelect = (e: SelectChangeEvent) => {
        if (e.target.value !== '') {
            setUserData({ ...userData, departamento_nacimiento: e.target.value });
            fetchAndSetMunicipioById(e.target.value);
            setBlockMuni(false);
        } else {
            setDdlMunicipioNacimiento([]);
        }
    };
    const handleValidOnSubmit = () => {

        let errors: { field: string; error: string }[] = [];

        let camposError: string[] = [];

        if (!isValidFirstSecondNameFirstSecondLastName(userData['primer_nombre'], true)) {
            errors.push({
                field: 'Primer nombre',
                error: helperValidFirstSecondNameFirstSecondLastName(userData['primer_nombre'], true)
            });
            camposError.push('primer_nombre');
        }

        if (!isValidFirstSecondNameFirstSecondLastName(userData['segundo_nombre'])) {
            errors.push({
                field: 'Segundo nombre',
                error: helperValidFirstSecondNameFirstSecondLastName(userData['segundo_nombre'])
            });
            camposError.push('segundo_nombre');
        }

        if (!isValidFirstSecondNameFirstSecondLastName(userData['primer_apelido'], true)) {
            errors.push({
                field: 'Primer apellido',
                error: helperValidFirstSecondNameFirstSecondLastName(userData['primer_apelido'], true)
            });
            camposError.push('primer_apelido');
        }

        if (!isValidFirstSecondNameFirstSecondLastName(userData['segundo_apelido'])) {
            errors.push({
                field: 'Segundo apellido',
                error: helperValidFirstSecondNameFirstSecondLastName(userData['segundo_apelido'])
            });
            camposError.push('segundo_apelido');
        }
        if (!isValidFieldRequired(userData['tipo_de_documento'])) {
            errors.push({ field: 'Tipo de documento', error: 'Campo requerido' });
            camposError.push('tipo_de_documento');
        }

        if (!isValidDocumentNumber(userData['numero_documento'], documentTypeField, userData['tipo_de_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)) {
            errors.push({
                field: 'Número de documento',
                error: helperIsValidDocumentNumber(userData['numero_documento'], documentTypeField, userData['tipo_de_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)
            });
            camposError.push('numero_documento');
        }

        if (!isValidFieldRequired(userData['cuenta_sisben'])) {
            errors.push({ field: '¿Cuenta con Sisbén?', error: 'Campo requerido' });
            camposError.push('cuenta_sisben');

        } else if ((userData['cuenta_sisben'] == CODIGO_SI)) {
            if (!isValidFieldRequired(userData['tipo_sisben'])) {
                errors.push({ field: 'Tipo de Sisbén', error: 'Campo requerido' });
                camposError.push('tipo_sisben');
            }
        }

        if (!isValidFieldRequired(userData['cuidador'])) {
            errors.push({ field: '¿Usted  es Cuidador?', error: 'Campo requerido' });
            camposError.push('cuidador');

        } else if ((userData['cuidador'] == CODIGO_SI)) {
            if (!isValidFieldRequired(userData['tipo_cuidador'])) {
                errors.push({ field: 'Persona que recibe cuidado', error: 'Campo requerido' });
                camposError.push('tipo_cuidador');
            }
        }

        if (!isValidFieldRequired(userData['sexoFinal'])) {
            errors.push({ field: 'Sexo asignado al nacer', error: 'Campo requerido' });
            camposError.push('sexo');
        }
        else if (userData['sexoFinal'] == 'M') {
            if (!isValidFieldRequired(userData['libreta_militar'])) {
                errors.push({ field: 'Libreta Militar', error: 'Campo requerido' });
                camposError.push('libreta_militar');
            } else if (userData['libreta_militar'] == CODIGO_SI) {
                if (!isValidFieldRequired(userData['tipo_libreta'])) {
                    errors.push({ field: 'Tipo de libreta', error: 'Campo requerido' });
                    camposError.push('tipo_libreta');
                }

                if (!isValidFieldRequired(userData['numero_libreta'])) {
                    errors.push({ field: 'Número de libreta', error: 'Campo requerido' });
                    camposError.push('numero_libreta');
                }
            }
        }

        if (!isValidFieldRequired(userData['genero'])) {
            errors.push({ field: 'Identidad de Género', error: 'Campo requerido' });
            camposError.push('genero');
        }

        if (!isValidFieldRequired(userData['orientacion_sexual'])) {
            errors.push({ field: 'Orientación sexual', error: 'Campo requerido' });
            camposError.push('orientacion_sexual');
        }

        if (!isValidFieldRequired(userData['jefe_hogar'])) {
            errors.push({ field: 'Jefe o Jefa de hogar', error: 'Campo requerido' });
            camposError.push('jefe_hogar');
        }
        if (userData['reconoce_focalizada'] == CODIGO_SI && !isValidFieldRequired(userData['grupo_etnico'])) {
            errors.push({ field: 'Grupo Etnico', error: 'Campo requerido' });
        }

        if (userData['reconoce_focalizada'] == CODIGO_SI && !isValidFieldRequired(userData['persona_discapacidad'])) {
            errors.push({ field: 'Persona con Discapacidad', error: 'Campo requerido' });
        }


        if (userData['persona_discapacidad'] == CODIGO_SI && !isValidFieldRequired(userData['condicion_discapacidad'])) {
            errors.push({ field: 'Tipo de discapacidad', error: 'Campo requerido' });
        }

        if (!isValidFieldRequired(userData['fecha_nascimiento'])) {
            errors.push({ field: 'Fecha de nacimiento', error: 'Campo requerido' });
            camposError.push('fecha_nascimiento');
        }

        if (!isValidFieldRequired(userData['correo_Electronico'])) {
            errors.push({ field: 'Correo electrónico', error: 'Campo requerido' });
            camposError.push('correo_electronico');
        }
        else if (!isValidEmail(userData['correo_Electronico'])) {
            errors.push({ field: 'Correo electrónico', error: 'Correo electrónico no valido' });
            camposError.push('correo_electronico');
        }

        if (!isValidColombianPhoneNumber(userData['telefono_contacto'], true)) {
            errors.push({
                field: 'teléfono_contacto',
                error: helperIsValidColombianPhoneNumber(userData['telefono_contacto'], true),
            });
            camposError.push('telefono_contacto');
        }

        if (
            isValidFieldRequired(userData['otro_telefono']) &&
            !isValidPhoneNumber(userData['otro_telefono'])
        ) {
            errors.push({
                field: 'teléfono_contacto',
                error: 'Teléfono de contacto no es válido',
            });
            camposError.push('otro_telefono');
        }
        if (!isValidFieldRequired(userData['pais_nascimento'])) {
            errors.push({ field: 'País de nacimiento', error: 'Campo requerido' });
            camposError.push('pais_nascimiento');
        }
        else if (userData['pais_nascimento'] == 'CO') {
            if (!isValidFieldRequired(userData['departamento_nacimiento'])) {
                errors.push({ field: 'Departamento de nacimiento', error: 'Campo requerido' });
                camposError.push('departamento_nacimiento');
            }
            if (!isValidFieldRequired(userData['municipio_nacimiento'])) {
                errors.push({ field: 'Municipio de nacimiento', error: 'Campo requerido' });
                camposError.push('municipio_nacimiento');
            }
        }
        if (!isValidFieldRequired(userData['nacionalidad'])) {
            errors.push({ field: 'Nacionalidad', error: 'Campo requerido' });
            camposError.push('nacionalidad');
        }

        if (userData['reconoce_focalizada'] == CODIGO_SI) {
            if (tipoPoblacionChecked?.indexOf(TIPO_POBLACION_CODIGO_RETORNADO) > -1 && !isValidFieldRequired(userData['pais_retornado'])) {
                errors.push({ field: 'País del cual fue retornado', error: 'Campo requerido' });
                camposError.push('pais_retornado');
            }
            if (requiredTipoPoblacion()) {
                const itemsCheckd = userData['tipo_poblacion']?.split(',');
                if (listPoblaciones?.filter(i => itemsCheckd?.find((c: any) => c == i.code)).map(i => i.code)?.length <= 0) {
                    errors.push({ field: 'Tipo de población', error: 'Campo requerido' });
                    camposError.push('tipo_poblacion');
                }
            } else if (userData['grupo_etnico'] == GRUPO_ETNICO_CODIGO_INDIGENA) {
                if (!isValidFieldRequired(userData['grupo_indigena'])) {
                    errors.push({ field: 'Grupo Indígena', error: 'Campo requerido' });
                    camposError.push('grupo_indigena');
                }
            }
        }

        //Aplica reglas de validacion para las observacioness
        if (!isValidMinMaxText(userData['observaciones'], 10, 1200)) {
            errors.push({
                field: 'observaciones',
                error: helperValidMinMaxText(userData['observaciones'], 10, 1200).split('\n')[0],
            });
            camposError.push('observaciones');
        }

        //Aplica reglas de validacion sobre la fecha de nacimiento y el tipo de documento
        errors.push(...checkRulesByBirthDateAndDocType(userData['fecha_nascimiento'], userData['tipo_de_documento']));

        //Aplica reglas de validacion sobre la nacionalidad y el tipo de documento
        errors.push(...checkRulesByCountryAndDocumentType(userData['pais_nascimiento'], userData['tipo_de_documento']));

        setTouchedFields(camposError);

        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\Error en diligenciamiento de la información\n ${errors.map((x) => {
                    return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }

        return true;
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit(event: FormEvent) {
        const retorno = handleValidOnSubmit();
        if (retorno) {
            try {
                setLoading(true);
                event.preventDefault();
                let object = createObjectToSubmit();
                const response: any = await createCurriculo(object);
                setAlert(true);
                setAlertMsg(`¡Registro completado con éxito!`);
                setOpen(true);
                setStep(2);
                setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
            } catch (error: any) {
                if (error.response) {
                    console.error(error.response.data.error);
                    setOpen(true);
                    setAlert(false);
                    setAlertMsg(error.response.data.error);
                } else {
                    console.error('Ocurrio un error procesando la solicitud.');
                }
            } finally {
                setLoading(false);
            }
        }
    }

    const createObjectDirecao = (): ComplementoDirecaoModel[] => {
        let compDirecaoArr: ComplementoDirecaoModel[] = [];
        if (
            userData['complementoDirecao'] !== null &&
            userData['complementoDirecao'] !== undefined &&
            (userData['complementoDirecao'] as Array<any>).length > 0
        ) {
            (userData['complementoDirecao'] as Array<any>).forEach((e) => {
                let x: ComplementoDirecaoModel = {
                    complemento: setValueOrDefault(e['complemento'], 'string'),
                    id: setValueOrDefault(e['id'], 'number'),
                    tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
                };
                compDirecaoArr.push(x);
            });
        }

        return compDirecaoArr;
    };

    const createObjectPuestos = (): PuestosModel[] => {
        let puestosArr: PuestosModel[] = [];

        if (
            userData['listPuestos'] !== null &&
            userData['listPuestos'] !== undefined &&
            (userData['listPuestos'] as Array<any>).length > 0
        ) {
            (userData['listPuestos'] as Array<any>).forEach((e) => {
                let x: PuestosModel = {
                    id: setValueOrDefault(e['id'], 'number'),
                    puestos: setValueOrDefault(e['puestos'], 'string'),
                };
                puestosArr.push(x);
            });
        }

        return puestosArr;
    };

    const createObjectNivelEducativo = (): NivelEducativoModel[] => {
        let nivelEducativoArr: NivelEducativoModel[] = [];

        if (
            userData['nivelEducativo'] !== null &&
            userData['nivelEducativo'] !== undefined &&
            (userData['nivelEducativo'] as Array<any>).length > 0
        ) {
            (userData['nivelEducativo'] as Array<any>).forEach((e) => {
                let x: NivelEducativoModel = {
                    id: setValueOrDefault(e['id'], 'number'),
                    areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
                    estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
                    idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
                    txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
                    fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
                    interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
                    nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
                    nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
                    paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
                    tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
                    tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
                };
                nivelEducativoArr.push(x);
            });
        }

        return nivelEducativoArr;
    };

    const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
        let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
        if (
            userData['capacitacionCertificacion'] !== null &&
            userData['capacitacionCertificacion'] !== undefined &&
            (userData['capacitacionCertificacion'] as Array<any>).length > 0
        ) {
            (userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
                let x: CapacitacionCertificacionModel = {
                    id: setValueOrDefault(e['id'], 'number'),
                    duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
                    estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
                    fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
                    instituicion: setValueOrDefault(e['instituicion'], 'string'),
                    nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
                    paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
                    tpCapacitacionCertificacion: setValueOrDefault(
                        e['tpCapacitacionCertificacion'],
                        'string'
                    ),
                };
                capCertificacionesArr.push(x);
            });
        }

        return capCertificacionesArr;
    };

    const createObjectIdioma = (): IdiomaModel[] => {
        let idiomaArr: IdiomaModel[] = [];
        if (
            userData['listIdioma'] !== null &&
            userData['listIdioma'] !== undefined &&
            (userData['listIdioma'] as Array<any>).length > 0
        ) {
            (userData['listIdioma'] as Array<any>).forEach((e) => {
                let x: IdiomaModel = {
                    id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
                    nivel: setValueOrDefault(e['nivel'], 'number'),
                    id: setValueOrDefault(e['id'], 'number'),
                };
                idiomaArr.push(x);
            });
        }
        return idiomaArr;
    };

    const createObjectHerramienta = (): HerramientasModel[] => {
        let herramientasArr: HerramientasModel[] = [];
        if (
            userData['listHerramientas'] !== null &&
            userData['listHerramientas'] !== undefined &&
            (userData['listHerramientas'] as Array<any>).length > 0
        ) {
            (userData['listHerramientas'] as Array<any>).forEach((e) => {
                let x: HerramientasModel = {
                    id: setValueOrDefault(e['id'], 'number'),
                    nivel: setValueOrDefault(e['nivel'], 'number'),
                    id_tipo_conocimientos: setValueOrDefault(
                        otrosConocimientosList.find((y) => y.value === e['tipo'])?.code,
                        'string'
                    ),
                    herramientas: setValueOrDefault(e['herramientas'], 'string'),
                };
                herramientasArr.push(x);
            });
        }

        return herramientasArr;
    };

    const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
        let expLaboralArr: ExperienciaLaboralModel[] = [];
        if (
            userData['experienciaLaboral'] !== null &&
            userData['experienciaLaboral'] !== undefined &&
            (userData['experienciaLaboral'] as Array<any>).length > 0
        ) {
            (userData['experienciaLaboral'] as Array<any>).forEach((e) => {
                let x: ExperienciaLaboralModel = {
                    id: setValueOrDefault(e['id'], 'number'),
                    cargo: setValueOrDefault(e['cargo'], 'string'),
                    cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
                    cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
                    fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
                    fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
                    funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
                    nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
                    productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
                    sector: setValueOrDefault(e['sector'], 'string'),
                    telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
                    tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
                    trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
                    paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
                };
                expLaboralArr.push(x);
            });
        }
        return expLaboralArr;
    };

    const createObjectUserComplemento = (): UserComplementoModel[] => {
        let userCompArr: UserComplementoModel[] = [];
        let userComp: UserComplementoModel = {
            id_users: setValueOrDefault(userData['id_users'], 'number'),
            primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
            segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
            primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
            segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
            nombre_identitario: setValueOrDefault(userData['nombre_identitario'], 'string'),
            tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
            numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
            fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
            celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
            genero: setValueOrDefault(userData['sexoFinal'], 'string')
        };
        userCompArr.push(userComp);
        return userCompArr;
    };

    const createObjectToSubmit = (): ResumeModel => {
        let resume: ResumeModel = {
            id_users: setValueOrDefault(userData['id_users'], 'number'),
            step: setValueOrDefault(step, 'string'),
            estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
            //numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
            //tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
            sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
            genero: setValueOrDefault(userData['genero'], 'string'),
            orientacion_sexual: setValueOrDefault(userData['orientacion_sexual'], 'string'),
            jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
            persona_discapacidad: setValueOrDefault(userData['persona_discapacidad'], 'string'),
            condicion_discapacidad: setValueOrDefault(userData['condicion_discapacidad'], 'string'),
            pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
            pais_retornado: setValueOrDefault(userData['pais_retornado'], 'string'),
            nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
            departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
            municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
            libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
            tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
            numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
            cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
            tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
            cuidador: setValueOrDefault(userData['cuidador'], 'string'),
            tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
            reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
            correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
            // telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
            otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
            observaciones: setValueOrDefault(userData['observaciones'], 'string'),
            pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
            bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
            pertence: setValueOrDefault(userData['pertence'], 'string'),
            prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
            punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
            local: setValueOrDefault(userData['local'], 'string'),
            id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
            id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
            id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
            id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
            id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
            id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
            id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
            id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
            id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
            codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
            direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
            possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
            situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
            ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
            aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
            aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
            Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'], 'string'),
            posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
            conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
            categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
            conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
            categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
            Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
            nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
            perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
            grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
            grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
            userComplemento: createObjectUserComplemento(),
            complementoDirecao: createObjectDirecao(),
            listPuestos: createObjectPuestos(),
            nivelEducativo: createObjectNivelEducativo(),
            capacitacionCertificacion: createObjectCapacitacionCertificacion(),
            listIdioma: createObjectIdioma(),
            listHerramientas: createObjectHerramienta(),
            experienciaLaboral: createObjectExpLaboral(),
        };

        return resume;
    };

    //validation for candidate document
    const validationSchemaByTpDoc = (type: string) => {
        switch (type) {
            case 'CC':
                setDocumentTypeField('number');
                setMinDocumentFieldLenght(3);
                setMaxDocumentFieldLenght(11);
                break;
            case 'TI':
                setDocumentTypeField('number');
                setMinDocumentFieldLenght(10);
                setMaxDocumentFieldLenght(11);
                break;
            case 'CE':
                setDocumentTypeField('number');
                setMinDocumentFieldLenght(1);
                setMaxDocumentFieldLenght(7);
                break;
            case 'PA':
                setDocumentTypeField('text');
                setMinDocumentFieldLenght(1);
                setMaxDocumentFieldLenght(17);
                break;
            case 'PEP':
                setDocumentTypeField('number');
                setMinDocumentFieldLenght(15);
                setMaxDocumentFieldLenght(20);
                break;
            case 'PT':
                setDocumentTypeField('number');
                setMinDocumentFieldLenght(4);
                setMaxDocumentFieldLenght(7);
                break;
            default:
                setDocumentTypeField('text');
                setMinDocumentFieldLenght(0);
                setMaxDocumentFieldLenght(20);
        }
    };

    //Evento de escucha para onChange sobre el atributo "fecha de nacimiento"
    const onChangeBirthDate = (value: any) => {
        let errors = checkRulesByBirthDateAndDocType(value, userData['tipo_de_documento']);
        if (errors.length > 0) {
            setUserData({ ...userData, fecha_nascimiento: value, tipo_de_documento: '' })
            setAlert(false);
            setAlertMsg(
                `${errors.map((x) => {
                    return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
                        }`;
                })}`
            );
            setOpen(true);
        } else {
            setUserData({ ...userData, fecha_nascimiento: value })
        }
    }

    //Evento de escucha para onChange sobre el atributo "tipo de documento"
    const onChangeDocType = (value: any) => {
        let errors = checkRulesByCountryAndDocumentType(userData['pais_nascimento'], value);
        if (errors.length > 0) {
            setUserData({ ...userData, value, tipo_de_documento: '' })
            setAlert(false);
            setAlertMsg(
                `${errors.map((x) => {
                    return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())} ${x.error
                        }`;
                })}`
            );
            setOpen(true);
        } else {
            setUserData({ ...userData, tipo_de_documento: value });
        }
    }
    const handleBlur = (field: any) => {
        setTouchedFields([...touchedFields, field]);
    }

    /**
     * Handle event change ocpiones tipo poblacion
     * @param event 
     */
    const handleChangeTipoPoblacion = (event: SelectChangeEvent) => {
        const { target: { value } } = event;
        const index = tipoPoblacionChecked?.indexOf(String(value));
        let checked: any = [];
        var pais_retornado = userData['pais_retornado'];
        if (index > -1) {
            checked = tipoPoblacionChecked?.filter(i => i != value);
            if (value == TIPO_POBLACION_CODIGO_RETORNADO) {
                pais_retornado = null;
            }
        } else if (isValidFieldRequired(value)) {
            checked = tipoPoblacionChecked ? tipoPoblacionChecked?.concat(value) : [value];
        }
        const tipo_poblacion = concatArrayToStringJoinBy(checked, ',');
        setUserData({ ...userData, tipo_poblacion: tipo_poblacion, pais_retornado: pais_retornado })
        setTipoPoblacionChecked(checked);
    };

    /**
     * Determina si debe mostrar error en el grupo: Tipo de poblacion
     * @returns 
     */
    const errorOnTipoPoblacion = () => {
        return touchedFields.includes('tipo_poblacion') && (!tipoPoblacionChecked || tipoPoblacionChecked?.length <= 0) && requiredTipoPoblacion();
    }

    /**
     * Handle event change en input grupo etnico
     * @param event 
     */
    const onChangeGrupoEtnico = (value: any) => {
        var grupo_indigena = userData['grupo_indigena']
        if (value != GRUPO_ETNICO_CODIGO_INDIGENA) {
            grupo_indigena = null;
        }
        setUserData({ ...userData, grupo_etnico: value, grupo_indigena: grupo_indigena });
    }


    /**
 * Handle event change en input grupo etnico
 * @param event 
 */
    const onChangeDiscapacidad = (e: any) => {
        setUserData({
            ...userData,
            condicion_discapacidad: e.target.value
        });
    }

    /**
     * Determina si tipo poblacion es requerido
     * @returns 
     */
    const requiredTipoPoblacion = () => {
        return userData['grupo_etnico'] == GRUPO_ETNICO_NO_RECONOCE_NINGUNA && userData['persona_discapacidad'] == '0'
    }


    return (
        <>
            {loading ? (
                null
            ) : (loadingParams ? <LinearLoadingSpinner isLoading={true} /> :
                <>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            Datos personales
                        </Typography>
                    </Box>
                    <Grid container direction='row' justifyContent='space-between' width={'100%'}>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                required
                                id='primer_nombre'
                                name='primer_nombre'
                                label='Primer nombre'
                                value={initValue(userData['primer_nombre'])}
                                onChange={(e) => {
                                    setUserData({ ...userData, primer_nombre: e.target.value });
                                }}
                                onBlur={() => handleBlur('primer_nombre')}
                                size='medium'
                                error={touchedFields.includes('primer_nombre') && !isValidFirstSecondNameFirstSecondLastName(userData['primer_nombre'], true)}
                                helperText={touchedFields.includes('primer_nombre') ? helperValidFirstSecondNameFirstSecondLastName(userData['primer_nombre'], true) : ''}
                                inputProps={{ minLength: 2, maxLength: 40 }}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                id='segundo_nombre'
                                name='segundo_nombre'
                                label='Segundo nombre'
                                value={initValue(userData['segundo_nombre'])}
                                onBlur={() => handleBlur('segundo_nombre')}
                                onChange={(e) => setUserData({ ...userData, segundo_nombre: e.target.value })}
                                size='medium'
                                inputProps={{ maxLength: 40 }}
                                error={touchedFields.includes('segundo_nombre') && !isValidFirstSecondNameFirstSecondLastName(userData['segundo_nombre'])}
                                helperText={touchedFields.includes('segundo_nombre') ? helperValidFirstSecondNameFirstSecondLastName(userData['segundo_nombre']) : ''}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                required
                                id='primer_apelido'
                                name='primer_apelido'
                                label='Primer apellido'
                                value={initValue(userData['primer_apelido'])}
                                onChange={(e) => setUserData({ ...userData, primer_apelido: e.target.value })}
                                size='medium'
                                error={!isValidFirstSecondNameFirstSecondLastName(userData['primer_apelido'], true)}
                                helperText={helperValidFirstSecondNameFirstSecondLastName(userData['primer_apelido'], true)}
                                inputProps={{ minLength: 2, maxLength: 40 }}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                id='segundo_apelido'
                                name='segundo_apelido'
                                label='Segundo apellido'
                                value={
                                    isValidFieldRequired(userData['segundo_apelido'])
                                        ? userData['segundo_apelido']
                                        : ''
                                }
                                onChange={(e) => setUserData({ ...userData, segundo_apelido: e.target.value })}
                                size='medium'
                                inputProps={{ maxLength: 40 }}
                                error={!isValidFirstSecondNameFirstSecondLastName(userData['segundo_apelido'])}
                                helperText={helperValidFirstSecondNameFirstSecondLastName(userData['segundo_apelido'])}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                id='nombre_identitario'
                                name='nombre_identitario'
                                label='Nombre identitario'
                                value={
                                    isValidFieldRequired(userData['nombre_identitario'])
                                        ? userData['nombre_identitario']
                                        : ''
                                }
                                onChange={(e) => setUserData({ ...userData, nombre_identitario: e.target.value })}
                                size='medium'
                                inputProps={{ maxLength: 30 }}
                                error={!isValidMinMaxText(userData['nombre_identitario'], 10, 30, false)}
                                helperText={!isValidMinMaxText(userData['nombre_identitario'], 10, 30, false) ? 'Debe tener entre 10 y 30 caracteres' : ''}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={!isValidFieldRequired(userData['tipo_de_documento'])}
                            >
                                <InputLabel required id='tipo_de_documento'>
                                    Tipo de documento
                                </InputLabel>
                                <Select
                                    required
                                    labelId='tipo_de_documento'
                                    id='tipo_de_documento'
                                    label='Tipo de documento'
                                    value={initValue(userData['tipo_de_documento'])}
                                    onChange={(e) => {
                                        userData['numero_documento'] = '';
                                        validationSchemaByTpDoc(e.target.value);
                                        onChangeDocType(e.target.value);
                                    }}
                                >
                                    <MenuItem value='' key={''}>
                                        Seleccione...
                                    </MenuItem>
                                    {listTipoDocumentoCandidato.map((item) => (
                                        <MenuItem key={item.code} value={item.code}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!isValidFieldRequired(userData['tipo_de_documento']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['tipo_de_documento']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                required
                                id='numero_documento'
                                name='numero_documento'
                                label='Número de documento'
                                value={initValue(userData['numero_documento'])}
                                onChange={(e) => {
                                    if (e.target.value.length <= maxDocumentFieldLenght)
                                        setUserData({ ...userData, numero_documento: e.target.value });
                                }}
                                type={documentTypeField}
                                size='medium'
                                inputProps={{
                                    maxLength: maxDocumentFieldLenght,
                                    minLength: minDocumentFieldLenght,
                                }}
                                onKeyDown={documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen
                                error={!isValidDocumentNumber(userData['numero_documento'], documentTypeField, userData['tipo_de_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)}
                                helperText={
                                    !isValidFieldRequired(userData['tipo_de_documento'])
                                        ? 'Primero seleccione el tipo de documento'
                                        : helperIsValidDocumentNumber(userData['numero_documento'], documentTypeField, userData['tipo_de_documento'], minDocumentFieldLenght, maxDocumentFieldLenght, true)
                                }
                                disabled={!isValidFieldRequired(userData['tipo_de_documento'])}
                            />
                        </Grid>
                        <Grid xs={12} md={3} mb={5} pr={2} item={true}>
                            <FormControl sx={{ minWidth: '100%' }} size='medium'>
                                <InputLabel id='estado_civil'>Estado civil</InputLabel>
                                <Select
                                    labelId='estado_civil'
                                    id='estado_civil'
                                    label='Estado civil'
                                    value={initValue(userData['estado_civil'])}
                                    onChange={(e) => setUserData({ ...userData, estado_civil: e.target.value })}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {estadoCivilList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={touchedFields.includes('sexo') && !isValidFieldRequired(userData['sexoFinal'])}
                            >
                                <InputLabel required id='sexoFinal'>
                                    Sexo asignado al nacer
                                </InputLabel>
                                <Select
                                    required
                                    labelId='sexoFinal'
                                    id='sexoFinal'
                                    label='Sexo asignado al nacer'
                                    value={initValue(userData['sexoFinal'])}
                                    onBlur={() => handleBlur('sexo')}
                                    onChange={(e) => setUserData({ ...userData, sexoFinal: e.target.value })}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {sexoList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touchedFields.includes('sexo') && !isValidFieldRequired(userData['sexoFinal']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['sexoFinal']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={touchedFields.includes('genero') && !isValidFieldRequired(userData['genero'])}
                            >
                                <InputLabel required id='genero'>
                                    Identidad de Género
                                </InputLabel>
                                <Select
                                    required
                                    labelId='genero'
                                    id='genero'
                                    label='Identidad de Género'
                                    value={initValue(userData['genero'])}
                                    onChange={(e) => setUserData({ ...userData, genero: e.target.value })}
                                    onBlur={() => handleBlur('genero')}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {generoList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touchedFields.includes('genero') && !isValidFieldRequired(userData['genero']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['genero']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={touchedFields.includes('orientacion_sexual') && !isValidFieldRequired(userData['orientacion_sexual'])}
                            >
                                <InputLabel required id='orientacion_sexual'>
                                    Orientación sexual
                                </InputLabel>
                                <Select
                                    required
                                    labelId='orientacion_sexual'
                                    id='orientacion_sexual'
                                    label='Orientación sexual'
                                    value={initValue(userData['orientacion_sexual'])}
                                    onChange={(e) => setUserData({ ...userData, orientacion_sexual: e.target.value })}
                                    onBlur={() => handleBlur('orientacion_sexual')}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {orientacionSexualList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touchedFields.includes('orientacion_sexual') && !isValidFieldRequired(userData['orientacion_sexual']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['orientacion_sexual']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={touchedFields.includes('jefe_hogar') && !isValidFieldRequired(userData['jefe_hogar'])}
                            >
                                <InputLabel required id='jefe_hogar'>
                                    Jefe o jefa de hogar
                                </InputLabel>
                                <Select
                                    required
                                    labelId='jefe_hogar'
                                    id='jefe_hogar'
                                    label='Jefe o jefa de hogar'
                                    value={initValue(userData['jefe_hogar'])}
                                    onChange={(e) => setUserData({ ...userData, jefe_hogar: e.target.value })}
                                    onBlur={() => handleBlur('jefe_hogar')}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {siNoList.map(({ code, value }) => (
                                        <MenuItem key={code} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touchedFields.includes('jefe_hogar') && !isValidFieldRequired(userData['jefe_hogar']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['jefe_hogar']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
                                <DemoItem>
                                    <DatePicker
                                        disableFuture
                                        value={dayjs(userData['fecha_nascimiento'], 'YYYY-MM-DD')}
                                        format='DD/MM/YYYY'
                                        onOpen={() => handleBlur('fecha_nascimiento')}
                                        onChange={(newValue) => {
                                            onChangeBirthDate(newValue?.format('YYYY-MM-DD'))
                                            // console.log('cambiar datos')
                                        }}
                                        slotProps={{
                                            textField: {
                                                id: 'fecha_nascimiento',
                                                name: 'fecha_nascimiento',
                                                label: 'Fecha de nacimiento',
                                                required: true,
                                                variant: 'outlined',
                                                error: touchedFields.includes('fecha_nascimiento') && !isValidFieldRequired(userData['fecha_nascimiento']),
                                                helperText: touchedFields.includes('fecha_nascimiento') && !isValidFieldRequired(userData['fecha_nascimiento']) ? 'Campo requerido' : ''
                                            },
                                            actionBar: { actions: ["clear", "cancel", "today"] }
                                        }}
                                    />
                                </DemoItem>
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                                error={touchedFields.includes('pais_nascimiento') && !isValidFieldRequired(userData['pais_nascimento'])}
                            >
                                <InputLabel id='pais_nascimento' required>
                                    País de nacimiento
                                </InputLabel>
                                <Select
                                    required
                                    labelId='pais_nascimento'
                                    id='pais_nascimento'
                                    label='País de nacimiento'
                                    value={initValue(userData['pais_nascimento'])}
                                    onChange={(e) => handleSelectPais(e)}
                                    onBlur={() => handleBlur('pais_nascimiento')}
                                >
                                    <MenuItem value=''>Seleccione...</MenuItem>
                                    {paisList.map(({ code, value }, i) => (
                                        <MenuItem key={i} value={code}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touchedFields.includes('pais_nascimiento') && !isValidFieldRequired(userData['pais_nascimento']) && (
                                    <FormHelperText>
                                        {!isValidFieldRequired(userData['pais_nascimento']) ? 'Campo requerido' : ''}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid container direction='row'>

                            <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={touchedFields.includes('nacionalidad') && !isValidFieldRequired(userData['nacionalidad'])}
                                >
                                    <InputLabel required id='nacionalidad'>
                                        Nacionalidad
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId='nacionalidad'
                                        id='nacionalidad'
                                        label='Nacionalidad'
                                        value={initValue(userData['nacionalidad'])}
                                        onChange={(e) => setUserData({ ...userData, nacionalidad: e.target.value })}
                                        onBlur={() => handleBlur('nacionalidad')}
                                        disabled={blockNacion}
                                    >
                                        <MenuItem value=''>Seleccione...</MenuItem>
                                        {ddlNacionalidad.map(({ code, value }) => (
                                            <MenuItem key={code} value={code}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touchedFields.includes('nacionalidad') && !isValidFieldRequired(userData['nacionalidad']) && (
                                        <FormHelperText>
                                            {!isValidFieldRequired(userData['nacionalidad']) ? 'Campo requerido' : ''}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            {userData['pais_nascimento'] == 'CO' && (
                                <>
                                    <Grid xs={12} md={4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={touchedFields.includes('departamento_nacimiento') && !isValidFieldRequired(userData['departamento_nacimiento'])}
                                        >
                                            <InputLabel id='departamento_nacimiento' required>
                                                Departamento de nacimiento
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId='departamento_nacimiento'
                                                id='departamento_nacimiento'
                                                label='Departamento de nacimiento'
                                                value={initValue(userData['departamento_nacimiento'])}
                                                onChange={(e) => handleSelect(e)}
                                                onBlur={() => handleBlur('departamento_nacimiento')}
                                            >
                                                <MenuItem value=''>Seleccione...</MenuItem>
                                                {departamentoList.map(({ code, value }) => (
                                                    <MenuItem key={code} value={code}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touchedFields.includes('departamento_nacimiento') && !isValidFieldRequired(userData['departamento_nacimiento']) && (
                                                <FormHelperText>
                                                    {!isValidFieldRequired(userData['departamento_nacimiento'])
                                                        ? 'Campo requerido'
                                                        : ''}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={touchedFields.includes('municipio_nacimiento') && !isValidFieldRequired(userData['municipio_nacimiento'])}
                                        >
                                            <InputLabel id='municipio_nacimiento' required>
                                                Municipio de nacimiento
                                            </InputLabel>
                                            <Select
                                                required
                                                readOnly={blockMuni}
                                                labelId='municipio_nacimiento'
                                                id='municipio_nacimiento'
                                                label='Municipio de nacimiento'
                                                value={initValue(userData['municipio_nacimiento'])}
                                                onChange={(e) =>
                                                    setUserData({ ...userData, municipio_nacimiento: e.target.value })
                                                }
                                                onBlur={() => handleBlur('municipio_nacimiento')}

                                            >
                                                <MenuItem value=''>Seleccione...</MenuItem>
                                                {ddlMunicipioNacimiento.map(({ code, value }) => (
                                                    <MenuItem key={code} value={code}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touchedFields.includes('municipio_nacimiento') && !isValidFieldRequired(userData['municipio_nacimiento']) && (
                                                <FormHelperText>
                                                    {!isValidFieldRequired(userData['municipio_nacimiento'])
                                                        ? 'Campo requerido'
                                                        : ''}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </>
                            )}

                        </Grid>

                        <Grid container direction='row'>
                            {userData['sexoFinal'] == 'M' && (
                                <>
                                    <Grid xs={12} md={4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={
                                                touchedFields.includes('libreta_militar') && userData['sexoFinal'] == 'M' &&
                                                !isValidFieldRequired(userData['libreta_militar'])
                                            }
                                        >
                                            <InputLabel id='libreta_militar' required>
                                                Libreta militar
                                            </InputLabel>
                                            <Select
                                                required={userData['sexoFinal'] == 'M'}
                                                labelId='libreta_militar'
                                                id='libreta_militar'
                                                label='Libreta militar'
                                                value={initValue(userData['libreta_militar'])}
                                                onChange={(e) =>
                                                    setUserData({ ...userData, libreta_militar: e.target.value })
                                                }
                                                onBlur={() => handleBlur('libreta_militar')}
                                            >
                                                <MenuItem value=''>Seleccione...</MenuItem>
                                                {siNoList.map(({ code, value }) => (
                                                    <MenuItem key={code} value={code}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {userData['sexoFinal'] == 'M' &&
                                                !isValidFieldRequired(userData['libreta_militar']) && (
                                                    <FormHelperText>
                                                        {touchedFields.includes('libreta_militar') && userData['sexoFinal'] == 'M' &&
                                                            !isValidFieldRequired(userData['libreta_militar'])
                                                            ? 'Campo requerido'
                                                            : ''}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </Grid>
                                    {userData['libreta_militar'] == CODIGO_SI && (
                                        <>
                                            <Grid xs={12} md={4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                                <FormControl
                                                    sx={{ minWidth: '100%' }}
                                                    size='medium'
                                                    error={
                                                        touchedFields.includes('tipo_libreta') &&
                                                        userData['sexoFinal'] == 'M' &&
                                                        userData['libreta_militar'] == CODIGO_SI &&
                                                        !isValidFieldRequired(userData['tipo_libreta'])
                                                    }
                                                >
                                                    <InputLabel id='tipo_libreta' required>
                                                        Tipo de libreta
                                                    </InputLabel>
                                                    <Select
                                                        required={
                                                            userData['sexoFinal'] == 'M' && userData['libreta_militar'] == CODIGO_SI
                                                        }
                                                        labelId='tipo_libreta'
                                                        id='tipo_libreta'
                                                        label='Tipo de libreta'
                                                        value={initValue(userData['tipo_libreta'])}
                                                        onChange={(e) =>
                                                            setUserData({ ...userData, tipo_libreta: e.target.value })
                                                        }
                                                        onBlur={() => handleBlur('tipo_libreta')}
                                                    >
                                                        <MenuItem value=''>Seleccione...</MenuItem>
                                                        {tipoLibretaMilList.map(({ code, value }) => (
                                                            <MenuItem key={code} value={code}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {userData['sexoFinal'] == 'M' &&
                                                        userData['libreta_militar'] == CODIGO_SI &&
                                                        !isValidFieldRequired(userData['tipo_libreta']) && (
                                                            <FormHelperText>
                                                                {touchedFields.includes('tipo_libreta') &&
                                                                    userData['sexoFinal'] == 'M' &&
                                                                    userData['libreta_militar'] == CODIGO_SI &&
                                                                    !isValidFieldRequired(userData['tipo_libreta'])
                                                                    ? 'Campo requerido'
                                                                    : ''}
                                                            </FormHelperText>
                                                        )}
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={12} md={4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id='numero_libreta'
                                                    name='numero_libreta'
                                                    label='Número de libreta'
                                                    value={initValue(userData['numero_libreta'])}
                                                    onChange={(e) =>
                                                        setUserData({ ...userData, numero_libreta: e.target.value })
                                                    }
                                                    onBlur={() => handleBlur('numero_libreta')}
                                                    size='medium'
                                                    error={
                                                        touchedFields.includes('numero_libreta') &&
                                                        userData['sexoFinal'] == 'M' &&
                                                        userData['libreta_militar'] == CODIGO_SI &&
                                                        !isValidFieldRequired(userData['numero_libreta'])
                                                    }
                                                    helperText={
                                                        touchedFields.includes('numero_libreta') &&
                                                            userData['sexoFinal'] == 'M' &&
                                                            userData['libreta_militar'] == CODIGO_SI &&
                                                            !isValidFieldRequired(userData['numero_libreta'])
                                                            ? 'Campo requerido'
                                                            : ''
                                                    }
                                                    inputProps={{ maxLength: 45 }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid container direction='row'>
                            <Grid xs={12} md={userData['cuenta_sisben'] == CODIGO_SI ? 3 : 4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={
                                        touchedFields.includes('cuenta_sisben') &&
                                        !isValidFieldRequired(userData['cuenta_sisben'])
                                    }
                                >
                                    <InputLabel id='cuenta_sisben' required>
                                        ¿Cuenta con Sisbén?
                                    </InputLabel>
                                    <Select
                                        required={true}
                                        labelId='cuenta_sisben'
                                        id='cuenta_sisben'
                                        label='¿Cuenta con Sisbén?'
                                        value={initValue(userData['cuenta_sisben'])}
                                        onChange={(e) =>
                                            setUserData({ ...userData, cuenta_sisben: e.target.value })
                                        }
                                        onBlur={() => handleBlur('cuenta_sisben')}
                                    >
                                        <MenuItem value=''>Seleccione...</MenuItem>
                                        {siNoList.map(({ code, value }) => (
                                            <MenuItem key={code} value={code}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {
                                        !isValidFieldRequired(userData['cuenta_sisben']) && (
                                            <FormHelperText>
                                                {touchedFields.includes('cuenta_sisben') &&
                                                    !isValidFieldRequired(userData['cuenta_sisben'])
                                                    ? 'Campo requerido'
                                                    : ''}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            {userData['cuenta_sisben'] == CODIGO_SI && (
                                <>
                                    <Grid xs={12} md={3} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={
                                                touchedFields.includes('tipo_sisben') &&
                                                userData['cuenta_sisben'] == CODIGO_SI &&
                                                !isValidFieldRequired(userData['tipo_sisben'])
                                            }
                                        >
                                            <InputLabel id='tipo_sisben' required>
                                                Tipo de Sisbén
                                            </InputLabel>
                                            <Select
                                                required={userData['cuenta_sisben'] == CODIGO_SI}
                                                labelId='tipo_sisben'
                                                id='tipo_sisben'
                                                label='Tipo de Sisbén'
                                                value={initValue(userData['tipo_sisben'])}
                                                onChange={(e) =>
                                                    setUserData({ ...userData, tipo_sisben: e.target.value })
                                                }
                                                onBlur={() => handleBlur('tipo_sisben')}
                                            >
                                                <MenuItem value=''>Seleccione...</MenuItem>
                                                {tipoSisbenList.map(({ code, value, label }) => (
                                                    <MenuItem key={code} value={code}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                userData['cuenta_sisben'] == CODIGO_SI &&
                                                !isValidFieldRequired(userData['tipo_sisben']) && (
                                                    <FormHelperText>
                                                        {touchedFields.includes('tipo_sisben') &&
                                                            userData['cuenta_sisben'] == CODIGO_SI &&
                                                            !isValidFieldRequired(userData['tipo_sisben'])
                                                            ? 'Campo requerido'
                                                            : ''}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </Grid>
                                </>)}
                            <Grid xs={12} md={userData['cuidador'] == CODIGO_SI ? 3 : 4} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={
                                        touchedFields.includes('cuidador') &&
                                        !isValidFieldRequired(userData['cuidador'])
                                    }
                                >
                                    <InputLabel id='cuidador' required>
                                        ¿Usted es Cuidador o Cuidadora?
                                    </InputLabel>
                                    <Select
                                        required={true}
                                        labelId='cuidador'
                                        id='cuidador'
                                        label='¿Usted es Cuidador o Cuidadora?'
                                        value={initValue(userData['cuidador'])}
                                        onChange={(e) =>
                                            setUserData({ ...userData, cuidador: e.target.value })
                                        }
                                        onBlur={() => handleBlur('cuidador')}
                                    >
                                        <MenuItem value=''>Seleccione...</MenuItem>
                                        {siNoList.map(({ code, value }) => (
                                            <MenuItem key={code} value={code}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {
                                        !isValidFieldRequired(userData['cuidador']) && (
                                            <FormHelperText>
                                                {touchedFields.includes('cuidador') &&
                                                    !isValidFieldRequired(userData['cuidador'])
                                                    ? 'Campo requerido'
                                                    : ''}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            {userData['cuidador'] == CODIGO_SI && (
                                <>
                                    <Grid xs={12} md={3} mb={5} pr={2} sx={{ display: 'block' }} item={true}>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={
                                                touchedFields.includes('tipo_cuidador') &&
                                                userData['cuidador'] == CODIGO_SI &&
                                                !isValidFieldRequired(userData['tipo_cuidador'])
                                            }
                                        >
                                            <InputLabel id='tipo_cuidador' required>
                                                Persona que recibe cuidado
                                            </InputLabel>
                                            <Select
                                                required={userData['cuidador'] == CODIGO_SI}
                                                labelId='tipo_cuidador'
                                                id='tipo_cuidador'
                                                label='Persona que recibe cuidado'
                                                value={initValue(userData['tipo_cuidador'])}
                                                onChange={(e) =>
                                                    setUserData({ ...userData, tipo_cuidador: e.target.value })
                                                }
                                                onBlur={() => handleBlur('tipo_cuidador')}
                                            >
                                                <MenuItem value=''>Seleccione...</MenuItem>
                                                {tipoCuidadorList.map(({ code, value, label }) => (
                                                    <MenuItem key={code} value={code}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                userData['cuidador'] == CODIGO_SI &&
                                                !isValidFieldRequired(userData['tipo_cuidador']) && (
                                                    <FormHelperText>
                                                        {touchedFields.includes('tipo_cuidador') &&
                                                            userData['cuidador'] == CODIGO_SI &&
                                                            !isValidFieldRequired(userData['tipo_cuidador'])
                                                            ? 'Campo requerido'
                                                            : ''}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </Grid>
                                </>)}
                        </Grid>
                        <Grid container direction='row'>
                            <Grid xs={12} md={6} mb={5} pr={2} item={true}>
                                <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip id='button-tooltip' {...props}>
                                            Grupo étnico; Personas con discapacidad; Personas en proceso de
                                            reintegración;
                                        </Tooltip>
                                    )}
                                >
                                    <FormControl sx={{ minWidth: '100%' }} size='medium'>
                                        <InputLabel id='reconoce_focalizada'>
                                            <span>
                                                {' '}
                                                ¿Se reconoce como parte de una población con mayores barreras para conseguir empleo?
                                                <span
                                                    className='bi bi-question-circle-fill'
                                                    style={{ color: '#2266cc', marginLeft: '5px' }}
                                                ></span>
                                            </span>
                                        </InputLabel>
                                        <Select
                                            labelId='reconoce_focalizada'
                                            id='reconoce_focalizada'
                                            label='¿Se reconoce como parte de una población con mayores barreras para conseguir empleo?&nbsp'
                                            value={initValue(userData['reconoce_focalizada'])}
                                            onChange={(e) =>
                                                setUserData({ ...userData, reconoce_focalizada: e.target.value })
                                            }
                                        >
                                            <MenuItem value=''>Seleccione...</MenuItem>
                                            {siNoList.map(({ code, value }) => (
                                                <MenuItem key={code} value={code}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </OverlayTrigger>
                            </Grid>
                            <Grid xs={3} mb={5} pr={2} item={true} />
                            {userData['reconoce_focalizada'] == CODIGO_SI && (
                                <>
                                    <Grid container direction='row' width={'100%'} mb={5}>
                                        <Grid xs={12} md={6} sx={{ display: 'block' }} item={true}>
                                            <FormControl variant="standard" component='fieldset'
                                                required={requiredTipoPoblacion()}
                                                error={errorOnTipoPoblacion()}>
                                                <FormLabel
                                                    component="legend"
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 'bold',
                                                        color: errorOnTipoPoblacion() ? '' : '#2B2B2B !Important',
                                                        marginTop: 2
                                                    }}>
                                                    Tipo de población
                                                </FormLabel>
                                                <FormGroup aria-label='position'>
                                                    {listPoblaciones?.map(({ id, code, value, label }) => (
                                                        <FormControlLabel
                                                            key={id}
                                                            control={
                                                                <Checkbox
                                                                    key={id}
                                                                    color='default'
                                                                    checked={tipoPoblacionChecked?.indexOf(code) > -1}
                                                                    value={code}
                                                                    onChange={handleChangeTipoPoblacion}
                                                                    onBlur={() => handleBlur('tipo_poblacion')}
                                                                /*sx={{
                                                                    "&.Mui-checked": {
                                                                        "&, & + .MuiFormControlLabel-label": {
                                                                            color: "red"
                                                                        }
                                                                    }
                                                                }}*/
                                                                />
                                                            }
                                                            label={label}
                                                            labelPlacement='end'
                                                            onBlur={() => handleBlur('tipo_poblacion')}
                                                        />
                                                    ))}

                                                </FormGroup>
                                                <FormHelperText>
                                                    {errorOnTipoPoblacion() ? 'Campo requerido' : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={12} md={6} mb={5} pr={2} item={true}>
                                            <FormLabel
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color: !isValidFieldRequired(userData['grupo_etnico']) ? '' : '#2B2B2B !Important',
                                                    marginTop: 2,
                                                    marginBottom: 3,
                                                }}
                                            >
                                                Grupo étnico
                                            </FormLabel>
                                            <FormControl component='fieldset'
                                                sx={{ minWidth: '100%', marginBottom: '40px' }}
                                                size='medium'
                                                error={touchedFields.includes('grupo_etnico') && !isValidFieldRequired(userData['grupo_etnico'])}
                                            >

                                                <InputLabel required={userData['reconoce_focalizada'] == CODIGO_SI} id='grupo_etnico'>
                                                    Grupo étnico
                                                </InputLabel>
                                                <Select
                                                    required={userData['reconoce_focalizada'] == CODIGO_SI}
                                                    labelId='grupo_etnico'
                                                    id='grupo_etnico'
                                                    label='Grupo Etnico'
                                                    value={initValue(userData['grupo_etnico'])}
                                                    onBlur={() => handleBlur('grupo_etnico')}
                                                    onChange={(e) => onChangeGrupoEtnico(e.target.value)}
                                                >
                                                    <MenuItem value={''} key={''}>
                                                        Seleccione...
                                                    </MenuItem>
                                                    {listGrupoEtnico.map((item) => (
                                                        <MenuItem key={item.code} value={item.code}>
                                                            {item.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {userData['reconoce_focalizada'] == CODIGO_SI && !isValidFieldRequired(userData['grupo_etnico']) && (
                                                    <FormHelperText>
                                                        {touchedFields.includes('grupo_etnico') && !isValidFieldRequired(userData['grupo_etnico']) ? 'Campo requerido' : ''}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                            {userData['grupo_etnico']?.indexOf(GRUPO_ETNICO_CODIGO_INDIGENA) > -1 && (
                                                <>
                                                    <FormControl component='fieldset'
                                                        sx={{ minWidth: '100%' }}
                                                        size='medium'
                                                        error={touchedFields.includes('grupo_indigena') && !isValidFieldRequired(userData['grupo_indigena'])}
                                                    >
                                                        <InputLabel required id='grupo_indigena'>
                                                            Grupo Indigena
                                                        </InputLabel>
                                                        <Select
                                                            required={
                                                                userData['grupo_etnico']?.indexOf(GRUPO_ETNICO_CODIGO_INDIGENA) > -1
                                                            }
                                                            labelId='grupo_indigena'
                                                            id='grupo_indigena'
                                                            label='Grupo Indigena'
                                                            value={initValue(userData['grupo_indigena'])}
                                                            onChange={(e) => {
                                                                setUserData({
                                                                    ...userData,
                                                                    grupo_indigena: e.target.value
                                                                });
                                                            }}
                                                            onBlur={() => handleBlur('grupo_indigena')}
                                                        >
                                                            <MenuItem value={''} key={''}>
                                                                Seleccione...
                                                            </MenuItem>
                                                            {listGrupoIndigena.map((item) => (
                                                                <MenuItem key={item.code} value={item.code}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                        {userData['grupo_etnico']?.indexOf(GRUPO_ETNICO_CODIGO_INDIGENA) > -1 && !isValidFieldRequired(userData['grupo_indigena']) && (
                                                            <FormHelperText>
                                                                {touchedFields.includes('grupo_indigena') && !isValidFieldRequired(userData['grupo_indigena']) ? 'Campo requerido' : ''}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </>)}
                                            <FormControl component='fieldset'
                                                sx={{ minWidth: '100%' }}
                                            >
                                                <FormLabel
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 'bold',
                                                        marginTop: 2,
                                                        color: !isValidFieldRequired(userData['persona_discapacidad']) ? '' : '#2B2B2B !Important',
                                                        marginBottom: 3,
                                                    }}
                                                >
                                                    Discapacidad
                                                </FormLabel>
                                                <FormControl
                                                    sx={{ minWidth: '100%', marginBottom: 5, }}
                                                    size='medium'
                                                    error={touchedFields.includes('persona_discapacidad') && !isValidFieldRequired(userData['persona_discapacidad'])}
                                                >
                                                    <InputLabel required id='persona_discapacidad'>
                                                        ¿Es usted una persona con discapacidad?
                                                    </InputLabel>
                                                    <Select
                                                        required={userData['reconoce_focalizada'] == CODIGO_SI}
                                                        labelId='persona_discapacidad'
                                                        id='persona_discapacidad'
                                                        label='¿Es usted una persona con discapacidad?'
                                                        value={initValue(userData['persona_discapacidad'])}
                                                        onChange={(e) =>
                                                            setUserData({ ...userData, persona_discapacidad: e.target.value, condicion_discapacidad: e.target.value = 1 ? null : userData['condicion_discapacidad'] })}
                                                        onBlur={() => handleBlur('persona_discapacidad')}
                                                    >
                                                        <MenuItem value=''>Seleccione...</MenuItem>
                                                        {siNoList.map(({ code, value }) => (
                                                            <MenuItem key={code} value={code}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {userData['reconoce_focalizada'] == CODIGO_SI && touchedFields.includes('persona_discapacidad') && !isValidFieldRequired(userData['persona_discapacidad']) && (
                                                        <FormHelperText>
                                                            {!isValidFieldRequired(userData['persona_discapacidad']) ? 'Campo requerido' : ''}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {userData['persona_discapacidad'] == CODIGO_SI && (
                                                    <>
                                                        <FormControl component='fieldset'
                                                            sx={{ minWidth: '100%', marginBottom: 5 }}
                                                            size='medium'
                                                            error={touchedFields.includes('condicion_discapacidad')&& !isValidFieldRequired(userData['condicion_discapacidad'])}
                                                        >
                                                            <InputLabel required id='condicion_discapacidad'>
                                                                Tipo de discapacidad
                                                            </InputLabel>
                                                            <Select
                                                                required={userData['persona_discapacidad'] == CODIGO_SI}
                                                                labelId='condicion_discapacidad'
                                                                id='condicion_discapacidad'
                                                                label='Tipo de discapacidad'
                                                                onBlur={() => handleBlur('condicion_discapacidad')}
                                                                value={initValue(userData['condicion_discapacidad'])}
                                                                onChange={(e) => {
                                                                    onChangeDiscapacidad(e)
                                                                }}
                                                            >
                                                                <MenuItem value={''} key={''}>
                                                                    Seleccione...
                                                                </MenuItem>
                                                                {listDiscapacidad.map(({ code, value, label }) => (
                                                                    <MenuItem key={code} value={code}>
                                                                        {label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {userData['persona_discapacidad'] == CODIGO_SI && !isValidFieldRequired(userData['condicion_discapacidad']) && (
                                                                <FormHelperText>
                                                                    {touchedFields.includes('condicion_discapacidad') && !isValidFieldRequired(userData['condicion_discapacidad']) ? 'Campo requerido' : ''}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </>)}
                                            </FormControl>
                                            <br></br><br></br>
                                            {tipoPoblacionChecked?.indexOf(TIPO_POBLACION_CODIGO_RETORNADO) > -1 && (
                                                <FormControl
                                                    sx={{ minWidth: '100%' }}
                                                    size='medium'
                                                    error={!isValidFieldRequired(userData['pais_retornado'])}
                                                >
                                                    <InputLabel required id='pais_retornado'>
                                                        País del cual fue retornado
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId='pais_retornado'
                                                        id='pais_retornado'
                                                        label='País del cual fue retornado'
                                                        value={initValue(userData['pais_retornado'])}
                                                        onChange={(e) =>
                                                            setUserData({ ...userData, pais_retornado: e.target.value })
                                                        }
                                                    >
                                                        <MenuItem value='' key=''>
                                                            Seleccione...
                                                        </MenuItem>
                                                        {paisList.map(({ id, code, value }) => (
                                                            <MenuItem key={id} value={code}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {!isValidFieldRequired(userData['pais_retornado']) && (
                                                        <FormHelperText>
                                                            {!isValidFieldRequired(userData['pais_retornado'])
                                                                ? 'Campo requerido'
                                                                : ''}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Grid xs={12} mb={1} pr={2} item={true}>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                    Datos de contacto
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true} display={'block'}>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <TextField
                                    disabled
                                    required
                                    fullWidth
                                    type='email'
                                    id='correo_Electronico'
                                    name='correo_Electronico'
                                    label='Correo electrónico'
                                    value={initValue(userData['correo_Electronico'])}
                                    onChange={(e) => setUserData({ ...userData, correo_Electronico: e.target.value })}
                                    onBlur={() => handleBlur('correo_electronico')}
                                    size='medium'
                                    error={touchedFields.includes('correo_electronico') && !isValidFieldRequired(userData['correo_Electronico']) || !isValidEmail(userData['correo_Electronico'])}
                                    helperText={touchedFields.includes('correo_electronico') && !isValidFieldRequired(userData['correo_Electronico']) ? 'Campo requerido' : !isValidEmail(userData['correo_Electronico']) ? 'Correo electrónico no es válido' : ''}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <OverlayTrigger
                                placement='top'
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip id='button-tooltip' {...props}>
                                        {`${TOOL_TIP_TELEPHONE_TEXT}`}
                                    </Tooltip>
                                )}
                            >
                                <TextField
                                    required
                                    type='text'
                                    fullWidth
                                    id='telefono_contacto'
                                    name='telefono_contacto'
                                    label='Teléfono'
                                    value={initValue(userData['telefono_contacto'])}
                                    onChange={(e) => {
                                        let val = e.target.value
                                            ?.replaceAll(/[^\d]/g, '')
                                            ?.replaceAll('-', '')
                                            ?.replaceAll('+', '');
                                        let reg = /[0-9]|\./;
                                        if (reg.test(val) || val === '')
                                            setUserData({
                                                ...userData,
                                                telefono_contacto: formatMaskPhoneNumber12Digits(val),
                                            });
                                    }}
                                    onBlur={() => handleBlur('telefono_contacto')}
                                    size='medium'
                                    error={touchedFields.includes('telefono_contacto') && !isValidColombianPhoneNumber(userData['telefono_contacto'], true)}
                                    helperText={touchedFields.includes('telefono_contacto') ? helperIsValidColombianPhoneNumber(userData['telefono_contacto'], true) : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <i className='bi bi-question-circle-fill'
                                                    style={{ color: '#2266cc' }}></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                            </OverlayTrigger>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <OverlayTrigger
                                placement='top'
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip id='button-tooltip' {...props}>
                                        {`${TOOL_TIP_TELEPHONE_TEXT}`}
                                    </Tooltip>
                                )}
                            >
                                <TextField
                                    required={
                                        isValidFieldRequired(userData['otro_telefono']) &&
                                        !isValidPhoneNumber(userData['otro_telefono'])
                                    }
                                    type='text'
                                    fullWidth
                                    id='otro_telefono'
                                    name='otro_telefono'
                                    label='Otro teléfono'
                                    value={initValue(userData['otro_telefono'])}
                                    onChange={(e) => {
                                        let val = e.target.value
                                            ?.replaceAll(/[^\d]/g, '')
                                            ?.replaceAll('-', '')
                                            ?.replaceAll('+', '');
                                        let reg = /[0-9]|\./;
                                        if (reg.test(val) || val === '')
                                            setUserData({
                                                ...userData,
                                                otro_telefono: formatMaskPhoneNumber12Digits(val),
                                            });
                                    }}
                                    onBlur={() => handleBlur('otro_telefono')}
                                    size='medium'
                                    error={touchedFields.includes('otro_telefono') && !isValidColombianPhoneNumber(userData['otro_telefono'])}
                                    helperText={touchedFields.includes('otro_telefono') ? helperIsValidColombianPhoneNumber(userData['otro_telefono']) : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <i
                                                    className='bi bi-question-circle-fill'
                                                    style={{ color: '#2266cc', marginLeft: '5px' }}
                                                ></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                            </OverlayTrigger>
                        </Grid>
                        <Grid xs={12} mb={5} pr={2} item={true}>
                            <TextField
                                fullWidth
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                value={initValue(userData['observaciones'])}
                                onChange={(e) => setUserData({ ...userData, observaciones: e.target.value })}
                                onBlur={() => handleBlur('observaciones')}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 1200, minLength: 10 }}
                                size='medium'
                                error={touchedFields.includes('observaciones') && !isValidMinMaxText(userData['observaciones'], 10, 1200)}
                                helperText={touchedFields.includes('observaciones') ? helperValidMinMaxText(userData['observaciones'], 10, 1200) : ''}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                        <Button variant='contained' sx={{ mr: 1, width: 90, height: 40 }} disabled>
                            Atrás
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
                            onClick={handleSubmit}
                        >
                            Siguiente
                        </Button>
                    </Box>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            variant={'filled'}
                            severity={alert ? 'success' : 'error'}
                            sx={{ whiteSpace: 'pre-line' }}
                        >
                            {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                            {alertMsg}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    );
};
