import { useEffect, useState } from 'react';
import { ParameterConfigMmodel } from '../../models/parameter.model';
import parameterSingleton from '../../services/Parameters.service';
import parameters from '../../util/parameters';

const useAdminPositioTypeParams = () => {
	const [cargoAdministradorList, setCargoAdministradorList] = useState<ParameterConfigMmodel[]>([]);
    
    useEffect(() => {
		getAllParameters();
	}, []);

	const getAllParameters = async () => {
		await fetchCargoAdministradorParams();
	};

	const fetchCargoAdministradorParams = async ()=>{
		const res = await parameterSingleton.fetchData(parameters.CARGOADMINISTRADOR);
		setCargoAdministradorList(res);
	}

	return {
		cargoAdministradorList,
	};
};

export default useAdminPositioTypeParams;
