import { FormEvent, useContext, useState, useEffect } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Box,
	Typography,
	Divider,
	FormControlLabel,
	Alert,
	Snackbar,
	AlertTitle,
	Checkbox,
	FormHelperText,
	InputAdornment,
	SelectChangeEvent,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import _ from 'lodash';
import { createCurriculo } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import {
	formatMaskPhoneNumber12Digits,
	getDateNowToString,
	initValue,
	isValidFieldRequired,
	isValidPhoneNumber,
	setValueOrDefault,
	convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber
} from '../../../../../util/Index';
import useLaboralExperienceParams from '../../../../../hooks/parameters/useLaboralExperienceParams';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs, { Dayjs } from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

export const Step6 = () => {
	const { setStep, userData, setUserData, setCurrentProgress } = useContext(multiStepContext);
	const {
		listPais,
		listSiNo,
		otrosConocimientosList,
		listSectorIndustrial,
		listTipoExperienciaLaboral,
	} = useLaboralExperienceParams();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [editMode, setEditMode] = useState(false);
	const [openRowEdit, setOpenRowEdit] = useState<number>(0);
	const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');

	const [display, setDisplay] = useState<string>('');
	const [tieneExperienciaLaboral, setTieneExperienciaLaboral] = useState<string>('');
	const [tpExperienciaLaboral, setTpExperienciaLaboral] = useState<string>('');
	const [productoServicio, setProductoServicio] = useState<string>('');
	const [cuaPersonasTrabajan, setCuaPersonasTrabajan] = useState<string>('');
	const [sector, setSector] = useState<string>('');
	const [cargo, setCargo] = useState<string>('');
	const [cargoEquivalente, setCargoEquivalente] = useState<string>('');
	const [nombreEmpresa, setNombreEmpresa] = useState<string>('');
	const [telefonoEmpresa, setTelefonoEmpresa] = useState<string>('');
	const [paisEmpresa, setPaisEmpresa] = useState<string>('');
	const [fechaIngresso, setFechaIngresso] = useState<string>('');
	const [trabajandoMismoTrabajo, setTrabajandoMismoTrabajo] = useState<boolean>(false);
	const [fechaRetiro, setFechaRetiro] = useState<string>('');
	const [errorRetiro, setErrorRetiro] = useState(false)
	const [funcionesLogros, setFuncionesLogros] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [step, setStepUser] = useState<string>('step6');
	const [editOrCreateItemInProgress, setEditOrCreateItemInProgress] = useState(false);
	const [touchedFields, setTouchedFields] = useState<string[]>([]);

	const [rowsExperienciaLaboral, setRowsExperienciaLaboral] = useState([
		{
			id: 0,
			tpExperienciaLaboral: '',
			productoServicio: '',
			cuaPersonasTrabajan: '',
			sector: '',
			cargo: '',
			cargoEquivalente: '',
			nombreEmpresa: '',
			telefonoEmpresa: '',
			paisEmpresa: '',
			fechaIngresso: '',
			trabajandoMismoTrabajo: false,
			fechaRetiro: '',
			funcionesLogros: '',
		},
	]);

	//Flag para saber si ha cambiado el input de Tipo de experiencia laboral
	const [changedTipoExperienciaLaboral, setChangedTipoExperienciaLaboral] = useState<boolean>(false);

	//Metodo de control para evento de cambio del input: 'Tipo de experiencia laboral'
	const onChangeTipoExperienciaLaboral = (e: SelectChangeEvent) => {
		setTpExperienciaLaboral(e.target.value)
		setChangedTipoExperienciaLaboral(isValidFieldRequired(e.target.value) ? true : false);
		setEditOrCreateItemInProgress(true);
	}

	const CODIGO_SI = '1';

	useEffect(() => {
		let tiene_experiencia_laboral = userData['tiene_experiencia_laboral'] === true ? CODIGO_SI : (userData['tiene_experiencia_laboral'] === false ? '0' : '');
		setTieneExperienciaLaboral(tiene_experiencia_laboral);
		if (
			userData['CurriculoExperienciaLaborals'] !== null &&
			userData['CurriculoExperienciaLaborals'] !== undefined &&
			(userData['CurriculoExperienciaLaborals'] as Array<any>).length > 0
		) {
			const listRowsExperienciaLaboral = rowsExperienciaLaboral.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsExperienciaLaboral) {
				//console.log('complementos', rowsExperienciaLaboral);
				const list = [...rowsExperienciaLaboral];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsExperienciaLaboral(list);
			}

			///rowsComplementos.push(createObjectDirecaoAtuliza());
			setRowsExperienciaLaboral(userData['CurriculoExperienciaLaborals']);
			setDisplay('none');
			setTieneExperienciaLaboral(CODIGO_SI);
			setUserData({ ...userData, tiene_experiencia_laboral: true });
		} else {
			const listRowsExperienciaLaboral = rowsExperienciaLaboral.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsExperienciaLaboral) {
				//console.log('complementos', rowsExperienciaLaboral);
				const list = [...rowsExperienciaLaboral];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsExperienciaLaboral(list);
			}
		}
	}, []);

	const handleclear = () => {
		setChangedTipoExperienciaLaboral(false);
		setTpExperienciaLaboral('');
		setProductoServicio('');
		setCuaPersonasTrabajan('');
		setSector('');
		setCargo('');
		setCargoEquivalente('');
		setTelefonoEmpresa('');
		setNombreEmpresa('');
		setPaisEmpresa('');
		setFechaIngresso('');
		setTrabajandoMismoTrabajo(false);
		setFechaRetiro('');
		setFuncionesLogros('');
		setOpenRowEdit(0);
		setEditMode(false);
		setEditOrCreateItemInProgress(false);
	};


	const handleValidOnSave = () => {

		let errors: { field: string; error: string }[] = [];

		let camposError: string[] = [];

		//Se ajusta la regla, cuando el usuario ha seleccionado ¿Tiene experiencia laboral? : SI, entonces debe diligenciar informacion de almenos una Experiencia
		if (!isValidFieldRequired(tieneExperienciaLaboral)) {
			errors.push({ field: '¿Tiene experiencia laboral?', error: 'Debe indicar si tiene o no experiencia laboral' });
			camposError.push('tieneExperienciaLaboral');
		}
		else if (tieneExperienciaLaboral === "1" && rowsExperienciaLaboral.length === 0)
			errors.push({ field: 'Experiencia Laboral', error: 'Debe diligenciar información de la experiencia laboral' });

		if (changedTipoExperienciaLaboral && !isValidFieldRequired(tpExperienciaLaboral)) {
			errors.push({ field: 'Tipo de experiencia Laboral', error: 'Campo requerido' });
			camposError.push('tpExperienciaLaboral');
		}

		if (changedTipoExperienciaLaboral && isValidFieldRequired(tpExperienciaLaboral)) {
			if (!isValidFieldRequired(sector)) {
				errors.push({ field: 'Sector', error: 'Campo requerido' });
				camposError.push('sector');
			}
			if (!isValidFieldRequired(cargo)) {
				errors.push({ field: 'Cargo', error: 'Campo requerido' });
				camposError.push('cargo');
			}
			if (!isValidFieldRequired(cargoEquivalente)) {
				errors.push({ field: 'Cargo Equivalente', error: 'Campo requerido' });
				camposError.push('cargoEquivalente');
			}
			if (!isValidFieldRequired(nombreEmpresa)) {
				errors.push({ field: 'Nombre de la Empresa', error: 'Campo requerido' });
				camposError.push('nombreEmpresa');
			}
			if (!isValidColombianPhoneNumber(telefonoEmpresa, false)) {
				errors.push({
					field: 'Teléfono de la Empresa',
					error: helperIsValidColombianPhoneNumber(telefonoEmpresa, false),
				});
				camposError.push('telefonoEmpresa');
			}
			if (!isValidFieldRequired(paisEmpresa)) {
				errors.push({ field: 'Pais', error: 'Campo requerido' });
				camposError.push('paisEmpresa');
			}
			if (!isValidFieldRequired(fechaIngresso)) {
				errors.push({ field: 'Fecha Ingreso', error: 'Campo requerido' });
				camposError.push('fechaingreso');
			}
			if (!trabajandoMismoTrabajo)
				if (!isValidFieldRequired(fechaRetiro)) {
					if (errorRetiro) {
						errors.push({ field: 'Fecha de retiro', error: 'La fecha de retiro no puede ser anterior a la fecha de ingreso' });
						camposError.push('fechaingreso');
					}
					else {
						errors.push({ field: 'Fecha de retiro', error: 'Campo requerido' });
						camposError.push('fechaingreso');
					}
				}
				else {
					if (!compareDates(fechaIngresso, fechaRetiro)) {
						errors.push({
							field: 'Fecha de retiro',
							error: 'La fecha de retiro no puede ser anterior a la fecha de ingreso',
						});
						camposError.push('fechaingreso');
					}
				}

			if (!isValidFieldRequired(funcionesLogros)) {
				errors.push({ field: 'Funciones Logros', error: 'Campo requerido' });
				camposError.push('funcionesLogros');
			}
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos completado\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;

	};
	const handleAddExperienciaLaboral = () => {
		if (handleValidOnSubmit()) {
			const listTipoExperenciaLab = listTipoExperienciaLaboral.find((obj) => {
				return obj.code === tpExperienciaLaboral || obj.value === tpExperienciaLaboral;
			});
			const listSector = listSectorIndustrial.find((obj) => {
				return obj.code === sector || obj.value === sector;
			});
			const listPaisEmpresa = listPais.find((obj) => {
				return obj.code === paisEmpresa || obj.value === paisEmpresa;
			});

			let idTipoExperenciaLab = listTipoExperenciaLab?.code as string;
			let idSector = listSector?.code as string;
			let idPaisEmpresa = listPaisEmpresa?.code as string;

			setTouchedFields([]);

			setRowsExperienciaLaboral([
				...rowsExperienciaLaboral,
				{
					id: new Date().getTime(),
					tpExperienciaLaboral: idTipoExperenciaLab,
					productoServicio: productoServicio,
					cuaPersonasTrabajan: cuaPersonasTrabajan,
					sector: idSector,
					cargo: cargo,
					cargoEquivalente: cargoEquivalente,
					nombreEmpresa: nombreEmpresa,
					telefonoEmpresa: telefonoEmpresa,
					paisEmpresa: idPaisEmpresa,
					fechaIngresso: fechaIngresso,
					trabajandoMismoTrabajo: trabajandoMismoTrabajo,
					fechaRetiro: fechaRetiro,
					funcionesLogros: funcionesLogros,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Experiencia laboral agregada con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			handleclear();
			setDisplay('none');
			setEditOrCreateItemInProgress(false);
		}
	};
	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveExperienciaLaboral = (id: number) => {
		const list = [...rowsExperienciaLaboral];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsExperienciaLaboral(list);

		if (list.length === 0) {
			setDisplay('');
			setTieneExperienciaLaboral('');
		}
	};

	const handleNext = () => {
		setUserData({ ...userData, CurriculoExperienciaLaborals: rowsExperienciaLaboral });
		setStep(7);
	};

	const handleRetorno = () => {
		setUserData({ ...userData, CurriculoExperienciaLaborals: rowsExperienciaLaboral });
		setStep(5);
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];
		if (!isValidFieldRequired(tpExperienciaLaboral)) {
			errors.push({ field: 'Tipo de experiencia laboral', error: 'Campo requerido' });
			camposError.push('tieneExperienciaLaboral');
		}

		//Valida los demas campos solo si Hay un tipo experiencia laboral seleccionada
		if (isValidFieldRequired(tpExperienciaLaboral)) {
			if (!isValidFieldRequired(sector)) {
				errors.push({ field: 'Sector', error: 'Campo requerido' });
				camposError.push('sector');
			}
			if (!isValidFieldRequired(cargo)) {
				errors.push({ field: 'Cargo', error: 'Campo requerido' });
				camposError.push('cargo');
			}
			if (!isValidFieldRequired(cargoEquivalente)) {
				errors.push({ field: 'Cargo equivalente', error: 'Campo requerido' });
				camposError.push('cargoEquivalente');
			}
			if (!isValidFieldRequired(nombreEmpresa)) {
				errors.push({ field: 'Nombre de la empresa', error: 'Campo requerido' });
				camposError.push('nombreEmpresa');
			}
			if (!isValidFieldRequired(paisEmpresa)) {
				errors.push({ field: 'País', error: 'Campo requerido' });
				camposError.push('paisEmpresa');
			}
			if (!isValidFieldRequired(fechaIngresso)) {
				errors.push({ field: 'Fecha de ingreso', error: 'Campo requerido' });
				camposError.push('fechaingreso');
			}

			if (isValidFieldRequired(telefonoEmpresa) && !isValidPhoneNumber(telefonoEmpresa)) {
				errors.push({
					field: 'teléfono_contacto',
					error: 'Teléfono de contacto no es válido',
				});
				camposError.push('telefonoEmpresa');
			}

			if (!trabajandoMismoTrabajo)
				if (!isValidFieldRequired(fechaRetiro)) {
					if (errorRetiro) {
						errors.push({ field: 'Fecha de retiro', error: 'La fecha de retiro no puede ser anterior o igual a la fecha de ingreso' });
						camposError.push('fechaingreso');
					}
					else {
						errors.push({ field: 'Fecha de retiro', error: 'Campo requerido' });
						camposError.push('fechaingreso');
					}
				}
				else {
					if (!compareDates(fechaIngresso, fechaRetiro)) {
						errors.push({
							field: 'Fecha de retiro',
							error: 'La fecha de retiro no puede ser anterior a la fecha de ingreso',
						});
						camposError.push('fechaingreso');
					}
				}

			if (!isValidLogros(funcionesLogros)) {
				errors.push({
					field: 'Funciones y logros',
					error: msgLogros(funcionesLogros).split('\n')[0],
				});
				camposError.push('funcionesLogros');
			}
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};

	const handleClose = () => {
		setOpen(false);
	};

	async function handleSubmit(event: FormEvent) {
		if (handleValidOnSave()) {
			setUserData({ ...userData, CurriculoExperienciaLaborals: rowsExperienciaLaboral });
			setLoading(true);
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(7);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};
	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						otrosConocimientosList.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			rowsExperienciaLaboral !== null &&
			rowsExperienciaLaboral !== undefined &&
			(rowsExperienciaLaboral as Array<any>).length > 0
		) {
			(rowsExperienciaLaboral as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
			tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
			tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'], 'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),

			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
			tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
			tiene_nivel_educativo: userData['tiene_nivel_educativo'],
			tiene_experiencia_laboral: createObjectExpLaboral()?.length > 0 ? true : (tieneExperienciaLaboral === CODIGO_SI ? true : (tieneExperienciaLaboral === '0' ? false : userData['tiene_experiencia_laboral'])),
			tiene_capacitaciones: userData['tiene_capacitaciones'],
		};

		return resume;
	};

	const showDatePickeRetiro = () => {
		// @ts-ignore
		document.querySelector('#fecha_Retiro').showPicker();
	};

	const showDatePickerIngresso = () => {
		// @ts-ignore
		document.querySelector('#fecha_Ingresso').showPicker();
	};

	const msgLogros = (value: string) => {
		if (value === undefined || value === '') return `Campo requerido\n\n0/1200`;

		if (value.length < 10)
			return `¡El campo debe tener al menos 10 caracteres!\n\n${value.length}/1200`;

		return `${value.length}/1200`;
	};

	const isValidLogros = (value: string) => {
		if (value === undefined || value === '') return false;

		if (value.length < 10) return false;

		return true;
	};
	const onChangeTieneExperienciaLaboral = (e: SelectChangeEvent) => {
		let tieneExperienciaLaboral = null;
		if (e.target.value == CODIGO_SI) {
			setTieneExperienciaLaboral(e.target.value)
			if (rowsExperienciaLaboral && rowsExperienciaLaboral.length === 0) {
				setChangedTipoExperienciaLaboral(true);
			}
			tieneExperienciaLaboral = true;
		} else {
			setTieneExperienciaLaboral('0')
			handleclear();
			tieneExperienciaLaboral = false;
		}
		userData['tiene_experiencia_laboral'] = tieneExperienciaLaboral;
	}

	const handleEdit = (item: any) => {
		setEditOrCreateItemInProgress(true);
		setEditMode(true);
		setOpenRowEdit(item.id);
		setBorderColorEdit('2px solid blue');
		setTimeout(() => {
			setBorderColorEdit('1px solid');
		}, 4000);
		window.scrollTo(0, 170);
		//console.log('item', item);
		setTieneExperienciaLaboral(CODIGO_SI);
		setTpExperienciaLaboral(listTipoExperienciaLaboral.find((x: any) => x.value === item.tpExperienciaLaboral || x.code === item.tpExperienciaLaboral)?.code || '');
		setSector(listSectorIndustrial.find((x: any) => x.value === item.sector || x.code === item.sector)?.code || '');
		setCargo(item.cargo || '');
		setCargoEquivalente(item.cargoEquivalente || '');
		setNombreEmpresa(item.nombreEmpresa || '');
		setTelefonoEmpresa(item.telefonoEmpresa || '');
		setPaisEmpresa(listPais.find((x) => x.value === item.paisEmpresa || x.code === item.paisEmpresa)?.code || '');
		setTrabajandoMismoTrabajo(item.trabajandoMismoTrabajo || '');
		setFechaIngresso(item.fechaIngresso || '');
		setFechaRetiro(item.fechaRetiro || '');
		setFuncionesLogros(item.funcionesLogros || '');
		setChangedTipoExperienciaLaboral(true)
	};

	const handleSaveEdit = () => {
		if (editMode) {
			let item = rowsExperienciaLaboral.find((x) => x.id === openRowEdit);
			//console.log('item edit', item);
			let iRemove = rowsExperienciaLaboral.findIndex((x) => x.id === openRowEdit);
			if (handleValidOnSubmit()) {
				const listTipoExperenciaLab = listTipoExperienciaLaboral.find((obj) => {
					return obj.code === tpExperienciaLaboral || obj.value === tpExperienciaLaboral;
				});
				const listSector = listSectorIndustrial.find((obj) => {
					return obj.code === sector || obj.value === sector;
				});
				const listPaisEmpresa = listPais.find((obj) => {
					return obj.code === paisEmpresa || obj.value === paisEmpresa;
				});

				let idTipoExperenciaLab = listTipoExperenciaLab?.code as string;
				let idSector = listSector?.code as string;
				let idPaisEmpresa = listPaisEmpresa?.code as string;

				let itemsToSave = rowsExperienciaLaboral.filter((_, i) => i !== iRemove);

				itemsToSave.push({
					id: openRowEdit || new Date().getTime(),
					tpExperienciaLaboral: idTipoExperenciaLab,
					productoServicio: productoServicio,
					cuaPersonasTrabajan: cuaPersonasTrabajan,
					sector: idSector,
					cargo: cargo,
					cargoEquivalente: cargoEquivalente,
					nombreEmpresa: nombreEmpresa,
					telefonoEmpresa: telefonoEmpresa,
					paisEmpresa: idPaisEmpresa,
					fechaIngresso: fechaIngresso,
					trabajandoMismoTrabajo: trabajandoMismoTrabajo,
					fechaRetiro: fechaRetiro,
					funcionesLogros: funcionesLogros,
				});

				setRowsExperienciaLaboral(itemsToSave);
				handleclear();
				setEditOrCreateItemInProgress(false);
			}
		}
	};

	const compareDates = (date1: string, date2: string) => {
		let dt1 = new Date(date1).setHours(0, 0, 0, 0);
		let dt2 = new Date(date2).setHours(0, 0, 0, 0);

		return (dt2 > dt1);
	};

	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}
	/**
	 * Metodo de evento de campo de tipo de valor para el checkbox '¿Este es su empleo actual?'
	 * @param value 
	 */
	const onChengeTrabajandoMismoTrabajo = (value: any) => {
		if (Boolean(value) === true) {
			setFechaRetiro('')
		}
		setTrabajandoMismoTrabajo(value)
	}

	return (
		<>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Agregar experiencia laboral{editMode ? ' - modo de edición' : ''}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					// border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
					border: `${borderColorEdit}`,
				}}
				item
			>
				<Grid xs={4} mb={5} pr={2} display={display} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={
							touchedFields.includes('tieneExperienciaLaboral')
							&& !isValidFieldRequired(tieneExperienciaLaboral)
						}
					>
						<InputLabel required id={'tieneExperienciaLaboral'}>¿Tiene experiencia laboral?</InputLabel>
						<Select
							required
							labelId={'tieneExperienciaLaboral'}
							id={'tieneExperienciaLaboral'}
							label='¿Tiene experiencia laboral?'
							value={tieneExperienciaLaboral}
							onChange={(e) => onChangeTieneExperienciaLaboral(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listSiNo.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{touchedFields.includes('tieneExperienciaLaboral')
							&& !isValidFieldRequired(tieneExperienciaLaboral)
							&& (
								<FormHelperText>
									Debe indicar si tiene o no experiencia laboral
								</FormHelperText>
							)
						}
					</FormControl>
				</Grid>

				{tieneExperienciaLaboral === CODIGO_SI && (
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('tpExperienciaLaboral') && changedTipoExperienciaLaboral && !isValidFieldRequired(tpExperienciaLaboral)}
							>
								<InputLabel required id={'tpExperienciaLaboral'}>
									Tipo de experiencia laboral
								</InputLabel>
								<Select
									required
									labelId={'tpExperienciaLaboral'}
									id={'tpExperienciaLaboral'}
									label='Tipo de experiencia laboral'
									value={tpExperienciaLaboral}
									onChange={(e) => onChangeTipoExperienciaLaboral(e)}
									onBlur={() => handleBlur('tpExperienciaLaboral')}
								>
									<MenuItem value={''}>Seleccione</MenuItem>
									{listTipoExperienciaLaboral.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('tpExperienciaLaboral') && changedTipoExperienciaLaboral && !isValidFieldRequired(tpExperienciaLaboral) && (
									<FormHelperText>
										{!isValidFieldRequired(tpExperienciaLaboral) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{/* sector campo select */}
						<Grid xs={12} md={8} mb={5} item>
							<FormControl
								className='col-6'
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('sector') && changedTipoExperienciaLaboral && !isValidFieldRequired(sector)}
							>
								<InputLabel required id='sector'>
									Sector
								</InputLabel>
								<Select
									required
									labelId={'sector'}
									id={'sector'}
									label='Sector'
									value={sector}
									onChange={(e) => setSector(e.target.value)}
									onBlur={() => handleBlur('sector')}
								>
									<MenuItem value={''}>{'Seleccione'}</MenuItem>
									{listSectorIndustrial.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('sector') && changedTipoExperienciaLaboral && !isValidFieldRequired(sector) && (
									<FormHelperText>
										{!isValidFieldRequired(sector) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								required
								id={'cargo'}
								name={'cargo'}
								label='Cargo'
								value={cargo}
								onChange={(e) => setCargo(e.target.value)}
								onBlur={() => handleBlur('cargo')}
								placeholder='Ingrese el cargo'
								size='medium'
								error={touchedFields.includes('cargo') && changedTipoExperienciaLaboral && !isValidFieldRequired(cargo)}
								helperText={touchedFields.includes('cargo') && changedTipoExperienciaLaboral && !isValidFieldRequired(cargo) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								required
								id={'cargoEquivalente'}
								name={'cargoEquivalente'}
								label='Cargo equivalente'
								value={cargoEquivalente}
								onChange={(e) => setCargoEquivalente(e.target.value)}
								onBlur={() => handleBlur('caroEquivalente')}
								placeholder='Busque el cargo'
								size='medium'
								error={touchedFields.includes('cargoEquivalente') && changedTipoExperienciaLaboral && !isValidFieldRequired(cargoEquivalente)}
								helperText={touchedFields.includes('cargoEquivalente') && changedTipoExperienciaLaboral && !isValidFieldRequired(cargoEquivalente) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} item>
							<TextField
								fullWidth
								required
								id={'nombreEmpresa'}
								name={'nombreEmpresa'}
								label='Nombre de la empresa'
								value={nombreEmpresa}
								onChange={(e) => setNombreEmpresa(e.target.value)}
								onBlur={() => handleBlur('nombreEmpresa')}
								placeholder='Escriba el nombre'
								size='medium'
								error={touchedFields.includes('nombreEmpresa') && changedTipoExperienciaLaboral && !isValidFieldRequired(nombreEmpresa)}
								helperText={touchedFields.includes('nombreEmpresa') && changedTipoExperienciaLaboral && !isValidFieldRequired(nombreEmpresa) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								required={
									isValidFieldRequired(telefonoEmpresa) && !isValidPhoneNumber(telefonoEmpresa)
								}
								fullWidth
								id={'telefonoEmpresa'}
								name={'telefonoEmpresa'}
								label='Teléfono de la empresa'
								placeholder='Escribe el telefono'
								size='medium'
								type='text'
								value={initValue(telefonoEmpresa)}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
										?.replaceAll('-', '')
										?.replaceAll('+', '');
									let reg = /[0-9]|\./;
									if (reg.test(val) || val === '')
										setTelefonoEmpresa(formatMaskPhoneNumber12Digits(val));
								}}
								onBlur={() => handleBlur('telefonoEmpresa')}
								error={touchedFields.includes('telefonoEmpresa') && !isValidColombianPhoneNumber(telefonoEmpresa)}
								helperText={touchedFields.includes('telefonoEmpresa') ? helperIsValidColombianPhoneNumber(telefonoEmpresa) : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
										</InputAdornment>
									),
								}}
								inputProps={{
									maxLength: 10,
								}}
							/>
						</Grid>
						<Grid xs={12} md={8} mb={5} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('paisEmpresa') && changedTipoExperienciaLaboral && !isValidFieldRequired(paisEmpresa)}
							>
								<InputLabel required id={'paisEmpresa'}>
									País
								</InputLabel>
								<Select
									required
									labelId={'paisEmpresa'}
									id={'paisEmpresa'}
									label='País'
									value={paisEmpresa}
									onChange={(e) => setPaisEmpresa(e.target.value)}
									onBlur={() => handleBlur('paisEmpresa')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listPais.map(({ code, value }, i) => (
										<MenuItem key={i} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('paisEmpresa') && changedTipoExperienciaLaboral && !isValidFieldRequired(paisEmpresa) && (
									<FormHelperText>
										{!isValidFieldRequired(paisEmpresa) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>

						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControlLabel
								value={trabajandoMismoTrabajo}
								control={
									<Checkbox
										color='default'
										checked={trabajandoMismoTrabajo ? true : false}
										value={trabajandoMismoTrabajo}
										onChange={(e) => onChengeTrabajandoMismoTrabajo(e.target.checked)}
									/>
								}
								label='¿Este es su empleo actual?'
								labelPlacement='end'
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
								<DemoItem>
									<DatePicker
										disableFuture
										value={dayjs(fechaIngresso, 'YYYY-MM-DD')}
										format='DD/MM/YYYY'
										onOpen={() => handleBlur('fechaingreso')}
										onChange={(newValue) => {
											// Si la fecha de retiro ya tiene un valor y la nueva fecha de ingreso es igual a la fecha de retiro,
											// entonces establecer la fecha de retiro como vacía ('')
											if (fechaRetiro && newValue && (dayjs(newValue).isAfter(dayjs(fechaRetiro)) || dayjs(newValue).isSame(dayjs(fechaRetiro)))) {
												setFechaRetiro('');
												setErrorRetiro(true);
											}
											// else{setErrorRetiro(false)}
											// Actualizar la fecha de ingreso
											setFechaIngresso(newValue ? newValue.format('YYYY-MM-DD') : '');
										}}
										slotProps={{
											textField: {
												id: 'fecha_Ingresso',
												name: fechaIngresso,
												label: 'Fecha de ingreso',
												required: true,
												variant: 'outlined',
												error: touchedFields.includes('fechaingreso') && !isValidFieldRequired(fechaIngresso),
												helperText: touchedFields.includes('fechaingreso') && !isValidFieldRequired(fechaIngresso) ? 'Campo requerido' : ''
											},
											actionBar: { actions: ["clear", "cancel", "today"] }
										}}
									/>
								</DemoItem>
							</LocalizationProvider>
						</Grid>
						{!trabajandoMismoTrabajo && (
							<Grid xs={12} md={4} mb={5} item>
								<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
									<DemoItem>
										<DatePicker
											disableFuture
											value={dayjs(fechaRetiro, 'YYYY-MM-DD')}
											format='DD/MM/YYYY'
											onOpen={() => handleBlur('fecha_retiro')}
											onChange={(newValue) => {
												// Verificar si la nueva fecha de retiro es igual o menor a la fecha de ingreso
												if (newValue && (dayjs(newValue).isBefore(dayjs(fechaIngresso)) || dayjs(newValue).isSame(dayjs(fechaIngresso)))) {
													// Si es igual o menor, no actualizar la fecha de retiro
													setFechaRetiro('');
													setErrorRetiro(true)
												} else {
													setErrorRetiro(false)
													// Si no es igual ni menor, actualizar la fecha de retiro
													setFechaRetiro(newValue ? newValue.format('YYYY-MM-DD') : '');
												}
											}}
											slotProps={{
												textField: {
													id: 'fechaRetiro',
													name: fechaRetiro,
													label: 'Fecha de retiro',
													required: true,
													variant: 'outlined',
													error: touchedFields.includes('fechaingreso') && !isValidFieldRequired(fechaRetiro),
													helperText: touchedFields.includes('fechaingreso') && !isValidFieldRequired(fechaRetiro)
														? (errorRetiro ? 'No puede ser previo o igual a la fecha de ingreso' : 'Campo requerido')
														: ''
												},
												actionBar: { actions: ["clear", "cancel", "today"] }
											}}
										/>
									</DemoItem>
								</LocalizationProvider>
							</Grid>
						)}
						<Grid xs={trabajandoMismoTrabajo ? 4 : 0} mb={5} item />
						<Grid xs={12} mb={5} item>
							<TextField
								fullWidth
								required
								id={'funcionesLogros'}
								name={'funcionesLogros'}
								label='Funciones y logros'
								value={funcionesLogros}
								onChange={(e) => setFuncionesLogros(e.target.value)}
								onBlur={() => handleBlur('funcionesLogros')}
								placeholder='Describe lo que hiciste'
								multiline
								rows={4}
								size='medium'
								error={touchedFields.includes('funcionesLogros') && changedTipoExperienciaLaboral && !isValidLogros(funcionesLogros)}
								helperText={touchedFields.includes('funcionesLogros') && !changedTipoExperienciaLaboral ? '' : msgLogros(funcionesLogros)}
								inputProps={{ maxLength: 1200, minLength: 10 }}
							/>
						</Grid>
					</Grid>
				)}

				<Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>

					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						{editMode ? (
							<>
								<Button
									variant='contained'
									onClick={handleSaveEdit}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Guardar
								</Button>
							</>
						) : (
							<>
								<Button
									disabled={tieneExperienciaLaboral === '0' || tieneExperienciaLaboral === ''}
									variant='contained'
									onClick={handleAddExperienciaLaboral}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Agregar
								</Button>
							</>
						)
						}
						{editOrCreateItemInProgress && (
							<Button
								variant='contained'
								sx={{ width: 90, background: '#6c757d', mr: 1 }}
								onClick={handleclear}
							>
								Cancelar
							</Button>
						)}
					</Box>

				</Grid>
			</Grid>
			{rowsExperienciaLaboral.length > 0 && (
				<Grid xs={12} item>
					<Typography color='#2B2B2B' variant='h6'>
						Agregados
					</Typography>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
				</Grid>
			)}
			<Grid xs={12} item>
				{rowsExperienciaLaboral.map((row, i) => (
					<Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							sx={{
								backgroundColor: '#1C447F',
								pt: 2,
								pl: 2,
								pb: 2,
								pr: 2,
								borderTopLeftRadius: 6,
								borderTopRightRadius: 6,
							}}
							item
						>
							<Typography color='white' variant='h6'>
								{' '}
								{row.cargo || ''}
							</Typography>
							<Box display='flex' justifyContent='end' alignItems='center'>
								<div>
									<div className='d-flex justify-content-end flex-shrink-0'>
										<button
											disabled={editOrCreateItemInProgress}
											className='btn btn-icon btn-active-color-secondary btn-sm me-3'
											style={{ background: '#C4D2E8' }}
											onClick={() => handleEdit(row)}
										>
											<EditIcon />
										</button>

										<button
											disabled={editOrCreateItemInProgress}
											className='btn btn-icon btn-active-color-secondary btn-sm'
											onClick={() => handleRemoveExperienciaLaboral(row.id)}
											style={{ background: '#DD1342' }}
										>
											<DeleteOutlinedIcon style={{ color: 'white' }} />
										</button>
									</div>
								</div>
							</Box>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							mb={5}
							sx={{
								border: 1,
								borderBottom: 3,
								borderColor: '#AFADAD',
								borderBottomLeftRadius: 6,
								borderBottomRightRadius: 6,
								pt: 5,
								pl: 3,
								pr: 3,
							}}
							item
						>
							<Grid xs={12} md={3} mb={5} mr={5} item>
								<Typography>
									{'Tipo de experiencia laboral: ' + (listTipoExperienciaLaboral.find((obj) => obj.code === row.tpExperienciaLaboral || obj.value === row.tpExperienciaLaboral)?.value || '')}
								</Typography>
								<Typography>{'Sector:  ' + (listSectorIndustrial.find((obj) => obj.code === row.sector || obj.value === row.sector)?.value || '')}</Typography>
								<Typography>{'Cargo: ' + (row.cargo || '')}</Typography>
								<Typography>{'Cargo equivalente:  ' + row.cargoEquivalente}</Typography>
							</Grid>

							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={3} mb={5} mr={5} item>
								<Typography>{'Nombre de la empresa: ' + (row.nombreEmpresa || '')}</Typography>
								{row.telefonoEmpresa && (<Typography>{'Teléfono de la empresa:  ' + (row.telefonoEmpresa || '')}</Typography>)}
								<Typography>{'Fecha de ingreso:  ' + (convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(row.fechaIngresso) || '')}</Typography>
								{row.fechaRetiro && (<Typography>{'Fecha de retiro: ' + (convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(row.fechaRetiro) || '')}</Typography>)}
							</Grid>
							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={2} mb={5} mr={2} item>
								<Typography>{'Funciones y logros: ' + (row.funcionesLogros || '')}</Typography>
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					Atrás
				</Button>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
					disabled={editOrCreateItemInProgress}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
