import React, { useState, useEffect } from 'react';
import { VacancyManagement } from '../modules/auth/components/VacancyManagement';
import { vacancyTempral } from '../modules/apps/user-management/users-list/core/_requests';

export const multiStepContext = React.createContext();

const VacancyContext = () => {
	const [currentStep, setStep] = useState(1);
	const [userData, setUserData] = useState([]);
	const [finalData, setFinalData] = useState([]);
	const [data, setData] = useState([]);
	const [loadedData, setLoadedData] =
		useState(false); /*Used to know if the information has been loaded once (edit mode)*/
	const [loading, setLoading] =useState(false);
	const [shouldDisable, setShouldDisable] = useState(false);

	const submitData = () => {
		setFinalData((finalData) => [...finalData, userData]);
		setUserData('');
	};
// console.log(userData)
	useEffect(() => {
		vacancyTempral().then((resp) => {
			if (data.data) {
				setUserData(resp)
			}
		}).catch((error) => {
			console.log(error)
		})
	}, [])

	return (
		<div>
			<multiStepContext.Provider
				value={{
					currentStep,
					setStep,
					userData,
					setUserData,
					finalData,
					setFinalData,
					submitData,
					data,
					setData,
					loadedData,
					setLoadedData,
					loading,
					setLoading,
					shouldDisable,
					setShouldDisable
				}}
			>
				<VacancyManagement />
			</multiStepContext.Provider>
		</div>
	);
};

export default VacancyContext;
