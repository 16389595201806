import { useEffect, useState } from 'react';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import styleScss from '../../pages/employer/styles.module.scss';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    IconButton,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { truncateWords } from '../../util/Index';
import { Verified, } from '@mui/icons-material';
import { OverlayTrigger, Tooltip as BootTip, Table } from 'react-bootstrap';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { any } from 'prop-types';

const Preforma = ({ preforma, userData, setUserData, setPerfoma, handleCloseModal, deletePreforma, setActualizarLista }: any) => {
    const {
        data = [],
        totalPages: totalP = 0,
        currentPage = 1,
        total: totalRecords = 0,
    } = preforma || {};

    // -------------------------
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<number | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null);
    };

    const handleSelectItem = (item: any) => {
        setUserData({ ...item, status_approval: userData?.status_approval, codigo_vacante: userData?.codigo_vacante})
        handleCloseModal()
    };

    const handleModifyItem = (item: any) => {
        setUserData({ ...item})
        handleCloseModal()
    };
    const handleDeleteItem  = async (item: any) => {
        await deletePreforma(item);
        setActualizarLista(true)
    };
    // -------------------------

    //Pagination
    const [total, setTotal] = useState<number>(totalRecords);
    const [totalPage, setTotalPage] = useState<number>(totalP);
    const [filter, setFilter] = useState<any>({
        page: 1,
        limit: 10,
        name: '',
        estado: ''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangePage = (newPage: any) => {
        const newPageNumber = parseInt(newPage) + 1;
        const f = { page: newPageNumber, limit: filter.limit, name: filter.name, role: filter.role, estado: filter.estado };
        setPage(newPage);
        setFilter(f);
    };

    const handleChangeRowsPerPage = (event: any) => {
        const f = { page: 1, limit: parseInt(event.target.value, 10), name: filter.name, role: filter.role, estado: filter.estado }
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFilter(f);
    };
    const handleFirstPageButtonClick = () => {
        handleChangePage(0);
    };

    const handleLastPageButtonClick = () => {
        handleChangePage(Math.ceil(total / rowsPerPage) - 1);
    };
    return (
        <div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
            <div className={`${styleScss['table2']} ${styleScss['table-limit']}`} style={{ width: '100%' }}>
                <TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
                    <Table style={{ overflowY: 'visible' }}>

                        <TableHead className={`${styleScss['th-custom']}`}>
                            <TableRow>
                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word' }}>
                                    <h4>Nombre de la preforma</h4>
                                </TableCell>
                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                    <h4>Acciones</h4>
                                </TableCell>
                            </TableRow>
                        </TableHead>


                        <TableBody>
                            {data?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                        No hay datos disponibles
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((x: any) => (
                                    <TableRow key={x?.id}>
                                        <TableCell
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: '14.5px',
                                                overflowWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                textAlign: 'center',
                                            }}
                                            className={`${styleScss['td-break']}`}
                                        >
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 40, hide: 400 }}
                                                overlay={(props) => (
                                                    <BootTip id="tooltip" {...props}>
                                                        <div dangerouslySetInnerHTML={{ __html: x?.nombre_vacante || '' }} />
                                                    </BootTip>
                                                )}
                                            >
                                                <p className="card-text">
                                                    {truncateWords(x?.nombre_vacante || '', 40)}
                                                </p>
                                            </OverlayTrigger>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <Button
                                                aria-controls={open === x?.id ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open === x?.id ? 'true' : undefined}
                                                onClick={(event) => handleClick(event, x?.id)}
                                            >
                                                <i className="bi bi-three-dots" style={{ fontSize: '20px' }}></i>
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open === x?.id}
                                                onClose={handleClose}
                                            >
                                                <MenuItem
                                                    style={{ color: '#4683DE' }}
                                                    onClick={() => handleSelectItem(x)}
                                                >
                                                    <Verified style={{ marginRight: 8 }} />
                                                    Utilizar esta preforma
                                                </MenuItem>
                                                <MenuItem
                                                    style={{ color: '#4683DE' }}
                                                    onClick={() => handleModifyItem(x)}
                                                >
                                                    <BorderColorIcon style={{ marginRight: 8 }} />
                                                    Modificar esta preforma
                                                </MenuItem>
                                                <MenuItem
                                                    style={{ color: '#4683DE' }}
                                                    onClick={() => handleDeleteItem(x.id)}
                                                >
                                                    <DeleteIcon style={{ marginRight: 8 }} />
                                                    Eliminar esta preforma
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>

                    <div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            component="div"
                            style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => handleChangePage(newPage)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Filas por página:"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
                                <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
                                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                                        <FirstPageIcon />
                                    </IconButton>
                                    <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                                        <ChevronRightIcon />
                                    </IconButton>
                                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                                        <LastPageIcon />
                                    </IconButton>
                                </div>
                            )}
                        />
                    </div>
                </TableContainer>
            </div>
        </div >
    )
}

export default Preforma
