import React, { useState } from 'react';
import { InformacionAdicionalEmpresa } from '../modules/auth/components/InformacionAdicionalEmpresa';

export const multiStepContext = React.createContext();

const InformacionAdicionalEmpresaContext = () => {
	const [userData, setUserData] = useState([]);
	const [data, setData] = useState([]);
	const [loadedData, setLoadedData] =
		useState(false); /*Used to know if the information has been loaded once (edit mode)*/
	const [loading, setLoading] =useState(false);
	const [shouldDisable, setShouldDisable] = useState(false);

	const submitData = () => {
		setUserData('');
	};

	return (
		<div>
			<multiStepContext.Provider
				value={{
					userData,
					setUserData,
					submitData,
					data,
					setData,
					loadedData,
					setLoadedData,
					loading,
					setLoading,
					shouldDisable,
					setShouldDisable
				}}
			>
				<InformacionAdicionalEmpresa />
			</multiStepContext.Provider>
		</div>
	);
};

export default InformacionAdicionalEmpresaContext;
