import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { CardModel } from '../../models/CardModel';
import { getVacanteMatching, getCurriculoPDF } from '../../modules/auth/core/_requests';
import { getMeetsByCandidate } from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Alert,
	Box,
	CircularProgress,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import { useAuth } from '../../../app/modules/auth';
import Chatbot from '../../components/Chatbot';
import { concatArrayToStringJoinBy, convertUTCDateToLocalDate, truncateWords } from '../../util/Index';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const CandidateAreaPage = () => {
	const { currentUser, auth } = useAuth();
	const [listMatch, setListMatch]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [meets, setMeets]: any[] = useState([]);
	const [search, setSearch] = useState('');
	const disableMatching = (/true/).test(process.env.REACT_APP_DISABLE_MATCHING_COMPONENTS || '');

	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * Llamado al servicio de match de vacantes
	 */
	async function getMatchVacantes() {
		if (!disableMatching) {
			setLoading(true);
			try {
				const response = await getVacanteMatching();
				if (response && response.data) {
					setListMatch(response.data.results ? response.data.results : []);
				} else {
					setListMatch([]);
				}
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setMsgAlert(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	async function getCurriculo() {
		setLoadingPDF(true);
		try {
			const response = await getCurriculoPDF();
			const url = window.URL.createObjectURL(
				new Blob([response.data], { type: 'application/pdf' })
			);
			const link = document.createElement('a');
			link.href = url;
			//link.setAttribute('download', `hoja_de_vida_.pdf`); //Opção para download direto do PDF
			link.setAttribute('target', '_blank'); // Abre o PDF em uma nova aba
			link.setAttribute('rel', 'noopener noreferrer'); // Adiciona o atributo rel para segurança
			document.body.appendChild(link);
			link.click();
			setLoadingPDF(false);
		} catch (error: any) {
			if (error.response) {
				const data = JSON.parse(await error.response.data.text());
				console.error(data.error)
				setIsError(true);
				setOpen(true);
				setMsgAlert(data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoadingPDF(false);
		}
	}

	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard de Candidatos',
			content: 'Métricas e indicadores de las vacantes.',
			action: '/candidato/dashboard/candidato',
			icon: 'bi-rocket-takeoff-fill'
		},
		{
			title: 'Hoja de vida',
			content: 'Accede a tu currículo',
			icon: 'bi-file-person-fill',
			callMethod: true,
			callMethodImpl: getCurriculo,
			processInProgress: loadingPDF
		},
	];

	/**
	 * Contenido card Buscar vacantes disponibles
	 * @returns 
	 */
	const overwriteContentImpBuscarVacantes = () => {
		return (
			<>

			</>
		)
	}

	/**
	 * Contenido card Citas programadas
	 * @returns 
	 */
	const overwriteContentImpCitasProgramadas = () => {
		return (
			<>
				<div className='row' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
					{meets.slice(0, 10).map((x: any, i: any) => (
						<div
							onClick={() => window.open(x.link_event, '_blank')}
							className='col-sm-12 d-flex'
							key={i}
							id={x.id}
							style={{ cursor: 'pointer' }}
						>
							<div className={`${styleScss['box-aside']}`}>
								<p className={`${styleScss['txt-center']}`}>
									{convertUTCDateToLocalDate(new Date(x.inicio))
										.toJSON()
										.slice(5, 10)
										.split('-')
										.reverse()
										.join('/')}
								</p>
							</div>
							<div style={{ marginLeft: '5px' }}>
								<p className='text-primary'>
									{truncateWords(concatArrayToStringJoinBy([x.primer_nome ?? '', x.segundo_nome ?? ''], ' '), 20)}
								</p>
								<p>
									{x.inicio ? moment(new Date(x.inicio).toUTCString()).format('hh:mm a') : ''}
								</p>
							</div>
						</div>
					))}
				</div>
			</>
		)
	}

	const listManage: CardModel[] = [
		{
			title: 'Hoja de vida',
			content: 'Completa tu información personal y profesional para generar tu hoja de vida.',
			action: `/candidato/crear-hoja-vida/${currentUser?.user?.id}`,
			actionName: 'Registrar',
			icon: 'bi-file-earmark-text-fill'
		},
		{
			title: 'Vacantes postuladas',
			content: 'Ve los procesos de selección en los que estás participando.',
			action: '/candidato/postuladas-vacantes',
			actionName: 'Ver más',
			icon: 'bi-card-checklist'
		},
		{
			title: 'Citas programadas',
			content: 'Calendario de tutoría y entrevistas.',
			action: `/candidato/citas-programadas`,
			actionName: 'Ver más',
			icon: 'bi-calendar-check',
			overwriteContent: true, //Determina si se debe sobrescribir el contenido por defecto 
			overwriteContentImp: overwriteContentImpCitasProgramadas //Contenido a sobre escribir
		},
		/*{
			title: 'Buscar vacantes disponibles',
			content: '',
			action: `/candidato/search-vacantes/${search}`,
			actionName: 'Buscar',
			icon: 'bi-search',
			overwriteContent: true, //Determina si se debe sobrescribir el contenido por defecto 
			overwriteContentImp: overwriteContentImpBuscarVacantes //Contenido a sobre escribir
		}*/
	];

	async function loadMeets(idUser: any) {
		try {
			await getMeetsByCandidate(`id_candidate=${idUser}&activos=true&vigentes=true`).then((response) => {
				setMeets(response.data);
			});
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setIsError(true);
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	useEffect(() => {
		let idUser = currentUser?.user?.id;
		loadMeets(idUser);
		getMatchVacantes();
	}, []);

	const navigate = useNavigate();

	return (
		<>
			{/* <h2>Área del consejero</h2> */}
			<div className='row mt-15'>
				{/* Cards */}
				<div className='col-sm-4 mt-5'>
					{listManage.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px' }}
							key={x.action}
							id={i.toString()}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-3'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.overwriteContent && (<><x.overwriteContentImp /><br /></>)}
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
				{/* Match Vacantes recomendadas - Buscar vacantes disponibles*/}
				<div className='col-sm-4 mt-5'>
					{/* Buscar vacantes */}
					<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px' }}>
						<div className={`${styleScss['top-icon']}`}>
							<i className={`bi bi-search ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-3'>
							<h2 className='card-title'>Buscar vacantes disponibles</h2>
							<p className='card-text'></p>
							<TextField
								sx={{
									'& label.Mui-focused': { color: 'white' },
									backgroundColor: '#FFF',
									border: 'transparent',
									borderRadius: '10px',
									marginBottom: '10px',
								}}
								fullWidth
								required
								id='busca_vacante'
								name='busca_vacante'
								placeholder='Nombre o Código de la vacante'
								size='small'
								onChange={(e) => { setSearch(e.target.value); }}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										navigate(`/candidato/search-vacantes/${search}`);
									}
								}}
							/>
							<Link to={`/candidato/search-vacantes/${search}`}
								className='btn btn-primary btn-lg btn-block'
								style={{ padding: '8px 40px' }}>
								Buscar
							</Link>
						</div>
					</div>
					{/* Match Vacantes recomendadas*/}
					{!disableMatching && (
						<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px', width: '100%' }}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi bi-bricks ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-5'>
								<h2 className='card-title'>Vacantes recomendadas</h2>
								<p className='card-text mb-5'>
									Trabajos que mejor se adaptan a tus habilidades.
								</p>
								{loading ? (
									<LinearLoadingSpinner isLoading={loading} />
								) : listMatch.length === 0 ? (
									<Typography variant='h6'>{msgAlert}</Typography>
								) : (
									<>
										{listMatch.slice(0, 10).map((x: any, i: any) => (
											<Link to={`/candidato/vacante/${x.id_vacante}`} key={i} id={x.id}>
												<div
													className='mb-2'
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '10px',
													}}
												>
													<div className={`${styleScss['box']}`}>
														<p className={`${styleScss['txt-center']}`}>{x.matching}%</p>
													</div>
													<div className='mt-3'>
														<div className='text-primary'>
															{x.codigo_vacante ?
																(
																	<>
																		<b>{x.codigo_vacante}</b>
																		<p>{x.nombre_vacante}</p>
																	</>
																) : (<p>{x.nombre_vacante}</p>)
															}
															<span className='text-black' style={{ fontWeight: '500' }}>{x.razon_social}</span>
														</div>
													</div>
												</div>
											</Link>
										))}
										<Link
											to='/candidato/matching-vacantes'
											className='btn btn-primary mt-4'
											style={{ padding: '8px 40px' }}
										>
											Ver más
										</Link>
									</>
								)}
							</div>
						</div>
					)}
				</div>
				{/* Dashboards */}
				<div className={`col-sm-${!disableMatching ? '4' : '4'} mt-5`}>
					{listDashboard.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['blue-card']}`} style={{ borderRadius: '10px' }}
							key={i} id={i.toString()}>
							{x.action ? (
								<Link to={x.action!}>
									<div className='card-body mt-3'>
										<i className={`bi ${x.icon} ${styleScss['icon-custom-white']}`}></i>
										<h3 className='card-title text-white mt-2'>{x.title}</h3>
										<p className='card-text text-white'>{x.content}</p>
									</div>
								</Link>
							) : (
								<>
									{x.processInProgress ? (
										<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<CircularProgress sx={{ color: 'white' }} size={100} />
										</Box>
									) : (
										<div className={`card-body mt-3 ${x.callMethod ? 'cursor-pointer' : ''}`} onClick={x.callMethod ? x.callMethodImpl : undefined}>
											<i className={`bi  ${x.icon} ${styleScss['icon-custom-white']}`}></i>
											<h3 className='card-title text-white mt-2'>{x.title}</h3>
											<p className='card-text text-white'>{x.content}</p>
										</div>
									)}
								</>
							)}
						</div>
					))}
				</div>

			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidateAreaWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Área Candidato</PageTitle>
			<CandidateAreaPage />
			<Chatbot />
		</>
	);
};

export { CandidateAreaWrapper };
