import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { DetailedReportingPanel } from './DetailedReportingPanel';
import { ReportsPanel } from './ReportsPanel';


const DetailedReports = () => {
	const { auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();


	return (
		<>
			<PageTitle breadcrumbs={[]}>Reportes Detallados</PageTitle>
			<DetailedReportingPanel />
		</>
	)
}

export { DetailedReports }


