
import { Navigate, Outlet, useLocation } from "react-router-dom";

type RequireAuthProps = {
	userRole: number,
	allowedRol: number
};

const RequireAuth = ({ userRole, allowedRol }: RequireAuthProps): JSX.Element => {
	const location = useLocation();

	return (
		userRole === allowedRol
			? <Outlet />
			: <Navigate to="/" state={{ from: location }} replace />
	);
};

export { RequireAuth };