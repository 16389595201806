import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getVagasPostuladas } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../../app/modules/auth';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { initValue } from '../../util/Index'
import { listPostulacionModel } from '../../models/ListPostulacionModel'
import {
	Alert,
	IconButton,
	InputLabel,
	Paper,
	Select,
	Snackbar,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	FormControl,
	Stack
} from '@mui/material';
import { OverlayTrigger, Tooltip as BootTip, Table } from 'react-bootstrap';
import { truncateWords } from '../../util/Index';
import { LinearLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CandidateRequestedVacancyPage = () => {
	const { currentUser, isCounselorCandidateRole, isAdminRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const { id_candidato } = useParams();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		autoPostulacion: null,
		estado: '',
	});
	const [loading, setLoading] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [id, setId] = useState<string>('');
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const openMenu = Boolean(anchorEl);
	const id_users = currentUser?.user?.id;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);


	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, autoPostulacion: filter.autoPostulacion, estado: filter.estado };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), autoPostulacion: filter.autoPostulacion, estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
		setAnchorEl(event.currentTarget);
		setId(id);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
		setId('');
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		handleSubmit();
	}, []);

	const changePaginate = (pf: any) => {
		setFilter({ ...filter, page: pf.page, limit: pf.limit });
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const queryParam = `page=${filter.page}&limit=${filter.limit}&nombre_vacante=${userData.nombre_vacante ? userData.nombre_vacante : ''
				}&autoPostulacion=${filter.autoPostulacion}&estado=${filter.estado}&id_candidato=${id_candidato || id_users}`;
			const response = await getVagasPostuladas(queryParam);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				setIsError(true);
				setOpen(true);
				console.error(error.response.data.error);
				setMsgAlert(`${error.response.data.error}`);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		setPage(0);
		setFilter({ ...filter, page: 1 });
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, nombre_vacante: event.target.value });
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	/**
	 * Retorna el path para ver el componente de informacion resumen de la vacante
	 * @param idVacante 
	 * @returns 
	 */
	const getPathInfoVacante = (idVacante: any) => {
		if (isCounselorCandidateRole()) {
			return `/consejero/vacante/${idVacante}/${id_candidato}`;
		} else {
			return `/candidato/vacante/${idVacante}`;
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-3'>
					<div className='col-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div
									className='col-xs-12 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175 }} >
									<InputLabel id='aupostulado'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>¿Autopostulado?</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='aupostulado'
										id='aupostulado'
										label='¿Autopostulado?'
										value={initValue(filter.autoPostulacion)}
										onChange={(e) => {
											setPage(0);
											setFilter({ ...filter, page: 1, autoPostulacion: e.target.value });
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='true'>Sí</MenuItem>
										<MenuItem value='false'>No</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175 }}>
									<InputLabel id='estado' style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Postulación</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='estado'
										id='estado'
										label='Postulación'
										value={initValue(filter.estado)}
										onChange={(e) => {
											setPage(0);
											setFilter({ ...filter, page: 1, estado: e.target.value });
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listPostulacionModel.map((item) => (
											<MenuItem key={item.code} value={item.code}>
												{item.value}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={`input-group mb-3 ${styleScss['entrada-filtro-text']}`}>
									<input
										type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Buscar por Vacante/Código'
										aria-label='Buscar por Vacante/Código'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchVacancy'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.lenght !== 0 && (
					<div className={`${styleScss['table2']}`}>
						<Stack padding={2}>
							<TableContainer component={Paper} style={{ overflowX: 'auto' }}>
								<Table className={`table table-responsive table-striped `} style={{ tableLayout: 'fixed' }}>

									<TableHead className={`${styleScss['th-custom']}`}>
										<TableRow>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Código</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Vacante</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Empresa</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Descripción</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word' }}>
												<h4>Estado de la vacante</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Autopostulado</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Postulación</h4>
											</TableCell>
											<TableCell className={`${styleScss['tableCell']}`} style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
												<h4>Acciones</h4>
											</TableCell>
										</TableRow>
									</TableHead>
									{!loading && <TableBody>
										{!loading && data.map((x: any, i: any) => (
											<TableRow key={i} id={i.toString()}>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['td-break']} ${styleScss['tableCell']}`}>{x.codigo_vacante}</TableCell>
												<TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all' }} className={`${styleScss['tableCell']}`}>
													<OverlayTrigger
														placement='left'
														delay={{ show: 30, hide: 400 }}
														overlay={(props) => (
															<BootTip id='tooltip' {...props}>
																<div dangerouslySetInnerHTML={{ __html: x.nombre_vacante }} />
															</BootTip>
														)}
													>
														<p className={`card-text`}>
															{truncateWords(x.nombre_vacante, 30)}
														</p>
													</OverlayTrigger>
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['td-break']} ${styleScss['tableCell']}`}>{x.Empresa.razon_social}</TableCell>
												<TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all' }} className={`${styleScss['tableCell']}`}>
													<OverlayTrigger
														placement='left'
														delay={{ show: 30, hide: 400 }}
														overlay={(props) => (
															<BootTip id='tooltip' {...props}>
																<div dangerouslySetInnerHTML={{ __html: x.Describa_vacante }} />
															</BootTip>
														)}
													>
														<p className={`card-text`}>
															{truncateWords(x.Describa_vacante, 30)}
														</p>
													</OverlayTrigger>
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['tableCell']}`}>{x.status ? 'Abierta' : 'Cerrada'}</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['tableCell']}`}>
													{x.CurriculoVagaEmpresas && x.CurriculoVagaEmpresas.length > 0 && x.CurriculoVagaEmpresas[0]?.id_counselor ? 'No' : 'Sí'}
												</TableCell>
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }} className={`${styleScss['tableCell']}`}>
													{x.CurriculoVagaEmpresas && x.CurriculoVagaEmpresas.length > 0 && x.CurriculoVagaEmpresas[0]?.status &&
														x.CurriculoVagaEmpresas[0].status.charAt(0).toUpperCase() + x.CurriculoVagaEmpresas[0].status.slice(1).toLowerCase()
													}
												</TableCell>
												<TableCell style={{ textAlign: 'center' }} className={`${styleScss['tableCell']}`}>
													<Button
														id='basic-button'
														aria-controls={openMenu ? 'basic-menu' : undefined}
														aria-haspopup='true'
														aria-expanded={openMenu ? 'true' : undefined}
														onClick={(e) => handleClickMenu(e, x.id)}
													>
														<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
													</Button>

													<Menu
														id='basic-menu'
														anchorEl={anchorEl}
														open={openMenu}
														onClose={handleCloseMenu}
														MenuListProps={{
															'aria-labelledby': 'basic-button',
														}}
													>
														<MenuItem onClick={handleCloseMenu}>
															<Link to={getPathInfoVacante(id)}>
																<RemoveRedEyeIcon />
																&nbsp; Ver vacante
															</Link>
														</MenuItem>
													</Menu>
												</TableCell>
											</TableRow>
										))}
									</TableBody>}


									{data.length === 0 && (
										<tfoot>
											<TableRow>
												<TableCell colSpan={7}>
													<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
														No se encontraron registros
													</p>
												</TableCell>
											</TableRow>
										</tfoot>
									)}
								</Table>
								<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
									<TablePagination
										rowsPerPageOptions={[10, 20, 50, 100]}
										component="div"
										style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
										count={total}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={(event, newPage) => handleChangePage(newPage)}
										onRowsPerPageChange={handleChangeRowsPerPage}
										labelRowsPerPage="Filas por página:"
										labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
										ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
											<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
												<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
													<FirstPageIcon />
												</IconButton>
												<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
													<ChevronLeftIcon />
												</IconButton>
												<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
													<ChevronRightIcon />
												</IconButton>
												<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
													<LastPageIcon />
												</IconButton>
											</div>
										)}
									/>
								</div>
							</TableContainer>
						</Stack>
					</div>
				)}
				{/* <PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/> */}
			</div >
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidateRequestedVacancyWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Vacantes Postuladas</PageTitle>
			<CandidateRequestedVacancyPage />
		</>
	);
};

export { CandidateRequestedVacancyWrapper };
