import { useContext, useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Grid,
	Box,
	Button,
	Divider,
	Typography,
	Autocomplete,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Alert,
	AlertTitle,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { useAuth } from '../../../../../../app/modules/auth';
import { useParams } from 'react-router-dom';
import { formatMaskPhoneNumber12Digits, initValue, isValidColombianPhoneNumber, helperIsValidColombianPhoneNumber, isValidFirstSecondNameFirstSecondLastName, helperValidFirstSecondNameFirstSecondLastName, regexValidAlphabetValueText, isValidEmail, isValidFieldRequired, concatArrayToStringJoinBy } from '../../../../../util/Index';
import {
	getVacancyId,
	getCompaniesCounselor,
	createVacancyTempral,
	putVacancyTempral,
	getPreforma,
	deletePreforma,
} from '../../../../apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useAboutVacancyParams from '../../../../../hooks/parameters/vacancy-management/useAboutVacancyParams';
import { any } from 'prop-types';
import Preforma from '../../../../../components/Performa/Preforma';
import Modal from '@mui/material/Modal';

export interface CompanyModel {
	id: number;
	razon_social: string;
	numero_documento: string;
	status: string;
}
const styleModal = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};
// modal de validar si quiere o no usar  las preformas

export const Step1 = () => {


	const { currentUser, auth, isAdminRole, isCounselorEmployerRole } = useAuth();
	const { setStep, userData, setUserData, loadedData, setLoadedData, loading, setLoading, shouldDisable, setShouldDisable } =
		useContext(multiStepContext);

	const { id } = useParams(); /*if it has ID: Edit Mode; else: Create Mode*/
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState<CompanyModel | any>(null);
	const { listTipoCargoContacto, loadingParams } = useAboutVacancyParams();
	const handleSetUserData = (userData: any) => setUserData({ ...userData });


	useEffect(() => {
		if (isAdminOrCounselor()) {
			getListCompanies();
		}
		if (id !== undefined && parseInt(id) > 0 && (!loadedData as boolean)) {
			getVacancy();
		}
		if (currentUser?.user?.empresa_id) {
			handleSetUserData(userData);
		}
	}, []);

	useEffect(() => {
		ifShouldDisable();
	}, [loading])

	useEffect(() => {
		findAndSetSelectedCompany();
	}, [userData?.id_empresa, companies?.length > 0]);


	const findAndSetSelectedCompany = () => {
		if (userData && userData?.id_empresa && null != userData.id_empresa) {
			const addedCompany: CompanyModel | undefined = companies.find(c => (c['id'] as number) == userData.id_empresa);
			if (addedCompany) {
				setSelectedCompany(addedCompany);
				formik.initialValues.id_empresa = initValue(addedCompany['id']);
			}
		}
	}
	const ifShouldDisable = () => {
		if (userData.status_approval == 'EN_ANALISIS' || userData.status_approval == 'TEMPORAL' || userData.status_approval == 'PREFORMA' || userData.status_approval == undefined) {
			setShouldDisable(false);
		}
		else {
			setShouldDisable(true);
		}
	}

	const getVacancy = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			try {
				setLoading(true);
				const response = await getVacancyId(parseInt(id));
				await handleSetUserData(response.data);
				setLoadedData(true);
			} catch (error: any) {
				if (error.response) {
					console.error('error', error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	};

	/**
	 * Obtiene lista de compañias para asociar al flujo de registro de la vacante
	 */
	const getListCompanies = async () => {
		try {
			const response = await getCompaniesCounselor();
			setCompanies(response.data);
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	};
	class validationForm {
		id_empresa?: string;
		nombre_vacante: string = initValue(userData['nombre_vacante']);
		// responsable_vacante: string = ''
		persona_solicita_cargo: string = initValue(userData['persona_solicita_cargo']);
		telefono_contacto: string = initValue(userData['telefono_contacto']);
		Correo_eletrenico_contacto: string = initValue(userData['Correo_eletrenico_contacto']);
		tipo_cargo_contacto: string = initValue(userData['tipo_cargo_contacto']);
		extension_contacto: string = initValue(userData['extension_contacto']);
		// asesor_responsable: string = ''
		constructor() {
			//Roles: Administrador y Consejero
			if (isAdminRole() || isCounselorEmployerRole()) {
				this.id_empresa = initValue(userData['id_empresa']);
			}
		}
	}
	const formSchema = useMemo(() => {

		const nombre_vacante = Yup.string()
			.max(500, 'Máximo 500 caracteres')
			.required('Campo requerido');
		const persona_solicita_cargo = Yup.string()
			.required('Campo requerido')
			.max(45, 'Máximo 45 caracteres')
			.test('isValidFirstSecondNameFirstSecondLastName', (val) => { return isValidFirstSecondNameFirstSecondLastName(val ? val : '', true) })
			.matches(regexValidAlphabetValueText, 'Solo caracteres alfabéticos');

		const telefono_contacto = Yup.string()
			.test('isValidColombianPhoneNumber', (val) => helperIsValidColombianPhoneNumber(val, true), (val) => { return isValidColombianPhoneNumber(val, true) })
			.required('Campo requerido');

		const extension_contacto = Yup.number().nullable().
			min(0, 'Extensión muy corta').max(99999, 'Extensión muy extensa. Maximo 5 números.');

		const Correo_eletrenico_contacto = Yup.string()
			.required('Campo requerido')
			.test('ruleEmail', 'Correo electrónico no es válido', (value) => {
				return isValidEmail(value || '')
			})
			.max(100, 'Máximo 100 caracteres')

		const tipo_cargo_contacto = shouldDisable == true ? Yup.string().nullable() : Yup.string().required('Campo requerido');

		//Roles: Administrador y Consejero
		if (isAdminRole() || isCounselorEmployerRole()) {
			return Yup.object().shape({
				id_empresa: Yup.string().required('Campo requerido'),
				nombre_vacante: nombre_vacante,
				persona_solicita_cargo: persona_solicita_cargo,
				telefono_contacto: telefono_contacto,
				extension_contacto: extension_contacto,
				Correo_eletrenico_contacto: Correo_eletrenico_contacto,
				tipo_cargo_contacto: tipo_cargo_contacto
			});
		} else {
			return Yup.object().shape({
				nombre_vacante: nombre_vacante,
				persona_solicita_cargo: persona_solicita_cargo,
				telefono_contacto: telefono_contacto,
				extension_contacto: extension_contacto,
				Correo_eletrenico_contacto: Correo_eletrenico_contacto,
				tipo_cargo_contacto: tipo_cargo_contacto
			});
		}
	}, [shouldDisable]);
	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				const data = { ...userData, poblaciones_aceptadas: concatArrayToStringJoinBy(userData.poblacionesAceptadas, ',', null) }
				const nextStep = () => {
					setSubmitting(true);
					if (formik.isValid) setStep(2);
				}
				if (data.status_approval === 'TEMPORAL') return putVacancyTempral(data).then((res) => {
					nextStep()
				}).catch((error) => {
					console.log(error);
				});
				if (!userData.id && data.status_approval !== 'PREFORMA') createVacancyTempral(userData).then((res) => {
					nextStep()
					setUserData({ ...userData, status_approval: 'TEMPORAL' })
				}).catch((error) => {
					console.log(error);
				});
				else nextStep()
			} catch (error) {
				setSubmitting(false);
				setStatus('Información incorrecta');
			}
		},
	});

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */
	const [perfoma, setPerfoma] = useState()
	const [actualizarLista, setActualizarLista] = useState(false)



	// modal de validar si quiere o no usar  las preformas
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	// modal de validar si quiere o no usar  las preformas


	const [openAprobada, setOpenAprobada] = useState(false);

	const isAdminOrCounselor = () => {
		return isAdminRole() || isCounselorEmployerRole();
	}
	const handleGetPreforma = async (id: any) => {
		try {
			if (!id) {
				if (perfoma) {
					setUserData([])
				}
				return
			}
			// setLoading(true);
			const response = await getPreforma(id);
			if (response?.data?.total > 0) {
				setPerfoma(response.data)
				handleOpenModal()
			}

		} catch (error: any) {
			if (error.response) {
				console.error('Error:', error.response.data.error);
			} else {
				console.error('Ocurrió un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	// validando si  la empresa tiene  preformas
	useEffect(() => {
		if (actualizarLista === true) {
			handleGetPreforma(selectedCompany?.id)
			setActualizarLista(false)
		}
	}, [actualizarLista])
	// validando si  la empresa tiene  preformas

	/**
	 * Opcion de filtrado para campo de empresa
	 */
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		stringify: (option: CompanyModel) => option.numero_documento + option.razon_social,
	});

	return (
		<>
			{loading ? (
				null
			) : (loadingParams ? <LinearLoadingSpinner isLoading={true} /> :
				<>
					{id !== undefined && parseInt(id) > 0 && userData['codigo_vacante'] && (
						<Box sx={{ mb: 3 }}>
							<Typography variant='h6' sx={{ fontWeight: 'bold', color: '#707179' }}>
								Código de la vacante: {userData['codigo_vacante']}
							</Typography>
						</Box>
					)}
					<Box sx={{ mb: 3 }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
							Datos del responsable
						</Typography>
					</Box>



					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						{isAdminOrCounselor() && (
							<Grid xs={12} md={4} mb={5} pr={2} item={true}>
								<Autocomplete
									disabled={loading || shouldDisable}
									sx={{ minWidth: '100%' }}
									size='medium'
									id="id_empresa"
									getOptionLabel={(opt: CompanyModel) => initValue(opt.razon_social)}
									isOptionEqualToValue={(opt, value) => (opt && value) && opt.id === value.id}
									options={companies}
									value={selectedCompany}
									filterOptions={filterOptions}
									onChange={(e: any, newValue: CompanyModel | null) => {
										setSelectedCompany(newValue);
										handleSetUserData({ ...userData, id_empresa: newValue?.id });
										formik.setFieldValue('id_empresa', newValue?.id);
										formik.handleChange(e);
										handleGetPreforma(newValue?.id)
									}}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.razon_social}
											</li>
										);
									}}
									renderInput={(params) => (

										<TextField {...params}
											label="Empresa"
											id="id_empresa"
											{...formik.getFieldProps('id_empresa')}
											error={formik.touched.id_empresa && Boolean(formik.errors.id_empresa)}
											// error={(!selectedCompany) && (!userData.id_empresa)}
											helperText={formik.touched.id_empresa ? formik.errors.id_empresa : ''}
											required
										/>

									)}

								/>
							</Grid>

						)
						}
						{/* modal de carga del select */}

						<Modal
							open={openModal}
							onClose={handleCloseModal}
							aria-labelledby="parent-modal-title"
							aria-describedby="parent-modal-description"
						>
							<Box sx={{ ...styleModal, width: "70%", borderRadius: 5 }}>
								{/* <Preforma preforma={perfoma} setUserData={setUserData} setPerfoma={setPerfoma} /> */}
								<Preforma userData={userData} preforma={perfoma} setUserData={setUserData} setPerfoma={setPerfoma} handleCloseModal={handleCloseModal} setActualizarLista={setActualizarLista} deletePreforma={deletePreforma} />
								<div className="" style={{ width: '100%', textAlign: 'center' }}>
									<Button onClick={handleCloseModal} style={{ background: '#4683DE', color: '#fff' }} > No deseo usar una preforma ahora</Button>
								</div>
							</Box>
						</Modal>
						{/* {perfoma ?
							<Dialog
								open={openAprobada}
								keepMounted
								onClose={() => { setOpenAprobada(false); }}
								aria-describedby='alert-dialog-description'
							>
								<Preforma preforma={perfoma} setUserData={setUserData} setPerfoma={setPerfoma} />
								<DialogActions>
								</DialogActions>
							</Dialog>
							: null} */}




						<Grid xs={12} md={isAdminOrCounselor() ? 4 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('nombre_vacante')}
								error={formik.touched.nombre_vacante && Boolean(formik.errors.nombre_vacante)}
								helperText={formik.touched.nombre_vacante ? formik.errors.nombre_vacante : ''}
								required
								id='nombre_vacante'
								name='nombre_vacante'
								label='Nombre de la vacante'
								value={initValue(userData['nombre_vacante'])}
								inputProps={{ maxLength: 500 }}
								onChange={(e) => {
									handleSetUserData({ ...userData, nombre_vacante: e.target.value });
									formik.handleChange(e);
								}}
								size='medium'
								disabled={shouldDisable}
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 4 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('persona_solicita_cargo')}
								error={formik.touched.persona_solicita_cargo && !isValidFirstSecondNameFirstSecondLastName(userData['persona_solicita_cargo'], true)}
								helperText={formik.touched.persona_solicita_cargo ? helperValidFirstSecondNameFirstSecondLastName(userData['persona_solicita_cargo'], true) : ''}
								label='Persona que solicita el cargo'
								placeholder='Ingresa el nombre'
								type='text'
								value={initValue(userData['persona_solicita_cargo'])}
								onChange={(e) => {
									handleSetUserData({ ...userData, persona_solicita_cargo: e.target.value });
									formik.handleChange(e);
								}}
								inputProps={{ maxLength: 45 }}
								size='medium'
								disabled={shouldDisable}
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 4 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								inputProps={{ autoComplete: 'new-password', maxLength: 10 }}
								{...formik.getFieldProps('telefono_contacto')}
								error={formik.touched.telefono_contacto && !isValidColombianPhoneNumber(formik.getFieldProps('telefono_contacto').value, true)}
								helperText={formik.touched.telefono_contacto ? helperIsValidColombianPhoneNumber(formik.getFieldProps('telefono_contacto').value, true) : ''}
								label='Teléfono de contacto'
								placeholder='Ingresa un teléfono'
								value={initValue(userData['telefono_contacto'])}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
										?.replaceAll('-', '')
										?.replaceAll('+', '');
									let reg = /[0-9]|\./;
									if (reg.test(val) || val === '') {
										handleSetUserData({ ...userData, telefono_contacto: formatMaskPhoneNumber12Digits(val) });
										formik.handleChange(e);
									}
								}}
								size='medium'
								disabled={shouldDisable}
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 4 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required={false}
								{...formik.getFieldProps('extension_contacto')}
								error={formik.touched.extension_contacto && Boolean(formik.errors.extension_contacto)}
								helperText={formik.touched.extension_contacto ? formik.errors.extension_contacto : ''}
								label='Extensión de contacto'
								placeholder='Ingresar una Extension'
								value={initValue(userData['extension_contacto'])}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
									handleSetUserData({ ...userData, extension_contacto: val });
									formik.handleChange(e);

								}}
								type="text"
								inputProps={{
									inputMode: 'numeric',
									maxLength: 5,
									autoComplete: 'new-password',
								}}
								size='medium'
								disabled={shouldDisable}
							/>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 4 : 6} mb={5} pr={2} item={true}>
							<FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium' error={formik.touched.tipo_cargo_contacto && Boolean(formik.errors.tipo_cargo_contacto)}>
								<InputLabel required id='tipo_cargo_contacto'>
									Tipo de cargo de contacto
								</InputLabel>
								<Select
									required
									{...formik.getFieldProps('tipo_cargo_contacto')}
									error={formik.touched.tipo_cargo_contacto && Boolean(formik.errors.tipo_cargo_contacto)}
									labelId='tipo_cargo_contacto'
									id='tipo_cargo_contacto'
									label='Tipo Cargo de Contacto'
									value={initValue(userData['tipo_cargo_contacto'])}
									onChange={(e) => {
										handleSetUserData({ ...userData, tipo_cargo_contacto: e.target.value });
										formik.handleChange(e);
									}}
									disabled={shouldDisable}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listTipoCargoContacto.map(({ code, value, label }, i) => (
										<MenuItem value={code} key={i}>
											{label}
										</MenuItem>
									))}
								</Select>
								<FormHelperText sx={{ color: '#d32f2f' }}>
									{formik.touched.tipo_cargo_contacto ? formik.errors.tipo_cargo_contacto : ''}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid xs={12} md={isAdminOrCounselor() ? 8 : 6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('Correo_eletrenico_contacto')}
								error={
									formik.touched.Correo_eletrenico_contacto &&
									Boolean(formik.errors.Correo_eletrenico_contacto)
								}
								helperText={
									formik.touched.Correo_eletrenico_contacto
										? formik.errors.Correo_eletrenico_contacto
										: ''
								}
								label='Correo electrónico de contacto'
								placeholder='Ingresa un correo electrónico'
								value={initValue(userData['Correo_eletrenico_contacto'])}
								onChange={(e) => {
									handleSetUserData({ ...userData, Correo_eletrenico_contacto: e.target.value });
									formik.handleChange(e);
								}}
								inputProps={{ type: 'email', maxLength: 80 }}
								size='medium'
								disabled={shouldDisable}
							/>
						</Grid>

					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button variant='contained' sx={{ mr: 1, width: 90, height: 40 }} disabled>
							Atrás
						</Button>
						{/*<Box sx={{ flex: '1 1 auto' }} />*/}
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={(e) => {
								formik.handleSubmit();
							}}
						>
							Siguiente
						</Button>
					</Box>
				</>
			)}
		</>
	);
};
