import { FormEvent, useContext, useState, useEffect } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Box,
	Typography,
	Divider,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
	SelectChangeEvent,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import _ from 'lodash';
import { createCurriculo } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import { initValue, isValidFieldRequired, getDateNowToString, setValueOrDefault, convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY, convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD } from '../../../../../util/Index';
import useEducationParams from '../../../../../hooks/parameters/useEducationParams';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales'
import dayjs, { Dayjs } from 'dayjs';

export const Step7 = () => {
	const { setStep, userData, setUserData, submitData, finalData, data, setData, setCurrentProgress } =
		useContext(multiStepContext);

	const { listSiNo,
		listPais,
		otrosConocimientosList,
		listEstadoCertificado,
		listTipoCapacitacion } = useEducationParams();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [editMode, setEditMode] = useState(false);
	const [openRowEdit, setOpenRowEdit] = useState<number>(0);
	const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
	const [touchedFields, setTouchedFields] = useState<string[]>([]);

	const [display, setDisplay] = useState<string>('');
	const [tieneCapacitaciones, setTieneCapacitaciones] = useState<string>('');
	const [tpCapacitacionCertificacion, setTpCapacitacionCertificacion] = useState<string>('');
	const [nombrePrograma, setNombrePrograma] = useState<string>('');
	const [instituicion, setInstituicion] = useState<string>('');
	const [paisCapacitacione, setPaisCapacitacione] = useState<string>('');
	const [estadoCapacitacione, setEstadoCapacitacione] = useState<string>('');
	const [fechaCertificacion, setFechaCertificacion] = useState<string>('');
	const [duracionHoras, setDuracionHoras] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [step, setStepUser] = useState<string>('step7');
	const [editOrCreateItemInProgress, setEditOrCreateItemInProgress] = useState(false);

	const [rowsCapacitacionCertificacion, setRowsCapacitacionCertificacion] = useState([
		{
			id: 0,
			tpCapacitacionCertificacion: '',
			nombrePrograma: '',
			instituicion: '',
			paisCapacitacione: '',
			estadoCapacitacione: '',
			fechaCertificacion: '',
			duracionHoras: '',
		},
	]);

	const CODIGO_SI = '1';

	//Flag para saber si ha cambiado el input de Tipo de capacitación y certificacion
	const [changedTipoCapacitacionCertificacion, setChangedTipoCapacitacionCertificacion] = useState<boolean>(false);

	//Metodo de control para evento de cambio del input: 'Tipo de experiencia laboral'
	const onChangeTipoCapacitacionCertificacion = (e: SelectChangeEvent) => {
		setTpCapacitacionCertificacion(e.target.value)
		setChangedTipoCapacitacionCertificacion(isValidFieldRequired(e.target.value) ? true : false);
		setEditOrCreateItemInProgress(true);
	}

	useEffect(() => {
		let tiene_capacitaciones = userData['tiene_capacitaciones'] === true ? CODIGO_SI : (userData['tiene_capacitaciones'] === false ? '0' : '');
		setTieneCapacitaciones(tiene_capacitaciones);
		if (
			userData['CurriculoCapacitaciones'] !== null &&
			userData['CurriculoCapacitaciones'] !== undefined &&
			(userData['CurriculoCapacitaciones'] as Array<any>).length > 0
		) {
			const listRowsCapacitacionCertificacion = rowsCapacitacionCertificacion.find((obj) => {
				return obj.id === 0;
			});
			if (listRowsCapacitacionCertificacion) {
				const list = [...rowsCapacitacionCertificacion];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsCapacitacionCertificacion(list);
			}

			///rowsComplementos.push(createObjectDirecaoAtuliza());
			setRowsCapacitacionCertificacion(userData['CurriculoCapacitaciones']);
			setDisplay('none');
			setTieneCapacitaciones(CODIGO_SI);
			setUserData({ ...userData, tiene_capacitaciones: true });
		} else {
			const listRowsCapacitacionCertificacion = rowsCapacitacionCertificacion.find((obj) => {
				return obj.id === 0;
			});
			if (listRowsCapacitacionCertificacion) {
				const list = [...rowsCapacitacionCertificacion];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsCapacitacionCertificacion(list);
			}
		}
	}, []);

	const handleNext = () => {
		setUserData({ ...userData, CurriculoCapacitaciones: rowsCapacitacionCertificacion });
		setStep(8);
	};

	const handleRetorno = () => {
		setUserData({ ...userData, CurriculoCapacitaciones: rowsCapacitacionCertificacion });
		setStep(6);
	};
	const handleclear = () => {
		setChangedTipoCapacitacionCertificacion(false)
		setTpCapacitacionCertificacion('');
		setNombrePrograma('');
		setInstituicion('');
		setPaisCapacitacione('');
		setEstadoCapacitacione('');
		setFechaCertificacion('');
		setDuracionHoras('');
		setOpenRowEdit(0);
		setEditMode(false);
		setEditOrCreateItemInProgress(false);
	};

	const handleValidOnSave = () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];

		//Se ajusta la regla, cuando el usuario ha seleccionado ¿Tiene capacitaciones y certificaciones? : SI, entonces debe diligenciar informacion de almenos una capacitacion y certificacion
		if (!isValidFieldRequired(tieneCapacitaciones)){
			errors.push({ field: '¿Tiene capacitaciónes y certificaciones?', error: 'Debe indicar si tiene o no capacitaciones y certificaciones' });
			camposError.push('tieneCapacitaciones');
		}
		else if (tieneCapacitaciones === "1" && rowsCapacitacionCertificacion.length === 0){
			errors.push({ field: 'Capacitación o Certificación', error: 'Debe diligenciar información de la capacitación o certificación' });
			camposError.push('tpCapacitacionCertificacion');
		}

		if (changedTipoCapacitacionCertificacion && !isValidFieldRequired(tpCapacitacionCertificacion)){
			errors.push({ field: 'Tipo capacitación o certificación', error: 'Campo requerido' });
			camposError.push('tpCapacitacionCertificacion');
		}

		if (changedTipoCapacitacionCertificacion && isValidFieldRequired(tpCapacitacionCertificacion)) {
			if (!isValidFieldRequired(nombrePrograma)){
				errors.push({ field: 'Nombre del Programa', error: 'Campo requerido' });
				camposError.push('nombrePrograma');
			}
			if (!isValidFieldRequired(instituicion)){
				errors.push({ field: 'Instituición', error: 'Campo requerido' });
				camposError.push('institucion');
			}
			if (!isValidFieldRequired(paisCapacitacione)){
				errors.push({ field: 'Pais', error: 'Campo requerido' });
				camposError.push('paisCapacitacione');
			}
			if (!isValidFieldRequired(estadoCapacitacione)){
				errors.push({ field: 'Estado', error: 'Campo requerido' });
				camposError.push('estadoCapacitacione');
			}
			//Estado: Certificado
			if (isValidFieldRequired(estadoCapacitacione) && estadoCapacitacione === '2') {
				if (!isValidFieldRequired(fechaCertificacion)){
					errors.push({ field: 'Fecha de Certificación', error: 'Campo requerido' });
					camposError.push('fechaCertificacion');
				}
			}
			//Se aplican reglas de control para el input duracion en horas
			if (!isValidFieldRequired(duracionHoras)){
				errors.push({ field: 'Duración en horas', error: 'Campo requerido' });
				camposError.push('duracionHoras');
			}
			else if (Number(duracionHoras) <= 0){
				errors.push({ field: 'Duración en horas', error: 'Valores mayores a 0' });
				camposError.push('duracionHoras');
			}
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos completado\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;

	};
	const handleAddCapacitacionCertificacion = () => {
		if (handleValidOnSubmit()) {
			const listTpCapacitacionCertificacion = listTipoCapacitacion.find((obj) => {
				return obj.code === tpCapacitacionCertificacion;
			});
			const listPaisCapacitacione = listPais.find((obj) => {
				return obj.code === paisCapacitacione;
			});
			const listEstadoCapacitacione = listEstadoCertificado.find((obj) => {
				return obj.code === estadoCapacitacione;
			});

			let idTpCapacitacionCertificacion = listTpCapacitacionCertificacion?.code as string;
			let idPaisCapacitacione = listPaisCapacitacione?.code as string;
			let idEstadoCapacitacione = listEstadoCapacitacione?.code as string;

			setRowsCapacitacionCertificacion([
				...rowsCapacitacionCertificacion,
				{
					id: new Date().getTime(),
					tpCapacitacionCertificacion: idTpCapacitacionCertificacion,
					nombrePrograma: nombrePrograma,
					instituicion: instituicion,
					paisCapacitacione: idPaisCapacitacione,
					estadoCapacitacione: idEstadoCapacitacione,
					fechaCertificacion: fechaCertificacion,
					duracionHoras: duracionHoras,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Educación informal agregada con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			handleclear();
			setDisplay('none');
			setEditOrCreateItemInProgress(false);
		}
	};
	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveCapacitacionCertificacion = (id: number) => {
		const list = [...rowsCapacitacionCertificacion];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsCapacitacionCertificacion(list);

		if (list.length === 0) {
			setDisplay('');
			setTieneCapacitaciones('');
		}
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];
		if (!isValidFieldRequired(tpCapacitacionCertificacion)){
			errors.push({ field: 'Tipo capacitación o certificación', error: 'Campo requerido' });
			camposError.push('tpCapacitacionCertificacion');
		}

		if (changedTipoCapacitacionCertificacion && isValidFieldRequired(tpCapacitacionCertificacion)) {
			if (!isValidFieldRequired(nombrePrograma)){
				errors.push({ field: 'Nombre del programa', error: 'Campo requerido' });
				camposError.push('nombrePrograma');
			}
			if (!isValidFieldRequired(paisCapacitacione)){
				errors.push({ field: 'País', error: 'Campo requerido' });
				camposError.push('paisCapacitacione');
			}
			if (!isValidFieldRequired(estadoCapacitacione)){
				errors.push({ field: 'Estado', error: 'Campo requerido' });
				camposError.push('estadoCapacitacione');
			}
			//Estado: Certificado
			if (isValidFieldRequired(estadoCapacitacione) && estadoCapacitacione === '2') {
				if (!isValidFieldRequired(fechaCertificacion)){
					errors.push({ field: 'Fecha de Certificación', error: 'Campo requerido' });
					camposError.push('fechaCertificacion');
				}
			}
			if (!isValidFieldRequired(instituicion)){
				errors.push({ field: 'Institución', error: 'Campo requerido' });
				camposError.push('institucion');
			}

			//Se aplican reglas de control para el input duracion en horas
			if (!isValidFieldRequired(duracionHoras)){
				errors.push({ field: 'Duración en horas', error: 'Campo requerido' });
				camposError.push('duracionHoras');
			}
			else if (Number(duracionHoras) <= 0){
				errors.push({ field: 'Duración en horas', error: 'Valores mayores a 0' });
				camposError.push('duracionHoras');
			}
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);

			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		} else return true;
	};

	async function handleSubmit(event: FormEvent) {
		if (handleValidOnSave()) {
			setUserData({ ...userData, CurriculoCapacitaciones: rowsCapacitacionCertificacion });
			setLoading(true);
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(8);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const showDatePickeRetiro = () => {
		// @ts-ignore
		document.querySelector('#fechaCertificacion').showPicker();
	};

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};
	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			rowsCapacitacionCertificacion !== null &&
			rowsCapacitacionCertificacion !== undefined &&
			(rowsCapacitacionCertificacion as Array<any>).length > 0
		) {
			(rowsCapacitacionCertificacion as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'number'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						otrosConocimientosList.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
            tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
            tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'],'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),
			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
			tiene_nivel_educativo: userData['tiene_nivel_educativo'],
			tiene_experiencia_laboral: userData['tiene_experiencia_laboral'],
			tiene_capacitaciones: createObjectCapacitacionCertificacion()?.length > 0 ? true : (tieneCapacitaciones === CODIGO_SI ? true : (tieneCapacitaciones === '0' ? false : userData['tiene_capacitaciones']))
		};

		return resume;
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (item: any) => {
		setChangedTipoCapacitacionCertificacion(true)
		setEditOrCreateItemInProgress(true)
		setEditMode(true);
		setBorderColorEdit('2px solid blue');
		setTimeout(() => {
			setBorderColorEdit('1px solid');
		}, 4000);
		setOpenRowEdit(item.id);
		window.scrollTo(0, 170);
		setTieneCapacitaciones(CODIGO_SI);
		setTpCapacitacionCertificacion(
			listTipoCapacitacion.find((x: any) => x.value === item.tpCapacitacionCertificacion || x.code === item.tpCapacitacionCertificacion)?.code || ''
		);
		setNombrePrograma(item.nombrePrograma || '');
		setInstituicion(item.instituicion || '');
		setPaisCapacitacione(
			listPais.find((x) => x.value === item.paisCapacitacione || x.code === item.paisCapacitacione)?.code || ''
		);
		setEstadoCapacitacione(
			listEstadoCertificado.find((x: any) => x.value === item.estadoCapacitacione || x.code === item.estadoCapacitacione)?.code || ''
		);
		setDuracionHoras(item.duracionHoras || '');
		setFechaCertificacion(convertDateStringDDMMYYYYToDateStringWithFormatYYYYMMDD(item.fechaCertificacion) || '');
	};

	const handleSaveEdit = () => {
		if (editMode) {
			let iRemove = rowsCapacitacionCertificacion.findIndex((x) => x.id === openRowEdit);

			if (handleValidOnSubmit()) {
				const listTpCapacitacionCertificacion = listTipoCapacitacion.find((obj) => {
					return obj.code === tpCapacitacionCertificacion;
				});
				const listPaisCapacitacione = listPais.find((obj) => {
					return obj.code === paisCapacitacione;
				});
				const listEstadoCapacitacione = listEstadoCertificado.find((obj) => {
					return obj.code === estadoCapacitacione;
				});

				let idTpCapacitacionCertificacion = listTpCapacitacionCertificacion?.code as string;
				let idPaisCapacitacione = listPaisCapacitacione?.code as string;
				let idEstadoCapacitacione = listEstadoCapacitacione?.code as string;

				let itemsToSave = rowsCapacitacionCertificacion.filter((_, i) => i !== iRemove);
				itemsToSave.push({
					id: openRowEdit || new Date().getTime(),
					tpCapacitacionCertificacion: idTpCapacitacionCertificacion,
					nombrePrograma: nombrePrograma,
					instituicion: instituicion,
					paisCapacitacione: idPaisCapacitacione,
					estadoCapacitacione: idEstadoCapacitacione,
					fechaCertificacion: fechaCertificacion,
					duracionHoras: duracionHoras,
				});
				setRowsCapacitacionCertificacion(itemsToSave);
				handleclear();
				setEditOrCreateItemInProgress(false);
			}
		}
	};
	const onChangeTieneCapacitacionesCertificaciones = (e: SelectChangeEvent) => {
		let tiene_capacitaciones = null;
		if (e.target.value == CODIGO_SI) {
			setTieneCapacitaciones(e.target.value)
			if (rowsCapacitacionCertificacion && rowsCapacitacionCertificacion.length === 0) {
				setChangedTipoCapacitacionCertificacion(true);
			}
			tiene_capacitaciones = true;
		} else {
			setTieneCapacitaciones('0')
			handleclear();
			tiene_capacitaciones = false;
		}
		userData['tiene_capacitaciones'] = tiene_capacitaciones;
	}

	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}
	return (
		<>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						Agregar capacitaciones y certificaciones{editMode ? ' - modo de edición' : ''}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					// border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
					border: `${borderColorEdit}`,
				}}
				item
			>
				<Grid xs={4} mb={5} pr={2} display={display} item>
					<FormControl 
						sx={{ minWidth: '100%' }} 
						size='medium'
						error={
							touchedFields.includes('tieneCapacitaciones') 
							&& !isValidFieldRequired(tieneCapacitaciones) 
						}
					>
						<InputLabel required id={'tieneCapacitaciones'}>
							¿Tiene capacitaciones y certificaciones?
						</InputLabel>
						<Select
							required
							labelId={'tieneCapacitaciones'}
							id={'tieneCapacitaciones'}
							label='¿Tiene capacitaciones y certificaciones?'
							value={tieneCapacitaciones}
							onChange={(e) => onChangeTieneCapacitacionesCertificaciones(e)}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{listSiNo.map(({ code, value }) => (
								<MenuItem key={code} value={code}>
									{value}
								</MenuItem>
							))}
						</Select>
						{	touchedFields.includes('tieneCapacitaciones') 
							&& !isValidFieldRequired(tieneCapacitaciones) 
							&& (
								<FormHelperText>
									Debe indicar si tiene o no capacitaciones y certificaciones
								</FormHelperText>
							)
						}
					</FormControl>
				</Grid>

				{tieneCapacitaciones == CODIGO_SI && (
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('tpCapacitacionCertificacion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(tpCapacitacionCertificacion)}
							>
								<InputLabel>Tipo capacitación o certificación</InputLabel>
								<Select
									required
									id={'tpCapacitacionCertificacion'}
									name={'tpCapacitacionCertificacion'}
									label='Tipo capacitación o certificación'
									value={tpCapacitacionCertificacion}
									onChange={(e) => onChangeTipoCapacitacionCertificacion(e)}
									onBlur={() => handleBlur('tpCapacitacionCertificacion')}
								>
									<MenuItem value={''}>{'Seleccione'}</MenuItem>
									{listTipoCapacitacion.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('tpCapacitacionCertificacion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(tpCapacitacionCertificacion) && (
									<FormHelperText>
										{!isValidFieldRequired(tpCapacitacionCertificacion) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								required
								id={'nombrePrograma'}
								name={'nombrePrograma'}
								label='Nombre del programa'
								value={nombrePrograma}
								onChange={(e) => setNombrePrograma(e.target.value)}
								onBlur={() => handleBlur('nombrePrograma')}
								placeholder='Escriba el nombre del programa'
								size='medium'
								error={touchedFields.includes('nombrePrograma') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(nombrePrograma)}
								helperText={touchedFields.includes('nombrePrograma') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(nombrePrograma) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} item>
							<TextField
								fullWidth
								required
								id={'instituicion'}
								name={'instituicion'}
								label='Institución'
								value={instituicion}
								onChange={(e) => setInstituicion(e.target.value)}
								onBlur={() => handleBlur('institucion')}
								placeholder='Escriba el nombre de la institución'
								size='medium'
								error={touchedFields.includes('institucion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(instituicion)}
								helperText={touchedFields.includes('institucion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(instituicion) ? 'Campo requerido' : ''}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('paisCapacitacione') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(paisCapacitacione)}
							>
								<InputLabel required>País</InputLabel>
								<Select
									required
									id={'paisCapacitacione'}
									name={'paisCapacitacione'}
									label='País'
									value={paisCapacitacione}
									onChange={(e) => setPaisCapacitacione(e.target.value)}
									onBlur={() => handleBlur('paisCapacitacione')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listPais.map(({ code, value }, i) => (
										<MenuItem key={i} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('paisCapacitacione') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(paisCapacitacione) && (
									<FormHelperText>
										{!isValidFieldRequired(paisCapacitacione) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('estadoCapacitacione') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(estadoCapacitacione)}
							>
								<InputLabel required>Estado</InputLabel>
								<Select
									required
									id={'estadoCapacitacione'}
									name={'estadoCapacitacione'}
									label='Estado'
									value={estadoCapacitacione}
									onChange={(e) => setEstadoCapacitacione(e.target.value)}
									onBlur={() => handleBlur('estadoCapacitacione')}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listEstadoCertificado.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('estadoCapacitacione') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(estadoCapacitacione) && (
									<FormHelperText>
										{!isValidFieldRequired(estadoCapacitacione) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{estadoCapacitacione === '2' && (
							<>
								<Grid item xs={4} mb={5}>
									<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
										<DatePicker
											disableFuture
											format='DD/MM/YYYY'
											value={dayjs(fechaCertificacion, 'YYYY-MM-DD')}
											onOpen={() => handleBlur('fechaCertificacion')}
											onChange={(newValue) => {
												setFechaCertificacion(newValue?.format('YYYY-MM-DD') || '')
											}}
											slotProps={{
												textField: {
													label: 'Fecha de Certificación',
													id: 'fechaCertificacion',
													name: 'fechaCertificacion',
													required: true,
													variant: 'outlined',
													fullWidth: true,
													error: touchedFields.includes('fechaCertificacion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(fechaCertificacion),
													helperText: touchedFields.includes('fechaCertificacion') && changedTipoCapacitacionCertificacion && !isValidFieldRequired(fechaCertificacion) ? 'Campo requerido' : ''
												},
												actionBar: { actions: ["clear", "cancel", "today"] }
											}}
										/>
									</LocalizationProvider>
								</Grid>
							</>
						)}
						<Grid xs={12} md={4} mb={5} pr={2} item className='pe-0'>
							<TextField
								required
								fullWidth
								id={'duracionHoras'}
								name={'duracionHoras'}
								label='Duración en horas'
								value={duracionHoras}
								onChange={(e) => setDuracionHoras(e.target.value)}
								onBlur={() => handleBlur('duracionHoras')}
								size='medium'
								type='number'
								InputProps={{ inputProps: { min: 1 } }}
								error={touchedFields.includes('duracionHoras') && changedTipoCapacitacionCertificacion && (!isValidFieldRequired(duracionHoras) || Number(duracionHoras) <= 0)}
								helperText={touchedFields.includes('duracionHoras') && (changedTipoCapacitacionCertificacion && !isValidFieldRequired(duracionHoras)) ? 'Campo requerido' : (changedTipoCapacitacionCertificacion && Number(duracionHoras) <= 0) ? 'Valores mayores a 0' : ''}
							/>
						</Grid>
					</Grid>
				)}
				<Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>

					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						{editMode ? (
							<>
								<Button
									variant='contained'
									onClick={handleSaveEdit}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Guardar
								</Button>
							</>
						) : (
							<>
								<Button
									disabled={tieneCapacitaciones === '0' || tieneCapacitaciones === ''}
									variant='contained'
									onClick={handleAddCapacitacionCertificacion}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									Agregar
								</Button>
							</>
						)
						}
						{editOrCreateItemInProgress && (
							<Button
								variant='contained'
								sx={{ width: 90, background: '#6c757d', mr: 1 }}
								onClick={handleclear}
							>
								Cancelar
							</Button>
						)}
					</Box>
				</Grid>
			</Grid>
			{rowsCapacitacionCertificacion.length > 0 && (
				<Grid xs={12} item>
					<Typography color='#2B2B2B' variant='h6'>
						Agregados
					</Typography>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
				</Grid>
			)}
			<Grid xs={12} item>
				{rowsCapacitacionCertificacion.map((row, i) => (
					<Grid xs={12} item key={i} display={openRowEdit === row.id ? 'none' : ''}>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							sx={{
								backgroundColor: '#1C447F',
								pt: 2,
								pl: 2,
								pb: 2,
								pr: 2,
								borderTopLeftRadius: 6,
								borderTopRightRadius: 6,
							}}
							item
						>
							<Typography color='white' variant='h5'>
								{row.nombrePrograma || ''}
							</Typography>
							<Box display='flex' justifyContent='end' alignItems='center'>
								<div>
									<div className='d-flex justify-content-end flex-shrink-0'>
										<button
											disabled={editOrCreateItemInProgress}
											className='btn btn-icon btn-active-color-secondary btn-sm me-3'
											style={{ background: '#C4D2E8' }}
											onClick={() => handleEdit(row)}
										>
											<EditIcon />
										</button>

										<button
											disabled={editOrCreateItemInProgress}
											className='btn btn-icon btn-active-color-secondary btn-sm'
											style={{ background: '#DD1342' }}
											onClick={() => handleRemoveCapacitacionCertificacion(row.id)}
										>
											<DeleteOutlinedIcon style={{ color: 'white' }} />
										</button>
									</div>
								</div>
							</Box>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							mb={5}
							sx={{
								border: 1,
								borderBottom: 3,
								borderColor: '#AFADAD',
								borderBottomLeftRadius: 6,
								borderBottomRightRadius: 6,
								pt: 5,
								pl: 3,
								pr: 3,
							}}
							item
						>
							<Grid xs={12} md={3} mb={5} item>
								<Typography>
									Tipo de capacitación: {listTipoCapacitacion.find((i) => i.value === row.tpCapacitacionCertificacion || i.code === row?.tpCapacitacionCertificacion)?.value || ''}
								</Typography>
								<Typography>País: {listPais.find((i) => i.code === row.paisCapacitacione || i.value === row.paisCapacitacione)?.value || ''}</Typography>
								<Typography>Duración en horas: {row.duracionHoras || ''}</Typography>
							</Grid>
							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={3} mb={5} item>
								<Typography>Nombre del programa: {row.nombrePrograma || ''}</Typography>
								<Typography>Estado: {listEstadoCertificado.find((i) => i.code === row.estadoCapacitacione || i.value === row.estadoCapacitacione)?.value || ''}</Typography>
							</Grid>
							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={3} mb={5} item>
								<Typography>Institución: {row.instituicion || ''}</Typography>
								{row.fechaCertificacion && (<Typography>Fecha de certificación: {convertDateStringYYYYMMDDToDateStringWithFormatDDMMYYYY(row.fechaCertificacion) || ''}</Typography>)}
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />

			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					Atrás
				</Button>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
					disabled={editOrCreateItemInProgress}
				>
					Siguiente
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
