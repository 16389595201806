import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { Link } from 'react-router-dom';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { concatArrayToStringJoinBy, initValue, truncateWords } from '../../util/Index';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import { ListStatusApprovalModel } from '../../models/StatusApprovalModel'
import {
	getCompanies,
	downloadZipAllFilesByCompany,
	deleteEmpresaById,
	updateStatusEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	TablePagination,
	Stack,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	CircularProgress,
	Select,
	InputLabel
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { AddCircle } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

interface StatusCompanyProps {
	id: number;
	status?: string;
	observaciones?: string;
}

const CompanyManagementPage = () => {
	const navigate = useNavigate();
	const { isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [companyData, setCompanyData] = useState<StatusCompanyProps>({
		id: 0,
	});
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	const [open, setOpen] = useState(false);
	const [idEmpresa, setIdEmpresa] = useState();
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: ''
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getCompaniesAPI();
	}, [filter]);

	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, estado: filter.estado };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const getCompaniesAPI = async () => {
		setLoading(true);
		try {
			const response = await getCompanies(
				`page=${filter.page}&limit=${filter.limit}&nombre_employer=${userData.nombre_employer?.trim()}${filter.estado !== '' ? '&estado=' + filter.estado : ''}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	const downloadAllFilesZip = async (id: number) => {
		try {
			setSubmitting(true);
			const response = await downloadZipAllFilesByCompany(`id_empresa=${id}`);
			const blob = response.data;
			const url = URL.createObjectURL(blob);
			window.location.href = url;
		} catch (error: any) {
			if (error.response) {
				const data = JSON.parse(await error.response.data.text());
				console.error(data.error)
				setOpen(true);
				setAlert(false);
				setAlertMsg(data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setSubmitting(false);
		}
	};

	const updateActivationCompany = async (id: number, type: boolean) => {
		try {
			setSubmitting(true);
			if (type) {
				const response = await updateEmpresaToActive(`id=${id}`);
				setAlertMsg(response.data.message);
			} else {
				const response = await updateEmpresaToDesactive(`id=${id}`);
				setAlertMsg(response.data.message);
			}
			setOpen(true);
			setAlert(true);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setSubmitting(false);
		}
	};

	const changeStatusEmpresa = async () => {
		try {
			setOpenAprobada(false);
			setOpenRechazada(false);
			setLoading(true);
			setSubmitting(true);
			const retorno = await updateStatusEmpresa(companyData);
			setAlertMsg(retorno.data?.message);
			setOpen(true);
			setAlert(true);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
		}
	};

	const deleteEmpresa = async (id: any) => {
		try {
			setSubmitting(true);
			await deleteEmpresaById(`id=${id}`);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`¡Empresa eliminada con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setSubmitting(false);
			setOpenModal(false);
		}
	};

	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const { data } = props;

		return (
			<>
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{isAdminOrCounselor() && (<MenuItem onClick={handleClose}>
						<Link to={getPathEditarEmpresa(data.id)} style={{ width: '100%' }}>
							<BorderColorIcon />
							&nbsp; Editar
						</Link>
					</MenuItem>)}
					{isAdminOrCounselor() && (<MenuItem onClick={handleClose}>
						<Link to={getPathInformacionAdicionalEmpresa(data.id)} style={{ width: '100%' }}>
							<AddCircle />
							&nbsp; Información Adicional
						</Link>
					</MenuItem>)}
					{isAdminOrCounselor() && (<MenuItem onClick={handleClose}>
						{data.active ? (
							<button className={`${styleScss['button-link']} pointer`} onClick={() => updateActivationCompany(data.id, false)}>
								<ToggleOffIcon />
								&nbsp; Inactivar
							</button>
						) : (
							<button className={`${styleScss['button-link']} pointer`} onClick={() => updateActivationCompany(data.id, true)}>
								<ToggleOnIcon />
								&nbsp; Activar
							</button>
						)}
					</MenuItem>)}
					{isAdminOrCounselor() && data.status !== 'Aprobada' && data.status !== 'Rechazada' && (
						<MenuItem onClick={handleClose}>
							<button className={`${styleScss['button-link']} pointer`} onClick={() => handleOpenRechazada(data.id)}>
								<UnpublishedIcon />
								&nbsp; Rechazar
							</button>
						</MenuItem>
					)}
					{isAdminOrCounselor() && data.status !== 'Aprobada' && (
						<MenuItem onClick={handleClose}>
							<button className={`${styleScss['button-link']} pointer`} onClick={() => handleOpenAprobada(data.id)}>
								<VerifiedIcon />
								&nbsp; Aprobar
							</button>
						</MenuItem>
					)}
					{isAdminOrCounselor() && (
						<MenuItem onClick={handleClose}>
							<Link to={getPathEGestionarArchivosEmpresa(data.id)} style={{ width: '100%' }}>
								<FormatListBulletedIcon />
								&nbsp; Gestionar Archivos
							</Link>
						</MenuItem>
					)}
					<MenuItem onClick={handleClose}>
						<button className={`${styleScss['button-link']} pointer`} onClick={() => downloadAllFilesZip(data.id)}>
							<FileDownloadIcon />
							&nbsp; Descargar Archivos
						</button>
					</MenuItem>
					{
						data.status === 'En análisis' ?
							<MenuItem onClick={handleClose}>
								<button className={`${styleScss['button-link']} pointer`} onClick={() => navigateToCreateAcompaniment(data.id)}>
									<i className="bi bi-building-add" style={{ color: "#2266cc", marginLeft: 4, fontSize: 15 }} ></i>
									&nbsp; Crear acompañamiento empresarial
								</button>
							</MenuItem> : null
					}

					{/* Enviar el Nit de la empresa a crear acompañamiento empresarial */}



					{isCounselorEmployerRole() && data.status === "Aprobada" ? (
						<MenuItem onClick={handleClose}>
							<button className={`${styleScss['button-link']} pointer`} onClick={() => navigateToCreateAcompaniment(data.id)}>
								<i className="bi bi-building-add" style={{ color: "#2266cc", marginLeft: 4, fontSize: 15 }} ></i>
								&nbsp; Crear acompañamiento empresarial
							</button>
						</MenuItem>
					) : (
						<div></div>
					)}



					{isAdminRole() ? (
						<MenuItem onClick={handleClose}>
							<button className={`${styleScss['button-link']} pointer`} onClick={() => handleOpenModal(data.id)}>
								<DeleteIcon />
								&nbsp; Eliminar
							</button>
						</MenuItem>
					) : (
						<div></div>
					)}
				</Menu>
			</>
		);
	};

	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = (id: any) => {
		setIdEmpresa(id);
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const [openAprobada, setOpenAprobada] = useState(false);
	const handleOpenAprobada = (id: any) => {
		setCompanyData({ id: id, status: 'Aprobada' });
		setOpenAprobada(true);
	};
	const handleCloseAprobada = () => {
		setOpenAprobada(false);
	};
	const [openRechazada, setOpenRechazada] = useState(false);
	const handleOpenRechazada = (id: any) => {
		setUserData({ ...userData, observaciones: '' });
		setCompanyData({ id: id, status: 'Rechazada' });
		setOpenRechazada(true);
	};
	const handleCloseRechazada = () => {
		setOpenRechazada(false);
		setUserData({ ...userData, observaciones: '' });
	};

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */
	const isAdminOrCounselor = () => {
		return isAdminRole() || isCounselorEmployerRole();
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */
	const getPathRegistroEmpresa = () => {
		if (isAdminRole()) {
			return '/admin/registro-de-empresa';
		} else if (isCounselorEmployerRole()) {
			return '/consejero/registro-de-empresa';
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */
	const getPathEditarEmpresa = (idEmpresa: number) => {
		if (isAdminRole()) {
			return `/admin/registro-de-empresa/${idEmpresa}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/registro-de-empresa/${idEmpresa}`;
		} else {
			return 'auth/*';
		}
	}

	const navigateToCreateAcompaniment = (nitEmpresa: any) => {
		if (isCounselorEmployerRole()) {
			navigate(`/consejero/crear-acompanamiento-empresarial/${nitEmpresa}`);
		} else {
			navigate('/auth/*');
		}
	};

	/**
 * Retorna el path del usuario acorde al rol
 * @returns 
 */
	const getPathInformacionAdicionalEmpresa = (idEmpresa: number) => {
		if (isAdminRole()) {
			return `/admin/adicional-empresa/${idEmpresa}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/adicional-empresa/${idEmpresa}`;
		} else {
			return 'auth/*';
		}
	}

	/**
		 * Retorna el path del usuario acorde al rol
		 * @returns 
		 */
	const getPathEGestionarArchivosEmpresa = (idEmpresa: number) => {
		if (isAdminRole()) {
			return `/admin/empresa/${idEmpresa}/archivos`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/empresa/${idEmpresa}/archivos`;
		} else {
			return 'auth/*';
		}
	}

	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		handleChangePage(0);
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, nombre_employer: event.target.value });
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={isSubmitting} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 15px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 1px 1px 15px' }}>
								<div className='input-group mb-3'>
									<FormControl sx={{ minWidth: 120 }} fullWidth size="small">
										<InputLabel id='estado_aprobacion'
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Estado</InputLabel>
										<Select
											labelId='estado_aprobacion'
											id='estado_aprobacion'
											label='Estado'
											value={initValue(filter.estado)}
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
											onChange={(e) => {
												setFilter({
													...filter,
													page: 1,
													estado: e.target.value
												});
												setPage(0);
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{ListStatusApprovalModel.map((item) => (
												<MenuItem key={item.code} value={item.value}>
													{item.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={'input-group mb-3'}>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Buscar por Nom. Empresa/NIT/Correo'
										aria-label='Buscar por Nom. Empresa/NIT/Correo'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchEmpresa'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							<div className='col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								{isAdminOrCounselor() && (
									<Link to={getPathRegistroEmpresa()}
										className='btn btn-primary btn-lg'
										role="button">Registrar empresa</Link>
								)}
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
					<Stack padding={2}>
						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>
							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										{!isEmployerRole() && (
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
												<h4>Nombre Empresa</h4>
											</TableCell>
										)}
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Consejero
												empleador</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>NIT</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Responsable</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Correo electrónico</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Vacantes registradas</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Estado</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Activo</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Acciones</h4>
										</TableCell>
									</TableRow>
								</TableHead>
								{!loading && <TableBody>
									{data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											{!isEmployerRole() && (
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
													<Tooltip title={x.razon_social.toLowerCase()} placement='left' arrow>
														<p>
															{truncateWords(x.razon_social.toLowerCase(), 35)}
														</p>
													</Tooltip>
												</TableCell>
											)}
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.EmpresaExtraInfo?.User?.UserComplemento?.primer_nome} {x?.EmpresaExtraInfo?.User?.UserComplemento?.primer_apelido}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x?.numero_documento}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x?.Users.length > 0 ? concatArrayToStringJoinBy([x.Users[0].UserComplemento?.primer_nome, x.Users[0].UserComplemento?.primer_apelido], ' ') : ''}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x?.Users.length > 0 ? x.Users[0].email : ''}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.EmpresaVagas.length}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.status}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.active ? 'Sí' : 'No'}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												<MenuAction data={{ id: x.id, active: x.active, status: x.status }} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>}
								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={8}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}

							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>
					</Stack>
				</div>
			</div>
			<div>
				<Dialog
					open={openModal}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Eliminar empresa'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Esta acción excluirá la empresa de cuálquier proceso en la plataforma de empleo.
						</DialogContentText>
						<br />
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea eliminar esta empresa?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModal}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => deleteEmpresa(idEmpresa)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openAprobada}
					keepMounted
					onClose={handleCloseAprobada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Confirmación de aprobación de empresa'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea aprobar esta empresa?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseAprobada}
							disabled={isSubmitting}
							autoFocus
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							disabled={isSubmitting}
							autoFocus
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Aprobar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openRechazada}
					keepMounted
					onClose={handleCloseRechazada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Rechazar la empresa'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea rechazar esta empresa?
						</DialogContentText>
						<DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
							Escriba las razones del rechazo a continuación
						</DialogContentText>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								sx={{ width: 500, height: 250 }}
								rows={10}
								id='observaciones'
								name='observaciones'
								label='Observaciones'
								value={initValue(companyData['observaciones'])}
								onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
								size='medium'
								multiline
								inputProps={{ maxLength: 300 }}
								disabled={loading}
								helperText={`${companyData['observaciones'] === undefined ? '0' : companyData['observaciones']?.length}/${1200}`}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseRechazada}
							autoFocus
							disabled={isSubmitting}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							autoFocus
							disabled={companyData['observaciones'] === undefined || companyData['observaciones'] === '' || companyData['observaciones']?.trim() === ''}
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Rechazar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const AdminCompanyManagementWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión Empresas</PageTitle>
			<CompanyManagementPage />
		</>
	);
};

export { AdminCompanyManagementWrapper };
