import React, { useState } from 'react';
import { CurriculumRegistration } from '../modules/auth/components/CurriculumRegistration';

export const multiStepContext = React.createContext();

const CurriculumContext = () => {
	const [currentStep, setStep] = useState(1);
	const [userData, setUserData] = useState(['']);
	const [finalData, setFinalData] = useState(['']);
	const [data, setData] = useState([]);
	const [loadedData, setLoadedData] =
		useState(false); /*Used to know if the information has been loaded once (edit mode)*/
	const [currentProgress, setCurrentProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	const submitData = () => {
		setFinalData((finalData) => [...finalData, userData]);
		setUserData('');
	};

	return (
		<div>
			<multiStepContext.Provider
				value={{
					currentStep,
					setStep,
					userData,
					setUserData,
					finalData,
					setFinalData,
					submitData,
					data,
					setData,
					loadedData,
					setLoadedData,
					currentProgress,
					setCurrentProgress,
					loading,
					setLoading,
				}}
			>
				<CurriculumRegistration />
			</multiStepContext.Provider>
		</div>
	);
};

export default CurriculumContext;
