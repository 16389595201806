import { FormEvent, useContext, useEffect, useState } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CompanyEditContext';
import {
	formatMaskPhoneNumber12Digits,
	isValidFieldRequired,
	matchEmail,
	isValidFirstSecondNameFirstSecondLastName,
	helperValidFirstSecondNameFirstSecondLastName,
	isValidColombianPhoneNumber,
	helperIsValidColombianPhoneNumber,
	eventOnKeyDownInputNumber,
	helperIsValidDocumentNumber,
	isValidDocumentNumber
} from '../../../../../util/Index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getVerifyDocumentExists, getVerifyEmailExists } from '../../../core/_requests';
import useDocumentTypeParams from '../../../../../hooks/parameters/useDocumentTypeParams';
import { getUserDataById, updateCompany } from '../../../../apps/user-management/users-list/core/_requests';
import { useNavigate } from 'react-router-dom';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import useAdminPositioTypeParams from '../../../../../hooks/parameters/useAdminPositionTypeParams';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const Step3 = () => {
	const { setStep, userData, firstUser, setFirstUser, firstCompany } =
		useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [validationsDocuments, setValidationsDocuments] = useState<string[]>([]);
	const navigate = useNavigate();

	const [documentExist, setDocumentExists] = useState(false);
	const [emailExists, setEmailExists] = useState(false);

	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');
	const { listTipoDocumentoRepresentanteLegal } = useDocumentTypeParams();
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [loading, setLoading] = useState(false);

	const [changedEmail, setChangedEmail] = useState(false);
	const [changedNumeroDocumento, setChangedNumeroDocumento] = useState(false);
	const { cargoAdministradorList } = useAdminPositioTypeParams();


	useEffect(() => {
		validationSchemaByTpDoc(firstUser.complemento['tipo_doc']);
		loadPersonalInfo();
	}, []);
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	const loadPersonalInfo = async () => {
		const userDataState = userData.Users[0];
		const userId = userDataState.id;
		if (!userDataState) {
			return;
		}

		if (!userId) {
			return;
		}

		try {
			setLoading(true);
			const userDataFromAPI: any = await getUserDataById(`id=${userId}`);

			if (!userDataFromAPI || !userDataFromAPI.data) {
				return;
			}

			const { primer_nome, segundo_nome, primer_apelido, segundo_apelido,
				tipo_doc, numero_doc, cargo, celular } = userDataFromAPI.data.UserComplemento;

			setFirstUser({
				...firstUser,
				user: {
					...firstUser.user,
					email: userDataState.email,
					confirma_email: userDataState.email,
					role: userDataState.role
				},
				complemento: {
					...firstUser.complemento,
					tipo_doc: tipo_doc,
					numero_doc: numero_doc,
					primer_nome: primer_nome,
					segundo_nome: segundo_nome,
					primer_apelido: primer_apelido,
					segundo_apelido: segundo_apelido,
					cargo: cargo,
					celular: celular
				}
			})
		} catch (error: any) {
			console.error("Error al cargar datos del representante de la empresa", error);

		} finally {
			setLoading(false);
		}
	}

	const handleClose = () => {
		setOpen(false);
	};


	const isValidEmail = (e: string): boolean => {
		if (e?.match(matchEmail())) return true;

		return false;
	};

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
		try {
			const isValidToConinue = await handleNext();
			if (isValidToConinue) {
				setRequestInProgress(true);
				firstCompany.company.telefono = firstCompany.company?.telefono
					?.replace(/[^\d]/g, '')
					?.replaceAll('-', '')
					?.replaceAll('+', '');
				const companyBody = {
					firstCompany,
					firstUser,
				};
				await updateCompany(companyBody);
				setAlert(true);
				setOpen(true);
				setAlertMsg('¡Registro editado con éxito!');
				setTimeout(() => {
					navigate(-1);
				}, 2000);
			}
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setTimeout(() => {
				setRequestInProgress(false);
			}, 2000);
		}
	}

	const handleNext = async () => {
		let errors: { field: string; error: string }[] = [];
		let camposError: string[] = [];

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true)) {			
			camposError.push('primer_nome');
			errors.push({ field: 'Primer Nombre', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true) });
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nombre'], false)) {			
			camposError.push('segundo_nombre');
			errors.push({ field: 'Segundo Nombre', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nombre'], true) });
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true)) {			
			camposError.push('primer_apelido');
			errors.push({ field: 'Primer Apellido', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true) });
		}

		if (!isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false)) {			
			camposError.push('segundo_apelido');
			errors.push({ field: 'Segundo Apellido', error: helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false) });
		}

		if (!isValidFieldRequired(firstUser.complemento['tipo_doc'])) {			
			camposError.push('tipo_doc');
			errors.push({ field: 'Tipo de documento', error: 'Campo requerido' });
		}

		if (!isValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true)) {			
			camposError.push('segundo_apelido');
			errors.push({ field: 'Número de documento', error: helperIsValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) });
		}

		if (!isValidFieldRequired(firstUser.user['email'])) {			
			camposError.push('email');
			errors.push({ field: 'Correo electrónico', error: 'Campo requerido' });
		}

		else if (!isValidEmail(firstUser.user['email'])) {			
			camposError.push('email');
			errors.push({ field: 'Correo electrónico', error: 'Correo electrónico no es válido' });
		}

		if (isValidEmail(firstUser.user['email']) && !(firstUser.user['confirma_email'] === firstUser.user['email'])) {			
			camposError.push('confirma_email');
			errors.push({ field: 'Confirmar correo electrónico', error: 'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente' });
		}

		if (!isValidColombianPhoneNumber(firstUser.complemento['celular'], true)) {			
			camposError.push('celular');
			errors.push({ field: 'Teléfono', error: helperIsValidColombianPhoneNumber(firstUser.complemento['celular'], true) });
		}

		if (!isValidFieldRequired(firstUser.complemento['cargo'])) {			
			camposError.push('cargo');
			errors.push({ field: 'Cargo', error: 'Campo requerido' });
		}

		setTouchedFields(camposError);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}

		let existsDocument = false;
		let existsEmail = false;

		if (changedNumeroDocumento && isValidFieldRequired(firstUser.complemento['numero_doc']))
			existsDocument = await verifyDocumentExists(firstUser.complemento['numero_doc']);

		if (changedEmail && isValidFieldRequired(firstUser.user['email']) && isValidEmail(firstUser.user['email']))
			existsEmail = await verifyEmailExists(firstUser.user['email']);

		if (!existsDocument && !existsEmail) {
			//Se garantiza el mapeo de información del correo en el modelo de UserComplemento
			setFirstUser({
				...firstUser,
				complemento: { ...firstUser.complemento, correo: firstUser.user['email'] },
			})
			return true;
		} else {
			setAlert(false);
			setAlertMsg(
				`\n${validationsDocuments.map((x) => {
					return `\n${x}`;
				})}`
			);
			setOpen(true);
			setValidationsDocuments([]);
			return false;
		}
	};

	/**
	 * Llamado al servicio que valida la existencia del numero de documento en el modelo de user complemento
	 * @param documentNumber 
	 * @returns 
	 */
	const verifyDocumentExists = async (documentNumber: string) => {
		try {
			const response = await getVerifyDocumentExists(`document=${documentNumber}`);
			setDocumentExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				validationsDocuments.push(error.response.data.error);
				//TODO: Revisar este comportamiento
				setDocumentExists(true);
				return true;
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
				return true;
			}
		}
	};

	/**
	 * Llamado al servicio que valida la  existencia del email en el modelo de usuarios
	 * @param email 
	 * @returns 
	 */
	const verifyEmailExists = async (email: string) => {
		try {
			const response = await getVerifyEmailExists(`email=${email}`);
			setEmailExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			if (error.response) {
				console.log(`error`, error.response.data.error);
				validationsDocuments.push(error.response.data.error);
				setEmailExists(true);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			return true;
		}
	};

	//validation for candidate document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			case 'CC':
				//CC: entre 3 a 8 dígitos y 10 a 11 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(11);
				break;
			/*case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;*/
			case 'CE':
				//CE: 1 a 7 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(7);
				break;
			case 'PA':
				//PA: entre 1 a 17 caracteres alfanuméricos (acepta número y letras)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(1);
				setMaxDocumentFieldLenght(17);
				break;
			case 'PEP':
				//PEP: 15 y 20 dígitos (sólo números)
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(20);
				break;
			case 'PT':
				//PPT: El documento debe ser numérico y contener entre 4 y 7 dígitos
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};

	return (
		<> {loading
			? <LinearLoadingSpinner isLoading={true} />
			: <>
				<LoadingBackdrop loading={requestInProgress} />
				<Box sx={{ mb: 3 }}>
					<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
						Datos del administrador de la cuenta en esta plataforma
					</Typography>
				</Box>
				<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								required
								id='primer_nombre'
								name='primer_nombre'
								label='Primer nombre'
								value={firstUser.complemento['primer_nome']}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, primer_nome: e.target.value },
									})
								}
								onBlur={() => handleBlur('primer_nombre')}
								size='medium'
								error={touchedFields.includes('primer_nombre') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true)}
								helperText={touchedFields.includes('primer_nombre') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_nome'], true) : ''}
								inputProps={{ minLength: 2, maxLength: 40 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								id='segundo_nombre'
								name='segundo_nombre'
								label='Segundo nombre'
								value={firstUser.complemento['segundo_nome']}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, segundo_nome: e.target.value },
									})
								}
								onBlur={() => handleBlur('segundo_nombre')}
								size='medium'
								error={touchedFields.includes('segundo_nombre') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nome'])}
								helperText={touchedFields.includes('segundo_nombre') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_nome']) : ''}
								inputProps={{ maxLength: 40 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								required
								id='primer_apelido'
								name='primer_apelido'
								label='Primer apellido'
								value={firstUser.complemento['primer_apelido']}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, primer_apelido: e.target.value },
									})
								}
								onBlur={() => handleBlur('primer_apellido')}
								size='medium'
								error={touchedFields.includes('primer_apellido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true)}
								helperText={touchedFields.includes('primer_apellido') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['primer_apelido'], true) : ''}
								inputProps={{ minLength: 2, maxLength: 40 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								id='segundo_apelido'
								name='segundo_apelido'
								label='Segundo apellido'
								value={firstUser.complemento['segundo_apelido']}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, segundo_apelido: e.target.value },
									})
								}
								onBlur={() => handleBlur('segundo_apellido')}
								size='medium'
								error={touchedFields.includes('segundo_apellido') && !isValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false)}
								helperText={touchedFields.includes('segundo_apellido') ? helperValidFirstSecondNameFirstSecondLastName(firstUser.complemento['segundo_apelido'], false) : ''}
								inputProps={{ maxLength: 40 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstUser.complemento['tipo_doc'])}
						>
							<InputLabel id='tipo_documento' required>Tipo de documento</InputLabel>
							<Select
								required
								labelId='tipo_documento'
								id='tipo_documento'
								label='Tipo de documento'
								value={firstUser.complemento['tipo_doc']}
								size='medium'
								onChange={(e) => {
									firstUser.complemento['numero_doc'] = '';
									validationSchemaByTpDoc(e.target.value);
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, tipo_doc: e.target.value },
									});
								}}
								onBlur={() => handleBlur('tipo_documento')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{listTipoDocumentoRepresentanteLegal.map(({ code, value }) => (
									<MenuItem value={code} key={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('tipo_documento') && !isValidFieldRequired(firstUser.complemento['tipo_doc']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstUser.complemento['tipo_doc']) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								fullWidth
								required
								id='numero_documento'
								name='numero_documento'
								label='Número de documento'
								value={firstUser.complemento['numero_doc']}
								onKeyUp={() => setDocumentExists(false)}
								onChange={(e) => {
									if (e.target.value.length <= maxDocumentFieldLenght)
										setChangedNumeroDocumento(true)
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, numero_doc: e.target.value },
									});
								}}
								onBlur={() => handleBlur('numero_documento')}
								type={documentTypeField}
								size='medium'
								inputProps={{
									maxLength: maxDocumentFieldLenght,
									minLength: minDocumentFieldLenght,
									readOnly: !isValidFieldRequired(firstUser.complemento['tipo_doc'])
								}}
								onKeyDown={documentTypeField === 'number' ? eventOnKeyDownInputNumber : undefined}  // this is where the magic happen 
								error={touchedFields.includes('numero_documento') && !isValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true) || documentExist}
								helperText={
									touchedFields.includes('numero_documento') && !isValidFieldRequired(firstUser.complemento['tipo_doc']) ? 'Primero seleccione el tipo de documento'
										:
										(
											documentExist ? 'Número de documento ya registrado!' : helperIsValidDocumentNumber(firstUser.complemento['numero_doc'], documentTypeField, firstUser.complemento['tipo_doc'], minDocumentFieldLenght, maxDocumentFieldLenght, true)
										)
								}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<OverlayTrigger
							placement='right'
							delay={{ show: 250, hide: 400 }}
							overlay={(props) => (
								<Tooltip id='button-tooltip' {...props}>
									{/* (Código de país + Código de area + Numero del teléfono) */}
								</Tooltip>
							)}
						>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<TextField
									required
									fullWidth
									id='telefono'
									name='telefono'
									label='Teléfono'
									value={firstUser.complemento['celular']}
									onChange={(e) => {
										let val = e.target.value
											?.replaceAll(/[^\d]/g, '')
											?.replaceAll('-', '')
											?.replaceAll('+', '');
										let reg = /[0-9]|\./;
										if (reg.test(val) || val === '') {
											setFirstUser({
												...firstUser,
												complemento: {
													...firstUser.complemento,
													celular: formatMaskPhoneNumber12Digits(val),
												},
											});
										}
									}}
									onBlur={() => handleBlur('telefono')}
									size='medium'
									error={touchedFields.includes('telefono') && !isValidColombianPhoneNumber(firstUser.complemento['celular'], true)}
									helperText={touchedFields.includes('telefono') ? helperIsValidColombianPhoneNumber(firstUser.complemento['celular'], true) : ''}
									InputProps={{
										/* endAdornment: (
											<InputAdornment position='end'>
												<i
													className='bi bi-question-circle-fill'
													style={{ color: '#2266cc', marginLeft: '5px' }}
												></i>
											</InputAdornment>
										), */
									}}
									inputProps={{
										maxLength: 10,
									}}
								/>
							</FormControl>
						</OverlayTrigger>
					</Grid>
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<FormControl
							fullWidth
							size='medium'
							error={touchedFields.includes('cargo') && !isValidFieldRequired(firstUser.complemento['cargo'])}
						>
							<InputLabel id='cargo' required>Cargo</InputLabel>
							<Select
								required
								labelId='cargo'
								id='cargo'
								label='Cargo'
								value={firstUser.complemento['cargo']}
								onChange={(e) => {
									firstUser.complemento['cargo'] = '';
									setFirstUser({
										...firstUser,
										complemento: { ...firstUser.complemento, cargo: e.target.value },
									});
								}}
								onBlur={() => handleBlur('cargo')}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{/* TO-DO remover esta logica una vez la homologacion de este dato haya terminado */}
								{firstUser.complemento['cargo'] !== '1' && firstUser.complemento['cargo'] !== '2' && firstUser.complemento['cargo'] !== '3' ?
									<MenuItem value={firstUser.complemento['cargo']} key={0}>
										{firstUser.complemento['cargo']}
									</MenuItem> : <div></div>
								}
								{cargoAdministradorList.map(({ code, value }) => (
									<MenuItem value={code} key={code}>
										{value}
									</MenuItem>
								))}
							</Select>
							{touchedFields.includes('cargo') && !isValidFieldRequired(firstUser.complemento['cargo']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstUser.complemento['cargo']) ? 'Campo requerido' : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>					
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<OverlayTrigger
							placement='left'
							delay={{ show: 250, hide: 400 }}
							overlay={(props) => (
								<Tooltip id='button-tooltip' {...props}>
									Este correo electrónico será el usuário de login
								</Tooltip>
							)}
						>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<TextField
									required
									fullWidth
									id='correo_eletronico'
									name='correo_eletronico'
									label='Correo electrónico'
									value={firstUser.user['email']}
									onKeyUp={() => setEmailExists(false)}
									onChange={(e) => {
										setChangedEmail(true);
										setFirstUser({
											...firstUser,
											user: { ...firstUser.user, email: e.target.value },
										})
									}}
									onBlur={() => handleBlur('email')}
									size='medium'
									error={touchedFields.includes('email') && !isValidEmail(firstUser.user['email']) || emailExists}
									helperText={touchedFields.includes('email') && !isValidFieldRequired(firstUser.user['email']) ? 'Campo requerido' : !isValidEmail(firstUser.user['email']) ? 'Correo electrónico no es válido' : emailExists ? 'Correo electrónico ya esta registrado!' : ''
									}
									inputProps={{ maxLength: 80 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<i
													className='bi bi-question-circle-fill'
													style={{ color: '#2266cc', marginLeft: '5px' }}
												></i>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</OverlayTrigger>
					</Grid>
					<Grid xs={12} md={6} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<TextField
								required={isValidEmail(firstUser.user['email'])}
								fullWidth
								id='correo_eletronico_confirma'
								name='correo_eletronico_confirma'
								label='Confirmar correo electrónico'
								value={firstUser.user['confirma_email']}
								onChange={(e) =>
									setFirstUser({
										...firstUser,
										user: { ...firstUser.user, confirma_email: e.target.value },
									})
								}
								onBlur={() => handleBlur('confirma_email')}
								size='medium'
								error={
									touchedFields.includes('confirma_email') &&
									firstUser.user['email'] !== undefined &&
									firstUser.user['email'] !== '' &&
									!(firstUser.user['confirma_email'] === firstUser.user['email'])
								}
								inputProps={{ maxLength: 80 }}
								helperText={
									touchedFields.includes('confirma_email') &&
										firstUser.user['email'] !== undefined &&
										firstUser.user['email'] !== '' &&
										!(firstUser.user['confirma_email'] === firstUser.user['email'])
										? 'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente'
										: ''
								}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
					<Button
						variant='contained'
						sx={{ background: '#0A4396', mr: 1, width: 90 }}
						onClick={() => setStep(2)}
					>
						Atrás
					</Button>
					{/*<Box sx={{ flex: '1 1 auto' }} />*/}
					<Button
						variant='contained'
						sx={{ background: '#0A4396', mr: 1, width: 90 }}
						onClick={handleSubmit}
					>
						Enviar
					</Button>
				</Box>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={'filled'}
						severity={alert ? 'success' : 'error'}
						sx={{ whiteSpace: 'pre-line' }}
					>
						{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
						{alertMsg}
					</Alert>
				</Snackbar>
			</>
		}
		</>
	);
};
