/**
 * Lista de referencia para valores de los estados de las vacantes
 * 
 */
export const ListStatusApprovalModel = [
    { code: 'EN_ANALISIS', value: 'En análisis' },
    { code: 'APROBADA', value: 'Aprobada' },
    { code: 'RECHAZADA', value: 'Rechazada' },
    { code: 'CERRADO', value: 'Cerrado' },
    // { code: 'TEMPORAL', value: 'Temporal' },

];